import { CountryCodeKey, LanguageCodeKey } from "@/keys";

export type Language = {
  countryCode: CountryCodeKey;
  languages: Array<string>;
  defaultLanguage: string;
};

export const languages: Array<Language> = [
  {
    countryCode: CountryCodeKey.PR,
    languages: [
      `${LanguageCodeKey.es}-${CountryCodeKey.PR}`,
      `${LanguageCodeKey.en}-${CountryCodeKey.PR}`,
    ],
    defaultLanguage: `${LanguageCodeKey.es}-${CountryCodeKey.PR}`,
  },
  {
    countryCode: CountryCodeKey.CO,
    languages: [
      `${LanguageCodeKey.es}-${CountryCodeKey.CO}`,
      `${LanguageCodeKey.en}-${CountryCodeKey.CO}`,
    ],
    defaultLanguage: `${LanguageCodeKey.es}-${CountryCodeKey.CO}`,
  },
  {
    countryCode: CountryCodeKey.MX,
    languages: [
      `${LanguageCodeKey.es}-${CountryCodeKey.MX}`,
      `${LanguageCodeKey.en}-${CountryCodeKey.MX}`,
    ],
    defaultLanguage: `${LanguageCodeKey.es}-${CountryCodeKey.MX}`,
  },
  {
    countryCode: CountryCodeKey.AR,
    languages: [
      `${LanguageCodeKey.es}-${CountryCodeKey.AR}`,
      `${LanguageCodeKey.en}-${CountryCodeKey.AR}`,
    ],
    defaultLanguage: `${LanguageCodeKey.es}-${CountryCodeKey.AR}`,
  },
  {
    countryCode: CountryCodeKey.AE,
    languages: [
      `${LanguageCodeKey.es}-${CountryCodeKey.AE}`,
      `${LanguageCodeKey.en}-${CountryCodeKey.AE}`,
    ],
    defaultLanguage: `${LanguageCodeKey.en}-${CountryCodeKey.AE}`,
  },
];
