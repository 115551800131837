import { ListingAttachment } from "@/models";
import { ListingAttachmentBackend } from "../models/listing-attachment.backend.model";

export const responseListingAttachmentsAdapter = (
  attachments: ListingAttachmentBackend[],
  date: Date,
): ListingAttachment[] => {
  const data: ListingAttachment[] = [];

  if (attachments.length) {
    attachments.map((attachment) =>
      data.push({
        title: attachment.name || "---",
        url: attachment.url,
        date: date,
      }),
    );
  }
  return data;
};
