import { TenantBackend, responseParcelAdapter } from "@/adapters";
import { Tenant } from "@/models";

export const responseTenantAdapter = (tenant: TenantBackend): Tenant => ({
  id: tenant.id,
  name: tenant.name,
  address: tenant.address,
  url: tenant.url,
  geometry: tenant.geometry,
  pictures: tenant.pictures,
  propertySector: tenant.propertySector,
  updatedAt: tenant.updatedAt,
  createdAt: tenant.createdAt,
  extractedAt: tenant.extractedAt,
  sourceId: tenant.sourceId,
  cityId: tenant.cityId,
  parcelId: tenant.parcelId,
  sectorId: tenant.sectorId,
  subsectorId: tenant.subsectorId,
  microsectorId: tenant.microsectorId,
  sector: tenant.sector ?? null,
  subsector: tenant.subsector ?? null,
  microsector: tenant.microsector ?? null,
  source: tenant.source ?? null,
  phones: tenant.phones,
  city: tenant.city ? { value: tenant.city.id, label: tenant.city.name } : null,
  parcel: tenant?.parcel ? responseParcelAdapter(tenant.parcel) : null,
  propertyId: tenant.propertyId,
});
