import { store } from "@/app/store";
import { findCategoricFilter } from "@/views/pages/tableView/tables/TableUnits";

export const unitsTableMapper = (data, language) => {
  const dataTableMapper = [];
  const {
    newFilters: { filtersByPortal },
    app: { portal },
  } = store.getState();

  if (data?.length) {
    data.map((row) => {
      return dataTableMapper.push({
        ID: row.id,
        [language.picture.title]: row?.parcel?.photos?.length ? row.parcel.photos[0] : "---",
        [language.name.title]: row.parcel?.name ?? "---",
        [language.address.title]: row.parcel?.address ?? row.address ?? "---",
        [language.unitType.title]: row?.unitType ?? "---",
        [language.unitNumber.title]: row.addressJSON?.unitNumber?.value ?? "---",
        [language.cadaster.title]: row.govtId ?? "---",
        [language.sector.title]:
          row.parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
            ? findCategoricFilter({
                id: row?.parcel?.sectorId,
                arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
              })
            : "---",
        [language.subsector.title]:
          row.parcel?.subsectorId && filtersByPortal[portal].categoricFilters.subsectors.length
            ? findCategoricFilter({
                id: row?.parcel?.subsectorId,
                arrayCategoric: filtersByPortal[portal].categoricFilters.subsectors,
              })
            : "---",
      });
    });
  }
  return dataTableMapper;
};
