import axios from "axios";
import { URL_BACKEND } from "@/config";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ListingBackendInclude,
  ParcelBackendInclude,
  PermitBackendInclude,
  PropertyBackendInclude,
  TaxBackendInclude,
  TenantBackendInclude,
  TransactionBackendInclude,
  useEndpoint,
} from "@/keys";
import { AppThunk } from "@/app/store";
import { setBrochureProgress, setBrochureIsLoading } from "@/redux/slices";
import { toast } from "react-toastify";
import { api } from "../api";
import {
  LuxuryScoreBackend,
  ReportListBackend,
  ReportListingBackend,
  ReportPropertyBackend,
  RequestFiltersBackend,
  ValuationFeatureBackend,
  responseReportListAdapter,
  responseReportListingAdapter,
  responseReportPropertyAdapter,
} from "@/adapters";
import {
  ReportList,
  ReportListing,
  ReportProperty,
  UserFormReportTypes,
  ValuationFeature,
} from "@/models";
import { useLocaleCountryCode } from "@/hooks";
import { responseValuationFeaturesAdapter } from "@/adapters/responses/report/valuation-features.adapter";

export interface BodyReportListingProps {
  reportId: string;
  listingInclude: ListingBackendInclude[];
  parcelInclude?: ParcelBackendInclude[];
  lastTransactionInclude?: TransactionBackendInclude[];
  comparables: {
    returnSize: 5 | 6 | 7 | 8 | 9 | 10;
    comparableListingsInclude?: ListingBackendInclude[];
    comparableTransactionsInclude?: TransactionBackendInclude[];
    comparableTransactionsPropertiesInclude?: PropertyBackendInclude[];
  };
  analytics?: {
    days: number; //max(min(integer(), 1), 3_660)
    groupBy: "day" | "week" | "month" | "quarter" | "year"; //enums(['day', 'week', 'month', 'quarter', 'year'])
  };
}

export interface BodyReportPropertyProps {
  reportId: string;
  unitInclude: PropertyBackendInclude[];
  parcelInclude: ParcelBackendInclude[];
  listingsBody?: RequestFiltersBackend<ListingBackendInclude, { isCount: false }>;
  transactionsBody?: RequestFiltersBackend<TransactionBackendInclude, { isCount: false }>;
  permitsBody?: RequestFiltersBackend<PermitBackendInclude, { isCount: false }>;
  tenantsBody?: RequestFiltersBackend<TenantBackendInclude, { isCount: false }>;
  taxesBody?: RequestFiltersBackend<TaxBackendInclude, { isCount: false }>;
  documentsBody?: RequestFiltersBackend<TransactionBackendInclude, { isCount: false }>;
  analytics?: {
    days: number; //max(min(integer(), 1), 3_660)
    groupBy: "day" | "week" | "month" | "quarter" | "year"; //enums(['day', 'week', 'month', 'quarter', 'year'])
  };
  comparables: {
    returnSize: 5 | 6 | 7 | 8 | 9 | 10;
    comparableListingsInclude?: ListingBackendInclude[];
    comparableTransactionsInclude?: TransactionBackendInclude[];
    comparableTransactionsPropertiesInclude?: PropertyBackendInclude[];
  };
}
export interface BodyReportListProps {
  reportId: string;
  listing: {
    include: ListingBackendInclude[];
    lastTransactionInclude?: TransactionBackendInclude[];
    parcelInclude?: ParcelBackendInclude[];
  };
  parcel: {
    include: ParcelBackendInclude[];
    listingInclude?: ListingBackendInclude[];
    taxInclude?: TaxBackendInclude[];
    count: {
      tenants?: boolean;
      permits?: boolean;
      transactions?: boolean;
      listings?: boolean;
      units?: boolean;
    };
  };
}

interface BodyValuationFeaturesPropsBase {
  isForRent: boolean;
  luxuryScore: LuxuryScoreBackend;
}

interface BodyValuationFeaturesPropsFromUnit extends BodyValuationFeaturesPropsBase {
  fromUnit: true;
  sectorId: string;
  subsectorId: string;
}

interface BodyValuationFeaturesPropsNotFromUnit extends BodyValuationFeaturesPropsBase {
  fromUnit: false;
  property_type: string;
}

type BodyValuationFeaturesProps =
  | BodyValuationFeaturesPropsFromUnit
  | BodyValuationFeaturesPropsNotFromUnit;

const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReportListingData: builder.query<
      ReportListing,
      { id: string; countryCode: CountryCodeKey; bodyProps: BodyReportListingProps }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().listingReportData({
            id,
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ReportListingBackend }): ReportListing =>
        responseReportListingAdapter(response.data),
    }),
    getReportPropertyData: builder.query<
      ReportProperty,
      { id: string; countryCode: CountryCodeKey; bodyProps: BodyReportPropertyProps }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().unitReportData({
            id,
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ReportPropertyBackend }): ReportProperty =>
        responseReportPropertyAdapter(response.data),
    }),
    getReportListData: builder.query<
      ReportList,
      { id: string; countryCode: CountryCodeKey; bodyProps: BodyReportListProps }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().listReportData({
            id,
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ReportListBackend }): ReportList =>
        responseReportListAdapter(response.data),
    }),
    getValuationFeatures: builder.query<
      ValuationFeature,
      { countryCode: CountryCodeKey; bodyProps: BodyValuationFeaturesProps }
    >({
      query: ({ countryCode, bodyProps }) => {
        return {
          url: useEndpoint().valuationFeatures({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ValuationFeatureBackend }): ValuationFeature =>
        responseValuationFeaturesAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportListingDataQuery,
  useGetReportPropertyDataQuery,
  useGetReportListDataQuery,
  useLazyGetValuationFeaturesQuery,
} = reportApi;

export const getReport =
  ({
    url,
    title,
    localStorage,
    formBody,
  }: {
    url: string;
    title: string;
    localStorage: {
      i18nextLng: string;
      token: string;
    };
    formBody: UserFormReportTypes;
  }): AppThunk =>
  async (dispatch) => {
    const { countryCode } = useLocaleCountryCode();

    let progress = 0;
    const fakeProgressInterval = setInterval(() => {
      // Incrementa el progreso ficticio gradualmente
      if (progress < 80) {
        progress += 1;
        dispatch(setBrochureProgress({ progress }));
      }
    }, 500); // Intervalo de actualización ficticia

    try {
      dispatch(setBrochureIsLoading({ isLoading: true }));

      interface RequestData {
        url: string;
        localStorage: {
          i18nextLng: string;
          token: string;
        };
        type: UserFormReportTypes["type"];
        formBody: UserFormReportTypes["formBody"];
      }

      const requestData: RequestData = {
        url,
        localStorage,
        ...formBody,
      };

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().report({ countryCode: CountryCodeBackendKey[countryCode] }),
        data: requestData,
        method: "post",
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          // Detiene el progreso ficticio utilizando clearInterval
          clearInterval(fakeProgressInterval);
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch(setBrochureIsLoading({ isLoading: false }));
          if (percentCompleted > 80) {
            dispatch(setBrochureProgress({ progress: percentCompleted }));
          }
        },
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${title}.pdf`;
      link.click();
      return true;
    } catch (error) {
      clearInterval(fakeProgressInterval);
      dispatch(setBrochureIsLoading({ isLoading: false }));
      dispatch(setBrochureProgress({ progress: 0 }));
      toast.error("Error Generating Report", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
      return false;
    } finally {
      clearInterval(fakeProgressInterval);
      dispatch(setBrochureIsLoading({ isLoading: false }));
      dispatch(setBrochureProgress({ progress: 0 }));
    }
  };
