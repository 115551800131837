import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { User } from "@/models";

interface UserState {
  user: User | null;
  userCheck: boolean;
  isLoading: boolean;
}

const initialState: UserState = {
  user: null,
  userCheck: false,
  isLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User | null; userCheck: boolean }>) => {
      state.user = action.payload.user;
      state.userCheck = action.payload.userCheck;
      state.isLoading = false;
    },
    setUserPhoto: (state, action: PayloadAction<{ photoUrl: string }>) => {
      if (state.user) {
        state.user.photoUrl = action.payload.photoUrl;
      }
      state.isLoading = false;
    },
    setUserOrganizationPhoto: (state, action: PayloadAction<{ imgUrl: string }>) => {
      if (state.user?.organization) {
        state.user.organization.imgUrl = action.payload.imgUrl;
      }
      state.isLoading = false;
    },
    setUserIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    setUserCheck: (state, action: PayloadAction<{ userCheck: boolean }>) => {
      state.userCheck = action.payload.userCheck;
    },
  },
});

// These actions will dispatch in the app
export const { setUser, setUserPhoto, setUserOrganizationPhoto, setUserIsLoading, setUserCheck } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
