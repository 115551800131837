export enum SubsectorBackendKey {
  name = "name",
}

export type SubsectorBackendKeys = keyof typeof SubsectorBackendKey;

export enum PermitSubsectorBackendKey {
  id = "id",
  name = "name",
  nameEn = "nameEn",
  sectorId = "sectorId",
}

export type PermitSubsectorBackendKeys = keyof typeof PermitSubsectorBackendKey;
