import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface UnitState {
  unitId: string;
  unitView: boolean;
}

// Define the initial state using that type
const initialState: UnitState = {
  unitId: "",
  unitView: false,
};

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    setUnit: (state, action: PayloadAction<{ unitId: string; unitView: boolean }>) => {
      state.unitId = action.payload.unitId;
      state.unitView = action.payload.unitView;
    },
  },
});

// These actions will dispatch in the app
export const { setUnit } = unitSlice.actions;

export const selectUnit = (state: RootState) => state.unit;

export default unitSlice.reducer;
