import {
  ValuationReportBackend,
  responseReportCurrentValuationAdapter,
  responseReportTimeOnMarketAdapter,
} from "@/adapters";
import { ValuationReport } from "@/models";

export const responseReportValuationAdapter = (data: ValuationReportBackend): ValuationReport => ({
  currentValuation: data.currentValuation
    ? responseReportCurrentValuationAdapter(data.currentValuation)
    : null,
  timeOnMarket: data["Time on Market"]
    ? responseReportTimeOnMarketAdapter(data["Time on Market"])
    : null,
  model: {
    valuationLotModel: data.Model["valuation-lot Model"],
    valuationSaleModel: data.Model["valuation-sale Model"],
    valuationRentModel: data.Model["valuation-rent Model"],
  },
});
