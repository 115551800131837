import type { Transaction } from "@/models";
import {
  TransactionBackend,
  responseListingAdapter,
  responseParcelAdapter,
  responseTransactionPropertiesAdapter,
} from "@/adapters";

export const responseTransactionAdapter = (transaction: TransactionBackend): Transaction => {
  const newTransaction: Transaction = {
    id: transaction.id,
    address: transaction.address ?? null,
    buyerName: transaction.buyerName ?? null,
    createdAt: transaction.createdAt ?? null,
    deedBook: transaction.deedBook ?? null,
    deedNumber: transaction.deedNumber ?? null,
    deedPage: transaction.deedPage ?? null,
    sellerName: transaction.sellerName ?? null,
    transactionDate: transaction.transactionDate ?? null,
    transactionPrice: transaction.transactionPrice ?? null,
    transactionType: transaction.transactionType ?? null,
    scriptures: transaction.scriptures,
    transactionProperties: transaction.transactionProperties
      ? responseTransactionPropertiesAdapter(transaction.transactionProperties)
      : null,
    source: transaction.source ? transaction.source : null,
    sector: transaction.sector ?? null,
    subsector: transaction.subsector ?? null,
    microsector: transaction.microsector ?? null,
    pricePerBuiltSqm: transaction.pricePerBuiltSqm ?? 0,
    pricePerLotSqm: transaction.pricePerLotSqm ?? 0,

    parcelId: transaction.parcelId,
    genericListingId: transaction.genericListingId,
    parcel: transaction.parcel ? responseParcelAdapter(transaction.parcel) : null,
    listing: transaction.listing ? responseListingAdapter(transaction.listing) : null,
    builtArea: transaction.builtArea ?? 0,
    lotArea: transaction.lotArea ?? 0,
  };

  // Estos datos se estan agregando en front
  if (transaction?.transactionProperties?.length) {
    newTransaction.solar = transaction?.transactionProperties[0]?.solar
      ? transaction.transactionProperties[0].solar
      : transaction?.transactionProperties[0]?.property?.address
      ? transaction.transactionProperties[0].property.address
      : "---";

    newTransaction.unitType = transaction?.transactionProperties[0]?.property?.addressJSON?.unitType
      ? transaction?.transactionProperties[0]?.property?.addressJSON?.unitType.value
      : "---";

    newTransaction.unitNumber = transaction?.transactionProperties[0]?.property?.addressJSON
      ?.unitNumber
      ? transaction?.transactionProperties[0]?.property?.addressJSON?.unitNumber.value
      : "---";
  }

  return newTransaction;
};
