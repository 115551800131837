import React from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import notImg from "../../assets/img/slider/notImg.jpg";
import { getOrganization } from "@/configs";
import { OrganizationKey } from "@/keys";
import classNames from "classnames";

class CarouselBasic extends React.Component {
  state = {
    activeTab: "1",
    activeIndex: 0,
    image: [notImg],
  };

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    if (this.props.imgs.length) {
      const nextIndex =
        this.state.activeIndex === this.props.imgs.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({ activeIndex: nextIndex });
    }
  };

  previous = () => {
    if (this.animating) return;
    if (this.props.imgs.length) {
      const nextIndex =
        this.state.activeIndex === 0 ? this.props.imgs.length - 1 : this.state.activeIndex - 1;
      this.setState({ activeIndex: nextIndex });
    }
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const organizationIsRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;
    const { activeIndex } = this.state;
    const images = this.props.imgs.length ? this.props.imgs : this.state.image;
    const slides = images.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
          style={{
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
          }}>
          <div
            className="img-carousel-bg-border-radius"
            style={{
              backgroundImage: `url(${item}), url(${notImg})`,
              height: this.props.height ?? "",
              width: this.props.width ?? "",
            }}></div>
        </CarouselItem>
      );
    });
    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          className={classNames({
            ["container-img-carousel-bg-border-radius"]: !organizationIsRedAtlas,
          })}>
          <CarouselIndicators
            items={images}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          {images.length > 1 ? (
            <div>
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </div>
          ) : null}
        </Carousel>
      </React.Fragment>
    );
  }
}
export default CarouselBasic;
