import React from "react";
import "@/assets/scss/components/app-loader.scss";
import { Progress } from "reactstrap";
import { useTranslation } from "react-i18next";

type ProgressFullWidthProps = {
  progress: number;
};

function ProgressFullWidth({ progress }: ProgressFullWidthProps) {
  const { t } = useTranslation("translation");
  const translation = t("loadings", { returnObjects: true });
  return (
    <div className="fallback-spinner-custom vh-100">
      <div className="loadingProgress">
        <h3 className="text-center white fw-bold">{translation.brochureProgress.title}</h3>
        <Progress animated color="danger" value={progress} className="progress-xl mb-2" />
        <h5 className="text-center white fw-bold">{progress + " %"}</h5>
      </div>
    </div>
  );
}

export default ProgressFullWidth;
