import { useAppDispatch } from "@/app/hooks";
import { MapDrawnPolygonButtonId } from "@/keys";
import { setRefreshFilters } from "@/redux/slices";
import React from "react";
import { useTheme } from "styled-components";

interface RefreshFiltersProps {
  removeFilters: () => void;
}
function RefreshFilters({ removeFilters }: RefreshFiltersProps) {
  const { primary } = useTheme();
  const dispatch = useAppDispatch();
  const customIcon = (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="8.5" r="6.5" stroke={primary.color} strokeWidth="2" />
      <rect x="12" y="6" width="5" height="5" fill={primary.contrastColor} />
      <path
        d="M9 5.93555L13.9357 6.73505L14.7352 1.79939"
        stroke={primary.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <button
      onClick={() => {
        removeFilters();
        dispatch(setRefreshFilters(true));
      }}
      className={`d-flex align-items-center justify-content-center cursor-pointer`}
      style={{
        padding: "6px 6px 6px 15px",
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        borderLeft: `none`,
      }}
      id={MapDrawnPolygonButtonId.drawPolygonButton}>
      {customIcon}
    </button>
  );
}

export default RefreshFilters;
