import { EventListingPriceTrackingKey } from "@/keys";
import { ListingFeaturesTracking } from "@/models/listing/listing-featuresTracking.model";
import { ListingFeaturesTrackingBackend } from "../models/listing-featuresTracking.backend.model";
import { ListingBackend } from "../models/listing.backend.model";

export const responseListingFeaturesTrackingAdapter = (
  features: ListingFeaturesTrackingBackend,
  listing: ListingBackend,
): ListingFeaturesTracking => {
  const newData: ListingFeaturesTracking = {
    price: [],
  };

  if (features?.price || features?.price?.length) {
    features?.price?.map((price) => {
      if (price.createdAt && price.value) {
        newData.price.push({
          date: new Date(price.createdAt).toISOString().split("T")[0],
          value: price.value,
        });
      }
    });
  }

  const sortedPrice = newData.price
    ? newData.price.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    : [];

  function formatValue(input: string): string {
    const regex = /^\d/;
    return regex.test(input) ? "$" + input : input;
  }

  const data: ListingFeaturesTracking = {
    price: sortedPrice.map((priceData, index) => {
      const event =
        index === 0
          ? EventListingPriceTrackingKey.listed // Primer registro
          : index === sortedPrice.length - 1
          ? listing.active
            ? EventListingPriceTrackingKey.priceChange // Último registro, listing activo
            : EventListingPriceTrackingKey.unlisted // Último registro, listing inactivo
          : EventListingPriceTrackingKey.priceChange; // Registros intermedios

      const date =
        index === 0
          ? listing.createdAt // Fecha de creación del listing para el primer registro
          : index === sortedPrice.length - 1 && !listing.active
          ? (listing.deactivatedAt as Date) // Fecha de desactivación del listing para el último registro, listing inactivo
          : priceData.date;

      return {
        value: formatValue(priceData.value),
        date,
        event,
      };
    }),
  };

  return data;
};
