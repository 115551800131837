import React from "react";
import { Button } from "reactstrap";
import devicesIcon from "@/assets/img/icons/responsive.png";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function ResponsiveDesignNotAvailable({ userHasSubscription, clickBlocked }) {
  const { t } = useTranslation("translation");
  const translation = t("responsiveDesignNotAvailable", { returnObjects: true });

  const dispatch = useDispatch();
  return (
    <div className="content-overlay-login-register animate-element fadeIn">
      <div className="full-view-on-map-empty d-flex align-items-center justify-content-center">
        <div className="row white animate-element fadeIn">
          <div className="col-12">
            <div className="card p-3 d-flex justify-content-center">
              <img src={devicesIcon} alt="devicesIcon" width={50} height={50} className="m-auto" />
              <h5 className="text-center black mb-0 mt-2">
                <FormattedMessage
                  id={translation.title.id}
                  defaultMessage={translation.title.message}
                  values={{
                    bold: (str) => <b>{str}</b>,
                    broke: () => <br />,
                  }}
                />
              </h5>
              {/* {!dispatch(userHasSubscription()) && (
                <Button
                  color="primary"
                  className="mt-1 w-100 round fw-bold"
                  size="md"
                  onClick={() => clickBlocked()}>
                  {translation.button}
                </Button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveDesignNotAvailable;
