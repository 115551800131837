import mapStyleDark from "@/assets/mapStyles/mapStyleDark.json";
import mapStyleLight from "@/assets/mapStyles/mapStyleLight.json";

// icons control
import BlackSunIcon from "@/assets/img/icons/blackSun.png";
import WhiteSunIcon from "@/assets/img/icons/whiteSun.png";
import BlackMoonIcon from "@/assets/img/icons/blackMoon.png";
import WhiteMoonIcon from "@/assets/img/icons/whiteMoon.png";
import BlackSatelliteIcon from "@/assets/img/icons/blackSatellite.png";
import WhiteSatelliteIcon from "@/assets/img/icons/whiteSatellite.png";
import { CustomControlsMapIds } from "@/keys";
import React from "react";

type CreateMapControlsProps = {
  map: google.maps.Map;
  mapTypeId: typeof google.maps.MapTypeId;
  controlPosition: typeof google.maps.ControlPosition;
  userMapstyleRef: React.MutableRefObject<boolean>;
};

/* const handleControlClick = ({
  controlId,
  iconActive,
  map,
  style,
  mapTypeId,
  userMapstyleRef,
}: {
  controlId: string;
  iconActive: string;
  map: google.maps.Map;
  style: google.maps.MapTypeStyle[] | null;
  mapTypeId: google.maps.MapTypeId;
  userMapstyleRef: React.MutableRefObject<boolean>;
}) => {
  const control = document.getElementById(controlId) as HTMLImageElement | null;
  if (control) {
    control.classList.add("active");
    control.src = iconActive;
  }

  // Restaurar otros controles a su estado inactivo
    const otherControls = [
    CustomControlsMapIds.LIGHT,
    CustomControlsMapIds.DARK,
    CustomControlsMapIds.SATELLITE,
  ].filter((id) => id !== controlId);
  otherControls.forEach((id) => {
    const otherControl = document.getElementById(id) as HTMLImageElement | null;
    if (otherControl) {
      otherControl.classList.remove("active");
      // Restaurar los íconos inactivos aquí
      if (id === CustomControlsMapIds.LIGHT) {
        otherControl.src = BlackSunIcon;
      } else if (id === CustomControlsMapIds.DARK) {
        otherControl.src = BlackMoonIcon;
      } else if (id === CustomControlsMapIds.SATELLITE) {
        otherControl.src = BlackSatelliteIcon;
      }
    }
  });

    map.setOptions({
    styles: style,
    mapTypeId,
  });
}; */

export const CreateMapControls = ({
  map,
  mapTypeId,
  controlPosition,
  userMapstyleRef,
}: CreateMapControlsProps) => {
  /*   const lightControlClick = () =>
    handleControlClick({
      controlId: CustomControlsMapIds.LIGHT,
      iconActive: WhiteSunIcon,
      map,
      style: mapStyleLight,
      mapTypeId: mapTypeId.ROADMAP,
      userMapstyleRef,
    });

  const darkControlClick = () =>
    handleControlClick({
      controlId: CustomControlsMapIds.DARK,
      iconActive: WhiteMoonIcon,
      map,
      style: mapStyleDark,
      mapTypeId: mapTypeId.ROADMAP,
      userMapstyleRef,
    });

  const satelliteControlClick = () =>
    handleControlClick({
      controlId: CustomControlsMapIds.SATELLITE,
      iconActive: WhiteSatelliteIcon,
      map,
      style: null,
      mapTypeId: mapTypeId.SATELLITE,
      userMapstyleRef,
    });

  // Configurar eventos de click para cada controles de estilos de mapa
  const defaultControl = document.getElementById(CustomControlsMapIds.LIGHT);
  if (defaultControl) {
    defaultControl.className = "active";
    defaultControl.addEventListener("click", lightControlClick);
  }
  document.getElementById(CustomControlsMapIds.DARK)?.addEventListener("click", darkControlClick);
  document
    .getElementById(CustomControlsMapIds.SATELLITE)
    ?.addEventListener("click", satelliteControlClick);
    */

  // ------------- Configuracion de otros controles (zoom, streetView, rotate) -------------
  map.setOptions({
    mapTypeId: mapTypeId.ROADMAP,
    rotateControlOptions: {
      position: controlPosition.LEFT_BOTTOM,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: controlPosition.LEFT_CENTER,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: controlPosition.LEFT_CENTER,
    },
    tilt: 0,
  });
};
