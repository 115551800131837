export enum CountryCodeKey {
  PR = "PR",
  CO = "CO",
  MX = "MX",
  AR = "AR",
  AE = "AE",
}

export enum CountryCodeBackendKey {
  PR = "PRI",
  CO = "COL",
  MX = "MEX",
  AR = "ARG",
  AE = "ARE",
}
