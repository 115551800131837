import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "a966b836-3fad-4d1d-8324-ca55685ebadf",
    address: null,
    buyerName: " Ángel Humberto Faccio Rivera",
    createdAt: null,
    deedBook: null,
    deedNumber: null,
    deedPage: null,
    sellerName: null,
    transactionDate: "2024-02-14T04:00:00.000Z",
    transactionPrice: "205000.0",
    transactionType: "Compraventa",
    scriptures: {
      uploaded:
        "https://karibe.ridpr.pr.gov/karibefiles/KaribeArchivosCargados/2024-02/5764BC65-2377-425D-9E1C-5E37C6E72709.pdf",
      generated:
        "https://karibe.ridpr.pr.gov/karibefiles/KaribeArchivosGenerados/3A0B3FED-234B-4E0B-91E8-0CA10F04C545.pdf",
    },
    transactionProperties: [
      {
        createdAt: "2024-02-14T20:53:10.338Z",
        fincaNumber: null,
        govtId: "086-062-755-12-078",
        id: "c0446cbc-146e-b638-e469-08581d38103f",
        property: {
          id: "e5bb47a7-4bfb-f725-2964-cc4b7c0abb2b",
          address: "APT 15 B COND TORRIMAR PLAZA GUAYNABO",
          addressJSON: {
            id: "e5bb47a7-4bfb-f725-2964-cc4b7c0abb2b",
            zipCode: null,
            dataType: "unit",
            original: "APT 15 B COND TORRIMAR PLAZA GUAYNABO",
            sourceId: "faf69cc8-34a2-002a-7d4e-05d2cc329082",
            unitType: {
              value: "Apartment",
              status: "confirmed",
            },
            streetName: null,
            unitNumber: {
              value: "15 B ",
              status: "confirmed",
            },
            buildingName: {
              value: "Condominio Torrimar Plaza",
              method: "exact_match_es",
              status: "confirmed",
              parcelId: "858232e2-23ab-1647-0395-6720dc007511",
            },
            streetNumber: null,
            joinedAddress: null,
          },
          builtArea: "130.02",
          geometry: {
            type: "Point",
            coordinates: [-66.11826167, 18.38415445],
          },
          transactions: [],
          govtId: "086-062-755-12-078",
          toilets: null,
          rooms: null,
          parkingSpaces: null,
          amenity: null,
          city: null,
          neighborhood: null,
          parcelId: "858232e2-23ab-1647-0395-6720dc007511",
          parcel: {
            id: "858232e2-23ab-1647-0395-6720dc007511",
            name: "Condominio Torrimar Plaza",
            address: "Condominio Torrimar Plaza, Calle Condominio",
            photos: [
              "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1673462815571816b7ff2213f6c5fdea8.jpeg",
              "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167346281597518cd297e8fb3626641b9.png",
            ],
            sector: {
              name: "Residential",
            },
            subsector: {
              name: "Condominium",
            },
            city: {
              name: "Guaynabo",
            },
          },
          taxes: null,
          listings: null,
          unitType: "Apartment",
          unitNumber: "15 B ",
          lastTransactionPrice: 0,
          lastTransactionTotalAreaPricePerSqm: 0,
        },
        builtArea: "130.02",
        solar: null,
      },
    ],
    source: {
      name: "KARIBE",
      logoUrl:
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/6ddc66525719390399cfb6e9f2ea1505image%20%288%29.png",
    },
    sector: null,
    subsector: null,
    microsector: null,
    pricePerBuiltSqm: 0,
    pricePerLotSqm: 0,
    builtArea: 130.02,
    solar: "APT 15 B COND TORRIMAR PLAZA GUAYNABO",
    unitType: "Apartment",
    unitNumber: "15 B ",
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    transactionProperties: [
      {
        ...value.transactionProperties[0],
        property: {
          ...value.transactionProperties[0].property,
          parcel: {
            ...value.parcel,
            photos: [getRandomImage()],
          },
        },
      },
    ],
  }));
