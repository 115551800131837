import { NewsCategoriesKey } from "@/keys";
import { ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";

export function newsCategoriesColorsMapper({
  categoryName,
  newsColor,
  fillColor,
}: {
  categoryName: keyof typeof NewsCategoriesKey;
  newsColor: ThemeType["newsColors"]["categories"];
  fillColor: boolean;
}) {
  switch (categoryName) {
    case NewsCategoriesKey.residential:
      return fillColor ? newsColor.residential.color : newsColor.residential.contrastColor;
    case NewsCategoriesKey.retail:
      return fillColor ? newsColor.retail.color : newsColor.retail.contrastColor;
    case NewsCategoriesKey.office:
      return fillColor ? newsColor.office.color : newsColor.office.contrastColor;
    case NewsCategoriesKey.industrial:
      return fillColor ? newsColor.industrial.color : newsColor.industrial.contrastColor;
    case NewsCategoriesKey.land:
      return fillColor ? newsColor.land.color : newsColor.land.contrastColor;
    case NewsCategoriesKey.hospitality:
      return fillColor
        ? newsColor.lodgingAndHospitality.color
        : newsColor.lodgingAndHospitality.contrastColor;
    case NewsCategoriesKey.infrastructure:
      return fillColor ? newsColor.infrastructure.color : newsColor.infrastructure.contrastColor;
    case NewsCategoriesKey.tax:
      return fillColor ? newsColor.tax.color : newsColor.tax.contrastColor;
    case NewsCategoriesKey.finance:
      return fillColor ? newsColor?.finance.color : newsColor?.finance.contrastColor;
    case NewsCategoriesKey.construction:
      return fillColor ? newsColor?.construction.color : newsColor?.construction.contrastColor;
    default:
      return fillColor ? newsColor.default.color : newsColor.default.contrastColor;
  }
}
