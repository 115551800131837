import { WhiteLabelsURL } from "@/configs";
import { Listing } from "@/models";

export const getListingURL = (listing: Listing) => {
  if (listing.dataSource?.url && WhiteLabelsURL.includes(listing.dataSource?.url)) {
    return listing.dataSource?.url + "/listings/" + listing.genericListingId;
  } else {
    return listing.url;
  }
};
