import React from "react";
import BtnsFooter from "./BtnsFooter";
import "react-toggle/style.css";
import "@/assets/scss/plugins/forms/switch/react-toggle.scss";
import logotype_redatlas from "@/assets/img/logo/logotype.png";

// redux
import { appPaths, getOrganization, relativeAppPaths } from "@/configs";
import { OrganizationKey, PortalKey } from "@/keys";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import NewsFooter from "./news/NewsFooter";
import { sizeViewport } from "@/components/styledComponents";
import classNames from "classnames";
import { useAppDispatch } from "@/app/hooks";
import { userIsLoggedIn } from "@/redux/actions";

interface FooterNavbar {
  match: { path: string; id: string };
  clickBlocked: () => void;
  portal: keyof typeof PortalKey;
  windowWidth: number;
}

function FooterNavbar({ match, clickBlocked, portal, windowWidth }: FooterNavbar) {
  const { t } = useTranslation("translation");
  const dispatch = useAppDispatch();
  const translationFooter = t("footer", { returnObjects: true });
  const { primary, defaultColors } = useTheme();
  const isRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;
  const isMobile = windowWidth < sizeViewport.laptop;

  return (
    <div
      className={
        match.path === relativeAppPaths().list(match.id) ||
        match.path === appPaths.news.path ||
        match.path === relativeAppPaths().propertyProfile(match.id) ||
        (match.path === relativeAppPaths().listingProfile(match.id) &&
          getOrganization().organization.key !== OrganizationKey.redatlas) ||
        match.path === appPaths.profile.path ||
        match.path === appPaths.billing.path ||
        match.path === appPaths.business.path
          ? "d-none"
          : "d-block"
      }>
      <div
        style={{
          bottom: "1.6rem",
          position: "fixed",
          zIndex: isMobile ? 1 : 2,
          display: "block",
          margin: isMobile ? "1rem 1rem .3rem" : "1.3rem 2.2rem 0",
          left: 0,
          right: 0,
        }}>
        <div
          style={{
            display: "flex",
            justifyContent:
              match.path === appPaths.tableView.path &&
              getOrganization().organization.key === OrganizationKey.redatlas
                ? "end"
                : "space-between",
            alignItems: "center",
          }}>
          {isRedAtlas ? (
            !isMobile && <NewsFooter windowWidth={windowWidth} clickBlocked={clickBlocked} />
          ) : (
            <>
              <div
                style={{
                  cursor: "pointer",
                  padding: "1.1rem",
                  backgroundColor: defaultColors.background,
                  boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0.5rem",
                  width: "max-content",
                }}>
                <a
                  href={"https://app.atlas.red/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: primary.color,
                  }}>
                  <div className="d-flex align-items-center">
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                      className=" mr-05">
                      {translationFooter.poweredBy}
                    </span>
                    <img src={logotype_redatlas} alt="RED Atlas logo" className="logo-powered-by" />
                  </div>
                </a>
              </div>
            </>
          )}
          {!dispatch(userIsLoggedIn()) || isMobile ? (
            <div
              className={classNames("d-flex ml-1", {
                "justify-content-end": !isMobile,
                "justify-content-center": isMobile,
                "w-100": isMobile,
              })}
              style={{ width: isMobile ? "auto" : "unset", margin: "0 auto" }}>
              <BtnsFooter match={match} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default FooterNavbar;
