import { store } from "@/app/store";
import { PieChartOptions } from "@/models";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { AnalyticsBackendKey, SectorKey } from "@/keys";
import { AnalyticsBackend } from "../models/analytics/analytics.backend.model";

export const responseAnalyticsPieChartAdapter = ({
  data,
  key,
}: {
  data: AnalyticsBackend[];
  key: AnalyticsBackendKey;
}): PieChartOptions => {
  const dataPieChart: PieChartOptions = {
    series: [],
    labels: [],
    colors: [],
    total: 0,
  };

  const { themeName, organizationTheme, portal } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);
  const { filtersByPortal } = store.getState().newFilters;

  if (data.length) {
    if (key === AnalyticsBackendKey.propertyType) {
      data.map((x) => {
        const propertyTypeName = x.key;
        dataPieChart.series.push(Number(x.value));
        dataPieChart.labels.push(propertyTypeName);
        dataPieChart.colors.push(
          listingPropertyTypeColorsMapper({
            propertyTypeName: propertyTypeName,
            propertyTypeColor: activeTheme?.listingColors
              .propertyType as ThemeType["listingColors"]["propertyType"],
          }),
        );
        dataPieChart.total += Number(x.value);
      });
    }
    if (key === AnalyticsBackendKey.sectorId) {
      data.map((x) => {
        const findSector = filtersByPortal[portal].categoricFilters.sectors.find(
          (sec) => sec.value === x.key,
        );
        dataPieChart.series.push(Number(x.value));
        dataPieChart.labels.push(
          findSector ? findSector?.name ?? SectorKey.empty : SectorKey.empty,
        );
        dataPieChart.colors.push(
          sectorColorsMapper({
            sectorName: findSector ? findSector?.name ?? null : null,
            sectorColor: activeTheme?.parcelColors.sectors as ThemeType["parcelColors"]["sectors"],
            fillColor: true,
          }),
        );
        dataPieChart.total += Number(x.value);
      });
    }
  }

  return dataPieChart;
};
