export const filterSearch = (data, columns, userInput) => {
  return data.filter((d) => {
    const startsWithCondition = columns.find((column) => {
      if (column.selector) {
        return column.selector.includes(".")
          ? d[column.selector.split(".")[0]]
            ? d[column.selector.split(".")[0]][column.selector.split(".")[1]]
              ? d[column.selector.split(".")[0]][column.selector.split(".")[1]]
                  .toString()
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .startsWith(userInput.toLowerCase())
              : null
            : null
          : d[column.selector]
          ? d[column.selector]
              .toString()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .startsWith(userInput.toLowerCase())
          : null;
      }
    });

    const includesCondition = columns.find((column) => {
      if (column.selector) {
        return column.selector.includes(".")
          ? d[column.selector.split(".")[0]]
            ? d[column.selector.split(".")[0]][column.selector.split(".")[1]]
              ? d[column.selector.split(".")[0]][column.selector.split(".")[1]]
                  .toString()
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(userInput.toLowerCase())
              : null
            : null
          : d[column.selector]
          ? d[column.selector]
              .toString()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(userInput.toLowerCase())
          : null;
      }
    });

    return startsWithCondition || includesCondition || null;
  });
};
