export enum PermitKey {
  address = "address",
  addressJSON = "addressJSON",
  agency = "agency",
  case = "case",
  category = "category",
  createdAt = "createdAt",
  date = "date",
  dateNotApproved = "dateNotApproved",
  deletedAt = "deletedAt",
  description = "description",
  govtId = "govtId",
  id = "id",
  name = "name",
  owner = "owner",
  permitType = "permitType",
  procedure = "procedure",
  publicPrivate = "publicPrivate",
  status = "status",
  updatedAt = "updatedAt",
  url = "url",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
}

export enum PermitExpandKey {
  source = "source",
  parcel = "parcel",
  sector = "sector",
  subsector = "subsector",
}
