import React from "react";
import "../../assets/scss/components/app-loader.scss";
import Spinner from "./Spinner";

class SpinnerFullWidthMap extends React.Component {
  render() {
    return (
      <div className="fallback-spinner-custom vh-100" style={{ zIndex: 2 }}>
        <Spinner />
      </div>
    );
  }
}

export default SpinnerFullWidthMap;
