import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface AccordionProps {
  title: string | JSX.Element;
  isOpen?: boolean;
  children?: React.ReactNode;
  titleColor?: string;
  isExpanded?: boolean;
  onItemClick?: () => void;
  openIcon?: IconDefinition;
  closeIcon?: IconDefinition;
  containerProps?: React.CSSProperties;
  titleProps?: React.CSSProperties;
  contentProps?: React.CSSProperties;
}

const Container = styled.div`
  border-bottom: 0.125rem solid ${(props) => props.theme.common.lightGray};
`;

const Title = styled.div<{ titleColor?: string }>`
  color: ${(props) => props.titleColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
  cursor: pointer;
`;

const EndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: red;
`;

const ContentWrapper = styled.div<{ maxHeight: number }>`
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 1rem;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.5;
`;

export const AccordionItem = ({
  title,
  children,
  titleColor,
  isOpen,
  onItemClick,
  containerProps,
  titleProps,
  contentProps,
  openIcon = faMinus,
  closeIcon = faPlus,
}: AccordionProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentHeight = contentRef.current ? (isOpen ? contentRef.current.scrollHeight : 0) : 0;

  return (
    <Container style={containerProps}>
      <Title titleColor={titleColor} onClick={onItemClick} style={titleProps}>
        {title}
        <EndIcon>
          <FontAwesomeIcon icon={isOpen ? openIcon : closeIcon} />
        </EndIcon>
      </Title>

      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef} style={contentProps}>
          {children}
        </Content>
      </ContentWrapper>
    </Container>
  );
};
