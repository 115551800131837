import { relativeAppPaths } from "@/configs";
import { useLocaleCountryCode } from "../country-code.hook";
import i18n from "@/i18n";

const useRouteQueryString = () => {
  const { lookupQuerystring } = useLocaleCountryCode();

  const appListingProfileQueryString = (id: string) =>
    `${relativeAppPaths().listingProfile(id)}?${lookupQuerystring}=${i18n.language}`;

  const appPropertyProfileQueryString = ({
    id,
    entity,
    elementId,
  }: {
    id: string;
    entity?: string;
    elementId?: string;
  }) => {
    const queryParams = new URLSearchParams();
    queryParams.set(lookupQuerystring, i18n.language);

    if (entity) {
      queryParams.set("entity", entity);
    }

    if (elementId) {
      queryParams.set("id", elementId);
    }
    return `${relativeAppPaths().propertyProfile(id)}?${queryParams.toString()}`;
  };
  const appListQueryString = (id: string) =>
    `${relativeAppPaths().list(id)}?${lookupQuerystring}=${i18n.language}`;

  return {
    appListingProfileQueryString,
    appPropertyProfileQueryString,
    appListQueryString,
  };
};

export default useRouteQueryString;
