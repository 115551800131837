import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface NewsState {
  newsView: boolean;
  newsId: string | null;
}

// Define the initial state using that type
const initialState: NewsState = {
  newsView: false,
  newsId: null,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action: PayloadAction<{ newsView: boolean; newsId: string | null }>) => {
      state.newsView = action.payload.newsView;
      state.newsId = action.payload.newsId;
    },
  },
});

// These actions will dispatch in the app
export const { setNews } = newsSlice.actions;

export const selectNews = (state: RootState) => state.news;

export default newsSlice.reducer;
