import type { DataSource } from "@/models";
import { useEndpoint } from "@/keys";
import { DataSourceBackend, responseDataSourcesAdapter } from "@/adapters";
import { api } from "@/services";

export const sourcesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // ------------- Get Data Sources
    getDataSources: builder.query<DataSource[], { ids?: string[] }>({
      query: ({ ids }) => ({
        url: useEndpoint().dataSources(),
        method: "POST",
        body: { ids },
      }),
      transformResponse: (response: { data: DataSourceBackend[] }): DataSource[] =>
        responseDataSourcesAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

// -------------------------------- Queries (React hooks)--------------------------------
export const { useGetDataSourcesQuery } = sourcesApi;
