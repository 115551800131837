import React from "react";
import { appPaths, getOrganization } from "@/configs";
import { history } from "@/history";

function LogoNavbar() {
  return (
    <div
      onClick={() => history.push(appPaths.indexHome.path)}
      className={`logo d-flex align-items-center cursor-pointer mr-1`}>
      <img
        src={getOrganization().organization.logo}
        alt={getOrganization().organization.name}
        className="logo-navbar"></img>
    </div>
  );
}

export default LogoNavbar;
