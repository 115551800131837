export enum AmenityBackendKey {
  id = "id",
  name = "name",
  esName = "esName",
  amenityType = "amenityType",
}

export type AmenityBackendKeys = keyof typeof AmenityBackendKey;

// tabla pivot
enum ListingAmenityBackendKey {
  id = "id",
  genericListingId = "genericListingId",
  amenityId = "amenityId",
}

export type ListingAmenityBackendKeys = keyof typeof ListingAmenityBackendKey;
