import {
  ListingBackend,
  responseBrokersInfoAdapter,
  responseListingAmenitiesAdapter,
  responseParcelAdapter,
  responsePropertyAdapter,
  responseTransactionsAdapter,
} from "@/adapters";
import { Listing } from "@/models";
import { luxuryScoreAdapter } from "../luxuryScore/luxuryScore.adapter";
import { responseListingAttachmentsAdapter } from "./listing-attachments.adapter";
import { responseListingFeaturesTrackingAdapter } from "./listing-featuresTracking.adapter";
import { conditionScoreKey } from "@/keys";

export const responseListingAdapter = (listing: ListingBackend): Listing => ({
  id: listing.genericListingId,
  uniqueListingId: listing.uniqueListingId,
  ageNumeric: listing.ageNumeric,
  pricePerBuiltSqm: listing.pricePerBuiltSqm,
  pricePerLotSqm: listing.pricePerLotSqm,
  genericListingId: listing.genericListingId,
  location: listing.location,
  centroidLocation: listing.centroidLocation,
  title: listing.title,
  url: listing.url,
  price: listing.price,
  priceString: listing.priceString ?? null,
  isForRent: listing.isForRent,
  address: listing.address ?? "---",
  addressJSON: listing.addressJSON,
  builtArea: listing.builtArea ? Number(listing.builtArea) : 0,
  lotArea: listing?.lotArea ? Number(listing.lotArea) : 0,
  toilets: listing.toilets,
  rooms: listing.rooms,
  parkingSpaces: listing.parkingSpaces,
  buildingDate: listing.buildingDate,
  createdAt: listing.createdAt,
  deactivatedAt: listing.deactivatedAt,
  daysOnMarket: listing.daysOnMarket ? Math.round(listing.daysOnMarket) : undefined,
  descriptionText: listing.descriptionText,
  active: listing.active,
  dataset: listing.dataset ?? undefined,
  isOutlier: listing.isOutlier ?? undefined,
  type: listing.propertyType,
  propertyType: listing?.propertyType,
  apartmentType: listing?.apartmentType,
  photos: listing.photos?.length ? listing.photos : null,
  dataSource: {
    id: listing?.source?.id,
    name: listing?.source?.name,
    logoUrl: listing?.source?.logoUrl,
    url: listing?.source?.url,
  },
  brokerName: listing.brokerName,
  phones: listing.phones,
  brokersInfo: listing?.brokersInfo?.length
    ? responseBrokersInfoAdapter(listing?.brokersInfo)
    : null,
  floodZone: listing.parcel?.isFloodZone ?? false,
  parcel: listing.parcel ? responseParcelAdapter(listing.parcel) : undefined,
  city: listing.city
    ? {
        label: listing.city.name,
        value: listing.city.id,
      }
    : undefined,
  neighborhood: listing.neighborhood
    ? {
        label: listing.neighborhood.name,
        value: listing.neighborhood.id,
      }
    : undefined,
  subNeighborhood: listing.subNeighborhood
    ? {
        label: listing.subNeighborhood.name,
        value: listing.subNeighborhood.id,
      }
    : undefined,
  amenities: listing.listingAmenities
    ? responseListingAmenitiesAdapter(listing?.listingAmenities)
    : [],
  property: listing.property ? responsePropertyAdapter(listing.property) : null,
  propertyId: listing.propertyId,
  administrationFee: listing.administrationFee,
  luxuryScore: listing.luxuryScore ? luxuryScoreAdapter(listing.luxuryScore) : undefined,
  yearBuilt: listing.yearBuilt ?? undefined,
  jsonGPT: listing.jsonGPT
    ? {
        CAMFees: listing.jsonGPT.CAMFees,
        HOAFees: listing.jsonGPT.HOAFees,
        insuranceCost: listing.jsonGPT.insuranceCost,
        monthlyUtilities: listing.jsonGPT.monthlyUtilities,
        ceilingHeight: listing.jsonGPT.ceilingHeight,
        unitCeilingHeight: listing.jsonGPT.unitCeilingHeight,
        propertyCondition: listing.jsonGPT.propertyCondition
          ? Object.values(conditionScoreKey).includes(
              listing.jsonGPT.propertyCondition as conditionScoreKey,
            )
            ? (listing.jsonGPT.propertyCondition as conditionScoreKey)
            : null
          : null,
        buildingName: listing.jsonGPT.buildingName,
      }
    : undefined,
  inBuilding: listing.inBuilding,
  isDuplex: listing.isDuplex,
  isExterior: listing.isExterior,
  isNew: listing.isNew,
  isResidentialComplex: listing.isResidentialComplex,
  hasPublicServices: listing.hasPublicServices,
  administrationIncluded: listing.administrationIncluded,
  hasKidsPlayZone: listing.hasKidsPlayZone,
  hasReception: listing.hasReception,
  hasSoccerField: listing.hasSoccerField,
  officeArea: listing.officeArea,
  hasDeposit: listing.hasDeposit,
  hasGarage: listing.hasGarage,
  hasStorageSpace: listing.hasStorageSpace,
  attachments: listing?.attachments?.length
    ? responseListingAttachmentsAdapter(listing?.attachments, listing.createdAt)
    : null,
  featuresTracking: listing?.featuresTracking
    ? responseListingFeaturesTrackingAdapter(listing?.featuresTracking, listing)
    : null,
  sourceSpecificInfo: listing.sourceSpecificInfo,
  status: listing.status,
  transactions:
    listing.transactions !== undefined ? responseTransactionsAdapter(listing.transactions) : [],
});
