import React, { CSSProperties } from "react";
import { history } from "@/history";
import iconAtlasProfessional from "@/assets/img/logo/productsLogos/logo-atlas-professional.png";
import { ButtonLink, Card } from "../styledComponents";
import { useTranslation } from "react-i18next";
import LoginButtons from "./LoginButtons";
import { useAppDispatch } from "@/app/hooks";
import { userIsLogged } from "@/redux/actions";
import { appPaths } from "@/configs";
import { setCallToActionModalPricingsOpen } from "@/redux/slices";

type CallToActionAtlasProfessionalSmallProps = {
  topMessage?: string;
  showLoginButtons?: boolean;
  style?: CSSProperties;
  className?: string;
};

const CallToActionAtlasProfessionalSmall: React.FC<CallToActionAtlasProfessionalSmallProps> = ({
  topMessage,
  showLoginButtons,
  style,
  className,
}) => {
  const { t } = useTranslation(["corporateSite"]);
  const translation = t("corporateSite:home", { returnObjects: true });
  const dispatch = useAppDispatch();

  const onClickAtlasProfessionalIcon = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    // if (dispatch(userIsLogged())) {
    // history.push(appPaths.payment.path);
    dispatch(setCallToActionModalPricingsOpen({ callToActionModalPricingsOpen: true }));
    // } else {
    //   history.push(appPaths.signIn.path);
    // }
  };

  return (
    <Card
      onClick={(e) => onClickAtlasProfessionalIcon(e)}
      style={{ padding: "0.3rem", ...style }}
      className={`${className} d-flex justify-content-center flex-wrap border border-dark cursor-pointer`}>
      {topMessage && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginRight: "0.4rem" }}>
          <p className="black mb-0 text-center">{topMessage}</p>
        </div>
      )}

      <ButtonLink className="d-flex align-items-center justify-content-center" fontSize={14}>
        <img src={iconAtlasProfessional} alt="atlas-intelligence" width="25" className="mr-05" />
        <b className="fw-bold black mb-0">{translation.atlasProfessional}</b>
      </ButtonLink>
      {showLoginButtons && <LoginButtons />}
    </Card>
  );
};

export default CallToActionAtlasProfessionalSmall;
