import React, { useEffect, useRef, useState } from "react";
import { useLocaleCountryCode } from "@/hooks";
import { LanguageCodeKey } from "@/keys";
import { useGetNewsQuery } from "@/services";
import styled, { keyframes } from "styled-components";
import { useAppSelector } from "@/app/hooks";
import { history } from "@/history";
import { appPaths } from "@/configs";

const scrolling = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const MarqueeContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

const MarqueeWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  animation: ${scrolling} 95s linear infinite;
  animation-play-state: running; /* Asegura que la animación se esté ejecutando */

  &:hover {
    animation-play-state: paused;
  }
`;

const MarqueeItem = styled.span`
  display: inline-block;
  &:hover {
    color: gray;
  }
`;

const LatestNews = () => {
  const marqueeRef = useRef<HTMLDivElement>(null);
  const { languageCode, countryCode } = useLocaleCountryCode();

  const { data: news } = useGetNewsQuery({
    countryCode,
    active: true,
    count: 10,
    page: 0,
  });

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    if (marqueeElement) {
      const marqueeWidth = marqueeElement.offsetWidth;
      const wrapperWidth = marqueeElement.scrollWidth - marqueeWidth; // Restar el ancho del contenedor

      const animationDuration = wrapperWidth / 50; // Calcular la duración basada en el ancho total del contenido

      marqueeElement.style.setProperty("--marquee-animation-duration", `${animationDuration}s`);
    }
  }, [news]);

  return news?.length ? (
    <div className="d-flex align-items-center">
      <div>
        <MarqueeContainer>
          <MarqueeWrapper ref={marqueeRef}>
            {news.concat(news).map((data, index) => {
              return (
                <MarqueeItem key={index}>
                  <div className="d-flex align-items-center" style={{ whiteSpace: "nowrap" }}>
                    <div
                      className="cursor-pointer"
                      onClick={() => history.push(appPaths.news.path)}>
                      {languageCode === LanguageCodeKey.en ? data.title : data.esTitle}
                    </div>
                    <div className="px-1">|</div>
                  </div>
                </MarqueeItem>
              );
            })}
          </MarqueeWrapper>
        </MarqueeContainer>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LatestNews;
