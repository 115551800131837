import { SearchIdsBackend } from "@/adapters";
import { SearchIds } from "@/models";

export const responseSearchIdsAdapter = (searchIds: SearchIdsBackend): SearchIds => {
  const results: SearchIds = {
    listing: searchIds.listings?.map((listing) => listing._id),
    parcel: searchIds.parcels?.map((parcel) => parcel._id),
    property: searchIds.properties?.map((property) => property._id),
    transaction: searchIds.transactions?.map((transaction) => transaction._id),
    permit: searchIds.permits?.map((permit) => permit._id),
    tax: [],
    tenant: searchIds.tenants?.map((tenant) => tenant._id),
    documents: searchIds.documents?.map((document) => document._id),
  };
  return results;
};
