import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { TypeOfDrawing } from "@/keys";
export const defaultZoomPr = 10;

const defaultViewportPr = {
  ne: {
    lat: 19.189903382795336,
    lng: -64.9019185971221,
  },
  nw: {
    lat: 19.189903382795336,
    lng: -67.99731654634085,
  },
  se: {
    lat: 17.306704234757348,
    lng: -64.9019185971221,
  },
  sw: {
    lat: 17.306704234757348,
    lng: -67.99731654634085,
  },
};

interface BoundsProps {
  ne: {
    lat: number;
    lng: number;
  };
  nw: {
    lat: number;
    lng: number;
  };
  se: {
    lat: number;
    lng: number;
  };
  sw: {
    lat: number;
    lng: number;
  };
}

export enum TypeInfowindow {
  parcel = "parcel",
  listing = "listing",
}

// Define a type for the slice state
export type MapOptionsClusters = {
  center: { lat: number; lng: number };
  zoom:
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | number;
  bounds: BoundsProps;
};

export type InfowindowListing = { id: string; active: boolean };

export type Infowindow = {
  show: boolean;
  id: null | string;
  type: keyof typeof TypeInfowindow | null;
  countListings: number;
  listings: InfowindowListing[];
  parcelId?: string;
  activeListing?: boolean;
  listingWithParcel: boolean;
};
export enum MapThemeKeys {
  light = "light",
  dark = "dark",
  satellite = "satellite",
}
export type mapStyleType = {
  mapTypeId: string;
  theme: MapThemeKeys;
};
interface MapState {
  mapOptionsClusters: MapOptionsClusters;
  maxZoomClusters: number;

  // infowindow
  infowindow: Infowindow;

  drawingMode: TypeOfDrawing | null;
  userDrawnPolygon: boolean;
  deleteButtonWatcher: boolean;
  mapStyle: mapStyleType;
}

// Define the initial state using that type
const initialState: MapState = {
  mapOptionsClusters: {
    center: { lat: 18.250855183421933, lng: -66.44961757173148 },
    zoom: 10,
    bounds: defaultViewportPr,
  },
  maxZoomClusters: 15,

  // infowindow
  infowindow: {
    show: false,
    id: null,
    type: null,
    countListings: 0,
    listings: [],
    listingWithParcel: true,
  },

  drawingMode: null,
  userDrawnPolygon: false,
  deleteButtonWatcher: false,
  mapStyle: {
    mapTypeId: "roadmap",
    theme: MapThemeKeys.light,
  },
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setZoom: (
      state,
      action: PayloadAction<{
        zoom:
          | 4
          | 5
          | 6
          | 7
          | 8
          | 9
          | 10
          | 11
          | 12
          | 13
          | 14
          | 15
          | 16
          | 17
          | 18
          | 19
          | 20
          | 21
          | 22
          | number;
      }>,
    ) => {
      state.mapOptionsClusters.zoom = action.payload.zoom;
    },
    setCenter: (state, action: PayloadAction<{ center: { lat: number; lng: number } }>) => {
      state.mapOptionsClusters.center = action.payload.center;
    },
    setInfowindow: (
      state,
      action: PayloadAction<{
        show: boolean;
        id: string | null;
        type: keyof typeof TypeInfowindow | null;
        countListings?: number;
        listings?: InfowindowListing[];
        parcelId?: string;
        activeListing?: boolean;
        listingWithParcel?: boolean;
      }>,
    ) => {
      state.infowindow.show = action.payload.show;
      state.infowindow.type = action.payload.type;
      state.infowindow.id = action.payload.id;
      state.infowindow.countListings = action.payload.countListings || 0;
      state.infowindow.listings = action.payload.listings || [];
      state.infowindow.parcelId = action.payload.parcelId;
      state.infowindow.activeListing = action.payload.activeListing;
      state.infowindow.listingWithParcel = action.payload.listingWithParcel ?? true;
    },
    setDrawingMode: (
      state,
      action: PayloadAction<{
        drawingMode: TypeOfDrawing | null;
      }>,
    ) => {
      state.drawingMode = action.payload.drawingMode;
    },
    setUserDrawnPolygon: (
      state,
      action: PayloadAction<{
        userDrawnPolygon: boolean;
      }>,
    ) => {
      state.userDrawnPolygon = action.payload.userDrawnPolygon;
    },
    setDeleteButtonWatcher: (
      state,
      action: PayloadAction<{
        deleteButtonWatcher: boolean;
      }>,
    ) => {
      state.deleteButtonWatcher = action.payload.deleteButtonWatcher;
    },
    setMapStyle: (
      state,
      action: PayloadAction<{
        mapStyle: mapStyleType;
      }>,
    ) => {
      state.mapStyle = action.payload.mapStyle;
    },
  },
});

// These actions will dispatch in the app
export const {
  setZoom,
  setCenter,
  setInfowindow,
  setDrawingMode,
  setUserDrawnPolygon,
  setDeleteButtonWatcher,
  setMapStyle,
} = mapSlice.actions;

export const selectMap = (state: RootState) => state.map;

export default mapSlice.reducer;
