import { TaxBackend, responsePropertyAdapter } from "@/adapters";
import { Tax } from "@/models";

export const responseTaxAdapter = (tax: TaxBackend): Tax => ({
  id: tax.id,
  address: tax.address,
  land: tax.land ? tax.land : 0,
  taxable: tax.taxable ? tax.taxable : 0,
  exemption: tax.exemption ? tax.exemption : 0,
  machinery: tax.machinery ? tax.machinery : 0,
  structure: tax.structure ? tax.structure : 0,
  exoneration: tax.exoneration ? tax.exoneration : 0,
  totalValuation: tax.totalValuation ? tax.totalValuation : 0,
  property: tax.property ? responsePropertyAdapter(tax.property) : null,
});
