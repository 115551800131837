import { SectorKey, TransactionSectorKey } from "@/keys";
import { CategoricValueFilters } from "@/models";

export const reorderSectors = (sectors: CategoricValueFilters[]) => {
  const priorityList = [
    SectorKey.singleFamily,
    SectorKey.land,
    SectorKey.multiFamily,
    SectorKey.retail,
    SectorKey.industrial,
    SectorKey.publicService,
    SectorKey.office,
    SectorKey.recreation,
    SectorKey.lodgingAndHospitality,
    SectorKey.assemblyMeetingPlace,
  ];
  // Crear un mapa para almacenar los sectores por nombre
  const sectorMap = new Map();
  sectors.forEach((sector) => {
    sectorMap.set(sector.name, sector);
  });

  // Ordenar los sectores según la lista de prioridades
  const reorderedSectors = priorityList
    .map((sectorName) => {
      const sector = sectorMap.get(sectorName);
      sectorMap.delete(sectorName); // Eliminar el sector del mapa
      return sector;
    })
    .filter((sector) => sector); // Eliminar sectores indefinidos

  // Agregar cualquier sector restante que no esté en la lista de prioridades
  sectorMap.forEach((sector) => {
    reorderedSectors.push(sector);
  });

  return reorderedSectors;
};

export const reorderTransactionSectors = (sectors: CategoricValueFilters[]) => {
  const priorityList = [
    TransactionSectorKey.salePurchase,
    TransactionSectorKey.lease,
    TransactionSectorKey.mortgage,
    TransactionSectorKey.judicialSale,
    TransactionSectorKey.other,
  ];
  // Crear un mapa para almacenar los sectores por nombre
  const sectorMap = new Map();
  sectors.forEach((sector) => {
    sectorMap.set(sector.label?.toLowerCase(), sector);
  });

  // Ordenar los sectores según la lista de prioridades
  const reorderedSectors = priorityList
    .map((sectorName) => {
      const sector = sectorMap.get(sectorName);
      sectorMap.delete(sectorName); // Eliminar el sector del mapa
      return sector;
    })
    .filter((sector) => sector); // Eliminar sectores indefinidos

  // Agregar cualquier sector restante que no esté en la lista de prioridades
  sectorMap.forEach((sector) => {
    reorderedSectors.push(sector);
  });

  return reorderedSectors;
};
