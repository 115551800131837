import { SectorKey, StatisticsKey } from "@/keys";

export const mapperParcelSectorToStatisticsKey = ({ sector }: { sector: string }) => {
  return sector.toLowerCase() === SectorKey.land
    ? StatisticsKey.LOT
    : sector.toLowerCase() === SectorKey.multiFamily ||
      sector.toLowerCase() === SectorKey.singleFamily
    ? StatisticsKey.RESIDENTIAL
    : sector.toLowerCase() === SectorKey.retail
    ? StatisticsKey.BUSINESS
    : sector.toUpperCase();
};
