import {
  NewsBackend,
  responseAnalyticsAreaChartAdapter,
  responseAnalyticsColumnChartAdapter,
  responseListingsPricePerSqftStatisticsAdapter,
} from "@/adapters";
import { NewsCategoriesBackendKey, NewsCategoriesKey } from "@/keys";
import { News, NewsColumn, NewsSection } from "@/models";
import { responseAnalyticsPieChartAdapter } from "../analytics/responseAnalytics-pieChart.adapter";

export const responseNewsProfileAdapter = (news: NewsBackend): News => {
  const data: News = {
    id: news.id,
    title: news.title,
    esTitle: news.esTitle,
    category: news.category.map(
      (c) =>
        (Object.keys(NewsCategoriesBackendKey).find(
          (key) => c === NewsCategoriesBackendKey[key as keyof typeof NewsCategoriesBackendKey],
        ) as keyof typeof NewsCategoriesKey) ?? NewsCategoriesKey.all,
    ),
    date: news.date,
    link: news.link,
    description: news.description,
    esDescription: news.esDescription,
    sourceLogo: news.sourceLogo,
    country: {
      alpha2Code: news.country.alpha2Code,
    },
  };
  if (news.city) data.city = news.city;
  if (news.gptJSON) {
    const sections: NewsSection[] = [];
    news.gptJSON.sections?.map((section) => {
      const columns: NewsColumn[] = [];
      section.columns.map((column) => {
        const newColumn: NewsColumn = { columnKey: column.columnKey };
        if (column.title) {
          newColumn.title = {
            en: column.title.en,
            es: column.title.es,
          };
        }
        if (column.photo) {
          newColumn.photo = column.photo;
        }
        if (column.description) {
          newColumn.description = {
            en: column.description.en,
            es: column.description.es,
          };
        }
        if (column.statistics) {
          newColumn.statistics = {
            data: responseListingsPricePerSqftStatisticsAdapter(column.statistics.data),
            isMultiple: column.statistics.isMultiple,
            key: column.statistics.key,
          };
        }
        if (column.newListingsTable) {
          newColumn.newListingsTable = {
            data: column.newListingsTable.data,
          };
        }
        if (column.pieChart) {
          newColumn.pieChart = {
            data: responseAnalyticsPieChartAdapter({
              data: column.pieChart.data,
              key: column.pieChart.type,
            }),
            key: column.pieChart.type,
            title: {
              en: column.pieChart.title.en,
              es: column.pieChart.title.es,
            },
          };
        }
        if (column.columnChart) {
          newColumn.columnChart = {
            data: responseAnalyticsColumnChartAdapter(column.columnChart.data),
            key: column.columnChart.type,
            title: {
              en: column.columnChart.title.en,
              es: column.columnChart.title.es,
            },
          };
        }
        if (column.transactionAreaChartSpline) {
          newColumn.transactionAreaChartSpline = {
            data: responseAnalyticsColumnChartAdapter(column.transactionAreaChartSpline.data),
            key: column.transactionAreaChartSpline.type,
          };
        }
        if (column.carouselListingsCard) {
          newColumn.carouselListingsCard = {
            date: column.carouselListingsCard.date,
            isForRent: column.carouselListingsCard.isForRent,
          };
        }
        if (column.disclaimer) {
          newColumn.disclaimer = {
            title: column.disclaimer.title,
            description: column.disclaimer.description,
          };
        }
        columns.push(newColumn);
      });
      sections.push({ columns });
    });

    data.gptJSON = {
      version: news.gptJSON?.version,
      sections,
      ogImage: news.gptJSON?.ogImage,
    };
  }

  return data;
};
