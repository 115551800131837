import React from "react";
import { appPaths, getOrganization, relativeAppPaths } from "@/configs";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MenuUserInvestpr from "./menuUser/MenuUser.investpr";
import MenuUser from "./menuUser/MenuUser";
import { logout, userIsLogged, userIsLoggedIn } from "@/redux/actions";
import { LogIn } from "react-feather";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { History } from "history";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TypeInfowindow, selectMap, setInfowindow } from "@/redux/slices";
import { useTheme } from "styled-components";

type UserNavbarProps = {
  windowWidth: number;
  match: { path: string; id: string };
  history: History;
  clickBlocked: () => void;
};

function UserNavbar({ windowWidth, match, history, clickBlocked }: UserNavbarProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar", { returnObjects: true });
  const {
    common: { black },
  } = useTheme();

  const { infowindow } = useAppSelector(selectMap);

  const dispatch = useAppDispatch();

  const logoutUser = () => {
    dispatch(logout());
    if (
      match.path === relativeAppPaths().propertyProfile(match.id) ||
      match.path === relativeAppPaths().listingProfile(match.id)
    ) {
      dispatch(
        setInfowindow({
          show: true,
          id: match.id,
          type: TypeInfowindow.listing,
          countListings: infowindow.countListings,
          listings: infowindow.listings,
          activeListing: infowindow.activeListing,
        }),
      );
    }
    history.push(appPaths.home.path);
    toast.warn("You have successfully logged out", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return getOrganization().menu.menuUser.show ? (
    <li>
      <UncontrolledDropdown className="dropdown-list nav-item">
        {!getOrganization().auth.login ? (
          <MenuUserInvestpr />
        ) : dispatch(userIsLogged()) ? (
          <div>
            <MenuUser logout={logoutUser} />
          </div>
        ) : (
          <DropdownToggle
            tag="a"
            className="nav-link position-relative d-flex align-items-center p-0 h-40"
            style={{ margin: "1px 0" }}
            onClick={() =>
              dispatch(userIsLoggedIn()) ? null : history.push(appPaths.signIn.path)
            }>
            {getOrganization().auth.login ? (
              <div className="cursor-pointer d-flex align-items-center btn-header-navbar">
                {windowWidth <= 1236 ? null : (
                  <h6 className="mb-0 fw-bold black mr-1-custom">{language.menuUser.login}</h6>
                )}
                <LogIn color={black} size={18} />
              </div>
            ) : null}
          </DropdownToggle>
        )}
      </UncontrolledDropdown>
    </li>
  ) : null;
}

export default UserNavbar;
