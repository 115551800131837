import { appPaths, relativeAppPaths } from "@/configs";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import mapStyleDark from "@/assets/mapStyles/mapStyleDark.json";
import mapStyleLight from "@/assets/mapStyles/mapStyleLight.json";

// icons control
import BlackSunIcon from "@/assets/img/icons/blackSun.png";
import WhiteSunIcon from "@/assets/img/icons/whiteSun.png";
import BlackMoonIcon from "@/assets/img/icons/blackMoon.png";
import WhiteMoonIcon from "@/assets/img/icons/whiteMoon.png";
import BlackSatelliteIcon from "@/assets/img/icons/blackSatellite.png";
import WhiteSatelliteIcon from "@/assets/img/icons/whiteSatellite.png";

import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { CustomControlsMapIds } from "@/keys";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { MapThemeKeys, selectAppConfig, selectMap, setMapStyle } from "@/redux/slices";
import { sizeViewport } from "@/components/styledComponents";
import classNames from "classnames";
import { mapStyleType } from "@/redux/slices";

const NavbarLeft = () => {
  const match = {
    path: useLocation().pathname,
    id: useLocation().pathname.substring(useLocation().pathname.lastIndexOf("/") + 1),
  };

  const { t } = useTranslation("translation");
  const translation = t("map.controls", { returnObjects: true });
  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;
  const dispatch = useAppDispatch();

  type mapStylesOptionsType = {
    [key in CustomControlsMapIds]: mapStyleType;
  };
  const mapStylesOptions: mapStylesOptionsType = {
    [CustomControlsMapIds.LIGHT]: { mapTypeId: "roadmap", theme: MapThemeKeys.light },
    [CustomControlsMapIds.DARK]: { mapTypeId: "roadmap", theme: MapThemeKeys.dark },
    [CustomControlsMapIds.SATELLITE]: { mapTypeId: "hybrid", theme: MapThemeKeys.satellite },
  };

  const { mapStyle } = useAppSelector(selectMap);

  const isLight = mapStyle.mapTypeId === "roadmap" && mapStyle.theme === "light";
  const isDark = mapStyle.mapTypeId === "roadmap" && mapStyle.theme === "dark";
  const isSatellite = mapStyle.mapTypeId === "hybrid" && mapStyle.theme === "satellite";

  const handleClick = ({ id }: { id: CustomControlsMapIds }) => {
    // Este if es para hacer que el boton de Satellite funcione como toggle en mobile
    if (isMobile) {
      dispatch(
        setMapStyle({
          mapStyle: isLight ? mapStylesOptions[id] : mapStylesOptions[CustomControlsMapIds.LIGHT],
        }),
      );
      return;
    }
    dispatch(setMapStyle({ mapStyle: mapStylesOptions[id] }));
  };

  return (
    <div
      className={
        match.path === relativeAppPaths().list(match.id) ||
        match.path === appPaths.news.path ||
        match.path === relativeAppPaths().listingProfile(match.id) ||
        match.path === relativeAppPaths().propertyProfile(match.id) ||
        match.path === appPaths.profile.path ||
        match.path === appPaths.billing.path ||
        match.path === appPaths.business.path
          ? "d-none"
          : "d-block"
      }>
      <div className="card navbar-left" style={{ padding: "0.3rem" }}>
        <div
          id="mapControlLight"
          style={{ marginBottom: "0.3rem" }}
          className={classNames({ "d-none": isMobile })}>
          <img
            src={isLight ? WhiteSunIcon : BlackSunIcon}
            alt="Sun"
            id={CustomControlsMapIds.LIGHT}
            className={classNames("", { active: isLight })}
            onClick={() => handleClick({ id: CustomControlsMapIds.LIGHT })}
          />
        </div>
        <div
          id="mapControlDark"
          style={{ marginBottom: "0.3rem" }}
          className={classNames({ "d-none": isMobile })}>
          <img
            src={isDark ? WhiteMoonIcon : BlackMoonIcon}
            alt="Moon"
            id={CustomControlsMapIds.DARK}
            className={classNames("", { active: isDark })}
            onClick={() => handleClick({ id: CustomControlsMapIds.DARK })}
          />
        </div>
        <div id="mapControlSatellite">
          <img
            src={isSatellite ? WhiteSatelliteIcon : BlackSatelliteIcon}
            alt="Satellite"
            id={CustomControlsMapIds.SATELLITE}
            className={classNames("", { active: isSatellite })}
            onClick={() => handleClick({ id: CustomControlsMapIds.SATELLITE })}
          />
        </div>
        <UncontrolledTooltip placement="right" target="mapControlLight">
          {translation.light}
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="right" target="mapControlDark">
          {translation.dark}
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="right" target="mapControlSatellite">
          {translation.satellite}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default NavbarLeft;
