import React from "react";
import { useTheme } from "styled-components";
import { lighten } from "polished";
import { baseStyles } from "@/components/styledComponents";
import { Spinner } from "reactstrap";

function AnalyticsLoading() {
  const { primary, secondary } = useTheme();

  return (
    <div
      style={{
        height: "305.9px",
        width: "100%",
        backgroundColor: lighten(0.7, primary.color),
        borderRadius: baseStyles.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Spinner size="md" style={{ color: secondary.color }} />
    </div>
  );
}

export default AnalyticsLoading;
