import { ParcelEntityCountBackendKey } from "../backend/parcel-entity-count-backend.key";
import { PropertyEntityCountBackendKey } from "../backend/property-entity-count-backend.key copy";
import { CountryCodeBackendKey } from "../country-code.key";

export const useEndpoint = () => {
  // ----------------------- Listings -----------------------
  const listings = ({
    countryCode,
    isCount = false,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
  }): string => {
    return `/listings/get${isCount ? "/count" : ""}?code=${countryCode}`;
  };
  const listing = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/listings/get/${id}?code=${countryCode}`;
  };
  const listingReportData = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/report/data/listings/${id}?code=${countryCode}`;
  };
  const report = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string => {
    return `/brochure?code=${countryCode}`;
  };
  const similarListings = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/listings/get/${id}/similar?code=${countryCode}`;
  };
  const createListingExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/listings/export?code=${countryCode}`;

  // ----------------------- Properties -----------------------
  const properties = ({
    countryCode,
    isCount = false,
    entity,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
    entity?: PropertyEntityCountBackendKey;
  }): string => {
    return `/properties/get${isCount ? "/count" : ""}${
      entity ? `/entity` : ""
    }?code=${countryCode}${entity ? `&entity=${entity}` : ""}`;
  };

  // ----------------------- Parcels Lists -----------------------
  const lists = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/lists/?code=${countryCode}`;
  const createList = (): string => `/lists/`;
  const list = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/lists/get/${id}?code=${countryCode}`;
  };
  const listUpdateDelete = ({ id }: { id: string }): string => {
    return `/lists/${id}`;
  };
  const listsAvailability = ({
    id,
    entity,
    countryCode,
  }: {
    id: string;
    entity: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/lists/availability/${id}?entity=${entity}&code=${countryCode}`;
  };
  const listsMembers = ({ id }: { id: string }): string => {
    return `/lists/${id}/members/`;
  };
  const deleteListsMembers = ({ listId }: { listId: string }): string => {
    return `/lists/${listId}/members/`;
  };

  const listReportData = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/report/data/lists/${id}?code=${countryCode}`;
  };

  // ----------------------- Parcel -----------------------
  const parcels = ({
    countryCode,
    isCount = false,
    entity,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
    entity?: ParcelEntityCountBackendKey;
  }): string => {
    return `/parcels/get${isCount ? "/count" : ""}${entity ? `/entity` : ""}?code=${countryCode}${
      entity ? `&entity=${entity}` : ""
    }`;
  };

  const parcel = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/parcels/get/${id}?code=${countryCode}`;
  };

  const createParcelExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/parcels/export?code=${countryCode}`;

  // ----------------------- Property -----------------------
  const property = ({ id, countryCode }: { id: string; countryCode: CountryCodeBackendKey }) => {
    return `/properties/get/${id}?code=${countryCode}`;
  };

  const propertyPermits = (id: number | string): string => {
    return `/properties/${id}/permits/`;
  };
  const propertyNearBy = (id: number | string): string => {
    return `/properties/${id}/nearBy/`;
  };

  const createPropertyExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/properties/export?code=${countryCode}`;

  const unitReportData = ({
    id,
    countryCode,
  }: {
    id: string;
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/report/data/units/${id}?code=${countryCode}`;
  };

  // ----------------------- Transactions -----------------------
  const transactions = ({
    countryCode,
    isCount = false,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
  }): string => {
    return `/transactions/get${isCount ? "/count" : ""}?code=${countryCode}`;
  };
  const transaction = (id: number | string): string => {
    return `/transactions/${id}/`;
  };
  const createTransactionExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/transactions/export?code=${countryCode}`;

  // ----------------------- Tenants -----------------------
  const tenants = ({
    countryCode,
    isCount = false,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
  }): string => {
    return `/tenants/get${isCount ? "/count" : ""}?code=${countryCode}`;
  };
  const createTenantExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/tenants/export?code=${countryCode}`;

  // ----------------------- Permits -----------------------
  const permits = ({
    countryCode,
    isCount = false,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
  }): string => {
    return `/permits/get${isCount ? "/count" : ""}?code=${countryCode}`;
  };
  const createPermitExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/permits/export?code=${countryCode}`;

  // ----------------------- Taxes -----------------------
  const taxes = ({
    countryCode,
    isCount = false,
  }: {
    countryCode: CountryCodeBackendKey;
    isCount?: boolean;
  }): string => {
    return `/taxes/get${isCount ? "/count" : ""}?code=${countryCode}`;
  };
  const createTaxExportFile = ({ countryCode }: { countryCode: CountryCodeBackendKey }) =>
    `/taxes/export?code=${countryCode}`;

  // ----------------------- Auth -----------------------
  const login = (): string => "/auth/login/";
  const register = (): string => "/auth/register/";
  const recoverPassword = (): string => "/password/recover/";
  const restorePassword = (): string => "/password/restore/";
  const emailVerification = (): string => "/verification/";
  const emailVerificationResend = (): string => "/verification/resend/";

  // ----------------------- Profile -----------------------
  const profile = (): string => "/profile/";
  const profilePassword = (): string => "/profile/password/";
  const profileAvatar = (): string => "/profile/avatar/";

  // ----------------------- Organizations -----------------------
  const organizations = (): string => "/organizations/";
  const organization = ({ id }: { id: string }): string => `/organizations/${id}`;
  const organizationsAvatar = (): string => "/organizations/avatar/";
  const organizationsMembers = (): string => "/organizations/members/";
  const organizationsMember = ({ id }: { id: string }): string => `/organizations/members/${id}`;
  const organizationsMemberResendEmail = ({ id }: { id: string }): string =>
    `/organizations/members/${id}/resend-email`;

  // ----------------------- Subscription -----------------------
  const subscription = (): string => "/subscription/";
  const paymentHistory = (): string => "/subscription/paymentHistory/";
  const subscriptionCoupon = (): string => `/subscription/checkCoupon/`;
  const subscriptionStripe = (): string => "/subscription/stripe/";
  const subscriptionCard = (): string => "/subscription/card/";
  const subscriptionActive = (): string => "/subscription/active/";
  const subscriptionPlans = (): string => "/subscription/plans/";

  // ----------------------- News -----------------------
  const news = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/news?code=${countryCode}`;
  const newsProfile = ({
    countryCode,
    id,
  }: {
    countryCode: CountryCodeBackendKey;
    id: string;
  }): string => `/news/${id}?code=${countryCode}`;
  const newsCount = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/news/count?code=${countryCode}`;
  const newsCategories = (): string => `/news/categories/`;
  const statistics = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/statistics?code=${countryCode}`;
  const newsNotifications = (): string => `/news/notifications/`;

  // ----------------------- Locations -----------------------
  const location = (): string => "/location/";
  const country = (): string => `${location()}country/`;
  const cities = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `${location()}city?code=${countryCode}`;
  const city = ({
    countryCode,
    cityId,
  }: {
    countryCode: CountryCodeBackendKey;
    cityId: string;
  }): string => `${location()}city/${cityId}?code=${countryCode}`;
  const neighborhood = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `${location()}neighborhood?code=${countryCode}`;
  const subNeighborhood = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `${location()}subneighborhood?code=${countryCode}`;
  const zipCode = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `${location()}zipCode?code=${countryCode}`;
  const building = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `${location()}building?code=${countryCode}`;
  const localize = (): string => "/localize";

  // ----------------------- Filters -----------------------
  const listingsFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/listings/get/filters?code=${countryCode}`;
  const parcelsFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/parcels/get/filters?code=${countryCode}`;
  const transactionsFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/transactions/get/filters?code=${countryCode}`;
  const propertiesFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/properties/get/filters?code=${countryCode}`;
  const tenantsFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/tenants/get/filters?code=${countryCode}`;
  const taxesFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/taxes/get/filters?code=${countryCode}`;
  const permitsFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/permits/get/filters?code=${countryCode}`;
  const amenitiesFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/amenities/get/filters?code=${countryCode}`;
  const amenities = (): string => `/amenities`;
  const synonymAmenities = (): string => `/amenities/synonym-amenities`;
  const categoricFilters = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/filters/categoric-values?code=${countryCode}`;

  // ----------------------- CorporateSite -----------------------
  const contact = (): string => "/contact";

  // ----------------------- Analytics -----------------------
  const analyticsListingsTypes = ({
    countryCode,
  }: {
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/analytics/listings/types?code=${countryCode}`;
  };
  const analyticsListings = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string => {
    return `/analytics/listings?code=${countryCode}`;
  };
  const analyticsListingsDaysOnMarket = ({
    countryCode,
  }: {
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/analytics/listings/days-on-market?code=${countryCode}`;
  };
  const analyticsParcelsSectors = ({
    countryCode,
  }: {
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/analytics/parcels/sectors?code=${countryCode}`;
  };
  const analyticsParcels = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string => {
    return `/analytics/parcels?code=${countryCode}`;
  };
  const analyticsTransactions = ({
    countryCode,
  }: {
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/analytics/transactions?code=${countryCode}`;
  };

  // ----------------------- Valuations -----------------------
  const citiesAvailableValuations = (): string => `/valuations/query-metro-areas/`;
  const valuationFeatures = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/valuations/get/features?code=${countryCode}`;

  // ----------------------- Data Source -----------------------
  const dataSources = (): string => `/data-sources/get/`;

  // ----------------------- Others -----------------------
  const getSignedUrl = (): string => "/S3/getSignedUrl/";
  const search = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string => {
    return `/text-search?code=${countryCode}`;
  };
  const textSearchNaturalLanguage = ({
    countryCode,
  }: {
    countryCode: CountryCodeBackendKey;
  }): string => {
    return `/text-search/nl?code=${countryCode}`;
  };
  const elasticSearch = ({ countryCode }: { countryCode: CountryCodeBackendKey }): string =>
    `/elastic-search/search?code=${countryCode}`;

  const listingsEmbeddedValuationUrl = (): string => "/listings/embedded-valuation-url/"; //AVM (no se usa, se quito de back)

  return {
    property,
    listings,
    similarListings,
    permits,
    parcels,
    properties,
    lists,
    createList,
    transactions,
    taxes,
    profile,
    profilePassword,
    profileAvatar,
    organization,
    organizations,
    organizationsAvatar,
    organizationsMembers,
    organizationsMember,
    organizationsMemberResendEmail,
    login,
    register,
    recoverPassword,
    restorePassword,
    emailVerification,
    emailVerificationResend,
    tenants,
    news,
    newsProfile,
    newsCount,
    newsCategories,
    newsNotifications,
    country,
    cities,
    city,
    neighborhood,
    subNeighborhood,
    zipCode,
    building,
    localize,
    statistics,
    getSignedUrl,
    subscriptionCoupon,
    subscriptionStripe,
    subscription,
    paymentHistory,
    subscriptionCard,
    subscriptionActive,
    subscriptionPlans,
    listingsEmbeddedValuationUrl,
    listingReportData,
    unitReportData,
    report,
    transaction,
    propertyNearBy,
    propertyPermits,
    parcel,
    listing,
    list,
    listUpdateDelete,
    listsAvailability,
    listsMembers,
    deleteListsMembers,
    listReportData,
    listingsFilters,
    parcelsFilters,
    transactionsFilters,
    propertiesFilters,
    tenantsFilters,
    taxesFilters,
    permitsFilters,
    amenitiesFilters,
    amenities,
    synonymAmenities,
    categoricFilters,
    search,
    textSearchNaturalLanguage,
    elasticSearch,
    contact,
    analyticsListingsTypes,
    analyticsListingsDaysOnMarket,
    analyticsListings,
    analyticsParcelsSectors,
    analyticsParcels,
    analyticsTransactions,
    citiesAvailableValuations,
    valuationFeatures,
    dataSources,
    createParcelExportFile,
    createListingExportFile,
    createTransactionExportFile,
    createTenantExportFile,
    createPermitExportFile,
    createTaxExportFile,
    createPropertyExportFile,
  };
};
