import { Pill, sizeViewport } from "@/components/styledComponents";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { useAppSelector } from "@/app/hooks";
import { selectAppConfig } from "@/redux/slices";
import { useCategoryTranslations } from "@/hooks";
import { GroupedSuggestion } from "../SearchNavbar";
import { NumberFormat } from "@/utilities";

type ParcelSuggestionProps = {
  item: GroupedSuggestion;
  isFetchingListingCounts: boolean;
  listingCount?: number;
  isFetchingPermitCounts: boolean;
  permitCount?: number;
  isFetchingTransactionCounts: boolean;
  transactionCount?: number;
};

const ParcelSuggestion: FC<ParcelSuggestionProps> = ({
  item: { id, title, govtId, img, address, sector },
  isFetchingListingCounts,
  listingCount,
  isFetchingPermitCounts,
  permitCount,
  isFetchingTransactionCounts,
  transactionCount,
}) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const languageInfowindow = t("map.infoWindow", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { defaultColors, primary, listingColors, parcelColors, transactionColors } = useTheme();
  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;
  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": img,
      })}>
      <div className="item-container d-flex w-100 align-items-center">
        {img ? (
          <img
            className="img-list mt-25"
            src={img}
            height="60"
            width="60"
            alt={title ?? undefined}
          />
        ) : null}
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          <div className="w-100">
            <h6
              id={`nameResultAddress-${id}`}
              className="align-middle mb-0 fw-bold black"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
              }}>
              {address}
            </h6>
            <UncontrolledTooltip placement="bottom" target={`nameResultAddress-${id}`}>
              {address}
            </UncontrolledTooltip>
            {govtId ? (
              <div className="mt-05">
                <small className="fw-bold">{`${language.results.parcels.govtId}: `}</small>
                <small>{govtId}</small>
              </div>
            ) : null}
            <div
              className={classNames("d-flex flex-wrap", {
                "flex-column": isMobile,
                "align-items-left": isMobile,
                "align-items-center": !isMobile,
              })}>
              <Pill
                className="mr-1 cursor-pointer mt-05"
                id="Sector"
                style={{ width: "fit-content" }}
                color={defaultColors.background}
                backgroundColor={sectorColorsMapper({
                  sectorName: sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}
                borderColor={sectorColorsMapper({
                  sectorName: sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}>
                <p className="mb-0">{sector?.name ? languageSector(sector.name) : "---"}</p>
              </Pill>

              {listingCount ? (
                <div className="d-flex align-items-center mr-1 mt-05">
                  <div
                    className="sectorMarker"
                    style={{
                      backgroundColor: listingColors.status.multiple,
                      marginRight: "4px",
                    }}
                  />
                  <span>
                    {NumberFormat({
                      value: listingCount,
                      suffix: ` ${t("map.infoWindow.listingsCount.title", {
                        count: listingCount,
                      })}`,
                    })}
                  </span>
                </div>
              ) : null}
              {permitCount ? (
                <div className="d-flex align-items-center mr-1 mt-05">
                  <div
                    className="sectorMarker"
                    style={{
                      backgroundColor: primary.color,
                      marginRight: "4px",
                    }}
                  />
                  <span>
                    {NumberFormat({
                      value: permitCount,
                      suffix: ` ${t("map.infoWindow.permitsCount.title", {
                        count: permitCount,
                      })}`,
                    })}
                  </span>
                </div>
              ) : null}
              {transactionCount ? (
                <div className="d-flex align-items-center mt-05">
                  <div
                    className="sectorMarker"
                    style={{
                      backgroundColor: transactionColors.count,
                      marginRight: "4px",
                    }}
                  />
                  <span>
                    {NumberFormat({
                      value: transactionCount,
                      suffix: ` ${t("map.infoWindow.transactionCount.title", {
                        count: transactionCount,
                      })}`,
                    })}
                  </span>
                </div>
              ) : null}

              {isFetchingListingCounts || isFetchingPermitCounts || isFetchingTransactionCounts ? (
                <div className="d-flex align-items-center mt-05">
                  <Spinner
                    size="sm"
                    className="mx-05"
                    style={{ width: "14px", height: "14px", color: primary.color }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParcelSuggestion;
