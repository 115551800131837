import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CategoricFilters, FiltersType, FilterValue, GeometricFilter } from "@/models";
import { RootState } from "@/app/store";
import {
  NewsFiltersFrontKey,
  NewsFiltersEntityFrontKey,
  PortalKey,
  NewsFiltersTypeFrontKey,
  CategoricFiltersKey,
} from "@/keys";
import { defaultFilterDropdownlists } from "@/configs";

export interface Filters {
  [NewsFiltersEntityFrontKey.location]: FiltersType;
  [NewsFiltersEntityFrontKey.listing]: FiltersType;
  [NewsFiltersEntityFrontKey.parcel]: FiltersType;
  [NewsFiltersEntityFrontKey.transaction]: FiltersType;
  [NewsFiltersEntityFrontKey.property]: FiltersType;
  [NewsFiltersEntityFrontKey.tax]: FiltersType;
  [NewsFiltersEntityFrontKey.tenant]: FiltersType;
  [NewsFiltersEntityFrontKey.permit]: FiltersType;
  [NewsFiltersEntityFrontKey.listingAmenity]: FiltersType;
}

const defaultFiltersState: Filters = {
  [NewsFiltersEntityFrontKey.location]: {
    categoric: {
      [NewsFiltersFrontKey.address]: { value: null },
      [NewsFiltersFrontKey.cityId]: { value: null },
      [NewsFiltersFrontKey.neighborhoodId]: { value: null },
      [NewsFiltersFrontKey.subNeighborhoodId]: { value: null },
      [NewsFiltersFrontKey.zipCodeId]: { value: null },
    },
    geometric: {
      [NewsFiltersFrontKey.viewport]: {
        value: null,
      },
      [NewsFiltersFrontKey.coordinates]: {
        value: null,
      },
      [NewsFiltersFrontKey.drawnPolygon]: {
        value: null,
      },
    },
  },
  [NewsFiltersEntityFrontKey.listing]: {},
  [NewsFiltersEntityFrontKey.parcel]: {},
  [NewsFiltersEntityFrontKey.transaction]: {},
  [NewsFiltersEntityFrontKey.property]: {},
  [NewsFiltersEntityFrontKey.tax]: {},
  [NewsFiltersEntityFrontKey.tenant]: {},
  [NewsFiltersEntityFrontKey.permit]: {},
  [NewsFiltersEntityFrontKey.listingAmenity]: {
    categoric: {
      [NewsFiltersFrontKey.amenity]: { value: null },
      [NewsFiltersFrontKey.amenityRoom]: { value: null },
      [NewsFiltersFrontKey.amenityView]: { value: null },
    },
  },
};

const typeFilters: TypeFilters = {
  filters: defaultFiltersState,
  appliedFilters: defaultFiltersState,
  categoricFilters: {
    [CategoricFiltersKey.propertyType]: [],
    [CategoricFiltersKey.apartmentType]: [],
    [CategoricFiltersKey.status]: [],
    [CategoricFiltersKey.sectors]: [],
    [CategoricFiltersKey.subsectors]: [],
    [CategoricFiltersKey.microsectors]: [],
    [CategoricFiltersKey.amenities]: [],
    [CategoricFiltersKey.amenitiesRooms]: [],
    [CategoricFiltersKey.amenitiesViews]: [],
    [CategoricFiltersKey.amenitiesTop10]: [],
    [CategoricFiltersKey.permitSectors]: [],
    [CategoricFiltersKey.permitSubsectors]: [],
    [CategoricFiltersKey.permitCategories]: [],
    [CategoricFiltersKey.permitStatuses]: [],
    [CategoricFiltersKey.unitTypes]: [],
    [CategoricFiltersKey.transactionSectors]: [],
    [CategoricFiltersKey.transactionSubsectors]: [],
    [CategoricFiltersKey.transactionMicrosectors]: [],
  },
};

interface FiltersState {
  dropdownToggle: boolean;
  filtersByPortal: {
    [PortalKey.professional]: TypeFilters;
    [PortalKey.commercial]: TypeFilters;
    [PortalKey.residential]: TypeFilters;
    [PortalKey.investpr]: TypeFilters;
  };
  areFiltersAvailable: boolean;
  refreshFilters: boolean;
}

interface TypeFilters {
  filters: Filters;
  appliedFilters: Filters;
  categoricFilters: CategoricFilters;
}

// Define the initial state using that type
export const initialFiltersState: FiltersState = {
  dropdownToggle: false,
  filtersByPortal: {
    [PortalKey.professional]: typeFilters,
    [PortalKey.commercial]: typeFilters,
    [PortalKey.residential]: typeFilters,
    [PortalKey.investpr]: typeFilters,
  },
  areFiltersAvailable: false,
  refreshFilters: false,
};

export type OnlyKeyPortal = keyof typeof PortalKey;
export type OnlyKeyEntityFilter = keyof typeof NewsFiltersEntityFrontKey;
export type OnlyKeyTypeFilter = keyof typeof NewsFiltersTypeFrontKey;
export type OnlyKeyFilterFrontKey = keyof typeof NewsFiltersFrontKey;
let portalKey: keyof typeof PortalKey;
export let entityKey: keyof typeof NewsFiltersEntityFrontKey;
export let typeFilterKey: keyof typeof NewsFiltersTypeFrontKey;
export let filterKey: keyof typeof NewsFiltersFrontKey;

let categoricFilter: keyof typeof CategoricFiltersKey;

export const filtersSlice = createSlice({
  name: "filters",
  initialState: initialFiltersState,
  reducers: {
    setDropdownToggle: (state, action: PayloadAction<boolean>) => {
      state.dropdownToggle = action.payload;
    },
    setEntityFilters: (
      state,
      action: PayloadAction<{
        filters: FiltersType;
        entity: Exclude<NewsFiltersEntityFrontKey, NewsFiltersEntityFrontKey.location>;
      }>,
    ) => {
      const { filters, entity } = action.payload;
      let portalKey: keyof typeof PortalKey;
      if (entity === NewsFiltersEntityFrontKey.listingAmenity) {
        for (portalKey in state.filtersByPortal) {
          filters.categoric = {
            ...filters.categoric,
            ...state.filtersByPortal[portalKey].filters[entity].categoric,
          };
          state.filtersByPortal[portalKey].filters[entity] = filters;
        }
      } else {
        for (portalKey in state.filtersByPortal) {
          state.filtersByPortal[portalKey].filters[entity] = filters;
        }
      }
    },
    setAvailableFilters: (state, action: PayloadAction<boolean>) => {
      state.areFiltersAvailable = action.payload;
    },
    updateAppliedFilters: (state, action: PayloadAction<{ portal: OnlyKeyPortal }>) => {
      const { portal } = action.payload;

      for (entityKey in state.filtersByPortal[portal].filters) {
        for (typeFilterKey in state.filtersByPortal[portal].filters[entityKey]) {
          if (typeFilterKey === NewsFiltersTypeFrontKey.categoric) {
            const categories = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in categories) {
              if (categories[index].value) {
                categories[index].appliedValue = categories[index].value;
              } else {
                delete categories[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = categories;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
            const booleans = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in booleans) {
              if (index === NewsFiltersFrontKey.isFloodZone) {
                if (booleans[index].value) {
                  booleans[NewsFiltersFrontKey.isFloodZone].appliedValue =
                    booleans[NewsFiltersFrontKey.isFloodZone].value;
                } else {
                  delete booleans[NewsFiltersFrontKey.isFloodZone].appliedValue;
                }
              } else if (booleans[index].value) {
                booleans[index].appliedValue = booleans[index].value;
              } else {
                delete booleans[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = booleans;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
            const dates = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in dates) {
              if (dates[index].value.min || dates[index].value.max) {
                dates[index].appliedValue = dates[index].value;
              } else {
                delete dates[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = dates;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
            const numerics = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in numerics) {
              if (numerics[index].value.min || numerics[index].value.max) {
                numerics[index].appliedValue = numerics[index].value;
              } else {
                delete numerics[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = numerics;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.search) {
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              if (strings[index].value) {
                strings[index].appliedValue = strings[index].value;
              } else {
                delete strings[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = strings;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.geometric) {
            // este solo se esta usando para buscar por lat & lng
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              // por eso no aplico el viewport
              if (index !== NewsFiltersFrontKey.viewport) {
                if (strings[index].value) {
                  strings[index].appliedValue = strings[index].value;
                } else {
                  delete strings[index].appliedValue;
                }
              }
            }
            state.filtersByPortal[portal].appliedFilters[entityKey][typeFilterKey] = strings;
          }
        }
      }
    },
    setValueFilter: (
      state,
      action: PayloadAction<{
        portal: OnlyKeyPortal;
        entity: OnlyKeyEntityFilter;
        filterTypeValue:
          | Pick<FiltersType, "boolean">
          | Pick<FiltersType, "numeric">
          | Pick<FiltersType, "geometric">
          | Pick<FiltersType, "categoric">
          | Pick<FiltersType, "date">
          | Pick<FiltersType, "search">;
      }>,
    ) => {
      const { portal, entity, filterTypeValue } = action.payload;
      state.filtersByPortal[portal].filters[entity] = {
        ...state.filtersByPortal[portal].filters[entity],
        ...filterTypeValue,
      };
      // filtersSlice.caseReducers.setAreFiltersApplied(state, {
      //   type: "setAreFiltersApplied",
      //   payload: false,
      // });
    },
    setAllValuesFilter: (
      state,
      action: PayloadAction<{
        portal: OnlyKeyPortal;
        filters: Filters;
      }>,
    ) => {
      const { portal, filters } = action.payload;
      state.filtersByPortal[portal].filters = filters;
    },
    setGeometicFilterTypeAllPortals: (
      state,
      action: PayloadAction<{
        entity: OnlyKeyEntityFilter;
        type: OnlyKeyTypeFilter;
        filterValue: Record<string, FilterValue<GeometricFilter>>;
      }>,
    ) => {
      const { entity, type, filterValue } = action.payload;

      for (portalKey in state.filtersByPortal) {
        for (typeFilterKey in state.filtersByPortal[portalKey].filters[entity]) {
          if (type === NewsFiltersTypeFrontKey.geometric) {
            state.filtersByPortal[portalKey].filters[entity][type] = {
              ...state.filtersByPortal[portalKey].filters[entity][type],
              ...filterValue,
            };
          }
        }
      }

      for (portalKey in state.filtersByPortal) {
        for (typeFilterKey in state.filtersByPortal[portalKey].appliedFilters[entity]) {
          if (type === NewsFiltersTypeFrontKey.geometric) {
            state.filtersByPortal[portalKey].appliedFilters[entity][type] = {
              ...state.filtersByPortal[portalKey].appliedFilters[entity][type],
              ...filterValue,
            };
          }
        }
      }
    },
    setAppliedFilters: (
      state,
      action: PayloadAction<{
        portal: OnlyKeyPortal;
      }>,
    ) => {
      const { portal } = action.payload;

      for (entityKey in state.filtersByPortal[portal].filters) {
        for (typeFilterKey in state.filtersByPortal[portal].filters[entityKey]) {
          if (typeFilterKey === NewsFiltersTypeFrontKey.categoric) {
            const categories = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in categories) {
              if (categories[index].value) {
                categories[index].appliedValue = categories[index].value;
              } else {
                delete categories[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = categories;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
            const booleans = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in booleans) {
              if (index === NewsFiltersFrontKey.isFloodZone) {
                if (booleans[index].value) {
                  booleans[NewsFiltersFrontKey.isFloodZone].appliedValue =
                    booleans[NewsFiltersFrontKey.isFloodZone].value;
                } else {
                  delete booleans[NewsFiltersFrontKey.isFloodZone].appliedValue;
                }
              } else if (booleans[index].value) {
                booleans[index].appliedValue = booleans[index].value;
              } else {
                delete booleans[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = booleans;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
            const dates = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in dates) {
              if (dates[index].value.min || dates[index].value.max) {
                dates[index].appliedValue = dates[index].value;
              } else {
                delete dates[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = dates;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
            const numerics = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in numerics) {
              if (numerics[index].value.min || numerics[index].value.max) {
                numerics[index].appliedValue = numerics[index].value;
              } else {
                delete numerics[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = numerics;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.search) {
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              if (strings[index].value) {
                strings[index].appliedValue = strings[index].value;
              } else {
                delete strings[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = strings;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.geometric) {
            // este solo se esta usando para buscar por lat & lng
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              // por eso no aplico el viewport
              if (index !== NewsFiltersFrontKey.viewport) {
                if (strings[index].value) {
                  strings[index].appliedValue = strings[index].value;
                } else {
                  delete strings[index].appliedValue;
                }
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = strings;
          }
        }
      }

      filtersSlice.caseReducers.updateAppliedFilters(state, {
        type: "updateAppliedFilters",
        payload: { portal },
      });
    },
    resetFiltersByPortal: (
      state,
      action: PayloadAction<{
        portal: OnlyKeyPortal;
      }>,
    ) => {
      const { portal } = action.payload;

      for (entityKey in state.filtersByPortal[portal].filters) {
        for (typeFilterKey in state.filtersByPortal[portal].filters[entityKey]) {
          if (typeFilterKey === NewsFiltersTypeFrontKey.categoric) {
            const categories = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in categories) {
              categories[index].value = null;
              delete categories[index].appliedValue;
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = categories;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
            const booleans = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in booleans) {
              booleans[index].value = false;
              delete booleans[index].appliedValue;
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = booleans;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
            const dates = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in dates) {
              dates[index].value.max = null;
              dates[index].value.min = null;
              delete dates[index].appliedValue;
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = dates;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
            const numerics = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in numerics) {
              numerics[index].value.max = "";
              numerics[index].value.min = "";
              delete numerics[index].appliedValue;
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = numerics;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.search) {
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              strings[index].value = "";
              delete strings[index].appliedValue;
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = strings;
          }
          if (typeFilterKey === NewsFiltersTypeFrontKey.geometric) {
            const strings = state.filtersByPortal[portal].filters[entityKey][typeFilterKey];
            for (const index in strings) {
              if (index !== NewsFiltersFrontKey.viewport) {
                // No elimino nunca los datos de viewport
                strings[index].value = null;
                delete strings[index].appliedValue;
              }
            }
            state.filtersByPortal[portal].filters[entityKey][typeFilterKey] = strings;
          }
        }
      }

      filtersSlice.caseReducers.updateAppliedFilters(state, {
        type: "updateAppliedFilters",
        payload: { portal },
      });
    },
    setCategoricFiltersAllPortals: (
      state,
      action: PayloadAction<{
        categorics: CategoricFilters;
      }>,
    ) => {
      const { categorics } = action.payload;

      for (portalKey in state.filtersByPortal) {
        let parseCategories: CategoricFilters = categorics;

        for (categoricFilter in defaultFilterDropdownlists[portalKey]) {
          if (defaultFilterDropdownlists[portalKey][categoricFilter].length) {
            parseCategories = {
              ...parseCategories,
              [categoricFilter]: defaultFilterDropdownlists[portalKey][categoricFilter],
            };
          } else {
            parseCategories = {
              ...parseCategories,
              [categoricFilter]: categorics[categoricFilter],
            };
          }
        }
        state.filtersByPortal[portalKey].categoricFilters = parseCategories;
      }
    },
    setRefreshFilters: (state, action: PayloadAction<boolean>) => {
      state.refreshFilters = action.payload;
    },
  },
});

// These actions will dispatch in the app
export const {
  setDropdownToggle,
  setEntityFilters,
  setValueFilter,
  setAllValuesFilter,
  setGeometicFilterTypeAllPortals,
  setAppliedFilters,
  resetFiltersByPortal,
  setCategoricFiltersAllPortals,
  setAvailableFilters,
  updateAppliedFilters,
  setRefreshFilters,
} = filtersSlice.actions;

export const selectFilters = (state: RootState) => state.newFilters;

export default filtersSlice.reducer;
