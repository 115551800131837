import type { Transaction } from "@/models";
import { TransactionBackend, responseTransactionAdapter } from "@/adapters";

export const responseTransactionsAdapter = (transactions: TransactionBackend[]): Transaction[] => {
  const newTransactions: Transaction[] = [];
  if (transactions.length) {
    transactions.map((transaction) => {
      newTransactions.push(responseTransactionAdapter(transaction));
    });
  }

  return newTransactions;
};
