import { useCategoryTranslations, useConvertUnits } from "@/hooks";
import { NumberFormat } from "@/utilities";
import { Pill } from "@/components/styledComponents";
import { Parcel } from "@/models";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useTheme } from "styled-components";
import { GroupedSuggestion } from "../SearchNavbar";

type TransactionSuggestionProps = {
  item: GroupedSuggestion;
  parcel?: Parcel;
};

const TransactionSuggestion: FC<TransactionSuggestionProps> = ({
  item: { id, address, img, type, transactionType, transactionPrice, pricePerBuiltSqm },
  parcel,
}) => {
  const { t } = useTranslation(["translation"]);
  const language = t("translation:navbar.filters.search", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { parcelColors, defaultColors } = useTheme();
  const { convertPricePerBuiltArea, languagePerBuiltAreaUnits } = useConvertUnits();
  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": !!img,
      })}>
      <div className="item-container d-flex w-100">
        <img
          className="img-list mt-25"
          src={parcel?.photos?.[0] ?? img}
          height="60"
          width="60"
          alt={address ?? undefined}
        />
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}>
          <div className="w-100">
            <h6
              className="align-middle mb-0 fw-bold black"
              id={`nameResultAddress-${id}`}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
              }}>
              {address}
            </h6>
            <UncontrolledTooltip placement="bottom" target={`nameResultAddress-${id}`}>
              {address}
            </UncontrolledTooltip>

            <div className={classNames("d-flex mt-05 flex-column")}>
              {transactionType ? (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "386px",
                  }}>
                  <small className="fw-bold">{`${language.results.transactions.transactionType}: `}</small>
                  <small>{transactionType}</small>
                </div>
              ) : null}
              {type ? (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "386px",
                  }}>
                  <small className="fw-bold">{`${language.results.documents.type}: `}</small>
                  <small>{type}</small>
                </div>
              ) : null}
              <Pill
                className="text-truncate mr-1 mt-05"
                color={defaultColors.background}
                style={{ width: "fit-content" }}
                backgroundColor={sectorColorsMapper({
                  sectorName: parcel?.sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}
                borderColor={sectorColorsMapper({
                  sectorName: parcel?.sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}>
                <p className="mb-0 text-overflow-ellipsis" style={{ marginTop: "2px" }}>
                  {parcel?.sector?.name ? languageSector(parcel.sector.name) : "---"}
                </p>
              </Pill>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end ml-1">
          {transactionPrice ? (
            <div className="mt-05">
              <h4 className="fw-bold black" style={{ whiteSpace: "nowrap" }}>
                {transactionPrice}
              </h4>
            </div>
          ) : null}
          {pricePerBuiltSqm ? (
            <div className="d-flex">
              <small className="fw-bold black mr-04" style={{ whiteSpace: "nowrap" }}>
                {NumberFormat({
                  value: convertPricePerBuiltArea({
                    value: pricePerBuiltSqm,
                  }),
                  prefix: "$ ",
                  suffix: "",
                  decimalScale: 2,
                })}
              </small>
              <small
                className="fw-bold"
                style={{ whiteSpace: "nowrap" }}>{`${languagePerBuiltAreaUnits}`}</small>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TransactionSuggestion;
