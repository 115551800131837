import { Libraries } from "@react-google-maps/api";

export const mapContainerStyle: React.CSSProperties = {
  width: "100vw",
  height: "100vh",
};

import mapStyleLight from "@/assets/mapStyles/mapStyleLight.json";
import { CountryCodeKey } from "@/keys";
import { countries } from "@/configs";

export const defaultMapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  styles: mapStyleLight,
  scaleControl: false,
  fullscreenControl: false,
  rotateControl: false,
  gestureHandling: "greedy",
  mapTypeControl: false,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
  },
  center: {
    lat: 18.250855183421933,
    lng: -65.74961757173148,
  },
  zoom: 9,
  tilt: 0,
};

export const libraries: Libraries = ["places", "drawing"];
