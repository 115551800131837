import { UserSubscriptionPlanBackend } from "@/adapters";
import { SubscriptionPlanBackendKey } from "@/keys";
import { UserSubscriptionPlan } from "@/models";

function mapValueToKey(value: string): keyof typeof SubscriptionPlanBackendKey | undefined {
  const entry = Object.entries(SubscriptionPlanBackendKey).find(([, v]) => v === value);
  return entry ? (entry[0] as keyof typeof SubscriptionPlanBackendKey) : undefined;
}

export const responseUserSubscriptionPlanAdapter = (
  plan: UserSubscriptionPlanBackend,
): UserSubscriptionPlan | undefined => {
  const nameKey = mapValueToKey(plan.name);

  if (nameKey) {
    const newPlan: UserSubscriptionPlan = {
      id: plan.id,
      name: nameKey,
      active: plan.active,
    };

    return newPlan;
  } else {
    return;
  }
};
