export enum PropertyKey {
  id = "id",
  address = "address",
  builtArea = "builtArea",
  geometry = "geometry",
  frontage = "frontage",
  govtId = "govtId",
  amenity = "amenity",
  sourceId = "sourceId",
  cityId = "cityId",
  neighborhoodId = "neighborhoodId",
  parcelId = "parcelId",
  unitType = "unitType",
  unitNumber = "unitNumber",
  lastTransactionPrice = "lastTransactionPrice",
  lastTransactionTotalAreaPricePerSqm = "lastTransactionTotalAreaPricePerSqm",
  buyerName = "buyerName",
}

export enum PropertyExpandKey {
  transactions = "transactions",
  source = "source",
  city = "city",
  neighborhood = "neighborhood",
  parcel = "parcel",
  taxes = "taxes",
}
