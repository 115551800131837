import { categoricSectorLabel } from "@/adapters";
import { ListingPropertyTypeBackendKey, ListingStatusBackendKey, SectorKey } from "@/keys";
import { useTranslation } from "react-i18next";

export const useCategoryTranslations = () => {
  const { t } = useTranslation("translation");
  const sectors = t("sectors", { returnObjects: true });
  const languagePropertyTypes = t("listingPropertyTypes", { returnObjects: true });
  const languageStatus = t("listingStatus", { returnObjects: true });

  const languageSector = (sector: string) => {
    if (
      sector in SectorKey ||
      Object.values(SectorKey).includes(sector.toLowerCase() as SectorKey)
    ) {
      return sectors[
        categoricSectorLabel({
          label: sector.toLowerCase(),
        }) as keyof typeof SectorKey
      ];
    } else return sector;
  };

  const languageListingPropertyType = (propertyType: string) => {
    if (propertyType in ListingPropertyTypeBackendKey) {
      return languagePropertyTypes[propertyType as keyof typeof ListingPropertyTypeBackendKey];
    } else return propertyType;
  };

  const languagelistingStatus = (status: string) => {
    if (status in ListingStatusBackendKey) {
      return languageStatus[status as keyof typeof ListingStatusBackendKey];
    } else return status;
  };

  return {
    languageSector,
    languageListingPropertyType,
    languagelistingStatus,
  };
};
