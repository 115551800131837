import styled, { css } from "styled-components";

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div<{
  checked: boolean;
}>`
  width: 16.8px;
  height: 16.8px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.primary.color};
  background-color: ${({ checked, theme }) => (checked ? theme.primary.color : theme.common.white)};
  cursor: pointer;
  position: relative;
`;

export const CheckIcon = styled.div<{
  checked: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${({ checked }) => (checked ? "block" : "none")};
`;

export const CheckboxLabel = styled.div`
  margin-left: 5px;
`;

export const hoverActiveStyles = css`
  background-color: ${({ theme }) => theme.common.lightGray};
  font-weight: bold;
  color: ${({ theme }) => theme.primary.color};
  border: 1px solid ${({ theme }) => theme.primary.color};
`;

export const RadioButtonComponent = styled.button<{
  active: boolean;
}>`
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  border: 1px solid ${({ theme }) => theme.common.lightGray};
  color: ${({ theme }) => theme.common.gray};
  outline: none;
  border-radius: 0.5rem !important;
  background-color: ${({ theme }) => theme.common.white};
  padding-left: auto;
  padding-right: auto;
  width: 100%;
  &:hover {
    ${hoverActiveStyles}
  }
  ${({ active }) => (active ? hoverActiveStyles : "")}
`;

export const RadioButtonGroup = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  > ${RadioButtonComponent}:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  > ${RadioButtonComponent}:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;
