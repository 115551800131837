import type { BrokerInfoBackend } from "@/adapters";
import { BrokerInfo } from "@/models";

export const responseBrokersInfoAdapter = (brokers: BrokerInfoBackend[]): BrokerInfo[] => {
  const data: BrokerInfo[] = [];

  if (brokers.length) {
    brokers.map((broker) =>
      data.push({
        company: broker.broker_company,
        emails: broker.broker_emails,
        license: broker.broker_license,
        name: broker.broker_name,
        phones: broker.broker_phones,
      }),
    );
  }
  return data;
};
