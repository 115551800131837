import React from "react";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { getOrganization } from "@/configs";
import { useTranslation } from "react-i18next";

type ContactNavbarProps = {
  windowWidth: number;
};

function ContactNavbar({ windowWidth }: ContactNavbarProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar.contact", { returnObjects: true });

  return getOrganization().menu.contact.show ? (
    <li className="d-flex align-items-center">
      <UncontrolledDropdown className="dropdown-list nav-item">
        <DropdownToggle
          tag="a"
          className="nav-link position-relative d-flex align-items-center p-0">
          <div
            className="cursor-pointer d-flex align-items-center btn-header-navbar pr-0"
            style={{ height: "18px" }}>
            {windowWidth <= 1236 ? null : <h6 className="fw-bold black mb-0">{language.title}</h6>}
          </div>
        </DropdownToggle>

        <DropdownMenu tag="ul" left="true" className="dropdown-menu-contact">
          <div className="p-1">
            <div className="user-name text-bold-600 mb-05">
              <a className="primary" href={`mailto:${getOrganization().menu.contact.email}`}>
                {getOrganization().menu.contact.title}
              </a>
            </div>
            <div className="user-status">
              <a className="primary" href={`mailto:${getOrganization().menu.contact.email}`}>
                {getOrganization().menu.contact.email}
              </a>
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  ) : null;
}

export default ContactNavbar;
