import type { Listing } from "@/models";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ListingBackendExpandKey,
  useEndpoint,
} from "@/keys";
import { ListingBackend, responseListingAdapter } from "@/adapters";
import { api } from "@/services";

const investprApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getListingInvestpr: builder.query<Listing, { id: string; countryCode: CountryCodeKey }>({
      query({ id, countryCode }) {
        return {
          url: useEndpoint().listing({ id, countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: {
            include: [
              "*",
              `${ListingBackendExpandKey.source}.*`,
              `${ListingBackendExpandKey.parcel}.*`,
            ],
          },
        };
      },
      transformResponse: (response: { data: ListingBackend }): Listing =>
        responseListingAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

export const { useGetListingInvestprQuery } = investprApi;
