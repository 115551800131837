import { List, MemberList } from "@/models";
import { ListMemberBackend } from "@/adapters";
import { ListEntityTypeKey } from "@/keys";

export const responseListAdapter = (list: ListMemberBackend): List => {
  const members: MemberList[] = [];

  if (list.parcelMembers.length) {
    list.parcelMembers.map(({ id, parcelId, parcel }) =>
      members.push({
        propertyListId: id,
        listId: list.id,
        memberId: parcelId,
        address: parcel.address,
        landArea: parcel.landArea,
        builtArea: parcel.properties
          ? parcel.properties?.reduce((a, c) => a + (c.totalArea ? parseFloat(c?.totalArea) : 0), 0)
          : null,
        picture: parcel.photos ? parcel.photos[0] : null,
        entityType: ListEntityTypeKey.parcel,
        sectorType: parcel.sector?.name ? parcel.sector?.name.toLowerCase() : null,
        price: null,
        isForRent: null,
        lastTransactionPrice: parcel?.lastTransactionPrice ?? null,
        pricePerLotSqm: parcel?.pricePerLotSqm ?? null,
        pricePerBuiltSqm: parcel?.pricePerBuiltSqm ?? null,
      }),
    );
  }
  if (list.listingMembers.length) {
    list.listingMembers.map(({ id, listingId, listing }) =>
      members.push({
        propertyListId: id,
        listId: list.id,
        memberId: listingId,
        address: listing?.address ?? "---",
        landArea: listing?.lotArea ? Number(listing.lotArea) : null,
        builtArea: listing?.builtArea ? Number(listing.builtArea) : null,
        picture: listing?.photos ? listing.photos[0] : null,
        entityType: ListEntityTypeKey.listing,
        sectorType: listing?.propertyType ? listing.propertyType : null,
        price: listing?.price ? Number(listing?.price) : null,
        isForRent: listing?.isForRent,
        lastTransactionPrice: listing?.lastTransactionPrice ?? null,
        pricePerLotSqm: listing?.pricePerLotSqm ?? null,
        pricePerBuiltSqm: listing?.pricePerBuiltSqm ?? null,
        status: listing?.status ?? null,
      }),
    );
  }

  return {
    id: list.id,
    name: list.name,
    description: list.description,
    members: members,
  };
};
