export enum AddressJSONStatusBackendKey {
  confirmed = "confirmed",
  plausible = "plausible",
  unlikely = "unlikely",
}

export enum AddressJSONDataTypeBackendKey {
  permit = "permit",
  parcel = "parcel",
  listing = "listing",
  unit = "unit",
}
