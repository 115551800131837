export const useOnLoadGoogleMaps = () => {
  class ClusterOverlayView extends google.maps.OverlayView {
    private map: google.maps.Map;
    private position: google.maps.LatLng;
    private content: HTMLDivElement;

    constructor({
      position,
      map,
      content,
    }: {
      position: google.maps.LatLng;
      map: google.maps.Map;
      content: HTMLDivElement;
    }) {
      super();
      this.position = position;
      this.content = content;
      this.map = map;
      this.setMap(map);
    }

    // Implementa el método onAdd
    onAdd() {
      this.content.style.position = "absolute";
      const panes = this.getPanes();
      panes?.overlayLayer.appendChild(this.content);
    }

    // Implementa el método draw
    draw() {
      if (this.content) {
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        this.content.style.left = divPosition?.x + "px";
        this.content.style.top = divPosition?.y + "px";
      }
    }
  }

  class InfoWindowOverlayView extends google.maps.OverlayView {
    position: google.maps.LatLng;
    containerDiv: HTMLDivElement;
    constructor(position: google.maps.LatLng, content: HTMLDivElement) {
      super();
      this.position = position;

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("infowindow-container");
      this.containerDiv.appendChild(content);

      // Optionally stop clicks, etc., from bubbling up to the map.
      InfoWindowOverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the infowindow is added to the map. */
    onAdd(): void {
      this.getPanes()?.floatPane.appendChild(this.containerDiv);
    }

    /** Called when the infowindow is removed from the map. */
    onRemove(): void {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /** Called each frame when the infowindow needs to draw itself. */
    draw(): void {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

      // Hide the infowindow when it is far out of view.
      if (divPosition) {
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

        if (display === "block") {
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top = divPosition.y + "px";
        }

        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }
  }

  return { ClusterOverlayView, InfoWindowOverlayView };
};
