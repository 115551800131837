import { LocalStorageKey } from "@/keys";

interface LoginState {
  token: string | null;
  emailVerificationView: boolean;

  isLogin: boolean;
  isLoading: boolean;
}

const initialState: LoginState = {
  token: localStorage.getItem(LocalStorageKey.token)
    ? localStorage.getItem(LocalStorageKey.token)
    : "",
  emailVerificationView: false,

  isLogin: false,
  isLoading: false,
};

export const login = (state = initialState, action: any) => {
  switch (action.type) {
    case "POST_LOGIN": {
      return {
        ...state,
        token: action.payload.token,
        isLoading: false,
      };
    }
    case "SET_TOKEN": {
      return {
        ...state,
        token: action.token,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        token: "",
        isLoading: false,
      };
    }
    case "SET_EMAIL_VERIFICATION_VIEW": {
      return {
        ...state,
        emailVerificationView:
          action.value === null || action.value === undefined
            ? !state.emailVerificationView
            : action.value,
        isLogin: action.value ? true : false,
      };
    }
    case "START_LOGIN_IS_LOADING": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "END_LOGIN_IS_LOADING": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
