import { AppDispatch, RootState } from "@/app/store";
import { MemberOrganization, Organization, User } from "@/models";
import axios from "axios";
import { URL_BACKEND } from "@/config";
import { CountryCodeBackendKey, CountryCodeKey, useEndpoint } from "@/keys";
import { toast } from "react-toastify";
import { getUserIsVerified, loadUser } from "@/redux/actions";
import { api } from "../api";
import { MemberOrganizationBackend, responseMembersOrganizationAdapter } from "@/adapters";
import { RoleBackendKey } from "@/keys/backend/role-backend.key";
import { setUserIsLoading } from "@/redux/slices";

export const createOrganization = ({ data }: { data: Partial<Organization> }) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));

      const accessToken = getState().auth.login.token;

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().organizations(),
        method: "post",
        data: {
          name: data.name,
          website: data.website,
          countryCode: data.country?.code,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      toast.success("The Business was created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(loadUser());
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
          return false;
        } else {
          if (error?.response?.data?.errors)
            error.response.data.errors.map((error: any) => {
              return toast.error(error, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
          return false;
        }
      }
    }
  };
};

export const updateOrganization = ({
  data,
  organizationId,
}: {
  data: Partial<Organization>;
  organizationId: string;
}) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));
      const accessToken = getState().auth.login.token;

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().organization({ id: organizationId }),
        method: "put",
        data: {
          name: data.name,
          countryCode: data.country?.code,
          industry: data.industry,
          tagline: data.tagline,
          address: data.address,
          phone: data.phone,
          website: data.website,
          overview: data.overview,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      toast.success("Business updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(loadUser());
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
        } else {
          if (error?.response?.data?.errors)
            error.response.data.errors.map((error: any) => {
              return toast.error(error, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
        }
      }
    }
  };
};

export const organizationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // ------------- Get Members Organization ------------- //
    getMembersOrganization: builder.query<MemberOrganization[], void>({
      query: () => ({
        url: useEndpoint().organizationsMembers(),
      }),
      transformResponse: (response: { data: MemberOrganizationBackend[] }): MemberOrganization[] =>
        responseMembersOrganizationAdapter(response.data),
      providesTags: ["MembersOrganization"],
    }),

    // ------------- Create Members Organization ------------- //
    createMemberOrganization: builder.mutation<
      { data: boolean; errors: [] },
      {
        data: Partial<User>;
        countryCode: CountryCodeBackendKey;
        role: RoleBackendKey.Broker;
      }
    >({
      query({ data, countryCode, role }) {
        return {
          url: useEndpoint().organizationsMembers(),
          method: "post",
          body: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            countryCode,
            role,
          },
        };
      },
      invalidatesTags: ["MembersOrganization"],
    }),

    // ------------- Delete Member Organization ------------- //
    deleteMemberOrganization: builder.mutation<
      { data: boolean; errors: [] },
      {
        id: string;
      }
    >({
      query({ id }) {
        return {
          url: useEndpoint().organizationsMember({ id }),
          method: "DELETE",
        };
      },
      invalidatesTags: ["MembersOrganization"],
    }),

    // ------------- Resend Email Member Organization ------------- //
    resendEmailMemberOrganization: builder.mutation<
      { data: boolean; errors: [] },
      {
        id: string;
      }
    >({
      query({ id }) {
        return {
          url: useEndpoint().organizationsMemberResendEmail({ id }),
          method: "POST",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMembersOrganizationQuery,
  useCreateMemberOrganizationMutation,
  useDeleteMemberOrganizationMutation,
  useResendEmailMemberOrganizationMutation,
} = organizationsApi;

export const getMembersOrganization = (state: RootState) =>
  organizationsApi.endpoints.getMembersOrganization.select()(state).data;
