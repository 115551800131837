import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { sizeViewport } from "@/components/styledComponents";
import {
  Filters,
  resetFiltersByPortal,
  selectAppConfig,
  selectMap,
  setDeleteButtonWatcher,
  setIsElasticSearh,
  setRefreshFilters,
  setUserInputSearch,
  setViewActive,
  updateActiveTabGrid,
} from "@/redux/slices";
import classNames from "classnames";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { AlertTriangle, MapPin, Type } from "react-feather";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { regexCoordinates } from "@/validations";
import {
  GeometryTypesKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  PortalKey,
  PropertyFeatureTabKey,
  TabKey,
} from "@/keys";
import { GptSearchModel, getSearchIds } from "@/services";
import { useTheme } from "styled-components";
import { appPaths } from "@/configs";
import { history } from "@/history";
import { setNews } from "@/redux/slices/news/news.slice";
import { useLocaleCountryCode } from "@/hooks";

import documentIcon from "@/assets/img/icons/features/searchSuggestions/documents.svg";
import gpsIcon from "@/assets/img/icons/features/searchSuggestions/gps.svg";
import listingsIcon from "@/assets/img/icons/features/searchSuggestions/listings.svg";
import permitsIcon from "@/assets/img/icons/features/searchSuggestions/permits.svg";
import propertiesIcon from "@/assets/img/icons/features/searchSuggestions/properties.svg";
import taxesIcon from "@/assets/img/icons/features/searchSuggestions/taxes.svg";
import tenantsIcon from "@/assets/img/icons/features/searchSuggestions/tenants.svg";
import transactionsIcon from "@/assets/img/icons/features/searchSuggestions/transactions.svg";
import unitsIcon from "@/assets/img/icons/features/searchSuggestions/units.svg";
import newsIcon from "@/assets/img/icons/features/news.svg";
import addressesIcon from "@/assets/img/icons/features/addresses.svg";
import cadasterNumberIcon from "@/assets/img/icons/features/cadaster-number.svg";
import { userIsLoggedIn } from "@/redux/actions";
import { useLocation } from "react-router-dom";
import ChatGptSuggestion from "@/layouts/components/navbar/suggestions/ChatGptSuggestion";
import useRouteQueryString from "@/hooks/queryString/useRouteQueryString.hook";
import { setUnit } from "@/redux/slices/units/unit.slice";
import { Point, TransactionProperty } from "@/models";
import { GroupedSuggestion, Suggestion } from "@/layouts/components/navbar/SearchNavbar";

type SuggestionsBoxProps = {
  filteredData: GroupedSuggestion[];
  setFilteredData: (value: any) => void;
  suggestions: Suggestion[];
  fullWidth?: boolean;
  changeUserInput: (value: {
    value: any;
    filter: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey;
    type: NewsFiltersTypeFrontKey;
  }) => void;
  setUserInput: (value: string) => void;
  userInput: string;
  gptSearchResults?: Filters | null;
  filterKey: "title";
  filterHeaderKey: "groupTitle";
  showSuggestions: boolean;
  setShowSuggestions: (value: boolean) => void;
  applyFilters: () => void;
  customRender: (
    item: GroupedSuggestion,
    i: number,
    filteredData: GroupedSuggestion[],
    activeSuggestion: number,
    onSuggestionItemClick: (value: {
      id: string;
      e?: React.MouseEvent<HTMLLIElement, MouseEvent>;
      title?: string | null;
      url?: string | null;
      filter?: NewsFiltersFrontKey;
      entity: NewsFiltersEntityFrontKey | "news";
      type?: NewsFiltersTypeFrontKey | null;
      unitId?: string;
    }) => void,
    onSuggestionItemHover: (index: number) => void,
    userInput?: string,
  ) => JSX.Element;
  isLoading: boolean;
  wasStringModifiedRef: React.MutableRefObject<boolean>;
  activeSuggestion: number;
  setActiveSuggestion: (value: number) => void;
  onClickGpt: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | KeyboardEvent<HTMLInputElement>,
  ) => void;
  gptNoResultsToShow: boolean;
  isLoadingGptSearchFilters: boolean;
};

function SuggestionsBox({
  filteredData,
  setFilteredData,
  fullWidth,
  changeUserInput,
  setUserInput,
  userInput,
  gptSearchResults,
  filterKey,
  filterHeaderKey,
  suggestions,
  showSuggestions,
  setShowSuggestions,
  applyFilters,
  customRender,
  isLoading,
  wasStringModifiedRef,
  activeSuggestion,
  setActiveSuggestion,
  onClickGpt,
  gptNoResultsToShow,
  isLoadingGptSearchFilters,
}: SuggestionsBoxProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const suggestionList = useRef<PerfectScrollbar>(null);
  const dispatch = useAppDispatch();

  const { secondary } = useTheme();
  const { userDrawnPolygon } = useAppSelector(selectMap);

  const { windowWidth, portal } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;
  const { countryCode } = useLocaleCountryCode();
  const { pathname } = useLocation();
  const { appPropertyProfileQueryString, appListingProfileQueryString } = useRouteQueryString();

  useEffect(() => {
    const tempFilteredData: GroupedSuggestion[] = [];
    if (suggestions) {
      suggestions.forEach((suggestion) => {
        tempFilteredData.push(...suggestion.data);
      });
      setFilteredData(tempFilteredData);
    }
  }, [suggestions]);

  // const [activeSuggestion, setActiveSuggestion] = useState(0);

  const examplesList = [
    {
      title: language.searchTooltip.listings,
      icon: listingsIcon,
      display: true,
    },
    {
      title: language.searchTooltip.tenants,
      icon: tenantsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.gpsCoordinates,
      icon: gpsIcon,
      display: true,
    },
    {
      title: language.searchTooltip.properties,
      icon: propertiesIcon,
      display: true,
    },
    { title: language.searchTooltip.taxes, icon: taxesIcon, display: dispatch(userIsLoggedIn()) },
    {
      title: language.searchTooltip.addresses,
      icon: addressesIcon,
      display: true,
    },
    { title: language.searchTooltip.units, icon: unitsIcon, display: dispatch(userIsLoggedIn()) },
    {
      title: language.searchTooltip.permits,
      icon: permitsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.cadasterNumber,
      icon: cadasterNumberIcon,
      display: true,
    },
    {
      title: language.searchTooltip.transactions,
      icon: transactionsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.documents,
      icon: documentIcon,
      display: dispatch(userIsLoggedIn()),
    },
    { title: language.searchTooltip.news, icon: newsIcon, display: true },
  ];

  /* F U N C I O N E S */
  const redirectToSection = ({ tab }: { tab: TabKey }) => {
    dispatch(updateActiveTabGrid({ tab }));
    if (pathname !== appPaths.tableView.path) {
      dispatch(setViewActive("grid"));
      history.push(appPaths.home.path);
    }
  };

  const onSuggestionItemClick = ({
    id,
    e,
    title,
    url,
    filter,
    entity,
    type,
    unitId,
  }: {
    id: string;
    e?: React.MouseEvent<HTMLLIElement, MouseEvent>;
    title?: string | null;
    url?: string | null;
    filter?: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey | "news";
    type?: NewsFiltersTypeFrontKey | null;
    unitId?: string;
  }) => {
    wasStringModifiedRef.current = false;

    setActiveSuggestion(-1);
    setShowSuggestions(false);
    dispatch(setUserInputSearch({ value: "", showPill: false }));
    setUserInput(userInput);
    if (pathname === appPaths.indexHome.path) {
      history.push(appPaths.home.path);
    }

    if (entity === "news") {
      if (url) {
        window.open(url);
      } else {
        dispatch(setNews({ newsView: true, newsId: id }));
      }
      return;
    } else {
      if (url) {
        if (entity === NewsFiltersEntityFrontKey.property) {
          dispatch(setUnit({ unitId: id, unitView: true }));
        } else if (unitId) {
          if (unitId) dispatch(setUnit({ unitId: unitId, unitView: true }));
        }
        history.push(url);
      } else {
        let value:
          | { value: string; label: string | null | undefined }
          | Point
          | string
          | null
          | undefined = null;

        value = (e?.target as HTMLElement)?.innerText
          ? (e?.target as HTMLElement)?.innerText
          : title;
        if (type === NewsFiltersTypeFrontKey.categoric) {
          value = {
            value: id,
            label: (e?.target as HTMLElement)?.innerText
              ? (e?.target as HTMLElement)?.innerText
              : title,
          };
        }
        if (
          type === NewsFiltersTypeFrontKey.geometric &&
          filter === NewsFiltersFrontKey.coordinates
        ) {
          const coordinates = title?.split(",");
          if (coordinates) {
            value = {
              type: GeometryTypesKey.point,
              coordinates: [parseFloat(coordinates[1]), parseFloat(coordinates[0])],
            };
          }
        }
        if (filter && type) changeUserInput({ value, filter, entity, type });
      }
    }
  };

  const onUserInputClick = ({ tab }: { tab: TabKey }) => {
    dispatch(setIsElasticSearh(true));
    setActiveSuggestion(-1);
    setShowSuggestions(false);
    dispatch(resetFiltersByPortal({ portal: portal }));
    dispatch(setRefreshFilters(true));
    redirectToSection({ tab });

    dispatch(
      getSearchIds({
        query: userInput,
        countryCode,
        callback() {
          applyFilters();
        },
      }),
    );
  };

  const onSuggestionItemHover = (index: number) => {
    setActiveSuggestion(index);
  };

  /* C O M P O N E N T E   D E   S U G E R E N C I A S */

  const renderGroupedSuggestion = (arr: GroupedSuggestion[]) => {
    const renderSuggestion = (item: GroupedSuggestion, i: number) => {
      if (!customRender) {
        return (
          <li
            className={classNames("suggestion-item", {
              active: filteredData.indexOf(item) === activeSuggestion,
            })}
            key={item[filterKey]}
            onClick={(e) => {
              onSuggestionItemClick({
                id: item.id,
                e,
                title: item.title,
                url: item.link,
                filter: item.filter,
                entity: item.entity,
                type: item.type,
                unitId: item.unitId ?? "",
              });
            }}
            onMouseEnter={(e) => {
              onSuggestionItemHover(filteredData.indexOf(item));
            }}>
            {item[filterKey]}
          </li>
        );
      } else if (customRender) {
        return customRender(
          item,
          i,
          filteredData,
          activeSuggestion,
          onSuggestionItemClick,
          onSuggestionItemHover,
          userInput,
        );
      } else {
        return null;
      }
    };

    return arr.map((item, i) => {
      return renderSuggestion(item, i);
    });
  };

  const renderSuggestions = () => {
    //const newFilteredData: filteredDataSuggestionType[] = [];
    const suggestionsToRender = suggestions.map((suggestion, index) => {
      const sortData = suggestion.data;
      //newFilteredData.push(...sortData);

      return (
        <React.Fragment key={suggestion[filterHeaderKey]}>
          {sortData.length ? (
            <div>
              {index > 0 && <hr className="mx-1" />}
              {/* ver mas resultados de parcels */}
              {suggestion.groupTitle !== "chatGpt" && (
                <li className="suggestion-item suggestion-title text-bold-600 d-flex justify-content-between align-items-center">
                  <p
                    className="suggestion-title text-bold-600 text-uppercase mr-1 mb-0"
                    style={{
                      textDecorationLine: "underline",
                      textDecorationColor: secondary.color,
                      textDecorationThickness: "2px",
                    }}>
                    {suggestion[filterHeaderKey]}
                  </p>
                  {[
                    "parcels",
                    "listings",
                    "properties",
                    "transactions",
                    "tenants",
                    "permits",
                    "documents",
                  ].includes(suggestion.id) ? (
                    <div
                      onClick={() => {
                        if (userDrawnPolygon) {
                          dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
                        }
                        onUserInputClick({
                          tab:
                            suggestion.id === "parcels"
                              ? TabKey.properties
                              : suggestion.id === "listings"
                              ? TabKey.listings
                              : suggestion.id === "properties"
                              ? TabKey.units
                              : suggestion.id === "transactions"
                              ? TabKey.transactions
                              : suggestion.id === "tenants"
                              ? TabKey.tenants
                              : suggestion.id === "permits"
                              ? TabKey.permits
                              : TabKey.documents,
                        });
                      }}>
                      {!isMobile && (
                        <small className="ml-1 primary cursor-pointer">{`${language.results.seeAll}`}</small>
                      )}
                    </div>
                  ) : null}
                </li>
              )}
              {renderGroupedSuggestion(sortData)}
            </div>
          ) : null}
        </React.Fragment>
      );
    });

    //setFilteredData(newFilteredData);
    return suggestionsToRender;
  };

  /* C O M P O N E N T E S   A L T E R N A T I V O S */

  // componente cuando no hay resultados
  function emptyResultsSearch() {
    return (
      <>
        <div className="suggestion-item no-result w-100">
          <AlertTriangle size={15} className="warning" />{" "}
          <span className="align-middle ml-50 fw-bold">{language.noResults.title}</span>
          <span className="ml-05 fw-bold">{`(${userInput})`}</span>
          <div className="mt-05 font-italic">{language.noResults.suggestion}</div>
        </div>
      </>
    );
  }

  // componente cuando escribe menos de 3 caracteres
  function invalidSearch() {
    return (
      <>
        <div
          className={classNames({
            "offset-left-2-5": !isMobile && !fullWidth && windowWidth < 670,
            "offset-left-1": isMobile && !fullWidth,
          })}
          style={{
            width:
              fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 4.5rem)" : "100%") : "",
          }}>
          <div className="suggestion-item no-result">
            <Type size={15} /> <span className="align-middle ml-50">{language.waitResults}</span>
          </div>
        </div>
      </>
    );
  }

  // componente cuando esta cargando resultados
  function loadingSuggestions() {
    return (
      <>
        <div
          className={classNames({
            "offset-left-2-5": !isMobile && !fullWidth && windowWidth < 670,
            "offset-left-1": isMobile && !fullWidth,
          })}
          style={{
            width:
              fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 4.5rem)" : "100%") : "",
          }}>
          <div className="suggestion-item no-result">
            <Spinner color="primary" size="sm" />
            <span className="align-middle ml-50 fw-bold">{language.loading}</span>
          </div>
        </div>
      </>
    );
  }

  // componente de coordenadas
  function coordinatesSuggestions() {
    return (
      <>
        <div
          className="suggestion-item coordinates"
          onClick={(e) => {
            onSuggestionItemClick({
              id: "",
              title: userInput,
              url: "",
              filter: NewsFiltersFrontKey.coordinates,
              entity: NewsFiltersEntityFrontKey.location,
              type: NewsFiltersTypeFrontKey.geometric,
              unitId: "",
            });
          }}>
          <MapPin size={15} className="dark" />{" "}
          <span className="align-middle ml-50 fw-bold">{language.results.coordinates.title}</span>
          <span className="ml-05">{`(${userInput})`}</span>
        </div>
      </>
    );
  }

  // componente cuando no hay texto escrito (sugerencias)
  function examplesSuggestions() {
    return (
      <>
        <div
          className={classNames("position-relative", {
            "offset-left-2-5": !isMobile && !fullWidth && windowWidth < 670,
            "offset-left-1": isMobile && !fullWidth,
          })}
          style={{
            width:
              fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 4.5rem)" : "100%") : "",
          }}>
          <div className="p-1 w-100">
            <div className="col-12">
              <p className="fw-bold search-tooltip-title">{language.searchTooltip.title}</p>
            </div>
            <div
              className="d-flex flex-wrap w-100 justify-content-between"
              style={{ overflow: "auto", maxHeight: fullWidth ? "15vh" : "" }}>
              {examplesList
                .filter((example) => example.display)
                .map((example) => {
                  return (
                    <div className="search-example-item col-4" key={example.title}>
                      <p className="fw-bold">
                        <span>
                          <img
                            src={example.icon}
                            alt={example.title}
                            height="16px"
                            className="mr-05"
                          />
                        </span>
                        {example.title}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    );
  }

  // componente de resultados
  function elasticSearchSuggestions() {
    return (
      <>
        {suggestions.map((suggestion, index) => {
          const sortData = suggestion.data;
          //newFilteredData.push(...sortData);

          return (
            <React.Fragment key={suggestion[filterHeaderKey]}>
              {sortData.length ? (
                <div>
                  {index > 0 && <hr className="mx-1" />}
                  {/* ver mas resultados de parcels */}

                  <li className="suggestion-item suggestion-title text-bold-600 d-flex justify-content-between align-items-center">
                    <p
                      className="suggestion-title text-bold-600 text-uppercase mr-1 mb-0"
                      style={{
                        textDecorationLine: "underline",
                        textDecorationColor: secondary.color,
                        textDecorationThickness: "2px",
                      }}>
                      {suggestion[filterHeaderKey]}
                    </p>
                    {[
                      "parcels",
                      "listings",
                      "properties",
                      "transactions",
                      "tenants",
                      "permits",
                      "documents",
                    ].includes(suggestion.id) ? (
                      <div
                        onClick={() => {
                          if (userDrawnPolygon) {
                            dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
                          }
                          onUserInputClick({
                            tab:
                              suggestion.id === "parcels"
                                ? TabKey.properties
                                : suggestion.id === "listings"
                                ? TabKey.listings
                                : suggestion.id === "properties"
                                ? TabKey.units
                                : suggestion.id === "transactions"
                                ? TabKey.transactions
                                : suggestion.id === "tenants"
                                ? TabKey.tenants
                                : suggestion.id === "permits"
                                ? TabKey.permits
                                : TabKey.documents,
                          });
                        }}>
                        {!isMobile && (
                          <small className="ml-1 primary cursor-pointer">{`${language.results.seeAll}`}</small>
                        )}
                      </div>
                    ) : null}
                  </li>

                  {renderGroupedSuggestion(sortData)}
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  // logica de cual se muestra
  function getDisplayWindow() {
    /*     if (showSuggestions && !isLoading) {
      return (
        <>
          {userInput.match(regexCoordinates)
            ? coordinatesSuggestions()
            : elasticSearchSuggestions()
            ? filteredData.length
              ? elasticSearchSuggestions()
              : !userInput.match(regexCoordinates) &&
                Object.keys(gptSearchResults || {}).length === 0
              ? emptyResultsSearch()
              : invalidSearch()
            : null}
        </>
      );
    } else {
      return userInput
        ? userInput.length >= 3
          ? wasStringModifiedRef.current && loadingSuggestions()
          : invalidSearch()
        : examplesSuggestions();
    } */

    if (showSuggestions && !isLoading) {
      // si busco texto y hay coordenadas
      if (userInput.match(regexCoordinates)) {
        return coordinatesSuggestions();
      }

      //si busco texto pero no trae resultados de Elastic Search
      if (Object.keys(gptSearchResults || {}).length === 0) {
        emptyResultsSearch();
      }

      // si hay resultados de Elastic Search, escribio mas de 3 letras y no son coordenadas
      return elasticSearchSuggestions();
    } else {
      if (userInput.length === 0) {
        return examplesSuggestions();
      }

      // si el usuario escribió menos de 3 caracteres
      if (userInput.length < 3) {
        return invalidSearch();
      }

      if (userInput.length >= 3 && wasStringModifiedRef.current) {
        return loadingSuggestions();
      }
    }
  }

  return (
    <>
      <PerfectScrollbar
        className={classNames("suggestions-list", {
          "offset-left-2-5": !isMobile && !fullWidth && windowWidth < 670,
          "offset-left-1": isMobile && !fullWidth,
        })}
        ref={suggestionList}
        component="ul"
        options={{ wheelPropagation: false }}
        style={{
          width: fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 4.5rem)" : "100%") : "",
          maxHeight: fullWidth ? "30vh" : "",
          height: fullWidth && isMobile ? "auto" : "auto",
          position: "absolute",
        }}>
        {userInput && userInput.length >= 3 && (
          <li
            className={classNames("btn-chatgpt-suggestion", {
              active: activeSuggestion === -1,
            })}
            onClick={(e) => {
              onClickGpt(e);
            }}
            onMouseEnter={(e) => onSuggestionItemHover(-1)}>
            <ChatGptSuggestion
              isLoadingSearchFilters={isLoadingGptSearchFilters}
              gptNoResultsToShow={gptNoResultsToShow}
            />
          </li>
        )}
        {getDisplayWindow()}
      </PerfectScrollbar>
    </>
  );
}

export default SuggestionsBox;
