import React from "react";
import { getOrganization } from "@/configs";
import { useTranslation } from "react-i18next";

type ImpellerNavbarProps = {
  windowWidth: number;
};

function ImpellerNavbar({ windowWidth }: ImpellerNavbarProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar", { returnObjects: true });

  return getOrganization().menu.impeller.show ? (
    <li className="d-flex align-items-center">
      <a
        className="cursor-pointer d-flex align-items-center btn-header-navbar"
        style={{ height: "18px" }}
        href={getOrganization().menu.impeller.url}
        target="_blank"
        rel="noopener noreferrer">
        {windowWidth <= 1236 ? null : (
          <h6 className="mb-0 fw-bold black">{language.impeller.title}</h6>
        )}
      </a>
    </li>
  ) : null;
}

export default ImpellerNavbar;
