import { register } from "../auth/loginActions";
import { toast } from "react-toastify";

export const handleReCaptchaVerify = ({ name, executeRecaptcha, data, email }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "START_REGISTER_IS_LOADING",
      });
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha(name);

      dispatch(register(data, email, token));
    } catch (error) {
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      toast.error("The captcha has failed, please try again in a few minutes", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
};
