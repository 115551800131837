import { useAppDispatch } from "@/app/hooks";
import { userIsLogged } from "@/redux/actions";
import React from "react";
import { ButtonBorderPrimary, ButtonPrimary } from "../styledComponents";
import { history } from "@/history";
import { appPaths } from "@/configs";
import { setCallToActionModalOpen, setCallToActionModalPricingsOpen } from "@/redux/slices";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const LoginButtons = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["auth"]);
  const translationInitialAuth = t("auth:initialAuth", { returnObjects: true });
  const { pathname } = useLocation();

  return (
    <>
      {/* {dispatch(userIsLogged()) ? ( */}
      <div className="d-flex justify-content-center" style={{ marginTop: "0.5rem", width: "100%" }}>
        <ButtonPrimary
          className="w-100"
          onClick={() => {
            dispatch(setCallToActionModalOpen({ callToActionModalOpen: false }));
            dispatch(setCallToActionModalPricingsOpen({ callToActionModalPricingsOpen: true }));
            if (pathname === appPaths.learnMore.path) {
              history.push(appPaths.home.path);
            }
          }}>
          {translationInitialAuth.buttons.subscribe}
        </ButtonPrimary>
      </div>
      {/* ) : (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "0.5rem", width: "100%" }}>
          <ButtonBorderPrimary
            className="w-100 mr-1"
            onClick={() => {
              dispatch(setCallToActionModalOpen({ callToActionModalOpen: false }));
              history.push(appPaths.signIn.path);
            }}>
            {translationInitialAuth.buttons.signIn}
          </ButtonBorderPrimary>
          <ButtonPrimary
            className="w-100"
            onClick={() => {
              dispatch(setCallToActionModalOpen({ callToActionModalOpen: false }));
              history.push(appPaths.signUp.path);
            }}>
            {translationInitialAuth.buttons.signUp}
          </ButtonPrimary>
        </div>
      )} */}
    </>
  );
};

export default LoginButtons;
