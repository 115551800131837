import React from "react";
import { DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from "reactstrap";
import { User, LogOut, CreditCard, MessageSquare, FileText, Briefcase } from "react-feather";
import { userHasSubscription, userIsLogged, userIsLoggedIn } from "@/redux/actions";
import notImgSmall from "@/assets/img/slider/notImgSmall.jpg";
import LogoEnterprise from "@/assets/img/icons/logo-enterprise.png";
import xCircle from "@/assets/img/icons/x-circle.svg";
import { appPaths, getOrganization } from "@/configs";
import { history } from "@/history";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  selectAppConfig,
  setUserMenuOpen,
  setCallToActionModalPricingsOpen,
  selectUser,
} from "@/redux/slices";
import { IconButton, sizeViewport } from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { URL_ADMIN } from "@/config";
import LanguageDropdown from "@/layouts/components/corporateSite/navbar/languageDropdowns/LanguageDropdown";
import { OrganizationKey, UserRoleKey } from "@/keys";
import CountryDropdown from "@/layouts/components/corporateSite/navbar/languageDropdowns/CountryDropdown";
import PerfectScrollbar from "react-perfect-scrollbar";
import iconAtlasProfessional from "@/assets/img/logo/productsLogos/logo-atlas-professional.png";

const MenuUser = ({ logout }: { logout: () => void }) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar.menuUser", { returnObjects: true });
  const languageTerminal = t("navbar.menuNavbar.terminal", { returnObjects: true });
  const { primary } = useTheme();
  const { userMenuOpen } = useAppSelector(selectAppConfig);
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;

  const isRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;

  const handdleSettings = (route: string) => {
    history.push(route);
  };

  const toggleMenuUser = () => {
    dispatch(setUserMenuOpen({ userMenuOpen: !userMenuOpen }));
  };

  const toggleMenuUserTrue = () => {
    dispatch(setUserMenuOpen({ userMenuOpen: true }));
  };

  const toggleMenuUserFalse = () => {
    dispatch(setUserMenuOpen({ userMenuOpen: false }));
  };

  return (
    <Dropdown isOpen={userMenuOpen} toggle={toggleMenuUser}>
      <DropdownToggle
        tag="a"
        className={classNames("nav-link position-relative d-flex align-items-center p-0", {
          "ml-1": !isMobile,
        })}>
        <span data-tour="user">
          <img
            src={user?.photoUrl ? user.photoUrl : notImgSmall}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = notImgSmall;
            }}
            className="round-circle"
            height="40"
            width="40"
            alt="avatar"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu
        right
        className={classNames({
          "mobile-fullscreen": isMobile,
          "user-dropdown": !isMobile,
        })}
        style={{ width: "293px" }}>
        <PerfectScrollbar>
          {isMobile && (
            <div className="w-100 d-flex justify-content-end" style={{ padding: "1rem" }}>
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(setUserMenuOpen({ userMenuOpen: false }));
                }}>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{
                    fontSize: "18px",
                    color: primary.color,
                  }}
                />
              </IconButton>
            </div>
          )}
          <div
            className={classNames("d-flex align-items-center p-1", {
              "flex-column": isMobile,
            })}
            style={{ gap: isMobile ? "2rem" : "" }}>
            {getOrganization().menu.menuUser.userPhotoUrl ? (
              <a
                className="primary"
                href={getOrganization().menu.menuUser.userPhotoUrl}
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={user?.photoUrl ? user.photoUrl : notImgSmall}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = notImgSmall;
                  }}
                  className={classNames("round-circle", {
                    "mr-1": !isMobile,
                  })}
                  height={isMobile ? "100" : "50"}
                  width={isMobile ? "100" : "50"}
                  alt="avatar"
                />
              </a>
            ) : (
              <img
                src={user?.photoUrl ? user.photoUrl : notImgSmall}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = notImgSmall;
                }}
                className={classNames("round-circle", {
                  "mr-1": !isMobile,
                })}
                height={isMobile ? "100" : "50"}
                width={isMobile ? "100" : "50"}
                alt="avatar"
              />
            )}
            <div style={{ textAlign: isMobile ? "center" : "left" }}>
              <div className="user-name text-bold-600 mb-05" style={{ overflowWrap: "anywhere" }}>
                {getOrganization().menu.menuUser.mailto ? (
                  <a className="primary" href={`mailto:${user?.email}`}>
                    {user?.firstName + " " + user?.lastName}
                  </a>
                ) : (
                  user?.firstName + " " + user?.lastName
                )}
              </div>
              <div className="user-status" style={{ overflowWrap: "anywhere" }}>
                {getOrganization().menu.menuUser.mailto ? (
                  <a className="primary" href={`mailto:${user?.email}`}>
                    {user?.email}
                  </a>
                ) : (
                  user?.email
                )}
              </div>
            </div>
          </div>

          {dispatch(userIsLogged()) && !dispatch(userHasSubscription()) ? (
            <DropdownItem
              tag="div"
              onClick={() =>
                dispatch(setCallToActionModalPricingsOpen({ callToActionModalPricingsOpen: true }))
              }>
              <img
                src={iconAtlasProfessional}
                alt="atlas-professional"
                height={isMobile ? 12 : 14}
                className="mr-50"
              />
              <span className={classNames("align-middle fw-bold")}>{language.subscribe}</span>
            </DropdownItem>
          ) : null}

          <div className="dropdown-divider"></div>
          {getOrganization().menu.menuUser.profile ? (
            <DropdownItem tag="div" onClick={() => handdleSettings(appPaths.profile.path)}>
              <User size={isMobile ? 22 : 14} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.account}
              </span>
            </DropdownItem>
          ) : null}
          {dispatch(userIsLoggedIn()) && user?.subscriptionPlan ? (
            <DropdownItem tag="div" onClick={() => handdleSettings(appPaths.billing.path)}>
              <CreditCard size={isMobile ? 22 : 14} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.billing}
              </span>
            </DropdownItem>
          ) : null}
          {dispatch(userIsLoggedIn()) && user?.organization ? (
            <DropdownItem tag="div" onClick={() => handdleSettings(appPaths.business.path)}>
              <Briefcase size={isMobile ? 22 : 14} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.business}
              </span>
            </DropdownItem>
          ) : null}
          {getOrganization().menu.menuUser.country || isRedAtlas ? (
            <DropdownItem tag="div" toggle={false} className="dropdown-language">
              <CountryDropdown
                fullWidth
                disableUnderline
                flagSize={isMobile ? "1.6rem" : "1rem"}
                variant="accordion"
                additionalAction={toggleMenuUserFalse}
              />
            </DropdownItem>
          ) : null}
          {getOrganization().menu.menuUser.language || isRedAtlas ? (
            <DropdownItem tag="div" toggle={false} className="dropdown-language">
              <LanguageDropdown
                fullWidth
                disableUnderline
                showIcon
                variant="accordion"
                iconSize={isMobile ? "1.6rem" : "1rem"}
              />
            </DropdownItem>
          ) : null}
          {getOrganization().menu.menuUser.feedback ? (
            <DropdownItem
              tag="div"
              onClick={() =>
                window.open(
                  "https://forms.office.com/Pages/ResponsePage.aspx?id=eLQ7DQIGXUWQjnX_GqaAy3UQuRG_2_BNkFO-uxK9GTlUOFhXRDFQTU9KN0NENTVINVdXNE85NEhYQi4u",
                  "_blank",
                )
              }>
              <MessageSquare size={isMobile ? 22 : 14} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.feedback}
              </span>
            </DropdownItem>
          ) : null}
          {getOrganization().menu.menuUser.reportErrors ? (
            <DropdownItem
              tag="a"
              href={"https://forms.office.com/r/nfU6gwGw36"}
              target="_blank"
              rel="noopener noreferrer">
              <img src={xCircle} style={{ width: isMobile ? "16px" : "14px" }} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.reportErrors}
              </span>
            </DropdownItem>
          ) : null}
          {getOrganization().menu.menuUser.noticesAndPolicies ? (
            <DropdownItem tag="div" onClick={() => history.push(appPaths.noticesAndPolicies.path)}>
              <FileText size={isMobile ? 22 : 14} className="mr-50" />
              <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                {language.noticesAndPolicies}
              </span>
            </DropdownItem>
          ) : null}
          {getOrganization().auth.login ? (
            <div>
              <DropdownItem divider />
              {dispatch(userIsLoggedIn()) && user?.role?.name === UserRoleKey.administrator ? (
                <DropdownItem tag="div" className="d-flex" style={{ padding: ".5rem .9rem" }}>
                  <a
                    className="cursor-pointer d-flex"
                    style={{ height: "18px", padding: 0 }}
                    href={URL_ADMIN}
                    target="_blank"
                    rel="noreferrer">
                    <img src={LogoEnterprise} alt="building-house" height={isMobile ? 12 : 14} />
                    <span
                      className={classNames("align-middle ml-50 black", { "fw-bold": isMobile })}>
                      {languageTerminal.title}
                    </span>
                  </a>
                </DropdownItem>
              ) : null}
              <DropdownItem tag="a" onClick={logout}>
                <LogOut size={isMobile ? 22 : 14} className="mr-50" />
                <span className={classNames("align-middle", { "fw-bold": isMobile })}>
                  {language.logout}
                </span>
              </DropdownItem>
            </div>
          ) : null}
        </PerfectScrollbar>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuUser;
