import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, Marker as MarkerPin } from "@react-google-maps/api";
import { CreateMapControls } from "./controls";
import { defaultMapOptions, mapContainerStyle } from "./mapConfig";
import { MapContainer } from "./styledComponents";
import { onPolygonCompleted, useCreateDrawingManager } from "./hooks/CreateDrawControls";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { AnyProps, ClusterFeature, PointFeature } from "supercluster";
import { Polygon as PolygonLocation } from "@react-google-maps/api";
import {
  Filters,
  MapThemeKeys,
  selectAppConfig,
  selectFilters,
  selectListing,
  selectMap,
  selectParcel,
  selectSearch,
  setCenter,
  setDeleteButtonWatcher,
  setGeometicFilterTypeAllPortals,
  setMapStyle,
  setRefreshFilters,
  setZoom,
} from "@/redux/slices";
import {
  DatasetListingsBackendKey,
  ListingBackendInclude,
  ListingBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  OrganizationKey,
  ParcelBackendExpandKey,
  ParcelBackendInclude,
  ParcelBackendKey,
  SectorBackendKey,
} from "@/keys";
import { useApplyFilters } from "@/hooks/filters/useApplyFilters";
import { recalculateViewport, transformBounds } from "@/utilities";
import { useLazyGetParcelsClustersQuery, useLazyGetListingsClustersQuery } from "@/services";
import { useOnLoadGoogleMaps } from "@/hooks/maps/useOnLoadGoogleMaps.hook";
import { countries, defaultFilters, getOrganization } from "@/configs";
import SpinnerClustersMap from "@/components/loading/SpinnerClustersMap";
import Cluster from "./components/cluster";
import Marker from "./components/marker";
import Polygon from "./components/polygon";
import { InfoWindow } from "./components/infowindow";
import { CreateClusters, ClusterCustomProperties } from "./hooks/CreateClusters";
import { useInfowindow } from "./hooks/useInfowindow";
import { useLocaleCountryCode } from "@/hooks";
import { CreateMarkerPin } from "./hooks/CreateMarkerPin";
import { handleCenterCountryMap } from "./hooks/HandleCenterCountryMap";
import { debounce } from "lodash";
import { sizeViewport } from "@/components/styledComponents";
import mapStyleDark from "@/assets/mapStyles/mapStyleDark.json";
import mapStyleLight from "@/assets/mapStyles/mapStyleLight.json";
import mapStyleSatellite from "@/assets/mapStyles/mapStyleSatellite.json";
import { useTheme } from "styled-components";
import usePolygonLocation from "./hooks/UsePolygonLocation";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type MapProps = {
  clickBlocked: () => void;
  match: { path: string; id: string };
};

const Map = ({ clickBlocked, match }: MapProps) => {
  const dispatch = useAppDispatch();

  const [markerPin, setMarkerPin] = useState<{ lat: number; lng: number } | null>(null);

  const { searchIds, isElasticSearch, loadingSearchIds } = useAppSelector(selectSearch);
  const { portal, windowWidth, builtAreaUnit, landAreaUnit } = useAppSelector(selectAppConfig);
  const { filtersByPortal, areFiltersAvailable, refreshFilters } = useAppSelector(selectFilters);
  const { listingCenter } = useAppSelector(selectListing);
  const { parcelCenter } = useAppSelector(selectParcel);
  const { mapStyle } = useAppSelector(selectMap);
  const { primary } = useTheme();
  const { t } = useTranslation(["errors"]);

  type StylesEnumType = {
    light: google.maps.MapTypeStyle[];
    dark: google.maps.MapTypeStyle[];
    satellite: google.maps.MapTypeStyle[];
  };

  const stylesEnum: StylesEnumType = {
    [MapThemeKeys.light]: mapStyleLight,
    [MapThemeKeys.dark]: mapStyleDark,
    [MapThemeKeys.satellite]: mapStyleSatellite,
  };

  // --------- set map styling when changing the style with the left buttons ------------
  useEffect(() => {
    mapRef?.current?.setOptions({
      styles: stylesEnum[mapStyle.theme],
      mapTypeId: mapStyle.mapTypeId,
    });
  }, [mapStyle]);

  const { mapOptionsClusters, deleteButtonWatcher, infowindow, maxZoomClusters } =
    useAppSelector(selectMap);

  const { applySearchFilters } = useApplyFilters();
  const { InfoWindowOverlayView } = useOnLoadGoogleMaps();
  const { CreateDrawControls } = useCreateDrawingManager();
  const { HandleInfowindow, GoToMapInfowindow } = useInfowindow();
  const { organization } = getOrganization();
  const isInvestPr = organization.key === OrganizationKey.investpr;
  const { countryCode } = useLocaleCountryCode();

  const isMobile = windowWidth < sizeViewport.laptop;

  const [triggerParcels, { data: parcels, isFetching: isFetchingParcels }] =
    useLazyGetParcelsClustersQuery();
  const [triggerListings, { data: listings, isFetching: isFetchingListings }] =
    useLazyGetListingsClustersQuery();

  // ---------------- MAP ----------------
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);
  const isDragMapRef = useRef<boolean>(false);
  const userMapstyleRef = useRef<boolean>(false);
  const isPolygonsZoomedRef = useRef<boolean>(true);
  const { polygonLocation } = usePolygonLocation({ map: mapRef.current });

  // ---------------- INFOWINDOW ----------------
  const infoWindowRef = useRef<google.maps.OverlayView | null>(null);
  const infoWindowPositionRef = useRef<google.maps.LatLng | null>(null);

  // ---------------- FILTERS ----------------
  const filtersRef = useRef<Filters>(filtersByPortal[portal].filters);
  const deletePolygonButtonRef = useRef<HTMLElement | null>(null);
  const wasPolygonCanceledRef = useRef<boolean>(false);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    mapsRef.current = google.maps;

    if (!isMobile) {
      CreateMapControls({
        map: mapRef.current,
        mapTypeId: mapsRef.current.MapTypeId,
        controlPosition: mapsRef.current.ControlPosition,
        userMapstyleRef,
      });
    }

    const drawingManager = CreateDrawControls({
      maps: mapsRef.current,
      map: mapRef.current,
      wasPolygonCanceledRef: wasPolygonCanceledRef,
    });

    // Escucha cada vez que se completa el dibujo de un poligono ----------
    mapsRef.current.event.addListener(
      drawingManager,
      "polygoncomplete",
      (polygon: google.maps.Polygon) => {
        onPolygonCompleted({
          polygon,
          dispatch,
          drawingManager,
          portal,
          filters: filtersRef.current,
          deletePolygonButtonRef,
          applySearchFunction: applySearchFilters,
          wasPolygonCanceledRef,
        });
      },
    );

    // ---------------- CUSTOM INFOWINDOW ----------------
    const center = map.getCenter();
    if (center) {
      infoWindowPositionRef.current = new google.maps.LatLng(center);
      infoWindowRef.current = new InfoWindowOverlayView(
        infoWindowPositionRef.current,
        document.getElementById("infowindow") as HTMLDivElement,
      );
    }
  };

  // Carga datos adicionales en el mapa después de que se haya completado alguna interacción con el usuario o cuando el mapa haya terminado de cargar contenido
  const onIdle = () => {
    // ----------------- START SET VIEWPORT -----------------
    const bounds = transformBounds({ bounds: mapRef.current?.getBounds() });

    // modifica el viewport en todos los filtros y todos los portales para tenerlo siempre actualizado. Si se cambia de portal obtener el último viewport
    dispatch(
      setGeometicFilterTypeAllPortals({
        entity: NewsFiltersEntityFrontKey.location,
        type: NewsFiltersTypeFrontKey.geometric,
        filterValue: {
          [NewsFiltersFrontKey.viewport]: {
            value: recalculateViewport({
              bounds,
              type: "exact",
            }),
          },
        },
      }),
    );

    // console.log(
    //   "bounds: ",
    //   recalculateViewport({
    //     bounds,
    //     type: "exact",
    //   }),
    // );
    // ----------------- END SET VIEWPORT -----------------

    // ----------------- START SET ZOOM -----------------
    dispatch(setZoom({ zoom: mapRef.current?.getZoom() as number }));
    // console.log("zoom: ", mapRef.current?.getZoom());
    // ----------------- END SET ZOOM -----------------

    // ----------------- START SET CENTER -----------------
    dispatch(
      setCenter({
        center: {
          lat: mapRef.current?.getCenter()?.lat() as number,
          lng: mapRef.current?.getCenter()?.lng() as number,
        },
      }),
    );
    // console.log("center: ", mapRef.current?.getCenter()?.lat(), mapRef.current?.getCenter()?.lng());
    // ----------------- END SET CENTER -----------------

    // ----------------- Reset dragMap -----------------
    if (isDragMapRef.current) {
      isDragMapRef.current = false;
    }

    // ----------------- SET MAP TO SATELLITE WHEN TOO ZOOMED TO SEE PARCELS -----------------
    if ((mapRef.current?.getZoom() as number) >= 16) {
      if (!isPolygonsZoomedRef.current) {
        dispatch(setMapStyle({ mapStyle: { mapTypeId: "hybrid", theme: MapThemeKeys.satellite } }));
      }
      isPolygonsZoomedRef.current = true;
    }

    if ((mapRef.current?.getZoom() as number) < 16) {
      if (isPolygonsZoomedRef.current) {
        dispatch(setMapStyle({ mapStyle: { mapTypeId: "roadmap", theme: MapThemeKeys.light } }));
      }
      isPolygonsZoomedRef.current = false;
    }
  };

  // ------------- START Draw Library
  // cada vez q cambian los filtros actualizo
  useEffect(() => {
    filtersRef.current = filtersByPortal[portal].filters;
  }, [filtersByPortal[portal].filters]);

  useEffect(() => {
    if (deleteButtonWatcher && deletePolygonButtonRef.current) {
      deletePolygonButtonRef.current?.dispatchEvent(new MouseEvent("click"));
      dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: false }));
      applySearchFilters();
    }
  }, [deleteButtonWatcher]);
  // ------------- END Draw Library

  // ----- START Carga clusters, markers y polygons cada vez q se cambia el viewport
  const debounceTriggerParcels = debounce((controller: AbortController) => {
    // basic include
    const include: ParcelBackendInclude[] = [
      ParcelBackendKey.id,
      ParcelBackendKey.centroid,
      `${ParcelBackendExpandKey.listings}.${ListingBackendKey.genericListingId}`,
      `${ParcelBackendExpandKey.listings}.${ListingBackendKey.dataset}`,
    ];
    // more columns include to create polygons & markers
    if (
      mapRef.current?.getZoom() !== undefined &&
      (mapRef.current.getZoom() as number) > maxZoomClusters
    )
      include.push(
        ParcelBackendKey.geometry,
        `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
        `${ParcelBackendExpandKey.listings}.${ListingBackendKey.isForRent}`,
        `${ParcelBackendExpandKey.listings}.${ListingBackendKey.active}`,
      );

    triggerParcels({
      countryCode,
      bodyProps: {
        filters: filtersByPortal[portal].appliedFilters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        include,
        searchIds: isElasticSearch ? { parcel: searchIds.parcel } : undefined,
      },
      controller,
    });
  }, 800);

  const debounceTriggerListings = debounce((controller: AbortController) => {
    // basic include
    const include: ListingBackendInclude[] = [
      ListingBackendKey.genericListingId,
      ListingBackendKey.location,
    ];
    // more columns include to create polygons & markers
    if (
      mapRef.current?.getZoom() !== undefined &&
      (mapRef.current.getZoom() as number) > maxZoomClusters
    )
      include.push(ListingBackendKey.isForRent, ListingBackendKey.dataset);

    triggerListings({
      countryCode,
      bodyProps: {
        filters: filtersByPortal[portal].appliedFilters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchListingFilters: true,
        isListing: true,
        include,
        searchIds: isElasticSearch ? { parcel: searchIds.parcel } : undefined,
      },
      controller,
    });
  }, 800);

  useEffect(() => {
    const controller = new AbortController();
    if (
      filtersByPortal[portal].appliedFilters?.location?.geometric?.viewport?.value &&
      areFiltersAvailable &&
      countries[countryCode].hasAccessToPlatform
    ) {
      isInvestPr ? debounceTriggerListings(controller) : debounceTriggerParcels(controller);
    }
    return () => {
      controller.abort();
      isInvestPr ? debounceTriggerListings.cancel() : debounceTriggerParcels.cancel();
    };
  }, [filtersByPortal[portal].appliedFilters, countryCode, areFiltersAvailable]);
  // ----- END Carga clusters, markers y polygons cada vez q se cambia el viewport

  useEffect(() => {
    if (mapRef.current) {
      handleCenterCountryMap({ map: mapRef.current, countryCode, isMobile });
    }
  }, [countryCode, mapRef.current]);

  useEffect(() => {
    if (mapRef.current && refreshFilters) {
      handleCenterCountryMap({ map: mapRef.current, countryCode, isMobile });
      dispatch(setRefreshFilters(false));
    }
  }, [refreshFilters, mapRef.current]);

  const clusters: PointFeature<AnyProps>[] | ClusterFeature<AnyProps>[] = useMemo(() => {
    if (mapRef.current) {
      return CreateClusters({
        listings,
        parcels,
        map: mapRef.current,
      });
    } else {
      return [];
    }
  }, [parcels, listings, mapRef.current, countryCode]);

  // TOAST EN EL MAPA CUANDO NO HAY RESULTADOS
  // useEffect(() => {
  //   if (
  //     (!isFetchingParcels && parcels && parcels.length === 0) ||
  //     (!isFetchingListings && listings && listings.length === 0)
  //   ) {
  //     toast.error(`${t("errors:endpoints.map.noResults")}`, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   }
  // }, [parcels, listings]);

  useEffect(() => {
    if (mapRef.current && infowindow.listingWithParcel) {
      HandleInfowindow({
        infowindow,
        infoWindowRef: infoWindowRef.current,
        clusters,
        parcels,
        listings,
        map: mapRef.current,
      });
    }
  }, [clusters, areFiltersAvailable]);

  useEffect(() => {
    if (mapsRef.current) {
      CreateMarkerPin({
        filterCategoric: {
          filter: filtersByPortal[portal].filters.location.categoric,
          filterName: NewsFiltersFrontKey.address,
        },
        filterGeometric: {
          filter: filtersByPortal[portal].filters.location.geometric,
          filterName: NewsFiltersFrontKey.coordinates,
        },
        maps: mapsRef.current,
        map: mapRef.current,
        setMarkerPin,
      });
    }
  }, [
    filtersByPortal[portal].filters.location.categoric?.[NewsFiltersFrontKey.address]?.appliedValue,
    filtersByPortal[portal].filters.location.geometric?.[NewsFiltersFrontKey.coordinates]
      ?.appliedValue,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GoToMapInfowindow({
      mapRef,
      mapsRef,
      infoWindowRef,
      clusters,
      infoWindowPositionRef,
      infowindow,
    });
  }, [parcelCenter, listingCenter, clusters]);

  const onMapUnmount = () => {
    mapRef.current = null;
  };

  const onDragStart = () => {
    isDragMapRef.current = true;
  };

  return (
    <>
      {isFetchingParcels || isFetchingListings || loadingSearchIds ? <SpinnerClustersMap /> : null}
      <MapContainer>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          options={defaultMapOptions}
          onLoad={onLoad}
          onIdle={onIdle}
          onUnmount={onMapUnmount}
          onDragStart={onDragStart}>
          {markerPin && <MarkerPin position={markerPin} />}
          {clusters?.map(({ id, geometry, properties }, index) => {
            const [lng, lat] = geometry.coordinates;
            const { cluster, point_count_abbreviated, point_count, parcel, listing } =
              properties as ClusterCustomProperties;

            return cluster ? (
              <Cluster
                key={`cluster-${id}`}
                lat={lat}
                lng={lng}
                countRecords={point_count}
                countRecordsAbbreviated={point_count_abbreviated}
                isDragMapRef={isDragMapRef}
                map={mapRef.current}
              />
            ) : parcel ? (
              mapOptionsClusters.zoom <= maxZoomClusters ? (
                <Cluster
                  key={`cluster-${index}`}
                  lat={lat}
                  lng={lng}
                  countRecords={1 + (parcel?.listings?.length ?? 0)}
                  isDragMapRef={isDragMapRef}
                  map={mapRef.current}
                />
              ) : (
                <div key={index}>
                  {parcel?.listings?.length && (
                    <Marker
                      key={`marker-${index}`}
                      listings={parcel.listings}
                      parcelId={parcel.id}
                      lat={lat}
                      lng={lng}
                      isDragMapRef={isDragMapRef}
                      map={mapRef.current}
                      maps={mapsRef.current}
                      infoWindowRef={infoWindowRef}
                      infoWindowPositionRef={infoWindowPositionRef}
                    />
                  )}
                  <Polygon
                    key={`polygon-${index}`}
                    parcel={parcel}
                    isDragMapRef={isDragMapRef}
                    lat={lat}
                    lng={lng}
                    map={mapRef.current}
                    maps={mapsRef.current}
                    infoWindowRef={infoWindowRef}
                    infoWindowPositionRef={infoWindowPositionRef}
                  />
                </div>
              )
            ) : listing ? (
              mapOptionsClusters.zoom <= maxZoomClusters ? (
                <Cluster
                  key={`cluster-${index}`}
                  lat={lat}
                  lng={lng}
                  countRecords={listing?.listings?.length ?? 0}
                  isDragMapRef={isDragMapRef}
                  map={mapRef.current}
                />
              ) : (
                <Marker
                  key={`marker-${index}`}
                  listings={listing.listings}
                  lat={lat}
                  lng={lng}
                  isDragMapRef={isDragMapRef}
                  map={mapRef.current}
                  maps={mapsRef.current}
                  infoWindowRef={infoWindowRef}
                  infoWindowPositionRef={infoWindowPositionRef}
                />
              )
            ) : null;
          })}
          <InfoWindow infoWindowRef={infoWindowRef.current} />
          {polygonLocation && (
            <PolygonLocation
              paths={polygonLocation}
              options={{
                clickable: false,
                strokeColor:
                  mapStyle.theme === MapThemeKeys.light ? primary.color : primary.contrastColor,
                fillColor:
                  mapStyle.theme === MapThemeKeys.light ? primary.color : primary.contrastColor,
                strokeOpacity: 1,
                strokeWeight: 2,
                fillOpacity: 0.2,
              }}
              onUnmount={(polygon) => polygon.setMap(null)}
            />
          )}
        </GoogleMap>
      </MapContainer>
    </>
  );
};
export default Map;
