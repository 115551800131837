import styled from "styled-components";

export const ButtonUnderline = styled.div<{ active?: boolean; disableUnderline?: boolean }>(
  ({ active, disableUnderline, theme }) => `
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  ${
    active &&
    `
    position: relative;
    transition: all 0.2s ease;
    `
  },
  &:hover,
  &:active,
  &:focus {
    &:after {
      ${!disableUnderline && "content: attr(data-before);"}
      height: 3px;
      border-radius: 0.5rem;
      width: 80%;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      bottom: -8.3px;
      background: ${theme.secondary.color};
      transition: all 0.2s linear;
    }
  }
`,
);
