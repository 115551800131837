export const LongNumberIntoAbbreviatedString = ({
  value,
  abbreviation,
}: {
  value: number;
  abbreviation: boolean;
}) => {
  if (value < 1e3) return value;
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(1) + `${abbreviation ? "K" : ""}`;
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + `${abbreviation ? "M" : ""}`;
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + `${abbreviation ? "B" : ""}`;
  if (value >= 1e12) return +(value / 1e12).toFixed(1) + `${abbreviation ? "T" : ""}`;
};
