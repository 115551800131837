import { history } from "@/history";
import setAuthorizationToken from "./setAuthorizationToken";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { URL_BACKEND } from "@/config";
import { updateUserLanguage } from "@/redux/actions";

import { AppDispatch, RootState } from "@/app/store";
import {
  useEndpoint,
  LocalStorageKey,
  UserRoleKey,
  CountryCodeBackendKey,
  PortalKey,
  OrganizationKey,
} from "@/keys";
import { appPaths, defaultPortal, getOrganization, themeConfig } from "@/configs";
import { listsApi, newsApi } from "@/services";
import {
  LoginBackend,
  RegisterBackend,
  responseLoginAdapter,
  responseRegisterAdapter,
  responseUserAdapter,
} from "@/adapters";
import { Login, Register } from "@/models";
import { paramsSerializer, tokenFormat } from "@/utilities";
import {
  changePortal,
  resetFiltersByPortal,
  setCallToActionModalOpen,
  setCallToActionModalPricingsOpen,
  setInfowindow,
  setUser,
  setUserIsLoading,
} from "@/redux/slices";
import i18n from "i18next";
import { defaultLanguageCode, useLocaleCountryCode } from "@/hooks";

const isRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;

export const login = (data: { email: string; password: string }) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_LOGIN_IS_LOADING",
      });

      const params = {
        email: data.email,
        password: data.password,
      };

      const response: AxiosResponse<{ data: LoginBackend }> = await axios.post(
        `${URL_BACKEND}${useEndpoint().login()}`,
        params,
      );

      const login: Login = responseLoginAdapter(response.data.data);

      // SET STORE
      localStorage.setItem(LocalStorageKey.token, login.token);

      dispatch({
        type: "POST_LOGIN",
        payload: login,
      });

      dispatch(getUserIsVerified());
      // Ahora hacemos la redirección aquí, y no en getUserIsVerified()
      history.push(appPaths.home.path);
    } catch (error) {
      dispatch({
        type: "END_LOGIN_IS_LOADING",
      });

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error("Invalid email or password", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      } else {
        console.log(error);
        toast.error("invalid email or password", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };
};

export const getUserIsVerified = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch({
        type: "START_REGISTER_IS_LOADING",
      });

      const token = getState().auth.login.token;

      setAuthorizationToken(token);
      const response = await axios.get(`${URL_BACKEND}${useEndpoint().profile()}`);

      const user = responseUserAdapter(response.data.data);

      if (!user.language) {
        dispatch(
          updateUserLanguage({
            languageCode: defaultLanguageCode,
          }),
        );
      } else {
        i18n.changeLanguage(`${user.language}-${i18n.language.split("-")[1]}`);
      }

      dispatch(setUser({ user, userCheck: true }));
      // if (user.verifiedAt) {
      if (!dispatch(userHasSubscription())) {
        // history.push(appPaths.payment.path);
        // TODO: Definir comportamiento por defecto para cuando se invoque getUserIsVerified()
        // Por ejemplo un PopUp, en lugar de redirigir directamente a /payment.
        // dispatch(setCallToActionModalOpen({ callToActionModalOpen: true }));
        dispatch(setCallToActionModalPricingsOpen({ callToActionModalPricingsOpen: true }));
      }
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      // }
      // --------------- VERIFICACION DE EMAIL ---------------
      // else {
      //   history.push(appPaths.emailVerification.path);
      //   dispatch(setEmailVerificationView(true, true));
      //   dispatch(emailVerificationResend(user.email));
      //   dispatch({
      //     type: "END_REGISTER_IS_LOADING",
      //   });
      // }
    } catch (error) {
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      // Si el token esta vencido se elimina y aparece para loguearte de nuevo
      localStorage.removeItem(LocalStorageKey.token);
      history.push(appPaths.indexHome.path);

      if (axios.isAxiosError(error)) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };
};

export const setToken = (token: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: "SET_TOKEN",
      token: tokenFormat(token),
    });
  };
};

export const logout = () => {
  return async (dispatch: any, getState: () => RootState) => {
    // const user = getState().user.user;

    try {
      localStorage.removeItem(LocalStorageKey.token);

      await dispatch({ type: "START_LOGIN_IS_LOADING" });

      // localStorage.setItem(
      //   "language",
      //   JSON.stringify({
      //     id: user.language.id,
      //     languageCode: user.language.language_code,
      //     countryCode: user.language.country_code,
      //     name: user.language.name,
      //   }),
      // );

      dispatch({ type: "LOGOUT", payload: {} });

      dispatch(setUser({ user: null, userCheck: false }));
      dispatch(resetFiltersByPortal({ portal: PortalKey.professional }));
      dispatch(listsApi.util.resetApiState());
      dispatch(newsApi.util.resetApiState());
      dispatch(
        setInfowindow({
          show: false,
          id: null,
          type: null,
          countListings: undefined,
          listings: undefined,
          parcelId: undefined,
          activeListing: undefined,
        }),
      ),
        dispatch(changePortal({ portal: defaultPortal }));
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      history.push(appPaths.indexHome.path);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };
};

const verifySubscriptionDate = ({
  expirationSubscriptionDate,
  isAdministrator,
}: {
  expirationSubscriptionDate: Date | null | undefined;
  isAdministrator: boolean;
}): boolean => {
  if (!isAdministrator) {
    if (expirationSubscriptionDate) {
      const expirationDate = new Date(expirationSubscriptionDate);
      const todayDate = new Date();
      if (expirationDate < todayDate) {
        return false; // te lleva al pago
      } else {
        return true; // te deja acceder
      }
    } else {
      return false; // te lleva al pago
    }
  } else {
    return true; // te deja acceder
  }
};

// Check if there is a logged in user
export const userIsLogged = () => {
  return (_: any, getState: () => RootState): boolean => {
    const token = getState().auth.login.token;
    const userIsVerified = getState().user?.user?.verifiedAt;
    return token && userIsVerified;
  };
};

// Check if the logged in user has a subscription
export const userHasSubscription = () => {
  return (_: any, getState: () => RootState): boolean => {
    const expirationSubscriptionDate = getState().user?.user?.expirationSubscriptionDate;
    const userRole = getState().user?.user?.role?.name;
    const isAdministrator = userRole === UserRoleKey.administrator;

    return verifySubscriptionDate({ expirationSubscriptionDate, isAdministrator });
  };
};

/**
 * userIsLoggedIn backup function
 * TODO - DELETE if the new version still works fine.
 */

// export const userIsLoggedIn = () => {
//   return (_: any, getState: () => RootState): boolean => {
//     const token = getState().auth.login.token;
//     const userIsVerified = getState().user?.user?.verifiedAt;
//     if (token && userIsVerified) {
//       const expirationSubscriptionDate = getState().user?.user?.expirationSubscriptionDate;
//       const userRole = getState().user?.user?.role?.name;
//       const isAdministrator = userRole === UserRoleKey.administrator;

//       return verifySubscriptionDate({ expirationSubscriptionDate, isAdministrator });
//     } else {
//       return false;
//     }
//   };
// };

export const userIsLoggedIn = () => {
  return (dispatch: any, _: () => RootState): boolean => {
    if (!isRedAtlas) return true;
    if (dispatch(userIsLogged())) {
      return dispatch(userHasSubscription());
    } else {
      return false;
    }
  };
};

export const updatePassword = (currentPassword: string, newPassword: string) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));

      const accessToken = getState().auth.login.token;

      const params = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().profilePassword(),
        method: "put",
        data: params,
        headers: {
          Authorization: accessToken,
        },
      });

      toast.success("Password changed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch(setUserIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error("invalid password", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      } else {
        console.log(error);
      }
    }
  };
};

export const register = (
  data: {
    firstName: string;
    lastName: string;
    market?: { value: number; label: string };
    company?: string;
    phone?: string;
    password: string;
  },
  email: string,
  tokenCaptcha: string,
) => {
  return async (dispatch: any) => {
    const { countryCode } = useLocaleCountryCode();
    try {
      const params = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: email,
        phone: data.phone ?? null,
        captcha: tokenCaptcha,
        password: data.password,
        role: UserRoleKey.broker,
        countryCode: CountryCodeBackendKey[countryCode],
        companyName: data.company ?? null,
        language: defaultLanguageCode,
      };

      const response: AxiosResponse<{ data: RegisterBackend }> = await axios.post(
        `${URL_BACKEND}${useEndpoint().register()}`,
        paramsSerializer(params),
      );

      const register: Register = responseRegisterAdapter(response.data.data);

      localStorage.setItem(LocalStorageKey.token, register.token);

      dispatch({
        type: "SET_TOKEN",
        token: register.token,
      });

      toast.success("Successful registration", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch(getUserIsVerified());
      // Ahora hacemos la redirección aquí, y no en getUserIsVerified()
      history.push(appPaths.home.path);
    } catch (error) {
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error("There was an error in the payment, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const recoverPassword = (email: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_SEND_EMAIL_IS_LOADING",
      });
      const response = await axios.post(`${URL_BACKEND}${useEndpoint().recoverPassword()}`, {
        email: email,
      });
      dispatch({
        type: "END_SEND_EMAIL_IS_LOADING",
      });
      if (response.data.data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      dispatch({
        type: "END_SEND_EMAIL_IS_LOADING",
      });
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            return false;
          } else {
            toast.error("invalid email", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
          }
        }
      } else {
        console.log(error);
      }

      return false;
    }
  };
};

export const restorePassword = (token: string, password: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${URL_BACKEND}${useEndpoint().restorePassword()}`, {
        token: token,
        password: password,
      });
      if (response) {
        toast.success("Password changed successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        history.push(appPaths.signIn.path);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error(
              "There was an error trying to change the password. try again in a few minutes",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              },
            );
          }
        }
      } else {
        console.log(error);
      }
    }
  };
};

export const setEmailVerificationView = (value?: boolean, isLogin?: boolean) => {
  return async (dispatch: any) => {
    dispatch({
      type: "SET_EMAIL_VERIFICATION_VIEW",
      value: value,
      isLogin: isLogin,
    });
  };
};

export const emailVerification = (code: string, email: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_REGISTER_IS_LOADING",
      });
      const response = await axios.post(`${URL_BACKEND}${useEndpoint().emailVerification()}`, {
        code: code,
        email: email,
      });

      const token = tokenFormat(response.data.data);
      localStorage.setItem(LocalStorageKey.token, token);

      dispatch({
        type: "SET_TOKEN",
        token: token,
      });

      if (response.status === 200) {
        toast.success("code verified successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        dispatch(setEmailVerificationView(false));
        dispatch(getUserIsVerified());
        dispatch({
          type: "END_REGISTER_IS_LOADING",
        });
      }
    } catch (error) {
      dispatch({
        type: "END_REGISTER_IS_LOADING",
      });
      toast.error("invalid code", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (axios.isAxiosError(error)) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };
};

export const emailVerificationResend = (email: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(
        `${URL_BACKEND}${useEndpoint().emailVerificationResend()}`,
        {
          email: email,
        },
      );
      if (response) {
        toast.success(`Email successfully sent to ${email}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("We couldn't send the email, please try again in a few minutes", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (axios.isAxiosError(error)) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };
};
