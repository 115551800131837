import { CurrentValuationBackend } from "@/adapters";
import { CurrentValuationBackendKey, CurrentValuationKey } from "@/keys";
import { CurrentValuation } from "@/models";
import { UnitMeasurement } from "@/utilities";

export const responseReportCurrentValuationAdapter = (
  data: CurrentValuationBackend,
): CurrentValuation => ({
  [CurrentValuationKey.builtValue]: data[CurrentValuationBackendKey.builtValue] ?? null,
  [CurrentValuationKey.builtValuePer]: {
    [UnitMeasurement.sqft]: data[CurrentValuationBackendKey.builtValuePerSqft] ?? null,
    [UnitMeasurement.sqm]: data[CurrentValuationBackendKey.builtValuePerSqmt] ?? null,
  },
  [CurrentValuationKey.lotValue]: data[CurrentValuationBackendKey.lotValue] ?? null,
  [CurrentValuationKey.lotValuePer]: {
    [UnitMeasurement.sqm]: data[CurrentValuationBackendKey.lotValuePerSqmt] ?? null,
    [UnitMeasurement.acre]: data[CurrentValuationBackendKey.lotValuePerAcre] ?? null,
  },
  [CurrentValuationKey.rentPerYear]: data[CurrentValuationBackendKey.rentPerYear] ?? null,
  [CurrentValuationKey.rentPerYearPer]: {
    [UnitMeasurement.sqft]: data[CurrentValuationBackendKey.rentPerYearPerSqft] ?? null,
    [UnitMeasurement.sqm]: data[CurrentValuationBackendKey.rentPerYearPerSqmt] ?? null,
  },
  [CurrentValuationKey.totalValue]: data[CurrentValuationBackendKey.totalValue] ?? null,
  [CurrentValuationKey.totalValuePer]: {
    [UnitMeasurement.sqft]: data[CurrentValuationBackendKey.totalValuePerSqft] ?? null,
    [UnitMeasurement.sqm]: data[CurrentValuationBackendKey.totalValuePerSqmt] ?? null,
  },
});
