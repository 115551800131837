import axios from "axios";
import { URL_BACKEND } from "@/config";
import { toast } from "react-toastify";
import { UploadFileTypeKey, useEndpoint } from "@/keys";
import { updateAvatarUser, updateAvatarUserCompany } from "../user";
import { setUserIsLoading } from "@/redux/slices";

export const uploadURLPicture = ({ file, uploadType }) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;

    try {
      dispatch(setUserIsLoading({ isLoading: true }));

      axios.defaults.headers.common["Authorization"] = accessToken;

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().getSignedUrl(),
        method: "post",
        data: {
          extension: file.name.split(".").pop(),
          mimetype: file.type,
          uploadType: uploadType,
        },
        headers: {
          Authorization: accessToken,
          "content-type": "application/json; charset=utf-8",
        },
      });

      if (response.data.data) {
        return dispatch(uploadPicture({ signedUrl: response.data.data, file, uploadType }));
      } else {
        dispatch(setUserIsLoading({ isLoading: false }));
        toast.error(`Picture ${file.name} could not be updated`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      console.log(error.response);
    }
  };
};

export const uploadPicture = ({ signedUrl, file, uploadType }) => {
  return async (dispatch) => {
    try {
      const resp = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "binary/octet-stream",
        },
        body: file,
      });

      console.log(resp);

      dispatch(setUserIsLoading({ isLoading: false }));

      if (resp.ok) {
        const url = resp.url.split("?").shift();
        if (uploadType === UploadFileTypeKey.USER_PICTURE) {
          dispatch(updateAvatarUser({ url }));
        }
        if (uploadType === UploadFileTypeKey.ORGANIZATION_PICTURE) {
          dispatch(updateAvatarUserCompany({ url }));
        }
      } else {
        toast.error(`Picture ${file.name} could not be updated`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      console.log(error.response);
    }
  };
};
