import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { appPaths } from "@/configs";
import {
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  PortalKey,
  TabKey,
} from "@/keys";
import {
  cleanSearch,
  reloadAllTabs,
  selectAppConfig,
  selectFilters,
  selectGridView,
  selectSearch,
  selectTableView,
  setAppliedFilters,
  setDeleteButtonWatcher,
  setIsElasticSearh,
  setPage,
  setUpdatedTab,
  setUserInputSearch,
  setValueFilter,
} from "@/redux/slices";
import { useLocation } from "react-router-dom";
import {
  loadListings,
  loadTenants,
  loadTransactions,
  loadTaxes,
  loadPermits,
  loadParcels,
  loadUnits,
  loadDocuments,
} from "@/services";
import { FilterValue, FiltersType } from "@/models";

export const useApplyFilters = () => {
  const dispatch = useAppDispatch();
  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { pathname } = useLocation();
  const { tabStateByPortal } = useAppSelector(selectTableView);
  const { activeTab } = useAppSelector(selectGridView);
  const { userInputSearch } = useAppSelector(selectSearch);

  const applySearchFilters = () => {
    dispatch(setAppliedFilters({ portal }));
    //Si esta en el mapa..
    if (pathname === appPaths.home.path) {
      dispatch(reloadAllTabs({ portal }));
    }
  };

  const loadTablesView = () => {
    dispatch(reloadAllTabs({ portal }));
    if (activeTab) dispatch(setUpdatedTab({ tab: activeTab, portal }));
    switch (activeTab) {
      case TabKey.properties:
        dispatch(
          loadParcels({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadParcels({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.properties)
              ?.pagination.itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.featuredListings:
        dispatch(
          loadListings({
            tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadListings({
            tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.featuredListings)
              ?.pagination.itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.listings:
        dispatch(
          loadListings({
            tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadListings({
            tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.listings)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.activeListings:
        dispatch(
          loadListings({
            tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadListings({
            tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.activeListings)
              ?.pagination.itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.inactiveListings:
        dispatch(
          loadListings({
            tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadListings({
            tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.inactiveListings)
              ?.pagination.itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.transactions:
        // dispatch(loadTransactionsCount());
        dispatch(
          loadTransactions({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadTransactions({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.transactions)
              ?.pagination.itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.tenants:
        dispatch(
          loadTenants({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadTenants({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.tenants)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.taxes:
        dispatch(
          loadTaxes({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadTaxes({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.taxes)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.permits:
        dispatch(
          loadPermits({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadPermits({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.permits)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.units:
        dispatch(
          loadUnits({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadUnits({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === TabKey.units)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      case TabKey.documents:
        dispatch(
          loadDocuments({
            // tabkey: activeTab,
            isCount: true,
          }),
        );
        dispatch(
          loadDocuments({
            // tabkey: activeTab,
            page: 1,
            perPage: tabStateByPortal[portal].find((tab) => tab.id === activeTab)?.pagination
              .itemsPerPage,
          }),
        );
        dispatch(setPage({ portal, tabkey: activeTab, page: 1 }));
        break;
      default:
        return;
    }
    return;
  };

  const removeFilter = ({
    filterName,
    entityFilterKey,
    typeFilterKey,
  }: {
    filterName: NewsFiltersFrontKey;
    entityFilterKey: NewsFiltersEntityFrontKey;
    typeFilterKey: NewsFiltersTypeFrontKey;
  }) => {
    if (filterName === NewsFiltersFrontKey.drawnPolygon) {
      dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
    }
    const appliedFiltersByPortal =
      portal === PortalKey.professional
        ? filtersByPortal.professional.filters
        : portal === PortalKey.residential
        ? filtersByPortal.residential.filters
        : portal === PortalKey.commercial
        ? filtersByPortal.commercial.filters
        : filtersByPortal.investpr.filters;

    let value = null;

    if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
      value = {
        min: "",
        max: "",
      };
    }

    if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
      value = {
        min: null,
        max: null,
      };
    }

    if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
      value = false;
    }

    let filterChange = {
      ...appliedFiltersByPortal[entityFilterKey][typeFilterKey],
      [filterName]: { value },
    };

    if (filterName === NewsFiltersFrontKey.cityId) {
      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.neighborhoodId]: { value: null },
      };
    }

    if (filterName === NewsFiltersFrontKey.sectorId) {
      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.subsectorId]: { value: null },
        [NewsFiltersFrontKey.microsectorId]: { value: null },
      };
    }

    if (filterName === NewsFiltersFrontKey.subsectorId) {
      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.microsectorId]: { value: null },
      };
    }

    if (filterName === NewsFiltersFrontKey.isFloodZone) {
      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.isFloodZone]: { value: false },
      };
    }

    if (filterName === NewsFiltersFrontKey.drawnPolygon) {
      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.drawnPolygon]: { value: null, appliedValue: null },
      };
    }

    // amenities match
    if (
      (filterName === NewsFiltersFrontKey.amenity ||
        filterName === NewsFiltersFrontKey.amenityRoom ||
        filterName === NewsFiltersFrontKey.amenityView) &&
      typeFilterKey === NewsFiltersTypeFrontKey.categoric
    ) {
      const amenityIdValue =
        appliedFiltersByPortal?.[entityFilterKey]?.[typeFilterKey]?.[NewsFiltersFrontKey.amenityId]
          .appliedValue;

      const amenityValue =
        appliedFiltersByPortal?.[entityFilterKey]?.[typeFilterKey]?.[filterName].appliedValue;

      const adjustedValue = Array.isArray(amenityIdValue)
        ? amenityIdValue.filter((item) => {
            if (Array.isArray(amenityValue))
              return !amenityValue?.some(({ value }) => value === item.value);
          })
        : null;

      const newValue = adjustedValue?.length ? adjustedValue : null;

      filterChange = {
        ...filterChange,
        [NewsFiltersFrontKey.amenityId]: {
          value: newValue,
          appliedValue: newValue,
        },
      };
    }

    dispatch(
      setValueFilter({
        portal: portal,
        entity: entityFilterKey,
        filterTypeValue: {
          [typeFilterKey]: filterChange,
        },
      }),
    );

    // landArea && lotArea match
    if (filterName === NewsFiltersFrontKey.landArea) {
      const listingEntityKey = NewsFiltersEntityFrontKey.listing;
      const listingNameKey = NewsFiltersFrontKey.lotArea;

      const listingFilterChange = {
        ...appliedFiltersByPortal[listingEntityKey][typeFilterKey],
        [listingNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: listingEntityKey,
          filterTypeValue: {
            [typeFilterKey]: listingFilterChange,
          },
        }),
      );
    }

    // builtArea && totalArea match
    if (filterName === NewsFiltersFrontKey.builtArea) {
      const propertyEntityKey = NewsFiltersEntityFrontKey.property;
      const propertyNameKey = NewsFiltersFrontKey.totalArea;

      const propertyFilterChange = {
        ...appliedFiltersByPortal[propertyEntityKey][typeFilterKey],
        [propertyNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: propertyEntityKey,
          filterTypeValue: {
            [typeFilterKey]: propertyFilterChange,
          },
        }),
      );
    }

    // listing.price && transaction.transactionPrice  match
    if (
      filterName === NewsFiltersFrontKey.price &&
      entityFilterKey === NewsFiltersEntityFrontKey.listing
    ) {
      const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
      const transactionNameKey = NewsFiltersFrontKey.transactionPrice;

      const transactionFilterChange = {
        ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
        [transactionNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: transactionEntityKey,
          filterTypeValue: {
            [typeFilterKey]: transactionFilterChange,
          },
        }),
      );
    }

    // listing.pricePerBuiltSqm && transaction.pricePerBuiltSqm  match
    if (
      filterName === NewsFiltersFrontKey.pricePerBuiltSqm &&
      entityFilterKey === NewsFiltersEntityFrontKey.listing
    ) {
      const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
      const transactionNameKey = NewsFiltersFrontKey.pricePerBuiltSqm;

      const transactionFilterChange = {
        ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
        [transactionNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: transactionEntityKey,
          filterTypeValue: {
            [typeFilterKey]: transactionFilterChange,
          },
        }),
      );
    }

    // listing.pricePerLotSqm && transaction.pricePerLotSqm  match
    if (
      filterName === NewsFiltersFrontKey.pricePerLotSqm &&
      entityFilterKey === NewsFiltersEntityFrontKey.listing
    ) {
      const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
      const transactionNameKey = NewsFiltersFrontKey.pricePerLotSqm;

      const transactionFilterChange = {
        ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
        [transactionNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: transactionEntityKey,
          filterTypeValue: {
            [typeFilterKey]: transactionFilterChange,
          },
        }),
      );
    }

    // parcel sector && propertyType / match
    if (
      filterName === NewsFiltersFrontKey.sectorId &&
      entityFilterKey === NewsFiltersEntityFrontKey.parcel
    ) {
      //--- propertyType
      const listingEntityKey = NewsFiltersEntityFrontKey.listing;
      const listingNameKey = NewsFiltersFrontKey.propertyType;

      const propertyFilterChange = {
        ...appliedFiltersByPortal[listingEntityKey][typeFilterKey],
        [listingNameKey]: { value },
      };

      dispatch(
        setValueFilter({
          portal: portal,
          entity: listingEntityKey,
          filterTypeValue: {
            [typeFilterKey]: propertyFilterChange,
          },
        }),
      );
    }

    if (userInputSearch?.value && !userInputSearch?.showPill) dispatch(cleanSearch());

    applySearchFilters();
  };
  return { applySearchFilters, loadTablesView, removeFilter };
};
