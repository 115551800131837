import { PositionTooltipType } from "@/keys";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";

interface TooltipPortalProps {
  targetElement: HTMLElement | null;
  showTooltip: boolean;
  position: keyof typeof PositionTooltipType;
  text: string;
}

interface TooltipTextProps {
  top: number;
  left: number;
  position: keyof typeof PositionTooltipType;
  showTooltip: boolean;
  tooltipWidth: number;
}

const TooltipContainer = styled.div`
  cursor: pointer;
`;

const TooltipText = styled.div<TooltipTextProps>`
  font-size: 12px;
  visibility: ${(props) => (props.showTooltip ? "visible" : "hidden")};
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: fixed;
  z-index: 999999;
  opacity: ${(props) => (props.showTooltip ? 1 : 0)};
  transition: opacity 0.3s;
  max-width: 200px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left + props.tooltipWidth}px; // Ajusta la posición horizontal

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;

    ${({ position }: { position: string }) => {
      switch (position) {
        case "top":
          return css`
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-color: #333 transparent transparent transparent;
          `;
        case "right":
          return css`
            top: 50%;
            left: -12px;
            transform: translateY(-50%);
            border-color: transparent #333 transparent transparent;
          `;
        case "bottom":
          return css`
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
            border-color: transparent transparent #333 transparent;
          `;
        case "left":
          return css`
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            border-color: transparent transparent transparent #333;
          `;
        default:
          return "";
      }
    }}
  }
`;

const TooltipPortal: React.FC<TooltipPortalProps> = ({
  targetElement,
  position,
  text,
  showTooltip,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [newPosition, setNewPosition] = useState<keyof typeof PositionTooltipType>(position);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  useEffect(() => {
    const calculateTooltipPosition = () => {
      if (targetElement) {
        const { top, left, width, height } = targetElement.getBoundingClientRect();
        const tooltipWidth = tooltipRef.current?.offsetWidth ?? 0;
        const tooltipHeight = tooltipRef.current?.offsetHeight ?? 0;
        let tooltipTop, tooltipLeft;

        switch (position) {
          case PositionTooltipType.top:
            if (top - tooltipHeight < 0) {
              tooltipTop = top + height + 10;
              setNewPosition(PositionTooltipType.bottom);
            } else {
              tooltipTop = top - tooltipHeight - 10;
            }
            tooltipLeft = left + width / 2 - tooltipWidth / 2;
            break;
          case PositionTooltipType.right:
            if (left + width + tooltipWidth > window.innerWidth) {
              tooltipLeft = left - tooltipWidth - 10;
              setNewPosition(PositionTooltipType.left);
            } else {
              tooltipLeft = left + width + 10;
            }
            tooltipTop = top + height / 2 - tooltipHeight / 2;
            break;
          case PositionTooltipType.bottom:
            if (top + height + tooltipHeight > window.innerHeight) {
              tooltipTop = top - tooltipHeight - 10;
              setNewPosition(PositionTooltipType.top);
            } else {
              tooltipTop = top + height + 10;
            }
            tooltipLeft = left + width / 2 - tooltipWidth / 2;
            break;
          case PositionTooltipType.left:
            if (left - tooltipWidth < 0) {
              tooltipLeft = left + width + 10;
              setNewPosition(PositionTooltipType.right);
            } else {
              tooltipLeft = left - tooltipWidth - 10;
            }
            tooltipTop = top + height / 2 - tooltipHeight / 2;
            break;
          default:
            tooltipTop = 0;
            tooltipLeft = 0;
        }

        setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
        setTooltipWidth(tooltipWidth / 2 - 20);
      }
    };

    calculateTooltipPosition();
    window.addEventListener("resize", calculateTooltipPosition);

    return () => {
      window.removeEventListener("resize", calculateTooltipPosition);
    };
  }, [targetElement]);

  const tooltipRef = useRef<HTMLDivElement>(null);

  if (!targetElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <TooltipText
      ref={tooltipRef}
      top={tooltipPosition.top}
      left={tooltipPosition.left}
      showTooltip={showTooltip}
      position={newPosition}
      tooltipWidth={tooltipWidth}>
      {text}
    </TooltipText>,
    document.body,
  );
};

export const Tooltip = ({
  text,
  position,
  children,
}: {
  text: string;
  position: keyof typeof PositionTooltipType;
  children: React.ReactNode;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const targetElementRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={targetElementRef}>
      {children}

      <TooltipPortal
        targetElement={targetElementRef.current}
        position={position}
        text={text}
        showTooltip={showTooltip}
      />
    </TooltipContainer>
  );
};
