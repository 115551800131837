import React from "react";
import iconAtlasProfessional from "@/assets/img/logo/productsLogos/logo-atlas-professional.png";
import isoValuationReport from "@/assets/img/icons/features/valuation-report.svg";
import isoBrochureGeneration from "@/assets/img/icons/features/b-generation.svg";
import isoAnalytics from "@/assets/img/icons/features/analytics.svg";
import isoLists from "@/assets/img/icons/features/lists.svg";
import isoNews from "@/assets/img/icons/features/news.svg";
import isoTransactions from "@/assets/img/icons/features/transactions.svg";
import isoPermits from "@/assets/img/icons/features/permits.svg";
import { ButtonXCirclePrimary, Card } from "../styledComponents";
import { useTranslation } from "react-i18next";
import LoginButtons from "./LoginButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
import Video from "./Video";

type CallToActionAtlasProfessionalProps = {
  topMessage1?: string;
  topMessage2?: string;
  onCloseFunction?: () => void;
};

const CallToActionAtlasProfessional: React.FC<CallToActionAtlasProfessionalProps> = ({
  topMessage1,
  topMessage2,
  onCloseFunction,
}) => {
  const { t } = useTranslation(["corporateSite", "payment"]);
  const translation = t("corporateSite:home", { returnObjects: true });

  const features: Array<{
    id: string;
    title: string;
    icon: JSX.Element;
  }> = [
    {
      id: "report",
      title: translation.features.reports.title,
      icon: <img src={isoValuationReport} alt="Valuation Reports" width="30px" height="auto" />,
    },
    {
      id: "brochure",
      title: translation.features.brochures.title,
      icon: (
        <img src={isoBrochureGeneration} alt="Brochure Generation" width="30px" height="auto" />
      ),
    },
    {
      id: "analytics",
      title: translation.features.analytics.title,
      icon: <img src={isoAnalytics} alt="Analytics" width="30px" height="auto" />,
    },
    {
      id: "lists",
      title: translation.features.lists.title,
      icon: <img src={isoLists} alt="Lists" width="30px" height="auto" />,
    },
    {
      id: "news",
      title: translation.features.news.title,
      icon: <img src={isoNews} alt="News" width="30px" height="auto" />,
    },
    {
      id: "transactions",
      title: translation.features.transactions.title,
      icon: <img src={isoTransactions} alt="Transactions" width="25px" height="auto" />,
    },
    {
      id: "permits",
      title: translation.features.permits.title,
      icon: <img src={isoPermits} alt="Permits" width="30px" height="auto" />,
    },
  ];

  return (
    <Card className="p-1 d-flex flex-column justify-content-between">
      {onCloseFunction && (
        <ButtonXCirclePrimary
          size="20px"
          right="17px"
          onClick={() => onCloseFunction()}
          style={{ zIndex: 1400 }}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </ButtonXCirclePrimary>
      )}
      {topMessage1 && (
        <div className="px-1 d-flex align-items-center justify-content-center">
          <span className="fw-bold black mb-0 text-center">{topMessage1}</span>
        </div>
      )}
      {topMessage2 && (
        <div className="px-1 d-flex align-items-center justify-content-center">
          <span className="fw-bold black mb-0 text-center">{topMessage2}</span>
        </div>
      )}
      <div className="px-1 d-flex pb-1 align-items-center justify-content-center">
        <img src={iconAtlasProfessional} alt="atlas-intelligence" width="30" className="mr-05" />
        <h2 className="fw-bold black mb-0">{translation.atlasProfessional}</h2>
      </div>
      <Video />
      <div
        className="mt-1 mb-05"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,auto)",
          gap: "10px",
          justifyContent: "center",
          // alignItems: "center",
        }}>
        {features.map((f) => (
          <div key={f.id}>
            <Card
              id={f.id}
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{
                height: "100%",
                padding: "5px",
              }}>
              <div>
                {f.icon}
                <UncontrolledTooltip placement="bottom" target={f.id}>
                  {f.title}
                </UncontrolledTooltip>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <LoginButtons />
    </Card>
  );
};

export default CallToActionAtlasProfessional;
