import {
  BodyExportFileBackend,
  FiltersBackend,
  FlexibleRequestFiltersBackend,
  PropertyBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  RequestFiltersBackend,
  responsePropertiesAdapter,
  responsePropertyAdapter,
} from "@/adapters";
import {
  KeyofSortDirectionBackendKey,
  TabKey,
  useEndpoint,
  PropertyBackendKey,
  PropertyBackendExpandKey,
  ParcelBackendKey,
  PropertyBackendInclude,
  CountryCodeKey,
  CountryCodeBackendKey,
  PropertyEntityCountBackendKey,
} from "@/keys";
import { EntityCount, Property } from "@/models";
import { api } from "../api";
import { AppThunk } from "@/app/store";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { defaultFilters } from "@/configs";
import { URL_BACKEND } from "@/config";
import { toast } from "react-toastify";
import { useLocaleCountryCode } from "@/hooks";
import { deepClone, deepMerge } from "@/utilities";

const propertyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPropertiesExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PropertyBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createPropertyExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
    getProperty: builder.query<
      Property,
      {
        id: string;
        countryCode: CountryCodeKey;
        bodyProps?: RequestFiltersBackend<PropertyBackendInclude, { isCount: false }>;
      }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().property({ id, countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: PropertyBackend }): Property =>
        responsePropertyAdapter(response.data),
    }),
    getProperties: builder.query<
      Property[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PropertyBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().properties({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: PropertyBackend[] }): Property[] =>
        responsePropertiesAdapter(response.data),
    }),
    getPropertiesCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PropertyBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().properties({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getPropertiesCounts: builder.query<
      EntityCount[],
      {
        controller?: AbortController;
        countryCode: CountryCodeKey;
        entity: PropertyEntityCountBackendKey;
        propertyIds: string[];
        filters?: FiltersBackend[];
      }
    >({
      query({ countryCode, entity, controller, propertyIds, filters }) {
        const baseFilter: FiltersBackend = {
          property: {
            search: {
              id: propertyIds,
            },
          },
        };

        const combinedFilters: FiltersBackend = filters?.length
          ? filters.reduce(
              (acc, filter) => deepMerge(acc, deepClone(filter)),
              deepClone(baseFilter),
            )
          : baseFilter;

        const body = {
          filters: [combinedFilters],
        };

        return {
          url: useEndpoint().properties({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
            entity,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: EntityCount[] }): EntityCount[] => response.data,
    }),
  }),
  overrideExisting: false,
});

export const loadUnits = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    try {
      dispatch(setLoading({ portal, tabkey: TabKey.units, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        defaultFilters: defaultFilters[portal],
        builtAreaUnit,
        landAreaUnit,
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy,
        sortOrder,
        searchIds: isElasticSearch ? { property: searchIds.property } : undefined,
      });

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().properties({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.units, count: responseCount.data.data }));
      } else {
        const include: PropertyBackendInclude[] = [
          `${PropertyBackendKey.id}`,
          `${PropertyBackendKey.address}`,
          `${PropertyBackendKey.addressJSON}`,
          `${PropertyBackendKey.govtId}`,
          `${PropertyBackendKey.totalArea}`,
          `${PropertyBackendKey.unitType}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.address}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.name}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.photos}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.sectorId}`,
          `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.subsectorId}`,
        ];
        const responseColumns: AxiosResponse<{ data: PropertyBackend[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().properties({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
        );
        dispatch(
          setColumns({
            portal,
            tabkey: TabKey.units,
            columns: responsePropertiesAdapter(responseColumns.data.data),
          }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any tenants", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.units, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.units, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useGetPropertyQuery,
  useLazyGetPropertyQuery,
  useGetPropertiesQuery,
  useLazyGetPropertiesQuery,
  useGetPropertiesCountQuery,
  useLazyGetPropertiesCountQuery,
  useLazyGetPropertiesExportFileQuery,
  useGetPropertiesCountsQuery,
} = propertyApi;
