import { responseSubscriptionPlanPriceAdapter, SubscriptionPlanBackend } from "@/adapters";
import { SubscriptionPlanBackendKey } from "@/keys";
import { SubscriptionPlan, SubscriptionPlanPrice } from "@/models";

function mapValueToKey(value: string): keyof typeof SubscriptionPlanBackendKey | undefined {
  const entry = Object.entries(SubscriptionPlanBackendKey).find(([, v]) => v === value);
  return entry ? (entry[0] as keyof typeof SubscriptionPlanBackendKey) : undefined;
}

export const responseSubscriptionPlanAdapter = (
  plan: SubscriptionPlanBackend,
): SubscriptionPlan | undefined => {
  const newPrices: SubscriptionPlanPrice[] = [];
  plan.prices.map((price) => {
    const p = responseSubscriptionPlanPriceAdapter(price);
    if (p) newPrices.push(p);
  });

  const nameKey = mapValueToKey(plan.name);

  if (nameKey && plan.active && newPrices.length) {
    // ---- Ordena los precios de menor a mayor
    newPrices.sort((a, b) => a.price - b.price);

    const newPlan: SubscriptionPlan = {
      id: plan.id,
      name: nameKey,
      active: plan.active,
      prices: newPrices,
    };

    return newPlan;
  } else {
    return;
  }
};
