import { SubscriptionPlanPrice } from "@/models";
import { SubscriptionPlanPriceBackend } from "../models/subscription.backend.model";

export const responseUserSubscriptionPlanPriceAdapter = (
  price: SubscriptionPlanPriceBackend,
): SubscriptionPlanPrice => ({
  id: price.id,
  stripePriceId: price.stripePriceId,
  stripeProductId: price.stripeProductId,
  price: Number(price.price),
  frequency: price.frequency,
  active: price.active,
});
