import {
  UserBackend,
  responseOrganizationCountryAdapter,
  responseUserSubscriptionPlanAdapter,
  responseUserSubscriptionPlanPriceAdapter,
} from "@/adapters";
import { User } from "@/models";

export const responseUserAdapter = (user: UserBackend): User => ({
  id: user.id,
  about: user.about,
  active: user.active,
  address: user.address,
  description: user.description,
  email: user.email,
  expirationSubscriptionDate: user.expirationSubscriptionDate,
  firstName: user.firstName,
  language: user.language,
  lastName: user.lastName,
  password: user.password,
  phone: user.phone,
  photoUrl: user.photoUrl,
  licenseNumber: user.licenseNumber,
  jobTitle: user.jobTitle,
  subscriptionPlan: user.subscriptionPlan
    ? responseUserSubscriptionPlanAdapter(user.subscriptionPlan) ?? null
    : null,
  subscriptionPlanPrice: user.subscriptionPlanPrice
    ? responseUserSubscriptionPlanPriceAdapter(user.subscriptionPlanPrice)
    : null,
  organization: user.organization
    ? {
        address: user.organization.address,
        id: user.organization.id,
        imgUrl: user.organization.imgUrl,
        industry: user.organization.industry,
        legalName: user.organization.legalName,
        name: user.organization.name,
        organizationStatus: user.organization.organizationStatus,
        overview: user.organization.overview,
        phone: user.organization.phone,
        tagline: user.organization.tagline,
        website: user.organization.website,
        country: user.organization.country
          ? responseOrganizationCountryAdapter(user.organization.country)
          : null,
      }
    : null,
  role: {
    id: user.role.id,
    name: user.role.name,
  },
  verifiedAt: user.verifiedAt,
  hasRenovationEnabled: user.hasRenovationEnabled,
});
