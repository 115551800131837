import { ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { SectorKey } from "@/keys";

export function sectorColorsMapper({
  sectorName,
  sectorColor,
  fillColor,
}: {
  sectorName: string | null;
  sectorColor: ThemeType["parcelColors"]["sectors"];
  fillColor: boolean;
}) {
  switch (typeof sectorName === "string" ? sectorName.toLowerCase() : sectorName) {
    case SectorKey.multiFamily:
      return fillColor ? sectorColor.multiFamily.color : sectorColor.multiFamily.contrastColor;
    case SectorKey.assemblyMeetingPlace:
      return fillColor
        ? sectorColor.assemblyMeetingPlace.color
        : sectorColor.assemblyMeetingPlace.contrastColor;
    case SectorKey.singleFamily:
      return fillColor ? sectorColor.singleFamily.color : sectorColor.singleFamily.contrastColor;
    case SectorKey.industrial:
      return fillColor ? sectorColor.industrial.color : sectorColor.industrial.contrastColor;
    case SectorKey.land:
      return fillColor ? sectorColor.land.color : sectorColor.land.contrastColor;
    case SectorKey.lodgingAndHospitality:
      return fillColor
        ? sectorColor.lodgingAndHospitality.color
        : sectorColor.lodgingAndHospitality.contrastColor;
    case SectorKey.office:
      return fillColor ? sectorColor.office.color : sectorColor.office.contrastColor;
    case SectorKey.publicService:
      return fillColor ? sectorColor.publicService.color : sectorColor.publicService.contrastColor;
    case SectorKey.recreation:
      return fillColor ? sectorColor.recreation.color : sectorColor.recreation.contrastColor;
    case SectorKey.retail:
      return fillColor ? sectorColor.retail.color : sectorColor.retail.contrastColor;
    default:
      return fillColor ? sectorColor.default.color : sectorColor.default.contrastColor;
  }
}
