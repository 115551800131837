export const customSortCaseInsensitive = (rowA: string, rowB: string): number => {
  const a = rowA.toLowerCase();
  const b = rowB.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
