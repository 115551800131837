import { Listing } from "@/models";
import { Marker as MarkerGoogle } from "@react-google-maps/api";
import React from "react";
import { useTheme } from "styled-components";
import { useInfowindow } from "../hooks/useInfowindow";
import { TypeInfowindow } from "@/redux/slices";

type MarkerProps = {
  listings: Pick<Listing, "genericListingId" | "isForRent" | "dataset" | "active">[];
  parcelId?: string;
  lat: number;
  lng: number;
  isDragMapRef: React.MutableRefObject<boolean>;
  map: google.maps.Map | null;
  infoWindowRef: React.MutableRefObject<google.maps.OverlayView | null>;
  maps: typeof google.maps | null;
  infoWindowPositionRef: React.MutableRefObject<google.maps.LatLng | null>;
};

const Marker = ({
  listings,
  parcelId,
  lat,
  lng,
  isDragMapRef,
  map,
  infoWindowRef,
  maps,
  infoWindowPositionRef,
}: MarkerProps) => {
  const { common, listingColors } = useTheme();

  const { ShowInfowindow } = useInfowindow();

  const onMarkerClick = () => {
    if (isDragMapRef.current) return;
    ShowInfowindow({
      lat,
      lng,
      map,
      maps,
      infoWindowRef,
      infoWindowPositionRef,
      listing: {
        listings,
        parcelId,
        typeInfowindow: TypeInfowindow.listing,
      },
    });
  };
  return (
    <MarkerGoogle
      position={{
        lat,
        lng,
      }}
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        scale:
          10 +
          (parseFloat(listings.length.toString().length.toString()) +
            parseFloat(listings.length.toString().length.toString()) +
            1), //tamaño
        strokeColor: common.white,
        strokeWeight: 1, //grosor del borde
        fillColor: listingColors.status.multiple, //color de relleno
        fillOpacity: 1, // opacidad del relleno
      }}
      label={{ text: `${listings.length}`, color: common.white }}
      onClick={() => onMarkerClick()}
      onUnmount={(marker) => marker.setMap(null)}
    />
  );
};

export default Marker;
