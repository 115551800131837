import React, { useState, memo, useEffect } from "react";
import { history } from "@/history";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  AmenityBackendKey,
  ListEntityTypeKey,
  ListingBackendExpandKey,
  ListingBackendInclude,
  ListingBackendKey,
  OrganizationKey,
  ParcelBackendExpandKey,
  ParcelBackendInclude,
  ParcelBackendKey,
  PropertyBackendKey,
  SectorBackendKey,
  SourceBackendKey,
} from "@/keys";
import { getOrganization } from "@/configs";
import {
  TypeInfowindow,
  selectMap,
  setCallToActionModalOpen,
  setDuplicatedListings,
  setInfowindow,
  setListing,
} from "@/redux/slices";
import { useLazyGetListingQuery, useLazyGetParcelQuery } from "@/services";
import ModalAvailableMembersLists from "@/components/modals/ModalAvailableMembersLists";
import LoadingInfoWindow from "./components/loading.infowindow";
import ErrorInfowindow from "./components/error.infowindow";
import ParcelInfowindow from "./components/parcel.infowindow";
import ListingInfowindow from "./components/listing.infowindow";
import useRouteQueryString from "@/hooks/queryString/useRouteQueryString.hook";
import { useLocaleCountryCode } from "@/hooks";
import { userIsLogged, userIsLoggedIn } from "@/redux/actions";
import { useAvailableFilters } from "@/hooks/filters/useAvailableFilters";

type InfoWindowProps = {
  infoWindowRef: google.maps.OverlayView | null;
};

const InfoWindow = ({ infoWindowRef }: InfoWindowProps) => {
  const { availableFilters } = useAvailableFilters();
  const listingInclude: ListingBackendInclude[] = [
    `${ListingBackendKey.genericListingId}`,
    `${ListingBackendKey.photos}`,
    `${ListingBackendKey.pricePerBuiltSqm}`,
    `${ListingBackendKey.address}`,
    ListingBackendKey.addressJSON,
    `${ListingBackendKey.price}`,
    `${ListingBackendKey.priceString}`,
    `${ListingBackendKey.propertyType}`,
    `${ListingBackendKey.isForRent}`,
    `${ListingBackendKey.status}`,
    `${ListingBackendKey.builtArea}`,
    `${ListingBackendKey.lotArea}`,
    `${ListingBackendKey.rooms}`,
    `${ListingBackendKey.toilets}`,
    `${ListingBackendKey.createdAt}`,
    `${ListingBackendKey.active}`,
    `${ListingBackendKey.uniqueListingId}`,
    `${ListingBackendExpandKey.amenities}.${AmenityBackendKey.id}`,
    `${ListingBackendExpandKey.source}.${SourceBackendKey.name}`,
    `${ListingBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
    `${ListingBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
  ];

  const {
    infowindow: { id, type, countListings, listings, parcelId, activeListing, show },
  } = useAppSelector(selectMap);

  const [
    triggerInfowindowParcel,
    { isError: isErrorParcel, data: parcel, isFetching: isFetchingParcel },
  ] = useLazyGetParcelQuery();

  const [
    triggerInfowindowListing,
    { isError: isErrorListing, data: listing, isFetching: isFetchingListing },
  ] = useLazyGetListingQuery();

  const { appPropertyProfileQueryString, appListingProfileQueryString } = useRouteQueryString();

  const dispatch = useAppDispatch();

  const [showListsModal, setShowListsModal] = useState(false);
  const toggleModal = () => setShowListsModal(!showListsModal);
  const [memberId, setMemberId] = useState("");
  const [entityType, setEntityType] = useState<keyof typeof ListEntityTypeKey | undefined>(
    undefined,
  );
  const { countryCode } = useLocaleCountryCode();

  const toggleListsModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (!dispatch(userIsLogged())) {
      dispatch(setCallToActionModalOpen({ callToActionModalOpen: true }));
    } else {
      if (id) {
        if (type === TypeInfowindow.parcel) {
          setShowListsModal(true);
          setMemberId(id);
          setEntityType(ListEntityTypeKey.parcel);
        } else {
          if (listing?.uniqueListingId) {
            openDuplicatesModal({ uniqueListingId: listing.uniqueListingId });
          } else {
            setShowListsModal(true);
            setMemberId(id);
            setEntityType(ListEntityTypeKey.listing);
          }
        }
      }
    }
  };

  const onCloseIndowindow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      setInfowindow({
        show: false,
        id: null,
        type: null,
        countListings: undefined,
        listings: undefined,
        parcelId: undefined,
        activeListing: undefined,
      }),
    );
    if (infoWindowRef) {
      infoWindowRef.setMap(null);
    }
  };

  const openDuplicatesModal = ({ uniqueListingId }: { uniqueListingId: string }) => {
    dispatch(
      setDuplicatedListings({
        uniqueListingId,
        duplicatedListingsView: true,
      }),
    );
  };

  const clickLocationProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (id) {
      if (type === TypeInfowindow.parcel) {
        history.push(appPropertyProfileQueryString({ id }));
      } else {
        if (listing?.uniqueListingId) {
          openDuplicatesModal({ uniqueListingId: listing.uniqueListingId });
        } else {
          if (getOrganization().organization.key !== OrganizationKey.redatlas) {
            history.push(appListingProfileQueryString(id));
          } else {
            dispatch(setListing({ listingId: id, listingView: true }));
          }
        }
      }
    }
  };

  const handleNextListing = () => {
    if (id) {
      const currentIndex = listings.findIndex((listing) => listing.id === id);
      let nextIndex = currentIndex + 1;

      if (nextIndex === listings.length) {
        nextIndex = 0;
      }

      triggerInfowindowListing(
        {
          id: listings[nextIndex].id,
          countryCode,
          bodyProps: {
            include: listingInclude,
          },
          userHasAccess: dispatch(userIsLoggedIn()),
          activeListing: listings[nextIndex].active,
        },
        true,
      );

      dispatch(
        setInfowindow({
          show: true,
          id: listings[nextIndex].id,
          type: TypeInfowindow.listing,
          countListings,
          listings,
          parcelId,
          activeListing: listings[nextIndex].active,
        }),
      );
    }
  };

  const handlePrevListing = () => {
    if (id) {
      const currentIndex = listings.findIndex((listing) => listing.id === id);
      let prevIndex = currentIndex - 1;

      if (prevIndex === -1) {
        prevIndex = listings.length - 1;
      }

      triggerInfowindowListing(
        {
          id: listings[prevIndex].id,
          countryCode,
          bodyProps: {
            include: listingInclude,
          },
          userHasAccess: dispatch(userIsLoggedIn()),
          activeListing: listings[prevIndex].active,
        },
        true,
      );

      dispatch(
        setInfowindow({
          show: true,
          id: listings[prevIndex].id,
          type: TypeInfowindow.listing,
          countListings,
          listings,
          parcelId,
          activeListing: listings[prevIndex].active,
        }),
      );
    }
  };

  useEffect(() => {
    if (id && type === TypeInfowindow.parcel && show) {
      const include: ParcelBackendInclude[] = [
        `${ParcelBackendKey.id}`,
        `${ParcelBackendKey.photos}`,
        `${ParcelBackendKey.name}`,
        `${ParcelBackendKey.address}`,
        `${ParcelBackendKey.landArea}`,
        `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
      ];
      if (availableFilters.property) {
        include.push(
          `${ParcelBackendExpandKey.properties}.${PropertyBackendKey.id}`,
          `${ParcelBackendExpandKey.properties}.${PropertyBackendKey.totalArea}`,
        );
      }
      triggerInfowindowParcel(
        {
          id,
          countryCode,
          bodyProps: {
            include,
          },
        },
        true,
      );
    }

    if (id && type === TypeInfowindow.listing && show) {
      triggerInfowindowListing(
        {
          id,
          countryCode,
          bodyProps: {
            include: listingInclude,
          },
          userHasAccess: dispatch(userIsLoggedIn()),
          activeListing: activeListing ? true : false,
        },
        true,
      );
    }
  }, [id, type, show]);

  // ----------- LOADING -----------
  if (isFetchingParcel || isFetchingListing || !show) {
    return (
      <div>
        <div id="infowindow" className="z-index-1">
          <LoadingInfoWindow />
        </div>
      </div>
    );
  }

  // ----------- ERRORS -----------
  if (isErrorParcel || isErrorListing) {
    return (
      <div>
        <div id="infowindow" className="z-index-1">
          <ErrorInfowindow onCloseIndowindow={onCloseIndowindow} />
        </div>
      </div>
    );
  }

  // ----------- COMPONENT -----------
  return (
    <div>
      <div id="infowindow" className="z-index-1">
        {parcel ? (
          parcel.id === id && type === TypeInfowindow.parcel ? (
            <ParcelInfowindow
              parcel={parcel}
              onCloseIndowindow={onCloseIndowindow}
              clickLocationProfile={clickLocationProfile}
              toggleListsModal={toggleListsModal}
            />
          ) : null
        ) : null}
        {listing ? (
          listing.id === id && type === TypeInfowindow.listing ? (
            <ListingInfowindow
              listing={listing}
              hideContent={!dispatch(userIsLoggedIn()) && !activeListing ? true : false}
              onCloseIndowindow={onCloseIndowindow}
              clickLocationProfile={clickLocationProfile}
              toggleListsModal={toggleListsModal}
              handleNextListing={handleNextListing}
              handlePrevListing={handlePrevListing}
            />
          ) : null
        ) : null}
      </div>
      {showListsModal ? (
        <ModalAvailableMembersLists
          showListsModal={showListsModal}
          setShowListsModal={setShowListsModal}
          toggleModal={toggleModal}
          memberId={memberId}
          entityType={entityType}
        />
      ) : null}
    </div>
  );
};

export default memo(InfoWindow);
