import { PropertyBackendKeys } from "./property-backend.key";

export enum TaxBackendKey {
  id = "id",
  address = "address",
  totalValuation = "totalValuation",
  exemption = "exemption",
  land = "land",
  structure = "structure",
  machinery = "machinery",
  exoneration = "exoneration",
  taxable = "taxable",
}

export type TaxBackendKeys = keyof typeof TaxBackendKey;

export enum TaxBackendSortKey {
  // id = "id",
  address = "address",
  totalValuation = "totalValuation",
  // exemption = "exemption",
  land = "land",
  structure = "structure",
  // machinery = "machinery",
  // exoneration = "exoneration",
  taxable = "taxable",
}

export enum TaxBackendExpandKey {
  property = "property",
}

export type TaxBackendExpandKeys = keyof typeof TaxBackendExpandKey;

export type TaxBackendInclude =
  | (TaxBackendKeys | "*")
  | `${TaxBackendExpandKeys}.${PropertyBackendKeys}`;
