import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { AreaChartOptions, OptionType } from "@/models";
import { ApexOptions } from "apexcharts";
import { thousandSeparatorWhithDecimal } from "@/utilities";
import { useTranslation } from "react-i18next";
import { ButtonBorderBottom, ButtonLinkExternal } from "@/components/styledComponents";
import { useTheme } from "styled-components";
import { ArrowDown, ArrowUp } from "react-feather";
import moment from "moment";
import ModalDisclaimer from "@/components/modals/ModalDisclaimer";
import {
  AnalyticsPeriodsStatusKey,
  StatisticsFiltersBackendKey,
  StatisticsGroupByKey,
} from "@/keys";
import {
  StyleReactSelect,
  customTheme,
  componentsSelect,
} from "@/components/reactSelect/StyleReactSelect";
import Select, { SingleValue } from "react-select";
import { countries } from "@/configs";
import { useLocaleCountryCode } from "@/hooks";

type AreaChartSplineStatisticsProps = {
  showFilters?: boolean;
  showPercentages?: boolean;
  showSelectGroup?: boolean;
  options: AreaChartOptions;
  yaxisFormatter?: string;
  defaultFilter: string;
};

type PeriodsProps = {
  id: keyof typeof StatisticsFiltersBackendKey;
  name: string;
  tooltipFormat: string;
  value: number;
  updatedAt?: Date;
};

type PeriodProps = {
  id: keyof typeof StatisticsFiltersBackendKey;
  name: string;
  price: number;
  difference: number;
  percent: number;
  status: keyof typeof AnalyticsPeriodsStatusKey;
  updatedAt?: Date;
  color: string;
};

function AreaChartSplineStatistics({
  showFilters = true,
  showPercentages = true,
  showSelectGroup = false,
  defaultFilter,
  options,
  yaxisFormatter,
}: AreaChartSplineStatisticsProps) {
  const { series, colors } = options;

  const groupOptions: OptionType[] = [
    { value: StatisticsGroupByKey.days, label: "Group by days" },
    { value: StatisticsGroupByKey.months, label: "Group by months" },
    { value: StatisticsGroupByKey.years, label: "Group by years" },
  ];

  const { t } = useTranslation("analytics");
  const translation = t("analytics", { returnObjects: true });
  const { alertColors, primary } = useTheme();
  const { countryCode } = useLocaleCountryCode();
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [periods, setPeriods] = useState<PeriodsProps[]>([]);
  const [period, setPeriod] = useState<PeriodProps>();
  const [filterSeries, setFilterSeries] = useState<AreaChartOptions["series"]>([{ data: [] }]);
  const [groupSelected, setGroupSelected] = useState<OptionType>(groupOptions[0]);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    colors: colors,
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
        type: defaultFilter,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: (val) =>
          (yaxisFormatter ? yaxisFormatter : "") +
          thousandSeparatorWhithDecimal(val.toFixed(2).toString()),
      },
      min: 0,
      forceNiceScale: true,
    },
    tooltip: {
      x: {
        format: "MM/dd/yyyy",
      },
      y: {
        formatter: (val) =>
          (yaxisFormatter ? yaxisFormatter : "") + thousandSeparatorWhithDecimal(val.toString()),
      },
    },
  });

  useEffect(() => {
    if (series[0].filters) {
      const newSeries: PeriodsProps[] = [];
      Object.entries(series[0].filters).map((filter) => {
        if (filter[0] !== "currentPrice") {
          newSeries.push({
            id: filter[0] as keyof typeof StatisticsFiltersBackendKey,
            name:
              filter[0] === StatisticsFiltersBackendKey["1year"]
                ? translation.statistics.filters.oneYear
                : filter[0] === StatisticsFiltersBackendKey["6months"]
                ? translation.statistics.filters.sixMonths
                : filter[0] === StatisticsFiltersBackendKey["3months"]
                ? translation.statistics.filters.threeMonths
                : filter[0] === StatisticsFiltersBackendKey["1month"]
                ? translation.statistics.filters.oneMonth
                : translation.statistics.filters.sevenDays,
            tooltipFormat: "MM/yyyy",
            value: filter[1],
            updatedAt: series[0].updatedAt,
          });
        }
      });
      setPeriods(newSeries.sort().reverse());

      if (chartOptions?.chart?.selection?.type) {
        const findPeriod = newSeries.find((p) => p.id === chartOptions?.chart?.selection?.type);
        if (findPeriod) {
          const currentPrice = series[0].filters.currentPrice;

          setPeriod({
            id: findPeriod?.id,
            name:
              findPeriod?.id === StatisticsFiltersBackendKey["1year"]
                ? translation.statistics.periods.pastYear
                : findPeriod?.id === StatisticsFiltersBackendKey["6months"]
                ? translation.statistics.periods.pastSixMonths
                : findPeriod?.id === StatisticsFiltersBackendKey["3months"]
                ? translation.statistics.periods.pastThreeMonths
                : findPeriod?.id === StatisticsFiltersBackendKey["1month"]
                ? translation.statistics.periods.pastMonth
                : translation.statistics.periods.pastSevenDays,
            price: currentPrice,
            difference: Number((currentPrice - findPeriod?.value).toFixed(2)),
            percent: Number(
              (((currentPrice - findPeriod?.value) / findPeriod?.value) * 100).toFixed(2),
            ),
            status:
              Number((currentPrice - findPeriod?.value).toFixed(2)) === 0
                ? AnalyticsPeriodsStatusKey.neutral
                : Number((currentPrice - findPeriod?.value).toFixed(2)) > 0
                ? AnalyticsPeriodsStatusKey.positive
                : AnalyticsPeriodsStatusKey.negative,
            updatedAt: findPeriod.updatedAt,
            color:
              Number((currentPrice - findPeriod?.value).toFixed(2)) === 0
                ? primary.color
                : Number((currentPrice - findPeriod?.value).toFixed(2)) > 0
                ? alertColors.success
                : alertColors.danger,
          });
        }
      }
    }

    if (series[0].data) {
      // busco en el array la fecha mas reciente y la guardo en una variable
      const arrayData: AreaChartOptions["series"][0]["data"] = [];

      series[0].data.map((data) => arrayData.push({ x: data.x, y: data.y }));

      const sortedArray = arrayData.sort(
        (a, b) => new Date(b.x).getTime() - new Date(a.x).getTime(),
      );
      const newestDate = sortedArray[0].x;

      // Filters by period
      const newfilterSeriesByPeriod: AreaChartOptions["series"] = [{ ...series[0], data: [] }];

      series[0].data.map((data) => {
        const currentDate = moment(newestDate);
        if (chartOptions?.chart?.selection?.type === StatisticsFiltersBackendKey["3months"]) {
          const threeMonthsAgo = moment(currentDate).subtract(3, "months");
          const objDate = moment(data.x);
          if (objDate.isBetween(threeMonthsAgo, currentDate, null, "[]")) {
            newfilterSeriesByPeriod[0].data.push({ x: data.x, y: data.y });
          }
        } else if (
          chartOptions?.chart?.selection?.type === StatisticsFiltersBackendKey["6months"]
        ) {
          const sixMonthsAgo = moment(currentDate).subtract(6, "months");
          const objDate = moment(data.x);
          if (objDate.isBetween(sixMonthsAgo, currentDate, null, "[]")) {
            newfilterSeriesByPeriod[0].data.push({ x: data.x, y: data.y });
          }
        } else if (chartOptions?.chart?.selection?.type === StatisticsFiltersBackendKey["1year"]) {
          const oneYearAgo = moment(currentDate).subtract(1, "years");
          const objDate = moment(data.x);
          if (objDate.isBetween(oneYearAgo, currentDate, null, "[]")) {
            newfilterSeriesByPeriod[0].data.push({ x: data.x, y: data.y });
          }
        } else if (chartOptions?.chart?.selection?.type === StatisticsFiltersBackendKey["1month"]) {
          const oneYearAgo = moment(currentDate).subtract(1, "months");
          const objDate = moment(data.x);
          if (objDate.isBetween(oneYearAgo, currentDate, null, "[]")) {
            newfilterSeriesByPeriod[0].data.push({ x: data.x, y: data.y });
          }
        } else if (chartOptions?.chart?.selection?.type === StatisticsFiltersBackendKey["7days"]) {
          const oneYearAgo = moment(currentDate).subtract(7, "days");
          const objDate = moment(data.x);
          if (objDate.isBetween(oneYearAgo, currentDate, null, "[]")) {
            newfilterSeriesByPeriod[0].data.push({ x: data.x, y: data.y });
          }
        }
      });
      setFilterSeries(newfilterSeriesByPeriod);
    }
  }, [series, chartOptions?.chart?.selection?.type]);

  useEffect(() => {
    if (periods) {
      if (series[0].filters) {
        const currentPrice = series[0].filters.currentPrice;
        const findPeriod = periods.find((p) => p.id === chartOptions?.chart?.selection?.type);
        if (findPeriod) {
          setChartOptions({
            ...chartOptions,
            colors: [
              Number((currentPrice - findPeriod?.value).toFixed(2)) === 0
                ? primary.color
                : Number((currentPrice - findPeriod?.value).toFixed(2)) > 0
                ? alertColors.success
                : alertColors.danger,
            ],
          });
        }
      }
    }
  }, [periods]);

  const updatePeriod = (timeline: string) => {
    const findPeriod = periods.find((p) => p.id === timeline);

    if (findPeriod !== undefined) {
      setChartOptions({
        ...chartOptions,
        chart: {
          ...chartOptions.chart,
          selection: {
            ...chartOptions.chart?.selection,
            type: findPeriod.id,
          },
        },
        tooltip: {
          ...chartOptions.tooltip,
          x: {
            ...chartOptions.tooltip?.x,
            format: findPeriod.tooltipFormat,
          },
        },
      });
    }
  };

  const handleDisclaimer = () => {
    setOpenDisclaimer(!openDisclaimer);
  };

  const handleGroup = (inputValue: SingleValue<OptionType> | null) => {
    if (inputValue) {
      setGroupSelected(inputValue);
    }
  };

  return (
    <>
      <div className="mt-1 d-flex justify-content-between align-items-center">
        {showPercentages && period ? (
          <div>
            <div>
              <span className="fw-bold mr-05">{countries[countryCode].currency}</span>
              <span className="fw-bold" style={{ fontSize: "2rem" }}>
                {thousandSeparatorWhithDecimal(period.price.toFixed(2))}
              </span>
            </div>
            <div
              className="d-flex align-items-center fw-bold"
              style={{
                color:
                  period.status === AnalyticsPeriodsStatusKey.positive
                    ? alertColors.success
                    : period.status === AnalyticsPeriodsStatusKey.negative
                    ? alertColors.danger
                    : primary.color,
                position: "relative",
                top: "-9px",
              }}>
              <span style={{ fontSize: "0.9rem", marginRight: "3px" }}>
                {`${
                  period.status === AnalyticsPeriodsStatusKey.positive ? "+" : ""
                }${thousandSeparatorWhithDecimal(
                  period.difference.toFixed(2),
                )} (${period.percent.toFixed(2)}%)`}{" "}
              </span>

              {period.status === AnalyticsPeriodsStatusKey.positive ? (
                <ArrowUp size={11} />
              ) : period.status === AnalyticsPeriodsStatusKey.negative ? (
                <ArrowDown size={11} />
              ) : null}
              <span style={{ fontSize: "0.9rem" }}>{period.name}</span>
            </div>
            <div
              style={{ fontSize: "0.7rem", position: "relative", top: "-9px" }}
              className="d-flex align-items-center">
              {period.updatedAt ? (
                <div className="mr-05">{moment(period.updatedAt).format("MMM D, h:mm A")}</div>
              ) : null}

              <ButtonLinkExternal
                fontSize="0.7rem"
                style={{ textDecoration: "underline" }}
                onClick={handleDisclaimer}>
                {translation.transactions.averageTransactionsPrice.disclaimer}
              </ButtonLinkExternal>
            </div>
          </div>
        ) : null}
        {showFilters ? (
          <div className="toolbar d-flex align-items-center justify-content-center">
            <div className="btn-group">
              {periods.map((period) => (
                <ButtonBorderBottom
                  key={period.id}
                  id={period.id}
                  active={
                    chartOptions.chart
                      ? chartOptions.chart.selection?.type !== period.id
                        ? false
                        : true
                      : true
                  }
                  onClick={() => updatePeriod(period.id)}>
                  {period.name}
                </ButtonBorderBottom>
              ))}
            </div>
          </div>
        ) : null}
      </div>

      {showSelectGroup ? (
        <div style={{ minWidth: "200px" }}>
          <Select
            options={groupOptions}
            value={groupSelected}
            styles={StyleReactSelect}
            theme={customTheme}
            components={componentsSelect}
            onChange={(inputValue) => handleGroup(inputValue)}
          />
        </div>
      ) : null}

      <ReactApexChart
        options={chartOptions}
        series={filterSeries}
        type="area"
        width={"100%"}
        height={253}
      />

      <ModalDisclaimer openModal={openDisclaimer} toggleModal={handleDisclaimer} />
    </>
  );
}

export default AreaChartSplineStatistics;
