import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useAppSelector } from "@/app/hooks";
import { useCreateListMutation, useUpdateListMutation } from "@/services";
import { selectLists } from "@/redux/slices";
import { ButtonPrimary, ButtonXCirclePrimary } from "../styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useLocaleCountryCode } from "@/hooks";
import { CountryCodeBackendKey } from "@/keys";

type ModalNewEditListProps = {
  isOpen: boolean;
  toggle: () => void;
};

export default function ModalNewEditList({ isOpen, toggle }: ModalNewEditListProps) {
  const { t } = useTranslation("lists");
  const language = t("lists", { returnObjects: true });

  const [listName, setListName] = useState("");
  const [listDescription, setListDescription] = useState("");
  const { countryCode } = useLocaleCountryCode();

  const [
    createPropertiesList,
    { error: errorCreatePropertyList, isLoading: isLoadingCreatePropertyList },
  ] = useCreateListMutation();

  const [
    updatePropertiesList,
    { error: errorUpdatePropertyList, isLoading: isLoadingUpdatePropertyList },
  ] = useUpdateListMutation();

  const { selectedList } = useAppSelector(selectLists);

  useEffect(() => {
    if (selectedList.id) {
      setListName(selectedList.name);
      setListDescription(
        selectedList.description === "No description provided" ? "" : selectedList.description,
      );
    }
  }, [selectedList]);

  const clearState = () => {
    setListName("");
    setListDescription("");
    toggle();
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (selectedList.id) {
      await updatePropertiesList({
        id: selectedList.id,
        body: {
          name: listName,
          description: listDescription,
          countryCode: CountryCodeBackendKey[countryCode],
        },
      }).unwrap();
      clearState();
    } else {
      await createPropertiesList({
        name: listName,
        description: listDescription,
        countryCode: CountryCodeBackendKey[countryCode],
      }).unwrap();
      clearState();
    }
  };

  return (
    <Modal
      zIndex={1400}
      isOpen={isOpen}
      toggle={() => clearState()}
      className="modal-dialog-centered"
      style={{ maxWidth: "300px" }}>
      <div className="recharge-points-header d-flex align-items-center justify-content-between">
        <h5 className="text-center mb-0 black fw-bold">
          {selectedList.id ? language.editList.title : language.createList.title}
        </h5>
        <ButtonXCirclePrimary size="18px" margin="10px" onClick={() => clearState()}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </ButtonXCirclePrimary>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="list-name">{language.createList.name.title}</Label>
            <Input
              type="text"
              name="name"
              id="list-name"
              placeholder={language.createList.name.placeholder}
              required
              value={listName}
              onChange={(e) => setListName(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="mb-0">
            <Label for="list-description">{language.createList.description.title}</Label>
            <Input
              type="textarea"
              name="description"
              id="list-description"
              placeholder={language.createList.description.placeholder}
              value={listDescription}
              onChange={(e) => setListDescription(e.target.value)}
            />
            <small
              className={`counter-value float-right ${
                listDescription ? (listDescription.length > 250 ? "bg-danger" : "") : ""
              }`}>
              {`${listDescription ? listDescription.length : 0}/250`}
            </small>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <ButtonPrimary
          onClick={handleSubmit}
          disabled={
            listName
              ? listName.length >= 2 && listName.length <= 50
                ? listDescription
                  ? listDescription.length > 250
                    ? true
                    : false
                  : false
                : true
              : true
          }>
          {language.createList.buttons.submit}
          {(isLoadingCreatePropertyList || isLoadingUpdatePropertyList) && (
            <Spinner size="sm" color="white" className="ml-05" />
          )}
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
}
