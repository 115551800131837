import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { NewsFiltersEntityFrontKey, TabKey } from "@/keys";
import { SearchIds } from "@/models";

// Define a type for the slice state
interface SearchState {
  userInputSearch: { value: string; showPill: boolean };
  isElasticSearch: boolean;
  searchIds: SearchIds;
  loadingSearchIds: boolean;
}

// Define the initial state using that type
const initialState: SearchState = {
  userInputSearch: { value: "", showPill: false },
  isElasticSearch: false,
  searchIds: {
    [NewsFiltersEntityFrontKey.listing]: [],
    [NewsFiltersEntityFrontKey.parcel]: [],
    [NewsFiltersEntityFrontKey.property]: [],
    [NewsFiltersEntityFrontKey.transaction]: [],
    [NewsFiltersEntityFrontKey.tenant]: [],
    [NewsFiltersEntityFrontKey.tax]: [],
    [NewsFiltersEntityFrontKey.permit]: [],
    [TabKey.documents]: [],
  },
  loadingSearchIds: false,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setUserInputSearch: (
      state,
      { payload }: PayloadAction<{ value: string; showPill: boolean }>,
    ) => {
      state.userInputSearch = payload;
    },
    setIsElasticSearh: (state, { payload }: PayloadAction<boolean>) => {
      state.isElasticSearch = payload;
    },
    setSearchIds: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchIds: SearchIds;
      }>,
    ) => {
      state.searchIds = payload.searchIds;
    },
    setLoadingSearchIds: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingSearchIds = payload;
    },
    cleanSearch: (state) => {
      state.searchIds = initialState.searchIds;
      state.userInputSearch = initialState.userInputSearch;
      state.loadingSearchIds = initialState.loadingSearchIds;
      state.isElasticSearch = initialState.isElasticSearch;
    },
  },
});

// These actions will dispatch in the app
export const {
  setUserInputSearch,
  setIsElasticSearh,
  setSearchIds,
  setLoadingSearchIds,
  cleanSearch,
} = searchSlice.actions;

export const selectSearch = (state: RootState) => state.search;

export default searchSlice.reducer;
