import styled from "styled-components";
import { baseStyles, fadeIn } from "@/components/styledComponents";

export const NavbarCard = styled.div<{
  margin?: string;
  right?: string;
  padding?: string;
  bottom?: string;
  top?: string;
  left?: string;
  position?: string;
  cursorPointer?: boolean;
  height?: string;
  zIndex?: number;
}>((props) => {
  const navbarCard: any = {
    position: props.position ?? "absolute",
    zIndex: props.zIndex ?? "1200",
    color: props.theme.defaultColors.color,
    backgroundColor: props.theme.defaultColors.background,
    borderRadius: baseStyles.borderRadius,
    padding: props.padding ?? "8.6px 10px",
    height: props.height ?? "37.5px",
  };

  if (props.margin !== undefined) {
    navbarCard.margin = props.margin;
  }

  if (props.top !== undefined) {
    navbarCard.top = props.top;
  }

  if (props.left !== undefined) {
    navbarCard.left = props.left;
  }

  if (props.right !== undefined) {
    navbarCard.right = props.right;
  }

  if (props.bottom !== undefined) {
    navbarCard.bottom = props.bottom;
  }
  if (props.cursorPointer !== undefined) {
    navbarCard.cursor = "pointer";
  }

  return navbarCard;
});

export const AnimatedNavbarCard = styled(NavbarCard)`
  animation-duration: 0.7s;
  animation-fill-mode: both;
  -webkit-animation-name: ${fadeIn};
  animation-name: ${fadeIn};
`;

export const NavbarCardContainer = styled.div<{
  margin?: string;
  right?: string;
  bottom?: string;
  top?: string;
  left?: string;
  position?: string;
}>((props) => {
  const navbarCard: any = {
    position: props.position ?? "absolute",
    zIndex: "1200",
    display: "flex",
    alignItems: "center",
  };

  if (props.margin !== undefined) {
    navbarCard.margin = props.margin;
  }

  if (props.top !== undefined) {
    navbarCard.top = props.top;
  }

  if (props.left !== undefined) {
    navbarCard.left = props.left;
  }

  if (props.right !== undefined) {
    navbarCard.right = props.right;
  }

  if (props.bottom !== undefined) {
    navbarCard.bottom = props.bottom;
  }

  return navbarCard;
});
