import setAuthorizationToken from "../auth/setAuthorizationToken";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { URL_BACKEND } from "@/config";
import { getUserIsVerified } from "../auth/loginActions";
import { useEndpoint } from "@/keys";
import { AppDispatch, RootState } from "@/app/store";
import { responseUserAdapter, UserBackend } from "@/adapters";
import { User } from "@/models";
import { setUser, setUserIsLoading, setUserOrganizationPhoto, setUserPhoto } from "@/redux/slices";

export const loadUser = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));
      const token = getState().auth.login.token;

      setAuthorizationToken(token);

      const response: AxiosResponse<{ data: UserBackend }, { data: User }> = await axios.get(
        `${URL_BACKEND}${useEndpoint().profile()}`,
      );

      const user = responseUserAdapter(response.data.data);

      dispatch(setUser({ user: user, userCheck: true }));
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // dispatch(getUserIsVerified());
        }
      } else {
        console.log(error);
      }
    }
  };
};

export const updateAvatarUser = ({ url }: { url: string }) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));
      const accessToken = getState().auth.login.token;

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().profileAvatar(),
        method: "put",
        data: { url: url },
        headers: {
          Authorization: accessToken,
        },
      });

      toast.success("Avatar updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch(setUserPhoto({ photoUrl: url }));
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
        } else {
          if (error?.response?.data)
            toast.error(error.response.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
      }
    }
  };
};

export const updateUser = (
  dataProfile: Partial<User>,
  // , dataBusiness: Partial<Organization>
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));

      const accessToken = getState().auth.login.token;

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().profile(),
        method: "put",
        data: {
          firstName: dataProfile.firstName,
          lastName: dataProfile.lastName,
          phone: dataProfile.phone ?? null,
          licenseNumber: dataProfile.licenseNumber ?? null,
          jobTitle: dataProfile.jobTitle ?? null,
          about: dataProfile.about ?? null,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      // await axios({
      //   baseURL: `${URL_BACKEND}`,
      //   url: useEndpoint().organizations(),
      //   method: "put",
      //   data: paramsSerializer({
      //     name: dataBusiness.name,
      //     industry: dataBusiness?.industry ?? null,
      //     tagline: dataBusiness.tagline ?? null,
      //     address: dataBusiness.address ?? null,
      //     phone: dataBusiness.phone ?? null,
      //     website: dataBusiness.website ?? null,
      //     overview: dataBusiness.overview ?? null,
      //   }),
      //   headers: {
      //     Authorization: accessToken,
      //   },
      // });

      toast.success("User data updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(loadUser());
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
        } else {
          if (error?.response?.data?.errors)
            error.response.data.errors.map((error: any) => {
              return toast.error(error, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
        }
      }
    }
  };
};

export const updateUserLanguage = ({ languageCode }: { languageCode: string }) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));

      const accessToken = getState().auth.login.token;

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().profile(),
        method: "put",
        data: {
          language: languageCode,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      dispatch(loadUser());
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
        }
      }
      //  else {
      //   error.response.data.errors.map((error) => {
      //     return toast.error(error.msg, {
      //       position: toast.POSITION.BOTTOM_RIGHT,
      //     });
      //   });
      // }
    }
  };
};

export const updateAvatarUserCompany = ({ url }: { url: string }) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setUserIsLoading({ isLoading: true }));
      const accessToken = getState().auth.login.token;

      await axios({
        baseURL: `${URL_BACKEND}`,
        url: useEndpoint().organizationsAvatar(),
        method: "put",
        data: { url: url },
        headers: {
          Authorization: accessToken,
        },
      });

      toast.success("Avatar updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch(setUserOrganizationPhoto({ imgUrl: url }));
    } catch (error) {
      dispatch(setUserIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 402) {
          toast.error(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getUserIsVerified());
        } else {
          if (error?.response?.data)
            toast.error(error.response.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
      }
    }
  };
};
