const TOTAL_COUNT = 1400;

const southWest = [{ lng: -65.88788844752173 }, { lat: 18.467107938568844 }];
const northEast = [{ lng: -67.1183401975888 }, { lat: 18.003791601966235 }];
const lngSpan = northEast[0].lng - southWest[0].lng;
const latSpan = northEast[1].lat - southWest[1].lat;

export const fakeClusters = [];

for (var i = 1; i < TOTAL_COUNT; i++) {
  var marker = {
    id: i - 1,
    lat: (southWest[1].lat + latSpan * Math.random()).toString(),
    lng: (southWest[0].lng + lngSpan * Math.random()).toString(),
    countRecords: Math.floor(Math.random() * 2000),
  };
  fakeClusters.push(marker);
}
