import { useAppSelector } from "@/app/hooks";
import {
  Accordion,
  AccordionItem,
  ButtonItem,
  ButtonUnderline,
  sizeViewport,
} from "@/components/styledComponents";
import { useLocaleCountryCode } from "@/hooks";
import { selectAppConfig, selectUser } from "@/redux/slices";
import { Language, languages } from "@/utilities/available-languages";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { useTheme } from "styled-components";

type CountryDropdownProps = {
  fullWidth?: boolean;
  disableUnderline?: boolean;
  flagSize?: string | number;
  variant?: string;
  additionalAction?: () => void;
};

type VariantTypes = {
  [key: string]: JSX.Element;
};

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  fullWidth = false,
  disableUnderline = false,
  flagSize = "2rem",
  variant = "dropdown",
  additionalAction,
}) => {
  const { countryCode } = useLocaleCountryCode();
  const { user } = useAppSelector(selectUser);
  const { windowWidth } = useAppSelector(selectAppConfig);
  const { t, i18n } = useTranslation("corporateSite");
  const translationCountries = t("countries", { returnObjects: true });
  const { defaultColors } = useTheme();
  const isMobile = windowWidth < sizeViewport.laptop;

  const [toggleCountry, setToggleCountry] = useState(false);

  const onClickCountry = (language: Language) => {
    if (language.languages) {
      if (!user?.email) {
        i18n.changeLanguage(language.defaultLanguage);
      } else {
        i18n.changeLanguage(`${user.language}-${language.defaultLanguage.split("-")[1]}`);
      }
    }
    setToggleCountry(false);
    if (additionalAction) additionalAction();
  };
  const getCountries = languages.map((language) => {
    return (
      <ButtonItem
        onClick={() => onClickCountry(language)}
        key={language.countryCode}
        className={classNames({
          active: countryCode === language.countryCode,
        })}>
        <ReactCountryFlag className="country-flag mr-50" countryCode={language.countryCode} svg />
        <span>{translationCountries[language.countryCode]}</span>
      </ButtonItem>
    );
  });

  const variants: VariantTypes = {
    accordion: (
      <>
        <Accordion>
          <AccordionItem
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <ReactCountryFlag
                    className={classNames("country-flag", { "mr-50": !isMobile || fullWidth })}
                    countryCode={countryCode}
                    svg
                    style={{ width: flagSize }}
                  />
                  {(windowWidth >= sizeViewport.tablet || fullWidth) &&
                    (windowWidth <= 1150 && !fullWidth
                      ? countryCode
                      : translationCountries[countryCode])}
                </div>
              </>
            }
            openIcon={faChevronUp}
            closeIcon={faChevronDown}
            containerProps={{ borderBottom: "none" }}
            titleProps={{ height: "1rem", fontSize: "inherit", padding: 0 }}
            contentProps={{ padding: "1rem 0" }}>
            {getCountries}
          </AccordionItem>
        </Accordion>
      </>
    ),
    dropdown: (
      <UncontrolledButtonDropdown
        isOpen={toggleCountry}
        toggle={() => {
          setToggleCountry(!toggleCountry);
        }}
        style={{ width: "100%" }}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="span"
          style={{
            whiteSpace: "nowrap",
            display: "block",
            width: fullWidth ? "100%" : "",
            marginRight: isMobile && !fullWidth ? "1rem" : "inherit",
          }}>
          <ButtonUnderline
            active={toggleCountry}
            disableUnderline={disableUnderline}
            style={{
              width: fullWidth ? "100%" : "",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ReactCountryFlag
                className={classNames("country-flag", { "mr-50": !isMobile || fullWidth })}
                countryCode={countryCode}
                svg
                style={{ width: flagSize }}
              />
              {(windowWidth >= sizeViewport.tablet || fullWidth) &&
                (windowWidth <= 1150 && !fullWidth
                  ? countryCode
                  : translationCountries[countryCode])}
            </div>

            <ChevronDown
              size={18}
              className={` ${
                windowWidth <= sizeViewport.laptop && !fullWidth ? "" : "ml-1-custom"
              }`}
              style={{ margin: 0, padding: 0 }}
            />
          </ButtonUnderline>
        </DropdownToggle>

        <DropdownMenu
          tag="ul"
          right={!fullWidth && isMobile}
          className={classNames("", {
            "dropdown-menu-corporate-left": !fullWidth && !isMobile,
            "dropdown-menu-corporate": fullWidth,
            "elevation-1": fullWidth,
          })}
          style={{
            backgroundColor: defaultColors.background,
            width: fullWidth ? "calc(100vw - 1.5rem" : "auto",
          }}>
          {getCountries}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    ),
  };

  return variants[variant];
};

export default CountryDropdown;
