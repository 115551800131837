import React, { useCallback, useEffect, useRef, useState } from "react";
import { GroupBase, InputActionMeta, OptionsOrGroups, PropsValue } from "react-select";
import {
  StyleReactSelectFilters,
  customTheme,
} from "@/components/reactSelect/StyleReactSelectFilters";
import { CountryCodeKey, NewsFiltersEntityFrontKey, NewsFiltersFrontKey } from "@/keys";
import { useAppSelector } from "@/app/hooks";
import { Filters, selectAppConfig, selectFilters } from "@/redux/slices";
import { useFilterSelect, useLocaleCountryCode } from "@/hooks";
import classNames from "classnames";
import { Neighborhood, OptionType } from "@/models";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";
import { Option } from "react-select/src/filters";
import AsyncSelect from "react-select/async";
import { sizeViewport } from "@/components/styledComponents";

interface AsyncSelectFilterProps {
  isAdvancedFilters: boolean;
  filterKey: keyof typeof NewsFiltersFrontKey;
  entityKey: keyof typeof NewsFiltersEntityFrontKey;
  title: string;
  placeholder: string;
  options?: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  isMulti?: true;
  isClearable?: boolean;
  isDisabled?: boolean;
  value?: PropsValue<OptionType>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  noOptionsMessage?: (obj: { inputValue: string }) => string | undefined;
  isLoading?: boolean;
  loadingMessage?: () => string;
  hideSelectedOptions?: boolean;
  components?: SelectComponentsConfig<OptionType, true, GroupBase<OptionType>>;
  cacheOptions?: any;
  defaultOptions?: boolean | OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void,
  ) => Promise<OptionsOrGroups<Option, GroupBase<Option>>> | void;
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  closeMenuOnSelect?: boolean;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
}

function AsyncSelectFilter({
  isAdvancedFilters,
  filterKey,
  entityKey,
  title,
  placeholder,
  options,
  isMulti,
  isClearable,
  isDisabled,
  value,
  onInputChange,
  noOptionsMessage,
  isLoading,
  loadingMessage,
  hideSelectedOptions,
  components,
  cacheOptions,
  defaultOptions,
  loadOptions,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  closeMenuOnSelect,
  setLocalFilters,
}: AsyncSelectFilterProps) {
  const { handleChange } = useFilterSelect();
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { countryCode } = useLocaleCountryCode();
  const isMobile = windowWidth < sizeViewport.laptop;

  const handleOnBlur = () => {
    setLocalFilters(filtersByPortal[portal].filters);
  };

  const currentFilter = `${entityKey}.${filterKey}`;

  return (
    <div
      className={classNames("col-12 mb-1", {
        "col-xl-3": isAdvancedFilters,
        "col-md-4": isAdvancedFilters,
        "col-lg-6": !isAdvancedFilters,
        "col-md-6": !isAdvancedFilters,
        "col-sm-12": !isAdvancedFilters,
      })}>
      <h6 className="mb-05 fw-bold black ml-1">{title}</h6>
      <AsyncSelect
        placeholder={placeholder}
        name={filterKey}
        cacheOptions={cacheOptions}
        options={options}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        styles={StyleReactSelectFilters(isMobile)}
        theme={customTheme}
        onChange={(inputValue, actionMeta) => {
          if (
            countryCode === CountryCodeKey.PR &&
            filterKey === NewsFiltersFrontKey.neighborhoodId
          ) {
            const newActionMeta = {
              ...actionMeta,
              name: (inputValue as unknown as Neighborhood)?.isNeighborhood
                ? NewsFiltersFrontKey.neighborhoodId
                : NewsFiltersFrontKey.subNeighborhoodId,
            };
            handleChange(inputValue, newActionMeta, entityKey, filtersByPortal[portal].filters);
          } else {
            handleChange(inputValue, actionMeta, entityKey, filtersByPortal[portal].filters);
          }
        }}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        value={value}
        hideSelectedOptions={hideSelectedOptions}
        onMenuClose={() => {
          if (activeSelect === currentFilter) {
            setMenuState(false);
            setActiveSelect(null);
          }
        }}
        onMenuOpen={() => {
          setMenuState(true);
          setActiveSelect(currentFilter);
        }}
        menuIsOpen={activeSelect === currentFilter && menuState}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPortalTarget={containerFilterRef.current}
        menuPosition="fixed"
        components={components}
        onBlur={() => handleOnBlur()}
      />
    </div>
  );
}
export default AsyncSelectFilter;
