import { CategoricFilters, CategoricValueFilters } from "@/models";
import { AmenityTypeBackendKey, CategoricFiltersBackend } from "@/adapters";
import { capitalizeFirstLetter, reorderSectors, reorderTransactionSectors } from "@/utilities";
import { CategoricFiltersKey, SectorKey } from "@/keys";

export const categoricSectorLabel = ({ label }: { label: string }) => {
  for (const key in SectorKey) {
    if (SectorKey[key as keyof typeof SectorKey] === label) {
      return key;
    }
  }
  return label;
};

export const responseCategoricsFiltersAdapter = ({
  parcel,
  listing,
  listingAmenity,
  property,
  transaction,
  tenant,
  permit,
}: CategoricFiltersBackend): CategoricFilters => {
  const propertyTypes: CategoricValueFilters[] = [];

  if (listing?.propertyType?.length) {
    listing.propertyType.map((type) =>
      propertyTypes.push({
        value: type.value,
        label: type.enLabel,
        name: type.enLabel, // copia del label para poder tener un identificador unico para hacer match con el sector
      }),
    );
    propertyTypes.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }

  const newSectors: CategoricValueFilters[] = [];
  if (parcel?.sectorId?.length) {
    parcel.sectorId.map((sector) =>
      newSectors.push({
        value: sector.value,
        label: categoricSectorLabel({ label: sector.enLabel.toLowerCase() }),
        name: sector.enLabel.toLocaleLowerCase(), // copia del label para poder tener un identificador unico para hacer match con el propertyType
      }),
    );
  }

  const newSubsectors: CategoricValueFilters[] = [];
  if (parcel?.subsectorId?.length) {
    parcel.subsectorId.map((subsector) =>
      newSubsectors.push({
        value: subsector.value,
        label: capitalizeFirstLetter({ string: subsector.enLabel }),
        esLabel: capitalizeFirstLetter({ string: subsector.esLabel }),
        sectorId: subsector.sectorId,
      }),
    );
  }

  const newMicrosectors: CategoricValueFilters[] = [];
  if (parcel?.microsectorId?.length) {
    parcel.microsectorId.map((microsector) =>
      newMicrosectors.push({
        value: microsector.value,
        label: capitalizeFirstLetter({ string: microsector.enLabel }),
        esLabel: capitalizeFirstLetter({ string: microsector.esLabel }),
        subsectorId: microsector.subsectorId,
      }),
    );
  }

  const newAmenities: CategoricValueFilters[] = [];
  const newAmenitiesRooms: CategoricValueFilters[] = [];
  const newAmenitiesViews: CategoricValueFilters[] = [];
  let newAmenitiestop10: CategoricValueFilters[] = [];
  if (listingAmenity?.amenityId?.length) {
    listingAmenity.amenityId.map((amenity) => {
      if (amenity.amenityType === null) {
        newAmenities.push({
          value: amenity.value,
          label: capitalizeFirstLetter({ string: amenity.enLabel }),
          esLabel: capitalizeFirstLetter({ string: amenity.esLabel }),
          amenityType: amenity.amenityType,
        });
      }
      if (amenity.amenityType === AmenityTypeBackendKey.room) {
        newAmenitiesRooms.push({
          value: amenity.value,
          label: capitalizeFirstLetter({ string: amenity.enLabel }),
          esLabel: capitalizeFirstLetter({ string: amenity.esLabel }),
          amenityType: amenity.amenityType,
        });
      }
      if (amenity.amenityType === AmenityTypeBackendKey.view) {
        newAmenitiesViews.push({
          value: amenity.value,
          label: capitalizeFirstLetter({ string: amenity.enLabel }),
          esLabel: capitalizeFirstLetter({ string: amenity.esLabel }),
          amenityType: amenity.amenityType,
        });
      }
    });
    const top10 = listingAmenity.amenityId.sort((a, b) => b.count - a.count).slice(0, 10);
    newAmenitiestop10 = top10.map((amenity) => ({
      value: amenity.value,
      label: amenity.enLabel,
      esLabel: amenity.esLabel,
      amenityType: amenity.amenityType,
    }));
  }

  const newPermitSectors: CategoricValueFilters[] = [];
  const newPermitSubsectors: CategoricValueFilters[] = [];
  const newPermitCategories: CategoricValueFilters[] = [];
  const newPermitStatuses: CategoricValueFilters[] = [];
  if (permit) {
    const { sectorId, subsectorId, category, status } = permit;
    if (sectorId?.length) {
      sectorId.map((sector) => {
        newPermitSectors.push({
          value: sector.value,
          label: sector.enLabel,
          esLabel: sector.esLabel,
        });
      });
    }
    if (subsectorId?.length) {
      subsectorId.map((subsector) => {
        newPermitSubsectors.push({
          value: subsector.value,
          label: subsector.enLabel,
          esLabel: subsector.esLabel,
          sectorId: subsector.sectorId,
        });
      });
    }
    if (category?.length) {
      category.map((category) => {
        newPermitCategories.push({
          value: category.value,
          label: capitalizeFirstLetter({ string: category.enLabel }),
          esLabel: capitalizeFirstLetter({ string: category.esLabel }),
        });
      });
    }
    if (status?.length) {
      status.map((status) => {
        newPermitStatuses.push({
          value: status.value,
          label: capitalizeFirstLetter({ string: status.enLabel }),
          esLabel: capitalizeFirstLetter({ string: status.esLabel }),
        });
      });
    }
  }

  const newUnitTypes: CategoricValueFilters[] = [];
  if (property?.unitType?.length) {
    property.unitType.map((unitType) => {
      newUnitTypes.push({
        value: unitType.value,
        label: capitalizeFirstLetter({ string: unitType.enLabel }),
        esLabel: capitalizeFirstLetter({ string: unitType.esLabel }),
      });
    });
  }

  const newTransactionSectors: CategoricValueFilters[] = [];
  const newTransactionSubsectors: CategoricValueFilters[] = [];
  const newTransactionMicrosectors: CategoricValueFilters[] = [];
  if (transaction) {
    const { sectorId, subsectorId, microsectorId } = transaction;
    if (sectorId?.length) {
      sectorId.map((sector) => {
        newTransactionSectors.push({
          value: sector.value,
          label: sector.enLabel,
          esLabel: sector.esLabel,
        });
      });
    }
    if (subsectorId?.length) {
      subsectorId.map((subsector) => {
        newTransactionSubsectors.push({
          value: subsector.value,
          label: subsector.enLabel,
          esLabel: subsector.esLabel,
          sectorId: subsector.sectorId,
        });
      });
    }
    if (microsectorId?.length) {
      microsectorId.map((microsector) => {
        newTransactionMicrosectors.push({
          value: microsector.value,
          label: microsector.enLabel,
          esLabel: microsector.esLabel,
          subsectorId: microsector.subsectorId,
        });
      });
    }
  }

  const newApartmentTypes: CategoricValueFilters[] = [];
  if (listing?.apartmentType?.length) {
    listing.apartmentType.map((type) =>
      newApartmentTypes.push({
        value: type.value,
        label: type.enLabel,
        esLabel: type.esLabel,
      }),
    );
  }

  const newStatuses: CategoricValueFilters[] = [];
  if (listing?.status?.length) {
    listing.status.map((status) =>
      newStatuses.push({
        value: status.value,
        label: status.enLabel,
      }),
    );
  }

  return {
    [CategoricFiltersKey.propertyType]: propertyTypes,
    [CategoricFiltersKey.apartmentType]: newApartmentTypes,
    [CategoricFiltersKey.status]: newStatuses,
    [CategoricFiltersKey.sectors]: reorderSectors(newSectors),
    [CategoricFiltersKey.subsectors]: newSubsectors,
    [CategoricFiltersKey.microsectors]: newMicrosectors,
    [CategoricFiltersKey.amenities]: newAmenities,
    [CategoricFiltersKey.amenitiesRooms]: newAmenitiesRooms,
    [CategoricFiltersKey.amenitiesViews]: newAmenitiesViews,
    [CategoricFiltersKey.amenitiesTop10]: newAmenitiestop10,
    [CategoricFiltersKey.permitSectors]: newPermitSectors,
    [CategoricFiltersKey.permitSubsectors]: newPermitSubsectors,
    [CategoricFiltersKey.permitCategories]: newPermitCategories,
    [CategoricFiltersKey.permitStatuses]: newPermitStatuses,
    [CategoricFiltersKey.unitTypes]: newUnitTypes,
    [CategoricFiltersKey.transactionSectors]: reorderTransactionSectors(newTransactionSectors),
    [CategoricFiltersKey.transactionSubsectors]: newTransactionSubsectors,
    [CategoricFiltersKey.transactionMicrosectors]: newTransactionMicrosectors,
  };
};
