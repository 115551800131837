import { URL_BACKEND } from "@/config";
import { useEndpoint } from "@/keys";
import { OptionType } from "@/models";
import { api } from "@/services";

const corporateSiteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loadContactForm: builder.query<
      string,
      {
        formType: string;
        firstName: string;
        lastName: string;
        company?: string;
        linkedinProfile?: string;
        subject: OptionType;
        phone?: string;
        email: string;
        message: string;
        attachmentUrl?: string;
        subscribe: boolean;
      }
    >({
      query({ formType, firstName, lastName, company, linkedinProfile, subject, phone, email, message, attachmentUrl, subscribe }) {
        return {
          url: `${URL_BACKEND}${useEndpoint().contact()}`,
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: {
            type: formType,
            name: `${firstName} ${lastName}`,
            email: email,
            otherData: {
              company: company,
              attachMentURL: attachmentUrl,
              linkedinProfile: linkedinProfile,
              subject: subject.value,
              message: message,
              is_subscribed: subscribe,
            },
            phone: phone,
          },
        };
      },
      transformResponse: (response: void): string => "success",
    }),
  }),
  overrideExisting: false,
});

export const { useLazyLoadContactFormQuery } = corporateSiteApi;
