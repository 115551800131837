import React from "react";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { Polygon as PolygonGoogle } from "@react-google-maps/api";
import { useTheme } from "styled-components";
import { ClusterCustomProperties } from "../hooks/CreateClusters";
import { TypeInfowindow } from "@/redux/slices";
import { useInfowindow } from "../hooks/useInfowindow";

type PolygonProps = {
  parcel: ClusterCustomProperties["parcel"];
  isDragMapRef: React.MutableRefObject<boolean>;
  lat: number;
  lng: number;
  map: google.maps.Map | null;
  infoWindowRef: React.MutableRefObject<google.maps.OverlayView | null>;
  infoWindowPositionRef: React.MutableRefObject<google.maps.LatLng | null>;
  maps: typeof google.maps | null;
};

const Polygon = ({
  parcel,
  isDragMapRef,
  lat,
  lng,
  map,
  maps,
  infoWindowRef,
  infoWindowPositionRef,
}: PolygonProps) => {
  const { parcelColors } = useTheme();
  const { ShowInfowindow } = useInfowindow();

  const onPolygonClick = () => {
    if (isDragMapRef.current) return;

    ShowInfowindow({
      lat,
      lng,
      map,
      maps,
      infoWindowRef,
      infoWindowPositionRef,
      parcel: {
        id: parcel?.id as string,
        typeInfowindow: TypeInfowindow.parcel,
        listings: parcel?.listings ?? [],
      },
    });
  };

  return (
    <>
      {parcel?.geometry ? (
        <PolygonGoogle
          paths={parcel.geometry}
          options={{
            strokeColor: sectorColorsMapper({
              // Define el color del borde del polígono
              sectorName: parcel?.sector?.name ?? null,
              sectorColor: parcelColors.sectors,
              fillColor: true,
            }),
            fillColor: sectorColorsMapper({
              // Define el color de relleno del polígono
              sectorName: parcel?.sector?.name ?? null,
              sectorColor: parcelColors.sectors,
              fillColor: true,
            }),
            strokeOpacity: 1, // Define la opacidad del borde del polígono
            strokeWeight: 2, // Define el grosor del borde del polígono
            fillOpacity: 0.6, // Define la opacidad del relleno del polígono
          }}
          onClick={() => onPolygonClick()}
          onUnmount={(polygon) => polygon.setMap(null)}
        />
      ) : null}
    </>
  );
};

export default Polygon;
