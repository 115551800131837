export const addDays = ({ date = new Date(), numOfDates }: { date?: Date; numOfDates: number }) => {
  date.setDate(date.getDate() + numOfDates);
  return date;
};

export const removeDays = ({
  date = new Date(),
  numOfDates,
}: {
  date?: Date;
  numOfDates: number;
}) => {
  date.setDate(date.getDate() - numOfDates);
  return date;
};

export const addMonths = ({
  date = new Date(),
  numOfMonths,
}: {
  date?: Date;
  numOfMonths: number;
}) => {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
};

export const removeMonths = ({
  date = new Date(),
  numOfMonths,
}: {
  date?: Date;
  numOfMonths: number;
}) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
};

export const daysBetween = ({ fromDate, toDate }: { fromDate: Date; toDate: Date }) => {
  const difference = toDate.getTime() - fromDate.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};
