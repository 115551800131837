import { ZipCode } from "@/models";
import { ZipCodeBackend } from "@/adapters";
import { convertGeoJson } from "@/utilities";

export const responseZipCodesAdapter = (zipCodes: ZipCodeBackend[]): ZipCode[] => {
  const data: ZipCode[] = [];
  if (zipCodes.length) {
    zipCodes.map((zipCode) => {
      data.push({
        id: zipCode.id,
        zipCode: zipCode.zipCode,
        geometry: zipCode.geometry
          ? convertGeoJson({
              geojson: zipCode.geometry,
            })
          : null,
      });
    });
  }

  return data;
};
