import { AxiosCall } from "@/models";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const useFetchAndLoad = () => {
  const [isLoading, setIsLoading] = useState(false);
  let controller: AbortController;

  const callEndpoint = async (axiosCall: AxiosCall<any>) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    setIsLoading(true);
    let result = {} as AxiosResponse<any>;
    try {
      result = await axiosCall.call;
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
    setIsLoading(false);
    return result;
  };

  const cancelEndpoint = () => {
    setIsLoading(false);
    controller && controller.abort();
  };

  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { isLoading, callEndpoint };
};

export default useFetchAndLoad;
