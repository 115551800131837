import {
  ReportListingBackend,
  responseListingAdapter,
  responseNeighborhoodAdapter,
  responseParcelAdapter,
  responseSubNeighborhoodAdapter,
  responseComparableListingAdapter,
  responseComparableTransactionsAdapter,
  responseReportValuationAdapter,
  responseTransactionsAdapter,
  responseAnalyticsAreaChartAdapter,
  responseAnalyticsColumnChartAdapter,
} from "@/adapters";
import { ReportListing } from "@/models";

export const responseReportListingAdapter = (data: ReportListingBackend): ReportListing => {
  const newData: ReportListing = {
    report: {
      listing: data.report.listing ? responseListingAdapter(data.report.listing) : null,
      parcel: data.report.parcel ? responseParcelAdapter(data.report.parcel) : undefined,
      lastTransaction: data.report.lastTransaction
        ? responseTransactionsAdapter(data.report.lastTransaction)
        : undefined,
      neighborhood: data.report.neighborhood
        ? responseNeighborhoodAdapter(data.report.neighborhood)
        : null,
      subNeighborhood: data.report.subNeighborhood
        ? responseSubNeighborhoodAdapter(data.report.subNeighborhood)
        : null,
      comparables: {
        activeListings: data.report.comparables.activeListings
          ? responseComparableListingAdapter(data.report.comparables.activeListings)
          : [],
        inactiveListings: data.report.comparables.inactiveListings
          ? responseComparableListingAdapter(data.report.comparables.inactiveListings)
          : [],
        transactions: data.report.comparables.transactions
          ? responseComparableTransactionsAdapter(data.report.comparables.transactions)
          : [],
      },
      analytics: {
        transactionAveragePrice: data.report.analytics.transactionAveragePrice
          ? {
              columnChart: responseAnalyticsColumnChartAdapter(
                data.report.analytics.transactionAveragePrice,
              ),
              areaChart: responseAnalyticsAreaChartAdapter(
                data.report.analytics.transactionAveragePrice,
              ),
            }
          : {
              columnChart: undefined,
              areaChart: undefined,
            },
        transactionVolume: data.report.analytics.transactionVolume
          ? {
              columnChart: responseAnalyticsColumnChartAdapter(
                data.report.analytics.transactionVolume,
              ),
              areaChart: responseAnalyticsAreaChartAdapter(data.report.analytics.transactionVolume),
            }
          : {
              columnChart: undefined,
              areaChart: undefined,
            },
      },
      valuation: data.report.valuation
        ? responseReportValuationAdapter(data.report.valuation)
        : null,
    },
    userForm: data.userForm,
  };

  return newData;
};
