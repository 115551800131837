import { CountryOptionType } from "@/models";
import { CountryBackend } from "@/adapters";
import { CountryCodeBackendKey } from "@/keys";

export const responseCountriesAdapter = (countries: CountryBackend[]): CountryOptionType[] => {
  const data: CountryOptionType[] = [];
  if (countries.length) {
    countries.map((country) => {
      const valuesCountryCodeBackendKey = Object.values(CountryCodeBackendKey);
      valuesCountryCodeBackendKey.forEach((countryCode) => {
        if (countryCode === country.code) {
          data.push({
            value: country.id,
            label: country.name,
            code: country.code,
            alpha2Code: country.alpha2Code,
          });
        }
      });
    });
  }

  return data;
};
