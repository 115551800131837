import { ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { ListingPropertyTypeBackendKey } from "@/keys";

export function listingPropertyTypeColorsMapper({
  propertyTypeName,
  propertyTypeColor,
}: {
  propertyTypeName: string | null;
  propertyTypeColor: ThemeType["listingColors"]["propertyType"];
}) {
  switch (propertyTypeName) {
    case ListingPropertyTypeBackendKey.APARTMENT:
      return propertyTypeColor.apartment;
    case ListingPropertyTypeBackendKey.BUSINESS:
      return propertyTypeColor.retail;
    case ListingPropertyTypeBackendKey.LOT:
      return propertyTypeColor.land;
    case ListingPropertyTypeBackendKey.OFFICE:
      return propertyTypeColor.office;
    case ListingPropertyTypeBackendKey.WAREHOUSE:
      return propertyTypeColor.industrial;
    case ListingPropertyTypeBackendKey.HOUSE:
      return propertyTypeColor.house;
    // case ListingPropertyTypeBackendKey.BUILDING:
    //   return propertyTypeColor.building;
    default:
      return propertyTypeColor.default;
  }
}
