import type { TransactionProperty } from "@/models";
import { TransactionPropertyBackend, responseTransactionPropertyAdapter } from "@/adapters";

export const responseTransactionPropertiesAdapter = (
  transactionProperties: TransactionPropertyBackend[],
): TransactionProperty[] => {
  const newTransactionsProperties: TransactionProperty[] = [];
  if (transactionProperties.length) {
    transactionProperties.map((property) => {
      newTransactionsProperties.push(responseTransactionPropertyAdapter(property));
    });
  }

  return newTransactionsProperties;
};
