import { OnlyKeyTheme, OrganizationKey, ThemeNameKey } from "@/keys";

export type ThemeType = typeof light; // This is the type definition for my theme object.

const defaultColorsLight = {
  background: "#FAFAFA",
  color: "#171717",
};

export const light = {
  themeName: ThemeNameKey.light as OnlyKeyTheme,
  common: {
    black: "#000",
    white: "#fff",
    lightGray: "#C6C6C6",
    gray: "#727272",
  },
  defaultColors: defaultColorsLight,
  primary: {
    color: "#444444",
    contrastColor: "#FAFAFA",
  },
  secondary: {
    color: "#D31216",
    contrastColor: "#FAFAFA",
  },
  buttons: {
    primary: {
      color: "#444444",
      contrastColor: "#FAFAFA",
    },
    borderPrimary: {
      color: "#444444",
      contrastColor: "#FAFAFA",
    },
    borderSecondary: {
      color: "#D31216",
      contrastColor: "#D31216",
    },
  },
  alertColors: {
    success: "#13A107",
    warning: "#ff9f43",
    danger: "#D31216",
  },
  listingColors: {
    status: {
      forSale: "#bd1e21",
      forLease: "#F4A315",
      multiple: "#FF9124",
      active: "#4EA81D",
      inactive: "#777676",
      sold: "#5C4A8E",
      default: "#969696",
    },
    propertyType: {
      retail: "#CE2C3E",
      apartment: "#667d99",
      house: "#00b0f0",
      land: "#733823",
      industrial: "#494C56",
      office: "#21387F",
      // building: "#727272",
      default: "#969696",
    },
  },
  propertyColors: {
    unitType: {
      Suite: "#CE2C3E",
      Office: "#21387F",
      Edifice: "#EE660C",
      Store: "#85B02D",
      Parking: "#00b0f0",
      Apartment: "#667d99",
      Unit: "#DE8131",
      PentHouse: "#733823",
      House: "#00b0f0",
      default: "#969696",
    },
  },
  parcelColors: {
    sectors: {
      multiFamily: { color: "#667d99", contrastColor: defaultColorsLight.background },
      assemblyMeetingPlace: {
        color: "#70AF4B",
        contrastColor: defaultColorsLight.color,
      },
      singleFamily: {
        color: "#00b0f0",
        contrastColor: defaultColorsLight.color,
      },
      industrial: { color: "#494C56", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      publicService: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      recreation: { color: "#61578E", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  transactionColors: {
    count: "#008000",
  },
  newsColors: {
    categories: {
      finance: {
        color: "#1D6F42",
        contrastColor: defaultColorsLight.background,
      },
      construction: {
        color: "#ffcc00",
        contrastColor: defaultColorsLight.background,
      },
      residential: { color: "#00b0f0", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      industrial: { color: "#3E4149", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      infrastructure: { color: "#DE8131", contrastColor: defaultColorsLight.background },
      tax: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  reportColors: {
    listingMarker: "#f0b547",
    transactionMarker: "#4a9834",
  },
};

const defaultColorsDark = {
  background: "#1f1f1f",
  color: "#dadada",
};
export const dark: ThemeType = {
  themeName: ThemeNameKey.dark,
  common: {
    black: "#000",
    white: "#fff",
    lightGray: "#C6C6C6",
    gray: "#727272",
  },
  defaultColors: defaultColorsDark,
  primary: {
    color: "#444444",
    contrastColor: "#FAFAFA",
  },
  secondary: {
    color: "#D31216",
    contrastColor: "#FAFAFA",
  },
  buttons: {
    primary: {
      color: "#444444",
      contrastColor: "#FAFAFA",
    },
    borderPrimary: {
      color: "#FAFAFA",
      contrastColor: "#444444",
    },
    borderSecondary: {
      color: "#D31216",
      contrastColor: "#D31216",
    },
  },
  alertColors: {
    success: "#13A107",
    warning: "#ff9f43",
    danger: "#D31216",
  },
  listingColors: {
    status: {
      forSale: "#bd1e21",
      forLease: "#F4A315",
      multiple: "#FF9124",
      active: "#4EA81D",
      inactive: "#777676",
      sold: "#5C4A8E",
      default: "#969696",
    },
    propertyType: {
      retail: "#CE2C3E",
      apartment: "#667d99",
      house: "#00b0f0",
      land: "#733823",
      industrial: "#494C56",
      office: "#21387F",
      // building: "#727272",
      default: "#969696",
    },
  },
  propertyColors: {
    unitType: {
      Suite: "#CE2C3E",
      Office: "#4B3D78",
      Edifice: "#EE660C",
      Store: "#85B02D",
      Parking: "#00b0f0",
      Apartment: "#494C56",
      Unit: "#DE8131",
      PentHouse: "#733823",
      House: "#2ECABE",
      default: "#969696",
    },
  },
  parcelColors: {
    sectors: {
      multiFamily: { color: "#667d99", contrastColor: defaultColorsLight.background },
      assemblyMeetingPlace: {
        color: "#70AF4B",
        contrastColor: defaultColorsLight.color,
      },
      singleFamily: {
        color: "#00b0f0",
        contrastColor: defaultColorsLight.color,
      },
      industrial: { color: "#494C56", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      publicService: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      recreation: { color: "#61578E", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  transactionColors: {
    count: "#008000",
  },
  newsColors: {
    categories: {
      finance: {
        color: "#1D6F42",
        contrastColor: defaultColorsDark.background,
      },
      construction: {
        color: "#ffcc00",
        contrastColor: defaultColorsDark.background,
      },
      residential: { color: "#00b0f0", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      industrial: { color: "#3E4149", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      infrastructure: { color: "#DE8131", contrastColor: defaultColorsLight.background },
      tax: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  reportColors: {
    listingMarker: "#f0b547",
    transactionMarker: "#4a9834",
  },
};

const defaultColorsInvestprLight = {
  background: "#FAFAFA",
  color: "#1a1b47",
};

export const investprLight: ThemeType = {
  themeName: ThemeNameKey.light,
  common: {
    black: "#000",
    white: "#fff",
    lightGray: "#C6C6C6",
    gray: "#727272",
  },
  defaultColors: defaultColorsInvestprLight,
  primary: {
    color: "#1a1b47",
    contrastColor: "#FAFAFA",
  },
  secondary: {
    color: "#2A989B",
    contrastColor: "#FAFAFA",
  },
  buttons: {
    primary: {
      color: "#1a1b47",
      contrastColor: "#FAFAFA",
    },
    borderPrimary: {
      color: "#1a1b47",
      contrastColor: "#FAFAFA",
    },
    borderSecondary: {
      color: "#2A989B",
      contrastColor: "#2A989B",
    },
  },
  alertColors: {
    success: "#13A107",
    warning: "#ff9f43",
    danger: "#D31216",
  },
  listingColors: {
    status: {
      forSale: "#bd1e21",
      forLease: "#F4A315",
      multiple: "#FF9124",
      active: "#4EA81D",
      inactive: "#777676",
      sold: "#5C4A8E",
      default: "#969696",
    },
    propertyType: {
      retail: "#CE2C3E",
      apartment: "#667d99",
      house: "#00b0f0",
      land: "#733823",
      industrial: "#494C56",
      office: "#21387F",
      // building: "#727272",
      default: "#969696",
    },
  },
  propertyColors: {
    unitType: {
      Suite: "#CE2C3E",
      Office: "#4B3D78",
      Edifice: "#EE660C",
      Store: "#85B02D",
      Parking: "#00b0f0",
      Apartment: "#494C56",
      Unit: "#DE8131",
      PentHouse: "#733823",
      House: "#2ECABE",
      default: "#969696",
    },
  },
  parcelColors: {
    sectors: {
      multiFamily: { color: "#667d99", contrastColor: defaultColorsLight.background },
      assemblyMeetingPlace: {
        color: "#70AF4B",
        contrastColor: defaultColorsLight.color,
      },
      singleFamily: {
        color: "#00b0f0",
        contrastColor: defaultColorsLight.color,
      },
      industrial: { color: "#494C56", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      publicService: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      recreation: { color: "#61578E", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  transactionColors: {
    count: "#008000",
  },
  newsColors: {
    categories: {
      finance: {
        color: "#1D6F42",
        contrastColor: defaultColorsInvestprLight.background,
      },
      construction: {
        color: "#ffcc00",
        contrastColor: defaultColorsInvestprLight.background,
      },
      residential: { color: "#00b0f0", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      industrial: { color: "#3E4149", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      infrastructure: { color: "#DE8131", contrastColor: defaultColorsLight.background },
      tax: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  reportColors: {
    listingMarker: "#f0b547",
    transactionMarker: "#4a9834",
  },
};

const defaultColorsInvestprDark = {
  background: "#1a1b47",
  color: "#dadada",
};

export const investprDark: ThemeType = {
  themeName: ThemeNameKey.dark,
  common: {
    black: "#000",
    white: "#fff",
    lightGray: "#C6C6C6",
    gray: "#727272",
  },
  defaultColors: defaultColorsInvestprDark,
  primary: {
    color: "#444444",
    contrastColor: "#FAFAFA",
  },
  secondary: {
    color: "#2A989B",
    contrastColor: "#FAFAFA",
  },
  buttons: {
    primary: {
      color: "#2A989B",
      contrastColor: "#FAFAFA",
    },
    borderPrimary: {
      color: "#2A989B",
      contrastColor: "#444444",
    },
    borderSecondary: {
      color: "#2A989B",
      contrastColor: "#2A989B",
    },
  },
  alertColors: {
    success: "#13A107",
    warning: "#ff9f43",
    danger: "#D31216",
  },
  listingColors: {
    status: {
      forSale: "#bd1e21",
      forLease: "#F4A315",
      multiple: "#FF9124",
      active: "#4EA81D",
      inactive: "#777676",
      sold: "#5C4A8E",
      default: "#969696",
    },
    propertyType: {
      retail: "#CE2C3E",
      apartment: "#667d99",
      house: "#00b0f0",
      land: "#733823",
      industrial: "#494C56",
      office: "#21387F",
      // building: "#727272",
      default: "#969696",
    },
  },
  propertyColors: {
    unitType: {
      Suite: "#CE2C3E",
      Office: "#4B3D78",
      Edifice: "#EE660C",
      Store: "#85B02D",
      Parking: "#00b0f0",
      Apartment: "#494C56",
      Unit: "#DE8131",
      PentHouse: "#733823",
      House: "#2ECABE",
      default: "#969696",
    },
  },
  parcelColors: {
    sectors: {
      multiFamily: { color: "#667d99", contrastColor: defaultColorsLight.background },
      assemblyMeetingPlace: {
        color: "#70AF4B",
        contrastColor: defaultColorsLight.color,
      },
      singleFamily: {
        color: "#00b0f0",
        contrastColor: defaultColorsLight.color,
      },
      industrial: { color: "#494C56", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      publicService: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      recreation: { color: "#61578E", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  transactionColors: {
    count: "#008000",
  },
  newsColors: {
    categories: {
      finance: {
        color: "#1D6F42",
        contrastColor: defaultColorsInvestprDark.background,
      },
      construction: {
        color: "#ffcc00",
        contrastColor: defaultColorsInvestprDark.background,
      },
      residential: { color: "#00b0f0", contrastColor: defaultColorsLight.background },
      retail: { color: "#CE2C3E", contrastColor: defaultColorsLight.background },
      office: { color: "#21387F", contrastColor: defaultColorsLight.background },
      industrial: { color: "#3E4149", contrastColor: defaultColorsLight.background },
      land: { color: "#733823", contrastColor: defaultColorsLight.background },
      lodgingAndHospitality: { color: "#5BC4BA", contrastColor: defaultColorsLight.background },
      infrastructure: { color: "#DE8131", contrastColor: defaultColorsLight.background },
      tax: { color: "#C3B177", contrastColor: defaultColorsLight.color },
      default: { color: "#969696", contrastColor: defaultColorsLight.background },
    },
  },
  reportColors: {
    listingMarker: "#f0b547",
    transactionMarker: "#4a9834",
  },
};

export const themes = {
  [OrganizationKey.redatlas]: [light, dark],
  [OrganizationKey.investpr]: [investprLight, investprDark],
};

const defaultTheme = light; // set the light theme as the default.
export default defaultTheme;
