import type { Attachment, Transaction } from "@/models";

export const responseAttachmentFrontAdapter = (transaction: Transaction[]): Attachment[] => {
  return transaction
    .map((transaction) =>
      Object.entries(transaction.scriptures || {}).map(([key, fileURL]) => ({
        id: transaction.id,
        date: transaction.transactionDate?.toString(),
        fileURL,
        fileType: getFileTypeByScripturesKey(key) || undefined,
        unitNumber:
          transaction.transactionProperties?.[0]?.property?.addressJSON?.unitNumber?.value,
        unitType: transaction.transactionProperties?.[0]?.property?.addressJSON?.unitType?.value,
        unitId: transaction.transactionProperties?.[0]?.property?.id,
        source: !transaction.source
          ? undefined
          : {
              id: transaction.source.id as string,
              name: transaction.source.name as string,
              logoUrl: transaction.source.logoUrl as string,
              url: transaction.source.url as string,
            },
        sector: transaction.sector,
        subsector: transaction.subsector,
      })),
    )
    .flat()
    .filter((attachment) => attachment.fileURL);
};

function getFileTypeByScripturesKey(key: string): string | undefined {
  switch (key) {
    case "generated":
      return "INVOICE";
    case "uploaded":
      return "DEED";
    case "notification":
      return "NOTIFICATION";
  }
}
