import React from "react";
import {
  CategoricFiltersKey,
  LanguageCodeKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
} from "@/keys";
import { Filters, OnlyKeyEntityFilter, selectAppConfig, selectFilters } from "@/redux/slices";
import DatePicker from "react-datepicker";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import {
  CustomMultiValueLabel,
  CustomOption,
  CustomPlaceholder,
} from "@/components/reactSelect/CustomComponents";
import { sizeViewport } from "@/components/styledComponents";
import { AdvancedFilters } from "../../../Filters";
import classNames from "classnames";
import { useTheme } from "styled-components";
import SelectFilter from "../../../components/SelectFilter";
import { useLocaleCountryCode } from "@/hooks";
import { CategoricValueFilters } from "@/models";

type PermitSectionProps = {
  isAdvancedFilters: boolean;
  advancedPermitFilters: AdvancedFilters["permit"]["filters"];
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  availablePermitsFilters: {
    show: boolean;
    sectorId: boolean;
    subsectorId: boolean;
    category: boolean;
    status: boolean;
    date: boolean;
  };
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
};

const PermitSection = ({
  isAdvancedFilters,
  advancedPermitFilters,
  handleMinMaxChange,
  availablePermitsFilters,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: PermitSectionProps) => {
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { languageCode } = useLocaleCountryCode();
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const isMobile = windowWidth < sizeViewport.laptop;
  const { common } = useTheme();

  const reduxFilters = filtersByPortal[portal].filters;

  // -------------- dependent options Select
  const dependentOptionsMap = (value: string) => {
    switch (value) {
      case CategoricFiltersKey.permitSectors:
        return filtersByPortal[portal].categoricFilters[CategoricFiltersKey.permitSectors].map(
          (option) => {
            const { value, label, esLabel } = option;
            return { value, label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label };
          },
        );
      case CategoricFiltersKey.permitSubsectors:
        if (reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value) {
          // si sector tiene valor
          const subS = filtersByPortal[portal].categoricFilters[
            CategoricFiltersKey.permitSubsectors
          ].filter(
            (subsector) =>
              (!Array.isArray(
                reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value,
              ) &&
                subsector[NewsFiltersFrontKey.sectorId] ===
                  reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value?.value) ||
              (Array.isArray(reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value) &&
                reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value.length === 1 &&
                subsector[NewsFiltersFrontKey.sectorId] ===
                  reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value[0]?.value),
          );
          if (subS.length) {
            return subS.map((option) => {
              const { value, label, esLabel, sectorId } = option;
              return {
                value,
                label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
                sectorId,
              };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters[CategoricFiltersKey.permitSubsectors].map(
            (option) => {
              const { value, label, esLabel, sectorId } = option;
              return {
                value,
                label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
                sectorId,
              };
            },
          );
        }
      default:
        return [];
    }
  };

  const customSort = (
    a: CategoricValueFilters,
    b: CategoricValueFilters,
    selected: Array<string>,
  ) => {
    if (selected.includes(a.value)) {
      return -1;
    }
    if (selected.includes(b.value)) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="row px-1">
      <div className="col-12">
        <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
          <h5 className="black fw-bold text-uppercase mb-0 mr-2">{language.menuFilters.permit}</h5>
          <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
        </div>
      </div>
      {advancedPermitFilters[NewsFiltersFrontKey.sectorId] &&
        availablePermitsFilters[NewsFiltersFrontKey.sectorId] &&
        reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.sectorId}
            entityKey={NewsFiltersEntityFrontKey.permit}
            title={language.filters.permitSector.title}
            placeholder={language.filters.permitSector.placeholder}
            options={dependentOptionsMap(CategoricFiltersKey.permitSectors).sort((a, b) =>
              customSort(
                a,
                b,
                Array.isArray(reduxFilters.permit.categoric?.sectorId.value)
                  ? (reduxFilters.permit.categoric?.sectorId.value?.map(
                      (option) => option.value,
                    ) as Array<string>)
                  : [],
              ),
            )}
            isDisabled={!dependentOptionsMap(CategoricFiltersKey.permitSectors).length}
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.permitSector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            value={reduxFilters.permit.categoric?.[NewsFiltersFrontKey.sectorId].value}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {advancedPermitFilters[NewsFiltersFrontKey.subsectorId] &&
        availablePermitsFilters[NewsFiltersFrontKey.subsectorId] &&
        reduxFilters.permit.categoric?.[NewsFiltersFrontKey.subsectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.subsectorId}
            entityKey={NewsFiltersEntityFrontKey.permit}
            title={language.filters.permitSubsector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.permitSubsectors).length
                ? language.filters.permitSubsector.placeholder
                : language.filters.permitSubsector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters.permitSubsectors
                ? dependentOptionsMap(CategoricFiltersKey.permitSubsectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(reduxFilters.permit.categoric?.subsectorId.value)
                        ? (reduxFilters.permit.categoric?.subsectorId.value?.map(
                            (option) => option.value,
                          ) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isDisabled={
              !reduxFilters.permit.categoric?.sectorId.value ||
              !dependentOptionsMap(CategoricFiltersKey.permitSubsectors).length
            }
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.permitSubsector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            value={reduxFilters.permit.categoric?.[NewsFiltersFrontKey.subsectorId].value}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {advancedPermitFilters[NewsFiltersFrontKey.category] &&
        availablePermitsFilters.category &&
        reduxFilters.permit.categoric?.category && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.category}
            entityKey={NewsFiltersEntityFrontKey.permit}
            title={language.filters.permitCategory.title}
            placeholder={language.filters.permitCategory.placeholder}
            options={filtersByPortal[portal].categoricFilters.permitCategories}
            isDisabled={!filtersByPortal[portal].categoricFilters.permitCategories.length}
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.permitCategory.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            value={reduxFilters.permit.categoric?.category?.value}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {advancedPermitFilters[NewsFiltersFrontKey.status] &&
        availablePermitsFilters.status &&
        reduxFilters.permit.categoric?.status && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.status}
            entityKey={NewsFiltersEntityFrontKey.permit}
            title={language.filters.permitStatus.title}
            placeholder={language.filters.permitStatus.placeholder}
            options={filtersByPortal[portal].categoricFilters.permitStatuses}
            isDisabled={!filtersByPortal[portal].categoricFilters.permitStatuses.length}
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.permitStatus.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            value={reduxFilters.permit.categoric?.status?.value}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {advancedPermitFilters[NewsFiltersFrontKey.date] &&
        availablePermitsFilters.date &&
        reduxFilters.permit.date?.[NewsFiltersFrontKey.date] && (
          <div
            className={classNames("col-12 mb-1", {
              "col-xl-3": isAdvancedFilters,
              "col-md-4": isAdvancedFilters,
              "col-md-6": !isAdvancedFilters,
              "col-sm-12": !isAdvancedFilters,
            })}>
            <h6 className="mb-05 fw-bold black ml-1">{language.filters.permitDate.title}</h6>
            <div className="row">
              <div className="col-md-6 col-12" style={{ marginBottom: isMobile ? ".5rem" : "" }}>
                <DatePicker
                  portalId="root-portal-filters"
                  placeholderText={language.filters.permitDate.startPlaceholder}
                  selected={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.min}
                  onChange={(date: Date) =>
                    handleMinMaxChange({
                      name: NewsFiltersFrontKey.date,
                      value: { min: date },
                      entity: NewsFiltersEntityFrontKey.permit,
                      type: NewsFiltersTypeFrontKey.date,
                    })
                  }
                  selectsStart
                  maxDate={
                    reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.max ?? new Date()
                  }
                  startDate={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.min}
                  endDate={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.max}
                  className="DatePicker"
                  popperClassName="calendar-popout"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  onInputClick={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    } else {
                      setMenuState(true);
                      setActiveSelect(
                        `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.min`,
                      );
                    }
                  }}
                  onSelect={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  onBlur={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  open={
                    activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.min` &&
                    menuState
                  }
                />
              </div>

              <div className="col-md-6 col-12">
                <DatePicker
                  portalId="root-portal-filters"
                  placeholderText={language.filters.permitDate.endPlaceholder}
                  selected={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.max}
                  onChange={(date: Date) =>
                    handleMinMaxChange({
                      name: NewsFiltersFrontKey.date,
                      value: { max: date },
                      entity: NewsFiltersEntityFrontKey.permit,
                      type: NewsFiltersTypeFrontKey.date,
                    })
                  }
                  selectsEnd
                  minDate={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.min}
                  maxDate={new Date()}
                  startDate={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.min}
                  endDate={reduxFilters.permit.date?.[NewsFiltersFrontKey.date].value.max}
                  className="DatePicker"
                  popperClassName="calendar-popout"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  onInputClick={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    } else {
                      setMenuState(true);
                      setActiveSelect(
                        `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.max`,
                      );
                    }
                  }}
                  onSelect={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  onBlur={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  open={
                    activeSelect ===
                      `${NewsFiltersEntityFrontKey.permit}.${NewsFiltersFrontKey.date}.max` &&
                    menuState
                  }
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PermitSection;
