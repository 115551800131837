import { CountryCodeKey } from "@/keys";
import { OptionType } from "@/models";
import { useLocaleCountryCode } from "../country-code.hook";

export const useDefaultFilterOptions = () => {
  const { countryCode } = useLocaleCountryCode();

  const pricesPR: OptionType[] = [
    { value: "200", label: "$200" },
    { value: "400", label: "$400" },
    { value: "600", label: "$600" },
    { value: "800", label: "$800" },
    { value: "1000", label: "$1K" },
    { value: "1200", label: "$1.2K" },
    { value: "1400", label: "$1.4K" },
    { value: "1600", label: "$1.6K" },
    { value: "1800", label: "$1.8K" },
    { value: "2000", label: "$2K" },
    { value: "2200", label: "$2.2K" },
    { value: "2400", label: "$2.4K" },
    { value: "2600", label: "$2.6K" },
    { value: "2800", label: "$2.8K" },
    { value: "3000", label: "$3K" },
    { value: "3500", label: "$3.5K" },
    { value: "4000", label: "$4K" },
    { value: "4500", label: "$4.5K" },
    { value: "5000", label: "$5K" },
    { value: "5500", label: "$5.5K" },
    { value: "6000", label: "$6K" },
    { value: "7000", label: "$7K" },
    { value: "8000", label: "$8K" },
    { value: "9000", label: "$9K" },
    { value: "10000", label: "$10K" },
    { value: "50000", label: "$50K" },
    { value: "100000", label: "$100K" },
    { value: "150000", label: "$150K" },
    { value: "200000", label: "$200K" },
    { value: "250000", label: "$250K" },
    { value: "300000", label: "$300K" },
    { value: "350000", label: "$350K" },
    { value: "400000", label: "$400K" },
    { value: "450000", label: "$450K" },
    { value: "500000", label: "$500K" },
    { value: "550000", label: "$550K" },
    { value: "600000", label: "$600K" },
    { value: "650000", label: "$650K" },
    { value: "700000", label: "$700K" },
    { value: "750000", label: "$750K" },
    { value: "800000", label: "$800K" },
    { value: "850000", label: "$850K" },
    { value: "900000", label: "$900K" },
    { value: "950000", label: "$950K" },
    { value: "1000000", label: "$1M" },
    { value: "1250000", label: "$1.25M" },
    { value: "1500000", label: "$1.5M" },
    { value: "1750000", label: "$1.75M" },
    { value: "2000000", label: "$2M" },
    { value: "2250000", label: "$2.25M" },
    { value: "2500000", label: "$2.5M" },
    { value: "2750000", label: "$2.75M" },
    { value: "3000000", label: "$3M" },
    { value: "3250000", label: "$3.25M" },
    { value: "3500000", label: "$3.5M" },
    { value: "3750000", label: "$3.75M" },
    { value: "4000000", label: "$4M" },
    { value: "4250000", label: "$4.25M" },
    { value: "4500000", label: "$4.5M" },
    { value: "4750000", label: "$4.75M" },
    { value: "5000000", label: "$5M" },
    { value: "6000000", label: "$6M" },
    { value: "7000000", label: "$7M" },
    { value: "8000000", label: "$8M" },
    { value: "9000000", label: "$9M" },
    { value: "10000000", label: "$10M" },
    { value: "11000000", label: "$11M" },
    { value: "12000000", label: "$12M" },
    { value: "13000000", label: "$13M" },
    { value: "14000000", label: "$14M" },
    { value: "15000000", label: "$15M" },
    { value: "16000000", label: "$16M" },
    { value: "17000000", label: "$17M" },
    { value: "18000000", label: "$18M" },
  ];

  const pricesCO: OptionType[] = [
    { value: "800000", label: "$800K" },
    { value: "1600000", label: "$1.6M" },
    { value: "2400000", label: "$2.4M" },
    { value: "3200000", label: "$3.2M" },
    { value: "4000000", label: "$4M" },
    { value: "4800000", label: "$4.8M" },
    { value: "5600000", label: "$5.6M" },
    { value: "6400000", label: "$6.4M" },
    { value: "7200000", label: "$7.2M" },
    { value: "8000000", label: "$8M" },
    { value: "8800000", label: "$8.8M" },
    { value: "9600000", label: "$9.6M" },
    { value: "10400000", label: "$10.4M" },
    { value: "11200000", label: "$11.2M" },
    { value: "12000000", label: "$12M" },
    { value: "14000000", label: "$14M" },
    { value: "16000000", label: "$16M" },
    { value: "18000000", label: "$18M" },
    { value: "20000000", label: "$20M" },
    { value: "22000000", label: "$22M" },
    { value: "24000000", label: "$24M" },
    { value: "28000000", label: "$28M" },
    { value: "32000000", label: "$32M" },
    { value: "36000000", label: "$36M" },
    { value: "40000000", label: "$40M" },
    { value: "200000000", label: "$200M" },
    { value: "400000000", label: "$400M" },
    { value: "600000000", label: "$600M" },
    { value: "800000000", label: "$800M" },
    { value: "1000000000", label: "$1B" },
    { value: "1200000000", label: "$1.2B" },
    { value: "1400000000", label: "$1.4B" },
    { value: "1600000000", label: "$1.6B" },
    { value: "1800000000", label: "$1.8B" },
    { value: "2000000000", label: "$2B" },
    { value: "2200000000", label: "$2.2B" },
    { value: "2400000000", label: "$2.4B" },
    { value: "2600000000", label: "$2.6B" },
    { value: "2800000000", label: "$2.8B" },
    { value: "3000000000", label: "$3B" },
    { value: "3200000000", label: "$3.2B" },
    { value: "3400000000", label: "$3.4B" },
    { value: "3600000000", label: "$3.6B" },
    { value: "3800000000", label: "$3.8B" },
    { value: "4000000000", label: "$4B" },
    { value: "5000000000", label: "$5B" },
    { value: "6000000000", label: "$6B" },
    { value: "7000000000", label: "$7B" },
    { value: "8000000000", label: "$8B" },
    { value: "9000000000", label: "$9B" },
    { value: "10000000000", label: "$10B" },
    { value: "11000000000", label: "$11B" },
    { value: "12000000000", label: "$12B" },
    { value: "13000000000", label: "$13B" },
    { value: "14000000000", label: "$14B" },
    { value: "15000000000", label: "$15B" },
    { value: "16000000000", label: "$16B" },
    { value: "17000000000", label: "$17B" },
    { value: "18000000000", label: "$18B" },
    { value: "19000000000", label: "$19B" },
    { value: "20000000000", label: "$20B" },
    { value: "24000000000", label: "$24B" },
    { value: "28000000000", label: "$28B" },
    { value: "32000000000", label: "$32B" },
    { value: "36000000000", label: "$36B" },
    { value: "40000000000", label: "$40B" },
    { value: "44000000000", label: "$44B" },
    { value: "48000000000", label: "$48B" },
    { value: "52000000000", label: "$52B" },
    { value: "56000000000", label: "$56B" },
    { value: "60000000000", label: "$60B" },
    { value: "64000000000", label: "$64B" },
    { value: "68000000000", label: "$68B" },
    { value: "72000000000", label: "$72B" },
  ];

  const sqfts: OptionType[] = [
    { value: "200", label: "200 sqft" },
    { value: "400", label: "400 sqft" },
    { value: "600", label: "600 sqft" },
    { value: "800", label: "800 sqft" },
    { value: "1000", label: "1K sqft" },
    { value: "1200", label: "1.2K sqft" },
    { value: "1400", label: "1.4K sqft" },
    { value: "1600", label: "1.6K sqft" },
    { value: "1800", label: "1.8K sqft" },
    { value: "2000", label: "2K sqft" },
    { value: "2200", label: "2.2K sqft" },
    { value: "2400", label: "2.4K sqft" },
    { value: "2600", label: "2.6K sqft" },
    { value: "2800", label: "2.8K sqft" },
    { value: "3000", label: "3K sqft" },
    { value: "3500", label: "3.5K sqft" },
    { value: "4000", label: "4K sqft" },
    { value: "4500", label: "4.5K sqft" },
    { value: "5000", label: "5K sqft" },
    { value: "5500", label: "5.5K sqft" },
    { value: "6000", label: "6K sqft" },
    { value: "7000", label: "7K sqft" },
    { value: "8000", label: "8K sqft" },
    { value: "9000", label: "9K sqft" },
    { value: "10000", label: "10K sqft" },
    { value: "12000", label: "12K sqft" },
    { value: "15000", label: "15K sqft" },
    { value: "20000", label: "20K sqft" },
    { value: "25000", label: "25K sqft" },
    { value: "30000", label: "30K sqft" },
    { value: "50000", label: "50K sqft" },
    { value: "100000", label: "100K sqft" },
    { value: "150000", label: "150K sqft" },
    { value: "200000", label: "200K sqft" },
    { value: "250000", label: "250K sqft" },
    { value: "500000", label: "500K sqft" },
  ];

  const acres: OptionType[] = [
    { value: "0.5", label: "0.5 acre" },
    { value: "1", label: "1 acre" },
    { value: "2", label: "2 acre" },
    { value: "3", label: "3 acre" },
    { value: "4", label: "4 acre" },
    { value: "5", label: "5 acre" },
    { value: "6", label: "6 acre" },
    { value: "7", label: "7 acre" },
    { value: "8", label: "8 acre" },
    { value: "9", label: "9 acre" },
    { value: "10", label: "10 acre" },
    { value: "15", label: "15 acre" },
    { value: "20", label: "20 acre" },
    { value: "25", label: "25 acre" },
    { value: "30", label: "30 acre" },
    { value: "35", label: "35 acre" },
    { value: "40", label: "40 acre" },
    { value: "45", label: "45 acre" },
    { value: "50", label: "50 acre" },
    { value: "60", label: "60 acre" },
    { value: "70", label: "70 acre" },
    { value: "80", label: "80 acre" },
    { value: "90", label: "90 acre" },
    { value: "100", label: "100 acre" },
    { value: "150", label: "150 acre" },
    { value: "200", label: "200 acre" },
    { value: "250", label: "250 acre" },
  ];

  const sqms: OptionType[] = [
    { value: "20", label: "20 sqm" },
    { value: "40", label: "40 sqm" },
    { value: "60", label: "60 sqm" },
    { value: "80", label: "80 sqm" },
    { value: "100", label: "100 sqm" },
    { value: "120", label: "120 sqm" },
    { value: "140", label: "140 sqm" },
    { value: "160", label: "160 sqm" },
    { value: "180", label: "180 sqm" },
    { value: "200", label: "200 sqm" },
    { value: "220", label: "220 sqm" },
    { value: "240", label: "240 sqm" },
    { value: "260", label: "260 sqm" },
    { value: "280", label: "280 sqm" },
    { value: "300", label: "300 sqm" },
    { value: "350", label: "350 sqm" },
    { value: "400", label: "400 sqm" },
    { value: "450", label: "450 sqm" },
    { value: "500", label: "500 sqm" },
    { value: "550", label: "550 sqm" },
    { value: "600", label: "600 sqm" },
    { value: "700", label: "700 sqm" },
    { value: "800", label: "800 sqm" },
    { value: "900", label: "900 sqm" },
    { value: "1000", label: "1K sqm" },
    { value: "1200", label: "1.2K sqm" },
    { value: "1500", label: "1.5K sqm" },
    { value: "2000", label: "2K sqm" },
    { value: "2500", label: "2.5K sqm" },
    { value: "3000", label: "3K sqm" },
    { value: "5000", label: "5K sqm" },
    { value: "10000", label: "10K sqm" },
    { value: "15000", label: "15K sqm" },
    { value: "20000", label: "20K sqm" },
    { value: "25000", label: "25K sqm" },
    { value: "50000", label: "50K sqm" },
  ];

  const pricesPerSqftPR: OptionType[] = [
    { value: "50", label: "$50/sqft" },
    { value: "100", label: "$100/sqft" },
    { value: "150", label: "$150/sqft" },
    { value: "200", label: "$200/sqft" },
    { value: "250", label: "$250/sqft" },
    { value: "300", label: "$300/sqft" },
    { value: "350", label: "$350/sqft" },
    { value: "400", label: "$400/sqft" },
    { value: "450", label: "$450/sqft" },
    { value: "500", label: "$500/sqft" },
    { value: "550", label: "$550/sqft" },
    { value: "600", label: "$600/sqft" },
    { value: "650", label: "$650/sqft" },
    { value: "700", label: "$700/sqft" },
    { value: "750", label: "$750/sqft" },
    { value: "800", label: "$800/sqft" },
    { value: "850", label: "$850/sqft" },
    { value: "900", label: "$900/sqft" },
    { value: "950", label: "$950/sqft" },
    { value: "1000", label: "$1K/sqft" },
    { value: "1250", label: "$1.25K/sqft" },
    { value: "1500", label: "$1.5K/sqft" },
    { value: "1750", label: "$1.75K/sqft" },
    { value: "2000", label: "$2K/sqft" },
    { value: "2250", label: "$2.25K/sqft" },
    { value: "2500", label: "$2.5K/sqft" },
    { value: "2750", label: "$2.75K/sqft" },
    { value: "3000", label: "$3K/sqft" },
    { value: "3250", label: "$3.25K/sqft" },
    { value: "3500", label: "$3.5K/sqft" },
    { value: "3750", label: "$3.75K/sqft" },
    { value: "4000", label: "$4K/sqft" },
    { value: "4250", label: "$4.25K/sqft" },
    { value: "4500", label: "$4.5K/sqft" },
    { value: "4750", label: "$4.75K/sqft" },
    { value: "5000", label: "$5K/sqft" },
  ];

  const pricesPerAcrePR: OptionType[] = [
    { value: "50", label: "$50/acre" },
    { value: "55", label: "$55/acre" },
    { value: "60", label: "$60/acre" },
    { value: "65", label: "$65/acre" },
    { value: "70", label: "$70/acre" },
    { value: "75", label: "$75/acre" },
    { value: "80", label: "$80/acre" },
    { value: "85", label: "$85/acre" },
    { value: "90", label: "$90/acre" },
    { value: "95", label: "$95/acre" },
    { value: "100", label: "$100/acre" },
    { value: "110", label: "$110/acre" },
    { value: "120", label: "$120/acre" },
    { value: "125", label: "$125/acre" },
    { value: "130", label: "$130/acre" },
    { value: "135", label: "$135/acre" },
    { value: "140", label: "$140/acre" },
    { value: "145", label: "$145/acre" },
    { value: "150", label: "$150/acre" },
    { value: "160", label: "$160/acre" },
    { value: "170", label: "$170/acre" },
    { value: "175", label: "$175/acre" },
    { value: "180", label: "$180/acre" },
    { value: "185", label: "$185/acre" },
    { value: "190", label: "$190/acre" },
    { value: "195", label: "$195/acre" },
    { value: "200", label: "$200/acre" },
    { value: "210", label: "$210/acre" },
    { value: "220", label: "$220/acre" },
    { value: "225", label: "$225/acre" },
    { value: "230", label: "$230/acre" },
    { value: "235", label: "$235/acre" },
    { value: "240", label: "$240/acre" },
    { value: "245", label: "$245/acre" },
    { value: "250", label: "$250/acre" },
    { value: "260", label: "$260/acre" },
    { value: "270", label: "$270/acre" },
    { value: "275", label: "$275/acre" },
    { value: "280", label: "$280/acre" },
    { value: "285", label: "$285/acre" },
    { value: "290", label: "$290/acre" },
    { value: "295", label: "$295/acre" },
    { value: "300", label: "$300/acre" },
    { value: "310", label: "$310/acre" },
    { value: "320", label: "$320/acre" },
    { value: "325", label: "$325/acre" },
    { value: "330", label: "$330/acre" },
    { value: "335", label: "$335/acre" },
    { value: "340", label: "$340/acre" },
    { value: "345", label: "$345/acre" },
    { value: "350", label: "$350/acre" },
    { value: "360", label: "$360/acre" },
    { value: "370", label: "$370/acre" },
    { value: "375", label: "$375/acre" },
    { value: "380", label: "$380/acre" },
    { value: "385", label: "$385/acre" },
    { value: "390", label: "$390/acre" },
    { value: "395", label: "$395/acre" },
    { value: "400", label: "$400/acre" },
  ];

  const pricesPerSqmCO: OptionType[] = [
    { value: "200000", label: "$200K/sqm" },
    { value: "400000", label: "$400K/sqm" },
    { value: "600000", label: "$600K/sqm" },
    { value: "800000", label: "$800K/sqm" },
    { value: "1000000", label: "$1M/sqm" },
    { value: "1200000", label: "$1.2M/sqm" },
    { value: "1400000", label: "$1.4M/sqm" },
    { value: "1600000", label: "$1.6M/sqm" },
    { value: "1800000", label: "$1.8M/sqm" },
    { value: "2000000", label: "$2M/sqm" },
    { value: "2200000", label: "$2.2M/sqm" },
    { value: "2400000", label: "$2.4M/sqm" },
    { value: "2600000", label: "$2.6M/sqm" },
    { value: "2800000", label: "$2.8M/sqm" },
    { value: "3000000", label: "$3M/sqm" },
    { value: "3200000", label: "$3.2M/sqm" },
    { value: "3400000", label: "$3.4M/sqm" },
    { value: "3600000", label: "$3.6M/sqm" },
    { value: "3800000", label: "$3.8M/sqm" },
    { value: "4000000", label: "$4M/sqm" },
    { value: "5000000", label: "$5M/sqm" },
    { value: "6000000", label: "$6M/sqm" },
    { value: "7000000", label: "$7M/sqm" },
    { value: "8000000", label: "$8M/sqm" },
    { value: "9000000", label: "$9M/sqm" },
    { value: "10000000", label: "$10M/sqm" },
    { value: "11000000", label: "$11M/sqm" },
    { value: "12000000", label: "$12M/sqm" },
    { value: "13000000", label: "$13M/sqm" },
    { value: "14000000", label: "$14M/sqm" },
    { value: "15000000", label: "$15M/sqm" },
    { value: "16000000", label: "$16M/sqm" },
    { value: "17000000", label: "$17M/sqm" },
    { value: "18000000", label: "$18M/sqm" },
    { value: "19000000", label: "$19M/sqm" },
    { value: "20000000", label: "$20M/sqm" },
    { value: "24000000", label: "$24M/sqm" },
    { value: "28000000", label: "$28M/sqm" },
    { value: "32000000", label: "$32M/sqm" },
    { value: "36000000", label: "$36M/sqm" },
    { value: "40000000", label: "$40M/sqm" },
    { value: "44000000", label: "$44M/sqm" },
    { value: "48000000", label: "$48M/sqm" },
    { value: "52000000", label: "$52M/sqm" },
    { value: "56000000", label: "$56M/sqm" },
    { value: "60000000", label: "$60M/sqm" },
    { value: "64000000", label: "$64M/sqm" },
    { value: "68000000", label: "$68M/sqm" },
    { value: "72000000", label: "$72M/sqm" },
  ];

  const numberRanges: OptionType[] = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];

  const rooms: OptionType[] = numberRanges;

  const toilets: OptionType[] = numberRanges;

  const parkings: OptionType[] = numberRanges;

  const prices: Record<CountryCodeKey, OptionType[]> = {
    [CountryCodeKey.PR]: pricesPR,
    [CountryCodeKey.CO]: pricesCO,
    [CountryCodeKey.AR]: [],
    [CountryCodeKey.AE]: [],
    [CountryCodeKey.MX]: [],
  };

  const builtAreaPrices: Record<CountryCodeKey, OptionType[]> = {
    [CountryCodeKey.PR]: pricesPerSqftPR,
    [CountryCodeKey.CO]: pricesPerSqmCO,
    [CountryCodeKey.AR]: [],
    [CountryCodeKey.AE]: [],
    [CountryCodeKey.MX]: [],
  };

  const builtAreaAreas: Record<CountryCodeKey, OptionType[]> = {
    [CountryCodeKey.PR]: sqfts,
    [CountryCodeKey.CO]: sqms,
    [CountryCodeKey.AR]: [],
    [CountryCodeKey.AE]: [],
    [CountryCodeKey.MX]: [],
  };

  const landAreaPrices: Record<CountryCodeKey, OptionType[]> = {
    [CountryCodeKey.PR]: pricesPerAcrePR,
    [CountryCodeKey.CO]: pricesPerSqmCO,
    [CountryCodeKey.AR]: [],
    [CountryCodeKey.AE]: [],
    [CountryCodeKey.MX]: [],
  };

  const landAreaAreas: Record<CountryCodeKey, OptionType[]> = {
    [CountryCodeKey.PR]: acres,
    [CountryCodeKey.CO]: sqms,
    [CountryCodeKey.AR]: [],
    [CountryCodeKey.AE]: [],
    [CountryCodeKey.MX]: [],
  };

  return {
    prices: prices[countryCode],
    builtArea: {
      prices: builtAreaPrices[countryCode],
      areas: builtAreaAreas[countryCode],
    },
    landArea: {
      prices: landAreaPrices[countryCode],
      areas: landAreaAreas[countryCode],
    },
    rooms,
    toilets,
    parkings,
  };
};
