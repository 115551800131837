import type {
  AreaChartOptions,
  ColumnChartOptions,
  ColumnStackedChartOptions,
  PieChartOptions,
} from "@/models";
import {
  AnalyticsBackendKey,
  AnalyticsTransactionTypeKey,
  CountryCodeBackendKey,
  CountryCodeKey,
  ListingBackendInclude,
  ListingBackendKey,
  ParcelBackendInclude,
  ParcelBackendKey,
  TransactionBackendInclude,
  useEndpoint,
} from "@/keys";
import {
  AnalyticsBackend,
  AnalyticsRangeBackend,
  RequestFiltersBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  responseAnalyticsAreaChartAdapter,
  responseAnalyticsColumnChartAdapter,
  responseAnalyticsColumnStackedChartAdapter,
  responseAnalyticsParcelAdapter,
} from "@/adapters";
import { api } from "@/services";
import { responseAnalyticsPieChartAdapter } from "@/adapters/responses/analytics/responseAnalytics-pieChart.adapter";

type AnalyticsListingBodyProps = {
  precision: number; // va de 100 a 1000
  series: 2 | 4 | 8;
  columnName: ListingBackendKey.price | ListingBackendKey.builtArea;
};

type AnalyticsParcelBodyProps = {
  precision: number; // va de 100 a 1000
  series: 2 | 4 | 6 | 8;
  columnName: ParcelBackendKey.landArea;
};

export interface AnalyticsTransactionBodyProps {
  daysAgo: number; // va de 1 a 3660
  groupDateBy: "day" | "week" | "month" | "quarter" | "year";
  type: AnalyticsTransactionTypeKey;
}

const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAnalyticsListingTypes: builder.query<
      PieChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
      }
    >({
      query({ countryCode, filtersProps }) {
        const body = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsListingsTypes({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: AnalyticsBackend[] }): PieChartOptions =>
        responseAnalyticsPieChartAdapter({
          data: response.data,
          key: AnalyticsBackendKey.propertyType,
        }),
    }),
    getAnalyticsListingPriceDistribution: builder.query<
      ColumnStackedChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
        bodyProps: AnalyticsListingBodyProps;
      }
    >({
      query({ countryCode, filtersProps, bodyProps }) {
        const filters = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsListings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: {
            ...filters,
            ...bodyProps,
          },
        };
      },
      transformResponse: (response: { data: AnalyticsRangeBackend[] }): ColumnStackedChartOptions =>
        responseAnalyticsColumnStackedChartAdapter(response.data),
    }),
    getAnalyticsListingBuiltAreaDistribution: builder.query<
      ColumnStackedChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
        bodyProps: AnalyticsListingBodyProps;
      }
    >({
      query({ countryCode, filtersProps, bodyProps }) {
        const filters = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsListings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: {
            ...filters,
            ...bodyProps,
          },
        };
      },
      transformResponse: (response: { data: AnalyticsRangeBackend[] }): ColumnStackedChartOptions =>
        responseAnalyticsColumnStackedChartAdapter(response.data),
    }),
    getAnalyticsListingDaysOnMarket: builder.query<
      ColumnStackedChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
      }
    >({
      query({ countryCode, filtersProps }) {
        const filters = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsListingsDaysOnMarket({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: {
            ...filters,
          },
        };
      },
      transformResponse: (response: { data: AnalyticsRangeBackend[] }): ColumnStackedChartOptions =>
        responseAnalyticsColumnStackedChartAdapter(response.data),
    }),
    getAnalyticsParcelSectorsDistribution: builder.query<
      PieChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: false }>;
      }
    >({
      query({ countryCode, filtersProps }) {
        const body = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsParcelsSectors({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: AnalyticsBackend[] }): PieChartOptions =>
        responseAnalyticsPieChartAdapter({
          data: response.data,
          key: AnalyticsBackendKey.sectorId,
        }),
    }),
    getAnalyticsParcels: builder.query<
      ColumnStackedChartOptions,
      {
        countryCode: CountryCodeKey;
        filtersProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: false }>;
        bodyProps: AnalyticsParcelBodyProps;
      }
    >({
      query({ countryCode, filtersProps, bodyProps }) {
        const filters = requestFiltersAdapter({ ...filtersProps });
        return {
          url: useEndpoint().analyticsParcels({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: {
            ...filters,
            ...bodyProps,
          },
        };
      },
      transformResponse: (response: { data: AnalyticsRangeBackend[] }): ColumnStackedChartOptions =>
        responseAnalyticsParcelAdapter(response.data),
    }),
    getAnalyticsTransactions: builder.query<
      | {
          columnChart: ColumnChartOptions;
          // areaChart: AreaChartOptions
        }
      | undefined,
      {
        countryCode: CountryCodeKey;
        filtersProps?: RequestFiltersAdapterProps<TransactionBackendInclude, { isCount: false }>;
        filtersBackendProps?: RequestFiltersBackend<TransactionBackendInclude, { isCount: false }>;
        bodyProps: AnalyticsTransactionBodyProps;
      }
    >({
      query({ countryCode, filtersProps, filtersBackendProps, bodyProps }) {
        const filtersFront = filtersProps ? requestFiltersAdapter({ ...filtersProps }) : null;
        return {
          url: useEndpoint().analyticsTransactions({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body: {
            ...(filtersFront ?? undefined),
            ...(filtersBackendProps ?? undefined),
            ...bodyProps,
          },
        };
      },
      transformResponse: (
        response: {
          data: AnalyticsBackend[];
        },
        meta,
        arg,
      ):
        | {
            columnChart: ColumnChartOptions;
            // areaChart: AreaChartOptions;
          }
        | undefined => {
        return {
          columnChart: responseAnalyticsColumnChartAdapter(response.data),
          // areaChart: responseAnalyticsAreaChartAdapter(response.data),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAnalyticsListingTypesQuery,
  useGetAnalyticsListingPriceDistributionQuery,
  useGetAnalyticsListingBuiltAreaDistributionQuery,
  useGetAnalyticsListingDaysOnMarketQuery,
  useGetAnalyticsParcelSectorsDistributionQuery,
  useGetAnalyticsTransactionsQuery,
  useGetAnalyticsParcelsQuery,
} = analyticsApi;
