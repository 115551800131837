import { useAppSelector } from "@/app/hooks";
import { sizeViewport } from "@/components/styledComponents";
import { useLocaleCountryCode } from "@/hooks";
import { CountryCodeKey } from "@/keys";
import { OptionType } from "@/models";
import { selectAppConfig, selectFilters, selectGridView, selectLocations } from "@/redux/slices";
import { useLazyGetCityQuery, useLazyGetZipCodesQuery } from "@/services";
import { useState, useEffect } from "react";

type UsePolygonLocationProps = {
  map: google.maps.Map | null;
};

const usePolygonLocation = ({ map }: UsePolygonLocationProps) => {
  const { countryCode } = useLocaleCountryCode();
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { viewActive } = useAppSelector(selectGridView);
  const { neighborhoods, subNeighborhoods } = useAppSelector(selectLocations);
  const isMobile = windowWidth < sizeViewport.laptop;

  const [polygonLocation, setPolygonLocation] = useState<google.maps.LatLng[][] | null>(null);

  const [triggerCity, { data: city }] = useLazyGetCityQuery();
  const [triggerZipcode, { data: zipCodes }] = useLazyGetZipCodesQuery();

  useEffect(() => {
    if (filtersByPortal[portal].appliedFilters.location.categoric?.subNeighborhoodId.appliedValue) {
      const findSubneighborhoodGeometry = (
        countryCode === CountryCodeKey.PR ? neighborhoods : subNeighborhoods
      ).find(
        (neighborhood) =>
          neighborhood.value ===
          (
            filtersByPortal[portal].appliedFilters.location.categoric?.subNeighborhoodId
              ?.appliedValue as OptionType
          )?.value,
      );
      if (findSubneighborhoodGeometry?.geometry) {
        setPolygonLocation(findSubneighborhoodGeometry.geometry);
        // center and zoom to bring the polygon completely into view
        const { center, zoom } = centerAndZoomToFitPolygon(findSubneighborhoodGeometry.geometry);
        if (center && zoom) {
          if (center && zoom) {
            map?.setCenter(center);
            map?.setZoom(zoom);
          }
        }
      }
    } else if (
      filtersByPortal[portal].appliedFilters.location.categoric?.neighborhoodId.appliedValue
    ) {
      const findNeighborhoodGeometry = neighborhoods.find(
        (neighborhood) =>
          neighborhood.value ===
          (
            filtersByPortal[portal].appliedFilters.location.categoric?.neighborhoodId
              ?.appliedValue as OptionType
          )?.value,
      );
      if (findNeighborhoodGeometry?.geometry) {
        setPolygonLocation(findNeighborhoodGeometry.geometry);
        // center and zoom to bring the polygon completely into view
        const { center, zoom } = centerAndZoomToFitPolygon(findNeighborhoodGeometry.geometry);
        if (center && zoom) {
          if (center && zoom) {
            map?.setCenter(center);
            map?.setZoom(zoom);
          }
        }
      }
    } else if (filtersByPortal[portal].appliedFilters.location.categoric?.cityId?.appliedValue) {
      triggerCity({
        cityId: (
          filtersByPortal[portal].appliedFilters.location.categoric?.cityId
            ?.appliedValue as OptionType
        )?.value,
        countryCode,
      });
    } else if (filtersByPortal[portal].appliedFilters.location.categoric?.zipCodeId?.appliedValue) {
      triggerZipcode({
        zipCode: (
          filtersByPortal[portal].appliedFilters.location.categoric?.zipCodeId
            ?.appliedValue as OptionType
        )?.label,
        countryCode,
      });
    } else {
      setPolygonLocation(null);
    }
  }, [
    filtersByPortal[portal].appliedFilters.location.categoric?.cityId?.appliedValue,
    filtersByPortal[portal].appliedFilters.location.categoric?.subNeighborhoodId?.appliedValue,
    filtersByPortal[portal].appliedFilters.location.categoric?.neighborhoodId?.appliedValue,
    filtersByPortal[portal].appliedFilters.location.categoric?.zipCodeId?.appliedValue,
  ]);

  // ------- CITY -------
  useEffect(() => {
    if (city?.geometry) {
      setPolygonLocation(city.geometry);
      // center and zoom to bring the polygon completely into view
      const { center, zoom } = centerAndZoomToFitPolygon(city.geometry);
      if (center && zoom) {
        map?.setCenter(center);
        map?.setZoom(zoom);
      }
    }
  }, [city]);

  // ------- ZIPCODE -------
  useEffect(() => {
    if (zipCodes?.[0]?.geometry) {
      setPolygonLocation(zipCodes?.[0]?.geometry);
      // center and zoom to bring the polygon completely into view
      const { center, zoom } = centerAndZoomToFitPolygon(zipCodes?.[0]?.geometry);
      if (center && zoom) {
        map?.setCenter(center);
        map?.setZoom(zoom);
      }
    }
  }, [zipCodes]);

  // ------- CALCULAR POLYGON -------

  type PolygonPaths = google.maps.LatLng[][]; // Tipo de las coordenadas del polígono

  // Función para calcular el centro del polígono
  const calculatePolygonCenter = (polygonPaths: PolygonPaths): google.maps.LatLng | null => {
    // Verifica si el polígono tiene al menos un punto
    if (polygonPaths.length === 0 || polygonPaths[0].length === 0) {
      return null;
    }

    // Cálculo del centro del polígono
    let latSum = 0;
    let lngSum = 0;
    let pointCount = 0;

    // Itera sobre todos los vértices del polígono
    polygonPaths.forEach((ring) => {
      ring.forEach((point) => {
        latSum += point.lat();
        lngSum += point.lng();
        pointCount++;
      });
    });

    // Calcula el promedio de las coordenadas para obtener el centro del polígono
    const center = new google.maps.LatLng(latSum / pointCount, lngSum / pointCount);

    return center;
  };

  // Función para calcular el nivel de zoom necesario para que el polígono entre completamente en la vista
  const calculateZoomToFitPolygon = (polygonPaths: PolygonPaths, map: google.maps.Map) => {
    // Verifica si el polígono tiene al menos un punto
    if (polygonPaths.length === 0 || polygonPaths[0].length === 0) {
      return null;
    }

    // Calcula los límites del polígono
    const polygonBounds = new google.maps.LatLngBounds();
    polygonPaths.forEach((ring) => {
      ring.forEach((point) => {
        polygonBounds.extend(point);
      });
    });

    // Ajusta los límites del mapa al polígono
    map.fitBounds(polygonBounds);

    // Devuelve el nivel de zoom
    return map.getZoom();
  };

  // Función para centrar el polígono en el centro del mapa y hacer zoom para que entre completamente en la vista
  const centerAndZoomToFitPolygon = (
    polygonPaths: PolygonPaths,
  ): { center: google.maps.LatLng | null; zoom: number | null } => {
    if (map && polygonPaths.length > 0) {
      // Calcula el centro del polígono
      const center = calculatePolygonCenter(polygonPaths);
      // Calcula el nivel de zoom necesario
      const zoom = calculateZoomToFitPolygon(polygonPaths, map) ?? null;

      const bounds = map?.getBounds();

      // Si el mapa tiene un bounds y la gridView esta activa, calcular la mitad izquierda del bounds y centrar el mapa en ella
      if (bounds && viewActive === "grid" && center && !isMobile) {
        // Calcular la mitad izquierda del bounds
        const boundsCenter = bounds.getCenter();
        const boundsEast = bounds.getNorthEast().lng();
        const halfWidth = (boundsEast - boundsCenter.lng()) / 2.5; // esto es asi porque la gridView no ocupa la mitad total de la pantalla

        // Ajustar el centro para centrarlo en la mitad izquierda
        const newLng = boundsCenter.lng() + halfWidth;
        const newCenter = new google.maps.LatLng(center.lat(), newLng);

        return { center: newCenter, zoom };
      }

      return { center, zoom };
    }
    return { center: null, zoom: null };
  };

  return {
    polygonLocation,
  };
};

export default usePolygonLocation;
