import React, { useState, useEffect, useCallback } from "react";
import { Plus } from "react-feather";
import { unitsTableMapper } from "@/redux/mappers/unitsTableMapper";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import notImg from "@/assets/img/slider/notImg.jpg";
import "@/assets/scss/plugins/extensions/react-paginate.scss";
import "@/assets/scss/pages/data-list.scss";
import { ChevronDown } from "react-feather";
import { userIsLoggedIn } from "@/redux/actions";
import SkeletonTableView from "@/components/skeleton/SkeletonTableView";
import { countries, getOrganization } from "@/configs";
import {
  ColumnKey,
  ListEntityTypeKey,
  KeyofSortDirectionKey,
  OrganizationKey,
  ParcelBackendKey,
  SortDirectionBackendKey,
  TabKey,
  PropertyBackendExpandKey,
  PropertyBackendKey,
  PropertyFeatureTabKey,
} from "@/keys";
import { CategoricValueFilters, Property } from "@/models";
import { loadUnits } from "@/services";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAppConfig, selectFilters } from "@/redux/slices";
import { useTranslation } from "react-i18next";
import { ButtonBorderPrimary, ButtonPrimary, Pill } from "@/components/styledComponents";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { useTheme } from "styled-components";
import classNames from "classnames";
import { NumberFormat } from "@/utilities/numberFormat/NumberFormat";
import { UncontrolledTooltip } from "reactstrap";
import { setUnit } from "@/redux/slices/units/unit.slice";
import { useCategoryTranslations, useConvertUnits, useLocaleCountryCode } from "@/hooks";

export const findCategoricFilter = ({
  id,
  arrayCategoric,
}: {
  id: string;
  arrayCategoric: CategoricValueFilters[];
}) => {
  const find = arrayCategoric.find((a) => a.value === id);
  if (find) {
    return find.name ?? find.label;
  } else {
    return "---";
  }
};

type TableUnitsProps = {
  units?: Property[];
  unitsCount?: number;
  setDataTableMapper: React.Dispatch<React.SetStateAction<Property[]>>;
  page?: number;
  paginationPerPageOptions?: number[];
  setPage: (page: number) => void;
  perPage?: number;
  setPerPage: (itemsPerPage: number) => void;
  unitsIsLoading?: boolean;
  clickLocalizationDetail: ({
    id,
    entity,
    elementId,
  }: {
    id: string;
    entity?: string;
    elementId?: string;
  }) => void;
  setShowListsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMemberId: React.Dispatch<React.SetStateAction<string>>;
  setEntityType: React.Dispatch<React.SetStateAction<ListEntityTypeKey | undefined>>;
  activeTab: TabKey | null;
  columnsToDisplay: ColumnKey[];
};

function TableUnits({
  units,
  unitsCount,
  setDataTableMapper,
  page,
  paginationPerPageOptions,
  setPage,
  perPage,
  setPerPage,
  unitsIsLoading,
  clickLocalizationDetail,
  setShowListsModal,
  setMemberId,
  setEntityType,
  activeTab,
  columnsToDisplay,
}: TableUnitsProps) {
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<IDataTableColumn<Property>[]>([]);
  const { t } = useTranslation("tableView");
  const language = t("tabs.units", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { defaultColors, parcelColors } = useTheme();
  const { countryCode } = useLocaleCountryCode();

  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);

  const { convertBuiltArea, languageBuiltAreaUnits } = useConvertUnits();

  const onRowClick = ({ parcelId, unitId }: { parcelId?: string | null; unitId: string }) => {
    dispatch(
      setUnit({
        unitId,
        unitView: true,
      }),
    );
    if (parcelId) {
      clickLocalizationDetail({
        id: parcelId,
        entity: PropertyFeatureTabKey.units,
        elementId: unitId,
      });
    }
  };

  useEffect(() => {
    setColumns([
      // IMG
      {
        omit: !columnsToDisplay.includes(ColumnKey.property_picture_url),
        selector: `${PropertyBackendExpandKey.parcel}.${ParcelBackendKey.photos}`,
        minWidth: "100px",
        maxWidth: "100px",
        cell: (row) => {
          return (
            <div
              className="py-1"
              onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
              <img
                src={
                  row.parcel?.photos && row.parcel?.photos?.length ? row.parcel.photos[0] : notImg
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = notImg;
                }}
                height="60"
                width="80"
                alt={row.id}
                className="img-list"
              />
            </div>
          );
        },
      },
      // NAME/ADDRESS (INCLUDE PARCEL SECTOR & PARCEL SUBSECTOR)
      {
        name: language.columns.nameAddress.title,
        omit: !columnsToDisplay.includes(ColumnKey.name),
        minWidth: "150px",
        cell: (row) => (
          <div
            className="d-flex flex-column w-100"
            onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            <p id={`nameAddress-${row.id}`} className="text-bold-500 line-clamp-1-ellipsis mb-0">
              {row.address ?? "---"}
            </p>
            <UncontrolledTooltip placement="bottom" target={`nameAddress-${row.id}`}>
              {row.address ?? "---"}
            </UncontrolledTooltip>
            <div className="d-flex">
              {row?.parcel?.sectorId ? (
                <Pill
                  className="d-flex align-items-center text-truncate cursor-pointer"
                  color={defaultColors.background}
                  backgroundColor={sectorColorsMapper({
                    sectorName:
                      row.parcel?.sectorId &&
                      filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: row?.parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}
                  borderColor={sectorColorsMapper({
                    sectorName:
                      row.parcel?.sectorId &&
                      filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: row?.parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}>
                  <p className="mb-0 text-overflow-ellipsis" style={{ marginTop: "2px" }}>
                    {row.parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                      ? languageSector(
                          findCategoricFilter({
                            id: row?.parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          }),
                        )
                      : "---"}
                  </p>
                </Pill>
              ) : null}
              {row?.parcel?.subsectorId ? (
                <span className="ml-05 text-overflow-ellipsis" style={{ paddingTop: "4px" }}>
                  {row.parcel?.subsectorId &&
                  filtersByPortal[portal].categoricFilters.subsectors.length
                    ? findCategoricFilter({
                        id: row?.parcel?.subsectorId,
                        arrayCategoric: filtersByPortal[portal].categoricFilters.subsectors,
                      })
                    : "---"}
                </span>
              ) : null}
            </div>
          </div>
        ),
      },
      {
        name: language.columns.unitType.title,
        omit: !columnsToDisplay.includes(ColumnKey.unitType),
        selector: ColumnKey.unitType,
        sortable: true,
        minWidth: "100px",
        maxWidth: "100px",
        cell: (row) => (
          <div
            className="d-flex flex-column"
            onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            <p className="text-bold-500 line-clamp-1-ellipsis mb-0">{row.unitType ?? "---"}</p>
          </div>
        ),
      },
      {
        name: language.columns.unitNumber.title,
        omit: !columnsToDisplay.includes(ColumnKey.unitNumber),
        minWidth: "100px",
        maxWidth: "100px",
        cell: (row) => (
          <div
            className="d-flex flex-column"
            onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            <p className="text-bold-500 line-clamp-1-ellipsis mb-0">{row.unitNumber ?? "---"}</p>
          </div>
        ),
      },
      {
        name: language.columns.builtArea.title,
        omit: !columnsToDisplay.includes(ColumnKey.rentable_area_sqm),
        selector: `${PropertyBackendKey.totalArea}`,
        sortable: true,
        minWidth: "150px",
        maxWidth: "150px",
        cell: (row) => (
          <div
            className="d-flex flex-column"
            onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            <p className="mb-0 fw-bold pill-gray black">
              {row.builtArea
                ? NumberFormat({
                    value: convertBuiltArea({
                      value: Number(row.builtArea),
                    }),
                    prefix: "",
                    suffix: ` ${languageBuiltAreaUnits}`,
                  })
                : "---"}
            </p>
          </div>
        ),
      },
      {
        name: language.columns.cadaster.title,
        omit: !columnsToDisplay.includes(ColumnKey.cadaster),
        selector: PropertyBackendKey.govtId,
        sortable: true,
        cell: (row) => (
          <div
            className="d-flex flex-column"
            onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            <p className="fw-bold line-clamp-1-ellipsis mb-0">{row?.govtId ? row.govtId : "---"}</p>
          </div>
        ),
      },
      {
        name: language.columns.viewUnit.title,
        omit: !columnsToDisplay.includes(ColumnKey.viewUnit),
        minWidth: "150px",
        cell: (row) => (
          <ButtonPrimary onClick={() => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}>
            {language.columns.viewUnit.title}
          </ButtonPrimary>
        ),
      },
      // ADD TO LIST
      {
        omit:
          !getOrganization().lists || !dispatch(userIsLoggedIn) || !countries[countryCode].lists,
        minWidth: getOrganization().lists ? "90px" : "0px",
        maxWidth: getOrganization().lists ? "90px" : "0px",
        cell: (row) => btnAddToList(row.parcel?.id),
      },
    ]);
  }, [activeTab, countryCode]);

  const btnAddToList = (parcelId?: string) => {
    return parcelId ? (
      <ButtonBorderPrimary
        onClick={() => {
          setShowListsModal(true);
          setMemberId(parcelId);
          setEntityType(ListEntityTypeKey.parcel);
        }}
        className="property-detail-btn-circle">
        <Plus size={20} />
      </ButtonBorderPrimary>
    ) : null;
  };

  const fetchUnits = useCallback(
    (page?: number, perPage?: number, column?: string, sortDirectionColumn?: string) => {
      dispatch(
        loadUnits({
          page,
          perPage,
          sortBy: column ? column : sortBy,
          sortOrder:
            ((sortDirectionColumn
              ? sortDirectionColumn
              : sortDirection) as SortDirectionBackendKey.ASC) || SortDirectionBackendKey.DESC,
        }),
      );
    },
    [dispatch, sortBy, sortDirection],
  );

  // useEffect(() => {
  //   fetchUnits(page, perPage);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // data para la exportación
    if (units) {
      const dataMapper = unitsTableMapper(units, language.columns);
      setDataTableMapper?.(dataMapper);
    }
  }, [units]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePerRowsChange = (currentRowsPerPage: number, currentPage: number) => {
    fetchUnits(currentPage, currentRowsPerPage);
    setPerPage(currentRowsPerPage);
  };

  const handlePageChange = (page: number) => {
    fetchUnits(page, perPage);
    setPage(page);
  };

  const handleSort = (column: IDataTableColumn, sortDirectionColumn: KeyofSortDirectionKey) => {
    if (typeof column.selector === "string") {
      setSortBy(column.selector);
      setSortDirection(sortDirectionColumn);
      fetchUnits(page, perPage, column.selector, sortDirectionColumn);
    }
  };

  return (
    <div>
      <div
        className={classNames(`data-list list-view`, {
          ["pagination-fixed"]: getOrganization().organization.key !== OrganizationKey.redatlas,
        })}>
        <DataTable
          className="dataTable-tableView tableLocations"
          style={{
            height: `calc(100vh - ${
              getOrganization().organization.key !== OrganizationKey.redatlas ? "328px" : "364px"
            })`,
          }}
          data={units ? units : []}
          columns={columns}
          progressPending={unitsIsLoading}
          progressComponent={<SkeletonTableView />}
          noHeader
          fixedHeader
          fixedHeaderScrollHeight={
            getOrganization().organization.key !== OrganizationKey.redatlas
              ? "calc(100vh - 359px)"
              : "calc(100vh - 405px)"
          }
          sortIcon={<ChevronDown />}
          onSort={handleSort}
          onRowClicked={(row) => onRowClick({ parcelId: row.parcel?.id, unitId: row.id })}
          sortServer
          pagination
          paginationServer
          paginationServerOptions={{
            persistSelectedOnSort: true,
          }}
          paginationComponentOptions={{
            rowsPerPageText: language.rowsPerPageText,
          }}
          paginationRowsPerPageOptions={paginationPerPageOptions}
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          paginationTotalRows={unitsCount}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={
            <div
              style={{
                position: "absolute",
                top: "calc(50%)",
                width: "100%",
                height: `calc(100vh - ${
                  getOrganization().organization.key !== OrganizationKey.redatlas
                    ? "328px"
                    : dispatch(userIsLoggedIn())
                    ? "364px"
                    : "390px"
                })`,
              }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h4 className="font-weight-bold">{language.noData}</h4>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default TableUnits;
