export enum CurrencyStripeBackend {
  usd = "usd",
}

export enum DurationStripeBackend {
  forever = "forever",
  once = "once",
  repeating = "repeating",
}

export interface CouponStripeBackend {
  id: string;
  object: string;
  amount_off: number | null;
  created: Date;
  currency: CurrencyStripeBackend;
  duration: DurationStripeBackend;
  duration_in_months: number | null;
  livemode: boolean;
  max_redemptions: number | null;
  metadata: object | null;
  name: string | null;
  percent_off: number | null;
  redeem_by: Date | null;
  times_redeemed: number;
  valid: boolean;
}
