import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// GOOGLE ANALYTICS
import ReactGA4 from "react-ga4";

// ------ layouts components
import Navbar from "./components/navbar/Navbar";
import FooterNavbar from "./components/footer/FooterNavbar";
import ResponsiveDesignNotAvailable from "./components/responsiveDesign/ResponsiveDesignNotAvailable";
// import Map from "./components/maps/map";
import Map from "./components/maps/map";

// ------ reusable components
import SpinnerFullWidth from "@/components/loading/SpinnerFullWidth";
import SpinnerFullWidthMap from "@/components/loading/SpinnerFullWidthMap";
import ProgressFullWidth from "@/components/loading/ProgressFullWidth";

// ------ configs
import { appPaths, countries, getOrganization } from "@/configs";

// ------ redux
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { userIsLoggedIn, userHasSubscription, setEmailVerificationView } from "@/redux/actions";
import { History } from "history";

import { useLocation } from "react-router-dom";
import {
  selectBrochure,
  selectFilters,
  selectSubscription,
  selectUser,
  setCallToActionModalOpen,
} from "@/redux/slices";
import NavbarLeft from "./components/corporateSite/navbar/NavbarLeft";
import { useLocaleCountryCode } from "@/hooks";
import { OrganizationKey } from "@/keys";
import SEOComponent from "@/components/seo/SEOComponent";

type MainLayoutProps = {
  children: JSX.Element;
  history: History;
};

function MainLayout({ children, history }: MainLayoutProps) {
  const match = {
    path: useLocation().pathname,
    id: useLocation().pathname.substring(useLocation().pathname.lastIndexOf("/") + 1),
  };
  const { countryCode } = useLocaleCountryCode();
  const app = useAppSelector((state) => state.app);
  const { downloadBrochureProgress } = useAppSelector(selectBrochure);
  const user = useAppSelector(selectUser);
  const sendEmail = useAppSelector((state) => state.auth.password.sendEmailIsLoading);
  const loadingRegister = useAppSelector((state) => state.auth.register.isLoading);
  const loadingLogin = useAppSelector((state) => state.auth.login.isLoading);
  const { subsCriptionIsLoading } = useAppSelector(selectSubscription);
  const { areFiltersAvailable } = useAppSelector(selectFilters);

  const organization = getOrganization().organization.key;
  const isAtlas = organization === OrganizationKey.redatlas;

  const [state, setState] = useState({
    path: "",
  });
  const [disclaimerVisible, setDisclaimerVisible] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (match.path === appPaths.emailVerification.path) {
      if (user.user?.email && !user.user?.verifiedAt) {
        dispatch(setEmailVerificationView());
      } else {
        history.push(appPaths.home.path);
      }
    }
  }, []);

  /**
   * Redirection to the corporate site when the
   * country does not have access to the platform
   */
  useEffect(() => {
    if (!countries[countryCode].hasAccessToPlatform) history.push(appPaths.indexHome.path);
  }, [countryCode]);

  useEffect(() => {
    // to report page view Google Analytics
    if (window.location.pathname + window.location.search !== state.path) {
      setState({
        ...state,
        path: window.location.pathname + window.location.search,
      });
      ReactGA4.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const organizationTheme = app.organizationTheme;

    if (organizationTheme) {
      document.body.classList.add(`theme--${organizationTheme}`);
    } else {
      document.body.classList.add(`theme--redatlas`);
    }
  }, [app.organizationTheme]);

  const loading = () => {
    if (dispatch(userIsLoggedIn())) {
      if (downloadBrochureProgress) {
        return <ProgressFullWidth progress={downloadBrochureProgress} />;
      } else {
        return subsCriptionIsLoading || user.isLoading || loadingLogin || !areFiltersAvailable ? (
          match.path === appPaths.tableView.path ? (
            <SpinnerFullWidthMap />
          ) : (
            <SpinnerFullWidth />
          )
        ) : null;
      }
    } else {
      return user.isLoading ||
        sendEmail ||
        loadingRegister ||
        subsCriptionIsLoading ||
        loadingLogin ? (
        <SpinnerFullWidth />
      ) : null;
    }
  };

  const clickBlocked = () => {
    dispatch(userIsLoggedIn());
    if (match.path === appPaths.emailVerification.path) {
      dispatch(setEmailVerificationView());
    } else {
      dispatch(setCallToActionModalOpen({ callToActionModalOpen: true }));
    }
  };

  return (
    <div className="wrapper horizontal-layout theme-primary navbar-floating">
      <SEOComponent title="RED Atlas" description="" showREDAtlas={false} />
      <div
        style={{ height: "100vh", width: "100vw" }} //Google maps
        className="app-content content p-0">
        {/*         {window.innerWidth < 1167 && (
          <ResponsiveDesignNotAvailable
            userHasSubscription={userHasSubscription}
            clickBlocked={clickBlocked}
          />
        )} */}
        {window.innerWidth < 1024 && disclaimerVisible && !isAtlas && (
          <ResponsiveDesignNotAvailable
            userHasSubscription={userHasSubscription}
            clickBlocked={clickBlocked}
          />
        )}

        {loading()}

        <ToastContainer />

        <Navbar
          windowWidth={window.innerWidth}
          history={history}
          match={match}
          clickBlocked={clickBlocked}
          portal={app.portal}
        />
        <div>{children}</div>

        <Map clickBlocked={clickBlocked} match={match} />

        <NavbarLeft />
      </div>

      <FooterNavbar
        match={match}
        clickBlocked={clickBlocked}
        portal={app.portal}
        windowWidth={window.innerWidth}
      />

      <div className="sidenav-overlay" />
    </div>
  );
}

export default MainLayout;
