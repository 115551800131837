import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "cfe4ea8b-fefa-41a1-9f82-4af02f8a4781",
    address: null,
    buyerName: " ISMAEL   TEXIDOR PAGÁN",
    createdAt: null,
    deedBook: null,
    deedNumber: null,
    deedPage: null,
    sellerName: null,
    transactionDate: "2023-12-22T04:00:00.000Z",
    transactionPrice: "470000.0",
    transactionType: "Compraventa",
    scriptures: {
      uploaded:
        "https://karibe.ridpr.pr.gov/karibefiles/KaribeArchivosCargados/2023-12/CE92C1C1-3CBC-4700-9F33-1C5ABD8E0F67.pdf",
      generated:
        "https://karibe.ridpr.pr.gov/karibefiles/KaribeArchivosGenerados/76CF6005-A163-45E3-80F7-9F2AECE284DD.pdf",
    },
    transactionProperties: [
      {
        createdAt: "2023-12-27T22:06:30.255Z",
        fincaNumber: null,
        govtId: "041-079-011-03-271",
        id: "2b50465f-639b-5d66-bab3-f89f2229f6c1",
        property: {
          id: "efb57e2d-7ec0-d297-c901-befc2bd8861b",
          address: "APT B 714 ISLA VERDE COND PLAYA DORADA CAROLINA",
          addressJSON: {
            id: "efb57e2d-7ec0-d297-c901-befc2bd8861b",
            km: null,
            sector: null,
            zipCode: null,
            dataType: "unit",
            original: "APT B 714 ISLA VERDE COND PLAYA DORADA CAROLINA",
            sourceId: "faf69cc8-34a2-002a-7d4e-05d2cc329082",
            unitType: {
              value: "Apartment",
              status: "confirmed",
            },
            streetName: null,
            unitNumber: {
              value: "B 714 ",
              status: "confirmed",
            },
            buildingName: {
              value: "Condominio Playa Dorada",
              method: "exact_match_es",
              status: "confirmed",
              parcelId: "027f194a-a508-bd7b-d528-768ed9b16374",
            },
            streetNumber: null,
            joinedAddress: null,
          },
          builtArea: "83.5",
          geometry: {
            type: "Point",
            coordinates: [-66.01145795, 18.44310277],
          },
          transactions: [],
          govtId: "041-079-011-03-271",
          toilets: null,
          rooms: null,
          parkingSpaces: null,
          amenity: null,
          city: null,
          neighborhood: null,
          parcelId: "027f194a-a508-bd7b-d528-768ed9b16374",
          parcel: {
            id: "027f194a-a508-bd7b-d528-768ed9b16374",
            name: "Condominio Playa Dorada",
            address: "Condominio Playa Dorada, 7041 Carretera 187",
            photos: [
              "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167940991997531f8bcf04c020f631488.png",
              "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1679409920128be90a7d30b27cfd70a89.jpeg",
            ],
            sector: {
              name: "Residential",
            },
            subsector: {
              name: "Condominium",
            },
            city: {
              name: "Carolina",
            },
          },
          taxes: null,
          listings: null,
          unitType: "Apartment",
          unitNumber: "B 714 ",
          lastTransactionPrice: 0,
          lastTransactionTotalAreaPricePerSqm: 0,
        },
        builtArea: "83.5",
        solar: null,
      },
    ],
    source: {
      name: "KARIBE",
      logoUrl:
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/6ddc66525719390399cfb6e9f2ea1505image%20%288%29.png",
    },
    sector: null,
    subsector: {
      name: "Compraventa",
      nameEn: "Sale-Purchase",
    },
    microsector: null,
    pricePerBuiltSqm: "5628.7503856517249174",
    pricePerLotSqm: 0,
    builtArea: 83.5,
    solar: "APT B 714 ISLA VERDE COND PLAYA DORADA CAROLINA",
    unitType: "Apartment",
    unitNumber: "B 714 ",
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    transactionProperties: [
      {
        ...value.transactionProperties[0],
        property: {
          ...value.transactionProperties[0].property,
          parcel: {
            ...value.parcel,
            photos: [getRandomImage()],
          },
        },
      },
    ],
  }));
