import { Listing } from "@/models";
import moment from "moment";

interface defaultListingsOrderProps {
  listings: Listing[];
}

// El orden de los listings debe ser primero activos ordenados por la fecha de creación (createdAt) y luego los removidos ordenados por la fecha de desactivación (deactivatedAt)
export const defaultListingsOrder = ({ listings }: defaultListingsOrderProps) => {
  const activeListings: Listing[] = [];
  const removedListings: Listing[] = [];
  listings.map((listing) => {
    if (listing.active) {
      activeListings.push(listing);
    } else {
      removedListings.push(listing);
    }
  });
  activeListings.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
  removedListings.sort((a, b) => moment(b.deactivatedAt).diff(moment(a.deactivatedAt)));
  return { activeListings, removedListings };
};
