import { NewsFiltersFrontKey, NewsFiltersTypeFrontKey } from "@/keys";
import { FilterValue, NumericFilter, OptionType } from "@/models";
import { Filters, OnlyKeyEntityFilter } from "@/redux/slices";
import React, { useState, useRef, useEffect } from "react";
import { Input, InputGroup } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ChevronDown, ChevronUp } from "react-feather";
import { DeleteCommas } from "@/utilities";
import "./styles/InputWithSelect.style.css";
import { useTheme } from "styled-components";

interface InputWithSelectProps {
  options: OptionType[];
  label: string;
  filter?: FilterValue<NumericFilter>;
  placeholder: {
    min: string;
    max: string;
  };
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  handleOnBlur: () => void;
  reduxFilters?: {
    originalFilter?: FilterValue<NumericFilter>;
    matchFilter?: FilterValue<NumericFilter>;
  };
  filterKeys: {
    name: NewsFiltersFrontKey;
    entity: OnlyKeyEntityFilter;
    type: Extract<NewsFiltersTypeFrontKey, NewsFiltersTypeFrontKey.numeric>;
  };
}

function InputWithSelect({
  options,
  label,
  filter,
  placeholder,
  handleMinMaxChange,
  handleOnBlur,
  reduxFilters,
  filterKeys,
}: InputWithSelectProps) {
  const { secondary } = useTheme();
  const [openOptionsMin, setOpenOptionsMin] = useState(false);
  const [openOptionsMax, setOpenOptionsMax] = useState(false);
  const [selectedIndexMin, setSelectedIndexMin] = useState<number | null>(null);
  const [selectedIndexMax, setSelectedIndexMax] = useState<number | null>(null);
  const optionsMinRef = useRef<HTMLElement>();
  const optionsMaxRef = useRef<HTMLElement>();
  const [filteredOptionsMin, setFilteredOptionsMin] = useState<OptionType[]>(options);
  const [filteredOptionsMax, setFilteredOptionsMax] = useState<OptionType[]>(options);
  const inputMinRef = useRef<HTMLInputElement>(null);
  const inputMaxRef = useRef<HTMLInputElement>(null);

  // --------- Cuando el user se desplaza hacia arriba o hacia abajo de las opciones
  const handleKeyDownMin = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndexMin((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptionsMin.length - 1),
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndexMin((prevIndex) =>
        prevIndex === null ? filteredOptionsMin.length - 1 : Math.max(prevIndex - 1, 0),
      );
    } else if (
      e.key === "Enter" &&
      selectedIndexMin !== null &&
      openOptionsMin &&
      filteredOptionsMin.length
    ) {
      handleMinMaxChange({
        name: filterKeys.name,
        value: { min: filteredOptionsMin[selectedIndexMin].value },
        entity: filterKeys.entity,
        type: filterKeys.type,
      });
      setOpenOptionsMin(false);
      setSelectedIndexMin(0);
    }
  };
  const handleKeyDownMax = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();

      setSelectedIndexMax((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptionsMax.length - 1),
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      setSelectedIndexMax((prevIndex) =>
        prevIndex === null ? filteredOptionsMax.length - 1 : Math.max(prevIndex - 1, 0),
      );
    } else if (
      e.key === "Enter" &&
      selectedIndexMax !== null &&
      openOptionsMax &&
      filteredOptionsMax.length
    ) {
      handleMinMaxChange({
        name: filterKeys.name,
        value: { max: filteredOptionsMax[selectedIndexMax].value },
        entity: filterKeys.entity,
        type: filterKeys.type,
      });
      setOpenOptionsMax(false);
      setSelectedIndexMax(0);
    }
  };

  // --------- Para actualizar el scroll a medida que el user se desplaza en las opciones
  useEffect(() => {
    if (optionsMinRef.current && selectedIndexMin !== null) {
      const selectedOption = optionsMinRef.current.childNodes[selectedIndexMin] as HTMLElement;
      if (selectedOption) {
        const containerRect = optionsMinRef.current.getBoundingClientRect();
        const optionRect = selectedOption.getBoundingClientRect();
        if (optionRect.bottom > containerRect.bottom) {
          optionsMinRef.current.scrollTop += optionRect.bottom - containerRect.bottom;
        } else if (optionRect.top < containerRect.top) {
          optionsMinRef.current.scrollTop -= containerRect.top - optionRect.top;
        }
      }
    }
    return;
  }, [selectedIndexMin, filteredOptionsMin]);

  useEffect(() => {
    if (optionsMaxRef.current && selectedIndexMax !== null) {
      const selectedOption = optionsMaxRef.current.childNodes[selectedIndexMax] as HTMLElement;
      if (selectedOption) {
        const containerRect = optionsMaxRef.current.getBoundingClientRect();
        const optionRect = selectedOption.getBoundingClientRect();
        if (optionRect.bottom > containerRect.bottom) {
          optionsMaxRef.current.scrollTop += optionRect.bottom - containerRect.bottom;
        } else if (optionRect.top < containerRect.top) {
          optionsMaxRef.current.scrollTop -= containerRect.top - optionRect.top;
        }
      }
    }
    return;
  }, [selectedIndexMax, filteredOptionsMax]);

  // --------- Filtrado de los datos en las opciones
  useEffect(() => {
    if (filter?.value.min) {
      const filtered = (
        filter?.value.max
          ? options.filter(
              (option) =>
                parseFloat(option.value) <=
                parseFloat(DeleteCommas(filter.value.max.toString()).toLowerCase()),
            )
          : options
      ).filter((option) =>
        option.value
          .toLowerCase()
          .includes(DeleteCommas(filter.value.min.toString()).toLowerCase()),
      );
      setFilteredOptionsMin(filtered);
      setSelectedIndexMin(0);

      // filtrado para las options de max
      const filteredMax = options.filter(
        (option) =>
          parseFloat(option.value) >=
          parseFloat(DeleteCommas(filter.value.min.toString()).toLowerCase()),
      );

      setFilteredOptionsMax(filteredMax);
    } else {
      setFilteredOptionsMin(
        filter?.value.max
          ? options.filter(
              (option) =>
                parseFloat(option.value) <=
                parseFloat(DeleteCommas(filter.value.max.toString()).toLowerCase()),
            )
          : options,
      );
      setSelectedIndexMin(0);
    }
  }, [filter?.value.min]);

  useEffect(() => {
    if (filter?.value.max) {
      const filtered = (
        filter?.value.min
          ? options.filter(
              (option) =>
                parseFloat(option.value) >=
                parseFloat(DeleteCommas(filter.value.min.toString()).toLowerCase()),
            )
          : options
      ).filter((option) =>
        option.value
          .toLowerCase()
          .includes(DeleteCommas(filter.value.max.toString()).toLowerCase()),
      );
      setFilteredOptionsMax(filtered);
      setSelectedIndexMax(0);

      // filtrado para las options de min
      const filteredMin = options.filter(
        (option) =>
          parseFloat(option.value) <=
          parseFloat(DeleteCommas(filter.value.max.toString()).toLowerCase()),
      );

      setFilteredOptionsMin(filteredMin);
    } else {
      setFilteredOptionsMax(
        filter?.value.min
          ? options.filter(
              (option) =>
                parseFloat(option.value) >=
                parseFloat(DeleteCommas(filter.value.min.toString()).toLowerCase()),
            )
          : options,
      );
      setSelectedIndexMax(0);
    }
  }, [filter?.value.max]);

  return (
    <>
      <h6 className="mb-05 fw-bold black ml-1">{label}</h6>
      <div className="d-flex">
        {filter && (
          <div className="input-container">
            <InputGroup className="inputGroupPriceFrom">
              {/* <InputGroupAddon addonType="prepend">$</InputGroupAddon> */}
              <Input
                innerRef={inputMinRef}
                placeholder={placeholder.min}
                type="text"
                value={filter.value.min}
                className="font-size-16px"
                onChange={(value: React.FormEvent<HTMLInputElement>) =>
                  handleMinMaxChange({
                    name: filterKeys.name,
                    value: { min: value.currentTarget.value },
                    entity: filterKeys.entity,
                    type: filterKeys.type,
                  })
                }
                onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                  if (
                    reduxFilters?.originalFilter?.value.min !== value.currentTarget.value &&
                    reduxFilters?.matchFilter?.value.min !== value.currentTarget.value
                  ) {
                    handleOnBlur();
                  }
                  setTimeout(() => {
                    setOpenOptionsMin(false), setSelectedIndexMin(null);
                  }, 200);
                }}
                onFocus={() => {
                  setOpenOptionsMin(true);
                  setSelectedIndexMin(0);
                }}
                onClick={() => {
                  setOpenOptionsMin(true);
                  setSelectedIndexMin(0);
                }}
                onKeyDown={handleKeyDownMin}
              />
              <div
                style={{ position: "absolute", zIndex: 100, right: "9.8px", top: "9.8px" }}
                onClick={() => {
                  if (inputMaxRef.current && inputMinRef.current) {
                    if (openOptionsMin) {
                      inputMinRef.current.blur();
                    } else {
                      inputMinRef.current.focus();
                    }
                    inputMaxRef.current.blur();
                  }
                }}>
                {openOptionsMin && filteredOptionsMin.length ? (
                  <ChevronUp style={{ color: secondary.color, width: "20px", height: "20px" }} />
                ) : (
                  <ChevronDown style={{ color: secondary.color, width: "20px", height: "20px" }} />
                )}
              </div>
            </InputGroup>

            {openOptionsMin && filteredOptionsMin.length ? (
              <PerfectScrollbar
                component="ul"
                className="options-list"
                containerRef={(el) => (optionsMinRef.current = el)}
                options={{ wheelPropagation: false }}>
                {filteredOptionsMin.map((option, index) => (
                  <li
                    key={index}
                    className={index === selectedIndexMin ? "selected" : ""}
                    onClick={() => {
                      handleMinMaxChange({
                        name: filterKeys.name,

                        value: { min: option.value },
                        entity: filterKeys.entity,
                        type: filterKeys.type,
                      });
                      if (inputMinRef.current) {
                        inputMinRef.current.focus(); // Focus the input after changing the value
                      }
                      setOpenOptionsMin(false);
                      setSelectedIndexMin(null);
                    }}>
                    {option.label}
                  </li>
                ))}
              </PerfectScrollbar>
            ) : null}
          </div>
        )}
        {filter && (
          <div className="input-container">
            <InputGroup className="inputGroupPriceTo">
              <Input
                style={{ borderRadius: "5px" }}
                innerRef={inputMaxRef}
                placeholder={placeholder.max}
                type="text"
                className="font-size-16px"
                value={filter.value.max}
                onChange={(value: React.FormEvent<HTMLInputElement>) => {
                  handleMinMaxChange({
                    name: filterKeys.name,

                    value: { max: value.currentTarget.value },
                    entity: filterKeys.entity,
                    type: filterKeys.type,
                  });
                }}
                onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                  if (
                    reduxFilters?.originalFilter?.value.max !== value.currentTarget.value &&
                    reduxFilters?.matchFilter?.value.max !== value.currentTarget.value
                  ) {
                    handleOnBlur();
                  }
                  setTimeout(() => {
                    setOpenOptionsMax(false), setSelectedIndexMax(null);
                  }, 200);
                }}
                onFocus={() => {
                  setOpenOptionsMax(true);
                  setSelectedIndexMax(0);
                }}
                onClick={() => {
                  setOpenOptionsMax(true);
                  setSelectedIndexMax(0);
                }}
                onKeyDown={handleKeyDownMax}
              />
              <div
                style={{ position: "absolute", zIndex: 100, right: "9.8px", top: "9.8px" }}
                onClick={() => {
                  if (inputMaxRef.current && inputMinRef.current) {
                    if (openOptionsMax) {
                      inputMaxRef.current.blur();
                    } else {
                      inputMaxRef.current.focus();
                    }
                    inputMinRef.current.blur();
                  }
                }}>
                {openOptionsMax && filteredOptionsMax.length ? (
                  <ChevronUp style={{ color: secondary.color, width: "20px", height: "20px" }} />
                ) : (
                  <ChevronDown style={{ color: secondary.color, width: "20px", height: "20px" }} />
                )}
              </div>
            </InputGroup>

            {openOptionsMax && filteredOptionsMax.length ? (
              <PerfectScrollbar
                component="ul"
                className="options-list"
                containerRef={(el) => (optionsMaxRef.current = el)}
                options={{ wheelPropagation: false }}>
                {filteredOptionsMax.map((option, index) => (
                  <li
                    key={index}
                    className={index === selectedIndexMax ? "selected" : ""}
                    onClick={() => {
                      handleMinMaxChange({
                        name: filterKeys.name,
                        value: { max: option.value },
                        entity: filterKeys.entity,
                        type: filterKeys.type,
                      });
                      if (inputMaxRef.current) {
                        inputMaxRef.current.focus(); // Focus the input after changing the value
                      }
                      setOpenOptionsMax(false);
                      setSelectedIndexMax(null);
                    }}>
                    {option.label}
                  </li>
                ))}
              </PerfectScrollbar>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}

export default InputWithSelect;
