import {
  BodyExportFileBackend,
  PermitBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  responsePermitsAdapter,
} from "@/adapters";
import { AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { defaultFilters } from "@/configs";
import {
  CountryCodeBackendKey,
  TabKey,
  useEndpoint,
  PermitBackendExpandKey,
  PermitBackendKey,
  SourceBackendKey,
  ParcelBackendKey,
  SortDirectionBackendKey,
  KeyofSortDirectionBackendKey,
  CountryCodeKey,
  PermitBackendInclude,
  PermitSectorBackendKey,
} from "@/keys";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "../api";
import { useLocaleCountryCode } from "@/hooks";
import { Permit } from "@/models";

export const permitApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPermits: builder.query<
      Permit[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PermitBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().permits({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: PermitBackend[] }): Permit[] =>
        responsePermitsAdapter(response.data),
    }),
    getPermitsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PermitBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().permits({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getPermitsExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<PermitBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createPermitExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const loadPermits = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    try {
      dispatch(setLoading({ portal, tabkey: TabKey.permits, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy: sortBy ?? PermitBackendKey.date,
        sortOrder: sortOrder ?? SortDirectionBackendKey.DESC,
        searchIds: isElasticSearch ? { permit: searchIds.permit } : undefined,
      });
      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().permits({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.permits, count: responseCount.data.data }));
      } else {
        const include: PermitBackendInclude[] = [
          `${PermitBackendKey.id}`,
          `${PermitBackendKey.address}`,
          `${PermitBackendKey.name}`,
          `${PermitBackendKey.category}`,
          `${PermitBackendKey.status}`,
          `${PermitBackendKey.date}`,
          PermitBackendKey.procedure,
          `${PermitBackendKey.url}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.photos}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.name}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.address}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.sectorId}`,
          `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.subsectorId}`,
          `${PermitBackendExpandKey.source}.${SourceBackendKey.name}`,
          `${PermitBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
          `${PermitBackendExpandKey.sector}.${PermitSectorBackendKey.name}`,
          `${PermitBackendExpandKey.sector}.${PermitSectorBackendKey.nameEn}`,
          `${PermitBackendExpandKey.subsector}.${PermitSectorBackendKey.name}`,
          `${PermitBackendExpandKey.subsector}.${PermitSectorBackendKey.nameEn}`,
        ];
        const responseColumns: AxiosResponse<{ data: PermitBackend[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().permits({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
        );
        dispatch(
          setColumns({
            portal,
            tabkey: TabKey.permits,
            columns: responsePermitsAdapter(responseColumns.data.data),
          }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any permits", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.permits, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.permits, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useLazyGetPermitsExportFileQuery,
  useLazyGetPermitsQuery,
  useLazyGetPermitsCountQuery,
} = permitApi;
