import type { Property } from "@/models";
import { PropertyBackend, responsePropertyAdapter } from "@/adapters";

export const responsePropertiesAdapter = (properties: PropertyBackend[]): Property[] => {
  const newProperties: Property[] = [];

  if (properties.length) {
    properties.map((property) => {
      newProperties.push(responsePropertyAdapter(property));
    });
  }

  return newProperties;
};
