import {
  ParcelBackend,
  responseTenantsAdapter,
  responseParcelAddressesJSONAdapter,
  responsePropertiesAdapter,
  responseListingsAdapter,
  responsePermitsAdapter,
  responseTransactionsAdapter,
} from "@/adapters";
import { Parcel } from "@/models";
import { convertGeoJson } from "@/utilities";

export const responseParcelAdapter = (parcel: ParcelBackend): Parcel => ({
  address: parcel.address,
  addressJSON: parcel.addressJSON,
  addresses: parcel.addressesJSON ? responseParcelAddressesJSONAdapter(parcel.addressesJSON) : [],
  applicableRegulation: parcel.applicableRegulation,
  cityId: parcel.cityId,
  classification: parcel.classification,
  classificationValidationDate: parcel.classificationValidationDate,
  createdAt: parcel.createdAt,
  districtOverlay: parcel.districtOverlay,
  floodZone: parcel.floodZone,
  floodZoneAdvisory: parcel.floodZoneAdvisory,
  floodingPanel: parcel.floodingPanel,
  floodway: parcel.floodway,
  geoHash: parcel.geoHash,
  geometry: parcel.geometry,
  govtId: parcel.govtId,
  id: parcel.id,
  isFloodZone: parcel.isFloodZone,
  landArea: parcel.landArea,
  microsectorId: parcel.microsectorId,
  name: parcel.name?.length ? parcel.name : null,
  neighborhoodId: parcel.neighborhoodId,
  subNeighborhoodId: parcel.subNeighborhoodId,
  parcelType: parcel.parcelType,
  photos: parcel.photos,
  putClassification: parcel.putClassification,
  putStatus: parcel.putStatus,
  qualification: parcel.qualification,
  sectorId: parcel.sectorId,
  soil: parcel.soil,
  subsectorId: parcel.subsectorId,
  updatedAt: parcel.updatedAt,
  usesPermitted: parcel.usesPermitted,
  parcelSector: parcel.parcelSector,
  historicArea: parcel.historicArea,
  historicSite: parcel.historicSite,
  geodataValidationDate: parcel.geodataValidationDate,
  city:
    parcel.city !== undefined && parcel.city !== null
      ? { value: parcel.city.id, label: parcel.city.name }
      : null,
  neighborhood:
    parcel.neighborhood !== undefined && parcel.neighborhood !== null
      ? { value: parcel.neighborhood.id, label: parcel.neighborhood.name }
      : null,
  subNeighborhood:
    parcel.subNeighborhood !== undefined && parcel.subNeighborhood !== null
      ? { value: parcel.subNeighborhood.id, label: parcel.subNeighborhood.name }
      : null,
  listings: parcel.listings !== undefined ? responseListingsAdapter(parcel.listings) : [],
  permits: parcel.permits !== undefined ? responsePermitsAdapter(parcel.permits) : [],
  tenants: parcel.tenants !== undefined ? responseTenantsAdapter(parcel.tenants) : [],
  properties: parcel.properties !== undefined ? responsePropertiesAdapter(parcel.properties) : [],
  transactions:
    parcel.transactions !== undefined ? responseTransactionsAdapter(parcel.transactions) : [],
  sector: parcel.sector,
  subsector: parcel.subsector,
  microsector: parcel.microsector,
  centroid: parcel.centroid,
  zipCode: parcel.zipCode
    ? {
        id: parcel.zipCode.id,
        zipCode: parcel.zipCode.zipCode,
        geometry: parcel.zipCode.geometry
          ? convertGeoJson({
              geojson: parcel.zipCode.geometry,
            })
          : null,
      }
    : null,
  oldClassificationJSON: parcel.oldClassificationJSON,
  building: parcel.building,
});
