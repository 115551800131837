import React from "react";
import LogoNews from "@/assets/img/icons/logo-news.png";
import { appPaths, getOrganization } from "@/configs";
import { userIsLoggedIn } from "@/redux/actions";
// import { Badge } from "reactstrap";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
// import { getNewsNotificationsCount, useGetNewsNotificationsQuery } from "@/services";
// import { getCookie, setCookieNewsNotificationExpired } from "@/cookies";
import { CookiesKey } from "@/keys";
import { History } from "history";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { selectUser } from "@/redux/slices";

type NewsButtonProps = {
  history: History;
  clickBlocked: () => void;
  windowWidth: number;
};

function NewsButton({ history, clickBlocked, windowWidth }: NewsButtonProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar", { returnObjects: true });

  const { secondary } = useTheme();
  const token = useAppSelector((state) => state.auth.login.token);
  // const newsNotifications = useAppSelector(getNewsNotificationsCount);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  // const defaultNewsNotificationsCount = 9;

  // --------- Get News Notifications first Time OR if the cookie expired
  // const { isFetching } = useGetNewsNotificationsQuery(undefined, {
  //   skip:
  //     (getCookie(CookiesKey.newsNotificationLastDate) && token && dispatch(userIsLoggedIn())) ||
  //     (getCookie(CookiesKey.newsNotificationLastDate) &&
  //       token &&
  //       dispatch(userIsLoggedIn()) &&
  //       !getCookie(CookiesKey.newsNotificationExpired))
  //       ? false
  //       : true,
  //   pollingInterval: !getCookie(CookiesKey.newsNotificationExpired) ? 500 : 0,
  // });

  // --------- Set the cookie with expiration date again
  // useEffect(() => {
  //   if (isFetching) {
  //     setCookieNewsNotificationExpired({ hoursToExpire: 2 });
  //   }
  // }, [isFetching]);

  const onClickNews = () => {
    history.push(appPaths.news.path);
  };

  return getOrganization().menu.news.show ? (
    <li className="d-flex align-items-center">
      <div
        className="cursor-pointer d-flex align-items-center btn-footer-navbar position-relative"
        onClick={() => onClickNews()}>
        <img
          src={LogoNews}
          alt="building-house"
          height={16}
          className={`${windowWidth <= 1236 ? "" : "mr-1-custom"}`}
          style={{ marginBottom: "2px" }}
        />
        {windowWidth <= 1236 ? null : (
          <div className="d-flex justify-content-end">
            <h6 className="mb-0 fw-bold black">{language.news.title}</h6>
          </div>
        )}

        {/* {!newsNotifications ? (
          <Badge
            pill
            style={{ color: secondary.contrastColor, backgroundColor: secondary.color }}
            className="badge-up">
            {`+${defaultNewsNotificationsCount}`}
          </Badge>
        ) : newsNotifications?.count ? (
          <Badge
            pill
            style={{ color: secondary.contrastColor, backgroundColor: secondary.color }}
            className="badge-up">
            {newsNotifications.count >= defaultNewsNotificationsCount
              ? `+${defaultNewsNotificationsCount}`
              : newsNotifications.count}
          </Badge>
        ) : null} */}
      </div>
    </li>
  ) : null;
}

export default NewsButton;
