import {
  BodyExportFileBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  responseTenantsAdapter,
  TenantBackend,
} from "@/adapters";
import { AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { defaultFilters } from "@/configs";
import {
  CountryCodeBackendKey,
  ParcelBackendKey,
  TabKey,
  useEndpoint,
  TenantBackendExpandKey,
  TenantBackendKey,
  KeyofSortDirectionBackendKey,
  SectorBackendKey,
  SubsectorBackendKey,
  MicrosectorBackendKey,
  SourceBackendKey,
  CountryCodeKey,
  TenantBackendInclude,
} from "@/keys";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "../api";
import { useLocaleCountryCode } from "@/hooks";
import { Tenant } from "@/models";

export const tenantApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getTenants: builder.query<
      Tenant[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TenantBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().tenants({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: TenantBackend[] }): Tenant[] =>
        responseTenantsAdapter(response.data),
    }),
    getTenantsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TenantBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().tenants({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getTenantsExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TenantBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createTenantExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const loadTenants = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    try {
      dispatch(setLoading({ portal, tabkey: TabKey.tenants, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy,
        sortOrder,
        searchIds: isElasticSearch ? { tenant: searchIds.tenant } : undefined,
      });

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().tenants({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.tenants, count: responseCount.data.data }));
      } else {
        const include: TenantBackendInclude[] = [
          `${TenantBackendKey.id}`,
          `${TenantBackendKey.pictures}`,
          `${TenantBackendKey.name}`,
          `${TenantBackendKey.url}`,
          `${TenantBackendKey.address}`,
          `${TenantBackendExpandKey.source}.${SourceBackendKey.name}`,
          `${TenantBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
          `${TenantBackendExpandKey.sector}.${SectorBackendKey.name}`,
          `${TenantBackendExpandKey.subsector}.${SubsectorBackendKey.name}`,
          `${TenantBackendExpandKey.microsector}.${MicrosectorBackendKey.name}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.name}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.address}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.photos}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.sectorId}`,
          `${TenantBackendExpandKey.parcel}.${ParcelBackendKey.subsectorId}`,
        ];
        const responseColumns: AxiosResponse<{ data: TenantBackend[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().tenants({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
        );
        dispatch(
          setColumns({
            portal,
            tabkey: TabKey.tenants,
            columns: responseTenantsAdapter(responseColumns.data.data),
          }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any tenants", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.tenants, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.tenants, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useLazyGetTenantsExportFileQuery,
  useLazyGetTenantsQuery,
  useLazyGetTenantsCountQuery,
} = tenantApi;
