export enum TenantKey {
  id = "id",
  name = "name",
  url = "url",
  geometry = "geometry",
  pictures = "pictures",
  propertySector = "propertySector",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  extractedAt = "extractedAt",
  sourceId = "sourceId",
  cityId = "cityId",
  parcelId = "parcelId",
  phones = "phones",
}

export enum TenantExpandKey {
  source = "source",
  city = "city",
  parcel = "parcel",
}
