import qs from "qs";

export const paramsSerializer = (params) => {
  //devuelve los parametros que estan con array en formato que necesita el back
  //quita los parametros q estan vacios, sino manda todos igual
  const newParams = {};
  for (const property in params) {
    if (params[property]) newParams[property] = params[property];
  }

  return qs.stringify(newParams);
};
