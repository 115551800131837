import type { ComparableListing } from "@/models";
import { ListingBackend, responseListingAdapter } from "@/adapters";

export const responseComparableListingAdapter = (listings: ComparableListingBackend[]) => {
  const newListings: ComparableListing[] = [];

  if (listings.length) {
    listings.map((listing) => {
      newListings.push({
        ...responseListingAdapter(listing),
        distanceInKm: listing.distanceInKm,
      });
    });
  }

  return newListings;
};

export type ComparableListingBackend = ListingBackend & {
  distanceInKm: number;
}
