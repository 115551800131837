import {
  responseParcelAdapter,
  ReportPropertyBackend,
  responsePropertyAdapter,
  responseListingsAdapter,
  responseTransactionsAdapter,
  responsePermitsAdapter,
  responseTaxesAdapter,
  responseTenantsAdapter,
  responseComparableListingAdapter,
  responseComparableTransactionsAdapter,
  responseReportValuationAdapter,
  responseAnalyticsAreaChartAdapter,
  responseAnalyticsColumnChartAdapter,
} from "@/adapters";
import { ReportProperty } from "@/models";

export const responseReportPropertyAdapter = (data: ReportPropertyBackend): ReportProperty => {
  const newData: ReportProperty = {
    report: {
      unit: responsePropertyAdapter(data.report.unit),
      parcel: data.report.parcel ? responseParcelAdapter(data.report.parcel) : null,
      listings: data.report.listings ? responseListingsAdapter(data.report.listings) : [],
      transactions: data.report.transactions
        ? responseTransactionsAdapter(data.report.transactions)
        : [],
      permits: data.report.permits ? responsePermitsAdapter(data.report.permits) : [],
      tenants: data.report.tenants ? responseTenantsAdapter(data.report.tenants) : [],
      taxes: data.report.taxes ? responseTaxesAdapter(data.report.taxes) : [],
      documents: data.report.documents ? responseTransactionsAdapter(data.report.documents) : [],
      analytics: {
        transactionAveragePrice: data.report.analytics.transactionAveragePrice
          ? {
              columnChart: responseAnalyticsColumnChartAdapter(
                data.report.analytics.transactionAveragePrice,
              ),
              areaChart: responseAnalyticsAreaChartAdapter(
                data.report.analytics.transactionAveragePrice,
              ),
            }
          : {
              columnChart: undefined,
              areaChart: undefined,
            },
        transactionVolume: data.report.analytics.transactionVolume
          ? {
              columnChart: responseAnalyticsColumnChartAdapter(
                data.report.analytics.transactionVolume,
              ),
              areaChart: responseAnalyticsAreaChartAdapter(data.report.analytics.transactionVolume),
            }
          : {
              columnChart: undefined,
              areaChart: undefined,
            },
      },
      comparables: {
        activeListings: data.report.comparables.activeListings
          ? responseComparableListingAdapter(data.report.comparables.activeListings)
          : [],
        inactiveListings: data.report.comparables.inactiveListings
          ? responseComparableListingAdapter(data.report.comparables.inactiveListings)
          : [],
        transactions: data.report.comparables.transactions
          ? responseComparableTransactionsAdapter(data.report.comparables.transactions)
          : [],
      },
      valuation: data.report.valuation
        ? responseReportValuationAdapter(data.report.valuation)
        : null,
    },
    userForm: data.userForm,
  };

  return newData;
};
