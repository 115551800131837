import { CouponStripeBackend, CouponBackend } from "@/adapters";
import { Coupon, CouponStripe } from "@/models";

export const responseCouponAdapter = (
  coupon: CouponStripeBackend | CouponBackend,
): CouponStripe | Coupon => {
  if ((coupon as CouponBackend)?.couponId) {
    return {
      id: coupon.id,
      name: coupon.name,
      couponId: (coupon as CouponBackend).couponId,
      updatedAt: (coupon as CouponBackend).updatedAt,
      createdAt: (coupon as CouponBackend).createdAt,
      deletedAt: (coupon as CouponBackend).deletedAt,
      expirationDate: (coupon as CouponBackend).expirationDate,
      trialDays: (coupon as CouponBackend).trialDays,
    } as Coupon;
  } else {
    return {
      id: coupon.id,
      name: coupon.name,
      object: (coupon as CouponStripeBackend).object,
      amount_off: (coupon as CouponStripeBackend).amount_off,
      created: (coupon as CouponStripeBackend).created,
      currency: (coupon as CouponStripeBackend).currency,
      duration: (coupon as CouponStripeBackend).duration,
      duration_in_months: (coupon as CouponStripeBackend).duration_in_months,
      livemode: (coupon as CouponStripeBackend).livemode,
      max_redemptions: (coupon as CouponStripeBackend).max_redemptions,
      metadata: (coupon as CouponStripeBackend).metadata,
      percent_off: (coupon as CouponStripeBackend).percent_off,
      redeem_by: (coupon as CouponStripeBackend).redeem_by,
      times_redeemed: (coupon as CouponStripeBackend).times_redeemed,
      valid: (coupon as CouponStripeBackend).valid,
    } as CouponStripe;
  }
};
