import { responseSubscriptionPlanAdapter, SubscriptionPlanBackend } from "@/adapters";
import { SubscriptionPlan } from "@/models";

export const responseSubscriptionPlansAdapter = (
  plans: SubscriptionPlanBackend[],
): SubscriptionPlan[] => {
  const newPlans: SubscriptionPlan[] = [];
  if (plans.length) {
    plans.map((plan) => {
      const p = responseSubscriptionPlanAdapter(plan);
      if (p) newPlans.push(p);
    });
  }

  return newPlans;
};
