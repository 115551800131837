import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { Listing, ListingCluster, ParcelCluster } from "@/models";
import { userIsLoggedIn } from "@/redux/actions";
import {
  Infowindow,
  InfowindowListing,
  TypeInfowindow,
  selectAppConfig,
  selectFilters,
  selectListing,
  selectMap,
  selectParcel,
  setInfowindow,
  setListingCenter,
  setParcelCenter,
} from "@/redux/slices";
import { AnyProps, ClusterFeature, PointFeature } from "supercluster";
import { ClusterCustomProperties } from "./CreateClusters";

type HandleInfowindowProps = {
  infowindow: Infowindow;
  infoWindowRef: google.maps.OverlayView | null;
  clusters: PointFeature<AnyProps>[] | ClusterFeature<AnyProps>[];
  parcels?: ParcelCluster[];
  listings?: Listing[];
  map: google.maps.Map;
};

type ShowInfowindowProps = {
  lat: number;
  lng: number;
  map: google.maps.Map | null;
  maps: typeof google.maps | null;
  infoWindowRef: React.MutableRefObject<google.maps.OverlayView | null>;
  infoWindowPositionRef: React.MutableRefObject<google.maps.LatLng | null>;
  parcel?: {
    id: string;
    typeInfowindow: TypeInfowindow.parcel;
    listings: Pick<Listing, "genericListingId" | "isForRent" | "dataset" | "active">[];
  };
  listing?: {
    listings: Pick<Listing, "genericListingId" | "isForRent" | "dataset" | "active">[];
    parcelId?: string;
    typeInfowindow: TypeInfowindow.listing;
  };
};

const MobilePolygonOffset = {
  18: 0.0007,
  19: 0.0003,
  20: 0.00015,
  21: 0.00008,
} as const;

type MobilePolygonOffset = typeof MobilePolygonOffset[keyof typeof MobilePolygonOffset];

type GoToMapInfowindowProps = {
  mapRef: React.MutableRefObject<google.maps.Map | null>;
  mapsRef: React.MutableRefObject<typeof google.maps | null>;
  infoWindowRef: React.MutableRefObject<google.maps.OverlayView | null>;
  clusters: PointFeature<AnyProps>[];
  infoWindowPositionRef: React.MutableRefObject<google.maps.LatLng | null>;
  infowindow: Infowindow;
};

export const useInfowindow = () => {
  const {
    mapOptionsClusters: { zoom },
    maxZoomClusters,
  } = useAppSelector(selectMap);

  const dispatch = useAppDispatch();
  const { listingId, listingCenter } = useAppSelector(selectListing);
  const { parcelId, parcelCenter } = useAppSelector(selectParcel);
  const { areFiltersAvailable } = useAppSelector(selectFilters);
  const { windowWidth } = useAppSelector(selectAppConfig);

  // Verifica si el polygon(parcel) o marker(listing) ya estaba con un infowindow abierto
  const HandleInfowindow = ({
    infowindow,
    infoWindowRef,
    clusters,
    parcels,
    listings,
    map,
  }: HandleInfowindowProps) => {
    if (infowindow.show && infowindow.id) {
      if (infowindow.type === TypeInfowindow.parcel) {
        const findParcel = parcels?.find((parcel) => parcel.id === infowindow.id);

        if (findParcel) {
          const isParcelInCluster = clusters.find(
            ({ properties }) => properties?.parcel?.id === infowindow.id,
          );
          if (isParcelInCluster && zoom > maxZoomClusters) {
            // El polygon(parcel) ya es visible en el cluster
            if (areFiltersAvailable) {
              infoWindowRef?.setMap(map);
            } else {
              infoWindowRef?.setMap(null);
            }
          } else {
            // El polygon(parcel) existe pero sigue siendo un cluster y por eso se oculta el infowindow
            infoWindowRef?.setMap(null);
          }
        } else {
          // El polygon(parcel) ya no existe en el cluster y por eso se oculta el infowindow
          infoWindowRef?.setMap(null);
        }
      } else if (infowindow.type === TypeInfowindow.listing) {
        const findListingParcel = parcels?.find((parcelItem) =>
          parcelItem.listings.some((listing) => listing.genericListingId === infowindow.id),
        );

        const findListing = listings?.find((listing) => listing.genericListingId === infowindow.id);

        if (findListingParcel) {
          const isListingInCluster = clusters.find(({ properties }) => {
            if (properties?.parcel?.listings.length) {
              return properties.parcel.listings.some(
                (listing: ListingCluster) => listing.genericListingId === infowindow.id,
              );
            }
          });

          if (isListingInCluster && zoom > maxZoomClusters) {
            // El marker(listing) ya es visible en el cluster
            if (areFiltersAvailable) {
              infoWindowRef?.setMap(map);
            } else {
              infoWindowRef?.setMap(null);
            }
          } else {
            // El marker(listing) existe pero sigue siendo un cluster y por eso se oculta el infowindow
            infoWindowRef?.setMap(null);
          }
        } else if (findListing) {
          const isListingInCluster = clusters.find(({ properties }) => {
            if (properties?.listing?.listings.length) {
              return properties.listing.listings.some(
                (listing: ListingCluster) => listing.genericListingId === infowindow.id,
              );
            }
          });

          if (isListingInCluster && zoom > maxZoomClusters) {
            // El marker(listing) ya es visible en el cluster
            if (areFiltersAvailable) {
              infoWindowRef?.setMap(map);
            } else {
              infoWindowRef?.setMap(null);
            }
          } else {
            // El marker(listing) existe pero sigue siendo un cluster y por eso se oculta el infowindow
            infoWindowRef?.setMap(null);
          }
        } else {
          // El marker(listing) ya no existe en el cluster y por eso se oculta el infowindow
          infoWindowRef?.setMap(null);
        }
      }
    }
  };

  // Muestra el infowindow cuando se hace click en un marker o polygon
  const ShowInfowindow = ({
    lat,
    lng,
    map,
    maps,
    infoWindowRef,
    infoWindowPositionRef,
    parcel,
    listing,
  }: ShowInfowindowProps) => {
    const center = {
      lat:
        windowWidth < 861
          ? lat + (MobilePolygonOffset[map?.getZoom() as keyof typeof MobilePolygonOffset] ?? 0)
          : lat,
      lng,
    };

    if (map && maps && infoWindowRef.current) {
      // remove old infowindow
      infoWindowRef.current.onRemove();
      infoWindowRef.current.setMap(null);

      // set map center
      map.setCenter(center);

      // set new infowindow position
      infoWindowPositionRef.current = new maps.LatLng(center);
      infoWindowRef.current.set("position", center);

      // set infowindow map
      infoWindowRef.current.setMap(map);

      if (listing) {
        const listings: InfowindowListing[] = listing.listings.map((listing) => ({
          id: listing.genericListingId,
          active: listing.active,
        }));

        dispatch(
          setInfowindow({
            show: true,
            id: listing.listings[0].genericListingId,
            type: listing.typeInfowindow,
            countListings: listing.listings.length,
            listings,
            parcelId: listing.parcelId,
            activeListing: listing.listings[0].active,
          }),
        );
      } else if (parcel) {
        const listings: InfowindowListing[] = parcel.listings.map((listing) => ({
          id: listing.genericListingId,
          active: listing.active,
        }));

        dispatch(
          setInfowindow({
            show: true,
            id: parcel.id,
            type: parcel.typeInfowindow,
            countListings: parcel.listings.length,
            listings,
            parcelId: parcel.id,
            activeListing: parcel.listings[0]?.active,
          }),
        );
      }
    }
  };

  // Muestra el infowindow cuando desde el perfil del listing o parcel se clickea en "Go to map"
  const GoToMapInfowindow = ({
    mapRef,
    mapsRef,
    infoWindowRef,
    clusters,
    infoWindowPositionRef,
    infowindow,
  }: GoToMapInfowindowProps) => {
    const defaultZoomMapToShow = 18;

    if (mapRef.current && mapsRef.current && infoWindowRef.current && clusters.length) {
      if (parcelId && parcelCenter) {
        // remove old infowindow
        infoWindowRef.current.onRemove();
        infoWindowRef.current.setMap(null);

        const findParcel = clusters?.find(({ properties }) => {
          const { parcel } = properties as ClusterCustomProperties;
          return parcel?.id === parcelId;
        });

        // set map center
        if (
          mapRef.current.getCenter()?.lat() !== parcelCenter.lat &&
          mapRef.current.getCenter()?.lng() !== parcelCenter.lng
        ) {
          mapRef.current.setCenter(parcelCenter);
          mapRef.current.setZoom(defaultZoomMapToShow);
        }

        if (findParcel) {
          const p = findParcel.properties as ClusterCustomProperties;
          const listings: InfowindowListing[] = p.parcel?.listings
            ? p.parcel.listings.map((listing) => ({
                id: listing.genericListingId,
                active: listing.active,
              }))
            : [];

          // set new infowindow position
          infoWindowPositionRef.current = new mapsRef.current.LatLng(parcelCenter);
          infoWindowRef.current.set("position", parcelCenter);

          // set infowindow map
          infoWindowRef.current.setMap(mapRef.current);

          dispatch(
            setInfowindow({
              show: true,
              id: parcelId,
              type: TypeInfowindow.parcel,
              countListings: listings.length,
              listings: listings,
              parcelId: parcelId,
              activeListing: listings[0]?.active,
            }),
          );

          //Remove parcelCenter
          dispatch(setParcelCenter({ parcelCenter: undefined }));
        }
      }

      if (listingId && listingCenter) {
        // remove old infowindow
        infoWindowRef.current.onRemove();
        infoWindowRef.current.setMap(null);

        // set map center
        if (
          mapRef.current.getCenter()?.lat() !== listingCenter.lat &&
          mapRef.current.getCenter()?.lng() !== listingCenter.lng
        ) {
          mapRef.current.setCenter(listingCenter);
          mapRef.current.setZoom(defaultZoomMapToShow);
        }

        const findListing = clusters?.find(({ properties }) => {
          const { parcel } = properties as ClusterCustomProperties;
          return parcel?.listings.some((listing) => listing.genericListingId === listingId);
        });

        if (findListing) {
          const properties = findListing.properties as ClusterCustomProperties;
          const listings: InfowindowListing[] = properties.parcel?.listings
            ? properties.parcel.listings.map((listing) => ({
                id: listing.genericListingId,
                active: listing.active,
              }))
            : [];

          const activeListing = listings.find((listing) => listing.id === listingId);
          if (activeListing) {
            // set new infowindow position
            infoWindowPositionRef.current = new mapsRef.current.LatLng(listingCenter);
            infoWindowRef.current.set("position", listingCenter);

            // set infowindow map
            infoWindowRef.current.setMap(mapRef.current);

            dispatch(
              setInfowindow({
                show: true,
                id: listingId,
                type: TypeInfowindow.listing,
                countListings: listings.length,
                listings,
                parcelId: properties.parcel?.id,
                activeListing: activeListing.active,
              }),
            );

            dispatch(setListingCenter({ listingCenter: null }));
          }
        } else if (!infowindow.listingWithParcel) {
          // set new infowindow position
          infoWindowPositionRef.current = new mapsRef.current.LatLng(listingCenter);
          infoWindowRef.current.set("position", listingCenter);

          // set infowindow map
          infoWindowRef.current.setMap(mapRef.current);

          dispatch(
            setInfowindow({
              show: true,
              id: listingId,
              type: TypeInfowindow.listing,
              listingWithParcel: false,
              activeListing: infowindow.activeListing,
            }),
          );
          dispatch(setListingCenter({ listingCenter: null }));
        }
      }
    }
  };

  return { HandleInfowindow, ShowInfowindow, GoToMapInfowindow };
};
