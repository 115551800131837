// Función para convertir coordenadas a objetos LatLng de Google Maps
export const convertCoordinates = ({
  coordinates,
  maps,
}: {
  coordinates: number[][][];
  maps: typeof google.maps;
}): google.maps.LatLng[][] => {
  return coordinates.map((ring) => ring.map(([lng, lat]) => new maps.LatLng(lat, lng)));
};

// Función para convertir geoJson a objetos LatLng de Google Maps
export const convertGeoJson = ({
  geojson,
}: // maps,
{
  geojson: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  // maps: typeof google.maps;
}): google.maps.LatLng[][] | null => {
  if (geojson.type === "Polygon") {
    return geojson.coordinates.map((ring) =>
      ring.map(([lng, lat]) => new google.maps.LatLng(lat, lng)),
    );
  } else if (geojson.type === "MultiPolygon") {
    // revisar estooo
    const test: google.maps.LatLng[][] = [];
    geojson.coordinates.map((polygon) =>
      polygon.map((ring) => test.push(ring.map(([lng, lat]) => new google.maps.LatLng(lat, lng)))),
    );
    return test;
  } else {
    console.error("El tipo de geometría no es compatible.");
    return null;
  }
};

// Funcion para convertir objetos LatLng de Google Maps a coordenadas
export const convertToCoordinates = (
  coordinates: { lat: () => number; lng: () => number }[],
): [number, number][][] => {
  const newCoordinates = [...coordinates, coordinates[0]];
  return [
    //string[][][]
    newCoordinates.map((coordinate) => [coordinate.lng(), coordinate.lat()]),
  ];
  /* return [
    //string[][][]
    coordinates.map((coordinate: { lat: () => void; lng: () => void }) => [
      String(coordinate.lat()),
      String(coordinate.lng()),
    ]),
  ] */
};

// Función para crear un polígono y agregarlo al mapa
export const createPolygon = ({
  coordinates,
  maps,
  map,
  strokeColor,
  fillColor,
  strokeOpacity,
  strokeWeight,
  fillOpacity,
  clickable,
}: {
  coordinates: number[][][];
  maps: typeof google.maps;
  map: google.maps.Map | null;
  strokeColor: string;
  fillColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillOpacity: number;
  clickable: boolean;
}): google.maps.Polygon => {
  const polygon = new maps.Polygon({
    paths: convertCoordinates({ coordinates, maps }),
    strokeColor, // Define el color del borde del polígono
    strokeOpacity, // Define la opacidad del borde del polígono
    strokeWeight, // Define el grosor del borde del polígono
    fillColor, // Define el color de relleno del polígono
    fillOpacity, // Define la opacidad del relleno del polígono
    map, // Asocia el polígono a un mapa específico
    clickable, // Define si el polígono es clickeable
  });

  return polygon;
};

// Extiende los limites del mapa en base a coordenadas de polígonos
export const extendBounds = ({
  coordinates,
  maps,
  bounds,
}: {
  coordinates: number[][][];
  maps: typeof google.maps;
  bounds: google.maps.LatLngBounds;
}) => {
  convertCoordinates({ coordinates, maps }).forEach((ring) => {
    ring.forEach((latLng) => {
      bounds.extend(latLng);
    });
  });
};
