export enum ValuationFeatureNumericBackendKey {
  pricePerLotSqm = "pricePerLotSqm",
  lotArea = "lotArea",
  long = "long",
  lat = "lat",
  builtArea = "builtArea",
  daysOnMarket = "daysOnMarket",
  parkingSpaces = "parkingSpaces",
  halfToilets = "halfToilets",
  toilets = "toilets",
  rooms = "rooms",
  yearBuilt = "yearBuilt",
  luxuryScore = "luxuryScore",
}
