import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ListingBackendSortKey,
  ParcelBackendSortKey,
  PermitBackendSortKey,
  PropertyBackendSortKey,
  SortDirectionBackendKey,
  TabKey,
  TaxBackendSortKey,
  TenantBackendSortKey,
  TransactionBackendSortKey,
} from "@/keys";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface GridViewState {
  viewActive?: "grid" | "list";
  activeTab: TabKey;
  loading: boolean;
  tabState: Array<{
    id: TabKey;
    sortByOptions: Array<
      | ListingBackendSortKey
      | ParcelBackendSortKey
      | PropertyBackendSortKey
      | TransactionBackendSortKey
      | PermitBackendSortKey
      | TenantBackendSortKey
      | TaxBackendSortKey
      | ListingBackendSortKey[]
      | ParcelBackendSortKey[]
      | PropertyBackendSortKey[]
      | TransactionBackendSortKey[]
      | PermitBackendSortKey[]
      | TenantBackendSortKey[]
      | TaxBackendSortKey[]
    >;
    pagination: {
      page: number;
      itemsPerPage: number;
      sortBy?: string | string[];
      sortOrder?: SortDirectionBackendKey;
    };
    count: {
      data: number | null;
      isLoading: boolean;
    };
    filters: {
      includeOutliers: boolean;
    };
  }>;
}

const partialInitialState = {
  pagination: {
    page: 1,
    itemsPerPage: 20,
    sortOrder: SortDirectionBackendKey.DESC,
  },
  filters: { includeOutliers: false },
  count: { data: null, isLoading: false },
};

// Define the initial state using that type
const initialState: GridViewState = {
  viewActive: "grid",
  activeTab: TabKey.listings,
  loading: false,
  tabState: [
    {
      ...partialInitialState,
      id: TabKey.listings,
      sortByOptions: [
        ListingBackendSortKey.builtArea,
        ListingBackendSortKey.createdAt,
        ListingBackendSortKey.lotArea,
        ListingBackendSortKey.price,
        [ListingBackendSortKey.createdAt, ListingBackendSortKey.score],
      ],
      pagination: {
        ...partialInitialState.pagination,
        sortBy: [ListingBackendSortKey.createdAt, ListingBackendSortKey.score], // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.featuredListings,
      sortByOptions: [
        ListingBackendSortKey.builtArea,
        ListingBackendSortKey.createdAt,
        ListingBackendSortKey.lotArea,
        ListingBackendSortKey.price,
        [ListingBackendSortKey.createdAt, ListingBackendSortKey.score],
      ],
      pagination: {
        ...partialInitialState.pagination,
        sortBy: [ListingBackendSortKey.createdAt, ListingBackendSortKey.score], // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.properties,
      sortByOptions: Object.values(ParcelBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: ParcelBackendSortKey.score, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.units,
      sortByOptions: Object.values(PropertyBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: PropertyBackendSortKey.totalArea, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.transactions,
      sortByOptions: Object.values(TransactionBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: TransactionBackendSortKey.transactionDate, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.tenants,
      sortByOptions: Object.values(TenantBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        // sortBy: TenantBackendSortKey.name, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.taxes,
      sortByOptions: Object.values(TaxBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: TaxBackendSortKey.totalValuation, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.permits,
      sortByOptions: Object.values(PermitBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: PermitBackendSortKey.date, // default sort
      },
    },
    {
      ...partialInitialState,
      id: TabKey.documents,
      sortByOptions: Object.values(TransactionBackendSortKey),
      pagination: {
        ...partialInitialState.pagination,
        sortBy: TransactionBackendSortKey.transactionDate, // default sort
      },
    },
  ],
};

export const gridViewSlice = createSlice({
  name: "gridView",
  initialState,
  reducers: {
    setViewActive: (state, action: PayloadAction<"grid" | "list" | undefined>) => {
      state.viewActive = action.payload;
    },
    updateActiveTabGrid: (state, action: PayloadAction<{ tab: TabKey }>) => {
      state.activeTab = action.payload.tab;
    },
    setLoadingGrid: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSortByGrid: (
      state,
      {
        payload: { tabkey, sortBy },
      }: PayloadAction<{
        tabkey: TabKey;
        sortBy: string | string[];
      }>,
    ) => {
      const tab = state.tabState.find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.sortBy = sortBy;
      }
    },
    setSortOrderByGrid: (
      state,
      {
        payload: { tabkey, sortOrder },
      }: PayloadAction<{
        tabkey: TabKey;
        sortOrder: SortDirectionBackendKey;
      }>,
    ) => {
      const tab = state.tabState.find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.sortOrder = sortOrder;
      }
    },
    setPageGrid: (
      state,
      {
        payload: { tabkey, page },
      }: PayloadAction<{
        tabkey: TabKey;
        page: number;
      }>,
    ) => {
      const tab = state.tabState.find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.page = page;
      }
    },
    setItemsPerPageGrid: (
      state,
      {
        payload: { tabkey, itemsPerPage },
      }: PayloadAction<{
        tabkey: TabKey;
        itemsPerPage: number;
      }>,
    ) => {
      const tab = state.tabState.find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.itemsPerPage = itemsPerPage;
      }
    },

    setCountLoadingGrid: (state, action: PayloadAction<{ tab: TabKey; isLoading: boolean }>) => {
      const tab = state.tabState.find((t) => t.id === action.payload.tab);
      if (tab) {
        tab.count.isLoading = action.payload.isLoading;
      }
    },

    setCountGrid: (state, action: PayloadAction<{ tab: TabKey; count: number | null }>) => {
      const tab = state.tabState.find((t) => t.id === action.payload.tab);
      if (tab) {
        tab.count.data = action.payload.count;
      }
    },
    setIncludeOutliers: (
      state,
      {
        payload: { tabkey, value },
      }: PayloadAction<{
        tabkey: TabKey;
        value: boolean;
      }>,
    ) => {
      const tab = state.tabState.find((t) => t.id === tabkey);
      if (tab) {
        tab.filters.includeOutliers = value;
      }
    },
  },
});

// These actions will dispatch in the app
export const {
  setViewActive,
  updateActiveTabGrid,
  setLoadingGrid,
  setSortByGrid,
  setSortOrderByGrid,
  setPageGrid,
  setItemsPerPageGrid,
  setCountLoadingGrid,
  setCountGrid,
  setIncludeOutliers,
} = gridViewSlice.actions;

export const selectGridView = (state: RootState) => state.gridView;

export default gridViewSlice.reducer;
