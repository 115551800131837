import styled from "styled-components";
import { rgba } from "polished";
import { fadeIn } from "@/components/styledComponents";

export const BlurOverlay = styled.div.attrs({ className: "device-fullscreen" })((props) => {
  return {
    backgroundColor: rgba(props.theme.common.black, 0.4),
    backdropFilter: "blur(4px)",
    position: "fixed",
    zIndex: 200,
  };
});

export const AnimatedBlurOverlay = styled(BlurOverlay)`
  animation-duration: 0.7s;
  animation-fill-mode: both;
  -webkit-animation-name: ${fadeIn};
  animation-name: ${fadeIn};
  height: 100vh;
  height: 100dvh;
`;

export const NavbarOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  -webkit-animation-name: ${fadeIn};
  animation-name: ${fadeIn};
`;
