import type { CategoricFilters, ListingAmenity, Search } from "@/models";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ExpandOptionsKey,
  NewsFiltersEntityBackendKey,
  NewsFiltersEntityFrontKey,
  PortalKey,
  TabKey,
  useEndpoint,
} from "@/keys";
import {
  FiltersBackend,
  FiltersTypeBackend,
  InputsTableFiltersBackend,
  responseSearchAdapter,
  SearchBackend,
  SearchIdsBackend,
} from "@/adapters";
import { api } from "@/services";
import { AppThunk, store } from "@/app/store";
import {
  Filters,
  OnlyKeyPortal,
  setAllValuesFilter,
  setAppliedFilters,
  setLoadingSearchIds,
  setSearchIds,
  setUserInputSearch,
} from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { URL_BACKEND } from "@/config";
import { responseSearchIdsAdapter } from "@/adapters/responses/filters/search-ids.adapter";
import { responseGptSearchAdapter } from "@/adapters/responses/filters/gpt-search.adapter";

export type GptSearchModel = {
  [NewsFiltersEntityFrontKey.location]: Partial<Filters>;
  [NewsFiltersEntityFrontKey.parcel]: Partial<Filters>;
  [NewsFiltersEntityFrontKey.transaction]: Partial<Filters>;
  [NewsFiltersEntityFrontKey.permit]: Partial<Filters>;
  [NewsFiltersEntityFrontKey.tenant]: Partial<Filters>;
  [NewsFiltersEntityFrontKey.tax]: Partial<Filters>;
};

export enum GptSearchBackendEntityKey {
  location = "location",
  parcel = "parcel",
  listing = "listing",
  listingAmenity = "listingAmenity",
  transaction = "transaction",
  entity = "entity",
}

export enum GptSearchBackendLocationFilterKey {
  city = "city",
  neighborhood = "neighborhood",
  subNeighborhood = "subNeighborhood",
  zipCode = "zipCode",
  urbanization = "urbanization",
}

export type GptSearchBackend = {
  [GptSearchBackendEntityKey.location]: {
    [GptSearchBackendLocationFilterKey.city]?: { id: string; name: string };
    [GptSearchBackendLocationFilterKey.neighborhood]?: { id: string; name: string };
    [GptSearchBackendLocationFilterKey.subNeighborhood]?: { id: string; name: string };
    [GptSearchBackendLocationFilterKey.zipCode]?: { id: string; zipCode: string };
    [GptSearchBackendLocationFilterKey.urbanization]?: { id: string; name: string };
  };
  [GptSearchBackendEntityKey.entity]:
    | NewsFiltersEntityBackendKey.listing
    | NewsFiltersEntityBackendKey.parcel
    | NewsFiltersEntityBackendKey.transaction
    | null;
  [GptSearchBackendEntityKey.parcel]: InputsTableFiltersBackend;
  [GptSearchBackendEntityKey.listing]: InputsTableFiltersBackend;
  [GptSearchBackendEntityKey.listingAmenity]: InputsTableFiltersBackend;
  // TODO
  // {
  //   categoric?: {
  //     amenityId: {
  //       matchAll: string[];
  //       matchKey: string;
  //     };
  //   };
  // };
  [GptSearchBackendEntityKey.transaction]: InputsTableFiltersBackend;
};

export type GptSearchBackendInclude = {
  parcel?: FiltersTypeBackend;
  listing?: FiltersTypeBackend;
  listingAmenity?: FiltersTypeBackend;
  transaction?: FiltersTypeBackend;
  property?: FiltersTypeBackend;
  tax?: FiltersTypeBackend;
  tenant?: FiltersTypeBackend;
  permit?: FiltersTypeBackend;
};

type GptSearchBackendBody = {
  include: GptSearchBackendInclude;
};

const gptSearchBasicInclude: GptSearchBackendInclude = {
  listing: {
    categoric: ["propertyType", "cityId", "neighborhoodId", "zipCodeId", "status"],
    numeric: ["lotArea", "builtArea", "price", "rooms", "toilets"],
  },
  parcel: {
    categoric: ["sectorId", "cityId", "neighborhoodId", "zipCodeId"],
    numeric: ["landArea"],
  },
  listingAmenity: {
    categoric: ["amenityId", "genericListingId"],
  },
};

const gptSearchAdvanceInclude: GptSearchBackendInclude = {
  listing: {
    boolean: ["active"],
    categoric: ["propertyType", "cityId", "neighborhoodId", "zipCodeId", "status"],
    numeric: [
      "lotArea",
      "builtArea",
      "price",
      "daysOnMarket",
      "parkingSpaces",
      "pricePerBuiltSqm",
      "pricePerLotSqm",
      "rooms",
      "toilets",
      "yearBuilt",
    ],
  },
  parcel: {
    boolean: ["isFloodZone"],
    categoric: ["sectorId", "cityId", "neighborhoodId", "zipCodeId", "buildingId"],
    numeric: ["landArea"],
  },
  listingAmenity: {
    categoric: ["amenityId", "genericListingId"],
  },
  transaction: {
    date: ["transactionDate"],
  },
};

const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearch: builder.query<
      Search,
      {
        userInputText: string;
        countryCode: CountryCodeKey;
        portal: OnlyKeyPortal;
        userFree: boolean;
      }
    >({
      query({ userInputText, countryCode, portal, userFree }) {
        const body: {
          text: string;
          expand: Extract<
            ExpandOptionsKey,
            | ExpandOptionsKey.cities
            | ExpandOptionsKey.listings
            | ExpandOptionsKey.parcels
            | ExpandOptionsKey.transactions
            | ExpandOptionsKey.properties
            | ExpandOptionsKey.tenants
            | ExpandOptionsKey.permits
            | ExpandOptionsKey.documents
            | ExpandOptionsKey.news
          >[];
          activeListings?: boolean;
        } = {
          text: userInputText,
          expand: [ExpandOptionsKey.cities, ExpandOptionsKey.listings],
        };

        if (portal !== PortalKey.investpr) {
          body.expand.push(ExpandOptionsKey.news);
          body.expand.push(ExpandOptionsKey.parcels);

          if (!userFree) {
            body.expand.push(ExpandOptionsKey.properties);
            body.expand.push(ExpandOptionsKey.transactions);
            body.expand.push(ExpandOptionsKey.tenants);
            body.expand.push(ExpandOptionsKey.permits);
            body.expand.push(ExpandOptionsKey.documents);
          } else {
            body.activeListings = true;
          }
        } else {
          body.activeListings = true;
        }

        return {
          url: useEndpoint().search({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: SearchBackend }): Search =>
        responseSearchAdapter(response.data),
    }),
    getGptSearch: builder.query<
      { filtersRedux: Filters | null; tabKey: TabKey | null; filtersAreAvailable: boolean },
      {
        search: string;
        countryCode: CountryCodeKey;
        categoricFilters: CategoricFilters;
        advancedFilters: boolean;
      }
    >({
      query({ search, countryCode, advancedFilters }) {
        return {
          url: useEndpoint().textSearchNaturalLanguage({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          params: {
            search,
          },
          body: {
            include: advancedFilters ? gptSearchAdvanceInclude : gptSearchBasicInclude,
          } as GptSearchBackendBody,
        };
      },
      transformResponse: async (
        response: { data: GptSearchBackend },
        meta,
        arg,
      ): Promise<{
        filtersRedux: Filters | null;
        tabKey: TabKey | null;
        filtersAreAvailable: boolean;
      }> => {
        return responseGptSearchAdapter(response.data, arg).then((data) => data);
      },
    }),
  }),
  overrideExisting: false,
});

export const getSearchIds = ({
  query,
  countryCode,
  callback,
}: {
  query: string;
  countryCode: CountryCodeKey;
  callback?: () => void;
}): AppThunk => {
  return async (dispatch, getState) => {
    const indexDictionary = {
      [NewsFiltersEntityFrontKey.listing]: "listings",
      [NewsFiltersEntityFrontKey.parcel]: "parcels",
      [NewsFiltersEntityFrontKey.property]: "properties",
      [NewsFiltersEntityFrontKey.transaction]: "transactions",
      [NewsFiltersEntityFrontKey.tenant]: "tenants",
      [NewsFiltersEntityFrontKey.permit]: "permits",
      [TabKey.documents]: "documents",
    };
    const index = Object.values(indexDictionary);
    try {
      dispatch(setLoadingSearchIds(true));
      dispatch(setUserInputSearch({ value: query, showPill: true }));
      const response: AxiosResponse<{
        data: SearchIdsBackend;
      }> = await axios.post(
        `${URL_BACKEND}${useEndpoint().elasticSearch({
          countryCode: CountryCodeBackendKey[countryCode],
        })}`,
        {
          query,
          index,
          size: 10000,
        },
      );
      dispatch(
        setSearchIds({
          searchIds: responseSearchIdsAdapter(response.data.data),
        }),
      );
      if (callback) callback();
    } catch (error) {
      console.error(error);
      dispatch(setUserInputSearch({ value: "", showPill: false }));
    } finally {
      dispatch(setLoadingSearchIds(false));
    }
  };
};

// -------------------------------- Queries (React hooks)--------------------------------
export const { useGetSearchQuery, useLazyGetGptSearchQuery } = searchApi;
