import { AnalyticsRangeBackend } from "@/adapters";
import { store } from "@/app/store";
import { ColumnStackedChartOptions } from "@/models";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { LongNumberIntoAbbreviatedString, convertUnits } from "@/utilities";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { SectorKey } from "@/keys";

export const responseAnalyticsParcelAdapter = (
  parcels: AnalyticsRangeBackend[],
): ColumnStackedChartOptions => {
  const data: ColumnStackedChartOptions = {
    series: [],
    categories: [],
    colors: [],
    total: 0,
  };

  const { themeName, organizationTheme, portal, landAreaUnit } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);
  const { filtersByPortal } = store.getState().newFilters;

  parcels.map(({ values, range }, index) => {
    data.categories.push(
      `${
        range.min
          ? `${LongNumberIntoAbbreviatedString({
              value: convertUnits({ value: range.min, to: landAreaUnit }) as number,
              abbreviation: range.max ? false : true,
            })}${range.max ? "-" : "+"}`
          : ""
      }${
        range.max
          ? `${LongNumberIntoAbbreviatedString({
              value: convertUnits({ value: range.max, to: landAreaUnit }) as number,
              abbreviation: true,
            })}`
          : ""
      }`,
    );
    values.map(({ key, value }) => {
      const findSector = filtersByPortal[portal].categoricFilters.sectors.find(
        (sec) => sec.value === key,
      );
      const isName = data.series?.find((serie) => serie.name === findSector?.name);

      if (findSector) {
        if (!isName) {
          data.series.push({
            name: findSector.name ?? SectorKey.empty,
            data: Array(parcels.length).fill(0),
          });
          data.colors.push(
            sectorColorsMapper({
              sectorName: findSector ? findSector.name ?? null : null,
              sectorColor: activeTheme?.parcelColors
                .sectors as ThemeType["parcelColors"]["sectors"],
              fillColor: true,
            }),
          );
        }
        data.series.find((serie) => {
          if (serie.name === findSector.label) {
            serie.data[index] = Number(value);
          }
        });
      }

      // const propertyTypeName = curitaCategoricPropertyTypes({ label: sectorId });
      // const isName = data.series?.find((serie) => serie.name === propertyTypeName);

      // if (!isName) {
      //   data.series.push({
      //     name: propertyTypeName,
      //     data: Array(pricesDistribution.length).fill(0),
      //   });
      //   data.colors.push(
      //     listingPropertyTypeColorsMapper({
      //       propertyTypeName: propertyTypeName,
      //       propertyTypeColor: activeTheme?.listingColors
      //         .propertyType as ThemeType["listingColors"]["propertyType"],
      //     }),
      //   );
      // }
      // data.series.find((serie) => {
      //   if (serie.name === propertyTypeName) {
      //     serie.data[index] = Number(count);
      //   }
      // });
    });
  });

  data.series.map((serie) => {
    const sumatoria = serie.data.reduce((acc, curr) => acc + curr, 0); // Calcula la sumatoria de todos los valores en el array
    if (data.total !== undefined) {
      data.total += sumatoria;
    }

    // const porcentajes = serie.data.map((valor) => Number(((valor / sumatoria) * 100).toFixed(2))); // Calcula los porcentajes
    // serie.data = porcentajes;
  });

  return data;
};
