import { SubNeighborhoodBackend } from "@/adapters";
import { useLocaleCountryCode } from "@/hooks";
import { CountryCodeKey } from "@/keys";
import { SubNeighborhood } from "@/models";
import { convertGeoJson } from "@/utilities";

export const responseSubNeighborhoodAdapter = (
  subNeighborhood: SubNeighborhoodBackend,
): SubNeighborhood => {
  const { countryCode } = useLocaleCountryCode();
  const data: SubNeighborhood = {
    value: subNeighborhood.id,
    label: subNeighborhood.name,
    geometry: subNeighborhood.geometry
      ? convertGeoJson({
          geojson: subNeighborhood.geometry,
        })
      : null,
  };
  if (countryCode === CountryCodeKey.PR) {
    data.isNeighborhood = false;
  }
  return data;
};
