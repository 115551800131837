import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "1d310fe5-56ad-43f7-a36b-95b01ed5a3c1",
    land: 346950,
    taxable: 370448140,
    exemption: 0,
    machinery: 369030833,
    structure: 1070357,
    exoneration: 0,
    totalValuation: 370448140,
    property: {
      address: "BO JOBOS INTERIOR CARR 3 KM.HM 143.0 GUAYAMA",
      addressJSON: null,
      transactions: [],
      govtId: "",
      city: null,
      neighborhood: null,
      parcelId: "c299f551-b1b1-9b33-a20b-7e5bd790678b",
      parcel: {
        id: "c299f551-b1b1-9b33-a20b-7e5bd790678b",
        name: "Black Start Generator",
        address: "1430 Carretera 3",
        photos: [
          "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16566233751541db50ef32cb1fc1661eb.png",
        ],
        sector: {
          name: "Energy Generation",
        },
        subsector: {
          name: "Conventional Fuels",
        },
      },
      taxes: null,
      listings: null,
      unitNumber: null,
      lastTransactionPrice: 0,
      lastTransactionTotalAreaPricePerSqm: 0,
    },
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    property: {
      ...value.property,
      parcel: {
        ...value.property.parcel,
        photos: [getRandomImage()],
      },
    },
  }));
