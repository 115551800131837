export enum TypeOfDrawing {
  polygon = "polygon",
  circle = "circle",
}

// ID que se utiliza para identificar los controles del mapa customizados
export enum CustomControlsMapIds {
  LIGHT = "light-control",
  DARK = "dark-control",
  SATELLITE = "satellite-control",
}

// ID que se utiliza para identificar el boton de drawnPolygon
export enum MapDrawnPolygonButtonId {
  drawPolygonButton = "draw-polygon-button",
}
