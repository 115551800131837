import { EmbeddedValuation } from "@/models/index";
import { loadAbort } from "@/utilities/index";
import axios from "axios";
import { URL_BACKEND } from "@/config";
import { useEndpoint } from "@/keys";

export const loadEmbeddedValuation = () => {
  const controller = loadAbort();
  return {
    call: axios.post<EmbeddedValuation>(
      `${URL_BACKEND}${useEndpoint().listingsEmbeddedValuationUrl()}`,
      { signal: controller.signal },
    ),
    controller,
  };
};
