import i18n, { defaultLanguage } from "@/i18n";
import { CountryCodeKey, LanguageCodeKey } from "@/keys";
// import { useTranslation } from "react-i18next";

export const [defaultLanguageCode, defaultCountryCode] = defaultLanguage.split("-");

export const useLocaleCountryCode = () => {
  // Returns country code from current language
  // const { i18n } = useTranslation();

  const [languageCode, countryCode] = i18n.language.split("-");
  const lookupQuerystring = i18n.options.detection?.lookupQuerystring as string;

  return {
    countryCode: (countryCode as CountryCodeKey) || (defaultCountryCode as CountryCodeKey),
    languageCode: (languageCode as LanguageCodeKey) || (defaultLanguageCode as LanguageCodeKey),
    lookupQuerystring,
  };
};
