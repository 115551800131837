import type { Listing } from "@/models";
import { ListingBackend, responseListingAdapter } from "@/adapters";

export const responseListingsAdapter = (listings: ListingBackend[]): Listing[] => {
  const newListings: Listing[] = [];

  if (listings.length) {
    listings.map((listing) => {
      newListings.push(responseListingAdapter(listing));
    });
  }

  return newListings;
};
