import { responseTransactionPropertiesAdapter, type SearchBackend } from "@/adapters";
import { NewsCategoriesBackendKey, NewsCategoriesKey } from "@/keys";
import {
  CitySearch,
  DocumentSearch,
  ListingSearch,
  NewSearch,
  Parcel,
  ParcelSearch,
  PermitSearch,
  PropertySearch,
  Search,
  TenantSearch,
  TransactionSearch,
} from "@/models";

export const responseSearchAdapter = (searchs: SearchBackend): Search => {
  const results: Search = {};

  if (searchs?.cities?.length) {
    const cities: CitySearch[] = [];
    searchs.cities.slice(0, 3).map((city) =>
      cities.push({
        value: city.id,
        label: city.name,
      }),
    );
    results.cities = cities;
  }

  if (searchs?.listings?.length) {
    const listings: ListingSearch[] = [];
    searchs.listings.slice(0, 3).map((listing) =>
      listings.push({
        id: listing.genericListingId,
        title: listing.title,
        address: listing.address ?? "---",
        price: listing.price,
        photos: listing?.photos?.length ? listing.photos : [],
        isForRent: listing.isForRent,
        active: listing.active,
        status: listing.status,
        propertyType: listing.propertyType,
      }),
    );
    results.listings = listings;
  }

  if (searchs?.parcels?.length) {
    const parcels: ParcelSearch[] = [];
    searchs.parcels.slice(0, 3).map((parcel) =>
      parcels.push({
        id: parcel.id,
        name: parcel.name ? parcel.name : null,
        address: parcel.address ?? "---",
        photos: parcel?.photos?.length ? parcel.photos : [],
        govtId: parcel.govtId,
        sector: parcel.sector,
      }),
    );
    results.parcels = parcels;
  }

  if (searchs?.properties?.length) {
    const properties: PropertySearch[] = [];
    searchs.properties.slice(0, 3).map((property) =>
      properties.push({
        id: property.id,
        govtId: property.govtId ?? null,
        address: property.address ?? "---",
        parcelId: property.parcelId ?? null,
        parcel: (property.parcel as unknown as Parcel) ?? null,
      }),
    );
    results.properties = properties;
  }

  if (searchs?.transactions?.length) {
    const transactions: TransactionSearch[] = [];
    searchs.transactions.slice(0, 3).map((transaction) =>
      transactions.push({
        id: transaction.id,
        address: transaction.address ?? "---",
        sellerName: transaction.sellerName ?? null,
        buyerName: transaction.buyerName ?? null,
        transactionType: transaction.transactionType ?? null,
        transactionPrice: transaction.transactionPrice ?? null,
        transactionProperties: transaction.transactionProperties
          ? responseTransactionPropertiesAdapter(transaction.transactionProperties)
          : null,
        pricePerBuiltSqm: transaction.pricePerBuiltSqm ?? 0,
      }),
    );
    results.transactions = transactions;
  }

  if (searchs?.tenants?.length) {
    const tenants: TenantSearch[] = [];
    searchs.tenants.slice(0, 3).map((tenant) =>
      tenants.push({
        id: tenant.id,
        name: tenant.name,
        address: tenant.address ?? "---",
        parcel: (tenant.parcel as unknown as Parcel) ?? null,
        sector: tenant.sector,
        subsector: tenant.subsector,
        microsector: tenant.microsector,
      }),
    );
    results.tenants = tenants;
  }

  if (searchs?.permits?.length) {
    const permits: PermitSearch[] = [];
    searchs.permits.slice(0, 3).map((permit) =>
      permits.push({
        id: permit.id,
        name: permit.name ?? null,
        address: permit.address ?? "---",
        parcel: (permit.parcel as unknown as Parcel) ?? null,
        status: permit.status,
      }),
    );
    results.permits = permits;
  }

  // Document is the same Transaction entity
  if (searchs?.documents?.length) {
    const documents: DocumentSearch[] = [];
    searchs.documents.slice(0, 3).map((transaction) =>
      documents.push({
        id: transaction.id,
        address: transaction.address ?? "---",
        transactionType: transaction.transactionType ?? null,
        transactionProperties: transaction.transactionProperties
          ? responseTransactionPropertiesAdapter(transaction.transactionProperties)
          : null,
      }),
    );
    results.documents = documents;
  }

  // News
  if (searchs?.news?.length) {
    const news: NewSearch[] = [];
    searchs.news.slice(0, 3).map((n) => {
      news.push({
        id: n.id,
        category: n.category.map(
          (c) =>
            (Object.keys(NewsCategoriesBackendKey).find(
              (key) => c === NewsCategoriesBackendKey[key as keyof typeof NewsCategoriesBackendKey],
            ) as keyof typeof NewsCategoriesKey) ?? NewsCategoriesKey.all,
        ),
        title: n.title,
        esTitle: n.esTitle,
        description: n.description,
        esDescription: n.esDescription,
        link: n.link,
        date: n.date,
        city: n.city,
        gptJSON: n.gptJSON?.ogImage
          ? {
              ogImage: n.gptJSON.ogImage,
            }
          : undefined,
      });
    });
    results.news = news;
  }

  return results;
};
