import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "fff65fe3-e7c5-4aae-b40a-5fe6cfd6bc0f",
    category: "Rural",
    status: "Incompleto",
    date: "2024-02-19T04:00:00.000Z",
    name: "CAFETIN COIRA",
    address: "BO POZAS MANICABOA Ciales, Puerto Rico 00638",
    url: "https://www.sbp.pr.gov/Applications/Externo/Resumen/?IdTramite=10c7298d-a615-44d4-b40e-07a253826201",
    parcel: {
      address: "615 Carretera 615",
      addresses: [],
      id: "b013eced-67c1-2fff-3971-f9e9b75484ef",
      name: null,
      photos: [
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1709385740026f4b880e17bdf7efcb4cb.png",
      ],
      sectorId: "540519e7-6057-7c7f-e36d-2fc55d512874",
      subsectorId: null,
      city: null,
      neighborhood: null,
      subNeighborhood: null,
      listings: [],
      permits: [],
      tenants: [],
      properties: [],
      zipCode: null,
    },
    source: {
      name: "Single Business Portal DDEC",
      logoUrl: null,
    },
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    parcel: {
      ...value.parcel,
      photos: [getRandomImage()],
    },
  }));
