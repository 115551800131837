import type { Listing } from "@/models";
import {
  CityBackendKey,
  ParcelBackendKey,
  KeyofSortDirectionBackendKey,
  ListingBackendExpandKey,
  ListingBackendKey,
  SortDirectionBackendKey,
  SourceBackendKey,
  TabKey,
  useEndpoint,
  DatasetListingsBackendKey,
  OrganizationKey,
  AmenityBackendKey,
  ListingBackendInclude,
  CountryCodeKey,
  CountryCodeBackendKey,
} from "@/keys";
import {
  FlexibleRequestFiltersBackend,
  ListingBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  RequestFiltersBackend,
  responseListingAdapter,
  responseListingsAdapter,
  responseSimilarListingsAdapter,
  SimilarListingBackend,
} from "@/adapters";
import { api } from "@/services";
import { AppThunk } from "@/app/store";
import { setColumns, setCount, setLoading, setRealCount } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { URL_BACKEND } from "@/config";
import { defaultFilters, getOrganization } from "@/configs";
import { useLocaleCountryCode } from "@/hooks";
import { userIsLoggedIn } from "@/redux/actions";
import { createFakeListing } from "@/views/pages/tableView/tables/fakeData/fakeListingsData";

const listingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getListing: builder.query<
      Listing,
      {
        id: string;
        countryCode: CountryCodeKey;
        bodyProps?: RequestFiltersBackend<ListingBackendInclude, { isCount: false }>;
        userHasAccess: boolean;
        activeListing?: boolean;
      }
    >({
      queryFn: async (
        { id, countryCode, bodyProps, userHasAccess, activeListing },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        if (activeListing === false) {
          if (!userHasAccess) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return { data: createFakeListing({ id }) };
          }
        }

        const result = await baseQuery({
          url: useEndpoint().listing({ id, countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        });

        if (result.error) {
          return { error: result.error };
        }
        const response = result.data as { data: ListingBackend }; // Aseguramos el tipo de result.data

        return { data: responseListingAdapter(response.data) };
      },
    }),
    getListings: builder.query<
      Listing[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query({ countryCode, bodyProps, controller }) {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().listings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: ListingBackend[] }): Listing[] =>
        responseListingsAdapter(response.data),
    }),
    getListingsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query({ countryCode, bodyProps, controller }) {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().listings({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    findSimilarListings: builder.query<string[], { id: string; countryCode: CountryCodeKey }>({
      query({ id, countryCode }) {
        return {
          url: useEndpoint().similarListings({
            id,
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
        };
      },
      transformResponse: (response: {
        data: { listings: SimilarListingBackend[] } | null;
      }): string[] => responseSimilarListingsAdapter(response.data?.listings ?? null),
    }),
    getSimilarListings: builder.query<
      Listing[],
      { listingsIds: string[]; countryCode: CountryCodeKey }
    >({
      query: ({ listingsIds, countryCode }) => {
        const body: RequestFiltersBackend<ListingBackendInclude, { isCount: false }> = {
          filters: [
            {
              listing: {
                search: {
                  genericListingId: listingsIds,
                },
              },
            },
          ],
          include: [
            `${ListingBackendKey.genericListingId}`,
            `${ListingBackendKey.photos}`,
            `${ListingBackendKey.price}`,
            `${ListingBackendKey.priceString}`,
            `${ListingBackendKey.rooms}`,
            `${ListingBackendKey.toilets}`,
            `${ListingBackendKey.builtArea}`,
            `${ListingBackendKey.isForRent}`,
            `${ListingBackendKey.address}`,
            `${ListingBackendKey.createdAt}`,
            `${ListingBackendKey.daysOnMarket}`,
            `${ListingBackendKey.active}`,
            ListingBackendKey.status,
            `${ListingBackendKey.propertyType}`,
            `${ListingBackendKey.pricePerLotSqm}`,
            `${ListingBackendKey.pricePerBuiltSqm}`,
            `${ListingBackendExpandKey.source}.${SourceBackendKey.name}`,
            `${ListingBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
            `${ListingBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
          ],
        };

        return {
          url: useEndpoint().listings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: ListingBackend[] }): Listing[] =>
        responseListingsAdapter(response.data),
    }),
    getListingsFiltersBackend: builder.query<
      Listing[],
      {
        countryCode: CountryCodeKey;
        bodyProps?: RequestFiltersBackend<ListingBackendInclude, { isCount: false }>;
      }
    >({
      query: ({ countryCode, bodyProps }) => {
        return {
          url: useEndpoint().listings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ListingBackend[] }): Listing[] =>
        responseListingsAdapter(response.data),
    }),
    getListingsCountFiltersBackend: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps?: RequestFiltersBackend<ListingBackendInclude, { isCount: true }>;
      }
    >({
      query: ({ countryCode, bodyProps }) => {
        return {
          url: useEndpoint().listings({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getListingsExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body = { ...requestFiltersAdapter({ ...bodyProps }), columns, format };
        return {
          url: useEndpoint().createListingExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const loadListings = ({
  tabkey,
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
  includeViewport,
}: {
  tabkey:
    | TabKey.listings
    | TabKey.activeListings
    | TabKey.inactiveListings
    | TabKey.featuredListings;
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
  includeViewport?: boolean;
}): AppThunk => {
  return async (dispatch, getState) => {
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();
    try {
      const { filtersByPortal } = getState().newFilters;
      //const { tabStateByPortal } = getState().tableview;
      const { tabState } = getState().gridView;

      const includeOutlier = tabState.find((tab) => tab.id === tabkey)?.filters.includeOutliers;
      dispatch(setLoading({ portal, tabkey, loading: true, isCount }));

      let activeListing: boolean | undefined = undefined;

      if (tabkey === TabKey.activeListings || tabkey === TabKey.inactiveListings) {
        activeListing = tabkey === TabKey.activeListings;
      }

      const requestFiltersAdapterParams: RequestFiltersAdapterProps<
        ListingBackendInclude,
        { isCount: boolean }
      > = {
        isCount,
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchListingFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy:
          sortBy ??
          // Esto le agrega por defecto el sortby por fecha de desactivacion cuando se aplica el filtro de inactive
          (filtersByPortal[portal].filters.listing.boolean?.inactive.appliedValue &&
          !filtersByPortal[portal].filters.listing.boolean?.active.appliedValue
            ? [ListingBackendKey.deactivatedAt, ListingBackendKey.score]
            : [ListingBackendKey.createdAt, ListingBackendKey.score]),
        sortOrder:
          sortOrder ??
          (tabkey === TabKey.listings ||
          tabkey === TabKey.activeListings ||
          tabkey === TabKey.inactiveListings
            ? SortDirectionBackendKey.DESC
            : undefined),
        searchIds: isElasticSearch ? { listing: searchIds.listing } : undefined,
        activeListing: dispatch(userIsLoggedIn()) ? activeListing : true,
        isListing: true,
        datasetFiltersListings: { notIn: [DatasetListingsBackendKey.duplicate] },
        listingsIsOutlier:
          getOrganization().organization.key === OrganizationKey.redatlas
            ? includeOutlier
              ? undefined
              : false
            : undefined,
      };

      const body = requestFiltersAdapter(requestFiltersAdapterParams);

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().listings({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        if (!dispatch(userIsLoggedIn())) {
          const bodyPublicVersion = requestFiltersAdapter({
            ...requestFiltersAdapterParams,
            activeListing,
          });
          const responseRealCount: AxiosResponse<{ data: number }> = await axios.post(
            `${URL_BACKEND}${useEndpoint().listings({
              countryCode: CountryCodeBackendKey[countryCode],
              isCount,
            })}`,
            {
              filters: bodyPublicVersion.filters,
            },
          );
          dispatch(setRealCount({ portal, tabkey, count: responseRealCount.data.data }));
        }
        dispatch(setCount({ portal, tabkey, count: responseCount.data.data }));
      } else {
        const include: ListingBackendInclude[] = [
          `${ListingBackendKey.genericListingId}`,
          `${ListingBackendKey.uniqueListingId}`,
          `${ListingBackendKey.photos}`,
          `${ListingBackendKey.title}`,
          `${ListingBackendKey.address}`,
          `${ListingBackendKey.addressJSON}`,
          `${ListingBackendKey.pricePerBuiltSqm}`,
          `${ListingBackendKey.pricePerLotSqm}`,
          `${ListingBackendKey.price}`,
          `${ListingBackendKey.priceString}`,
          `${ListingBackendKey.propertyType}`,
          `${ListingBackendKey.isForRent}`,
          `${ListingBackendKey.status}`,
          `${ListingBackendKey.builtArea}`,
          `${ListingBackendKey.rooms}`,
          `${ListingBackendKey.toilets}`,
          `${ListingBackendKey.parkingSpaces}`,
          `${ListingBackendKey.createdAt}`,
          `${ListingBackendKey.url}`,
          `${ListingBackendKey.active}`,
          `${ListingBackendKey.deactivatedAt}`,
          `${ListingBackendKey.daysOnMarket}`,
          `${ListingBackendKey.location}`,
          `${ListingBackendKey.lotArea}`,
          `${ListingBackendKey.dataset}`,
          `${ListingBackendExpandKey.source}.${SourceBackendKey.name}`,
          `${ListingBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
          `${ListingBackendExpandKey.source}.${SourceBackendKey.url}`,
          `${ListingBackendExpandKey.amenities}.${AmenityBackendKey.id}`,
          `${ListingBackendExpandKey.parcel}.${ParcelBackendKey.id}`,
          `${ListingBackendExpandKey.parcel}.${ParcelBackendKey.landArea}`,
          `${ListingBackendExpandKey.city}.${CityBackendKey.name}`,
        ];

        const responseColumns: AxiosResponse<{ data: ListingBackend[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().listings({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include: include,
          },
        );
        dispatch(
          setColumns({
            portal,
            tabkey,
            columns: responseListingsAdapter(responseColumns.data.data),
          }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any listings", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey, loading: false, isCount }));

      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useLazyGetListingQuery,
  useGetListingQuery,
  useGetListingsQuery,
  useLazyGetListingsQuery,
  useGetListingsCountQuery,
  useLazyGetListingsCountQuery,
  useFindSimilarListingsQuery,
  useGetSimilarListingsQuery,
  useGetListingsFiltersBackendQuery,
  useLazyGetListingsFiltersBackendQuery,
  useLazyGetListingsCountFiltersBackendQuery,
  useLazyGetListingsExportFileQuery,
} = listingApi;
