import moment from "moment";
import { Listing } from "@/models";
import { convertUnits } from "@/utilities";
import { store } from "@/app/store";

interface ListingsTableAdapterProps {
  listings: Listing[];
  language: any;
  languageBuiltAreaUnits: string;
}

export const listingsTableAdapter = ({
  listings,
  language,
  languageBuiltAreaUnits,
}: ListingsTableAdapterProps) => {
  const dataTableMapper: Record<string, string | number>[] = [];
  const { builtAreaUnit } = store.getState().app;

  if (listings?.length) {
    listings.map((row) => {
      return dataTableMapper.push({
        ID: row.id,
        [language.name.title]: row?.title ? row.title : row.address ? row.address : "---",
        [language.address.title]: row.address ? row.address : "---",
        [language.askingPrice.title]: row.price ? `$ ${row.price}` : "---",
        [language.priceString.title]: row.priceString ?? "---",
        [language.listingSector.title]: row.propertyType ? row.propertyType : "---",
        [language.coordinates.title]: row?.location?.coordinates.length
          ? row.location.coordinates[1] + ", " + row.location.coordinates[0]
          : "---",
        [language.listingType.title]: row.isForRent ? "For Lease" : "For Sale",
        [language.rentableArea.title]: row.builtArea
          ? convertUnits({ value: row.builtArea, to: builtAreaUnit }) + ` ${languageBuiltAreaUnits}`
          : "---",
        [language.bedrooms.title]: row?.rooms ?? "---",
        [language.bathrooms.title]: row?.toilets ?? "---",
        [language.garages.title]: row?.parkingSpaces ?? "---",
        [language.source.title]: row?.dataSource?.name ?? "---",
        [language.listingLink.title]: row.url ?? "---",
        [language.date.title]: row.createdAt ? moment(row.createdAt).format("l") : "---",
      });
    });
  }
  return dataTableMapper;
};
