import defaultTheme from "@/components/styledComponents/themes/redAtlas/theme";
import { OnlyKeyOrganization, OnlyKeyTheme, OrganizationKey, PortalKey } from "@/keys";
import { OnlyKeyPortal } from "@/redux/slices";
import { UnitMeasurement } from "@/utilities";

const organizationTheme = process.env.REACT_APP_ORGANIZATION;

export const defaultPortal = PortalKey.professional;

const checkOrganization = (): OnlyKeyPortal => {
  if (organizationTheme) {
    const findOrganization = Object.keys(PortalKey).find(
      (key) => key.toLowerCase() === organizationTheme,
    );
    return findOrganization ? (findOrganization as OnlyKeyPortal) : defaultPortal;
  } else {
    return PortalKey.professional;
  }
};

interface ThemeConfigType {
  themeName: OnlyKeyTheme;
  organizationTheme: OnlyKeyOrganization;
  portal: OnlyKeyPortal;
  windowWidth: number;
  userMenuOpen: boolean;
  builtAreaUnit: UnitMeasurement;
  landAreaUnit: UnitMeasurement;
  callToActionModalOpen: boolean;
  callToActionModalPricingsOpen: boolean;
}

//Template config options
export const themeConfig: ThemeConfigType = {
  themeName: defaultTheme.themeName,
  organizationTheme: organizationTheme
    ? (organizationTheme as OnlyKeyOrganization)
    : (OrganizationKey.redatlas as OnlyKeyOrganization),
  portal: checkOrganization(),
  windowWidth: window.innerWidth,
  userMenuOpen: false,
  builtAreaUnit: UnitMeasurement.sqft,
  landAreaUnit: UnitMeasurement.acre,
  callToActionModalOpen: false,
  callToActionModalPricingsOpen: false,
};
