import styled from "styled-components";

export const InputCheckbox = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0;
  border-color: ${(props) => `${props.color}`};
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    top: 0;
    &:checked {
      ~ .vx-checkbox {
        transform: rotate(0deg);
        border-color: ${(props) => `${props.color}`};

        .vx-checkbox--check {
          transform: translate(0);
          background-color: ${(props) => `${props.color}`};

          .vx-icon {
            transform: translate(0);
            line-height: 1.2;
            opacity: 1;
          }
        }
        &.vx-checkbox-sm {
          .vx-checkbox--check .vx-icon {
            font-size: 0.75rem;
          }
        }
        &.vx-checkbox-lg {
          .vx-checkbox--check .vx-icon {
            font-size: 1.2rem;
          }
        }
      }
    }
    &:active:checked + .vx-checkbox {
      .vx-checkbox--check {
        transform: translate(3px);
        background-color: ${(props) => `${props.color}`};

        .vx-icon {
          opacity: 1;
          transform: translate(6px);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .vx-checkbox {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
  .vx-checkbox {
    cursor: pointer;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border-width: $vs-checkbox-radio-border-width;
    border-style: solid;
    border-color: ${(props) => `${props.color}`};
    border-radius: 2px;
    transform: rotate(-90deg);
    transition: all 0.2s ease;
    overflow: hidden;
    margin-right: 0.5rem;
    &.vx-checkbox-sm {
      width: $vs-checkbox-box-sm;
      height: $vs-checkbox-box-sm;
    }
    &.vx-checkbox-lg {
      width: $vs-checkbox-box-lg;
      height: $vs-checkbox-box-lg;
    }
    .vx-checkbox--check {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      left: 0;
      transform: translate(100%);
      transform-origin: right;
      transition: all 0.2s ease;
      z-index: 10;
      .vx-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }
  .vx-checkbox-circle {
    border-radius: 50% !important;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;
