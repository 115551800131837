import React, { ReactNode, useEffect } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { NewsFiltersFrontKey, PortalKey } from "@/keys";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFilters, setCallToActionModalOpen, setDropdownToggle } from "@/redux/slices";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight, Sliders } from "react-feather";
import {
  ButtonBorderPrimary,
  ButtonPrimary,
  VerticalTab,
  VerticalTabsContainer,
  baseStyles,
} from "@/components/styledComponents";
import classNames from "classnames";

type FiltersProps = {
  applyFilters: () => void;
  removeFilters: () => void;
  portal: PortalKey;
  clickBlocked: () => void;
  isAdvancedFilters: boolean;
  handleAdvancedFilters: (arg: boolean) => void;
  children: ReactNode;
};

export type AdvancedFilters = {
  location: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.cityId]: boolean;
      [NewsFiltersFrontKey.neighborhoodId]: boolean;
      [NewsFiltersFrontKey.subNeighborhoodId]: boolean;
      [NewsFiltersFrontKey.zipCodeId]: boolean;
      [NewsFiltersFrontKey.buildingId]: boolean;
    };
  };
  parcel: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
      [NewsFiltersFrontKey.landArea]: boolean;
    };
  };
  listing: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.isForRent]: boolean;
      [NewsFiltersFrontKey.active]: boolean;
      [NewsFiltersFrontKey.propertyType]: boolean;
      [NewsFiltersFrontKey.price]: boolean;
      [NewsFiltersFrontKey.pricePerBuiltSqm]: boolean;
      [NewsFiltersFrontKey.pricePerLotSqm]: boolean;
      [NewsFiltersFrontKey.builtArea]: boolean;
      [NewsFiltersFrontKey.lotArea]: boolean;
      [NewsFiltersFrontKey.rooms]: boolean;
      [NewsFiltersFrontKey.toilets]: boolean;
      [NewsFiltersFrontKey.parkingSpaces]: boolean;
      [NewsFiltersFrontKey.daysOnMarket]: boolean;
      [NewsFiltersFrontKey.createdAt]: boolean;
      [NewsFiltersFrontKey.amenityId]: boolean;
      [NewsFiltersFrontKey.isFloodZone]: boolean;
    };
  };
  transaction: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.transactionPrice]: boolean;
      [NewsFiltersFrontKey.transactionPricePerBuiltSqm]: boolean;
      [NewsFiltersFrontKey.transactionPricePerLotSqm]: boolean;
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
      [NewsFiltersFrontKey.transactionDate]: boolean;
    };
  };
  property: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.unitType]: boolean;
      [NewsFiltersFrontKey.totalArea]: boolean;
      [NewsFiltersFrontKey.rooms]: boolean;
      [NewsFiltersFrontKey.toilets]: boolean;
      [NewsFiltersFrontKey.parkingSpaces]: boolean;
    };
  };
  permit: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.permitType]: boolean;
      [NewsFiltersFrontKey.agency]: boolean;
      [NewsFiltersFrontKey.category]: boolean;
      [NewsFiltersFrontKey.status]: boolean;
      [NewsFiltersFrontKey.date]: boolean;
    };
  };
  tenant: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
    };
  };
  tax: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.taxable]: boolean;
      [NewsFiltersFrontKey.totalValuation]: boolean;
      [NewsFiltersFrontKey.exemption]: boolean;
      [NewsFiltersFrontKey.land]: boolean;
      [NewsFiltersFrontKey.structure]: boolean;
      [NewsFiltersFrontKey.machinery]: boolean;
      [NewsFiltersFrontKey.exoneration]: boolean;
    };
  };
};

const DesktopFiltersContainer = ({
  applyFilters,
  removeFilters,
  portal,
  clickBlocked,
  isAdvancedFilters,
  handleAdvancedFilters,
  children,
}: FiltersProps) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const dispatch = useAppDispatch();
  const { dropdownToggle } = useAppSelector(selectFilters);

  return (
    <div className="d-flex">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ padding: "6px 0" }}>
        <UncontrolledButtonDropdown
          isOpen={dropdownToggle}
          toggle={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            handleAdvancedFilters(false);

            dispatch(setDropdownToggle(!dropdownToggle));
          }}>
          <DropdownToggle
            data-toggle="dropdown"
            tag="span"
            className={`${dropdownToggle ? "btn-navbar-filter active" : "btn-navbar-filter"}`}
            style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
            <Sliders
              size={16}
              style={{ transform: "rotate(90deg)" }}
              className="mr-05 cursor-pointer"
            />
            {language.navbarButtons.filters}
          </DropdownToggle>

          <DropdownMenu
            id="root-portal-filters"
            tag="ul"
            right
            className={`dropdown-filter-property p-0 m-0`}
            style={{
              width: isAdvancedFilters ? "calc(100vw - (4.4rem + 35px))" : "calc(100vw - 55vw)",
              transition: "width 400ms",
            }}>
            <VerticalTabsContainer active={isAdvancedFilters}>
              <VerticalTab
                active={true}
                className=""
                onClick={() => handleAdvancedFilters(!isAdvancedFilters)}>
                <div style={{ writingMode: "vertical-lr" }}>
                  {isAdvancedFilters
                    ? language.navbarButtons.simpliedFilters
                    : language.navbarButtons.advancedFilters}
                  {isAdvancedFilters ? (
                    <ChevronLeft size={18} className="fw-bold mt-05" />
                  ) : (
                    <ChevronRight size={18} className="fw-bold mt-05" />
                  )}
                </div>
              </VerticalTab>
            </VerticalTabsContainer>
            <div className="row">
              <div className="col-12">
                {children}
                <div className="row">
                  <div className="col-12">
                    <div
                      style={{
                        boxShadow: "5px 5px 13px 1px rgba(63, 63, 63, 0.2)",
                        borderRadius: baseStyles.borderRadius,
                      }}>
                      <div className="row d-flex justify-content-center">
                        <div
                          className={classNames("col-12", {
                            "col-md-10": isAdvancedFilters,
                          })}>
                          <div
                            className="dropdown-menu-footer d-flex align-items-center justify-content-center px-1 py-05"
                            style={{
                              position: "sticky",
                              bottom: "1rem",
                            }}>
                            <div className="d-flex align-items-center justify-content-center">
                              <ButtonBorderPrimary
                                aria-label="clear-filters"
                                color="danger"
                                onClick={() => {
                                  dispatch(setDropdownToggle(false));
                                  portal === PortalKey.commercial ||
                                  portal === PortalKey.residential
                                    ? clickBlocked()
                                    : removeFilters();
                                }}
                                className={`d-flex align-items-center justify-content-center mr-1`}
                                style={{ padding: "0.75rem 2rem" }}>
                                <div className="fw-bold fs-13 text-uppercase">
                                  {language.navbarButtons.clear}
                                </div>
                              </ButtonBorderPrimary>
                              <ButtonPrimary
                                color="danger"
                                minWidth="fit-content"
                                className={`d-flex align-items-center justify-content-center fw-bold`}
                                style={{ padding: "0.75rem 2rem" }}
                                onClick={() => {
                                  dispatch(setDropdownToggle(false));
                                  portal === PortalKey.commercial ||
                                  portal === PortalKey.residential
                                    ? clickBlocked()
                                    : applyFilters();
                                }}>
                                <div className="fw-bold fs-13 text-uppercase">
                                  {language.navbarButtons.go}
                                </div>
                              </ButtonPrimary>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
      <div className="border-right-gray ml-1 mr-05" />
    </div>
  );
};
export default DesktopFiltersContainer;
