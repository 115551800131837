import React from "react";
import { ButtonXCirclePrimary } from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { AlertCircle } from "react-feather";

type ErrorInfowindowProps = {
  onCloseIndowindow: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

function ErrorInfowindow({ onCloseIndowindow }: ErrorInfowindowProps) {
  const { primary, secondary } = useTheme();
  return (
    <div>
      <ButtonXCirclePrimary
        size="18px"
        margin="10px"
        onClick={(e) => onCloseIndowindow(e)}
        position="absolute"
        top="0"
        style={{
          zIndex: 1,
          backgroundColor: primary.contrastColor,
          borderRadius: "100%",
          height: "18px",
          width: "18px",
        }}>
        <FontAwesomeIcon icon={faCircleXmark} />
      </ButtonXCirclePrimary>
      <div
        style={{ height: "150px", width: "100%" }}
        className="d-flex align-items-center justify-content-center flex-column">
        <AlertCircle size={20} style={{ color: secondary.color }} />
        <h5 className="gray text-center mb-0 fw-bold mt-1">
          Oops, something went wrong. <br /> Please try again later.
        </h5>
      </div>
    </div>
  );
}

export default ErrorInfowindow;
