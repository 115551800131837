import { PermitBackend, responseParcelAdapter } from "@/adapters";
import { Permit } from "@/models";

export const responsePermitAdapter = (permit: PermitBackend): Permit => {
  const newPermit: Permit = {
    id: permit.id,
    case: permit.case,
    procedure: permit.procedure,
    category: permit.category,
    status: permit.status,
    date: permit.date,
    dateNotApproved: permit.dateNotApproved,
    name: permit.name,
    description: permit.description,
    address: permit.address,
    owner: permit.owner,
    publicPrivate: permit.publicPrivate,
    agency: permit.agency,
    url: permit.url,
    createdAt: permit.createdAt,
    updatedAt: permit.updatedAt,
    deletedAt: permit.deletedAt,
    govtId: permit.govtId,
    addressJSON: permit.addressJSON,
    permitType: permit.permitType,
    parcel: permit.parcel ? responseParcelAdapter(permit.parcel) : undefined,
    propertyId: permit.propertyId,
  };
  if (permit.source) {
    newPermit.source = { name: permit.source.name, logoUrl: permit.source.logoUrl };
  }
  if (permit.sectorId) {
    newPermit.sectorId = permit.sectorId;
  }
  if (permit.subsectorId) {
    newPermit.subsectorId = permit.subsectorId;
  }
  if (permit.sector) {
    newPermit.sector = permit.sector;
  }
  if (permit.subsector) {
    newPermit.subsector = permit.subsector;
  }
  return newPermit;
};
