import React from "react";
import NumberFormats, { NumberFormatProps } from "react-number-format";

type CustomNumberFormatProps = Pick<
  NumberFormatProps,
  "value" | "prefix" | "suffix" | "decimalScale"
>;

/**
 * For decimal formatting use the prop 'decimalScale'
 */
export const NumberFormat = ({
  value,
  prefix,
  suffix,
  decimalScale = 0,
}: CustomNumberFormatProps) => {
  return (
    <NumberFormats
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={decimalScale}
      prefix={prefix}
      suffix={suffix}
    />
  );
};
