import { LanguageCodeKey } from "@/keys";
import { Amenities } from "@/models";
import { AmenityBackend, AmenityTypeBackendKey } from "../models/amenity.backend.model";

export const responseAmenitiesAdapter = (
  amenities: AmenityBackend[],
  language?: LanguageCodeKey,
): Amenities => {
  const newAmenities: Amenities = {
    amenities: [],
    rooms: [],
    views: [],
    top10: [],
  };

  if (amenities.length) {
    amenities.map((amenity) => {
      if (amenity.amenityType === AmenityTypeBackendKey.room) {
        newAmenities.rooms.push({
          value: amenity.id,
          label: language === LanguageCodeKey.es ? amenity.esName : amenity.name,
        });
      } else if (amenity.amenityType === AmenityTypeBackendKey.view) {
        newAmenities.views.push({
          value: amenity.id,
          label: language === LanguageCodeKey.es ? amenity.esName : amenity.name,
        });
      } else {
        newAmenities.amenities.push({
          value: amenity.id,
          label: language === LanguageCodeKey.es ? amenity.esName : amenity.name,
        });
      }
    });
    const top10 = amenities.sort((a, b) => b.count - a.count).slice(0, 10);
    newAmenities.top10 = top10.map((amenity) => ({
      value: amenity.id,
      label: language === LanguageCodeKey.es ? amenity.esName : amenity.name,
      amenityType: amenity.amenityType,
    }));
  }

  return newAmenities;
};
