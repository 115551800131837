import { store } from "@/app/store";
import { ColumnChartOptions } from "@/models";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { AnalyticsBackend } from "../models/analytics/analytics.backend.model";

export const responseAnalyticsColumnChartAdapter = (
  data: AnalyticsBackend[],
): ColumnChartOptions => {
  const dataChart: ColumnChartOptions = {
    series: [
      {
        data: [],
      },
    ],
    categories: [],
    colors: [],
  };

  const { themeName, organizationTheme, portal } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);

  if (data.length) {
    data.map((data) => {
      dataChart.series[0].data.push(Number(parseFloat(data.value).toFixed(2)));
      dataChart.categories.push(data.key);
      dataChart.colors.push(activeTheme?.secondary.color as ThemeType["secondary"]["color"]);
    });
  }

  return dataChart;
};
