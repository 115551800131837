import React from 'react';
import { ButtonLink, ButtonLinkExternal } from './Buttons';
import { history } from '@/history';

interface ButtonType {
  buttonType: string;
  cta: string;
  urlTarget?: string,
  buttonColor?: string;
  notClickeable?: boolean
}

interface ParagraphButtonProps {
  buttonTypes: ButtonType[]; // Change to an array of objects containing buttonType and cta
  text: string;
}

export const ParagraphWithButton = ({
  buttonTypes,
  text,
}: ParagraphButtonProps): JSX.Element => {
  const regexPattern = buttonTypes.map((type) => `(${type.buttonType})`).join('|');
  const regex = new RegExp(regexPattern, 'g');

  const splitText = text.split(regex);
  const elements = splitText.map((part, index) => {
    const buttonTypeObject = buttonTypes.find((type) => type.buttonType === part);

    if (buttonTypeObject) {
      const isExternalLink = buttonTypeObject.urlTarget?.startsWith('http');

      return isExternalLink ? (
        <ButtonLinkExternal
          href={buttonTypeObject.urlTarget}
          key={index}
          style={{ fontSize: 'inherit', color: buttonTypeObject.buttonColor || 'inherit' }}
        >
          {buttonTypeObject.cta}
        </ButtonLinkExternal>
      ) : (
        <ButtonLink
          key={index}
          notClickeable={buttonTypeObject.notClickeable || false}
          onClick={() => buttonTypeObject.notClickeable ? "" : history.push(`${buttonTypeObject.urlTarget}`)}
          style={{ fontSize: 'inherit', color: buttonTypeObject.buttonColor || 'inherit' }}
        >
          {buttonTypeObject.cta}
        </ButtonLink>
      );
    } else {
      return <span key={index}>{part}</span>;
    }
  });

  return <p>{elements}</p>;
};