export enum TimeOnMarketBackendKey {
  lotValue = "Lot Value",
  pricePerAcre = "Price per Acre",
  builtValue = "Built Value",
  pricePerSqft = "Price per Sqft",
  annualRent = "Annual Rent",
  rentPerSqft = "Rent per Sqft",
  rentToValue = "Rent to Value",
  totalValue = "Total Value",
}

export enum TimeOnMarketDataBackendKey {
  oneMonth = "1 Month",
  sixMonth = "6 Month",
  twelveMonth = "12 Month",
  certainty = "Certainty",
}

export enum CurrentValuationBackendKey {
  totalValue = "Total Value",
  totalValuePerSqft = "Total Value Per Sqft",
  totalValuePerSqmt = "Total Value Per Sqmt",
  builtValue = "Built Value",
  builtValuePerSqft = "Built Value Per Sqft",
  builtValuePerSqmt = "Built Value Per Sqmt",
  lotValue = "Lot Value",
  lotValuePerAcre = "Lot Value Per Acre",
  lotValuePerSqmt = "Lot Value Per Sqmt",
  rentPerYear = "Rent per Year",
  rentPerYearPerSqft = "Rent per Year per Sqft",
  rentPerYearPerSqmt = "Rent per Year per Sqmt",
}
