import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { themeConfig } from "@/configs";
import { OnlyKeyPortal } from "@/redux/slices";
import { OnlyKeyTheme } from "@/keys";
import { UnitMeasurement } from "@/utilities";

const initialState = themeConfig;

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changePortal: (state, action: PayloadAction<{ portal: OnlyKeyPortal }>) => {
      state.portal = action.payload.portal;
    },
    setWindowWidth: (state, action: PayloadAction<{ windowWidth: number }>) => {
      state.windowWidth = action.payload.windowWidth;
    },
    changeTheme: (state, action: PayloadAction<{ themeName: OnlyKeyTheme }>) => {
      const { themeName } = action.payload;
      state.themeName = themeName;
    },
    setUserMenuOpen: (state, action: PayloadAction<{ userMenuOpen: boolean }>) => {
      state.userMenuOpen = action.payload.userMenuOpen;
    },
    setBuiltAreaUnit: (state, action: PayloadAction<{ builtAreaUnit: UnitMeasurement }>) => {
      state.builtAreaUnit = action.payload.builtAreaUnit;
    },
    setLandAreaUnit: (state, action: PayloadAction<{ landAreaUnit: UnitMeasurement }>) => {
      state.landAreaUnit = action.payload.landAreaUnit;
    },
    setCallToActionModalOpen: (
      state,
      action: PayloadAction<{ callToActionModalOpen: boolean }>,
    ) => {
      state.callToActionModalOpen = action.payload.callToActionModalOpen;
    },
    setCallToActionModalPricingsOpen: (
      state,
      action: PayloadAction<{ callToActionModalPricingsOpen: boolean }>,
    ) => {
      state.callToActionModalPricingsOpen = action.payload.callToActionModalPricingsOpen;
    },
  },
});

// These actions will dispatch in the app
export const {
  setWindowWidth,
  changePortal,
  changeTheme,
  setUserMenuOpen,
  setBuiltAreaUnit,
  setLandAreaUnit,
  setCallToActionModalOpen,
  setCallToActionModalPricingsOpen,
} = appSlice.actions;

export const selectAppConfig = (state: RootState) => state.app;

export default appSlice.reducer;
