import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeAnimated from "react-select/animated";
import variables from "../../assets/scss/core/variables/_variables.scss";
import { rgba } from "polished";
import { useAppSelector } from "@/app/hooks";
import { selectAppConfig } from "@/redux/slices";
import { sizeViewport } from "../styledComponents";

const env = process.env.REACT_APP_ORGANIZATION;
const primary = variables[`${env}-primary`]
  ? variables[`${env}-primary`]
  : variables["redatlas-primary"];

export const StyleReactSelect = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? `1px solid ${primary}` : `1px solid #cccccc`,
    boxShadow: state.isFocused ? `0px 0px 0px 1px ${primary} !important` : `none`,

    "&:hover": {
      boxShadow: `0px 0px 0px 1px ${primary} !important`,
      border: `1px solid ${primary} !important`,
    },
  }),
  menu: (base) => ({
    ...base,
    // borderRadius: `0.5rem`
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    // borderRadius: `0.5rem`
  }),
  option: (base) => ({
    ...base,
    cursor: `pointer`,
    // border: `0.2rem solid #fff`,
    // borderRadius: `0.5rem`,
    color: "black",
    "&:active": {
      backgroundColor: rgba(0, 0, 0, 0.2),
    },
  }),
};

export const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: rgba(0, 0, 0, 0.2),
    primary: rgba(255, 255, 255, 0),
  },
});

function MenuList(props) {
  const { windowWidth } = useAppSelector(selectAppConfig);

  let height = { height: `145px` };
  if (props.children.length === 3 && windowWidth >= sizeViewport.tablet) {
    height = { height: `109px` };
  } else if (props.children.length === 2 && windowWidth >= sizeViewport.tablet) {
    height = { height: `72.5px` };
  } else if (props.children.length === 1 || !props.children.length) {
    height = { height: `36.25px` };
  } else if (windowWidth < sizeViewport.tablet) {
    height = { height: `unset` };
  }

  return (
    <div className={`${props.selectProps.components.MenuList}`} style={height}>
      <PerfectScrollbar options={{ wheelPropagation: false }}>{props.children}</PerfectScrollbar>
    </div>
  );
}

export const componentsSelect = {
  MenuList,
};

export const animatedComponents = makeAnimated();
