import { appPaths } from "@/configs";
import { CookiesKey } from "@/keys";
import Cookies from "universal-cookie";

export const setCookieNewsNotificationLastDate = () => {
  const today = new Date();
  const serverDate = today.toLocaleString("sv-SE", { timeZone: "UTC" });

  setCookie({
    name: CookiesKey.newsNotificationLastDate,
    value: serverDate,
    path: appPaths.home.path,
  });
};

const addHours = (numOfHours: number, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};

export const setCookieNewsNotificationExpired = ({ hoursToExpire }: { hoursToExpire: number }) => {
  const date = addHours(hoursToExpire);

  setCookie({
    name: CookiesKey.newsNotificationExpired,
    value: date,
    path: appPaths.home.path,
    expires: date,
  });
};

const setCookie = ({
  name,
  value,
  path,
  expires,
}: {
  name: string;
  value: any;
  path: string;
  expires?: Date;
}) => {
  const cookie = new Cookies();
  cookie.set(name, value, { path: path, expires: expires });
};
