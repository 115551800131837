import type { AvailableMemberList, Lists, List } from "@/models";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ListEntityTypeKey,
  ListingBackendInclude,
  ParcelBackendInclude,
  useEndpoint,
} from "@/keys";
import {
  responseListsAdapter,
  responseListAdapter,
  ListBackend,
  ListMemberBackend,
  responseAvailableMemberListAdapter,
} from "@/adapters";
import { RootState } from "@/app/store";
import { api } from "@/services";

export interface BodyListProps {
  listingInclude: ListingBackendInclude[];
  parcelInclude: ParcelBackendInclude[];
}

export const listsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // ------------- Get Lists (All)
    getLists: builder.query<Lists, { countryCode: CountryCodeKey }>({
      query: ({ countryCode }) =>
        useEndpoint().lists({ countryCode: CountryCodeBackendKey[countryCode] }),
      transformResponse: (response: { data: ListBackend }): Lists =>
        responseListsAdapter(response.data),
      providesTags: ["Lists"],
    }),

    // ------------- Get List (Once)
    getList: builder.query<
      List,
      { id: string; countryCode: CountryCodeKey; bodyProps?: BodyListProps }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().list({ id, countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        };
      },

      transformResponse: (response: { data: ListMemberBackend }): List =>
        responseListAdapter(response.data),
      providesTags: ["List"],
    }),

    // ------------- Create List (Once)
    createList: builder.mutation<
      { data: boolean; errors: [] },
      Partial<List> & { countryCode: CountryCodeBackendKey }
    >({
      query(body) {
        return {
          url: useEndpoint().createList(),
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Lists", "AvailabilityLists"],
    }),

    // ------------- Update List (Once)
    updateList: builder.mutation<
      { data: boolean; errors: [] },
      { id: string; body: Partial<List> & { countryCode: CountryCodeBackendKey } }
    >({
      query({ id, body }) {
        return {
          url: useEndpoint().listUpdateDelete({ id }),
          method: "put",
          body,
        };
      },
      invalidatesTags: ["List", "Lists"],
    }),

    // ------------- Delete Member of List (Once)
    deleteMemberList: builder.mutation<
      { data: boolean; errors: [] },
      { listId: string; propertyListId: string; entity: ListEntityTypeKey }
    >({
      query({ listId, propertyListId, entity }) {
        return {
          url: useEndpoint().deleteListsMembers({ listId }),
          method: "DELETE",
          body: { id: propertyListId, entity },
        };
      },
      invalidatesTags: ["Lists", "List", "AvailabilityLists"],
    }),

    // ------------- Delete List (Once)
    deleteList: builder.mutation<{ data: boolean; errors: [] }, { listId: string }>({
      query({ listId }) {
        return {
          url: useEndpoint().listUpdateDelete({ id: listId }),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Lists", "AvailabilityLists"],
    }),

    // ------------- Chequea las listas que aún no contienen la propiedad seleccionada
    checkMemberAvailability: builder.query<
      AvailableMemberList[],
      { memberId: string; entityType: keyof typeof ListEntityTypeKey; countryCode: CountryCodeKey }
    >({
      query: ({ memberId, entityType, countryCode }) =>
        useEndpoint().listsAvailability({
          id: memberId,
          entity: entityType,
          countryCode: CountryCodeBackendKey[countryCode],
        }),
      transformResponse: (response: { data: AvailableMemberList[] }): AvailableMemberList[] =>
        responseAvailableMemberListAdapter(response.data),
      providesTags: ["AvailabilityLists"],
    }),

    // ------------- Add Member to List (Once)
    addListMember: builder.mutation<
      { data: boolean; errors: [] },
      { listId: string; memberId: string; entityType: keyof typeof ListEntityTypeKey }
    >({
      query({ listId, memberId, entityType }) {
        return {
          url: useEndpoint().listsMembers({ id: listId }),
          method: "post",
          body: { id: memberId, entity: entityType },
        };
      },
      invalidatesTags: ["AvailabilityLists", "Lists", "List"],
    }),
  }),
  overrideExisting: false,
});

// -------------------------------- Queries (React hooks)--------------------------------
export const {
  useGetListsQuery,
  useGetListQuery,
  useCreateListMutation,
  useUpdateListMutation,
  useDeleteListMutation,
  useDeleteMemberListMutation,
  useCheckMemberAvailabilityQuery,
  useAddListMemberMutation,
} = listsApi;
