import { NewsFiltersEntityFrontKey, NewsFiltersFrontKey, NewsFiltersTypeFrontKey } from "@/keys";
import { Filters, OnlyKeyEntityFilter, selectAppConfig, selectFilters } from "@/redux/slices";
import React from "react";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { useTranslation } from "react-i18next";
import { AdvancedFilters, handleOnBlurProps } from "../../../Filters";
import classNames from "classnames";
import { useTheme } from "styled-components";
import { useAppSelector } from "@/app/hooks";

type TaxSectionProps = {
  isAdvancedFilters: boolean;
  advancedTaxesFilters: AdvancedFilters["tax"]["filters"];
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  availableTaxesFilters: {
    show: boolean;
    totalValuation: boolean;
    exemption: boolean;
    land: boolean;
    structure: boolean;
    machinery: boolean;
    exoneration: boolean;
    taxable: boolean;
  };
  localFilters: Filters;
  handleOnBlur: (params: handleOnBlurProps[]) => void;
};

const TaxSection = ({
  isAdvancedFilters,
  advancedTaxesFilters,
  handleMinMaxChange,
  availableTaxesFilters,
  localFilters,
  handleOnBlur,
}: TaxSectionProps) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const { common } = useTheme();
  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const reduxFilters = filtersByPortal[portal].filters;

  type NumericFiltersKeys =
    | NewsFiltersFrontKey.totalValuation
    | NewsFiltersFrontKey.exemption
    | NewsFiltersFrontKey.land
    | NewsFiltersFrontKey.structure
    | NewsFiltersFrontKey.machinery
    | NewsFiltersFrontKey.exoneration
    | NewsFiltersFrontKey.taxable;

  const numericFiltersKeys: Array<NumericFiltersKeys> = [
    NewsFiltersFrontKey.taxable,
    NewsFiltersFrontKey.totalValuation,
    NewsFiltersFrontKey.exemption,
    NewsFiltersFrontKey.land,
    NewsFiltersFrontKey.structure,
    NewsFiltersFrontKey.machinery,
    NewsFiltersFrontKey.exoneration,
  ];

  return (
    <div className="row px-1">
      <div className="col-12">
        <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
          <h5 className="black fw-bold text-uppercase mb-0 mr-2">{language.menuFilters.tax}</h5>
          <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
        </div>
      </div>
      {numericFiltersKeys.map(
        (key) =>
          availableTaxesFilters[key] &&
          localFilters.tax.numeric?.[NewsFiltersFrontKey[key]] &&
          advancedTaxesFilters?.[key] && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}
              key={key}>
              <h6 className="mb-05 fw-bold black ml-1">{language.filters[key].title}</h6>
              <div className="d-flex">
                <InputGroup className="inputGroupPriceFrom">
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>

                  <Input
                    placeholder={language.filters[key].minPlaceholder}
                    type="text"
                    className="font-size-16px"
                    value={localFilters.tax.numeric?.[NewsFiltersFrontKey[key]].value.min}
                    onChange={(value: React.FormEvent<HTMLInputElement>) =>
                      handleMinMaxChange({
                        name: NewsFiltersFrontKey[key],
                        value: { min: value.currentTarget.value },
                        entity: NewsFiltersEntityFrontKey.tax,
                        type: NewsFiltersTypeFrontKey.numeric,
                      })
                    }
                    onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                      if (
                        reduxFilters.tax.numeric?.[NewsFiltersFrontKey[key]].value.min !==
                        value.currentTarget.value
                      ) {
                        handleOnBlur([
                          {
                            entity: NewsFiltersEntityFrontKey.tax,
                            filterTypeValue: localFilters.tax,
                          },
                        ]);
                      }
                    }}
                  />
                </InputGroup>
                <InputGroup className="inputGroupPriceTo">
                  <Input
                    placeholder={language.filters[key].maxPlaceholder}
                    type="text"
                    value={localFilters.tax.numeric?.[NewsFiltersFrontKey[key]].value.max}
                    className="font-size-16px"
                    onChange={(value: React.FormEvent<HTMLInputElement>) =>
                      handleMinMaxChange({
                        name: NewsFiltersFrontKey[key],
                        value: { max: value.currentTarget.value },
                        entity: NewsFiltersEntityFrontKey.tax,
                        type: NewsFiltersTypeFrontKey.numeric,
                      })
                    }
                    onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                      if (
                        reduxFilters.tax.numeric?.[NewsFiltersFrontKey[key]].value.max !==
                        value.currentTarget.value
                      ) {
                        handleOnBlur([
                          {
                            entity: NewsFiltersEntityFrontKey.tax,
                            filterTypeValue: localFilters.tax,
                          },
                        ]);
                      }
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          ),
      )}
    </div>
  );
};

export default TaxSection;
