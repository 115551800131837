import {
  BodyExportFileBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  RequestFiltersBackend,
  responseTransactionsAdapter,
  TransactionBackend,
} from "@/adapters";
import { AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { defaultFilters } from "@/configs";
import {
  CityBackendKey,
  TransactionBackendExpandKey,
  TransactionBackendKey,
  SourceBackendKey,
  CountryCodeBackendKey,
  ParcelBackendExpandKey,
  ParcelBackendKey,
  SectorBackendKey,
  SubsectorBackendKey,
  TabKey,
  useEndpoint,
  SortDirectionBackendKey,
  KeyofSortDirectionBackendKey,
  DatasetTransactionsBackendKey,
  TransactionBackendInclude,
  CountryCodeKey,
  TransactionSectorBackendKey,
  ParcelBackendInclude,
} from "@/keys";
import { Parcel, Transaction } from "@/models";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "../api";
import { useLocaleCountryCode } from "@/hooks";

export const transactionApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getTransactionsDeprecated: builder.query<
      Transaction[],
      {
        bodyProps: RequestFiltersBackend<TransactionBackendInclude, { isCount: false }>;
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ bodyProps, countryCode }) => {
        return {
          url: useEndpoint().transactions({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: TransactionBackend[] }): Transaction[] =>
        responseTransactionsAdapter(response.data),
    }),
    getTransactions: builder.query<
      Transaction[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TransactionBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().transactions({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: TransactionBackend[] }): Transaction[] =>
        responseTransactionsAdapter(response.data),
    }),
    getTransactionsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TransactionBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().transactions({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getTransactionsExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TransactionBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createTransactionExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const loadTransactions = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { tabStateByPortal } = getState().tableview;
    const { tabState } = getState().gridView;

    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    const includeOutlier = tabState.find((tab) => tab.id === TabKey.transactions)?.filters
      .includeOutliers;
    try {
      dispatch(setLoading({ portal, tabkey: TabKey.transactions, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        // TODO: Aplicamos este filtro para traer en COL solo las transacciones que esten asociadas a units
        defaultFilters:
          countryCode === CountryCodeKey.CO
            ? {
                ...defaultFilters[portal],
                property: {
                  ...defaultFilters[portal].property,
                  search: {
                    ...defaultFilters[portal].property?.search,
                    id: {
                      isNotNull: true,
                    },
                  },
                },
              }
            : defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy: sortBy ?? TransactionBackendKey.transactionDate,
        sortOrder: sortOrder ?? SortDirectionBackendKey.DESC,
        datasetFiltersTransactions: includeOutlier
          ? { notIn: [DatasetTransactionsBackendKey.duplicate] }
          : {
              notIn: [
                DatasetTransactionsBackendKey.outlier,
                DatasetTransactionsBackendKey.duplicate,
                DatasetTransactionsBackendKey.incomplete,
              ],
            },
        searchIds: isElasticSearch ? { transaction: searchIds.transaction } : undefined,
      });

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().transactions({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.transactions, count: responseCount.data.data }));
      } else {
        const include: TransactionBackendInclude[] = [
          `${TransactionBackendKey.id}`,
          `${TransactionBackendKey.address}`,
          `${TransactionBackendKey.transactionType}`,
          `${TransactionBackendKey.transactionPrice}`,
          `${TransactionBackendKey.pricePerBuiltSqm}`,
          `${TransactionBackendKey.pricePerLotSqm}`,
          `${TransactionBackendKey.transactionDate}`,
          `${TransactionBackendKey.buyerName}`,
          `${TransactionBackendKey.sellerName}`,
          `${TransactionBackendKey.scriptures}`,
          `${TransactionBackendExpandKey.properties}.*`,
          `${TransactionBackendExpandKey.sector}.${TransactionSectorBackendKey.name}`,
          `${TransactionBackendExpandKey.sector}.${TransactionSectorBackendKey.nameEn}`,
          `${TransactionBackendExpandKey.source}.${SourceBackendKey.logoUrl}`,
          `${TransactionBackendExpandKey.source}.${SourceBackendKey.name}`,
        ];
        const responseColumns: AxiosResponse<{ data: Transaction[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().transactions({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
          {
            transformResponse: [
              (data) => {
                let resp;
                try {
                  resp = JSON.parse(data);
                  resp.data = responseTransactionsAdapter(resp.data);
                  return resp;
                } catch (error) {
                  throw Error(
                    `[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`,
                  );
                }
              },
            ],
          },
        );

        const parcelIds = [];

        for (let index = 0; index < responseColumns.data.data.length; index++) {
          const transaction = responseColumns.data.data[index];
          if (
            transaction?.transactionProperties?.length &&
            transaction?.transactionProperties[0]?.property?.parcelId
          ) {
            parcelIds.push(transaction?.transactionProperties[0]?.property?.parcelId);
          }
        }

        const bodyParcel: RequestFiltersBackend<ParcelBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
            },
          ],
          include: [
            `${ParcelBackendKey.id}`,
            `${ParcelBackendKey.photos}`,
            `${ParcelBackendKey.name}`,
            `${ParcelBackendKey.address}`,
            `${ParcelBackendExpandKey.city}.${CityBackendKey.name}`,
            `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
            `${ParcelBackendExpandKey.subsector}.${SubsectorBackendKey.name}`,
          ],
        };

        const responseParcelColumns: AxiosResponse<{ data: Parcel[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          bodyParcel,
        );

        const transactionsExpanded = responseColumns.data.data;

        for (let index = 0; index < transactionsExpanded.length; index++) {
          const transaction = transactionsExpanded[index];
          if (
            transaction?.transactionProperties?.length &&
            transaction?.transactionProperties[0]?.property
          ) {
            const parcelId = transaction?.transactionProperties[0]?.property?.parcelId;
            if (parcelId) {
              const parcel = responseParcelColumns.data.data.find(
                (parcel) => parcel.id === parcelId,
              );
              if (parcel) {
                transaction.transactionProperties[0].property.parcel = parcel;
              }
            }
          }
        }

        dispatch(
          setColumns({ portal, tabkey: TabKey.transactions, columns: transactionsExpanded }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any transactions", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.transactions, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.transactions, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useLazyGetTransactionsExportFileQuery,
  useLazyGetTransactionsDeprecatedQuery,
  useLazyGetTransactionsQuery,
  useLazyGetTransactionsCountQuery,
} = transactionApi;
