export enum ExpandOptionsKey {
  cities = "cities",
  city = "city",
  neighborhood = "neighborhood",
  source = "source",
  parcel = "parcel",
  properties = "properties",
  listings = "listings",
  permits = "permits",
  tenants = "tenants",
  taxes = "taxes",
  transactions = "transactions",
  property = "property",
  parcels = "parcels",
  sector = "sector",
  subsector = "subsector",
  microsector = "microsector",
  documents = "documents",
  news = "news",
}
