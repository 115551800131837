import type { Tax } from "@/models";
import { TaxBackend, responseTaxAdapter } from "@/adapters";

export const responseTaxesAdapter = (taxes: TaxBackend[]): Tax[] => {
  const newTaxes: Tax[] = [];

  if (taxes.length) {
    taxes.map((tax) => {
      newTaxes.push(responseTaxAdapter(tax));
    });
  }

  return newTaxes;
};
