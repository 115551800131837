import React, { useState } from "react";

import { Modal, ModalBody, Spinner } from "reactstrap";
import { CheckCircle, List, Circle } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectLists, setShowNewListModal } from "@/redux/slices";
import {
  useAddListMemberMutation,
  useCheckMemberAvailabilityQuery,
  useGetListsQuery,
} from "@/services";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AvailableMemberList } from "@/models";
import { ListEntityTypeKey, OrganizationKey } from "@/keys";
import { ButtonPrimary, ButtonXCirclePrimary } from "../styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useLocaleCountryCode } from "@/hooks";
import { userIsLogged } from "@/redux/actions";
import { getOrganization } from "@/configs";

type ModalAvailableMembersListsProps = {
  showListsModal: boolean;
  setShowListsModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleModal: () => void;
  memberId: string;
  entityType?: keyof typeof ListEntityTypeKey | undefined;
};

export default function ModalAvailableMembersLists({
  showListsModal,
  setShowListsModal,
  toggleModal,
  memberId,
  entityType,
}: ModalAvailableMembersListsProps) {
  const { showListModal } = useAppSelector(selectLists);
  const { countryCode } = useLocaleCountryCode();
  const dispatch = useAppDispatch();
  const { data: propertiesLists } = useGetListsQuery(
    { countryCode },
    {
      skip:
        !dispatch(userIsLogged()) ||
        getOrganization().organization.key !== OrganizationKey.redatlas,
      selectFromResult: ({ data, ...rest }) => {
        return {
          data: dispatch(userIsLogged()) ? data : undefined,
          ...rest,
        };
      },
    },
  );
  const { primary } = useTheme();
  const [selectedList, setSelectedList] = useState("");
  const { t } = useTranslation("lists");
  const translation = t("lists", { returnObjects: true });

  const [disableListClick, setDisableListClick] = useState<boolean>(false);

  const {
    data: availableLists,
    isLoading: isLoadingAvailableLists,
    isFetching: isFetchingAvailableLists,
  } = useCheckMemberAvailabilityQuery(
    !memberId || !entityType ? skipToken : { memberId, entityType, countryCode },
  );

  const [addPropertyListMember, { isLoading: isLoadingAddPropertyList }] =
    useAddListMemberMutation();

  const toggleNewListModal = () => {
    dispatch(setShowNewListModal({ showListModal: !showListModal }));
  };

  const handlePropertyToList = async ({
    list,
    memberId,
    entityType,
  }: {
    list: AvailableMemberList;
    memberId: string;
    entityType?: keyof typeof ListEntityTypeKey;
  }) => {
    if (!list.available || !entityType || disableListClick) return;
    setDisableListClick(true);
    setSelectedList(list.id);
    await addPropertyListMember({ listId: list.id, memberId, entityType }).unwrap();
    setShowListsModal(false);
    setSelectedList("");
    setDisableListClick(false);
  };

  const renderLists = () => (
    <div>
      {isLoadingAvailableLists || isFetchingAvailableLists ? (
        <div className="d-flex justify-content-center mb-2 mt-1">
          <Spinner size="md" color="primary" className="ml-05" />
        </div>
      ) : (
        availableLists &&
        availableLists.map((list) => (
          <div
            className={[
              "media-list media-bordered mb-1",
              list.available
                ? "cursor-pointer btn-list border-success"
                : "list-disabled border-gray",
            ].join(" ")}
            key={list.id}
            onClick={() => handlePropertyToList({ list, memberId, entityType })}>
            <div className="d-flex align-items-center">
              {!list.available ? (
                <CheckCircle size={16} className="success mr-05" />
              ) : (
                <Circle size={16} className="success mr-05" />
              )}
              <h4 className="mb-0 modal-list-name fw-bold black">{list.name}</h4>
              {isLoadingAddPropertyList && selectedList === list.id && (
                <Spinner size="sm" color="primary" className="ml-05" />
              )}
            </div>
            {!list.available && (
              <small className="success">{translation.selectList.listNotAvailable}</small>
            )}
          </div>
        ))
      )}
    </div>
  );

  return (
    <Modal
      zIndex={1300}
      isOpen={showListsModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      style={{ maxWidth: "330px" }}>
      <div className="recharge-points-header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <List size={16} className="mr-05" style={{ color: primary.color }} />
          <h5 className="text-center mb-0 fw-bold black">{translation.selectList.title}</h5>
        </div>
        <ButtonXCirclePrimary size="18px" margin="10px" onClick={toggleModal}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </ButtonXCirclePrimary>
      </div>

      <ModalBody>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false,
          }}
          style={{ maxWidth: "300px", maxHeight: "250px" }}>
          {propertiesLists?.count ? (
            renderLists()
          ) : (
            <div>
              <p className="text-center">{translation.selectList.noLists}</p>
            </div>
          )}
        </PerfectScrollbar>
        <div className="d-flex justify-content-center mt-1">
          <ButtonPrimary
            onClick={() => {
              toggleNewListModal();
            }}>
            {translation.selectList.buttons.create}
          </ButtonPrimary>
        </div>
      </ModalBody>
    </Modal>
  );
}
