import React from "react";
import { useAppSelector } from "@/app/hooks";
import { CategoricValueFilters } from "@/models";
import { Pill, sizeViewport } from "@/components/styledComponents";
import { CategoricFiltersKey, NewsFiltersEntityFrontKey, NewsFiltersFrontKey } from "@/keys";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { Filters, selectAppConfig, selectFilters } from "@/redux/slices";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { useTheme } from "styled-components";
import {
  CustomMultiValueLabel,
  CustomOption,
  CustomPlaceholder,
} from "@/components/reactSelect/CustomComponents";
import { AdvancedFilters } from "../../../Filters";
import SelectFilter from "../../../components/SelectFilter";
import { useCategoryTranslations } from "@/hooks";

type TenantSectionProps = {
  isAdvancedFilters: boolean;
  advancedTenantFilters: AdvancedFilters["tenant"]["filters"];
  availableTenantFilters: {
    show: boolean;
    sector: boolean;
    subsector: boolean;
    microsector: boolean;
  };
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
};
const TenantSection = ({
  isAdvancedFilters,
  advancedTenantFilters,
  availableTenantFilters,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: TenantSectionProps) => {
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const { defaultColors, parcelColors, common } = useTheme();
  const isMobile = windowWidth < sizeViewport.laptop;
  const { languageSector } = useCategoryTranslations();

  const reduxFilters = filtersByPortal[portal].filters;

  // -------------- dependent options Select
  const dependentOptionsMap = (value: string) => {
    switch (value) {
      case CategoricFiltersKey.sectors:
        return filtersByPortal[portal].categoricFilters.sectors.map((option) => {
          const { value, label, name } = option;
          return { value, label, name };
        });
      case CategoricFiltersKey.subsectors:
        if (reduxFilters.tenant.categoric?.sectorId.value) {
          // si sector tiene valor
          const subS = filtersByPortal[portal].categoricFilters.subsectors.filter(
            (subsector) =>
              (!Array.isArray(reduxFilters.tenant.categoric?.sectorId.value) &&
                subsector.sectorId === reduxFilters.tenant.categoric?.sectorId.value?.value) ||
              (Array.isArray(reduxFilters.tenant.categoric?.sectorId.value) &&
                reduxFilters.tenant.categoric?.sectorId.value.length === 1 &&
                subsector.sectorId === reduxFilters.tenant.categoric?.sectorId.value[0]?.value),
          );
          if (subS.length) {
            return subS.map((option) => {
              const { value, label, sectorId } = option;
              return { value, label, sectorId };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.subsectors.map((option) => {
            const { value, label, sectorId } = option;
            return { value, label, sectorId };
          });
        }
      case CategoricFiltersKey.microsectors:
        if (reduxFilters.tenant.categoric?.subsectorId.value) {
          // si subsector tiene valor
          const micro = filtersByPortal[portal].categoricFilters.microsectors.filter(
            (microsector) =>
              (!Array.isArray(reduxFilters.tenant.categoric?.subsectorId.value) &&
                microsector.subsectorId ===
                  reduxFilters.tenant.categoric?.subsectorId.value?.value) ||
              (Array.isArray(reduxFilters.tenant.categoric?.subsectorId.value) &&
                reduxFilters.tenant.categoric?.subsectorId.value.length === 1 &&
                microsector.subsectorId ===
                  reduxFilters.tenant.categoric?.subsectorId.value[0]?.value),
          );
          if (micro.length) {
            return micro.map((option) => {
              const { value, label, subsectorId } = option;
              return { value, label, subsectorId };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.microsectors.map((option) => {
            const { value, label, subsectorId } = option;
            return { value, label, subsectorId };
          });
        }
      default:
        return [];
    }
  };

  const customSort = (
    a: CategoricValueFilters,
    b: CategoricValueFilters,
    selected: Array<string>,
  ) => {
    if (selected.includes(a.value)) {
      return -1;
    }
    if (selected.includes(b.value)) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="row px-1">
      <div className="col-12">
        <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
          <h5 className="black fw-bold text-uppercase mb-0 mr-2">{language.menuFilters.tenant}</h5>
          <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
        </div>
      </div>
      {advancedTenantFilters[NewsFiltersFrontKey.sectorId] &&
        availableTenantFilters.sector &&
        reduxFilters.tenant.categoric?.[NewsFiltersFrontKey.sectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.sectorId}
            entityKey={NewsFiltersEntityFrontKey.tenant}
            title={language.filters.tenantSector.title}
            placeholder={language.filters.tenantSector.placeholder}
            options={dependentOptionsMap(CategoricFiltersKey.sectors).sort((a, b) =>
              customSort(
                a,
                b,
                Array.isArray(reduxFilters.tenant.categoric?.sectorId.value)
                  ? (reduxFilters.tenant.categoric?.sectorId.value?.map(
                      (option) => option.value,
                    ) as Array<string>)
                  : [],
              ),
            )}
            isClearable={true}
            value={reduxFilters.tenant.categoric?.sectorId.value}
            isMulti
            isDisabled={!dependentOptionsMap(CategoricFiltersKey.sectors).length}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.tenantSector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => {
                return (
                  <components.Option {...props}>
                    <div className="d-flex align-items-center">
                      <Pill
                        style={{ display: "flex", alignItems: "center", padding: "2px 6px" }}
                        color={defaultColors.background}
                        backgroundColor={sectorColorsMapper({
                          sectorName: props.data.name ?? null,
                          sectorColor: parcelColors.sectors,
                          fillColor: true,
                        })}
                        borderColor={sectorColorsMapper({
                          sectorName: props.data.name ?? null,
                          sectorColor: parcelColors.sectors,
                          fillColor: true,
                        })}>
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            backgroundColor: props.isSelected
                              ? "white"
                              : sectorColorsMapper({
                                  sectorName: props.data.name ?? null,
                                  sectorColor: parcelColors.sectors,
                                  fillColor: true,
                                }),
                            border: `1.5px solid white`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          {props.isSelected ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{
                                color: sectorColorsMapper({
                                  sectorName: props.data.name ?? null,
                                  sectorColor: parcelColors.sectors,
                                  fillColor: true,
                                }),
                                fontSize: "12px",
                              }}
                            />
                          ) : null}
                        </div>
                        <p className="mb-0 ml-05">
                          {languageSector(props.data.name ? props.data.name : props.data.label)}
                        </p>
                      </Pill>
                    </div>
                  </components.Option>
                );
              },
              MultiValueContainer: ({ children, ...props }) => {
                const {
                  data: { label, value, name },
                  selectProps: { value: selectedValues },
                } = props;
                return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
                  <Pill
                    style={{ padding: "4px 6px" }}
                    color={defaultColors.background}
                    backgroundColor={sectorColorsMapper({
                      sectorName: name ?? null,
                      sectorColor: parcelColors.sectors,
                      fillColor: true,
                    })}
                    borderColor={sectorColorsMapper({
                      sectorName: name ?? null,
                      sectorColor: parcelColors.sectors,
                      fillColor: true,
                    })}>
                    {languageSector(name ? name : label) +
                      (selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``)}
                  </Pill>
                ) : null;
              },
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}
      {advancedTenantFilters[NewsFiltersFrontKey.subsectorId] &&
        availableTenantFilters.subsector &&
        reduxFilters.tenant.categoric?.[NewsFiltersFrontKey.subsectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.subsectorId}
            entityKey={NewsFiltersEntityFrontKey.tenant}
            title={language.filters.tenantSubsector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.subsectors).length
                ? language.filters.tenantSubsector.placeholder
                : language.filters.tenantSubsector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters.subsectors
                ? dependentOptionsMap(CategoricFiltersKey.subsectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(reduxFilters.tenant.categoric?.subsectorId.value)
                        ? (reduxFilters.tenant.categoric?.subsectorId.value?.map(
                            (option) => option.value,
                          ) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isClearable={true}
            value={reduxFilters.tenant.categoric?.subsectorId.value}
            isMulti
            isDisabled={
              !reduxFilters.tenant.categoric?.sectorId.value ||
              !dependentOptionsMap(CategoricFiltersKey.subsectors).length
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.tenantSubsector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}
      {advancedTenantFilters[NewsFiltersFrontKey.microsectorId] &&
        availableTenantFilters.microsector &&
        reduxFilters.tenant.categoric?.[NewsFiltersFrontKey.microsectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.microsectorId}
            entityKey={NewsFiltersEntityFrontKey.tenant}
            title={language.filters.tenantMicrosector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.microsectors).length
                ? language.filters.tenantMicrosector.placeholder
                : language.filters.tenantMicrosector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters.microsectors
                ? dependentOptionsMap(CategoricFiltersKey.microsectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(reduxFilters.tenant.categoric?.microsectorId.value)
                        ? (reduxFilters.tenant.categoric?.microsectorId.value?.map(
                            (option) => option.value,
                          ) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isClearable={true}
            value={reduxFilters.tenant.categoric?.microsectorId.value}
            isMulti
            isDisabled={
              !reduxFilters.tenant.categoric?.subsectorId.value ||
              !dependentOptionsMap(CategoricFiltersKey.microsectors).length
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.tenantMicrosector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}
    </div>
  );
};

export default TenantSection;
