import { store } from "@/app/store";
import { ColumnStackedChartOptions } from "@/models";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { LongNumberIntoAbbreviatedString } from "@/utilities";
import { AnalyticsRangeBackend } from "@/adapters";

export const responseAnalyticsColumnStackedChartAdapter = (
  data: AnalyticsRangeBackend[],
): ColumnStackedChartOptions => {
  const dataChart: ColumnStackedChartOptions = {
    series: [],
    categories: [],
    colors: [],
    total: 0,
  };

  const { themeName, organizationTheme } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);

  data.map(({ range, values }, index) => {
    dataChart.categories.push(
      `${
        range.min
          ? `${LongNumberIntoAbbreviatedString({
              value: range.min,
              abbreviation: range.max ? false : true,
            })}${range.max ? "-" : "+"}`
          : ""
      }${
        range.max
          ? `${LongNumberIntoAbbreviatedString({
              value: range.max,
              abbreviation: true,
            })}`
          : ""
      }`,
    );
    values.map(({ key, value }) => {
      const propertyTypeName = key;
      const isName = dataChart.series?.find((serie) => serie.name === propertyTypeName);

      if (!isName) {
        dataChart.series.push({
          name: propertyTypeName,
          data: Array(data.length).fill(0),
        });
        dataChart.colors.push(
          listingPropertyTypeColorsMapper({
            propertyTypeName: propertyTypeName,
            propertyTypeColor: activeTheme?.listingColors
              .propertyType as ThemeType["listingColors"]["propertyType"],
          }),
        );
      }
      dataChart.series.find((serie) => {
        if (serie.name === propertyTypeName) {
          serie.data[index] = Number(value);
        }
      });
    });
  });

  dataChart.series.map((serie) => {
    const sumatoria = serie.data.reduce((acc, curr) => acc + curr, 0); // Calcula la sumatoria de todos los valores en el array
    if (dataChart.total !== undefined) {
      dataChart.total += sumatoria;
    }

    // const porcentajes = serie.data.map((valor) => Number(((valor / sumatoria) * 100).toFixed(2))); // Calcula los porcentajes
    // serie.data = porcentajes;
  });

  return dataChart;
};
