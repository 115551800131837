import type { Listing, Parcel, ParcelCluster } from "@/models";
import { DatasetListingsBackendKey } from "@/keys";
import { convertGeoJson } from "@/utilities";

export const responseParcelsClustersAdapter = (parcels: Parcel[]): ParcelCluster[] => {
  const newParcelsClusters: ParcelCluster[] = [];

  if (parcels.length) {
    parcels.map((parcel) => {
      let listings: Listing[] = [];
      if (parcel.listings) {
        listings = parcel.listings.filter(
          (listing) => listing.dataset !== DatasetListingsBackendKey.duplicate,
        );
      }

      listings.sort((a, b) => a.genericListingId.localeCompare(b.genericListingId));

      newParcelsClusters.push({
        id: parcel.id,
        centroid: parcel.centroid,
        sector: parcel.sector,
        listings: listings.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1)),
        geometry: parcel.geometry
          ? convertGeoJson({
              geojson: parcel.geometry,
            })
          : null,
      });
    });
  }

  return newParcelsClusters;
};
