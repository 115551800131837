import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface BrochureState {
  downloadBrochureProgress: number;
  isLoadingBrochure: boolean;
}

// Define the initial state using that type
const initialState: BrochureState = {
  downloadBrochureProgress: 0,
  isLoadingBrochure: false,
};

export const brochureSlice = createSlice({
  name: "brochure",
  initialState,
  reducers: {
    setBrochureProgress: (state, action: PayloadAction<{ progress: number }>) => {
      state.downloadBrochureProgress = action.payload.progress;
      if (action.payload.progress === 100) {
        state.downloadBrochureProgress = 0;
      }
    },
    setBrochureIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoadingBrochure = action.payload.isLoading;
    },
  },
});

// These actions will dispatch in the app
export const { setBrochureProgress, setBrochureIsLoading } = brochureSlice.actions;

export const selectBrochure = (state: RootState) => state.brochure;

export default brochureSlice.reducer;
