import type { MemberOrganization } from "@/models";
import { MemberOrganizationBackend } from "@/adapters";

export const responseMembersOrganizationAdapter = (
  members: MemberOrganizationBackend[],
): MemberOrganization[] => {
  const newMembers: MemberOrganization[] = [];

  if (members.length) {
    members.map((member) => {
      newMembers.push({
        id: member.id,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        status: member.isVerificated,
        role: member.role,
      });
    });
  }

  return newMembers;
};
