import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PortalKey, TabKey } from "@/keys";
import { RootState } from "@/app/store";
import { Listing, Permit, Transaction, Tax, Tenant, Parcel, Property } from "@/models";
import { OnlyKeyPortal } from "@/redux/slices";

type DataResponse<T> = {
  data: T;
  isLoading: boolean;
};

// Define a type for the slice state
interface TableViewState {
  tabStateByPortal: {
    [key in PortalKey]: Array<{
      id: TabKey;
      columns: DataResponse<
        Array<Parcel | Listing | Transaction | Tenant | Tax | Permit | Property>
      >;
      toUpdate: boolean;
      count: DataResponse<number>;
      realCount?: DataResponse<number>;
      pagination: {
        page: number;
        itemsPerPage: number;
      };
      /*       filters: {
        includeOutliers: boolean;
      }; */
    }>;
  };
}

const partialInitialState = {
  toUpdate: true,
  count: { data: 0, isLoading: false },
  columns: { isLoading: false, data: [] },
  pagination: {
    page: 1,
    itemsPerPage: 20,
  },
  filters: {
    includeOutliers: false,
  },
};

// Define the initial state using that type
const initialState: TableViewState = {
  tabStateByPortal: {
    professional: [
      { id: TabKey.listings, realCount: { data: 0, isLoading: false }, ...partialInitialState },
      { id: TabKey.properties, ...partialInitialState },
      { id: TabKey.activeListings, ...partialInitialState },
      { id: TabKey.inactiveListings, ...partialInitialState },
      { id: TabKey.transactions, ...partialInitialState },
      { id: TabKey.tenants, ...partialInitialState },
      { id: TabKey.taxes, ...partialInitialState },
      { id: TabKey.permits, ...partialInitialState },
      { id: TabKey.units, ...partialInitialState },
      { id: TabKey.documents, ...partialInitialState },
    ],
    residential: [
      { id: TabKey.listings, ...partialInitialState },
      { id: TabKey.properties, ...partialInitialState },
    ],
    commercial: [{ id: TabKey.activeListings, ...partialInitialState }],
    investpr: [{ id: TabKey.featuredListings, ...partialInitialState }],
  },
};

export const tableViewSlice = createSlice({
  name: "tableView",
  initialState,
  reducers: {
    setUpdatedTab: (state, action: PayloadAction<{ portal: OnlyKeyPortal; tab: TabKey }>) => {
      const tab = state.tabStateByPortal[action.payload.portal].findIndex(
        (tab) => tab.id === action.payload.tab,
      );
      if (tab >= 0) {
        state.tabStateByPortal[action.payload.portal][tab].id = action.payload.tab;
        state.tabStateByPortal[action.payload.portal][tab].toUpdate = false;
      }
    },
    reloadAllTabs: (state, action: PayloadAction<{ portal: OnlyKeyPortal }>) => {
      const tabsArray = state.tabStateByPortal[action.payload.portal];
      tabsArray.forEach((tab) => {
        tab.toUpdate = true;
      });
      state.tabStateByPortal[action.payload.portal] = tabsArray;
    },
    setLoading: (
      state,
      {
        payload: { portal, tabkey, loading, isCount = false },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        loading: boolean;
        isCount?: boolean;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        if (isCount) {
          tab.count.isLoading = loading;
        } else {
          tab.columns.isLoading = loading;
        }
      }
    },
    setCount: (
      state,
      {
        payload: { portal, tabkey, count },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        count: number;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        tab.count.data = count;
      }
    },
    setRealCount: (
      state,
      {
        payload: { portal, tabkey, count },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        count: number;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab?.realCount) {
        tab.realCount.data = count;
      }
    },
    setColumns: (
      state,
      {
        payload: { portal, tabkey, columns },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        columns: Array<Parcel | Listing | Transaction | Tenant | Tax | Permit | Property>;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        tab.columns.data = columns;
      }
    },
    setPage: (
      state,
      {
        payload: { portal, tabkey, page },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        page: number;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.page = page;
      }
    },
    setItemsPerPage: (
      state,
      {
        payload: { portal, tabkey, itemsPerPage },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        itemsPerPage: number;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        tab.pagination.itemsPerPage = itemsPerPage;
      }
    },
    /*     setIncludeOutliers: (
      state,
      {
        payload: { portal, tabkey, value },
      }: PayloadAction<{
        portal: OnlyKeyPortal;
        tabkey: TabKey;
        value: boolean;
      }>,
    ) => {
      const tab = state.tabStateByPortal[portal].find((t) => t.id === tabkey);
      if (tab) {
        tab.filters.includeOutliers = value;
        tab.toUpdate = true;
        tab.pagination.page = 1;
      }
    }, */
  },
});

// These actions will dispatch in the app
export const {
  setUpdatedTab,
  reloadAllTabs,
  setLoading,
  setCount,
  setRealCount,
  setColumns,
  setPage,
  setItemsPerPage,
  /* setIncludeOutliers, */
} = tableViewSlice.actions;

export const selectTableView = (state: RootState) => state.tableview;

export default tableViewSlice.reducer;
