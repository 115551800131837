export enum StatisticsGroupByKey {
  days = "days",
  months = "months",
  years = "years",
}

export enum StatisticsKey {
  ALL = "ALL",
  RESIDENTIAL = "RESIDENTIAL",
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  BUSINESS = "BUSINESS",
  OFFICE = "OFFICE",
  WAREHOUSE = "WAREHOUSE",
  LOT = "LOT",
}

export enum NewsStatisticsKey {
  ALL = "ALL",
  RESIDENTIAL = "RESIDENTIAL",
  BUSINESS = "BUSINESS",
  OFFICE = "OFFICE",
  WAREHOUSE = "WAREHOUSE",
  LOT = "LOT",
}
