import { InputCheckbox } from "@/components/styledComponents";
import React, { ChangeEventHandler, MouseEventHandler, FocusEventHandler } from "react";
interface CheckBoxesVuexyCircleProps {
  name?: string;
  color: string;
  defaultChecked?: any;
  checked?: any;
  value: any;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  size?: string;
  icon: JSX.Element;
  label: JSX.Element | string;
  textTruncateLabel?: boolean;
}
function CheckBoxesVuexyCircle({
  name,
  color,
  defaultChecked,
  checked,
  value,
  disabled,
  onClick,
  onChange,
  onBlur,
  size,
  icon,
  label,
  textTruncateLabel,
}: CheckBoxesVuexyCircleProps) {
  return (
    <InputCheckbox color={color}>
      <input
        name={name}
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        disabled={disabled}
        onClick={onClick ? onClick : undefined}
        onChange={onChange ? onChange : undefined}
        onBlur={onBlur ? onBlur : undefined}
      />
      <span
        className={`vx-checkbox ${`vx-checkbox-circle vx-checkbox-${size ?? "md"}`}`}
        style={{
          width: size ?? "1rem !important",
          height: size ?? "1rem !important",
        }}>
        {value ? (
          <span
            className="vx-checkbox--check"
            style={{
              width: size ?? "1rem !important",
              height: size ?? "1rem !important",
            }}>
            {icon}
          </span>
        ) : null}
      </span>
      <span className={`${textTruncateLabel ? "text-truncate" : ""}`}>{label}</span>
    </InputCheckbox>
  );
}

export default CheckBoxesVuexyCircle;
