import React from "react";
import { Modal, UncontrolledTooltip } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ButtonXCirclePrimary } from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useGetListingPricePerSqftStatisticQuery } from "@/services";
import { StatisticsFiltersBackendKey } from "@/keys";
import AnalyticsEmpty from "@/components/analytics/AnalyticsEmpty";
import AreaChartSplineStatistics from "@/components/analytics/charts/AreaChart.spline.statistics";
import AnalyticsLoading from "@/components/analytics/AnalyticsLoading";
import { AreaChartOptions } from "@/models";
import { AlertCircle } from "react-feather";
import { useTheme } from "styled-components";
import { useConvertUnits, useLocaleCountryCode } from "@/hooks";
import { FakeTableContainer } from "@/views/pages/tableView/tables/fakeData";
import { useAppDispatch } from "@/app/hooks";
import { userIsLoggedIn } from "@/redux/actions";
import { countries } from "@/configs";

type ModalNewsStatisticsProps = {
  toggleModal: () => void;
  openModal: boolean;
  serieId: string | null;
  serieTitle: string;
};

function ModalNewsStatistics({
  toggleModal,
  openModal,
  serieId,
  serieTitle,
}: ModalNewsStatisticsProps) {
  const { t } = useTranslation("analytics");
  const translation = t("analytics", { returnObjects: true });
  const { countryCode } = useLocaleCountryCode();
  const { defaultColors, primary } = useTheme();
  const { languageBuiltAreaUnits } = useConvertUnits();
  const dispatch = useAppDispatch();

  const {
    data: listingsPricesPerSqft,
    error: errorListingsPricesPerSqft,
    isFetching: isFetchingListingsPricesPerSqft,
  } = useGetListingPricePerSqftStatisticQuery(
    {
      countryCode,
      name: "ListingsIDX",
    },
    {
      skip: !countries[countryCode].hasAccessToPlatform,
      selectFromResult: ({ data, error, isFetching }) => {
        if (data !== undefined) {
          const newData: AreaChartOptions = {
            series: [],
            colors: data.colors,
          };

          const findAll = data?.series.find((serie) => serie.id === serieId);

          if (findAll !== undefined) {
            newData.series.push({
              id: findAll.id,
              // color: findAll.color,
              filters: findAll.filters,
              name: t("analytics.listings.listingsPricePerSqft.tooltipName", {
                units: languageBuiltAreaUnits,
              }),
              data: findAll.data,
              updatedAt: findAll.updatedAt,
            });
            // newData.colors.push(findAll.color as string);
          }

          return { data: newData.series.length ? newData : data, error, isFetching };
        } else {
          return { data, error, isFetching };
        }
      },
    },
  );

  return (
    <Modal isOpen={openModal} className="modal-dialog-centered" toggle={toggleModal}>
      <div className="p-1 d-flex justify-content-between align-items-center">
        <div className="mr-1">
          <h5 className="fw-bold mr-1 mb-0" style={{ color: defaultColors.color }}>
            {serieTitle}{" "}
            <AlertCircle
              className="mr-1 cursor-pointer"
              size={16}
              style={{ color: primary.color }}
              id="info"
            />
          </h5>

          <UncontrolledTooltip placement="top" target="info">
            {translation.listings.listingsPricePerSqft.description}
          </UncontrolledTooltip>
        </div>

        <ButtonXCirclePrimary size="18px" margin="10px" onClick={() => toggleModal()}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </ButtonXCirclePrimary>
      </div>
      <FakeTableContainer hideData={!dispatch(userIsLoggedIn())}>
        <PerfectScrollbar className="px-1" options={{ suppressScrollX: true }}>
          {errorListingsPricesPerSqft ? (
            <AnalyticsEmpty title={translation.error.title} />
          ) : listingsPricesPerSqft?.series[0].data ? (
            listingsPricesPerSqft?.series[0].data.length ? (
              <AreaChartSplineStatistics
                showFilters
                showPercentages
                options={listingsPricesPerSqft}
                yaxisFormatter={translation.transactions.averageTransactionsPrice.yaxisFormatter}
                defaultFilter={StatisticsFiltersBackendKey["1year"]}
              />
            ) : (
              <AnalyticsEmpty title={translation.empty.title} />
            )
          ) : (
            <AnalyticsLoading />
          )}
        </PerfectScrollbar>
      </FakeTableContainer>
    </Modal>
  );
}

export default ModalNewsStatistics;
