import React from "react";
import { useDispatch } from "react-redux";
import { userIsLoggedIn } from "@/redux/actions";
import { ButtonBorderPrimary, sizeViewport } from "@/components/styledComponents";
import { selectAppConfig, selectGridView, setViewActive } from "@/redux/slices";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import CallToActionAtlasProfessionalSmall from "@/components/callToAction/CallToActionAtlasProfessionalSmall";

function BtnsFooter(props) {
  const { t } = useTranslation("translation");
  const translation = t("map", { returnObjects: true });

  const { windowWidth } = useAppSelector(selectAppConfig);

  const isMobile = windowWidth < sizeViewport.laptop;

  const { viewActive } = useAppSelector(selectGridView);
  const dispatch = useDispatch();
  const onClickGridView = () => {
    dispatch(setViewActive("grid"));
  };

  return (
    <div
      style={{
        display: !viewActive || !isMobile ? "inline-block" : "none",
        width: !isMobile ? "calc(30vw)" : "200px",
        minWidth: !isMobile ? "350px" : "100px",
        maxWidth: "400px",
      }}>
      <div className="card mb-0 p-05">
        {!dispatch(userIsLoggedIn()) && !isMobile && (
          <CallToActionAtlasProfessionalSmall topMessage="Subscribe to: " />
        )}
        <div className="d-flex">
          {!viewActive && isMobile && (
            <ButtonBorderPrimary
              className="listView-btn fw-bold d-flex align-items-center"
              disableBorderRadius
              style={{
                textAlign: "center",
                width: "100%",
                borderTopRightRadius: "0.3rem",
                borderBottomRightRadius: "0.3rem",
              }}
              onClick={() => {
                onClickGridView();
              }}>
              <span style={{ textTransform: "uppercase", marginTop: "2px" }}>
                {translation.gridView.title}
              </span>
            </ButtonBorderPrimary>
          )}
        </div>
      </div>
    </div>
  );
}
export default BtnsFooter;
