import React, { useState } from "react";
import { Navbar, Badge, Spinner } from "reactstrap";
import classnames from "classnames";
import Filters from "./filtersNavbar/Filters";
import MenuNavbar from "./menuNavbar/MenuNavbar";
import SearchNavbar from "./SearchNavbar";
import { ChevronLeft, ChevronRight, X } from "react-feather";
import moment from "moment";
import { userIsLoggedIn } from "@/redux/actions";

import { useDispatch } from "react-redux";
import {
  filterKey,
  setValueFilter,
  selectFilters,
  entityKey,
  typeFilterKey,
  resetFiltersByPortal,
  selectSearch,
  cleanSearch,
  setDeleteButtonWatcher,
  selectAppConfig,
  setViewActive,
} from "@/redux/slices";
import {
  CountryCodeKey,
  LanguageCodeKey,
  MapDrawnPolygonButtonId,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  OrganizationKey,
  PortalKey,
  SectorKey,
} from "@/keys";
import { appPaths, relativeAppPaths, getOrganization } from "@/configs";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { NavbarOverlay, sizeViewport } from "@/components/styledComponents";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import DrawingController from "@/components/mapControllers/DrawingController";
import classNames from "classnames";
import { useApplyFilters } from "@/hooks/filters/useApplyFilters";
import { useCategoryTranslations, useConvertUnits, useLocaleCountryCode } from "@/hooks";
import LogoNavbar from "./LogoNavbar";
import { useTheme } from "styled-components";
import RefreshFilters from "./RefreshFilters";

function ThemeNavbar({ portal, match, windowWidth, clickBlocked, history }) {
  const dispatch = useDispatch();
  const { primary } = useTheme();

  const isMobile = windowWidth < sizeViewport.laptop;

  const { t } = useTranslation("translation");
  const translation = t("navbar", { returnObjects: true });
  const { languageSector, languagelistingStatus } = useCategoryTranslations();
  const [navbarSearch, setNavbarSearch] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState(0);
  const [openMobileSearchbar, setOpenMobileSearchbar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const { languageBuiltAreaUnits, languageLandAreaUnits } = useConvertUnits();

  const { filtersByPortal, dropdownToggle } = useAppSelector(selectFilters);
  const { userInputSearch, loadingSearchIds } = useAppSelector(selectSearch);
  const { userMenuOpen } = useAppSelector(selectAppConfig);
  const { languageCode } = useLocaleCountryCode();

  const { applySearchFilters, removeFilter } = useApplyFilters();

  const removeFilters = () => {
    dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
    dispatch(resetFiltersByPortal({ portal }));

    dispatch(cleanSearch());

    applySearchFilters();
  };

  const clickNavbarSearch = (bool) => {
    setNavbarSearch(bool);
  };

  /*   const removeFilter = ({ filterName, entityFilterKey, typeFilterKey }) => {
      if (filterName === NewsFiltersFrontKey.drawnPolygon) {
        dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
      }
      const appliedFiltersByPortal =
        portal === PortalKey.professional
          ? filtersByPortal.professional.filters
          : portal === PortalKey.residential
            ? filtersByPortal.residential.filters
            : portal === PortalKey.commercial
              ? filtersByPortal.commercial.filters
              : filtersByPortal.investpr.filters;
  
      let value = null;
  
      if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
        value = {
          min: "",
          max: "",
        };
      }
  
      if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
        value = {
          min: null,
          max: null,
        };
      }
  
      if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
        value = false;
      }
  
      let filterChange = {
        ...appliedFiltersByPortal[entityFilterKey][typeFilterKey],
        [filterName]: { value },
      };
  
      if (filterName === NewsFiltersFrontKey.cityId) {
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.neighborhoodId]: { value: null },
        };
      }
  
      if (filterName === NewsFiltersFrontKey.sectorId) {
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.subsectorId]: { value: null },
          [NewsFiltersFrontKey.microsectorId]: { value: null },
        };
      }
  
      if (filterName === NewsFiltersFrontKey.subsectorId) {
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.microsectorId]: { value: null },
        };
      }
  
      if (filterName === NewsFiltersFrontKey.isFloodZone) {
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.isFloodZone]: { value: false },
        };
      }
  
      if (filterName === NewsFiltersFrontKey.drawnPolygon) {
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.drawnPolygon]: { value: null, appliedValue: null },
        };
      }
  
      // amenities match
      if (
        filterName === NewsFiltersFrontKey.amenity ||
        filterName === NewsFiltersFrontKey.amenityRoom ||
        filterName === NewsFiltersFrontKey.amenityView
      ) {
        const amenityIdValue =
          appliedFiltersByPortal[entityFilterKey][typeFilterKey][NewsFiltersFrontKey.amenityId]
            .appliedValue;
        const amenityValue =
          appliedFiltersByPortal[entityFilterKey][typeFilterKey][filterName].appliedValue;
  
        const adjustedValue = Array.isArray(amenityIdValue)
          ? amenityIdValue.filter((item) => !amenityValue.some(({ value }) => value === item.value))
          : null;
  
        const newValue = adjustedValue.length ? adjustedValue : null;
  
        filterChange = {
          ...filterChange,
          [NewsFiltersFrontKey.amenityId]: {
            value: newValue,
            appliedValue: newValue,
          },
        };
      }
  
      dispatch(
        setValueFilter({
          portal: portal,
          entity: entityFilterKey,
          filterTypeValue: {
            [typeFilterKey]: filterChange,
          },
        }),
      );
  
      // landArea && lotArea match
      if (filterName === NewsFiltersFrontKey.landArea) {
        const listingEntityKey = NewsFiltersEntityFrontKey.listing;
        const listingNameKey = NewsFiltersFrontKey.lotArea;
  
        const listingFilterChange = {
          ...appliedFiltersByPortal[listingEntityKey][typeFilterKey],
          [listingNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: listingEntityKey,
            filterTypeValue: {
              [typeFilterKey]: listingFilterChange,
            },
          }),
        );
      }
  
      // builtArea && totalArea match
      if (filterName === NewsFiltersFrontKey.builtArea) {
        const propertyEntityKey = NewsFiltersEntityFrontKey.property;
        const propertyNameKey = NewsFiltersFrontKey.totalArea;
  
        const propertyFilterChange = {
          ...appliedFiltersByPortal[propertyEntityKey][typeFilterKey],
          [propertyNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: propertyEntityKey,
            filterTypeValue: {
              [typeFilterKey]: propertyFilterChange,
            },
          }),
        );
      }
  
      // listing.price && transaction.transactionPrice  match
      if (
        filterName === NewsFiltersFrontKey.price &&
        entityFilterKey === NewsFiltersEntityFrontKey.listing
      ) {
        const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
        const transactionNameKey = NewsFiltersFrontKey.transactionPrice;
  
        const transactionFilterChange = {
          ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
          [transactionNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: transactionEntityKey,
            filterTypeValue: {
              [typeFilterKey]: transactionFilterChange,
            },
          }),
        );
      }
  
      // listing.pricePerBuiltSqm && transaction.pricePerBuiltSqm  match
      if (
        filterName === NewsFiltersFrontKey.pricePerBuiltSqm &&
        entityFilterKey === NewsFiltersEntityFrontKey.listing
      ) {
        const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
        const transactionNameKey = NewsFiltersFrontKey.pricePerBuiltSqm;
  
        const transactionFilterChange = {
          ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
          [transactionNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: transactionEntityKey,
            filterTypeValue: {
              [typeFilterKey]: transactionFilterChange,
            },
          }),
        );
      }
  
      // listing.pricePerLotSqm && transaction.pricePerLotSqm  match
      if (
        filterName === NewsFiltersFrontKey.pricePerLotSqm &&
        entityFilterKey === NewsFiltersEntityFrontKey.listing
      ) {
        const transactionEntityKey = NewsFiltersEntityFrontKey.transaction;
        const transactionNameKey = NewsFiltersFrontKey.pricePerLotSqm;
  
        const transactionFilterChange = {
          ...appliedFiltersByPortal[transactionEntityKey][typeFilterKey],
          [transactionNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: transactionEntityKey,
            filterTypeValue: {
              [typeFilterKey]: transactionFilterChange,
            },
          }),
        );
      }
  
      // parcel sector && propertyType / match
      if (
        filterName === NewsFiltersFrontKey.sectorId &&
        entityFilterKey === NewsFiltersEntityFrontKey.parcel
      ) {
        //--- propertyType
        const listingEntityKey = NewsFiltersEntityFrontKey.listing;
        const listingNameKey = NewsFiltersFrontKey.propertyType;
  
        const propertyFilterChange = {
          ...appliedFiltersByPortal[listingEntityKey][typeFilterKey],
          [listingNameKey]: { value },
        };
  
        dispatch(
          setValueFilter({
            portal: portal,
            entity: listingEntityKey,
            filterTypeValue: {
              [typeFilterKey]: propertyFilterChange,
            },
          }),
        );
      }
  
      applySearchFilters();
    }; */

  const badge = ({ filterName, entityFilterKey, typeFilterKey, value }) => {
    return (
      <Badge
        pill
        color="light-secondary"
        className={classNames("align-items-center navbar-box-shadow mr-05 filter-badge", {
          "d-none": value === undefined && filterName === NewsFiltersFrontKey.drawnPolygon,
          "d-flex": value !== undefined,
        })}
        key={filterName}>
        <p
          className={classnames("text-capitalize mb-0 black", {
            ["mr-05"]:
              filterName !== NewsFiltersFrontKey.isForSale &&
              filterName !== NewsFiltersFrontKey.isForRent &&
              filterName !== NewsFiltersFrontKey.active &&
              filterName !== NewsFiltersFrontKey.inactive &&
              filterName !== NewsFiltersFrontKey.isFloodZone,
            ["fw-bold"]:
              filterName === NewsFiltersFrontKey.isForSale ||
              filterName === NewsFiltersFrontKey.isForRent ||
              filterName === NewsFiltersFrontKey.active ||
              filterName === NewsFiltersFrontKey.inactive ||
              filterName === NewsFiltersFrontKey.isFloodZone ||
              filterName === NewsFiltersFrontKey.drawnPolygon,
          })}
          style={{
            lineHeight: "1.2",
          }}>{`${
          entityFilterKey === NewsFiltersEntityFrontKey.tenant
            ? translation.filters.badges.tenant[filterName]
            : entityFilterKey === NewsFiltersEntityFrontKey.listing &&
              filterName === NewsFiltersFrontKey.status
            ? translation.filters.badges.listing[filterName]
            : entityFilterKey === NewsFiltersEntityFrontKey.permit
            ? translation.filters.badges.permit[filterName]
            : entityFilterKey === NewsFiltersEntityFrontKey.transaction
            ? t(`navbar.filters.badges.transaction.${filterName}`, {
                builtAreaUnits: languageBuiltAreaUnits,
                landAreaUnits: languageLandAreaUnits,
              })
            : t(`navbar.filters.badges.${filterName}`, {
                builtAreaUnits: languageBuiltAreaUnits,
                landAreaUnits: languageLandAreaUnits,
              })
        }${
          filterName !== NewsFiltersFrontKey.isForSale &&
          filterName !== NewsFiltersFrontKey.isForRent &&
          filterName !== NewsFiltersFrontKey.active &&
          filterName !== NewsFiltersFrontKey.inactive &&
          filterName !== NewsFiltersFrontKey.isFloodZone &&
          filterName !== NewsFiltersFrontKey.drawnPolygon
            ? `:`
            : ``
        }`}</p>
        {typeFilterKey === NewsFiltersTypeFrontKey.numeric && (
          <>
            {value.min && (
              <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
                {`${translation.filters.badges.min}: ${value.min}`}
              </p>
            )}
            {value.min && value.max && (
              <p className="text-capitalize mb-0 mx-05" style={{ lineHeight: "1.2" }}>
                {`|`}
              </p>
            )}
            {value.max && (
              <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
                {`${translation.filters.badges.max}: ${value.max}`}
              </p>
            )}
          </>
        )}
        {typeFilterKey === NewsFiltersTypeFrontKey.categoric && (
          <p
            className="text-capitalize mb-0 black fw-bold"
            style={{ lineHeight: "1.2" }}
            title={
              Array.isArray(value)
                ? value
                    .map((v) =>
                      entityFilterKey === NewsFiltersEntityFrontKey.listing &&
                      filterName === NewsFiltersFrontKey.status
                        ? languagelistingStatus(v.label)
                        : v.label,
                    )
                    ?.join(" + ")
                : value?.label
            }>
            {Array.isArray(value)
              ? (filterName === NewsFiltersFrontKey.sectorId &&
                (entityFilterKey === NewsFiltersEntityFrontKey.parcel ||
                  entityFilterKey === NewsFiltersEntityFrontKey.tenant)
                  ? languageSector(value?.[0]?.name)
                  : entityFilterKey === NewsFiltersEntityFrontKey.listing &&
                    filterName === NewsFiltersFrontKey.status
                  ? languagelistingStatus(value?.[0]?.label)
                  : languageCode === LanguageCodeKey.en
                  ? value?.[0]?.label
                  : value?.[0]?.esLabel ?? value?.[0]?.label) +
                (value?.length > 1 ? ` +${value?.length - 1}` : ``)
              : languageCode === LanguageCodeKey.en
              ? value?.label
              : value?.esLabel ?? value?.label}
          </p>
        )}
        {typeFilterKey === NewsFiltersTypeFrontKey.date ? (
          <>
            <>
              {value.min && (
                <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
                  {`${translation.filters.badges.min}: ${moment(value.min).format(
                    languageCode === LanguageCodeKey.en ? "MM/DD/YYYY" : "DD/MM/YYYY",
                  )}`}
                </p>
              )}
              {value.min && value.max && (
                <p className="text-capitalize mb-0 mx-05" style={{ lineHeight: "1.2" }}>
                  {`|`}
                </p>
              )}
              {value.max && (
                <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
                  {`${translation.filters.badges.max}: ${moment(value.max).format(
                    languageCode === LanguageCodeKey.en ? "MM/DD/YYYY" : "DD/MM/YYYY",
                  )}`}
                </p>
              )}
            </>
          </>
        ) : null}
        {typeFilterKey === NewsFiltersTypeFrontKey.search && (
          <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
            {value}
          </p>
        )}
        {typeFilterKey === NewsFiltersTypeFrontKey.geometric &&
          filterName !== NewsFiltersFrontKey.drawnPolygon && (
            <p className="text-capitalize mb-0 black fw-bold" style={{ lineHeight: "1.2" }}>
              {`(${value.coordinates[1]}, ${value.coordinates[0]})`}
            </p>
          )}

        <X
          size={12}
          className="ml-05 cursor-pointer primary"
          id={filterName}
          onClick={() => {
            removeFilter({ filterName, entityFilterKey, typeFilterKey });
          }}
        />
      </Badge>
    );
  };

  const showFilters = () => {
    const appliedFiltersByPortal =
      portal === PortalKey.professional
        ? filtersByPortal.professional.filters
        : portal === PortalKey.residential
        ? filtersByPortal.residential.filters
        : portal === PortalKey.commercial
        ? filtersByPortal.commercial.filters
        : filtersByPortal.investpr.filters;

    const filtersBadgesData = [];
    let entity = entityKey;
    let type = typeFilterKey;
    let filter = filterKey;

    for (entity in appliedFiltersByPortal) {
      for (type in appliedFiltersByPortal[entity]) {
        for (filter in appliedFiltersByPortal[entity][type]) {
          if (
            (appliedFiltersByPortal[entity][type][filter].appliedValue !== undefined ||
              filter === NewsFiltersFrontKey.drawnPolygon) &&
            filter !== NewsFiltersFrontKey.amenityId &&
            filter !== NewsFiltersFrontKey.lotArea &&
            filter !== NewsFiltersFrontKey.totalArea &&
            filter !== NewsFiltersFrontKey.propertyType &&
            (entity !== NewsFiltersEntityFrontKey.transaction ||
              filter !== NewsFiltersFrontKey.transactionPrice) &&
            (entity !== NewsFiltersEntityFrontKey.transaction ||
              filter !== NewsFiltersFrontKey.pricePerBuiltSqm) &&
            (entity !== NewsFiltersEntityFrontKey.transaction ||
              filter !== NewsFiltersFrontKey.pricePerLotSqm)
          ) {
            filtersBadgesData.push({
              entityFilterKey: entity,
              typeFilterKey: type,
              filterName: filter,
              value: appliedFiltersByPortal[entity][type][filter].appliedValue,
            });
          }
        }
      }
    }
    const objectsWithValue = filtersBadgesData.length
      ? filtersBadgesData.filter((objeto) => objeto.value !== undefined)
      : null;

    return (
      <div
        className="map-search"
        style={{
          display:
            (match.path === appPaths.home.path || match.path === appPaths.tableView.path) &&
            (filtersBadgesData.length || userInputSearch.value)
              ? ""
              : "none",
        }}>
        <Tabs
          activeTab={activeFilterTab}
          onTabClick={(e, index) => {
            setActiveFilterTab(index);
          }}
          hideNavBtnsOnMobile={false}
          tabsContainerStyle={{
            alignItems: "center",
          }}
          leftNavBtnClassName="py-0 pl-0"
          rightNavBtnClassName="py-0 pr-0"
          rightBtnIcon={<ChevronRight size={20} style={{ color: "white" }} />}
          leftBtnIcon={<ChevronLeft size={20} style={{ color: "white" }} />}>
          {/* ------ Pill clear All Filters ------ */}
          {(objectsWithValue.length > 1 ||
            (objectsWithValue.length === 1 && userInputSearch.value)) && (
            <Tab style={{ padding: 0, height: "fit-content", width: "fit-content" }}>
              <div
                className="d-flex align-items-center navbar-box-shadow mr-05"
                style={{
                  backgroundColor: primary.color,
                  padding: "0.4em",
                  borderRadius: "10rem",
                  color: "white",
                  fontSize: "85%",
                  border: `1.5px solid ${primary.contrastColor}`,
                }}
                key={"clearFilters"}>
                <p
                  className="mb-0 fw-bold"
                  style={{
                    lineHeight: "1",
                    textTransform: "initial",
                    color: primary.contrastColor,
                  }}>
                  {translation.filters.navbarButtons.clearAllFilters}
                </p>

                <X
                  size={12}
                  className="ml-05 cursor-pointer"
                  style={{ color: primary.contrastColor }}
                  onClick={() => {
                    removeFilters();
                  }}
                />
              </div>
            </Tab>
          )}
          {userInputSearch.showPill ? (
            <Tab style={{ padding: 0, height: "fit-content", width: "fit-content" }}>
              <Badge
                pill
                color="light-secondary"
                className="d-flex align-items-center navbar-box-shadow mr-05 filter-badge"
                key={"userInputSearch"}>
                <p
                  className="text-capitalize mb-0 black mr-05"
                  style={{
                    lineHeight: "1.2",
                  }}>{`${translation.filters.search.badge}:`}</p>
                <p
                  className="mb-0 black fw-bold"
                  style={{ lineHeight: "1.2", textTransform: "initial" }}>
                  {`"${userInputSearch.value}"`}
                </p>
                {loadingSearchIds ? (
                  <Spinner
                    color="primary"
                    size="sm"
                    className="ml-05"
                    style={{ width: "0.869rem", height: "0.869rem" }}
                  />
                ) : (
                  <X
                    size={12}
                    className="ml-05 cursor-pointer primary"
                    onClick={() => {
                      dispatch(cleanSearch());
                      applySearchFilters();
                    }}
                  />
                )}
              </Badge>
            </Tab>
          ) : null}

          {filtersBadgesData.map((filterBadge) => (
            <Tab
              key={`${filterBadge.entityFilterKey}.${filterBadge.filterName}`}
              style={{ padding: 0, height: "fit-content", width: "fit-content" }}>
              {badge({
                entityFilterKey: filterBadge.entityFilterKey,
                typeFilterKey: filterBadge.typeFilterKey,
                filterName: filterBadge.filterName,
                value: filterBadge.value,
              })}
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  };

  const hideNavbar =
    match.path === relativeAppPaths().list(match.id) ||
    match.path === appPaths.news.path ||
    match.path === relativeAppPaths().propertyProfile(match.id) ||
    (match.path === relativeAppPaths().listingProfile(match.id) &&
      getOrganization().organization.key !== OrganizationKey.redatlas) ||
    match.path === appPaths.profile.path ||
    match.path === appPaths.billing.path ||
    match.path === appPaths.business.path;

  return (
    <React.Fragment>
      <div className="content-overlay" />
      {dropdownToggle || userMenuOpen ? <NavbarOverlay /> : null}
      <Navbar
        className="header-navbar navbar-expand-lg navbar navbar-with-menu bg-transparent fixed-top"
        style={{ minHeight: 0 }}>
        <div className="navbar-wrapper">
          <div className="navbar-container content p-0">
            <div
              className={classnames(
                "navbar-collapse d-flex justify-content-between align-items-center",
                {
                  absoluteNavbar: hideNavbar,
                },
              )}
              id="navbar-mobile">
              <div
                className={classNames("align-items-center animate-element fadeIn w-100", {
                  "d-none": hideNavbar,
                  "d-flex": !hideNavbar,
                })}
                style={{ zIndex: 5 }}>
                {isMobile ? (
                  <div className="w-100">
                    <div className="d-flex align-items-center navbar-box-shadow filter-navbar-box-light justify-content-start w-100">
                      <div
                        className={classNames("", {
                          "d-none": navbarSearch,
                        })}>
                        <Filters
                          applyFilters={applySearchFilters}
                          removeFilters={removeFilters}
                          portal={portal}
                          clickBlocked={clickBlocked}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                        />
                      </div>

                      <div
                        className={classNames("w-100 align-items-center", {
                          "d-none": openFilters,
                          "d-flex": !openFilters,
                        })}
                        style={{ height: "53px" }}>
                        <SearchNavbar
                          windowWidth={windowWidth}
                          setOpenMobileSearchbar={setOpenMobileSearchbar}
                          setNavbarSearch={clickNavbarSearch}
                          navbarSearch={navbarSearch}
                          portal={portal}
                          applyFilters={applySearchFilters}
                        />
                      </div>

                      <div
                        className={classNames(
                          `${
                            dispatch(userIsLoggedIn()) || !getOrganization().auth.login
                              ? "menu-navbar-user-box-light"
                              : "menu-navbar-box-light"
                          }`,
                          {
                            "d-none": navbarSearch || openFilters,
                          },
                        )}
                        style={{ zIndex: 5 }}>
                        <MenuNavbar
                          clickBlocked={clickBlocked}
                          windowWidth={windowWidth}
                          history={history}
                          match={match}
                          portal={portal}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                ) : (
                  <div className="w-100">
                    <div className="d-flex align-items-center navbar-box-shadow filter-navbar-box-light justify-content-start w-100">
                      <LogoNavbar windowWidth={windowWidth} />
                      <Filters
                        applyFilters={applySearchFilters}
                        removeFilters={removeFilters}
                        portal={portal}
                        clickBlocked={clickBlocked}
                        openFilters={openFilters}
                        setOpenFilters={setOpenFilters}
                      />
                      {getOrganization().filters[portal].search.show && (
                        <SearchNavbar
                          clickBlocked={clickBlocked}
                          windowWidth={windowWidth}
                          setNavbarSearch={clickNavbarSearch}
                          setOpenMobileSearchbar={setOpenMobileSearchbar}
                          navbarSearch={navbarSearch}
                          portal={portal}
                          applyFilters={applySearchFilters}
                          removeFilter={removeFilter}
                        />
                      )}

                      <DrawingController
                        id={MapDrawnPolygonButtonId.drawPolygonButton}
                        windowWidth={windowWidth}
                      />

                      <RefreshFilters removeFilters={removeFilters} />
                    </div>
                  </div>
                )}
              </div>

              {!openMobileSearchbar && !isMobile && (
                <div
                  style={{ width: "100%", height: "53.56px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    if (match.path === appPaths.tableView.path) {
                      dispatch(setViewActive());
                      setTimeout(() => {
                        history.push(appPaths.home.path);
                      }, 500);
                    } else {
                      history.push(appPaths.home.path);
                    }
                  }}
                />
              )}
              {!isMobile && !openMobileSearchbar && !openFilters && (
                <div
                  className={`logo d-flex align-items-center justify-content-end navbar-box-shadow  ${
                    dispatch(userIsLoggedIn()) || !getOrganization().auth.login
                      ? "menu-navbar-user-box-light"
                      : "menu-navbar-box-light"
                  }`}
                  style={{ zIndex: 5 }}>
                  <MenuNavbar
                    clickBlocked={clickBlocked}
                    windowWidth={windowWidth}
                    history={history}
                    match={match}
                    portal={portal}
                  />
                </div>
              )}
            </div>
          </div>
          {showFilters()}
        </div>
      </Navbar>
    </React.Fragment>
  );
}

export default ThemeNavbar;
