import { LuxuryScoreBackendType } from "@/adapters";

import { KeyofTypeofLuxuryScore, LuxuryScoreKey } from "@/keys";

export const luxuryScoreAdapter = (score: LuxuryScoreBackendType): KeyofTypeofLuxuryScore => {
  const luxuryScores = {
    [6]: LuxuryScoreKey.Q1,
    [5]: LuxuryScoreKey.Q2,
    [4]: LuxuryScoreKey.Q3,
    [3]: LuxuryScoreKey.Q4,
    [2]: LuxuryScoreKey.Q5,
    [1]: LuxuryScoreKey.Q6,
  };
  return luxuryScores[score] as KeyofTypeofLuxuryScore;
};

export const luxuryScoreAdapterReverse = (
  score: KeyofTypeofLuxuryScore,
): LuxuryScoreBackendType => {
  const luxuryScores = {
    [LuxuryScoreKey.Q1]: 6,
    [LuxuryScoreKey.Q2]: 5,
    [LuxuryScoreKey.Q3]: 4,
    [LuxuryScoreKey.Q4]: 3,
    [LuxuryScoreKey.Q5]: 2,
    [LuxuryScoreKey.Q6]: 1,
  };
  return luxuryScores[score] as LuxuryScoreBackendType;
};

export const luxuryScoreReverse = {
  [LuxuryScoreKey.Q1]: "6",
  [LuxuryScoreKey.Q2]: "5",
  [LuxuryScoreKey.Q3]: "4",
  [LuxuryScoreKey.Q4]: "3",
  [LuxuryScoreKey.Q5]: "2",
  [LuxuryScoreKey.Q6]: "1",
};
