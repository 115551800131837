import { ParcelBackendKeys } from "./parcel-backend.key";
import { PermitSectorBackendKeys } from "./sector-backend.key";
import { SourceBackendKeys } from "./source-backend.key";
import { PermitSubsectorBackendKeys } from "./subsector-backend.key";

export enum PermitBackendKey {
  id = "id",
  address = "address",
  addressJSON = "addressJSON",
  agency = "agency",
  name = "name",
  category = "category",
  status = "status",
  date = "date",
  procedure = "procedure",
  url = "url",
  case = "case",
  description = "description",
  owner = "owner",
  publicPrivate = "publicPrivate",
  permitType = "permitType",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
}

export type PermitBackendKeys = keyof typeof PermitBackendKey;

export enum PermitBackendSortKey {
  // id = "id",
  address = "address",
  // addressJSON = "addressJSON",
  // agency = "agency",
  name = "name",
  // category = "category",
  status = "status",
  date = "date",
  // procedure = "procedure",
  // url = "url",
  // case = "case",
  // description = "description",
  // owner = "owner",
  // publicPrivate = "publicPrivate",
  // permitType = "permitType",
  // sectorId = "sectorId", // Inconsistent SortKey
  // subsectorId = "subsectorId", // Inconsistent SortKey
}

export enum PermitBackendExpandKey {
  parcel = "parcel",
  source = "source",
  sector = "sector",
  subsector = "subsector",
}

export type PermitBackendExpandKeys = keyof typeof PermitBackendExpandKey;

export type PermitBackendInclude =
  | (PermitBackendKeys | "*")
  | `${PermitBackendExpandKey}.${ParcelBackendKeys}`
  | `${PermitBackendExpandKey}.${SourceBackendKeys}`
  | `${PermitBackendExpandKey}.${PermitSectorBackendKeys}`
  | `${PermitBackendExpandKey}.${PermitSubsectorBackendKeys}`;
