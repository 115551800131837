import { BackendInclude, FlexibleRequestFiltersBackend, RequestFiltersBackend } from "@/adapters";
import { removeDays } from "@/utilities";

type requestFiltersOrAdapterProps = {
  requestFilters: FlexibleRequestFiltersBackend<BackendInclude>;
};

export const requestFiltersOrAdapter = ({
  requestFilters,
}: requestFiltersOrAdapterProps): FlexibleRequestFiltersBackend<BackendInclude> => {
  const newRequest: FlexibleRequestFiltersBackend<BackendInclude> = requestFilters;

  if (requestFilters?.filters?.length) {
    const hasDaysOnMarketFilter = requestFilters.filters[0].listing?.numeric?.daysOnMarket;
    const isActiveFilterSet = Array.isArray(requestFilters.filters[0].listing?.boolean?.active)
      ? requestFilters.filters[0].listing?.boolean?.active.includes(true)
      : requestFilters.filters[0].listing?.boolean?.active === undefined ||
        requestFilters.filters[0].listing?.boolean?.active !== false;

    /**
     * La siguiente lógica solo es necesaria cuando la búsqueda incluye listings activos, ya que solo los listings inactivos tienen daysOnMarket.
     * Si la búsqueda solo permite traer listings inactivos, entonces no se necesita esta lógica.
     */
    if (hasDaysOnMarketFilter && isActiveFilterSet) {
      let currentFilters = structuredClone(requestFilters.filters[0]); // Se hace una copia por valor usando la utilidad `structuredClone` que evita que la copia se haga por referencia.

      const minDaysOnMarket = currentFilters.listing?.numeric?.daysOnMarket.min;
      const createdAtMax = currentFilters.listing?.date?.createdAt.max;

      // Verificar si el filtro de días mínimos en el mercado está definido
      if (minDaysOnMarket !== undefined) {
        const currentDate = new Date();
        const minCreatedAt = removeDays({ date: currentDate, numOfDates: minDaysOnMarket });

        // Calcular la nueva fecha máxima teniendo en cuenta createdAtMax
        // Los listings activos que cumplen con el mínimo de días en el mercado del filtro son los que se crearon máximo hasta hace esos mismos días de la fecha actual.
        // Por ejemplo: Si son mínimo 3 días en el mercado, entonces los listings activos que cumplen con el filtro son los que se crearon máximo hasta hace 3 días.
        const newMaxDate = createdAtMax
          ? new Date(Math.min(createdAtMax.getTime(), minCreatedAt.getTime()))
          : minCreatedAt;

        // Actualizar los filtros con la nueva fecha máxima
        currentFilters = {
          ...currentFilters,
          listing: {
            ...currentFilters.listing,
            date: {
              ...currentFilters.listing?.date,
              createdAt: {
                ...currentFilters.listing?.date?.createdAt,
                max: newMaxDate,
              },
            },
          },
        };
      }

      // Verificar si hay un filtro de días máximos en el mercado
      const maxDaysOnMarket = currentFilters.listing?.numeric?.daysOnMarket.max;
      const createdAtMin = currentFilters.listing?.date?.createdAt.min;
      if (maxDaysOnMarket !== undefined) {
        const currentDate = new Date();
        const maxCreatedAt = removeDays({ date: currentDate, numOfDates: maxDaysOnMarket });

        // Calcular la nueva fecha mínima teniendo en cuenta createdAtMin
        // Los listings activos que cumplen con el máximo de días en el mercado del filtro son los que se crearon después de hace esos mismos días de la fecha actual.
        // Por ejemplo: Si son máximo 5 días en el mercado, entonces los listings activos que cumplen con el filtro son los que se crearon después de hace 5 días.
        const newMinDate = createdAtMin
          ? new Date(Math.max(createdAtMin.getTime(), maxCreatedAt.getTime()))
          : maxCreatedAt;

        // Actualizar los filtros con la nueva fecha mínima
        currentFilters = {
          ...currentFilters,
          listing: {
            ...currentFilters.listing,
            date: {
              ...currentFilters.listing?.date,
              createdAt: {
                ...currentFilters.listing?.date?.createdAt,
                min: newMinDate,
              },
            },
          },
        };
      }

      if (currentFilters.listing?.numeric?.daysOnMarket) {
        // Los listing activos no tienen daysOnMarket, además este es el motivo por el que estamos agregando este filtro OR, así que se debe quitar este filtro de aquí.
        delete currentFilters.listing.numeric.daysOnMarket;
        // Agregar el filtro de active en true, evita que se cuelen listings inactivos que cumplen la condición anterior del filtro de createdAt.
        currentFilters = {
          ...currentFilters,
          listing: {
            ...currentFilters.listing,
            boolean: {
              ...currentFilters.listing.boolean,
              active: true,
            },
          },
        };
      }
      newRequest?.filters?.push(currentFilters);
    }
    // case to parcel name OR parcel govtId (FILTER SEARCH NAVBAR)
    if (requestFilters.filters[0].parcel?.search?.name) {
      newRequest?.filters?.push({
        parcel: {
          search: {
            govtId: requestFilters.filters[0].parcel?.search?.name,
          },
        },
      });
    }
  }

  return newRequest;
};
