import React from "react";
import { OptionType } from "@/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupBase, MultiValueGenericProps, PlaceholderProps, components } from "react-select";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { OptionProps } from "react-select";
import { useTheme } from "styled-components";

export const CustomPlaceholder: React.ComponentType<
  PlaceholderProps<OptionType, true, GroupBase<OptionType>>
> = ({ children, ...props }) => (
  <components.Placeholder {...props}>
    <div className="d-flex align-items-center">
      <FontAwesomeIcon
        icon={faSearch}
        className="mr-05"
        style={{ fontSize: "16px", color: "primary.color" }}
        data-tour="search"
      />
      <p
        className="mb-0 text-overflow-ellipsis"
        style={{
          maxWidth: "160px",
        }}>
        {children}
      </p>
    </div>
  </components.Placeholder>
);

export const CustomOption: React.ComponentType<
  OptionProps<OptionType, true, GroupBase<OptionType>>
> = ({ children, ...props }) => {
  const { primary } = useTheme();
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          style={{
            border: `1px solid ${primary.color}`,
            width: "20px",
            height: "20px",
            cursor: "pointer",
            accentColor: primary.color,
          }}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.selectOption(props.data);
          }}
        />
        <span className="ml-05">{children}</span>
      </div>
    </components.Option>
  );
};

export const CustomMultiValueLabel: React.ComponentType<
  MultiValueGenericProps<OptionType, true, GroupBase<OptionType>>
> = ({ children, ...props }) => {
  const {
    data: { label, value },
    selectProps: { value: selectedValues },
  } = props;
  return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
    <components.MultiValueLabel {...props}>
      <div className="d-flex align-items-center" style={{ maxWidth: "160px" }}>
        <div className="text-overflow-ellipsis">{label}</div>
        <span className="fw-bold">
          {selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``}
        </span>
      </div>
    </components.MultiValueLabel>
  ) : null;
};
