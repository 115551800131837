import { Pill } from "@/components/styledComponents";
import {
  CountryCodeKey,
  ListingPropertyTypeBackendKey,
  ListingStatusBackendKey,
  NewsFiltersEntityFrontKey,
} from "@/keys";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";
import notImg from "@/assets/img/slider/notImg.jpg";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useTheme } from "styled-components";
import { useCategoryTranslations, useLocaleCountryCode } from "@/hooks";
import { listingStatusColorsColorsMapper } from "@/redux/mappers/listingStatusColorsMapper";
import { GroupedSuggestion } from "../SearchNavbar";

type ListingSuggestionProps = {
  item: GroupedSuggestion;
};

const ListingSuggestion: FC<ListingSuggestionProps> = ({
  item: { id, title, price, img, address, active, isForRent, propertyType, status },
}) => {
  const { t } = useTranslation(["listing", "translation"]);
  const translationListing = t("listing:listing", { returnObjects: true });
  const { languageListingPropertyType, languagelistingStatus } = useCategoryTranslations();
  const { listingColors, defaultColors } = useTheme();
  const { countryCode } = useLocaleCountryCode();
  const isResidential =
    propertyType === ListingPropertyTypeBackendKey.HOUSE ||
    propertyType === ListingPropertyTypeBackendKey.APARTMENT;

  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": !!img,
      })}>
      <div className="item-container d-flex w-100">
        {img ? (
          <img
            className="img-list mt-25"
            src={img}
            height="60"
            width="60"
            alt={title ?? undefined}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = notImg;
            }}
          />
        ) : null}
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}>
          <div className="w-100">
            <div className="d-flex align-items-center">
              <h6
                className="align-middle mb-0 fw-bold black"
                id={`nameResultAddress-${id}`}
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}>
                {address}
              </h6>
              {price ? (
                <div className="d-flex flex-column align-items-end ml-1">
                  <div className="mt-05 d-flex flex-column align-items-end justify-content-end">
                    <h4 className="fw-bold black" style={{ whiteSpace: "nowrap", marginBottom: 0 }}>
                      {price}
                    </h4>
                    {isForRent && typeof price !== "string" && (
                      <small style={{ marginLeft: "0.2rem" }}>
                        {countryCode === CountryCodeKey.PR
                          ? isResidential
                            ? translationListing.monthlyLease
                            : translationListing.annualLease
                          : translationListing.monthlyLease}
                      </small>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <UncontrolledTooltip placement="bottom" target={`nameResultAddress-${id}`}>
              {address}
            </UncontrolledTooltip>

            <div className="d-flex align-items-center mt-05">
              {propertyType ? (
                <div>
                  <Pill
                    className="mr-05"
                    color={defaultColors.background}
                    backgroundColor={listingPropertyTypeColorsMapper({
                      propertyTypeName: propertyType,
                      propertyTypeColor: listingColors.propertyType,
                    })}
                    borderColor={listingPropertyTypeColorsMapper({
                      propertyTypeName: propertyType,
                      propertyTypeColor: listingColors.propertyType,
                    })}>
                    <p className="mb-0 text-overflow-ellipsis">
                      {languageListingPropertyType(propertyType)}
                    </p>
                  </Pill>
                </div>
              ) : null}
              <div>
                <Pill
                  className="mr-05"
                  color={defaultColors.background}
                  backgroundColor={
                    active ? listingColors.status.active : listingColors.status.inactive
                  }
                  borderColor={
                    active ? listingColors.status.active : listingColors.status.inactive
                  }>
                  <p className="mb-0 text-overflow-ellipsis">
                    {active ? translationListing.active : translationListing.inactive}
                  </p>
                </Pill>
              </div>
              {status ? (
                <div>
                  <Pill
                    color={defaultColors.background}
                    backgroundColor={listingStatusColorsColorsMapper({
                      statusName: status,
                      statusColor: listingColors.status,
                    })}
                    borderColor={listingStatusColorsColorsMapper({
                      statusName: status,
                      statusColor: listingColors.status,
                    })}>
                    <p className="mb-0 text-overflow-ellipsis">{languagelistingStatus(status)}</p>
                  </Pill>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingSuggestion;
