import React from "react";
import { getDataSourceFromUrl } from "./dataSource-from-url.utility";

export const getImgDataSourceInvestpr = (url: string) => {
    const newDatasource = getDataSourceFromUrl(url);
  
    return (
      <img
        src={newDatasource?.logoUrl}
        alt={newDatasource?.name}
        className="dataSourceLogoGridView"
      />
    )
  };