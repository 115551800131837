import { City } from "@/models";
import { CityBackend } from "@/adapters";
import { convertGeoJson } from "@/utilities";

export const responseCityAdapter = (city: CityBackend): City => ({
  value: city.id,
  label: city.name,
  geometry: city.geometry
    ? convertGeoJson({
        geojson: city.geometry,
      })
    : null,
});
