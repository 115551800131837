import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface ParcelState {
  parcelId?: string;
  parcelCenter?: { lat: number; lng: number };
}

// Define the initial state using that type
const initialState: ParcelState = {
  parcelId: undefined,
  parcelCenter: undefined,
};

export const parcelSlice = createSlice({
  name: "parcel",
  initialState,
  reducers: {
    setParcel: (
      state,
      action: PayloadAction<{ parcelId?: string }>,
    ) => {
      state.parcelId = action.payload.parcelId;
    },
    setParcelCenter: (
      state,
      action: PayloadAction<{ parcelCenter?: { lat: number; lng: number } }>,
    ) => {
      state.parcelCenter = action.payload.parcelCenter;
    },
  },
});

// These actions will dispatch in the app
export const { setParcel, setParcelCenter } = parcelSlice.actions;

export const selectParcel = (state: RootState) => state.parcel;

export default parcelSlice.reducer;
