export enum SortDirectionKey {
  asc = "asc",
  desc = "desc",
}

export enum SortDirectionBackendKey {
  ASC = "ASC",
  DESC = "DESC",
}

export type KeyofSortDirectionKey = keyof typeof SortDirectionKey;
export type KeyofSortDirectionBackendKey = keyof typeof SortDirectionBackendKey;
