import { Tenant } from "@/models";
import { TenantBackend, responseTenantAdapter } from "@/adapters";

export const responseTenantsAdapter = (tenants: TenantBackend[]): Tenant[] => {
  const newTenants: Tenant[] = [];

  if (tenants.length) {
    tenants.map((tenant) => newTenants.push(responseTenantAdapter(tenant)));
  }
  return newTenants;
};
