import { BuildingBackend } from "../models/building.backend.model";
import { Building } from "@/models/locations/building.model";

export const responseBuildingsAdapter = (buildings: BuildingBackend[]): Building[] => {
  const data: Building[] = [];
  if (buildings.length) {
    buildings.map((building) => {
      data.push({
        id: building.id,
        name: building.name.startsWith("Urbanización ")
          ? building.name.replace("Urbanización ", "")
          : building.name,
      });
    });
  }

  return data;
};
