import React, { useState, ReactElement } from "react";
import { CategoricFilter, FilterValue, NumericFilter, OptionType } from "@/models";
import { Check } from "react-feather";
import { Filters, OnlyKeyEntityFilter } from "@/redux/slices";
import { NewsFiltersFrontKey, NewsFiltersTypeFrontKey } from "@/keys";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  CheckIcon,
  CheckboxContainer,
  CheckboxLabel,
  HiddenCheckbox,
  RadioButtonComponent,
  RadioButtonGroup,
  StyledCheckbox,
} from "./RadioButton.styledComponent";
import { useFilterSelect } from "@/hooks";
import { UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";

type FilterNumericType = {
  filter: FilterValue<NumericFilter>;
  name: NewsFiltersFrontKey;
  entity: OnlyKeyEntityFilter;
  type: Extract<NewsFiltersTypeFrontKey, NewsFiltersTypeFrontKey.numeric>;
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
};

type FilterCategoricType = {
  filter: FilterValue<CategoricFilter>;
  name: NewsFiltersFrontKey;
  entity: OnlyKeyEntityFilter;
  type: Extract<NewsFiltersTypeFrontKey, NewsFiltersTypeFrontKey.categoric>;
  reduxFilters: Filters;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
};

type FilterType = FilterNumericType | FilterCategoricType;

interface RadioButtonProps {
  label: string;
  exactMatch: {
    defaultValue: boolean;
    show: boolean;
  };
  options: OptionType[];
  showTooltip?: boolean;
  tooltipLabel?: ReactElement;
  isAdvancedFilters: boolean;
  filter: FilterType;
}

function RadioButton({
  label,
  exactMatch,
  options,
  showTooltip,
  tooltipLabel,
  isAdvancedFilters,
  filter,
}: RadioButtonProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.filters", { returnObjects: true });
  const { common } = useTheme();
  const { handleChange } = useFilterSelect();
  const [exactMatchValue, setExactMatchValue] = useState<boolean>(
    filter.type === NewsFiltersTypeFrontKey.numeric
      ? filter.filter.value.min.toString().length === 0 ||
        filter.filter.value.max.toString().length === 0
        ? false
        : filter.filter.value.min === filter.filter.value.max
        ? true
        : exactMatch.defaultValue
      : exactMatch.defaultValue,
  );

  const handleCheckboxChange = () => {
    if (filter.type === NewsFiltersTypeFrontKey.numeric) {
      if (!exactMatchValue) {
        filter.handleMinMaxChange({
          name: filter.name,
          value: { min: filter.filter.value.min, max: filter.filter.value.min },
          entity: filter.entity,
          type: filter.type,
        });
      } else {
        filter.handleMinMaxChange({
          name: filter.name,
          value: { min: filter.filter.value.min, max: "" },
          entity: filter.entity,
          type: filter.type,
        });
      }
      setExactMatchValue(!exactMatchValue);
    }
  };

  const handleClick = ({ option }: { option: OptionType }) => {
    if (filter.type === NewsFiltersTypeFrontKey.numeric) {
      if (filter.filter.value.min === option.value) {
        filter.handleMinMaxChange({
          name: filter.name,
          value: { min: "", max: "" },
          entity: filter.entity,
          type: filter.type,
        });
      } else {
        if (exactMatchValue) {
          filter.handleMinMaxChange({
            name: filter.name,
            value: { min: option.value, max: option.value },
            entity: filter.entity,
            type: filter.type,
          });
        } else {
          filter.handleMinMaxChange({
            name: filter.name,
            value: { min: option.value, max: "" },
            entity: filter.entity,
            type: filter.type,
          });
        }
      }
    }
    if (filter.type === NewsFiltersTypeFrontKey.categoric) {
      if (!Array.isArray(filter.filter.value)) {
        let inputValue: OptionType | null = null;
        if (filter.filter.value?.value !== option.value) {
          inputValue = {
            value: option.value,
            label: option.label,
          };
        } else {
          inputValue = null;
        }
        handleChange(
          inputValue,
          {
            action: "select-option",
            option: undefined,
            name: filter.name,
          },
          filter.entity,
          filter.reduxFilters,
        );
      }
    }
  };

  const handleBlur = () => {
    if (filter.type === NewsFiltersTypeFrontKey.categoric) {
      filter.setLocalFilters(filter.reduxFilters);
    }
  };

  return (
    <div
      className={classNames("col-12 mb-1", {
        "col-xl-3": isAdvancedFilters,
        "col-md-4": isAdvancedFilters,
        "col-xl-6": !isAdvancedFilters,
        "col-lg-6": !isAdvancedFilters,
        "col-md-6": !isAdvancedFilters,
        "col-sm-12": !isAdvancedFilters,
      })}>
      <div className="d-flex align-items-center justify-content-between mb-05">
        {tooltipLabel ? (
          <div className="d-flex align-items-center">
            {tooltipLabel}
            <h6 className="fw-bold black mb-0">{label}</h6>
          </div>
        ) : (
          <h6 className="mb-0 fw-bold black ml-1">{label}</h6>
        )}
        {exactMatch.show ? (
          <CheckboxContainer>
            <HiddenCheckbox checked={exactMatchValue} onChange={handleCheckboxChange} />
            <StyledCheckbox checked={exactMatchValue} onChange={handleCheckboxChange}>
              <CheckIcon checked={exactMatchValue}>
                <Check style={{ width: "14px", height: "14px", color: common.white }} />
              </CheckIcon>
            </StyledCheckbox>
            <CheckboxLabel>{language.useExactMatch}</CheckboxLabel>
          </CheckboxContainer>
        ) : null}
      </div>

      <RadioButtonGroup>
        {options.map((option) => (
          <RadioButtonComponent
            key={`${filter.name}${filter.entity}${option.value}`}
            id={`${filter.name}-${option.value}`}
            onClick={() => handleClick({ option })}
            onBlur={() => handleBlur()}
            active={
              filter.type === NewsFiltersTypeFrontKey.numeric
                ? filter.filter.value.min === option.value
                : filter.filter.value
                ? (filter.filter.value as OptionType).value === option.value
                : false
            }>
            {exactMatchValue ? option.label : `${option.label}+`}
            {showTooltip && option.tooltip && (
              <UncontrolledTooltip placement="bottom" target={`${filter.name}-${option.value}`}>
                {option.tooltip}
              </UncontrolledTooltip>
            )}
          </RadioButtonComponent>
        ))}
      </RadioButtonGroup>
    </div>
  );
}

export default RadioButton;
