import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { themeConfig } from '@/configs';

const layouts = {
  mainLayout: MainLayout,
};

const ContextLayout = React.createContext();

class Layout extends React.Component {
  state = {
    activeLayout: themeConfig.layout,
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction,
    portal: themeConfig.portal,
  };

  updateWidth = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  handleWindowResize = () => {
    this.updateWidth();
    // if (this.state.activeLayout === "mainLayout" && this.state.width <= 1199) {
    //   this.setState({
    //     activeLayout: "vertical",
    //     lastLayout: "mainLayout",
    //   });
    // }

    // if (this.state.lastLayout === "mainLayout" && this.state.width >= 1199) {
    //   this.setState({
    //     activeLayout: "mainLayout",
    //     lastLayout: "vertical",
    //   });
    // }
  };

  componentDidMount = () => {
    // if (window !== "undefined") {
    //   window.addEventListener("resize", this.handleWindowResize);
    // }
    // this.handleDirUpdate();
    // if (this.state.activeLayout === "mainLayout" && this.state.width <= 1199) {
    //   this.setState({
    //     activeLayout: "vertical",
    //   });
    // } else if (
    //   themeConfig.layout === "mainLayout" &&
    //   this.state.width >= 1200
    // ) {
    //   this.setState({
    //     activeLayout: "mainLayout",
    //   });
    // } else {
    //   this.setState({
    //     activeLayout: "vertical",
    //   });
    // }
  };

  componentDidUpdate() {
    this.handleDirUpdate();
  }

  handleDirUpdate = () => {
    let dir = this.state.direction;
    if (dir === 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  };

  render() {
    const { children } = this.props;
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          mainLayout: layouts['mainLayout'],
          switchLayout: (layout) => {
            this.setState({ activeLayout: layout });
          },
          switchDir: (dir) => {
            this.setState({ direction: dir });
          },
          switchPortal: (portal) => {
            this.setState({ portal: portal });
          },
        }}>
        {children}
      </ContextLayout.Provider>
    );
  }
}

export { Layout, ContextLayout };
