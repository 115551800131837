import React from "react";
import {
  ButtonBorderPrimary,
  ButtonPrimary,
  ButtonXCirclePrimary,
  Pill,
  baseStyles,
} from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import CarouselBasic from "@/components/carousel/CarouselBasic";
import { Listing } from "@/models";
import moment from "moment";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";
import { capitalizeFirstLetterOfEachWord, NumberFormat } from "@/utilities";
import { getImgDataSourceInvestpr } from "@/utilities/investpr/imgDataSource-investpr.utility";

// icons
import RentableArea from "@/assets/img/icons/wide.png";
import Bedroom from "@/assets/img/icons/bedroom.png";
import Bathroom from "@/assets/img/icons/bathroom.png";
import LandIcon from "@/assets/img/icons/land.png";
import { countries, getOrganization } from "@/configs";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight, PlusCircle } from "react-feather";
import { CountryCodeKey, ListingPropertyTypeBackendKey, OrganizationKey } from "@/keys";
import {
  InfowindowListing,
  TypeInfowindow,
  selectMap,
  setDuplicatedListings,
  setInfowindow,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import iconLinkedProp from "@/assets/img/icons/linked-prop.png";
import { useHistory } from "react-router-dom";
import useRouteQueryString from "@/hooks/queryString/useRouteQueryString.hook";
import { useCategoryTranslations, useConvertUnits, useLocaleCountryCode } from "@/hooks";
import classNames from "classnames";
import { FakeTableContainer } from "@/views/pages/tableView/tables/fakeData";
import { listingStatusColorsColorsMapper } from "@/redux/mappers/listingStatusColorsMapper";

type ListingInfowindowProps = {
  listing: Listing;
  hideContent?: boolean;
  onCloseIndowindow: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  clickLocationProfile: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  toggleListsModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handlePrevListing: () => void;
  handleNextListing: () => void;
};

function ListingInfowindow({
  listing,
  hideContent,
  onCloseIndowindow,
  clickLocationProfile,
  toggleListsModal,
  handlePrevListing,
  handleNextListing,
}: ListingInfowindowProps) {
  const { t } = useTranslation(["translation"]);
  const translation = t("translation:map.infoWindow", { returnObjects: true });
  const { languageListingPropertyType, languagelistingStatus } = useCategoryTranslations();
  const { primary, defaultColors, listingColors, common } = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { appPropertyProfileQueryString } = useRouteQueryString();
  const {
    infowindow: { countListings, listings, parcelId, listingWithParcel },
  } = useAppSelector(selectMap);
  const { countryCode } = useLocaleCountryCode();
  const {
    convertLandArea,
    convertBuiltArea,
    convertPricePerBuiltArea,
    languageBuiltAreaUnits,
    languageLandAreaUnits,
    languagePerBuiltAreaUnits,
    languagePerLandAreaUnits,
  } = useConvertUnits();

  const isResidential =
    listing.propertyType === ListingPropertyTypeBackendKey.HOUSE ||
    listing.propertyType === ListingPropertyTypeBackendKey.APARTMENT;

  const getListingPrice = () => {
    if (getOrganization().organization.key === OrganizationKey.redatlas) {
      if (listing.price) {
        return NumberFormat({
          value:
            !isResidential && listing.isForRent && countryCode === CountryCodeKey.PR
              ? Number(listing.price) * 12
              : listing.price,
          prefix: "$",
          suffix: "",
        });
      } else {
        return translation.noPrice;
      }
    } else if (listing.priceString) {
      return listing.priceString;
    } else if (listing.price) {
      return NumberFormat({
        value:
          !isResidential && listing.isForRent && countryCode === CountryCodeKey.PR
            ? Number(listing.price) * 12
            : listing.price,
        prefix: "$",
        suffix: "",
      });
    } else {
      return translation.priceUponRequest;
    }
  };

  const handleParcel = ({ id }: { id: string }) => {
    dispatch(
      setInfowindow({
        show: true,
        id,
        type: TypeInfowindow.parcel,
        countListings,
        listings,
        parcelId: id,
        activeListing: listings[0].active,
      }),
    );
  };

  const organizationIsRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        }}>
        {organizationIsRedAtlas && (
          <div className="p-05 d-flex align-items-center" style={{ marginRight: "30px" }}>
            {listingWithParcel ? (
              <>
                <ButtonBorderPrimary
                  disableBorderRadius
                  style={{
                    // padding: "8px 10px",
                    borderTopLeftRadius: "0.3rem",
                    borderBottomLeftRadius: "0.3rem",
                  }}
                  className="text-uppercase w-100"
                  onClick={() =>
                    parcelId
                      ? handleParcel({
                          id: parcelId,
                        })
                      : listing.parcel
                      ? handleParcel({
                          id: listing.parcel.id,
                        })
                      : null
                  }>
                  {translation.buttons.tabs.property}
                </ButtonBorderPrimary>

                <ButtonPrimary
                  disableBorderRadius
                  style={{
                    // padding: "8px 10px",
                    borderTopRightRadius: "0.3rem",
                    borderBottomRightRadius: "0.3rem",
                  }}
                  className="text-uppercase w-100">
                  {`${countListings} ${translation.buttons.tabs.listings}`}
                </ButtonPrimary>
              </>
            ) : (
              <div>
                <div className="py-05">{translation.noLinkedProperty}</div>
              </div>
            )}
          </div>
        )}
        {organizationIsRedAtlas && countries[countryCode].lists && (
          <div
            style={{
              position: "absolute",
              top: "52px",
              right: "0",
              zIndex: 2,
            }}>
            <ButtonPrimary
              borderRadius={`${baseStyles.borderRadius} 0 0 ${baseStyles.borderRadius}`}
              minWidth="40px"
              className={classNames("property-detail-btn-circle", {
                ["blur-content"]: hideContent,
              })}
              onClick={(e) => {
                if (!hideContent) toggleListsModal(e);
              }}>
              <PlusCircle size={20} />
            </ButtonPrimary>
          </div>
        )}
        <div
          className={classNames({
            ["blur-content"]: hideContent,
          })}>
          <CarouselBasic imgs={listing.photos?.length ? listing?.photos : []} />
        </div>
        <div
          className={classNames({
            ["blur-content"]: hideContent,
          })}
          style={{
            position: "absolute",
            top: organizationIsRedAtlas ? "52px" : "10px",
            backgroundColor: primary.color,
            padding: "5px",
            opacity: 0.8,
            borderTopRightRadius: "0.5rem",
          }}>
          <span className="font-weight-bold" style={{ color: primary.contrastColor }}>
            {listing?.createdAt ? moment().diff(listing.createdAt, "days") : "---"}
            {translation.createdAt}
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.8127844887955182) 0%, rgba(255,255,255,0) 100%)",
            width: "100%",
            height: "50%",
          }}
          className={`d-flex align-items-end p-1`}>
          <div className="d-flex" style={{ alignItems: "center", flexFlow: "wrap-reverse" }}>
            {listing.propertyType ? (
              <Pill
                className={classNames({
                  ["blur-content"]: hideContent,
                })}
                style={{
                  marginRight: "0.5rem",
                  marginTop: "0.5rem",
                }}
                color={defaultColors.background}
                backgroundColor={listingPropertyTypeColorsMapper({
                  propertyTypeName: listing.propertyType,
                  propertyTypeColor: listingColors.propertyType,
                })}
                borderColor={listingPropertyTypeColorsMapper({
                  propertyTypeName: listing.propertyType,
                  propertyTypeColor: listingColors.propertyType,
                })}>
                <p className="mb-0">{languageListingPropertyType(listing.propertyType) ?? "---"}</p>
              </Pill>
            ) : null}

            <Pill
              style={{
                marginRight: "0.5rem",
                marginTop: "0.5rem",
              }}
              color={defaultColors.background}
              backgroundColor={
                listing.active ? listingColors.status.active : listingColors.status.inactive
              }
              borderColor={
                listing.active ? listingColors.status.active : listingColors.status.inactive
              }>
              {listing.active
                ? translation.listingStatus.active
                : translation.listingStatus.inactive}
            </Pill>

            {listing.status ? (
              <Pill
                style={{
                  marginRight: "0.5rem",
                  marginTop: "0.5rem",
                }}
                color={defaultColors.background}
                backgroundColor={listingStatusColorsColorsMapper({
                  statusName: listing.status,
                  statusColor: listingColors.status,
                })}
                borderColor={listingStatusColorsColorsMapper({
                  statusName: listing.status,
                  statusColor: listingColors.status,
                })}>
                {languagelistingStatus(listing.status)}
              </Pill>
            ) : null}

            {listing?.parcel?.id ? (
              <div
                className="mb-0 cursor-pointer"
                style={{
                  marginRight: "0.5rem",
                  marginTop: "0.5rem",
                }}>
                <img
                  src={iconLinkedProp}
                  alt="linked-prop"
                  width="20"
                  // id="iconLinkedProp"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setDuplicatedListings({
                        uniqueListingId: undefined,
                        duplicatedListingsView: false,
                      }),
                    );
                    listing?.parcel?.id
                      ? history.push(appPropertyProfileQueryString({ id: listing.parcel.id }))
                      : null;
                  }}
                />
                {/* TODO: esto lo descomente porq a veces al renderizar de nuevo no encontraba el id del tooltip */}
                {/* <UncontrolledTooltip placement="bottom" target="iconLinkedProp">
                  {languageListing.buttons.linkedProperty}
                </UncontrolledTooltip> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <ButtonXCirclePrimary
        size="18px"
        margin="10px"
        onClick={(e) => onCloseIndowindow(e)}
        position="absolute"
        top="0"
        style={{
          zIndex: 1,
          backgroundColor: primary.contrastColor,
          borderRadius: "100%",
          height: "18px",
          width: "18px",
        }}>
        <FontAwesomeIcon icon={faCircleXmark} />
      </ButtonXCirclePrimary>

      <FakeTableContainer
        hideData={hideContent}
        smallCTA
        topMessage1={translation.notAllowedListing}>
        <div
          className={classNames({
            ["blur-content"]: hideContent,
          })}
          style={{ padding: "10px" }}>
          <div
            className="d-flex justify-content-between align-items-start flex-row-reverse flex-wrap"
            style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {getOrganization().organization.key === OrganizationKey.redatlas &&
            listing.uniqueListingId ? (
              <p
                className="text-bold-500 text-center mb-0 fw-bold"
                style={{
                  backgroundColor: common.gray,
                  padding: "0 3px",
                  color: primary.contrastColor,
                  whiteSpace: "nowrap",
                }}>
                {translation.multipleSources}
              </p>
            ) : getOrganization().organization.key === OrganizationKey.investpr ? (
              listing.url ? (
                getImgDataSourceInvestpr(listing.url)
              ) : null
            ) : listing?.dataSource?.logoUrl ? (
              <img
                src={listing?.dataSource?.logoUrl}
                alt={listing?.dataSource?.name}
                className="dataSourceLogoGridView"
              />
            ) : (
              <p className="text-bold-500 text-truncate mb-0">
                {listing?.dataSource?.name
                  ? capitalizeFirstLetterOfEachWord({ string: listing?.dataSource?.name })
                  : null}
              </p>
            )}
            <div className="mr-auto">
              <div className="d-flex align-items-center">
                <h3 className="font-weight-bold mb-0 mr-auto" style={{ color: "black" }}>
                  {getListingPrice()}
                </h3>
                {listing.isForRent && listing.price && (
                  <small style={{ marginLeft: "0.2rem" }}>
                    {countryCode === CountryCodeKey.PR
                      ? isResidential
                        ? translation.monthly
                        : translation.annually
                      : translation.monthly}
                  </small>
                )}
              </div>
              <small
                className="fw-bold"
                style={{ position: "relative", top: "-2px", fontSize: "11.66px" }}>
                {listing.propertyType !== ListingPropertyTypeBackendKey.LOT ? (
                  listing.pricePerBuiltSqm ? (
                    NumberFormat({
                      value: convertPricePerBuiltArea({
                        value:
                          !isResidential && listing.isForRent && countryCode === CountryCodeKey.PR
                            ? listing.pricePerBuiltSqm * 12
                            : listing.pricePerBuiltSqm,
                      }),
                      prefix: "$ ",
                      decimalScale: 2,
                      suffix: ` ${languagePerBuiltAreaUnits}`,
                    })
                  ) : (
                    <div
                      style={{
                        height: "20px",
                      }}>{`$ --- ${languagePerBuiltAreaUnits}`}</div>
                  )
                ) : listing.price &&
                  listing.lotArea &&
                  (convertLandArea({ value: listing.lotArea }) || 0) > 0 ? (
                  NumberFormat({
                    value:
                      !isResidential && listing.isForRent && countryCode === CountryCodeKey.PR
                        ? (Number(listing.price) /
                            (convertLandArea({ value: listing.lotArea }) || 0)) *
                          12
                        : Number(listing.price) /
                          (convertLandArea({ value: listing.lotArea }) || 0),
                    prefix: "$ ",
                    decimalScale: 2,
                    suffix: ` ${languagePerLandAreaUnits}`,
                  })
                ) : (
                  <div
                    style={{
                      height: "20px",
                    }}>{`$ --- ${languagePerLandAreaUnits}`}</div>
                )}
              </small>
            </div>
          </div>

          {listing.propertyType !== ListingPropertyTypeBackendKey.LOT &&
          listing.propertyType !== ListingPropertyTypeBackendKey.WAREHOUSE ? (
            <div className="d-flex flex-wrap">
              <div className="d-flex align-items-center mr-1">
                <img src={Bedroom} alt={"bedrooms"} width={14} height={14} className="mr-05" />
                <p className="mb-0 fw-bold" style={{ marginTop: "2px" }}>
                  {listing.rooms ?? "---"}
                </p>
              </div>

              <div className="d-flex align-items-center mr-1">
                <img src={Bathroom} alt={"bathrooms"} width={14} height={14} className="mr-05" />
                <p className="mb-0 fw-bold" style={{ marginTop: "2px" }}>
                  {listing.toilets ?? "---"}
                </p>
              </div>

              {listing.amenities?.length ? (
                <div className="d-flex align-items-center mr-1">
                  <p className="mb-0" style={{ marginTop: "2px" }}>
                    <b>{`+ ${listing.amenities?.length} `}</b>
                    {translation.amenities}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="d-flex flex-wrap">
            {listing.propertyType !== ListingPropertyTypeBackendKey.LOT &&
            listing.propertyType !== ListingPropertyTypeBackendKey.WAREHOUSE ? (
              <div className="d-flex align-items-center mr-1 cursor-pointer">
                <img
                  src={RentableArea}
                  alt="RentableArea"
                  width={14}
                  height={14}
                  className="mr-05"
                />
                <p className="mb-0" style={{ marginTop: "2px" }}>
                  {listing.builtArea
                    ? NumberFormat({
                        value: convertBuiltArea({ value: listing.builtArea }),
                        prefix: "",
                        suffix: ` ${languageBuiltAreaUnits}`,
                      })
                    : "---"}
                </p>
              </div>
            ) : null}
            <div className="d-flex align-items-center mr-1-custom cursor-pointer">
              <img src={LandIcon} alt="lotArea" width={14} height={14} className="mr-05" />
              <p className="mb-0" style={{ marginTop: "2px" }}>
                {listing.lotArea
                  ? NumberFormat({
                      value: convertLandArea({ value: listing.lotArea }),
                      prefix: "",
                      suffix: ` ${languageLandAreaUnits}`,
                      decimalScale: 3,
                    })
                  : "---"}
              </p>
            </div>
          </div>

          <p title={listing.address} className="mb-0 text-overflow-ellipsis">
            {listing.address}
          </p>

          <div className="row mt-1">
            <div className="col-12 d-flex justify-content-center">
              <ButtonPrimary
                style={getOrganization().lists ? { marginRight: "5px" } : undefined}
                width="100%"
                paddingY="6px"
                onClick={(e) => clickLocationProfile(e)}>
                {translation.buttons.listingProfile}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </FakeTableContainer>

      {countListings > 1 && (
        <div
          className="d-flex align-items-center justify-content-between navbar-infowindow-multiple"
          style={{
            backgroundColor: "#e5e5e5",
            padding: "5px",
            color: `rgba(68,68,68,0.8)`,
          }}>
          <div className="button-infowindow-multiple mr-05" onClick={() => handlePrevListing()}>
            <ChevronLeft size={16} />
          </div>

          <div className="fw-bold fs-13" style={{ color: `rgba(68,68,68,0.8)` }}>
            {t("map.infoWindow.countListingsLocation", {
              count: listings.findIndex((l) => l.id === listing.id) + 1,
              countListingsInfowindow: countListings,
            })}
          </div>
          <div className="button-infowindow-multiple ml-05" onClick={() => handleNextListing()}>
            <ChevronRight size={16} />
          </div>
        </div>
      )}
    </>
  );
}

export default ListingInfowindow;
