import React from "react";
import { Modal } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ButtonXCirclePrimary } from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";

type ModalDisclaimerProps = {
  toggleModal: () => void;
  openModal: boolean;
};

function ModalDisclaimer({ toggleModal, openModal }: ModalDisclaimerProps) {
  const { t } = useTranslation("analytics");
  const translation = t("analytics.disclaimer", { returnObjects: true });

  const closeModal = () => {
    toggleModal();
  };

  return (
    <Modal isOpen={openModal} className="modal-dialog-centered">
      <div className="p-1 d-flex justify-content-between align-items-center">
        <div className="w-100">
          <h5 className="text-center mb-0 fw-bold black text-uppercase">{translation.title}</h5>
        </div>

        <ButtonXCirclePrimary size="18px" margin="10px" onClick={() => closeModal()}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </ButtonXCirclePrimary>
      </div>

      <PerfectScrollbar
        className="p-1 mb-05"
        options={{
          wheelPropagation: false,
        }}
        style={{ maxHeight: "calc(100vh - 240px)" }}>
        <div className="text-justify">
          <Trans
            defaults={translation.description}
            components={{ lineBreak: <br />, bold: <b></b> }}
          />
        </div>
      </PerfectScrollbar>
    </Modal>
  );
}

export default ModalDisclaimer;
