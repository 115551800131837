import { City } from "@/models";
import { CityBackend, responseCityAdapter } from "@/adapters";

export const responseCitiesAdapter = (cities: CityBackend[]): City[] => {
  const data: City[] = [];
  if (cities.length) {
    cities.map((city) => {
      data.push(responseCityAdapter(city));
    });
  }

  return data;
};
