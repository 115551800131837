import type { FiltersTypeBackend } from "@/adapters";
import { NewsFiltersFrontKey } from "@/keys";
import type { FiltersType } from "@/models";

export const responseFiltersAdapter = (filter: FiltersTypeBackend | null): FiltersType => {
  const listingFilters: FiltersType = {};

  if (filter?.numeric !== undefined) {
    filter.numeric.map(
      (index: string) =>
        (listingFilters.numeric = {
          ...listingFilters.numeric,
          [index]: { value: { min: "", max: "" } },
        }),
    );
  }

  if (filter?.boolean !== undefined) {
    filter.boolean.map((index: string) => {
      let filters = {
        ...listingFilters.boolean,
        [index]: { value: false },
      };
      if (index === NewsFiltersFrontKey.active) {
        filters = {
          ...filters,
          [NewsFiltersFrontKey.inactive]: { value: false },
        };
      }
      if (index === NewsFiltersFrontKey.isForRent) {
        filters = {
          ...filters,
          [NewsFiltersFrontKey.isForSale]: { value: false },
        };
      }
      if (index === NewsFiltersFrontKey.isFloodZone) {
        filters = {
          ...filters,
          [NewsFiltersFrontKey.isFloodZone]: { value: false },
        };
      }

      return (listingFilters.boolean = filters);
    });
  }

  if (filter?.categoric !== undefined) {
    filter.categoric.map(
      (index: string) =>
        (listingFilters.categoric = {
          ...listingFilters.categoric,
          [index]: { value: null },
        }),
    );
  }

  if (filter?.date !== undefined) {
    filter.date.map(
      (index: string) =>
        (listingFilters.date = {
          ...listingFilters.date,
          [index]: {
            value: {
              min: null,
              max: null,
            },
          },
        }),
    );
  }

  if (filter?.search !== undefined) {
    filter.search.map(
      (index: string) =>
        (listingFilters.search = {
          ...listingFilters.search,
          [index]: { value: "" },
        }),
    );
  }

  if (filter?.geometric !== undefined) {
    filter.geometric.map(
      (index: string) =>
        (listingFilters.geometric = {
          ...listingFilters.geometric,
          [index]: { value: null },
        }),
    );
  }

  return listingFilters;
};
