import { AddressJSONBackend } from "@/adapters";
import { AddressJSONDataTypeBackendKey } from "@/keys";
import { AddressJSON } from "@/models";

export const responseParcelAddressesJSONAdapter = (
  addresses: AddressJSONBackend[],
): AddressJSON[] => {
  const newAddresses: AddressJSON[] = [];

  if (addresses.length) {
    addresses.map((address) => {
      if (address.streetName?.value) {
        newAddresses.push({
          id: address.id,
          dataType: address.dataType,
          buildingName: address.buildingName,
          joinedAddress: address.joinedAddress,
          original: address.original,
          sourceId: address.sourceId,
          streetName: address.streetName,
          streetNumber: address.streetNumber,
          unitNumber: address.unitNumber,
          unitType: address.unitType,
          zipCode: address.zipCode,
        });
      }
    });
  }

  return newAddresses;
};
