import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import gptSearchIcon from "@/assets/img/icons/features/gpt-search.png";
import { Spinner } from "reactstrap";

type ChatGptSuggestionProps = {
  isLoadingSearchFilters: boolean;
  gptNoResultsToShow: boolean;
};

function ChatGptSuggestion({ isLoadingSearchFilters, gptNoResultsToShow }: ChatGptSuggestionProps) {
  const { t } = useTranslation("translation");
  const languageGptSearch = t("navbar.filters.gptSuggestions", { returnObjects: true });
  const translationNavbar = t("navbar", { returnObjects: true });
  const { primary, secondary, common } = useTheme();

  return (
    <>
      {gptNoResultsToShow && !isLoadingSearchFilters ? (
        <>
          <div className="d-flex">
            <p className="suggestion-title text-bold-600 text-uppercase mr-1">
              {translationNavbar.filters.menuFilters.gptSuggestions}
            </p>
          </div>
          <p className="text-wrap-pretty">{languageGptSearch.noResults}</p>
        </>
      ) : (
        <div className="d-flex justify-content-between align-items-center w-100 ">
          <div className="d-flex align-items-center flex-wrap" style={{ color: primary.color }}>
            <div className="d-flex justify-content-between align-items-baseline ">
              <img className="mr-05" src={gptSearchIcon} style={{ height: "20px" }} />
              <p
                className="fw-bold text-center text-uppercase mr-1"
                style={{ fontSize: "16px", margin: 0 }}>
                {languageGptSearch.title}
              </p>
            </div>
            <small
              className="font-italic"
              style={{ fontSize: "11px", color: primary.color, marginTop: "0.3rem" }}>
              {languageGptSearch.clickHere}
            </small>
          </div>
          <div className="h-100">
            {isLoadingSearchFilters && <Spinner size="sm" style={{ color: secondary.color }} />}
          </div>
        </div>
      )}
    </>
  );
}

export default ChatGptSuggestion;
