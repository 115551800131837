import {
  PropertyBackend,
  responseListingsAdapter,
  responseParcelAdapter,
  responsePropertyTransactionsAdapter,
  responseTaxesAdapter,
} from "@/adapters";
import { Property } from "@/models";

export const responsePropertyAdapter = (property: PropertyBackend): Property => {
  const newProperty: Property = {
    id: property.id,
    address: property.address ?? "---",
    addressJSON: property.addressJSON ?? null,
    builtArea: property.totalArea,
    geometry: property.geometry,
    frontage: property.frontage,
    transactions: property.propertyTransactions
      ? responsePropertyTransactionsAdapter(property.propertyTransactions)
      : [],
    govtId: property.govtId ? property.govtId : "",
    toilets: property.toilets,
    rooms: property.rooms,
    parkingSpaces: property.parkingSpaces,
    amenity: property.amenity,
    sourceId: property.sourceId,
    source: property.source,
    cityId: property.cityId,
    city: property.city ? { value: property.city.id, label: property.city.name } : null,
    neighborhoodId: property.neighborhoodId,
    neighborhood: property.neighborhood
      ? { value: property.neighborhood.id, label: property.neighborhood.name }
      : null,
    parcelId: property.parcelId,
    parcel: property.parcel ? responseParcelAdapter(property.parcel) : null,
    taxes: property.taxes ? responseTaxesAdapter(property.taxes) : null,
    listings: property.listings ? responseListingsAdapter(property.listings) : null,
    unitType: property.unitType === "Edifice" ? "Building" : property.unitType,

    // Estos datos salen de addressJSON
    unitNumber: property.addressJSON?.unitNumber ? property.addressJSON?.unitNumber.value : null,

    // Estos datos se estan agregando en front
    lastTransactionPrice: 0,
    lastTransactionTotalAreaPricePerSqm: 0,
  };

  // Calculate last transaction price, price per sqm & buyerName
  if (property?.propertyTransactions?.length && property.propertyTransactions !== undefined) {
    const transactions: {
      date: Date;
      price: number;
      buyerName: string;
    }[] = [];

    property.propertyTransactions.map((transaction) => {
      if (transaction?.transaction?.transactionDate) {
        transactions.push({
          date: transaction?.transaction.transactionDate,
          price: transaction?.transaction.transactionPrice
            ? Number(transaction?.transaction?.transactionPrice)
            : 0,
          buyerName: transaction?.transaction.buyerName ? transaction?.transaction.buyerName : "",
        });
      }
    });

    if (transactions.length) {
      const maxDate = transactions.reduce((a, b) => {
        return new Date(a.date) > new Date(b.date) ? a : b;
      });

      if (maxDate && maxDate.price) {
        //------ last transaction price
        newProperty.lastTransactionPrice = maxDate.price;
        //------ buyer name
        newProperty.buyerName = maxDate.buyerName;

        //------ Price per sqm
        if (maxDate.price && property.totalArea) {
          const totalAreaPrice = maxDate.price / Number(property.totalArea);
          newProperty.lastTransactionTotalAreaPricePerSqm = totalAreaPrice;
        }
      }
    }
  }

  return newProperty;
};
