export enum DatasetListingsBackendKey {
  test = "test",
  train = "train",
  outlier = "outlier",
  incomplete = "incomplete",
  duplicate = "duplicate",
  sublisting = "sublisting",
  evaluate = "evaluate",
  // parent = "parent", // TODO: solución temporal que agregaron en datos para separar los padres de los hijos
}

export enum DatasetTransactionsBackendKey {
  outlier = "outlier",
  clean = "clean",
  incomplete = "incomplete",
  duplicate = "duplicate",
}
