import axios, { AxiosResponse } from "axios";
import { URL_BACKEND } from "@/config";
import { CountryCodeBackendKey, CountryCodeKey, useEndpoint } from "@/keys";
import { AppThunk } from "@/app/store";
import {
  CityAvailableValuationBackend,
  CityBackend,
  CountryBackend,
  CountryCodeBackend,
  NeighborhoodBackend,
  SubNeighborhoodBackend,
  ZipCodeBackend,
  responseCitiesAdapter,
  responseCitiesAvailableValuationsAdapter,
  responseCityAdapter,
  responseCountriesAdapter,
  responseCountryCodeAdapter,
  responseNeighborhoodsAdapter,
  responseSubNeighborhoodsAdapter,
} from "@/adapters";
import {
  City,
  CityAvailableValuation,
  CountryCode,
  CountryOptionType,
  Neighborhood,
  SubNeighborhood,
  ZipCode,
} from "@/models";
import {
  setCities,
  setNeighborhoods,
  setSubNeighborhoods,
  setCountries,
  setCitiesAvailableValuations,
  updateNeighborhoods,
} from "@/redux/slices";
import { api } from "../api";
import { responseZipCodesAdapter } from "@/adapters/responses/locations/zip-codes.adapter";
import { BuildingBackend } from "@/adapters/responses/models/building.backend.model";
import { Building } from "@/models/locations/building.model";
import { responseBuildingsAdapter } from "@/adapters/responses/locations/buildings.adapter";

const locationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountryCodeByIP: builder.query<CountryCode, void>({
      query: () => {
        return {
          url: useEndpoint().localize(),
          method: "get",
        };
      },
      transformResponse: (response: { data: CountryCodeBackend }): CountryCode =>
        responseCountryCodeAdapter(response.data),
    }),
    getCity: builder.query<
      City,
      {
        cityId: string;
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ cityId, countryCode }) => {
        return {
          url: useEndpoint().city({ countryCode: CountryCodeBackendKey[countryCode], cityId }),
          method: "get",
        };
      },
      transformResponse: (response: { data: CityBackend[] }) =>
        responseCitiesAdapter(response.data)?.[0],
    }),
    getZipCodes: builder.query<
      ZipCode[],
      {
        zipCode: string;
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ zipCode, countryCode }) => {
        return {
          url: useEndpoint().zipCode({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "get",
          params: { zipCode },
        };
      },
      transformResponse: (response: { data: ZipCodeBackend[] }) =>
        responseZipCodesAdapter(response.data),
    }),
    getBuildings: builder.query<
      Building[],
      {
        name: string;
        type: string;
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ name, type, countryCode }) => {
        return {
          url: useEndpoint().building({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "get",
          params: { name, type },
        };
      },
      transformResponse: (response: { data: BuildingBackend[] }) =>
        responseBuildingsAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetCountryCodeByIPQuery,
  useLazyGetZipCodesQuery,
  useLazyGetBuildingsQuery,
  useLazyGetCityQuery,
} = locationApi;

export const getCountries = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: CountryBackend[] }, { data: CountryOptionType[] }> =
        await axios.get(`${URL_BACKEND}${useEndpoint().country()}`);

      const countries = responseCountriesAdapter(response.data.data);

      dispatch(setCountries({ countries }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCities = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: CityBackend[] }, { data: City[] }> = await axios.get(
        `${URL_BACKEND}${useEndpoint().cities({
          countryCode: CountryCodeBackendKey[countryCode],
        })}`,
      );

      const cities = responseCitiesAdapter(response.data.data);

      dispatch(setCities({ cities }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCitiesAvailableValuations = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<
        { data: CityAvailableValuationBackend[] },
        { data: CityAvailableValuation[] }
      > = await axios.get(`${URL_BACKEND}${useEndpoint().citiesAvailableValuations()}`);

      const citiesAvailableValuations = responseCitiesAvailableValuationsAdapter(
        response.data.data,
      );

      dispatch(setCitiesAvailableValuations({ citiesAvailableValuations }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getNeighbordhoods = ({
  cityId,
  countryCode,
}: {
  cityId: string;
  countryCode: CountryCodeKey;
}): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: NeighborhoodBackend[] }, { data: Neighborhood[] }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().neighborhood({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            params: { cityId },
          },
        );

      const neighborhoods = responseNeighborhoodsAdapter(response.data.data);
      dispatch(setNeighborhoods({ neighborhoods }));

      const fetchSubNeighborhoods = async (neighborhoodIds: string[]) => {
        return dispatch(getSubNeighbordhoods({ neighborhoodIds: neighborhoodIds, countryCode }));
      };

      if (countryCode === CountryCodeKey.PR) {
        const neighborhoodIds = neighborhoods.map((neighborhood) => neighborhood.value);
        await fetchSubNeighborhoods(neighborhoodIds);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSubNeighbordhoods = ({
  neighborhoodIds,
  countryCode,
}: {
  neighborhoodIds: string[];
  countryCode: CountryCodeKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response: AxiosResponse<
        { data: SubNeighborhoodBackend[] },
        { data: SubNeighborhood[] }
      > = await axios.post(
        `${URL_BACKEND}${useEndpoint().subNeighborhood({
          countryCode: CountryCodeBackendKey[countryCode],
        })}`,
        {
          neighborhoodIds,
        },
      );

      const subNeighborhoods = responseSubNeighborhoodsAdapter(response.data.data);

      if (countryCode === CountryCodeKey.PR) {
        const neighborhoods = getState().locations.neighborhoods;

        const newNeighborhoods = neighborhoods.concat(subNeighborhoods);
        newNeighborhoods.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        dispatch(updateNeighborhoods({ neighborhoods: newNeighborhoods }));
      } else {
        dispatch(setSubNeighborhoods({ subNeighborhoods }));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
