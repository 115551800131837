import {
  BodyExportFileBackend,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  RequestFiltersBackend,
  responseTaxesAdapter,
  TaxBackend,
} from "@/adapters";
import { AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { defaultFilters } from "@/configs";
import {
  CountryCodeBackendKey,
  TabKey,
  useEndpoint,
  TaxBackendKey,
  TaxBackendExpandKey,
  PropertyBackendKey,
  SortDirectionBackendKey,
  KeyofSortDirectionBackendKey,
  ParcelBackendKey,
  ParcelBackendExpandKey,
  SectorBackendKey,
  SubsectorBackendKey,
  CountryCodeKey,
  TaxBackendInclude,
  ParcelBackendInclude,
} from "@/keys";
import { Parcel, Tax } from "@/models";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "../api";
import { useLocaleCountryCode } from "@/hooks";

export const taxApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getTaxes: builder.query<
      Tax[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TaxBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().taxes({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: TaxBackend[] }): Tax[] =>
        responseTaxesAdapter(response.data),
    }),
    getTaxesCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TaxBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().taxes({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getTaxesExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<TaxBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createTaxExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const loadTaxes = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    try {
      dispatch(setLoading({ portal, tabkey: TabKey.taxes, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy: sortBy ?? TaxBackendKey.totalValuation,
        sortOrder: sortOrder ?? SortDirectionBackendKey.DESC,
        searchIds: isElasticSearch ? { tax: searchIds.tax } : undefined,
      });

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().taxes({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.taxes, count: responseCount.data.data }));
      } else {
        const include: TaxBackendInclude[] = [
          `${TaxBackendKey.id}`,
          `${TaxBackendKey.totalValuation}`,
          `${TaxBackendKey.exemption}`,
          `${TaxBackendKey.land}`,
          `${TaxBackendKey.structure}`,
          `${TaxBackendKey.machinery}`,
          `${TaxBackendKey.exoneration}`,
          `${TaxBackendKey.taxable}`,
          `${TaxBackendKey.address}`,
          `${TaxBackendExpandKey.property}.${PropertyBackendKey.id}`,
          `${TaxBackendExpandKey.property}.${PropertyBackendKey.parcelId}`,
          `${TaxBackendExpandKey.property}.${PropertyBackendKey.address}`,
        ];
        const responseColumns: AxiosResponse<{ data: Tax[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().taxes({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
          {
            transformResponse: [
              (data) => {
                let resp;
                try {
                  resp = JSON.parse(data);
                  resp.data = responseTaxesAdapter(resp.data);
                  return resp;
                } catch (error) {
                  throw Error(
                    `[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`,
                  );
                }
              },
            ],
          },
        );

        const parcelIds = [];

        for (let index = 0; index < responseColumns.data.data.length; index++) {
          const tax = responseColumns.data.data[index];
          if (tax.property?.parcelId) {
            parcelIds.push(tax.property.parcelId);
          }
        }

        const bodyParcel: RequestFiltersBackend<ParcelBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
            },
          ],
          include: [
            `${ParcelBackendKey.id}`,
            `${ParcelBackendKey.photos}`,
            `${ParcelBackendKey.name}`,
            `${ParcelBackendKey.address}`,
            `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
            `${ParcelBackendExpandKey.subsector}.${SubsectorBackendKey.name}`,
          ],
        };

        const responseParcelColumns: AxiosResponse<{ data: Parcel[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          bodyParcel,
        );

        const taxesExpanded = responseColumns.data.data;

        for (let index = 0; index < taxesExpanded.length; index++) {
          const tax = taxesExpanded[index];
          if (tax.property?.parcelId) {
            const parcel = responseParcelColumns.data.data.find(
              (parcel) => parcel.id === tax.property?.parcelId,
            );
            if (parcel) {
              tax.property.parcel = parcel;
            }
          }
        }

        dispatch(setColumns({ portal, tabkey: TabKey.taxes, columns: taxesExpanded }));
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any taxes", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.taxes, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.taxes, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const { useLazyGetTaxesExportFileQuery, useLazyGetTaxesQuery, useLazyGetTaxesCountQuery } =
  taxApi;
