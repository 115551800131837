import { CallToActionAtlasProfessional } from "@/components/callToAction";
import CallToActionAtlasProfessionalSmall from "@/components/callToAction/CallToActionAtlasProfessionalSmall";
import React, { CSSProperties } from "react";

type FakeTableContainerProps = {
  children: React.ReactNode;
  hideData?: boolean;
  topMessage1?: string;
  topMessage2?: string;
  style?: CSSProperties;
  onCloseFunction?: () => void;
  smallCTA?: boolean;
  topCTAModal?: string;
  verticallyCenteredModal?: boolean;
  staticMode?: boolean;
};

const FakeTableContainer: React.FC<FakeTableContainerProps> = ({
  children,
  hideData = false,
  topMessage1,
  topMessage2,
  style,
  onCloseFunction,
  smallCTA,
  topCTAModal,
  verticallyCenteredModal,
  staticMode,
}) => {
  return (
    <div style={style}>
      {hideData ? (
        <div
          style={{
            zIndex: 2,
            position: staticMode ? "relative" : "sticky",
            top: 0,
            display: "flex",
            justifyContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              top: verticallyCenteredModal ? "25svh" : topCTAModal ?? 50,
              transform: verticallyCenteredModal ? "translateY(-50%)" : undefined,
            }}>
            {smallCTA ? (
              <CallToActionAtlasProfessionalSmall className="mx-1" topMessage={topMessage1} />
            ) : (
              <CallToActionAtlasProfessional
                topMessage1={topMessage1}
                topMessage2={topMessage2}
                onCloseFunction={onCloseFunction}
              />
            )}
          </div>
        </div>
      ) : null}
      <div
        style={
          hideData
            ? {
                filter: "blur(5px)",
                position: "relative",
                pointerEvents: "none",
                height: "100%",
                width: "100%",
              }
            : {
                height: "100%",
                width: "100%",
              }
        }>
        {children}
      </div>
    </div>
  );
};

export default FakeTableContainer;
