import {
  responseListingAdapter,
  responseParcelAdapter,
  ReportListBackend,
  responseListAdapter,
  responseTransactionAdapter,
  responseTaxAdapter,
} from "@/adapters";
import { Listing, Parcel, ReportList, ReportListListing, ReportListParcel } from "@/models";

export const responseReportListAdapter = (data: ReportListBackend): ReportList => {
  const newData: ReportList = {
    report: {
      list: responseListAdapter(data.report.list),
      listings: data.report.list.listingMembers.length
        ? data.report.list.listingMembers.map((listingMember) => {
            const listing: Listing = responseListingAdapter(listingMember.listing);
            const newListing: ReportListListing = {
              ...listing,
              lastTransaction: listingMember.listing.lastTransaction
                ? responseTransactionAdapter(listingMember.listing.lastTransaction)
                : undefined,
            };
            return newListing;
          })
        : [],
      parcels: data.report.list.parcelMembers.length
        ? data.report.list.parcelMembers.map((parcelMember) => {
            const parcel: Parcel = responseParcelAdapter(parcelMember.parcel);
            const newParcel: ReportListParcel = {
              ...parcel,
              pricePerLotSqm: parcelMember.parcel.pricePerLotSqm,
              pricePerBuiltSqm: parcelMember.parcel.pricePerBuiltSqm,
              lastTransactionPrice: parcelMember.parcel.lastTransactionPrice,
              unitsTotalArea: parcelMember.parcel.unitsTotalArea,
              mostExpensiveTax: parcelMember.parcel.mostExpensiveTax
                ? responseTaxAdapter(parcelMember.parcel.mostExpensiveTax)
                : null,
              counts: parcelMember.parcel.counts,
            };
            return newParcel;
          })
        : [],
    },
  };

  return newData;
};
