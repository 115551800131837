export enum TaxKey {
  id = "id",
  land = "land",
  taxable = "taxable",
  exemption = "exemption",
  machinery = "machinery",
  structure = "structure",
  exoneration = "exoneration",
  totalValuation = "totalValuation",
}

export enum TaxExpandKey {
  property = "property",
}
