import React from "react";
import Skeleton from "react-loading-skeleton";
import { getOrganization } from "@/configs";

function LoadingInfowindow() {
  return (
    <div>
      <div style={{ height: "150px", width: "100%" }}>
        {"" || (
          <Skeleton
            height={150}
            style={{
              position: "absolute",
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
        )}
      </div>

      <div className="p-1">
        <p style={{ width: "100%", height: "auto" }} className="mb-05">
          {"" || <Skeleton height={15} />}
        </p>

        <p className="infowindow-address">{"" || <Skeleton height={20} />}</p>
        <div className="row mt-1">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center cursor-pointer">
              <div>{"" || <Skeleton height={25} width={25} circle={true} />}</div>

              <div className="ml-05">
                <p className="mb-0 line-break">{"" || <Skeleton height={15} width={60} />}</p>
              </div>
            </div>
            <div className="d-flex align-items-center ml-05 cursor-pointer">
              <div>{"" || <Skeleton height={25} width={25} circle={true} />}</div>

              <div className="ml-05">
                <p className="mb-0 line-break">{"" || <Skeleton height={15} width={60} />}</p>
              </div>
            </div>
            <div className="d-flex align-items-center ml-05 cursor-pointer">
              <div>{"" || <Skeleton height={25} width={25} circle={true} />}</div>
              <div className="ml-05">
                <p className="mb-0 line-break">{"" || <Skeleton height={15} width={60} />}</p>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 d-flex justify-content-between">
            {getOrganization().lists ? (
              <div style={{ height: "39.2px" }}>
                {"" || (
                  <Skeleton
                    height={39.2}
                    width={145}
                    style={{
                      borderRadius: "1.5rem",
                    }}
                  />
                )}
              </div>
            ) : null}
            <div
              style={
                getOrganization().lists ? { height: "39.2px" } : { height: "39.2px", width: "100%" }
              }>
              {"" || (
                <Skeleton
                  height={39.2}
                  width={getOrganization().lists ? 145 : undefined}
                  style={{
                    borderRadius: "1.5rem",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingInfowindow;
