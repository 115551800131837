export enum NewsCategoriesKey {
  all = "all",
  retail = "retail",
  construction = "construction",
  industrial = "industrial",
  infrastructure = "infrastructure",
  land = "land",
  hospitality = "hospitality",
  office = "office",
  tax = "tax",
  residential = "residential",
  unclassified = "unclassified",
  other = "other",
  finance = "finance",
}
