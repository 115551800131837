export enum SectorKey {
  multiFamily = "multi-family (apartment)",
  assemblyMeetingPlace = "assembly/ meeting place",
  singleFamily = "single-family (house)",
  industrial = "industrial",
  land = "land",
  lodgingAndHospitality = "lodging & hospitality",
  office = "office",
  publicService = "public service",
  recreation = "recreation",
  retail = "retail",
  empty = "empty",
}
