export enum TabKey {
  properties = "properties",
  listings = "listings",
  featuredListings = "featuredListings",
  activeListings = "activeListings",
  inactiveListings = "inactiveListings",
  transactions = "transactions",
  tenants = "tenants",
  taxes = "taxes",
  permits = "permits",
  units = "units",
  documents = "documents",
}
