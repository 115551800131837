import { NewsCategoriesBackendKey } from "@/keys";

export const responseNewsCategoriesAdapter = (
  newsCategories: Array<NewsCategoriesBackendKey>,
): Array<keyof typeof NewsCategoriesBackendKey> => {
  const data: Array<keyof typeof NewsCategoriesBackendKey> = [];
  if (newsCategories.length) {
    newsCategories.map((news) => {
      Object.keys(NewsCategoriesBackendKey).map((key, index) => {
        if (news === NewsCategoriesBackendKey[key as keyof typeof NewsCategoriesBackendKey]) {
          data.push(key as keyof typeof NewsCategoriesBackendKey);
        }
      });
    });
  }
  return data;
};
