export enum PermitSectorKey {
  id = "id",
  name = "name",
  nameEn = "nameEn",
}

export enum PermitSubsectorKey {
  id = "id",
  name = "name",
  nameEn = "nameEn",
  sectorId = "sectorId",
}
