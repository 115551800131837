import { ListingBackendKeys } from "./listing-backend.key";
import { ParcelBackendKeys } from "./parcel-backend.key";
import { PropertyBackendKeys } from "./property-backend.key";
import { SourceBackendKeys } from "./source-backend.key";
import { TransactionMicrosectorBackendKeys } from "./transaction-microsector-backend.key";
import { TransactionSectorBackendKeys } from "./transaction-sector-backend.key";
import { TransactionSubsectorBackendKeys } from "./transaction-subsector-backend.key";

export enum TransactionBackendKey {
  id = "id",
  address = "address",
  addressJSON = "addressJSON",
  buyerName = "buyerName",
  cityId = "cityId",
  createdAt = "createdAt",
  deedBook = "deedBook",
  deedNumber = "deedNumber",
  deedPage = "deedPage",
  externalId = "externalId",
  extractedAt = "extractedAt",
  scriptures = "scriptures",
  sellerName = "sellerName",
  transactionDate = "transactionDate",
  transactionPrice = "transactionPrice",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  transactionType = "transactionType",
  sourceId = "sourceId",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
  microsectorId = "microsectorId",
  parcelId = "parcelId",
  genericListingId = "genericListingId",
  builtArea = "builtArea",
  lotArea = "lotArea",
  dataset = "dataset",
}

export type TransactionBackendKeys = keyof typeof TransactionBackendKey;

export enum TransactionBackendSortKey {
  // id = "id",
  // address = "address",
  // addressJSON = "addressJSON",
  buyerName = "buyerName",
  // cityId = "cityId",
  // createdAt = "createdAt",
  // deedBook = "deedBook",
  // deedNumber = "deedNumber",
  // deedPage = "deedPage",
  // externalId = "externalId",
  // extractedAt = "extractedAt",
  // scriptures = "scriptures",
  sellerName = "sellerName",
  transactionDate = "transactionDate",
  transactionPrice = "transactionPrice",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  // pricePerLotSqm = "pricePerLotSqm",
  transactionType = "transactionType",
  // sourceId = "sourceId",
  // sectorId = "sectorId",
  // subsectorId = "subsectorId",
  // microsectorId = "microsectorId",
}

export enum TransactionBackendExpandKey {
  properties = "properties",
  parcel = "parcel",
  listing = "listing",
  source = "source",
  sector = "sector",
  subsector = "subsector",
  microsector = "microsector",
}

export type TransactionBackendExpandKeys = keyof typeof TransactionBackendExpandKey;

export type TransactionBackendInclude =
  | (TransactionBackendKeys | "*")
  | (`${TransactionBackendExpandKeys}.${PropertyBackendKeys}` | `${TransactionBackendExpandKeys}.*`)
  | (`${TransactionBackendExpandKeys}.${ParcelBackendKeys}` | `${TransactionBackendExpandKeys}.*`)
  | (`${TransactionBackendExpandKeys}.${ListingBackendKeys}` | `${TransactionBackendExpandKeys}.*`)
  | (`${TransactionBackendExpandKeys}.${SourceBackendKeys}` | `${TransactionBackendExpandKeys}.*`)
  | (
      | `${TransactionBackendExpandKeys}.${TransactionSectorBackendKeys}`
      | `${TransactionBackendExpandKeys}.*`
    )
  | (
      | `${TransactionBackendExpandKeys}.${TransactionSubsectorBackendKeys}`
      | `${TransactionBackendExpandKeys}.*`
    )
  | (
      | `${TransactionBackendExpandKeys}.${TransactionMicrosectorBackendKeys}`
      | `${TransactionBackendExpandKeys}.*`
    );
