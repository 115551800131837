// DO NOT MODIFY!!!
// NOTE: This file is automatically generated and should not be edited
// Generated by: generateLocales.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const defaultLanguage = "en-PR";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    supportedLngs: [
      "en-AE",
      "en-AR",
      "en-CO",
      "en-MX",
      "en-PR",
      "es-AE",
      "es-AR",
      "es-CO",
      "es-MX",
      "es-PR",
    ],
    load: "currentOnly",
    debug: false,
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lang",
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
