import type { SubscriptionPaymentHistoryBackend } from "@/adapters";
import { SubscriptionPaymentHistory } from "@/models";

export const responseSubscriptionPaymentHistoryAdapter = (
  histories: SubscriptionPaymentHistoryBackend[],
): SubscriptionPaymentHistory[] => {
  const data: SubscriptionPaymentHistory[] = [];
  if (histories.length) {
    histories.map((history) => {
      data.push({
        amount: history.amount,
        date: history.date,
        invoice: history.invoice,
        paymentMethod: {
          brand: history.payment_method.brand,
          last4: history.payment_method.last4,
          expMonth: history.payment_method.expMonth,
          expYear: history.payment_method.expYear,
        },
        plan: history.plan,
        status: history.status,
      });
    });
  }

  return data;
};
