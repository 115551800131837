import { DataSource } from "@/models";
import { DataSourceBackend } from "@/adapters";

export const responseDataSourcesAdapter = (dataSources: DataSourceBackend[]): DataSource[] => {
  const newDataSources: DataSource[] = [];

  if (dataSources.length) {
    dataSources.map((source) => newDataSources.push(source));
  }
  return newDataSources;
};
