import styled from "styled-components";

export const VerticalLine = styled.div<{
  color: string;
  height?: string;
}>`
  border-left: ${(props) => `1px solid ${props.color}`};
  margin-left: 10px;
  margin-right: 10px;
  height: ${(props) => props.height ?? "none"};
`;
