import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import auth from "@/redux/reducers/auth";
import user from "@/redux/slices/user/user.slice";
import subscription from "@/redux/slices/subscription/subscription.slice";
// import map from "@/redux/reducers/map";
import mapSlice from "@/redux/slices/map/map.slice";
import google from "@/redux/reducers/google";
import thunk from "redux-thunk";
import tableviewReducer from "@/redux/slices/tableView/table-view.slice";
import gridviewReducer from "@/redux/slices/gridView/grid-view.slice";
import listsReducer from "@/redux/slices/lists/lists.slice";
import locationsReducer from "@/redux/slices/locations/locations.slice";
import brochureSlice from "@/redux/slices/brochure/brochure.slice";
import parcelReducer from "@/redux/slices/parcels/parcel.slice";
import unitSliceReducer from "@/redux/slices/units/unit.slice";
import filtersReducer from "@/redux/slices/filters/filters.slice";
import { api } from "@/services";
import appSlice from "@/redux/slices/appConfig/app.slice";
import listingReducer from "@/redux/slices/listings/listing.slice";
import newsReducer from "@/redux/slices/news/news.slice";
import searchReducer from "@/redux/slices/search/search.slice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    auth: auth,
    brochure: brochureSlice,
    google: google,
    gridView: gridviewReducer,
    listing: listingReducer,
    news: newsReducer,
    lists: listsReducer,
    locations: locationsReducer,
    map: mapSlice,
    newFilters: filtersReducer,
    parcel: parcelReducer,
    search: searchReducer,
    subscription: subscription,
    tableview: tableviewReducer,
    user: user,
    unit: unitSliceReducer,
    [api.reducerPath]: api.reducer,
  },
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([api.middleware, thunk]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
