import React, { ReactNode, useState } from "react";

interface ChildProps {
  isOpen: boolean;
  onItemClick: () => void;
}

interface AccordionProps {
  children: ReactNode;
  scrollContainerRef?: React.MutableRefObject<HTMLElement | undefined>;
}

export const Accordion: React.FC<AccordionProps> = ({ children, scrollContainerRef }) => {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const scrollTop = (position: number) => {
    const curr = scrollContainerRef?.current;
    if (curr) {
      curr.scrollTop = position;
    }
  };

  const handleItemClick = (index: number) => {
    setOpenItem(index === openItem ? null : index);
    // scrollTop(0);
    setTimeout(() => {
      if (
        scrollContainerRef?.current &&
        scrollContainerRef?.current.firstChild instanceof HTMLElement
      ) {
        const scrollChildrenContainer = scrollContainerRef.current.firstChild as HTMLElement;
        // const clickedItem = scrollChildrenContainer.children[index];
        const elementsArray = Array.from(scrollChildrenContainer.children);
        const boundingClientRectsTop = elementsArray.map(
          (child) => child.getBoundingClientRect().top - child.getBoundingClientRect().height,
        );
        // console.log(" accordion container", scrollChildrenContainer);
        // console.log("bounding clients array", boundingClientRectsTop);
        // console.log("clicked item HTMLElement", clickedItem);
        // console.log("clicked item top", clickedItem.getBoundingClientRect().top);
        scrollTop(boundingClientRectsTop[index]);
      }
    }, 500);
  };

  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            isOpen: index === openItem,
            onItemClick: () => handleItemClick(index),
          } as ChildProps); // Use ChildProps to specify the props
        }
        return child;
      })}
    </>
  );
};
