import { TimeOnMarketBackend, responseReportTimeOnDataMarketAdapter } from "@/adapters";
import { TimeOnMarketBackendKey, TimeOnMarketKey } from "@/keys";
import { TimeOnMarket } from "@/models";

export const responseReportTimeOnMarketAdapter = (data: TimeOnMarketBackend): TimeOnMarket => ({
  [TimeOnMarketKey.lotValue]: data[TimeOnMarketBackendKey.lotValue]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.lotValue])
    : null,
  [TimeOnMarketKey.pricePerAcre]: data[TimeOnMarketBackendKey.pricePerAcre]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.pricePerAcre])
    : null,
  [TimeOnMarketKey.builtValue]: data[TimeOnMarketBackendKey.builtValue]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.builtValue])
    : null,
  [TimeOnMarketKey.pricePerSqft]: data[TimeOnMarketBackendKey.pricePerSqft]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.pricePerSqft])
    : null,
  [TimeOnMarketKey.annualRent]: data[TimeOnMarketBackendKey.annualRent]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.annualRent])
    : null,
  [TimeOnMarketKey.rentPerSqft]: data[TimeOnMarketBackendKey.rentPerSqft]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.rentPerSqft])
    : null,
  [TimeOnMarketKey.rentToValue]: data[TimeOnMarketBackendKey.rentToValue]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.rentToValue])
    : null,
  [TimeOnMarketKey.totalValue]: data[TimeOnMarketBackendKey.totalValue]
    ? responseReportTimeOnDataMarketAdapter(data[TimeOnMarketBackendKey.totalValue])
    : null,
});
