import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  Accordion,
  AccordionItem,
  ButtonItem,
  ButtonUnderline,
  sizeViewport,
} from "@/components/styledComponents";
import { useLocaleCountryCode } from "@/hooks";
import { updateUserLanguage } from "@/redux/actions";
import { selectAppConfig, selectUser } from "@/redux/slices";
import classNames from "classnames";
import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import languageIcon from "@/assets/img/icons/language.svg";
import { useTranslation } from "react-i18next";
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { useTheme } from "styled-components";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { string } from "prop-types";

type LanguageDropdownProps = {
  /**
   * Forces the width to minimum.
   * Useful to occupy only small widths.
   * If the prop is not passed, it continues to be responsive.
   * @default false
   */
  showIcon?: boolean;
  forceMobileSize?: boolean;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  iconSize?: string | number;
  variant?: string;
};

type VariantTypes = {
  [key: string]: JSX.Element;
};

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  showIcon = false,
  forceMobileSize = false,
  fullWidth = false,
  disableUnderline = false,
  variant = "dropdown",
  iconSize = "1rem",
}) => {
  const { windowWidth } = useAppSelector(selectAppConfig);
  const { user } = useAppSelector(selectUser);
  const [toggleLanguage, setToggleLanguage] = useState(false);
  const { t, i18n } = useTranslation("corporateSite");
  const translation = t("navbar", { returnObjects: true });
  const dispatch = useAppDispatch();
  const { countryCode } = useLocaleCountryCode();
  const { defaultColors } = useTheme();
  const isMobile = windowWidth < sizeViewport.laptop;

  const handleLanguage = ({ languageCode }: { languageCode: string }) => {
    if (user?.email) {
      dispatch(updateUserLanguage({ languageCode }));
    }
    i18n.changeLanguage(`${languageCode}-${countryCode}`);
    setToggleLanguage(false);
  };

  const variants: VariantTypes = {
    accordion: (
      <>
        <Accordion>
          <AccordionItem
            title={
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={languageIcon}
                    className={classNames("mr-05", {
                      "d-none": !showIcon,
                    })}
                    style={{ width: iconSize }}
                  />
                  {(windowWidth <= sizeViewport.laptop || forceMobileSize) && !fullWidth
                    ? translation.language.languageCode
                    : translation.language.title}
                </div>
              </>
            }
            openIcon={faChevronUp}
            closeIcon={faChevronDown}
            containerProps={{ borderBottom: "none" }}
            titleProps={{ height: "1rem", fontSize: "inherit", padding: 0 }}
            contentProps={{ padding: "1rem 0" }}>
            <ButtonItem onClick={() => handleLanguage({ languageCode: "en" })}>
              {translation.language.options.english}
            </ButtonItem>
            <ButtonItem onClick={() => handleLanguage({ languageCode: "es" })}>
              {translation.language.options.spanish}
            </ButtonItem>
          </AccordionItem>
        </Accordion>
      </>
    ),
    dropdown: (
      <UncontrolledButtonDropdown
        isOpen={toggleLanguage}
        toggle={() => {
          setToggleLanguage(!toggleLanguage);
        }}
        style={{ width: "100%" }}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="span"
          style={{
            whiteSpace: "nowrap",
            display: "block",
            width: fullWidth ? "100%" : "",
            marginRight: isMobile && !fullWidth ? "1rem" : "inherit",
          }}>
          <ButtonUnderline
            disableUnderline={disableUnderline}
            active={toggleLanguage}
            style={{
              width: fullWidth ? "100%" : "",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div>
              <img
                src={languageIcon}
                className={classNames("mr-05", {
                  "d-none": !showIcon,
                })}
                style={{ width: iconSize }}
              />
              {(windowWidth <= sizeViewport.laptop || forceMobileSize) && !fullWidth
                ? translation.language.languageCode
                : translation.language.title}
            </div>

            <ChevronDown
              size={18}
              className={`${
                windowWidth <= sizeViewport.laptop || forceMobileSize ? "" : "ml-1-custom"
              }`}
            />
          </ButtonUnderline>
        </DropdownToggle>

        <DropdownMenu
          tag="ul"
          right={!fullWidth}
          className={classNames(`dropdown-menu-corporate`, {
            "elevation-1": fullWidth,
          })}
          style={{
            backgroundColor: defaultColors.background,
            width: fullWidth ? "calc(100vw - 1.5rem)" : "auto",
          }}>
          <ButtonItem onClick={() => handleLanguage({ languageCode: "en" })}>
            {translation.language.options.english}
          </ButtonItem>
          <ButtonItem onClick={() => handleLanguage({ languageCode: "es" })}>
            {translation.language.options.spanish}
          </ButtonItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    ),
  };

  return variants[variant];
};

export default LanguageDropdown;
