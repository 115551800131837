import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { List } from "@/models";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface ListState {
  showListModal: boolean;
  selectedList: {
    id: string;
    name: string;
    description: string;
  };
}

// Define the initial state using that type
const initialState: ListState = {
  showListModal: false,
  selectedList: {
    id: "",
    name: "",
    description: "",
  },
};

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setShowNewListModal: (
      state,
      action: PayloadAction<{ showListModal: boolean; list?: List }>,
    ) => {
      state.showListModal = action.payload.showListModal;
      if (action.payload.list) {
        state.selectedList = {
          id: action.payload.list.id,
          name: action.payload.list.name,
          description: action.payload.list.description,
        };
      } else {
        state.selectedList = initialState.selectedList;
      }
    },
  },
});

// These actions will dispatch in the app
export const { setShowNewListModal } = listsSlice.actions;

export const selectLists = (state: RootState) => state.lists;

export default listsSlice.reducer;
