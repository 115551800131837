import React, { useState, useEffect, useRef } from "react";

type TypewriterProps = {
  texts: string[];
  writeDelay: number;
  backspaceDelay: number;
  pauseDelay: number;
};

const Typewriter = ({ texts, writeDelay, backspaceDelay, pauseDelay }: TypewriterProps) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [backspace, setBackspace] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (currentIndex === texts.length) {
      setCurrentIndex(0);
    }

    if (backspace) {
      timeout = setTimeout(() => {
        if (!isMounted.current) return;
        setCurrentText((prevText) => prevText.slice(0, -1));
        if (currentText === "") {
          if (!isMounted.current) return;
          setCurrentIndex((prevIndex) => prevIndex + 1);
          setBackspace(false);
        }
      }, backspaceDelay);
    } else {
      timeout = setTimeout(() => {
        if (!isMounted.current) return;
        setCurrentText((prevText) => {
          if (prevText === texts[currentIndex]) {
            setTimeout(() => {
              if (!isMounted.current) return;
              setBackspace(true);
            }, pauseDelay);

            return prevText;
          }
          return texts[currentIndex].slice(0, prevText.length + 1);
        });
      }, writeDelay);
    }

    return () => clearTimeout(timeout);
  }, [currentText, currentIndex, writeDelay, backspaceDelay, texts, backspace]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      if (isMounted.current) {
        setShowCursor((prevShow) => !prevShow);
      }
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <span>
      {currentText}
      {showCursor && <span>|</span>}
    </span>
  );
};

export default Typewriter;
