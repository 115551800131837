import styled from "styled-components";
import { baseStyles } from "../gobalStyles";

export const VerticalTabsContainer = styled.div<{ active: boolean }>(({ active }) => {
  return {
    zIndex: 1,
    height: "100%",
    position: "absolute",
    left: active ? `calc(100vw - (4.4rem + 35px))` : `calc(100vw - 55vw)`,
    transition: "left 400ms",
    display: "flex",
    flexDirection: "column",
  };
});

export const VerticalTab = styled.div<{ active: boolean }>(({ active, theme }) => {
  return {
    position: "relative",
    height: "100%",
    padding: "10px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    backgroundColor: active ? theme.defaultColors.background : "#dedede",
    color: active ? theme.primary.color : "#979797",
    boxShadow: "5px 5px 13px -1px rgba(63, 63, 63, 0.2)",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottomRightRadius: baseStyles.borderRadius,
    borderTopRightRadius: baseStyles.borderRadius,
  };
});
