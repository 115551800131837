import styled from "styled-components";

export const Pill = styled.div<{
  color: string;
  backgroundColor: string;
  borderColor: string;
  isTextShadow?: false;
}>(
  ({ theme, color, backgroundColor, borderColor, isTextShadow }) => `
  background: ${backgroundColor};
  color: ${color};
  text-shadow: ${isTextShadow === undefined ? "1px 1px 2px rgba(0, 0, 0, 0.4)" : "none"};
  padding: 0px 6px;
  border-radius: 10rem;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid ${borderColor};
  `,
);
