import {
  ListingBackend,
  ParcelBackend,
  PermitBackend,
  PropertyBackend,
  responsePropertyAdapter,
  responseTransactionsAdapter,
  TransactionBackend,
  TaxBackend,
  responseParcelAdapter,
  responsePropertiesAdapter,
  responseListingsAdapter,
  responseTaxesAdapter,
  responseTenantsAdapter,
  TenantBackend,
  responsePermitsAdapter,
  requestFiltersAdapter,
  RequestFiltersAdapterProps,
  responseParcelsAdapter,
  RequestFiltersBackend,
  FlexibleRequestFiltersBackend,
  BodyExportFileBackend,
  FiltersBackend,
} from "@/adapters";
import { AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { defaultFilters } from "@/configs";
import {
  CityBackendKey,
  CountryCodeBackendKey,
  CountryCodeKey,
  DatasetListingsBackendKey,
  DatasetTransactionsBackendKey,
  KeyofSortDirectionBackendKey,
  ListingBackendInclude,
  MicrosectorBackendKey,
  NeighborhoodBackendKey,
  ParcelBackendExpandKey,
  ParcelBackendInclude,
  ParcelBackendKey,
  ParcelEntityCountBackendKey,
  PermitBackendInclude,
  PropertyBackendInclude,
  PropertyBackendKey,
  SectorBackendKey,
  SortDirectionBackendKey,
  SubsectorBackendKey,
  TabKey,
  TaxBackendInclude,
  TenantBackendInclude,
  TransactionBackendInclude,
  useEndpoint,
} from "@/keys";
import { Listing, Parcel, Permit, Property, Tenant, Transaction, Tax, EntityCount } from "@/models";
import { setColumns, setCount, setLoading } from "@/redux/slices";
import axios, { AxiosResponse } from "axios";
import { api } from "../api";
import { useLocaleCountryCode } from "@/hooks";
import { deepClone, deepMerge } from "@/utilities";

export const parcelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getParcel: builder.query<
      Parcel,
      {
        id: string;
        countryCode: CountryCodeKey;
        bodyProps?: RequestFiltersBackend<ParcelBackendInclude, { isCount: false }>;
      }
    >({
      query: ({ id, countryCode, bodyProps }) => {
        return {
          url: useEndpoint().parcel({ id, countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: { data: ParcelBackend }): Parcel =>
        responseParcelAdapter(response.data),
    }),
    getParcelsByIds: builder.query<
      Parcel[],
      {
        parcelIds: string[];
        include: FlexibleRequestFiltersBackend<ParcelBackendInclude>["include"];
        countryCode: CountryCodeKey;
      }
    >({
      query({ countryCode, parcelIds, include }) {
        const body: RequestFiltersBackend<ParcelBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
            },
          ],
          include: include,
        };
        return {
          url: useEndpoint().parcels({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: ParcelBackend[] }): Parcel[] =>
        responseParcelsAdapter(response.data),
    }),
    getParcels: builder.query<
      Parcel[],
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: false }>;
        controller?: AbortController;
      }
    >({
      query({ countryCode, bodyProps, controller }) {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().parcels({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: ParcelBackend[] }): Parcel[] =>
        responseParcelsAdapter(response.data),
    }),
    getParcelsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: true }>;
        controller?: AbortController;
      }
    >({
      query({ countryCode, bodyProps, controller }) {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getParcelProperties: builder.query<
      Property[],
      {
        parcelId: string;
        countryCode: CountryCodeKey;
        include: FlexibleRequestFiltersBackend<PropertyBackendInclude>["include"];
      }
    >({
      query: ({ parcelId, countryCode, include }) => {
        const body: RequestFiltersBackend<PropertyBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
            },
          ],
          include: include,
        };
        return {
          url: useEndpoint().properties({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: PropertyBackend[] }): Property[] =>
        responsePropertiesAdapter(response.data),
    }),
    getParcelTransactions: builder.query<
      Transaction[],
      {
        parcelIds: string[];
        include: FlexibleRequestFiltersBackend<TransactionBackendInclude>["include"];
        countryCode: CountryCodeKey;
        controller?: AbortController;
        attachments?: true | undefined;
      }
    >({
      query: ({ parcelIds, include, countryCode, controller, attachments }) => {
        const filters: FlexibleRequestFiltersBackend<TransactionBackendInclude>["filters"] = [
          {
            parcel: {
              search: {
                id: parcelIds,
              },
            },
            transaction: {
              search: { dataset: { notIn: [DatasetTransactionsBackendKey.duplicate] } },
            },
          },
        ];

        if (attachments) {
          filters[0] = {
            ...filters[0],
            transaction: {
              search: {
                scriptures: {
                  isNotNull: true,
                },
              },
            },
          };
        }

        // TODO: Aplicamos este filtro para traer en COL solo las transacciones que esten asociadas a units
        if (countryCode === CountryCodeKey.CO) {
          filters[0] = {
            ...filters[0],
            property: {
              search: {
                id: {
                  isNotNull: true,
                },
              },
            },
          };
        }

        const body: RequestFiltersBackend<TransactionBackendInclude, { isCount: false }> = {
          filters: filters,
          include: include,
        };

        return {
          url: useEndpoint().transactions({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: TransactionBackend[] }): Transaction[] =>
        responseTransactionsAdapter(response.data),
    }),
    getParcelTaxes: builder.query<
      Tax[],
      {
        parcelId: string;
        include: FlexibleRequestFiltersBackend<TaxBackendInclude>["include"];
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ parcelId, include, countryCode }) => {
        const body: RequestFiltersBackend<TaxBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
            },
          ],
          include: include,
        };
        return {
          url: useEndpoint().taxes({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: TaxBackend[] }): Tax[] =>
        responseTaxesAdapter(response.data),
    }),
    getParcelTenants: builder.query<
      Tenant[],
      {
        parcelIds: string[];
        include: FlexibleRequestFiltersBackend<TenantBackendInclude>["include"];
        countryCode: CountryCodeKey;
      }
    >({
      query: ({ parcelIds, include, countryCode }) => {
        const body: RequestFiltersBackend<TenantBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
            },
          ],
          include: include,
        };
        return {
          url: useEndpoint().tenants({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: TenantBackend[] }): Tenant[] =>
        responseTenantsAdapter(response.data),
    }),
    getParcelPermits: builder.query<
      Permit[],
      {
        parcelIds: string[];
        include: FlexibleRequestFiltersBackend<PermitBackendInclude>["include"];
        countryCode: CountryCodeKey;
        controller?: AbortController;
      }
    >({
      query: ({ parcelIds, include, countryCode, controller }) => {
        const body: RequestFiltersBackend<PermitBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
            },
          ],
          include: include,
        };

        return {
          url: useEndpoint().permits({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: PermitBackend[] }): Permit[] =>
        responsePermitsAdapter(response.data),
    }),
    getParcelListings: builder.query<
      Listing[],
      {
        parcelIds: string[];
        showOnlyActiveListings?: boolean;
        include: FlexibleRequestFiltersBackend<ListingBackendInclude>["include"];
        countryCode: CountryCodeKey;
        controller?: AbortController;
      }
    >({
      query: ({ parcelIds, showOnlyActiveListings, include, countryCode, controller }) => {
        const body: RequestFiltersBackend<ListingBackendInclude, { isCount: false }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelIds,
                },
              },
              listing: {
                search: {
                  dataset: { notIn: [DatasetListingsBackendKey.duplicate] },
                },
                boolean: showOnlyActiveListings
                  ? {
                      active: true,
                    }
                  : {},
              },
            },
          ],
          include: include,
        };
        return {
          url: useEndpoint().listings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: ListingBackend[] }): Listing[] =>
        responseListingsAdapter(response.data),
    }),
    getNearbyProperties: builder.query<Array<Property>, { id: string }>({
      query: ({ id }) => useEndpoint().propertyNearBy(id),
      transformResponse: (response: { data: PropertyBackend[] }): Property[] => {
        return response.data.map((property) => responsePropertyAdapter(property));
      },
    }),
    getParcelsExportFile: builder.query<
      Blob,
      {
        countryCode: CountryCodeKey;
        bodyProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: false }>;
        columns: Record<string, unknown>;
        format: string;
      }
    >({
      query: ({ countryCode, bodyProps, columns, format }) => {
        const body: BodyExportFileBackend = {
          ...requestFiltersAdapter({ ...bodyProps }),
          columns,
          format,
        };
        return {
          url: useEndpoint().createParcelExportFile({
            countryCode: CountryCodeBackendKey[countryCode],
          }),
          method: "post",
          body,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        };
      },
    }),
    getParcelsCounts: builder.query<
      EntityCount[],
      {
        controller?: AbortController;
        countryCode: CountryCodeKey;
        entity: ParcelEntityCountBackendKey;
        parcelIds: string[];
        filters?: FiltersBackend[];
      }
    >({
      query({ countryCode, entity, controller, parcelIds, filters }) {
        const baseFilter: FiltersBackend = {
          parcel: {
            search: {
              id: parcelIds,
            },
          },
        };

        const combinedFilters: FiltersBackend = filters?.length
          ? filters.reduce(
              (acc, filter) => deepMerge(acc, deepClone(filter)),
              deepClone(baseFilter),
            )
          : baseFilter;

        const body = {
          filters: [combinedFilters],
        };

        return {
          url: useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
            entity,
          }),
          method: "post",
          body,
          signal: controller?.signal,
        };
      },
      transformResponse: (response: { data: EntityCount[] }): EntityCount[] => response.data,
    }),
    getParcelPropertiesCount: builder.query<
      number,
      { countryCode: CountryCodeKey; parcelId: string }
    >({
      query({ countryCode, parcelId }) {
        const body: RequestFiltersBackend<PropertyBackendInclude, { isCount: true }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
            },
          ],
        };
        return {
          url: useEndpoint().properties({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getParcelTransactionsCount: builder.query<
      number,
      { countryCode: CountryCodeKey; parcelId: string }
    >({
      query({ countryCode, parcelId }) {
        const body: RequestFiltersBackend<TransactionBackendInclude, { isCount: true }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
              transaction: {
                search: { dataset: { notIn: [DatasetTransactionsBackendKey.duplicate] } },
              },
            },
          ],
        };

        // TODO: Aplicamos este filtro para traer en COL solo las transacciones que esten asociadas a units
        if (countryCode === CountryCodeKey.CO && body.filters) {
          body.filters[0] = {
            ...body.filters[0],
            property: {
              search: {
                id: {
                  isNotNull: true,
                },
              },
            },
          };
        }

        return {
          url: useEndpoint().transactions({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getParcelTaxesCount: builder.query<number, { countryCode: CountryCodeKey; parcelId: string }>({
      query({ countryCode, parcelId }) {
        const body: RequestFiltersBackend<TaxBackendInclude, { isCount: true }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
            },
          ],
        };
        return {
          url: useEndpoint().taxes({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
    getParcelPermitsCount: builder.query<number, { countryCode: CountryCodeKey; parcelId: string }>(
      {
        query({ countryCode, parcelId }) {
          const body: RequestFiltersBackend<PermitBackendInclude, { isCount: true }> = {
            filters: [
              {
                parcel: {
                  search: {
                    id: parcelId,
                  },
                },
              },
            ],
          };

          return {
            url: useEndpoint().permits({
              countryCode: CountryCodeBackendKey[countryCode],
              isCount: true,
            }),
            method: "post",
            body,
          };
        },
        transformResponse: (response: { data: number }): number => response.data,
      },
    ),
    getParcelTenantsCount: builder.query<number, { countryCode: CountryCodeKey; parcelId: string }>(
      {
        query({ countryCode, parcelId }) {
          const body: RequestFiltersBackend<TenantBackendInclude, { isCount: true }> = {
            filters: [
              {
                parcel: {
                  search: {
                    id: parcelId,
                  },
                },
              },
            ],
          };

          return {
            url: useEndpoint().tenants({
              countryCode: CountryCodeBackendKey[countryCode],
              isCount: true,
            }),
            method: "post",
            body,
          };
        },
        transformResponse: (response: { data: number }): number => response.data,
      },
    ),
    getParcelListingsCount: builder.query<
      number,
      { countryCode: CountryCodeKey; parcelId: string }
    >({
      query({ countryCode, parcelId }) {
        const body: RequestFiltersBackend<ListingBackendInclude, { isCount: true }> = {
          filters: [
            {
              parcel: {
                search: {
                  id: parcelId,
                },
              },
              listing: {
                search: {
                  dataset: { notIn: [DatasetListingsBackendKey.duplicate] },
                },
              },
            },
          ],
        };
        return {
          url: useEndpoint().listings({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount: true,
          }),
          method: "post",
          body,
        };
      },
      transformResponse: (response: { data: number }): number => response.data,
    }),
  }),
  overrideExisting: false,
});

export const loadParcels = ({
  isCount = false,
  page,
  perPage,
  sortBy,
  sortOrder,
}: {
  isCount?: boolean;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: KeyofSortDirectionBackendKey;
}): AppThunk => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const { filtersByPortal } = getState().newFilters;
    const { portal, builtAreaUnit, landAreaUnit } = getState().app;
    const { searchIds, isElasticSearch } = getState().search;
    const { countryCode } = useLocaleCountryCode();

    try {
      dispatch(setLoading({ portal, tabkey: TabKey.properties, loading: true, isCount }));
      axios.defaults.headers.common["Authorization"] = accessToken;

      const body = requestFiltersAdapter({
        filters: filtersByPortal[portal].filters,
        builtAreaUnit,
        landAreaUnit,
        defaultFilters: defaultFilters[portal],
        includeViewport: true,
        matchParcelFilters: true,
        pagination:
          page && perPage
            ? {
                currentPage: page,
                perPage,
              }
            : undefined,
        sortBy: sortBy ?? ParcelBackendKey.score,
        sortOrder: sortOrder ?? SortDirectionBackendKey.DESC,
        searchIds: isElasticSearch ? { parcel: searchIds.parcel } : undefined,
      });

      if (isCount) {
        const responseCount: AxiosResponse<{ data: number }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
            isCount,
          })}`,
          {
            filters: body.filters,
          },
        );
        dispatch(setCount({ portal, tabkey: TabKey.properties, count: responseCount.data.data }));
      } else {
        const include: ParcelBackendInclude[] = [
          `${ParcelBackendKey.id}`,
          `${ParcelBackendKey.photos}`,
          `${ParcelBackendKey.name}`,
          `${ParcelBackendKey.address}`,
          `${ParcelBackendKey.landArea}`,
          `${ParcelBackendExpandKey.city}.${CityBackendKey.name}`,
          `${ParcelBackendExpandKey.neighborhood}.${NeighborhoodBackendKey.name}`,
          `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
          `${ParcelBackendExpandKey.subsector}.${SubsectorBackendKey.name}`,
          `${ParcelBackendExpandKey.microsector}.${MicrosectorBackendKey.name}`,
          `${ParcelBackendExpandKey.properties}.${PropertyBackendKey.id}`,
          `${ParcelBackendExpandKey.properties}.${PropertyBackendKey.totalArea}`,
        ];

        const responseColumns: AxiosResponse<{ data: ParcelBackend[] }> = await axios.post(
          `${URL_BACKEND}${useEndpoint().parcels({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            ...body,
            include,
          },
        );
        dispatch(
          setColumns({
            portal,
            tabkey: TabKey.properties,
            columns: responseParcelsAdapter(responseColumns.data.data),
          }),
        );
        // if (!responseColumns.data.data.length) {
        //   toast.error("We couldn't find any properties", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //   });
        // }
      }
      dispatch(setLoading({ portal, tabkey: TabKey.properties, loading: false, isCount }));
    } catch (error) {
      dispatch(setLoading({ portal, tabkey: TabKey.properties, loading: false, isCount }));
      // if (axios.isAxiosError(error) && error?.response?.status === 402) {
      //   toast.error(error.response.data, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //   });
      //   // dispatch(getUserIsVerified());
      // }
      console.log(error);
    }
  };
};

export const {
  useGetParcelQuery,
  useLazyGetParcelQuery,
  useGetParcelsQuery,
  useLazyGetParcelsQuery,
  useGetParcelsByIdsQuery,
  useLazyGetParcelsCountQuery,
  useGetParcelsCountsQuery,
  useGetParcelPropertiesQuery,
  useGetParcelPropertiesCountQuery,
  useGetParcelTransactionsQuery,
  useGetParcelTransactionsCountQuery,
  useGetParcelTaxesQuery,
  useGetParcelTaxesCountQuery,
  useGetParcelTenantsQuery,
  useGetParcelTenantsCountQuery,
  useGetParcelPermitsQuery,
  useGetParcelPermitsCountQuery,
  useGetParcelListingsQuery,
  useLazyGetParcelListingsQuery,
  useGetParcelListingsCountQuery,
  useGetNearbyPropertiesQuery,
  useLazyGetParcelsExportFileQuery,
} = parcelApi;
