import type { AreaChartOptions, DataChartNews, News, NewsNotifications } from "@/models";
import {
  CookiesKey,
  CountryCodeBackendKey,
  CountryCodeKey,
  NewsCategoriesBackendKey,
  useEndpoint,
} from "@/keys";
import {
  DataChartNewsBackend,
  NewsBackend,
  responseDataChartNewsAdapter,
  responseNewsAdapter,
  responseNewsCategoriesAdapter,
  responseNewsProfileAdapter,
} from "@/adapters";
import { RootState } from "@/app/store";
import { getCookie } from "@/cookies";
import { responseNewsNotificationsAdapter } from "@/adapters/responses/news/news-notifications.adapter";
import { api } from "@/services";
import { createSelector } from "@reduxjs/toolkit";

export const newsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // ------------- Get News
    getNews: builder.query<
      News[],
      {
        countryCode: CountryCodeKey;
        category?: NewsCategoriesBackendKey;
        search?: string;
        reportsResearch?: boolean;
        count: number;
        page: number;
        active: boolean;
      }
    >({
      query: ({ countryCode, category, search, reportsResearch, count, page, active }) => ({
        url: useEndpoint().news({ countryCode: CountryCodeBackendKey[countryCode] }),
        params: { page, count, active, category, search, reportsResearch },
      }),
      transformResponse: (response: { data: NewsBackend[] }): News[] =>
        responseNewsAdapter(response.data),
    }),
    // ------------- Get News Profile
    getNewsProfile: builder.query<News, { countryCode: CountryCodeKey; id: string }>({
      query: ({ countryCode, id }) => ({
        url: useEndpoint().newsProfile({ countryCode: CountryCodeBackendKey[countryCode], id }),
      }),
      transformResponse: (response: { data: NewsBackend }): News =>
        responseNewsProfileAdapter(response.data),
    }),
    getNewsCount: builder.query<
      number,
      {
        countryCode: CountryCodeKey;
        category?: NewsCategoriesBackendKey;
        search?: string;
        reportsResearch?: boolean;
        active: boolean;
      }
    >({
      query: ({ countryCode, category, search, reportsResearch, active }) => ({
        url: useEndpoint().newsCount({ countryCode: CountryCodeBackendKey[countryCode] }),
        params: { active, category, search, reportsResearch },
      }),
      transformResponse: (response: { data: number }): number => response.data,
    }),
    // ------------- Get News Categories
    getNewsCategories: builder.query<Array<keyof typeof NewsCategoriesBackendKey>, void>({
      query: () => ({
        url: useEndpoint().newsCategories(),
      }),
      transformResponse: (response: {
        data: Array<NewsCategoriesBackendKey>;
      }): Array<keyof typeof NewsCategoriesBackendKey> =>
        responseNewsCategoriesAdapter(response.data),
    }),
    // ------------- Get Data Chart News
    getDataChartNews: builder.query<
      AreaChartOptions,
      {
        countryCode: CountryCodeKey;
        name: string;
      }
    >({
      query: ({ countryCode, name }) => ({
        url: useEndpoint().statistics({ countryCode: CountryCodeBackendKey[countryCode] }),
        params: { name },
      }),
      transformResponse: (response: { data: DataChartNewsBackend }): AreaChartOptions =>
        responseDataChartNewsAdapter(response.data),
    }),
    // ------------- Get News Notifications
    getNewsNotifications: builder.query<NewsNotifications, void>({
      query: () => {
        const date = getCookie(CookiesKey.newsNotificationLastDate);
        return {
          url: useEndpoint().newsNotifications(),
          params: { date: date },
        };
      },
      transformResponse: (response: { data: number }): NewsNotifications =>
        responseNewsNotificationsAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

// export const { useGetListingQuery } = listingApi; // PARA VERSIÓN NUEVA DE TS.
// For older versions of TS, you can use api.endpoints.[endpointName].useQuery/useMutation to access the same hooks.
export const {
  useGetNewsQuery,
  useLazyGetNewsProfileQuery,
  useLazyGetNewsQuery,
  useLazyGetNewsCountQuery,
  useGetDataChartNewsQuery,
  useGetNewsNotificationsQuery,
  useGetNewsCategoriesQuery,
} = newsApi;

export const getNewsNotificationsCount = (state: RootState) =>
  newsApi.endpoints.getNewsNotifications.select()(state).data;
