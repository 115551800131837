import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "@/contexts/Layout";
import * as serviceWorker from "@/serviceWorker";
import { store } from "@/app/store";
import Spinner from "@/components/@vuexy/spinner/Fallback-spinner";
import "@/index.scss";
import "@/@fake-db";
import "./i18n";

const LazyApp = lazy(() => import("@/App"));

// const combineStore = {
//   store,
//   oldStore,
// };

const AppContainer = () => {
  // console.log(combineStore);
  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(React.createElement(AppContainer), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
