export enum NewsCategoriesBackendKey {
  retail = "Retail",
  construction = "Construction",
  industrial = "Industrial",
  infrastructure = "Infrastructure",
  land = "Land",
  hospitality = "Hospitality",
  office = "Office",
  tax = "Tax",
  residential = "Residential",
  unclassified = "Unclassified",
  other = "Other",
  finance = "Finance",
}
