import type { Parcel } from "@/models";
import { ParcelBackend, responseParcelAdapter } from "@/adapters";

export const responseParcelsAdapter = (parcels: ParcelBackend[]): Parcel[] => {
  const newParcels: Parcel[] = [];

  if (parcels.length) {
    parcels.map((parcel) => {
      newParcels.push(responseParcelAdapter(parcel));
    });
  }

  return newParcels;
};
