const addCommas = (string: string) => string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const addNumber = (string: string) => string.replace(/[^0-9.]/g, "").replace(/\.{2,}/g, ".");

export const thousandSeparatorWhithDecimal = (string: string) => {
  let val = string;

  // Verificar si el string comienza con un signo negativo o positivo
  let isNegative = false;
  let isPositive = false;
  if (val.startsWith("-")) {
    isNegative = true;
    val = val.substring(1); // Eliminar el signo negativo para el procesamiento
  }

  if (val.startsWith("+")) {
    isPositive = true;
    val = val.substring(1); // Eliminar el signo positivo para el procesamiento
  }

  let str = addNumber(val);

  const first = str.indexOf(".");
  const last = str.lastIndexOf(".");
  if (first !== last) {
    str = str.substring(0, last) + str.substring(last + 1);
  }
  if (val === ".") {
    str = val.replace(".", "");
  }

  // Agregar el signo negativo si es necesario
  if (isNegative) {
    str = "-" + str;
  }

  // Agregar el signo positivo si es necesario
  if (isPositive) {
    str = "+" + str;
  }

  return addCommas(str);
};
