import { parsePhoneNumber, CountryCode } from "libphonenumber-js";

export const formatPhoneNumber = ({
  phoneNumber,
  defaultCountry,
}: {
  phoneNumber: string;
  defaultCountry?: CountryCode;
}) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, defaultCountry);

    if (!parsedPhoneNumber.isValid()) {
      return phoneNumber;
    }

    const formattedPhoneNumber = `+${
      parsedPhoneNumber.countryCallingCode
    }${parsedPhoneNumber.formatNational()}`;

    return formattedPhoneNumber;
  } catch (error) {
    return phoneNumber;
  }
};
