import React, { useEffect, useState } from "react";
import {
  Badge,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import classNames from "classnames";
import { List } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { userIsLogged } from "@/redux/actions";
import { History } from "history";
import { getOrganization, relativeAppPaths } from "@/configs";
import { OrganizationKey, PortalKey } from "@/keys";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { ListDetail, Lists } from "@/models";
import { useTheme } from "styled-components";
import { ButtonPrimaryDiv } from "@/components/styledComponents";
import { lighten } from "polished";
import { useTranslation } from "react-i18next";
import ModalNewEditList from "@/components/modals/ModalNewEditList";
import { selectLists, setCallToActionModalOpen, setShowNewListModal } from "@/redux/slices";
import { useGetListsQuery } from "@/services";
import { useLocaleCountryCode } from "@/hooks";
import useRouteQueryString from "@/hooks/queryString/useRouteQueryString.hook";

type PropertyListNavbarProps = {
  history: History;
  clickBlocked: () => void;
  windowWidth: number;
  portal: string;
};

function PropertyListNavbar({
  history,
  clickBlocked,
  windowWidth,
  portal,
}: PropertyListNavbarProps) {
  const [lists, setList] = useState<Lists["lists"]>([]);
  const { secondary, primary, buttons } = useTheme();
  const { t } = useTranslation("translation");
  const language = t("navbar.menuNavbar.lists", { returnObjects: true });
  const { countryCode } = useLocaleCountryCode();
  const { appListQueryString } = useRouteQueryString();

  const dispatch = useAppDispatch();

  const { showListModal } = useAppSelector(selectLists);

  const toggleNewListModal = () => {
    if (dispatch(userIsLogged())) dispatch(setShowNewListModal({ showListModal: !showListModal }));
    else dispatch(setCallToActionModalOpen({ callToActionModalOpen: true }));
  };

  const { data: propertiesLists } = useGetListsQuery(
    { countryCode },
    {
      skip:
        !dispatch(userIsLogged()) ||
        getOrganization().organization.key !== OrganizationKey.redatlas,
      selectFromResult: ({ data, ...rest }) => {
        return {
          data: dispatch(userIsLogged()) ? data : undefined,
          ...rest,
        };
      },
    },
  );

  useEffect(() => {
    if (propertiesLists?.lists) {
      setList(propertiesLists.lists);
    } else {
      setList([]);
    }
  }, [propertiesLists]);

  const renderLists = lists.map((list: ListDetail) => (
    <DropdownItem
      className="media-list media-bordered cursor-pointer px-1 mt-0 py-1"
      style={{ width: "100%" }}
      key={list.id}
      onClick={() => {
        history.push(appListQueryString(list.id));
      }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="overflow-hidden">
          <div className="font-title-list-menu font-weight-700 mb-05 title-list-menu text-overflow-ellipsis pr-1">
            {list.name}
          </div>
          {list.description ? (
            <small className="d-inline-block text-truncate gray" style={{ maxWidth: "220px" }}>
              {list.description}
            </small>
          ) : (
            <small className="gray">{language.noDescription}</small>
          )}
        </div>
        <Badge
          pill
          style={{
            color: primary.contrastColor,
            backgroundColor: list.total == 0 ? lighten(0.5, primary.color) : primary.color,
          }}
          className="badge-total-list-properties">
          {list.total}
        </Badge>
      </div>
    </DropdownItem>
  ));

  return getOrganization().menu.lists.show ? (
    portal === PortalKey.professional ? (
      <>
        <li className="d-flex align-items-center">
          <UncontrolledDropdown className="dropdown-list nav-item">
            <DropdownToggle
              tag="a"
              className="nav-link position-relative d-flex align-items-center p-0"
              onClick={() => (dispatch(userIsLogged()) ? null : clickBlocked())}>
              <div className="cursor-pointer d-flex align-items-center btn-header-navbar">
                <List
                  size={18}
                  className={`${windowWidth <= 1236 ? "" : "mr-1-custom"}`}
                  style={{ color: primary.color }}
                />
                {windowWidth <= 1236 ? null : (
                  <h6 className="fw-bold black mb-0">{language.title}</h6>
                )}

                <Badge
                  pill
                  style={{ color: secondary.contrastColor, backgroundColor: secondary.color }}
                  className="badge-up">
                  {lists.length}
                </Badge>
              </div>
            </DropdownToggle>

            <DropdownMenu
              tag="ul"
              left="true"
              className={classNames("dropdown-menu-list-properties", {
                ["empty-cart"]: lists.length === 0,
              })}>
              <div className="d-flex align-items-center justify-content-between px-1 pt-1 mb-1">
                <div>
                  <h3 className="font-weight-bolderer font-weight-700 black mb-0">
                    {language.title}
                  </h3>

                  <small> {!lists.length && language.noLists}</small>
                </div>

                <DropdownItem className="p-0">
                  <ButtonPrimaryDiv
                    onClick={() => {
                      toggleNewListModal();
                    }}>
                    {language.new}
                  </ButtonPrimaryDiv>
                </DropdownItem>
              </div>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false,
                }}
                style={{ maxWidth: "300px", maxHeight: "194px" }}>
                {renderLists}
              </PerfectScrollbar>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
        {showListModal ? (
          <ModalNewEditList isOpen={showListModal} toggle={toggleNewListModal} />
        ) : null}
      </>
    ) : null
  ) : null;
}

export default PropertyListNavbar;
