import { Neighborhood } from "@/models";
import { NeighborhoodBackend, responseNeighborhoodAdapter } from "@/adapters";

export const responseNeighborhoodsAdapter = (
  neighborhoods: NeighborhoodBackend[],
): Neighborhood[] => {
  const data: Neighborhood[] = [];
  if (neighborhoods.length) {
    neighborhoods.map((neighborhood) => {
      data.push(responseNeighborhoodAdapter(neighborhood));
    });
  }

  return data;
};
