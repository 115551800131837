import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import Select, { GroupBase, InputActionMeta, OptionsOrGroups, PropsValue } from "react-select";
import {
  StyleReactSelectFilters,
  customTheme,
} from "@/components/reactSelect/StyleReactSelectFilters";
import {
  CountryCodeKey,
  ListingPropertyTypeBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  SectorKey,
} from "@/keys";
import { useAppSelector } from "@/app/hooks";
import { Filters, selectAppConfig, selectFilters, selectLocations } from "@/redux/slices";
import { useLazyGetBuildingsQuery, useLazyGetZipCodesQuery } from "@/services";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useFilterSelect, useLocaleCountryCode } from "@/hooks";
import { MenuKey } from "@/utilities";
import { AdvancedFilters, handleOnBlurProps } from "../Filters";
import classNames from "classnames";
import { useTheme } from "styled-components";
import { FilterValue, Neighborhood, OptionType } from "@/models";
import {
  SelectComponents,
  SelectComponentsConfig,
} from "react-select/dist/declarations/src/components";

import { Option } from "react-select/src/filters";
import { sizeViewport } from "@/components/styledComponents";

interface SelectFilterProps {
  isAdvancedFilters: boolean;
  filterKey: keyof typeof NewsFiltersFrontKey;
  entityKey: keyof typeof NewsFiltersEntityFrontKey;
  title: string;
  placeholder: string;
  options?: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  isMulti?: true;
  isClearable?: boolean;
  isDisabled?: boolean;
  value?: PropsValue<OptionType>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  noOptionsMessage?: (obj: { inputValue: string }) => string | undefined;
  isLoading?: boolean;
  loadingMessage?: () => string;
  hideSelectedOptions?: boolean;
  components?: SelectComponentsConfig<OptionType, true, GroupBase<OptionType>>;
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  closeMenuOnSelect?: boolean;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
  tooltip?: ReactElement;
}

function SelectFilter({
  isAdvancedFilters,
  filterKey,
  entityKey,
  title,
  placeholder,
  options,
  isMulti,
  isClearable,
  isDisabled,
  value,
  onInputChange,
  noOptionsMessage,
  isLoading,
  loadingMessage,
  hideSelectedOptions,
  components,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  closeMenuOnSelect,
  setLocalFilters,
  tooltip,
}: SelectFilterProps) {
  const { handleChange } = useFilterSelect();
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { countryCode } = useLocaleCountryCode();
  const isMobile = windowWidth < sizeViewport.laptop;

  const handleOnBlur = () => {
    setLocalFilters(filtersByPortal[portal].filters);
  };

  const currentFilter = `${entityKey}.${filterKey}`;

  return containerFilterRef ? (
    <div
      className={classNames("col-12 mb-1", {
        "col-xl-3": isAdvancedFilters,
        "col-md-4": isAdvancedFilters,
        "col-lg-6": !isAdvancedFilters,
        "col-md-6": !isAdvancedFilters,
        "col-sm-12": !isAdvancedFilters,
      })}>
      {tooltip ? (
        <div className="d-flex align-items-center mb-05">
          {tooltip}
          <h6 className="fw-bold black mb-0">{title}</h6>
        </div>
      ) : (
        <h6 className="mb-05 fw-bold black ml-1">{title}</h6>
      )}

      <Select
        placeholder={placeholder}
        name={filterKey}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        styles={StyleReactSelectFilters(isMobile)}
        theme={customTheme}
        onChange={(inputValue, actionMeta) => {
          if (
            countryCode === CountryCodeKey.PR &&
            filterKey === NewsFiltersFrontKey.neighborhoodId
          ) {
            const newActionMeta = {
              ...actionMeta,
              name: (inputValue as unknown as Neighborhood)?.isNeighborhood
                ? NewsFiltersFrontKey.neighborhoodId
                : NewsFiltersFrontKey.subNeighborhoodId,
            };
            handleChange(inputValue, newActionMeta, entityKey, filtersByPortal[portal].filters);
          } else {
            handleChange(inputValue, actionMeta, entityKey, filtersByPortal[portal].filters);
          }
          if (
            filterKey === NewsFiltersFrontKey.sectorId &&
            entityKey === NewsFiltersEntityFrontKey.parcel
          ) {
            const listingInputValue: OptionType[] = [];

            inputValue.map((type) => {
              if (type?.name?.toLowerCase() === SectorKey.singleFamily) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.HOUSE,
                  label: ListingPropertyTypeBackendKey.HOUSE,
                  name: ListingPropertyTypeBackendKey.HOUSE,
                });
              } else if (type?.name?.toLowerCase() === SectorKey.multiFamily) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.APARTMENT,
                  label: ListingPropertyTypeBackendKey.APARTMENT,
                  name: ListingPropertyTypeBackendKey.APARTMENT,
                });
              } else if (type?.name?.toLowerCase() === SectorKey.industrial) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.WAREHOUSE,
                  label: ListingPropertyTypeBackendKey.WAREHOUSE,
                  name: ListingPropertyTypeBackendKey.WAREHOUSE,
                });
              } else if (type?.name?.toLowerCase() === SectorKey.land) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.LOT,
                  label: ListingPropertyTypeBackendKey.LOT,
                  name: ListingPropertyTypeBackendKey.LOT,
                });
              } else if (type?.name?.toLowerCase() === SectorKey.office) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.OFFICE,
                  label: ListingPropertyTypeBackendKey.OFFICE,
                  name: ListingPropertyTypeBackendKey.OFFICE,
                });
              } else if (type?.name?.toLowerCase() === SectorKey.retail) {
                listingInputValue.push({
                  value: ListingPropertyTypeBackendKey.BUSINESS,
                  label: ListingPropertyTypeBackendKey.BUSINESS,
                  name: ListingPropertyTypeBackendKey.BUSINESS,
                });
              } else {
                listingInputValue.push(type);
              }
            });

            handleChange(
              listingInputValue,
              {
                action: "select-option",
                option: undefined,
                name: NewsFiltersFrontKey.propertyType,
              },
              NewsFiltersEntityFrontKey.listing,
              filtersByPortal[portal].filters,
            );
          }
        }}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        value={value}
        hideSelectedOptions={hideSelectedOptions}
        onMenuClose={() => {
          if (activeSelect === currentFilter) {
            setMenuState(false);
            setActiveSelect(null);
          }
        }}
        onMenuOpen={() => {
          setMenuState(true);
          setActiveSelect(currentFilter);
        }}
        menuIsOpen={activeSelect === currentFilter && menuState}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPortalTarget={containerFilterRef.current}
        menuPosition="fixed"
        components={components}
        onBlur={() => {
          if (!isMobile) handleOnBlur();
        }}
      />
    </div>
  ) : null;
}
export default SelectFilter;
