import { Listing, ParcelCluster } from "@/models";
import Supercluster from "supercluster";

export type ClusterCustomProperties = Supercluster.ClusterProperties & {
  parcel?: ParcelCluster;
  listing?: Listing & { listings: Listing[] };
};

interface CreateClustersProps {
  parcels?: ParcelCluster[];
  listings?: Listing[];
  map: google.maps.Map;
}

export const CreateClusters = ({ parcels, listings, map }: CreateClustersProps) => {
  const zoom = map?.getZoom() as number;

  if (parcels?.length) {
    const supercluster = new Supercluster({
      radius: setRadius({ zoom }), // Radio de agrupación
      maxZoom: 17, // Nivel de zoom máximo para agrupar
    });
    const points: Supercluster.PointFeature<Supercluster.AnyProps>[] = [];

    parcels.map((parcel) => {
      if (parcel?.centroid?.coordinates[0] && parcel?.centroid?.coordinates[1]) {
        points.push({
          type: "Feature",
          properties: {
            parcel: {
              id: parcel.id,
              geometry: parcel.geometry,
              sector: parcel.sector,
              listings: parcel.listings,
            },
          },
          geometry: parcel.centroid,
        });
      }
    });

    // Agregar polígonos al objeto cluster
    supercluster.load(points);
    // // Obtener clusters visibles en el mapa actual
    // const bounds: GeoJSON.BBox = [
    //   map?.getBounds()?.getSouthWest().lng() as number,
    //   map?.getBounds()?.getSouthWest().lat() as number,
    //   map?.getBounds()?.getNorthEast().lng() as number,
    //   map?.getBounds()?.getNorthEast().lat() as number,
    // ];

    // ------- Clustering Global -----
    const bounds: GeoJSON.BBox = [-180, -85, 180, 85];

    return supercluster.getClusters(bounds, zoom);
  } else if (listings?.length) {
    const supercluster = new Supercluster({
      radius: setRadius({ zoom }), // Radio de agrupación
      maxZoom: 17, // Nivel de zoom máximo para agrupar
    });
    const points: Supercluster.PointFeature<Supercluster.AnyProps>[] = [];

    listings.map((listing) => {
      if (listing?.location?.coordinates[0] && listing?.location?.coordinates[1]) {
        const findListing = points.find(
          (point) =>
            point.geometry.coordinates[0] === listing?.location?.coordinates[0] &&
            point.geometry.coordinates[1] === listing?.location?.coordinates[1],
        );
        if (findListing) {
          findListing.properties.listing.listings.push(listing);
        } else {
          points.push({
            type: "Feature",
            properties: {
              listing: {
                ...listing,
                listings: [listing],
              },
            },
            geometry: listing.location,
          });
        }
      }
    });

    // Agregar polígonos al objeto cluster
    supercluster.load(points);
    // // Obtener clusters visibles en el mapa actual
    // const bounds: GeoJSON.BBox = [
    //   map?.getBounds()?.getSouthWest().lng() as number,
    //   map?.getBounds()?.getSouthWest().lat() as number,
    //   map?.getBounds()?.getNorthEast().lng() as number,
    //   map?.getBounds()?.getNorthEast().lat() as number,
    // ];

    // ------- Clustering Global -----
    const bounds: GeoJSON.BBox = [-180, -85, 180, 85];

    return supercluster.getClusters(bounds, zoom);
  } else {
    return [];
  }
};

const setRadius = ({ zoom = 9 }: { zoom?: number }) => {
  if (zoom < 9) {
    return 120 + (9 - zoom) * 80;
  } else if (zoom > 15) {
    return 120 * 2;
  } else {
    return 120 + (zoom - 9) * 80;
  }
};
