import { CountryCodeKey } from "@/keys";
import { UnitMeasurement } from "@/utilities";

export const countries: {
  [key in CountryCodeKey]: {
    lat: number;
    lng: number;
    lngMobile: number;
    zoom: number;
    hasAccessToPlatform: boolean;
    currency: string;
    lists: boolean;
    builtAreaUnit: UnitMeasurement;
    landAreaUnit: UnitMeasurement;
    // filterOptions: {
    //   prices: OptionType[];
    //   builtArea: {
    //     areas: OptionType[];
    //     prices: OptionType[];
    //   };
    //   landArea: {
    //     areas: OptionType[];
    //     prices: OptionType[];
    //   };
    // };
  };
} = {
  [CountryCodeKey.PR]: {
    lat: 18.250855183421933,
    lng: -65.74961757173148,
    lngMobile: -65.74961757173148 - 0.6,
    zoom: 9,
    hasAccessToPlatform: true,
    currency: "USD",
    lists: true,
    builtAreaUnit: UnitMeasurement.sqft,
    landAreaUnit: UnitMeasurement.acre,
  },
  [CountryCodeKey.AR]: {
    lat: -38.513032254603665,
    lng: -63.61593499787949,
    lngMobile: -63.61593499787949,
    zoom: 4,
    hasAccessToPlatform: false,
    currency: "ARS",
    lists: false,
    builtAreaUnit: UnitMeasurement.sqm,
    landAreaUnit: UnitMeasurement.sqm,
  },
  [CountryCodeKey.CO]: {
    lat: 4.631,
    lng: -73.5,
    lngMobile: -74.1091,
    zoom: 7,
    hasAccessToPlatform: true,
    currency: "COP",
    lists: true,
    builtAreaUnit: UnitMeasurement.sqm,
    landAreaUnit: UnitMeasurement.sqm,
  },
  [CountryCodeKey.MX]: {
    lat: 23.897744178768523,
    lng: -102.33072405993305,
    lngMobile: -102.33072405993305,
    zoom: 5,
    hasAccessToPlatform: false,
    currency: "MXN",
    lists: false,
    builtAreaUnit: UnitMeasurement.sqm,
    landAreaUnit: UnitMeasurement.sqm,
  },
  [CountryCodeKey.AE]: {
    lat: 24.3505839,
    lng: 53.9396418,
    lngMobile: 53.9396418,
    zoom: 8,
    hasAccessToPlatform: false,
    currency: "AED",
    lists: false,
    builtAreaUnit: UnitMeasurement.sqm,
    landAreaUnit: UnitMeasurement.sqm,
  },
};
