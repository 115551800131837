import React from "react";
import {
  ButtonBorderPrimary,
  ButtonPrimary,
  ButtonXCirclePrimary,
  Pill,
  baseStyles,
  sizeViewport,
} from "@/components/styledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import CarouselBasic from "@/components/carousel/CarouselBasic";
import { Parcel } from "@/models";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { NumberFormat } from "@/utilities";
import { countries } from "@/configs";
import { Spinner } from "reactstrap";
import { useGetParcelPermitsQuery, useGetParcelTransactionsQuery } from "@/services";
import {
  ParcelBackendKey,
  PermitBackendExpandKey,
  PermitBackendKey,
  PropertyBackendKey,
  SectorKey,
  TransactionBackendExpandKey,
  TransactionBackendKey,
} from "@/keys";
import { TypeInfowindow, selectAppConfig, selectMap, setInfowindow } from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

// icons
import RentableArea from "@/assets/img/icons/wide.png";
import LandIcon from "@/assets/img/icons/land.png";
import { useTranslation } from "react-i18next";
import { PlusCircle } from "react-feather";
import { useCategoryTranslations, useConvertUnits, useLocaleCountryCode } from "@/hooks";
import { useAvailableFilters } from "@/hooks/filters/useAvailableFilters";
import { userIsLoggedIn } from "@/redux/actions";

type ParcelInfowindowProps = {
  onCloseIndowindow: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  parcel: Parcel;
  clickLocationProfile: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  toggleListsModal: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function ParcelInfowindow({
  onCloseIndowindow,
  parcel,
  clickLocationProfile,
  toggleListsModal,
}: ParcelInfowindowProps) {
  const { t } = useTranslation("translation");
  const translation = t("map.infoWindow", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { primary, parcelColors, listingColors, defaultColors, transactionColors } = useTheme();
  const { countryCode } = useLocaleCountryCode();
  const dispatch = useAppDispatch();
  const { convertBuiltArea, convertLandArea, languageBuiltAreaUnits, languageLandAreaUnits } =
    useConvertUnits();
  const { availableFilters } = useAvailableFilters();
  const {
    infowindow: { listings, countListings },
  } = useAppSelector(selectMap);

  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;

  const { data: permits, isFetching: isFetchingPermits } = useGetParcelPermitsQuery(
    {
      parcelIds: [parcel.id] as string[],

      include: [PermitBackendKey.id, `${PermitBackendExpandKey.parcel}.${ParcelBackendKey.id}`],

      countryCode,
    },
    {
      skip: !parcel.id || !availableFilters.permit || !dispatch(userIsLoggedIn()),
      selectFromResult: ({ data, isFetching }) => {
        let newPermits = null;

        if (data?.length) {
          const count = data.length;
          if (count !== 0) {
            newPermits = {
              count: count,
            };
          }
        }
        return { data: newPermits, isFetching };
      },
    },
  );

  const { data: transactions, isFetching: isFetchingTransactions } = useGetParcelTransactionsQuery(
    {
      parcelIds: [parcel.id] as string[],

      include: [
        TransactionBackendKey.id,
        `${TransactionBackendExpandKey.properties}.${PropertyBackendKey.parcelId}`,
      ],

      countryCode,
    },
    {
      skip: !parcel.id || !availableFilters.transaction || !dispatch(userIsLoggedIn()),
      selectFromResult: ({ data, isFetching }) => {
        let newTransactions = null;

        if (data?.length) {
          const count = data.filter(
            (transaction) =>
              transaction?.transactionProperties?.filter(
                (property) => property.property?.parcelId === parcel.id,
              ).length,
          ).length;

          if (count !== 0) {
            newTransactions = {
              count: count,
            };
          }
        }
        return { data: newTransactions, isFetching };
      },
    },
  );

  const handleListing = () => {
    dispatch(
      setInfowindow({
        show: true,
        id: listings[0].id,
        type: TypeInfowindow.listing,
        countListings,
        listings,
        parcelId: parcel.id,
        activeListing: listings[0].active,
      }),
    );
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        }}>
        <div className="p-05 d-flex align-items-center" style={{ marginRight: "30px" }}>
          <ButtonPrimary
            disableBorderRadius
            style={{
              // padding: "8px 10px",
              borderTopLeftRadius: "0.3rem",
              borderBottomLeftRadius: "0.3rem",
            }}
            className="text-uppercase w-100">
            {translation.buttons.tabs.property}
          </ButtonPrimary>

          <ButtonBorderPrimary
            disableBorderRadius
            style={{
              // padding: "8px 10px",
              borderTopRightRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
            }}
            className="text-uppercase w-100"
            disabled={!countListings}
            onClick={() => (countListings && listings.length ? handleListing() : null)}>
            {/* {isFetchingListingsCount ? (
              <Spinner size="sm" style={{ width: "12px", height: "12px" }} />
            ) : ( */}
            <span>{`${countListings}`}</span>
            {/* )} */}

            <span className="ml-05">{translation.buttons.tabs.listings}</span>
          </ButtonBorderPrimary>
        </div>
        {countries[countryCode].lists ? (
          <div
            style={{
              position: "absolute",
              top: "52px",
              right: "0",
              zIndex: 2,
            }}>
            <ButtonPrimary
              borderRadius={`${baseStyles.borderRadius} 0 0 ${baseStyles.borderRadius}`}
              minWidth="40px"
              className="property-detail-btn-circle"
              onClick={(e) => {
                toggleListsModal(e);
              }}>
              <PlusCircle size={20} />
            </ButtonPrimary>
          </div>
        ) : null}

        <CarouselBasic
          imgs={parcel.photos?.length ? parcel?.photos : []}
          height={isMobile ? "150px" : ""}
        />
        {parcel.sector?.name ? (
          <div
            style={{
              position: "absolute",
              bottom: "0",
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.8127844887955182) 0%, rgba(255,255,255,0) 100%)",
              width: "100%",
              height: "50%",
            }}
            className="d-flex align-items-end p-1">
            <div className="d-flex align-items-center">
              <Pill
                color={defaultColors.background}
                backgroundColor={sectorColorsMapper({
                  sectorName: parcel.sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}
                borderColor={sectorColorsMapper({
                  sectorName: parcel.sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}>
                <p className="mb-0">
                  {parcel.sector?.name ? languageSector(parcel.sector.name) : "---"}
                </p>
              </Pill>
            </div>
          </div>
        ) : null}
      </div>

      <ButtonXCirclePrimary
        size="18px"
        margin="10px"
        onClick={(e) => onCloseIndowindow(e)}
        position="absolute"
        top="0"
        style={{
          zIndex: 1,
          backgroundColor: primary.contrastColor,
          borderRadius: "100%",
          height: "18px",
          width: "18px",
        }}>
        <FontAwesomeIcon icon={faCircleXmark} />
      </ButtonXCirclePrimary>

      <div style={{ padding: "10px" }}>
        <h4 className="font-weight-bold text-overflow-ellipsis" style={{ color: "black" }}>
          {parcel.sector?.name.toLowerCase() === SectorKey.singleFamily
            ? "---"
            : parcel.name ?? "---"}
        </h4>
        <p
          className="font-weight-bold text-overflow-ellipsis mb-0"
          style={{
            textTransform: "capitalize",
          }}>
          {parcel?.address?.toLowerCase() ?? "---"}
        </p>

        <div className="d-flex align-items-center flex-wrap">
          {parcel.properties &&
          parcel.properties?.reduce((a, c) => a + parseFloat(c?.builtArea || "0"), 0) !== 0 ? (
            <>
              <div className="d-flex align-items-center mr-1">
                <img
                  src={RentableArea}
                  alt="RentableArea"
                  width={14}
                  height={14}
                  className="mr-05"
                />
                <p className="mb-0" style={{ marginTop: "2px" }}>
                  {NumberFormat({
                    value: convertBuiltArea({
                      value: parcel.properties?.reduce(
                        (a, c) => a + parseFloat(c?.builtArea || "0"),
                        0,
                      ),
                    }),
                    prefix: "",
                    suffix: ` ${languageBuiltAreaUnits}`,
                  })}
                </p>
              </div>
            </>
          ) : null}

          <div className="d-flex align-items-center mr-1">
            <img src={LandIcon} alt="landArea" width={14} height={14} className="mr-05" />
            <p className="mb-0" style={{ marginTop: "2px" }}>
              {parcel.landArea
                ? NumberFormat({
                    value: convertLandArea({
                      value: parcel.landArea,
                    }),
                    prefix: "",
                    suffix: ` ${languageLandAreaUnits}`,
                    decimalScale: 2,
                  })
                : "---"}
            </p>
          </div>

          {isFetchingPermits || isFetchingTransactions ? (
            <div className="d-flex align-items-center">
              <Spinner
                size="sm"
                className="mr-05"
                style={{ width: "14px", height: "14px", color: primary.color }}
              />
            </div>
          ) : null}
        </div>

        <div className="d-flex align-items-center flex-wrap mt-05">
          {countListings ? (
            <div className="d-flex align-items-center mr-1">
              <div
                className="sectorMarker"
                style={{
                  backgroundColor: listingColors.status.multiple,
                  marginRight: "4px",
                }}
              />
              <span>
                {NumberFormat({
                  value: countListings,
                  suffix: ` ${t("map.infoWindow.listingsCount.title", {
                    count: countListings,
                  })}`,
                })}
              </span>
            </div>
          ) : null}

          {permits ? (
            <div className="d-flex align-items-center mr-1">
              <div
                className="sectorMarker"
                style={{
                  backgroundColor: primary.color,
                  marginRight: "4px",
                }}
              />
              <span>
                {NumberFormat({
                  value: permits.count,
                  suffix: ` ${t("map.infoWindow.permitsCount.title", {
                    count: permits.count,
                  })}`,
                })}
              </span>
            </div>
          ) : null}

          {transactions ? (
            <div className="d-flex align-items-center">
              <div
                className="sectorMarker"
                style={{
                  backgroundColor: transactionColors.count,
                  marginRight: "4px",
                }}
              />
              <span>
                {NumberFormat({
                  value: transactions.count,
                  suffix: ` ${t("map.infoWindow.transactionCount.title", {
                    count: transactions.count,
                  })}`,
                })}
              </span>
            </div>
          ) : null}
        </div>

        <div className="row mt-1">
          <div className="col-12 d-flex justify-content-center">
            <ButtonPrimary width="100%" paddingY="6px" onClick={(e) => clickLocationProfile(e)}>
              {translation.buttons.propertyProfile}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </>
  );
}

export default ParcelInfowindow;
