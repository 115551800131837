export enum UnitTypeKey {
  Apartment = "Apartment",
  Edifice = "Edifice",
  House = "House",
  Office = "Office",
  Parking = "Parking",
  PentHouse = "PentHouse",
  Store = "Store",
  Suite = "Suite",
  Unit = "Unit",
}
