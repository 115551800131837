export enum SectorBackendKey {
  id = "id",
  name = "name",
}

export type SectorBackendKeys = keyof typeof SectorBackendKey;

export enum PermitSectorBackendKey {
  id = "id",
  name = "name",
  nameEn = "nameEn",
}

export type PermitSectorBackendKeys = keyof typeof PermitSectorBackendKey;
