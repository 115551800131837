import { LuxuryScoreBackendKey, LuxuryScoreKey } from "@/keys";
import { OptionType } from "@/models";
import { useTranslation } from "react-i18next";

export const useLuxuryScore = () => {
  const { t } = useTranslation("luxuryScore");
  const translationLuxuryScore = t("luxuryScore", { returnObjects: true });

  const LuxuryScoreOptions: OptionType[] = [
    {
      value: LuxuryScoreBackendKey.Q1,
      label: translationLuxuryScore[LuxuryScoreKey.Q1].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q2,
      label: translationLuxuryScore[LuxuryScoreKey.Q2].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q3,
      label: translationLuxuryScore[LuxuryScoreKey.Q3].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q4,
      label: translationLuxuryScore[LuxuryScoreKey.Q4].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q5,
      label: translationLuxuryScore[LuxuryScoreKey.Q5].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q6,
      label: translationLuxuryScore[LuxuryScoreKey.Q6].tooltip,
    },
  ];

  const LuxuryScoreOptionsFilters: OptionType[] = [
    {
      value: LuxuryScoreBackendKey.Q1,
      label: translationLuxuryScore[LuxuryScoreKey.Q1].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q1].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q2,
      label: translationLuxuryScore[LuxuryScoreKey.Q2].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q2].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q3,
      label: translationLuxuryScore[LuxuryScoreKey.Q3].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q3].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q4,
      label: translationLuxuryScore[LuxuryScoreKey.Q4].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q4].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q5,
      label: translationLuxuryScore[LuxuryScoreKey.Q5].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q5].tooltip,
    },
    {
      value: LuxuryScoreBackendKey.Q6,
      label: translationLuxuryScore[LuxuryScoreKey.Q6].title,
      tooltip: translationLuxuryScore[LuxuryScoreKey.Q6].tooltip,
    },
  ];

  return {
    LuxuryScoreOptions,
    LuxuryScoreOptionsFilters,
  };
};
