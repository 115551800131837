import React from "react";
import { useAppSelector } from "@/app/hooks";
import { CategoricValueFilters, OptionType } from "@/models";
import { Pill } from "@/components/styledComponents";
import {
  useCategoryTranslations,
  useConvertUnits,
  useDefaultFilterOptions,
  useLocaleCountryCode,
  useLuxuryScore,
} from "@/hooks";
import DatePicker from "react-datepicker";
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexyCircle";
import { Check, Info } from "react-feather";
import {
  CategoricFiltersKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  SectorKey,
} from "@/keys";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { Filters, OnlyKeyEntityFilter, selectAppConfig, selectFilters } from "@/redux/slices";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { useTheme } from "styled-components";
import {
  CustomMultiValueLabel,
  CustomOption,
  CustomPlaceholder,
} from "@/components/reactSelect/CustomComponents";
import { AdvancedFilters, handleOnBlurProps } from "../../../Filters";
import classNames from "classnames";
import { useGetAmenitiesQuery, useLazyGetSynonymAmenitiesQuery } from "@/services";
import { debounce } from "lodash";
import { AmenityTypeBackend, AmenityTypeBackendKey } from "@/adapters";
import SelectFilter from "../../../components/SelectFilter";
import AsyncSelectFilter from "../../../components/AsyncSelectFilter";
import InputWithSelect from "../../../components/InputWithSelect";
import RadioButton from "../../../components/radioButton/RadioButton";
// import LuxuryScoreTooltip from "@/components/luxuryScore/LuxuryScoreTooltip";

type PropertySectionProps = {
  isAdvancedFilters: boolean;
  advancedParcelFilters: AdvancedFilters["parcel"]["filters"];
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  localFilters: Filters;
  availableParcelFilters: {
    show: boolean;
    landArea: boolean;
    sector: boolean;
    subsector: boolean;
    microsector: boolean;
  };
  advancedUnitFilters: AdvancedFilters["property"]["filters"];
  availableUnitFilters: {
    show: boolean;
    rooms: boolean;
    toilets: boolean;
    parkingSpaces: boolean;
    unitType: boolean;
    buildingDate: boolean;
  };
  advancedListingFilters: AdvancedFilters["listing"]["filters"];
  availableListingFilters: {
    show: boolean;
    isForRent: boolean;
    active: boolean;
    floodZone: boolean;
    listingPrice: boolean;
    pricePerBuiltSqm: boolean;
    pricePerLotSqm: boolean;
    listingRentableArea: boolean;
    lotArea: boolean;
    listingSector: boolean;
    listingPublicationDate: boolean;
    luxuryScore: boolean;
    buildingDate: boolean;
    yearBuilt: boolean;
    bedrooms: boolean;
    bathrooms: boolean;
    garages: boolean;
    amenities: boolean;
    amenitiesRooms: boolean;
    amenitiesViews: boolean;
    daysOnMarket: boolean;
  };
  onCheckboxChange: (params: { name: string; value: boolean; entity: OnlyKeyEntityFilter }) => void;
  handleOnBlur: (params: handleOnBlurProps[]) => void;
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
};
const PropertySection = ({
  isAdvancedFilters,
  advancedParcelFilters,
  handleMinMaxChange,
  localFilters,
  availableParcelFilters,
  advancedUnitFilters,
  availableUnitFilters,
  advancedListingFilters,
  availableListingFilters,
  onCheckboxChange,
  handleOnBlur,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: PropertySectionProps) => {
  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const unitTypeLanguage = t("unitType", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();

  const { defaultColors, parcelColors, common, primary, listingColors } = useTheme();
  const { languageBuiltAreaUnits, languageLandAreaUnits } = useConvertUnits();
  const reduxFilters = filtersByPortal[portal].filters;

  // -------------- dependent options Select
  const dependentOptionsMap = (value: string) => {
    switch (value) {
      case CategoricFiltersKey.sectors:
        return filtersByPortal[portal].categoricFilters.sectors.map((option) => {
          const { value, label, name } = option;
          return { value, label, name };
        });
      case CategoricFiltersKey.subsectors:
        if (reduxFilters.parcel.categoric?.sectorId.value) {
          // si sector tiene valor
          const subS = filtersByPortal[portal].categoricFilters.subsectors.filter(
            (subsector) =>
              (!Array.isArray(reduxFilters.parcel.categoric?.sectorId.value) &&
                subsector.sectorId === reduxFilters.parcel.categoric?.sectorId.value?.value) ||
              (Array.isArray(reduxFilters.parcel.categoric?.sectorId.value) &&
                reduxFilters.parcel.categoric?.sectorId.value.length === 1 &&
                subsector.sectorId === reduxFilters.parcel.categoric?.sectorId.value[0]?.value),
          );
          if (subS.length) {
            return subS.map((option) => {
              const { value, label, sectorId } = option;
              return { value, label, sectorId };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.subsectors.map((option) => {
            const { value, label, sectorId } = option;
            return { value, label, sectorId };
          });
        }
      case CategoricFiltersKey.microsectors:
        if (reduxFilters.parcel.categoric?.subsectorId.value) {
          // si subsector tiene valor
          const micro = filtersByPortal[portal].categoricFilters.microsectors.filter(
            (microsector) =>
              (!Array.isArray(reduxFilters.parcel.categoric?.subsectorId.value) &&
                microsector.subsectorId ===
                  reduxFilters.parcel.categoric?.subsectorId.value?.value) ||
              (Array.isArray(reduxFilters.parcel.categoric?.subsectorId.value) &&
                reduxFilters.parcel.categoric?.subsectorId.value.length === 1 &&
                microsector.subsectorId ===
                  reduxFilters.parcel.categoric?.subsectorId.value[0]?.value),
          );
          if (micro.length) {
            return micro.map((option) => {
              const { value, label, subsectorId } = option;
              return { value, label, subsectorId };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.microsectors.map((option) => {
            const { value, label, subsectorId } = option;
            return { value, label, subsectorId };
          });
        }
      default:
        return [];
    }
  };

  const customSort = (
    a: CategoricValueFilters,
    b: CategoricValueFilters,
    selected: Array<string>,
  ) => {
    if (selected.includes(a.value)) {
      return -1;
    }
    if (selected.includes(b.value)) {
      return 1;
    }
    return 0;
  };

  const { LuxuryScoreOptionsFilters } = useLuxuryScore();

  const { languageCode } = useLocaleCountryCode();
  const { prices, builtArea, landArea, rooms, toilets, parkings } = useDefaultFilterOptions();
  const { data: amenities } = useGetAmenitiesQuery(
    { language: languageCode },
    { skip: !filtersByPortal[portal].filters.listingAmenity.categoric?.amenityId },
  );
  const [trigger] = useLazyGetSynonymAmenitiesQuery();

  const loadAmenitiesOptions = debounce(
    (
      inputValue: string,
      callback: (options: OptionType[]) => void,
      amenityType: AmenityTypeBackend,
    ) => {
      trigger({ language: languageCode, search: inputValue, amenityType }).then((response) => {
        const { data } = response;
        if (data?.amenities && amenityType === null) {
          callback(data.amenities);
        }
        if (data?.rooms && amenityType === AmenityTypeBackendKey.room) {
          callback(data.rooms);
        }
        if (data?.views && amenityType === AmenityTypeBackendKey.view) {
          callback(data.views);
        }
      });
    },
    400,
  );

  return (
    <>
      <div className="row px-1">
        <div className="col-12">
          <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
            <h5 className="black fw-bold text-uppercase mb-0 mr-2">
              {language.menuFilters.parcel}
            </h5>
            <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
          </div>
        </div>
        {isAdvancedFilters &&
          availableParcelFilters.sector &&
          advancedParcelFilters[NewsFiltersFrontKey.sectorId] && (
            <SelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.sectorId}
              entityKey={NewsFiltersEntityFrontKey.parcel}
              title={language.filters.sector.title}
              placeholder={language.filters.sector.placeholder}
              options={dependentOptionsMap(CategoricFiltersKey.sectors).sort((a, b) =>
                customSort(
                  a,
                  b,
                  Array.isArray(reduxFilters.parcel.categoric?.sectorId.value)
                    ? (reduxFilters.parcel.categoric?.sectorId.value?.map(
                        (option) => option.value,
                      ) as Array<string>)
                    : [],
                ),
              )}
              isClearable={true}
              value={reduxFilters.parcel.categoric?.sectorId.value}
              isMulti
              isDisabled={!dependentOptionsMap(CategoricFiltersKey.sectors).length}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Placeholder: ({ children, ...props }) => (
                  <CustomPlaceholder
                    {...props}>{`${language.filters.sector.placeholder}`}</CustomPlaceholder>
                ),
                Option: ({ children, ...props }) => {
                  return (
                    <components.Option {...props}>
                      <div className="d-flex align-items-center">
                        <Pill
                          style={{ display: "flex", alignItems: "center", padding: "2px 6px" }}
                          color={defaultColors.background}
                          backgroundColor={sectorColorsMapper({
                            sectorName: props.data.name ?? null,
                            sectorColor: parcelColors.sectors,
                            fillColor: true,
                          })}
                          borderColor={sectorColorsMapper({
                            sectorName: props.data.name ?? null,
                            sectorColor: parcelColors.sectors,
                            fillColor: true,
                          })}>
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                              backgroundColor: props.isSelected
                                ? "white"
                                : sectorColorsMapper({
                                    sectorName: props.data.name ?? null,
                                    sectorColor: parcelColors.sectors,
                                    fillColor: true,
                                  }),
                              border: `1.5px solid white`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            {props.isSelected ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  color: sectorColorsMapper({
                                    sectorName: props.data.name ?? null,
                                    sectorColor: parcelColors.sectors,
                                    fillColor: true,
                                  }),
                                  fontSize: "12px",
                                }}
                              />
                            ) : null}
                          </div>
                          <p className="mb-0 ml-05">
                            {languageSector(props.data.name ? props.data.name : props.data.label)}
                          </p>
                        </Pill>
                      </div>
                    </components.Option>
                  );
                },
                MultiValueContainer: ({ children, ...props }) => {
                  const {
                    data: { label, value, name },
                    selectProps: { value: selectedValues },
                  } = props;
                  return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
                    <Pill
                      style={{ padding: "4px 6px" }}
                      color={defaultColors.background}
                      backgroundColor={sectorColorsMapper({
                        sectorName: name ?? null,
                        sectorColor: parcelColors.sectors,
                        fillColor: true,
                      })}
                      borderColor={sectorColorsMapper({
                        sectorName: name ?? null,
                        sectorColor: parcelColors.sectors,
                        fillColor: true,
                      })}>
                      {languageSector(name ? name : label) +
                        (selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``)}
                    </Pill>
                  ) : null;
                },
                MultiValueRemove: () => null,
              }}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}
        {isAdvancedFilters &&
          availableParcelFilters.subsector &&
          advancedParcelFilters[NewsFiltersFrontKey.subsectorId] && (
            <SelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.subsectorId}
              entityKey={NewsFiltersEntityFrontKey.parcel}
              title={language.filters.subsector.title}
              placeholder={
                dependentOptionsMap(CategoricFiltersKey.subsectors).length
                  ? language.filters.subsector.placeholder
                  : language.filters.subsector.noOptions
              }
              options={
                filtersByPortal[portal].categoricFilters.subsectors
                  ? dependentOptionsMap(CategoricFiltersKey.subsectors).sort((a, b) =>
                      customSort(
                        a,
                        b,
                        Array.isArray(reduxFilters.parcel.categoric?.subsectorId.value)
                          ? (reduxFilters.parcel.categoric?.subsectorId.value?.map(
                              (option) => option.value,
                            ) as Array<string>)
                          : [],
                      ),
                    )
                  : []
              }
              isClearable={true}
              value={reduxFilters.parcel.categoric?.subsectorId.value}
              isMulti
              isDisabled={
                !reduxFilters.parcel.categoric?.sectorId.value ||
                !dependentOptionsMap(CategoricFiltersKey.subsectors).length
              }
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Placeholder: ({ children, ...props }) => (
                  <CustomPlaceholder
                    {...props}>{`${language.filters.subsector.placeholder}`}</CustomPlaceholder>
                ),
                Option: ({ children, ...props }) => (
                  <CustomOption {...props}>{children}</CustomOption>
                ),
                MultiValueLabel: ({ children, ...props }) => (
                  <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
                ),
                MultiValueRemove: () => null,
              }}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}
        {isAdvancedFilters &&
          availableParcelFilters.microsector &&
          advancedParcelFilters[NewsFiltersFrontKey.microsectorId] && (
            <SelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.microsectorId}
              entityKey={NewsFiltersEntityFrontKey.parcel}
              title={language.filters.microsector.title}
              placeholder={
                dependentOptionsMap(CategoricFiltersKey.microsectors).length
                  ? language.filters.microsector.placeholder
                  : language.filters.microsector.noOptions
              }
              options={
                filtersByPortal[portal].categoricFilters.microsectors
                  ? dependentOptionsMap(CategoricFiltersKey.microsectors).sort((a, b) =>
                      customSort(
                        a,
                        b,
                        Array.isArray(reduxFilters.parcel.categoric?.microsectorId.value)
                          ? (reduxFilters.parcel.categoric?.microsectorId.value?.map(
                              (option) => option.value,
                            ) as Array<string>)
                          : [],
                      ),
                    )
                  : []
              }
              isClearable={true}
              value={reduxFilters.parcel.categoric?.microsectorId.value}
              isMulti
              isDisabled={
                !reduxFilters.parcel.categoric?.subsectorId.value ||
                !dependentOptionsMap(CategoricFiltersKey.microsectors).length
              }
              closeMenuOnSelect={false}
              // noOptionsMessage={() => null}
              hideSelectedOptions={false}
              components={{
                Placeholder: ({ children, ...props }) => (
                  <CustomPlaceholder
                    {...props}>{`${language.filters.microsector.placeholder}`}</CustomPlaceholder>
                ),
                Option: ({ children, ...props }) => (
                  <CustomOption {...props}>{children}</CustomOption>
                ),
                MultiValueLabel: ({ children, ...props }) => (
                  <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
                ),
                MultiValueRemove: () => null,
              }}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.builtArea] &&
          availableListingFilters.listingRentableArea &&
          localFilters.listing.numeric?.builtArea && (
            <>
              <div
                className={classNames("col-12 mb-1", {
                  "col-xl-3": isAdvancedFilters,
                  "col-md-4": isAdvancedFilters,
                  "col-xl-6": !isAdvancedFilters,
                  "col-lg-6": !isAdvancedFilters,
                  "col-md-6": !isAdvancedFilters,
                  "col-sm-12": !isAdvancedFilters,
                })}>
                <InputWithSelect
                  options={builtArea.areas}
                  label={language.filters.listingRentableArea.title}
                  filter={localFilters.listing.numeric?.builtArea}
                  placeholder={{
                    min: language.filters.listingRentableArea.minPlaceholder,
                    max: language.filters.listingRentableArea.maxPlaceholder,
                  }}
                  handleMinMaxChange={handleMinMaxChange}
                  handleOnBlur={() =>
                    handleOnBlur([
                      {
                        entity: NewsFiltersEntityFrontKey.listing,
                        filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                      },
                      {
                        entity: NewsFiltersEntityFrontKey.property,
                        filterTypeValue: localFilters[NewsFiltersEntityFrontKey.property],
                      },
                    ])
                  }
                  reduxFilters={{
                    originalFilter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.builtArea],
                    matchFilter: reduxFilters.property.numeric?.[NewsFiltersFrontKey.totalArea],
                  }}
                  filterKeys={{
                    name: NewsFiltersFrontKey.builtArea,
                    entity: NewsFiltersEntityFrontKey.listing,
                    type: NewsFiltersTypeFrontKey.numeric,
                  }}
                />
              </div>
            </>
          )}

        {isAdvancedFilters &&
          availableParcelFilters.landArea &&
          localFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea] &&
          advancedParcelFilters[NewsFiltersFrontKey.landArea] && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}>
              <InputWithSelect
                options={landArea.areas}
                label={language.filters.landArea.title}
                filter={localFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea]}
                placeholder={{
                  min: language.filters.landArea.minPlaceholder,
                  max: language.filters.landArea.maxPlaceholder,
                }}
                handleMinMaxChange={handleMinMaxChange}
                handleOnBlur={() =>
                  handleOnBlur([
                    {
                      entity: NewsFiltersEntityFrontKey.listing,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                    },
                    {
                      entity: NewsFiltersEntityFrontKey.parcel,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.parcel],
                    },
                  ])
                }
                reduxFilters={{
                  originalFilter: reduxFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea],
                  matchFilter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.lotArea],
                }}
                filterKeys={{
                  name: NewsFiltersFrontKey.landArea,
                  entity: NewsFiltersEntityFrontKey.parcel,
                  type: NewsFiltersTypeFrontKey.numeric,
                }}
              />
            </div>
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.price] &&
          availableListingFilters.listingPrice &&
          localFilters.listing.numeric?.price && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-xl-6": !isAdvancedFilters,
                "col-lg-6": !isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}>
              <InputWithSelect
                options={prices}
                label={language.filters.listingPrice.title}
                filter={localFilters.listing.numeric?.price}
                placeholder={{
                  min: language.filters.listingPrice.minPlaceholder,
                  max: language.filters.listingPrice.maxPlaceholder,
                }}
                handleMinMaxChange={handleMinMaxChange}
                handleOnBlur={() =>
                  handleOnBlur([
                    {
                      entity: NewsFiltersEntityFrontKey.listing,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                    },
                    {
                      entity: NewsFiltersEntityFrontKey.transaction,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.transaction],
                    },
                  ])
                }
                reduxFilters={{
                  originalFilter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.price],
                  matchFilter:
                    reduxFilters.transaction.numeric?.[NewsFiltersFrontKey.transactionPrice],
                }}
                filterKeys={{
                  name: NewsFiltersFrontKey.price,
                  entity: NewsFiltersEntityFrontKey.listing,
                  type: NewsFiltersTypeFrontKey.numeric,
                }}
              />
            </div>
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.pricePerBuiltSqm] &&
          availableListingFilters.pricePerBuiltSqm &&
          localFilters.listing.numeric?.price && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-xl-4": !isAdvancedFilters,
                "col-lg-6": !isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}>
              <InputWithSelect
                options={builtArea.prices}
                label={`${language.filters.listingPricePerBuiltSqm.title} (${languageBuiltAreaUnits})`}
                filter={localFilters.listing.numeric?.pricePerBuiltSqm}
                placeholder={{
                  min: language.filters.listingPricePerBuiltSqm.minPlaceholder,
                  max: language.filters.listingPricePerBuiltSqm.maxPlaceholder,
                }}
                handleMinMaxChange={handleMinMaxChange}
                handleOnBlur={() =>
                  handleOnBlur([
                    {
                      entity: NewsFiltersEntityFrontKey.listing,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                    },
                    {
                      entity: NewsFiltersEntityFrontKey.transaction,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.transaction],
                    },
                  ])
                }
                reduxFilters={{
                  originalFilter:
                    reduxFilters.listing.numeric?.[NewsFiltersFrontKey.pricePerBuiltSqm],
                  matchFilter:
                    reduxFilters.transaction.numeric?.[
                      NewsFiltersFrontKey.transactionPricePerBuiltSqm
                    ],
                }}
                filterKeys={{
                  name: NewsFiltersFrontKey.pricePerBuiltSqm,
                  entity: NewsFiltersEntityFrontKey.listing,
                  type: NewsFiltersTypeFrontKey.numeric,
                }}
              />
            </div>
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.pricePerLotSqm] &&
          availableListingFilters.pricePerLotSqm &&
          localFilters.listing.numeric?.price && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-xl-4": !isAdvancedFilters,
                "col-lg-6": !isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}>
              <InputWithSelect
                options={landArea.prices}
                label={`${language.filters.listingPricePerLotSqm.title} (${languageLandAreaUnits})`}
                filter={localFilters.listing.numeric?.pricePerLotSqm}
                placeholder={{
                  min: language.filters.listingPricePerLotSqm.minPlaceholder,
                  max: language.filters.listingPricePerLotSqm.maxPlaceholder,
                }}
                handleMinMaxChange={handleMinMaxChange}
                handleOnBlur={() =>
                  handleOnBlur([
                    {
                      entity: NewsFiltersEntityFrontKey.listing,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                    },
                    {
                      entity: NewsFiltersEntityFrontKey.transaction,
                      filterTypeValue: localFilters[NewsFiltersEntityFrontKey.transaction],
                    },
                  ])
                }
                reduxFilters={{
                  originalFilter:
                    reduxFilters.listing.numeric?.[NewsFiltersFrontKey.pricePerLotSqm],
                  matchFilter:
                    reduxFilters.transaction.numeric?.[
                      NewsFiltersFrontKey.transactionPricePerLotSqm
                    ],
                }}
                filterKeys={{
                  name: NewsFiltersFrontKey.pricePerLotSqm,
                  entity: NewsFiltersEntityFrontKey.listing,
                  type: NewsFiltersTypeFrontKey.numeric,
                }}
              />
            </div>
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.luxuryScore] &&
          availableListingFilters.luxuryScore &&
          reduxFilters.listing.categoric?.[NewsFiltersFrontKey.luxuryScore] && (
            <RadioButton
              label={language.filters.luxuryScore.title}
              exactMatch={{
                defaultValue: true,
                show: false,
              }}
              options={LuxuryScoreOptionsFilters}
              showTooltip={true}
              isAdvancedFilters={isAdvancedFilters}
              filter={{
                filter: reduxFilters.listing.categoric?.[NewsFiltersFrontKey.luxuryScore],
                name: NewsFiltersFrontKey.luxuryScore,
                entity: NewsFiltersEntityFrontKey.listing,
                type: NewsFiltersTypeFrontKey.categoric,
                reduxFilters: reduxFilters,
                setLocalFilters: setLocalFilters,
              }}
              // tooltipLabel={
              //   <>
              //     <Info
              //       className="mr-1 cursor-pointer"
              //       size={15}
              //       style={{ color: primary.color }}
              //       id="infoLuxuryScores"
              //     />
              //     <LuxuryScoreTooltip target="infoLuxuryScores" />
              //   </>
              // }
            />
          )}

        {isAdvancedFilters &&
          advancedUnitFilters[NewsFiltersFrontKey.unitType] &&
          availableUnitFilters.unitType &&
          reduxFilters.property.categoric?.unitType && (
            <SelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.unitType}
              entityKey={NewsFiltersEntityFrontKey.property}
              title={language.filters.unitType.title}
              placeholder={language.filters.unitType.placeholder}
              options={filtersByPortal[portal].categoricFilters.unitTypes.map((unitType) => ({
                value: unitType.value,
                label:
                  unitTypeLanguage[unitType.label as keyof typeof unitTypeLanguage] ??
                  unitType.label,
              }))}
              isDisabled={!filtersByPortal[portal].categoricFilters.unitTypes.length}
              isMulti
              isClearable={true}
              closeMenuOnSelect={false}
              components={{
                Placeholder: ({ children, ...props }) => (
                  <CustomPlaceholder
                    {...props}>{`${language.filters.unitType.placeholder}`}</CustomPlaceholder>
                ),
                Option: ({ children, ...props }) => (
                  <CustomOption {...props}>{children}</CustomOption>
                ),
                MultiValueLabel: ({ children, ...props }) => (
                  <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
                ),
                MultiValueRemove: () => null,
              }}
              value={reduxFilters.property.categoric?.unitType?.value}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.rooms] &&
          availableListingFilters.bedrooms &&
          reduxFilters.listing.numeric?.[NewsFiltersFrontKey.rooms] && (
            <RadioButton
              label={language.filters.bedrooms.title}
              exactMatch={{
                defaultValue: false,
                show: true,
              }}
              options={rooms}
              isAdvancedFilters={isAdvancedFilters}
              filter={{
                filter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.rooms],
                name: NewsFiltersFrontKey.rooms,
                entity: NewsFiltersEntityFrontKey.listing,
                type: NewsFiltersTypeFrontKey.numeric,
                handleMinMaxChange: handleMinMaxChange,
              }}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.toilets] &&
          availableListingFilters.bathrooms &&
          reduxFilters.listing.numeric?.[NewsFiltersFrontKey.toilets] && (
            <RadioButton
              label={language.filters.bathrooms.title}
              exactMatch={{
                defaultValue: false,
                show: true,
              }}
              options={toilets}
              isAdvancedFilters={isAdvancedFilters}
              filter={{
                filter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.toilets],
                name: NewsFiltersFrontKey.toilets,
                entity: NewsFiltersEntityFrontKey.listing,
                type: NewsFiltersTypeFrontKey.numeric,
                handleMinMaxChange: handleMinMaxChange,
              }}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.parkingSpaces] &&
          availableListingFilters.garages &&
          reduxFilters.listing.numeric?.[NewsFiltersFrontKey.parkingSpaces] && (
            <RadioButton
              label={language.filters.garages.title}
              exactMatch={{
                defaultValue: false,
                show: true,
              }}
              options={parkings}
              isAdvancedFilters={isAdvancedFilters}
              filter={{
                filter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.parkingSpaces],
                name: NewsFiltersFrontKey.parkingSpaces,
                entity: NewsFiltersEntityFrontKey.listing,
                type: NewsFiltersTypeFrontKey.numeric,
                handleMinMaxChange: handleMinMaxChange,
              }}
            />
          )}

        {isAdvancedFilters &&
          availableListingFilters.yearBuilt &&
          reduxFilters.listing.numeric?.[NewsFiltersFrontKey.yearBuilt] &&
          advancedListingFilters[NewsFiltersFrontKey.yearBuilt] && (
            <div
              className={classNames("col-12 mb-1", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}>
              <h6 className="mb-05 fw-bold black ml-1">{language.filters.yearBuilt.title}</h6>
              <div className="row">
                <div className="col-md-6 col-12">
                  <DatePicker
                    portalId="root-portal-filters"
                    placeholderText={language.filters.yearBuilt.minPlaceholder}
                    selected={
                      reduxFilters.listing.numeric?.yearBuilt.value.min
                        ? new Date(Number(reduxFilters.listing.numeric?.yearBuilt.value.min), 0)
                        : null
                    }
                    onChange={(date: Date) => {
                      const number = date.getFullYear();
                      handleMinMaxChange({
                        name: NewsFiltersFrontKey.yearBuilt,
                        value: { min: number },
                        entity: NewsFiltersEntityFrontKey.listing,
                        type: NewsFiltersTypeFrontKey.numeric,
                      });
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    selectsStart
                    maxDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.max
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.max),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : new Date(`${new Date().getFullYear()}-12-31`)
                    }
                    startDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.min
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.min),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : null
                    }
                    endDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.max
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.max),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : null
                    }
                    className="DatePicker"
                    popperClassName="calendar-popout"
                    onInputClick={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.min`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      } else {
                        setMenuState(true);
                        setActiveSelect(
                          `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.min`,
                        );
                      }
                    }}
                    onSelect={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.min`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      }
                    }}
                    onBlur={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.min`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      }
                    }}
                    open={
                      activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.min` &&
                      menuState
                    }
                  />
                </div>
                <div className="col-md-6 col-12">
                  <DatePicker
                    portalId="root-portal-filters"
                    placeholderText={language.filters.yearBuilt.maxPlaceholder}
                    selected={
                      reduxFilters.listing.numeric?.yearBuilt.value.max
                        ? new Date(Number(reduxFilters.listing.numeric?.yearBuilt.value.max), 0)
                        : null
                    }
                    onChange={(date: Date) => {
                      const number = date.getFullYear();
                      handleMinMaxChange({
                        name: NewsFiltersFrontKey.yearBuilt,
                        value: { max: number },
                        entity: NewsFiltersEntityFrontKey.listing,
                        type: NewsFiltersTypeFrontKey.numeric,
                      });
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    selectsEnd
                    maxDate={new Date(`${new Date().getFullYear()}-12-31`)}
                    minDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.min
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.min),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : null
                    }
                    startDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.min
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.min),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : null
                    }
                    endDate={
                      reduxFilters.listing.numeric?.yearBuilt.value.max
                        ? new Date(
                            `${new Date(
                              Number(reduxFilters.listing.numeric?.yearBuilt.value.max),
                              0,
                            ).getFullYear()}-12-31`,
                          )
                        : null
                    }
                    className="DatePicker"
                    popperClassName="calendar-popout"
                    onInputClick={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.max`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      } else {
                        setMenuState(true);
                        setActiveSelect(
                          `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.max`,
                        );
                      }
                    }}
                    onSelect={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.max`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      }
                    }}
                    onBlur={() => {
                      if (
                        activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.max`
                      ) {
                        setMenuState(false);
                        setActiveSelect(null);
                      }
                    }}
                    open={
                      activeSelect ===
                        `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.yearBuilt}.max` &&
                      menuState
                    }
                  />
                </div>
              </div>
            </div>
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.amenity] &&
          availableListingFilters.amenities &&
          reduxFilters.listingAmenity.categoric?.amenityId && (
            <AsyncSelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.amenity}
              entityKey={NewsFiltersEntityFrontKey.listingAmenity}
              title={language.filters.amenities.title}
              placeholder={language.filters.amenities.placeholder}
              defaultOptions={
                amenities
                  ? [...amenities.amenities].sort((a, b) =>
                      customSort(
                        a,
                        b,
                        Array.isArray(reduxFilters.listingAmenity.categoric?.amenity?.value)
                          ? (reduxFilters.listingAmenity.categoric?.amenity?.value?.map(
                              (option) => option.value,
                            ) as Array<string>)
                          : [],
                      ),
                    )
                  : []
              }
              loadOptions={(inputValue, callback) =>
                loadAmenitiesOptions(
                  inputValue,
                  callback as unknown as (options: OptionType[]) => void,
                  null,
                )
              }
              cacheOptions
              hideSelectedOptions={false}
              isDisabled={!amenities?.amenities?.length}
              isMulti
              isClearable={true}
              value={reduxFilters.listingAmenity.categoric?.amenity.value}
              loadingMessage={() => language.filters.amenities.loading}
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="mr-05"
                          style={{ fontSize: "16px", color: primary.color }}
                          data-tour="search"
                        />
                        <p
                          className="mb-0 text-overflow-ellipsis"
                          style={{
                            maxWidth: "160px",
                          }}>{`${language.filters.amenities.placeholder}`}</p>
                      </div>
                    </components.Placeholder>
                  );
                },
                // Input Option with checkboxes
                Option: ({ children, ...props }) => {
                  return (
                    <components.Option {...props}>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={props.isSelected}
                          style={{
                            border: `1px solid ${primary.color}`,
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            accentColor: primary.color,
                          }}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.selectOption(props.data);
                          }}
                        />
                        <span className="ml-05 text-capitalize">{children}</span>
                      </div>
                    </components.Option>
                  );
                },
                MultiValueLabel: ({ children, ...props }) => {
                  const {
                    data: { label, value },
                    selectProps: { value: selectedValues },
                  } = props;
                  return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
                    <components.MultiValueLabel {...props}>
                      {label + (selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``)}
                    </components.MultiValueLabel>
                  ) : null;
                },
                MultiValueRemove: () => null,
              }}
              closeMenuOnSelect={false}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.amenityRoom] &&
          availableListingFilters.amenitiesRooms &&
          reduxFilters.listingAmenity.categoric?.amenityId && (
            <AsyncSelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.amenityRoom}
              entityKey={NewsFiltersEntityFrontKey.listingAmenity}
              title={language.filters.amenitiesRooms.title}
              placeholder={language.filters.amenitiesRooms.placeholder}
              defaultOptions={
                amenities
                  ? [...amenities.rooms].sort((a, b) =>
                      customSort(
                        a,
                        b,
                        Array.isArray(reduxFilters.listingAmenity.categoric?.amenityRoom?.value)
                          ? (reduxFilters.listingAmenity.categoric?.amenityRoom?.value?.map(
                              (option) => option.value,
                            ) as Array<string>)
                          : [],
                      ),
                    )
                  : []
              }
              loadOptions={(inputValue, callback) =>
                loadAmenitiesOptions(
                  inputValue,
                  callback as unknown as (options: OptionType[]) => void,
                  AmenityTypeBackendKey.room,
                )
              }
              cacheOptions
              hideSelectedOptions={false}
              isDisabled={!amenities?.rooms?.length}
              isMulti
              isClearable={true}
              value={reduxFilters.listingAmenity.categoric?.amenityRoom.value}
              loadingMessage={() => language.filters.amenitiesRooms.loading}
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="mr-05"
                          style={{ fontSize: "16px", color: primary.color }}
                          data-tour="search"
                        />
                        <p
                          className="mb-0 text-overflow-ellipsis"
                          style={{
                            maxWidth: "160px",
                          }}>{`${language.filters.amenitiesRooms.placeholder}`}</p>
                      </div>
                    </components.Placeholder>
                  );
                },
                // Input Option with checkboxes
                Option: ({ children, ...props }) => {
                  return (
                    <components.Option {...props}>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={props.isSelected}
                          style={{
                            border: `1px solid ${primary.color}`,
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            accentColor: primary.color,
                          }}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.selectOption(props.data);
                          }}
                        />
                        <span className="ml-05 text-capitalize">{children}</span>
                      </div>
                    </components.Option>
                  );
                },
                MultiValueLabel: ({ children, ...props }) => {
                  const {
                    data: { label, value },
                    selectProps: { value: selectedValues },
                  } = props;
                  return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
                    <components.MultiValueLabel {...props}>
                      {label + (selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``)}
                    </components.MultiValueLabel>
                  ) : null;
                },
                MultiValueRemove: () => null,
              }}
              closeMenuOnSelect={false}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.amenityView] &&
          availableListingFilters.amenitiesViews &&
          reduxFilters.listingAmenity.categoric?.amenityId && (
            <AsyncSelectFilter
              isAdvancedFilters={isAdvancedFilters}
              filterKey={NewsFiltersFrontKey.amenityView}
              entityKey={NewsFiltersEntityFrontKey.listingAmenity}
              title={language.filters.amenitiesViews.title}
              placeholder={language.filters.amenitiesViews.placeholder}
              defaultOptions={
                amenities
                  ? [...amenities.views].sort((a, b) =>
                      customSort(
                        a,
                        b,
                        Array.isArray(reduxFilters.listingAmenity.categoric?.amenityView?.value)
                          ? (reduxFilters.listingAmenity.categoric?.amenityView?.value?.map(
                              (option) => option.value,
                            ) as Array<string>)
                          : [],
                      ),
                    )
                  : []
              }
              loadOptions={(inputValue, callback) =>
                loadAmenitiesOptions(
                  inputValue,
                  callback as unknown as (options: OptionType[]) => void,
                  AmenityTypeBackendKey.view,
                )
              }
              cacheOptions
              hideSelectedOptions={false}
              isDisabled={!amenities?.views?.length}
              isMulti
              isClearable={true}
              value={reduxFilters.listingAmenity.categoric?.amenityView.value}
              loadingMessage={() => language.filters.amenitiesViews.loading}
              components={{
                Placeholder: ({ children, ...props }) => {
                  return (
                    <components.Placeholder {...props}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="mr-05"
                          style={{ fontSize: "16px", color: primary.color }}
                          data-tour="search"
                        />
                        <p
                          className="mb-0 text-overflow-ellipsis"
                          style={{
                            maxWidth: "160px",
                          }}>{`${language.filters.amenitiesViews.placeholder}`}</p>
                      </div>
                    </components.Placeholder>
                  );
                },
                // Input Option with checkboxes
                Option: ({ children, ...props }) => {
                  return (
                    <components.Option {...props}>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={props.isSelected}
                          style={{
                            border: `1px solid ${primary.color}`,
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            accentColor: primary.color,
                          }}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.selectOption(props.data);
                          }}
                        />
                        <span className="ml-05 text-capitalize">{children}</span>
                      </div>
                    </components.Option>
                  );
                },
                MultiValueLabel: ({ children, ...props }) => {
                  const {
                    data: { label, value },
                    selectProps: { value: selectedValues },
                  } = props;
                  return Array.isArray(selectedValues) && selectedValues[0]?.value === value ? (
                    <components.MultiValueLabel {...props}>
                      {label + (selectedValues.length > 1 ? ` +${selectedValues.length - 1}` : ``)}
                    </components.MultiValueLabel>
                  ) : null;
                },
                MultiValueRemove: () => null,
              }}
              closeMenuOnSelect={false}
              setMenuState={setMenuState}
              menuState={menuState}
              setActiveSelect={setActiveSelect}
              activeSelect={activeSelect}
              containerFilterRef={containerFilterRef}
              setLocalFilters={setLocalFilters}
            />
          )}

        {isAdvancedFilters &&
          advancedListingFilters[NewsFiltersFrontKey.isFloodZone] &&
          availableListingFilters.floodZone &&
          reduxFilters.parcel.boolean?.isFloodZone && (
            <div
              className={classNames("col-12 mb-1 d-flex justify-content-center align-items-end", {
                "col-xl-3": isAdvancedFilters,
                "col-md-4": isAdvancedFilters,
                "col-xl-4": !isAdvancedFilters,
                "col-lg-6": !isAdvancedFilters,
                "col-md-6": !isAdvancedFilters,
                "col-sm-12": !isAdvancedFilters,
              })}
              style={{ zIndex: 1, paddingBottom: "8px" }}>
              <Pill
                color={defaultColors.background}
                backgroundColor={
                  reduxFilters.parcel.boolean?.isFloodZone.value
                    ? listingColors.status.active
                    : common.gray
                }
                borderColor={
                  reduxFilters.parcel.boolean?.isFloodZone.value
                    ? listingColors.status.active
                    : common.gray
                }>
                <Checkbox
                  color="primary"
                  icon={<Check className="vx-icon" size={18} />}
                  value={reduxFilters.parcel.boolean?.isFloodZone.value}
                  label={language.filters.filterByFloodZone.title}
                  defaultChecked={reduxFilters.parcel.boolean?.isFloodZone.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onCheckboxChange({
                      name: NewsFiltersFrontKey.isFloodZone,
                      value: e.target.checked,
                      entity: NewsFiltersEntityFrontKey.parcel,
                    });
                  }}
                  onBlur={() => setLocalFilters(reduxFilters)}
                />
              </Pill>
            </div>
          )}
      </div>
    </>
  );
};

export default PropertySection;
