import type { DataChartNewsBackend, MeanDataBackend } from "@/adapters";
import { AreaChartOptions } from "@/models";
import { store } from "@/app/store";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { StatisticsFiltersBackendKey } from "@/keys";

export const responseDataChartNewsAdapter = (data: DataChartNewsBackend): AreaChartOptions => {
  const { themeName, organizationTheme } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);

  const newData: AreaChartOptions = {
    series: [],
    colors: [activeTheme?.primary.color as ThemeType["primary"]["color"]],
  };

  const dataSerie: MeanDataBackend[] = [];

  data.data.map((d) => {
    dataSerie.push({
      x: d.x,
      y: Number(d.y).toFixed(2),
    });
  });

  newData.series.push({
    id: "PRIPX",
    data: dataSerie,
    updatedAt: data.updatedAt,
    filters: {
      currentPrice: data.periods["currentPrice"],
      [StatisticsFiltersBackendKey["7days"]]: data.periods["7days"],
      [StatisticsFiltersBackendKey["1month"]]: data.periods["1month"],
      [StatisticsFiltersBackendKey["3months"]]: data.periods["3months"],
      [StatisticsFiltersBackendKey["6months"]]: data.periods["6months"],
      [StatisticsFiltersBackendKey["1year"]]: data.periods["1year"],
    },
  });

  return newData;
};
