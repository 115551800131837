import React from "react";

export const parseDescription = (text: string) => {
  const columns = text.split("<br/>");

  // const response =
  return columns.map((column) => {
    const simpleLineBreak = column.split("\\r\\n\\\\n\\r\\n"); //salto simple
    let description: string[] = [];

    simpleLineBreak.length &&
      simpleLineBreak.map((simple) => {
        description = simple.split("\\n\\\\n\\n");
      });

    simpleLineBreak.pop();

    return (
      <div key={column}>
        {simpleLineBreak.length !== 0 && (
          <div style={{ columnCount: 2 }}>
            {simpleLineBreak.map((simple, index) => {
              return (
                <div key={index}>
                  {simple}
                  <br />
                </div>
              );
            })}
          </div>
        )}
        {description.length && (
          <div>
            {description.map((simple, index) => {
              return (
                <div key={index}>
                  {simple}
                  <br />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  });
  // const simpleLineBreak = text.split("\r\n\\n\r\n"); //salto simple

  // return simpleLineBreak.length
  //   ? simpleLineBreak.map((simple, index) => {
  //       return (
  //         <div key={index}>
  //           {simple}
  //           <br />
  //         </div>
  //       );
  //     })
  //   : text;
};
