import {
  ButtonBorderPrimary,
  ButtonPlainPrimary,
  ButtonPrimary,
} from "@/components/styledComponents";
import { PortalKey } from "@/keys";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectFilters } from "@/redux/slices";
import { Sliders } from "react-feather";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

type FiltersProps = {
  applyFilters: () => void;
  removeFilters: () => void;
  portal: PortalKey;
  clickBlocked: () => void;
  openFilters: boolean;
  setOpenFilters: (value: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
};

function MobileFiltersContainer({
  applyFilters,
  removeFilters,
  portal,
  clickBlocked,
  openFilters,
  setOpenFilters,
  children,
}: FiltersProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });

  const handleOpenFilters = () => {
    setOpenFilters(true);
  };
  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  const handleApply = () => {
    applyFilters();
    handleCloseFilters();
  };

  const handleClear = () => {
    removeFilters();
    handleCloseFilters();
  };

  return (
    <>
      <div className={classNames("nav d-flex flex-nowrap")}>
        <ButtonPlainPrimary
          onClick={() => {
            handleOpenFilters();
          }}
          style={{ paddingLeft: "1rem" }}>
          <Sliders size={18} style={{ transform: "rotate(90deg)" }} className="cursor-pointer" />
        </ButtonPlainPrimary>
      </div>

      <div
        className="mobile-fullscreen"
        style={{
          backgroundColor: "#FFFFFF",
          display: openFilters ? "flex" : "none",
          flexDirection: "column",
        }}>
        <div style={{ padding: "1rem 0", width: "100%" }}>
          <ButtonPlainPrimary onClick={() => handleCloseFilters()}>
            <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: "1.5rem" }} />
          </ButtonPlainPrimary>
        </div>
        {children}
        <div
          className="d-flex flex-row-reverse align-items-center justify-content-around"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#ffffff",
            padding: "1.3rem 0",
          }}>
          <ButtonPrimary
            color="danger"
            minWidth="fit-content"
            className={`d-flex align-items-center justify-content-center fw-bold px-1`}
            style={{ padding: "6px", borderRadius: "0.3rem" }}
            onClick={() => {
              portal === PortalKey.commercial || portal === PortalKey.residential
                ? clickBlocked()
                : handleApply();
            }}>
            <div className="fw-bold fs-13 text-uppercase">{language.navbarButtons.go}</div>
          </ButtonPrimary>
          <ButtonBorderPrimary
            aria-label="clear-filters"
            color="danger"
            onClick={() => {
              portal === PortalKey.commercial || portal === PortalKey.residential
                ? clickBlocked()
                : handleClear();
            }}
            className={`d-flex align-items-center justify-content-center px-1`}
            style={{ padding: "6px", borderRadius: "0.3rem" }}>
            <div className="fw-bold fs-13 text-uppercase">{language.navbarButtons.clear}</div>
          </ButtonBorderPrimary>
        </div>
      </div>
    </>
  );
}

export default MobileFiltersContainer;
