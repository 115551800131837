import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { history } from "@/history";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AlertTriangle, ArrowRight, MapPin, Type } from "react-feather";
import debounce from "lodash/debounce";
import {
  GeometryTypesKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  PortalKey,
  TabKey,
} from "@/keys";
import { regexCoordinates } from "@/validations";
import { getOrganization } from "@/configs/organizationsConfig";
import { Trans, useTranslation } from "react-i18next";
import {
  resetFiltersByPortal,
  setDeleteButtonWatcher,
  selectAppConfig,
  selectSearch,
  selectMap,
  setRefreshFilters,
  setUserInputSearch,
  updateActiveTabGrid,
  setViewActive,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useTheme } from "styled-components";
import { appPaths } from "@/configs";
import { GptSearchModel, getSearchIds } from "@/services";
import { useLocaleCountryCode } from "@/hooks";
import classNames from "classnames";
import { sizeViewport } from "@/components/styledComponents";
import { setNews } from "@/redux/slices/news/news.slice";

import documentIcon from "@/assets/img/icons/features/searchSuggestions/documents.svg";
import gpsIcon from "@/assets/img/icons/features/searchSuggestions/gps.svg";
import listingsIcon from "@/assets/img/icons/features/searchSuggestions/listings.svg";
import permitsIcon from "@/assets/img/icons/features/searchSuggestions/permits.svg";
import propertiesIcon from "@/assets/img/icons/features/searchSuggestions/properties.svg";
import taxesIcon from "@/assets/img/icons/features/searchSuggestions/taxes.svg";
import tenantsIcon from "@/assets/img/icons/features/searchSuggestions/tenants.svg";
import transactionsIcon from "@/assets/img/icons/features/searchSuggestions/transactions.svg";
import unitsIcon from "@/assets/img/icons/features/searchSuggestions/units.svg";
import newsIcon from "@/assets/img/icons/features/news.svg";
import addressesIcon from "@/assets/img/icons/features/addresses.svg";
import cadasterNumberIcon from "@/assets/img/icons/features/cadaster-number.svg";
import { userIsLoggedIn } from "@/redux/actions";
import SuggestionsBox from "./SuggestionsBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Suggestion } from "@/layouts/components/navbar/SearchNavbar";

type AutocompleteProps = {
  filteredData: {
    id: string;
    title: string;
    groupTitle: string;
    link: string;
    filter: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey;
    type: NewsFiltersTypeFrontKey;
  }[];
  suggestions: Suggestion[];
  userInput: string;
  setUserInput: (value: string) => void;
  showSuggestions: boolean;
  setShowSuggestions: (value: boolean) => void;
  wasStringModifiedRef: React.MutableRefObject<boolean>;
  handleCloseSearch: () => void;
  className?: string;
  portal: keyof typeof PortalKey;
  isLoading: boolean;
  fullWidth?: boolean;
  filterKey: "title";
  filterHeaderKey: "groupTitle";
  placeholder: string;
  autoFocus: boolean;
  clearInput: boolean;
  setHandleSearch: any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  externalClick: (e: MouseEvent) => void;
  changeUserInput: (value: {
    value: any;
    filter: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey;
    type: NewsFiltersTypeFrontKey;
  }) => void;
  applyFilters: () => void;
  getLocations: (value: string) => void;
  // customRender: (
  //   item: any,
  //   i: any,
  //   filteredData: any,
  //   activeSuggestion: any,
  //   onSuggestionItemClick: any,
  //   onSuggestionItemHover: any,
  //   userInput?: string,
  // ) => JSX.Element;
  setNavbarSearch: (bool: boolean) => void;
  activeSuggestion: number;
  setActiveSuggestion: (value: number) => void;
  container: React.MutableRefObject<HTMLDivElement | null>;
  onClickGpt: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | KeyboardEvent<HTMLInputElement>,
  ) => void;
};

function Autocomplete(props: AutocompleteProps) {
  //const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [focused, setFocused] = useState(false);
  const { windowWidth } = useAppSelector(selectAppConfig);
  const dispatch = useAppDispatch();
  const { primary } = useTheme();
  const suggestionList = useRef<PerfectScrollbar>(null);
  //const container = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();

  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const { countryCode } = useLocaleCountryCode();
  const { userInputSearch } = useAppSelector(selectSearch);
  const { userDrawnPolygon } = useAppSelector(selectMap);

  const isMobile = windowWidth < sizeViewport.laptop;

  const redirectToSection = ({ tab }: { tab: TabKey }) => {
    dispatch(updateActiveTabGrid({ tab }));
    if (pathname !== appPaths.tableView.path) {
      dispatch(setViewActive("grid"));
      history.push(appPaths.home.path);
    }
  };

  /*   let filteredData: Array<{
    id: string;
    title: string;
    groupTitle: string;
    link: string;
    filter: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey;
    type: NewsFiltersTypeFrontKey;
  }> = []; */

  // on user Input Click (search in map)
  const onUserInputClick = ({ tab }: { tab: TabKey }) => {
    props.setActiveSuggestion(0);
    props.setShowSuggestions(false);
    dispatch(resetFiltersByPortal({ portal: props.portal }));
    dispatch(setRefreshFilters(true));
    redirectToSection({ tab });

    dispatch(
      getSearchIds({
        query: props.userInput,
        countryCode,
        callback() {
          props.applyFilters();
        },
      }),
    );
  };

  // Suggestion Click Event
  const onSuggestionItemClick = (
    id: string,
    e: any,
    title: string,
    url: string,
    filter: NewsFiltersFrontKey,
    entity: NewsFiltersEntityFrontKey | "news",
    type: NewsFiltersTypeFrontKey,
  ) => {
    props.setActiveSuggestion(-1);
    props.setShowSuggestions(false);
    props.setUserInput(e?.currentTarget?.innerText ? e.currentTarget.innerText : title);

    if (entity === "news") {
      if (url) {
        window.open(url);
      } else {
        dispatch(setNews({ newsView: true, newsId: id }));
      }
      return;
    } else {
      if (url) {
        history.push(url);
      } else {
        let value = e?.target?.innerText ? e.target.innerText : title;
        if (type === NewsFiltersTypeFrontKey.categoric) {
          value = { value: id, label: e.target.innerText ? e.target.innerText : title };
        }
        if (
          type === NewsFiltersTypeFrontKey.geometric &&
          filter === NewsFiltersFrontKey.coordinates
        ) {
          const coordinates = title.split(",");
          value = {
            type: GeometryTypesKey.point,
            coordinates: [parseFloat(coordinates[1]), parseFloat(coordinates[0])],
          };
        }
        props.changeUserInput({ value, filter, entity, type });
      }
    }
  };

  // Suggestion Hover Event
  const onSuggestionItemHover = (index: number) => {
    props.setActiveSuggestion(index);
  };

  const onChangeDebounce = useMemo(
    () =>
      debounce((value: boolean, userInput: string) => {
        props.setShowSuggestions(value);
        props.getLocations(userInput);
      }, 800),
    [countryCode],
  );
  // Input Change
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.wasStringModifiedRef.current = true;
    const userInput = e.currentTarget.value;
    props.setShowSuggestions(false);
    if (userInput.length >= 3) {
      onChangeDebounce(true, userInput);
    } else {
      onChangeDebounce(false, "");
    }
    // props.setActiveSuggestion(-1);
    props.setUserInput(userInput);

    if (e.target.value?.length < 1) {
      props.setShowSuggestions(false);
      // ESTA LINEA BORRA EL USER INPUT SI EL USUARIO BORRA TODO EL TEXTO, lo dejo por si esta raris que si borra todos los filtros manualmente no se borre el texto
      // dispatch(setUserInputSearch({ value: "", showPill: false }));
    }
  };

  // Input Click Event
  const onInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
  };

  // Input's Keydown Event
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { filterKey, setNavbarSearch, filteredData } = props;

    // User pressed the up arrow
    if (e.keyCode === 38) {
      if (props.activeSuggestion === -1) return;
      props.setActiveSuggestion(props.activeSuggestion - 1);
      if (
        e.target.value.length > -1 &&
        suggestionList !== null &&
        props.activeSuggestion <= filteredData.length / 2
      ) {
        suggestionList.current?.updateScroll();
        // suggestionList.current?.scrollTo({top: 0, behavior: 'smooth'}); // REVIEW
      }
    }

    // User pressed the down arrow
    else if (e.keyCode === 40 && props.activeSuggestion < filteredData.length - 1) {
      props.setActiveSuggestion(props.activeSuggestion + 1);

      if (
        e.target.value.length > -1 &&
        suggestionList !== null &&
        props.activeSuggestion >= filteredData.length / 2
      ) {
        // suggestionList.current?.scrollTo({top: suggestionList.current?.scrollHeight, behavior: 'smooth'}); // REVIEW
      }
    }

    // User Pressed ESC
    else if (e.keyCode === 27) {
      props.setShowSuggestions(false);
      props.setUserInput("");
      setNavbarSearch(false);
    }

    // User Pressed ENTER
    else if (e.keyCode === 13 && (props.showSuggestions || props.activeSuggestion === -1)) {
      if (props.activeSuggestion === -1 && props.userInput?.length >= 3) {
        props.onClickGpt(e);
      } else {
        onSuggestionItemClick(
          filteredData[props.activeSuggestion].id,
          e,
          filteredData[props.activeSuggestion].title,
          filteredData[props.activeSuggestion].link,
          filteredData[props.activeSuggestion].filter,
          filteredData[props.activeSuggestion].entity,
          filteredData[props.activeSuggestion].type,
        );
        props.setUserInput(filteredData[props.activeSuggestion][filterKey]);
        props.setShowSuggestions(false);
        setNavbarSearch(false);
      }
    } /* else {
      return;
    } */

    // Custom Keydown Event
    // if (props.onKeyDown !== undefined && props.onKeyDown !== null && props.onKeyDown) {
    //   props.onKeyDown(e, props.userInput);
    // }
  };

  // Grouped Suggestions
  // const renderGroupedSuggestion = (
  //   arr: Array<{
  //     id: string;
  //     groupTitle: string;
  //     title: string;
  //     link: string;
  //     filter: NewsFiltersFrontKey;
  //     entity: NewsFiltersEntityFrontKey;
  //     type: NewsFiltersTypeFrontKey;
  //   }>,
  // ) => {
  //   const { filterKey, customRender } = props;

  //   const renderSuggestion = (
  //     item: {
  //       id: string;
  //       groupTitle: string;
  //       title: string;
  //       link: string;
  //       filter: NewsFiltersFrontKey;
  //       entity: NewsFiltersEntityFrontKey;
  //       type: NewsFiltersTypeFrontKey;
  //     },
  //     i: number,
  //   ) => {
  //     if (!customRender) {
  //       return (
  //         <li
  //           className={classnames("suggestion-item", {
  //             active: filteredData.indexOf(item) === props.activeSuggestion,
  //           })}
  //           key={item[filterKey]}
  //           onClick={(e) =>
  //             onSuggestionItemClick(
  //               item.id,
  //               e,
  //               item.title,
  //               item.link,
  //               item.filter,
  //               item.entity,
  //               item.type,
  //             )
  //           }
  //           onMouseEnter={(e) => {
  //             onSuggestionItemHover(filteredData.indexOf(item));
  //           }}>
  //           {item[filterKey]}
  //         </li>
  //       );
  //     } else if (customRender) {
  //       return customRender(
  //         item,
  //         i,
  //         filteredData,
  //         props.activeSuggestion,
  //         onSuggestionItemClick,
  //         onSuggestionItemHover,
  //         props.userInput,
  //       );
  //     } else {
  //       return null;
  //     }
  //   };

  //   return arr.map((item, i) => {
  //     return renderSuggestion(item, i);
  //   });
  // };

  // // Renders Suggestions
  // const renderSuggestions = () => {
  //   const { filterKey, filterHeaderKey, suggestions } = props;

  //   filteredData = [];
  //   const suggestionsToRender = suggestions.map((suggestion, index) => {
  //     const sortData = suggestion.data;
  //     filteredData.push(...sortData);
  //     return (
  //       <React.Fragment key={suggestion[filterHeaderKey]}>
  //         {sortData.length ? (
  //           <div>
  //             {index > 0 && <hr className="mx-1" />}
  //             {/* ver mas resultados de parcels */}
  //             {suggestion.groupTitle !== "chatGpt" && (
  //               <li className="suggestion-item suggestion-title text-bold-600 d-flex justify-content-between align-items-center">
  //                 <p
  //                   className="suggestion-title text-bold-600 text-uppercase mr-1 mb-0"
  //                   style={{
  //                     textDecorationLine: "underline",
  //                     textDecorationColor: secondary.color,
  //                     textDecorationThickness: "2px",
  //                   }}>
  //                   {suggestion[filterHeaderKey]}
  //                 </p>
  //                 {[
  //                   "parcels",
  //                   "listings",
  //                   "properties",
  //                   "transactions",
  //                   "tenants",
  //                   "permits",
  //                   "documents",
  //                 ].includes(suggestion.id) ? (
  //                   <div
  //                     onClick={() => {
  //                       if (userDrawnPolygon) {
  //                         dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));
  //                       }
  //                       onUserInputClick({
  //                         tab:
  //                           suggestion.id === "parcels"
  //                             ? TabKey.properties
  //                             : suggestion.id === "listings"
  //                             ? TabKey.listings
  //                             : suggestion.id === "properties"
  //                             ? TabKey.units
  //                             : suggestion.id === "transactions"
  //                             ? TabKey.transactions
  //                             : suggestion.id === "tenants"
  //                             ? TabKey.tenants
  //                             : suggestion.id === "permits"
  //                             ? TabKey.permits
  //                             : TabKey.documents,
  //                       });
  //                     }}>
  //                     {!isMobile && (
  //                       <small className="ml-1 primary cursor-pointer">{`${language.results.seeAll}`}</small>
  //                     )}
  //                   </div>
  //                 ) : null}
  //               </li>
  //             )}
  //             {renderGroupedSuggestion(sortData)}
  //           </div>
  //         ) : null}
  //       </React.Fragment>
  //     );
  //   });
  //   return suggestionsToRender;
  // };

  // Clears Input
  const clearInput = (val: boolean) => {
    if (props.clearInput && !val) {
      props.setUserInput("");
    }
  };

  // Closes Suggestions if clicked outside container (On Blur Basically)
  const handleExtenalClick = (e: MouseEvent) => {
    const { target } = e;
    if (target !== props.container.current && !props.container.current?.contains(target as Node)) {
      props.setShowSuggestions(false);
      if (props.wasStringModifiedRef.current) {
        props.setUserInput("");
      }

      // This removes the input state and prevents a new call to the text-search endpoint when the countryCode changes.
      props.setHandleSearch({ loading: false, text: "" });
      if (props.externalClick) props.externalClick(e);
    }
  };

  useEffect(() => {
    const { autoFocus, clearInput } = props;

    // For searchbar focus
    if (input !== null && autoFocus) {
      input.current?.focus();
    }

    if (props.showSuggestions === false && focused) {
      props.setShowSuggestions(true);
    }

    // // Clear Input
    if (clearInput === false && props.userInput.length) {
      props.setUserInput("");
    }

    if (focused === true) {
      props.setShowSuggestions(true);
    }
  }, [props.clearInput]);

  useEffect(() => {
    document.body.addEventListener("click", handleExtenalClick);

    if (focused) {
      props.setShowSuggestions(true);
    }

    return () => {
      document.body.removeEventListener("click", handleExtenalClick);
    };
  }, []);

  const examplesList = [
    {
      title: language.searchTooltip.listings,
      icon: listingsIcon,
      display: true,
    },
    {
      title: language.searchTooltip.tenants,
      icon: tenantsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.gpsCoordinates,
      icon: gpsIcon,
      display: true,
    },
    {
      title: language.searchTooltip.properties,
      icon: propertiesIcon,
      display: true,
    },
    { title: language.searchTooltip.taxes, icon: taxesIcon, display: dispatch(userIsLoggedIn()) },
    {
      title: language.searchTooltip.addresses,
      icon: addressesIcon,
      display: true,
    },
    { title: language.searchTooltip.units, icon: unitsIcon, display: dispatch(userIsLoggedIn()) },
    {
      title: language.searchTooltip.permits,
      icon: permitsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.cadasterNumber,
      icon: cadasterNumberIcon,
      display: true,
    },
    {
      title: language.searchTooltip.transactions,
      icon: transactionsIcon,
      display: dispatch(userIsLoggedIn()),
    },
    {
      title: language.searchTooltip.documents,
      icon: documentIcon,
      display: dispatch(userIsLoggedIn()),
    },
    { title: language.searchTooltip.news, icon: newsIcon, display: true },
  ];

  /*   let suggestionsListComponent;

  if (props.showSuggestions && !props.isLoading) {
    suggestionsListComponent = (
      <PerfectScrollbar
        className={classNames("suggestions-list", {
          "offset-left-2-5": (isMobile || !props.fullWidth) && windowWidth < 670,
        })}
        ref={suggestionList}
        component="ul"
        options={{ wheelPropagation: false }}
        style={{
          width:
            props.fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 3rem)" : "100%") : "",
          maxHeight: props.fullWidth ? "30vh" : "",
          height: props.fullWidth && isMobile ? "auto" : "",
        }}>
        <>
          {props.userInput.match(regexCoordinates) ? (
            <div
              className="suggestion-item coordinates"
              onClick={(e) => {
                onSuggestionItemClick(
                  "",
                  null,
                  props.userInput,
                  "",
                  NewsFiltersFrontKey.coordinates,
                  NewsFiltersEntityFrontKey.location,
                  NewsFiltersTypeFrontKey.geometric,
                );
              }}>
              <MapPin size={15} className="dark" />{" "}
              <span className="align-middle ml-50 fw-bold">
                {language.results.coordinates.title}
              </span>
              <span className="ml-05">{`(${props.userInput})`}</span>
            </div>
          ) : renderSuggestions() ? (
            filteredData.length ? (
              renderSuggestions()
            ) : !props.userInput.match(regexCoordinates) &&
              Object.keys(props.filtersSuggestions || {}).length === 0 ? (
              <div className="suggestion-item no-result w-100">
                <AlertTriangle size={15} className="warning" />{" "}
                <span className="align-middle ml-50 fw-bold">{language.noResults.title}</span>
                <span className="ml-05 fw-bold">{`(${props.userInput})`}</span>
                <div className="mt-05 font-italic">{language.noResults.suggestion}</div>
              </div>
            ) : null
          ) : null}
        </>
      </PerfectScrollbar>
    );
  } else {
    suggestionsListComponent = props.userInput ? (
      props.userInput.length >= 3 ? (
        // no se puede comparar directamente el userInput con el userInputSearch en redux porque capaz el usuario borra una letra y escribe la misma letra, y no va a mostrarle nada hasta que llegan los resultados
        props.wasStringModifiedRef.current && (
          <div
            className={classNames("suggestions-list", {
              "offset-left-2-5": (isMobile || !props.fullWidth) && windowWidth < 670,
            })}
            style={{
              width:
                props.fullWidth && isMobile
                  ? windowWidth < 670
                    ? "calc(100vw - 3rem)"
                    : "100%"
                  : "",
            }}>
            <div className="suggestion-item no-result">
              <Spinner color="primary" size="sm" />
              <span className="align-middle ml-50 fw-bold">{language.loading}</span>
            </div>
          </div>
        )
      ) : (
        <div
          className={classNames("suggestions-list", {
            "offset-left-2-5": (isMobile || !props.fullWidth) && windowWidth < 670,
          })}
          style={{
            width:
              props.fullWidth && isMobile
                ? windowWidth < 670
                  ? "calc(100vw - 3rem)"
                  : "100%"
                : "",
          }}>
          <div className="suggestion-item no-result">
            <Type size={15} /> <span className="align-middle ml-50">{language.waitResults}</span>
          </div>
        </div>
      )
    ) : (
      <div
        className={classNames("suggestions-list", {
          "offset-left-2-5": (isMobile || !props.fullWidth) && windowWidth < 670,
        })}
        style={{
          width:
            props.fullWidth && isMobile ? (windowWidth < 670 ? "calc(100vw - 3rem)" : "100%") : "",
        }}>
        <div className="p-1 w-100">
          <p className="fw-bold search-tooltip-title">{language.searchTooltip.title}</p>
          <div
            className="d-flex flex-wrap w-100 justify-content-between"
            style={{ overflow: "auto", maxHeight: props.fullWidth ? "15vh" : "" }}>
            {examplesList
              .filter((example) => example.display)
              .map((example) => {
                return (
                  <div className="search-example-item col-4" key={example.title}>
                    <p className="fw-bold">
                      <span>
                        <img
                          src={example.icon}
                          alt={example.title}
                          height="16px"
                          className="mr-05"
                        />
                      </span>
                      {example.title}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  } */

  return (
    <div
      className={classNames("vx-autocomplete-container w-100 d-flex", {
        "w-100": isMobile || props.fullWidth,
      })}>
      <input
        type="text"
        onChange={(e) => {
          onChange(e);
        }}
        onKeyDown={(e) => onKeyDown(e)}
        value={props.userInput}
        className={`vx-autocomplete-search w-100 ${props.className ? props.className : ""}`}
        style={{ minWidth: props.fullWidth ? "100%" : "80%", backgroundColor: "transparent" }}
        placeholder={props.placeholder}
        onClick={onInputClick}
        ref={input}
        onFocus={(e) => {
          setFocused(true);
        }}
        autoFocus={props.autoFocus}
        onBlur={(e) => {
          // this.onBlur(e)
          if (props.onBlur) props.onBlur(e);
          setFocused(false);
        }}
      />

      {isMobile && (
        <FontAwesomeIcon
          icon={faXmark}
          style={{
            fontSize: "16px",
            color: primary.color,
            marginLeft: "1rem",
          }}
          onClick={() => props.handleCloseSearch()}
        />
      )}

      {/*       <SuggestionsBox
        fullWidth={props.fullWidth}
        changeUserInput={props.changeUserInput}
        setUserInput={setUserInput}
        userInput={userInput}
        filtersSuggestions={props.filtersSuggestions}
        filterKey={props.filterKey}
        filterHeaderKey={props.filterHeaderKey}
        suggestions={props.suggestions}
        showSuggestions={showSuggestions}
        setShowSuggestions={setShowSuggestions}
        applyFilters={props.applyFilters}
        customRender={props.customRender}
        isLoading={props.isLoading}
        wasStringModifiedRef={wasStringModifiedRef}
      /> */}
      {
        //suggestionsListComponent
      }
    </div>
  );
}

export default Autocomplete;
