import { FiltersType } from "@/models";
import { OnlyKeyFilterFrontKey } from "@/redux/slices";
import { toast } from "react-toastify";

type CreateMarkerPinProps = {
  filterCategoric?: {
    filter: FiltersType["categoric"];
    filterName: OnlyKeyFilterFrontKey;
  };
  filterGeometric?: {
    filter: FiltersType["geometric"];
    filterName: OnlyKeyFilterFrontKey;
  };

  maps: typeof google.maps;
  map: google.maps.Map | null;
  setMarkerPin: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    } | null>
  >;
};

export const CreateMarkerPin = ({
  filterCategoric,
  filterGeometric,
  maps,
  map,
  setMarkerPin,
}: CreateMarkerPinProps) => {
  // ------- Marker Pin by address (geocode)
  if (filterCategoric?.filter?.[filterCategoric.filterName].appliedValue) {
    const geocoder = new maps.Geocoder();
    const filterName = filterCategoric.filter?.[filterCategoric.filterName].value;
    if (filterName && !Array.isArray(filterName))
      geocoder.geocode({ placeId: filterName.value }, (results, status) => {
        if (results && maps && map && status === maps.GeocoderStatus.OK) {
          const center = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };

          map.setCenter(center);
          map.setZoom(17);

          setMarkerPin(center);
        } else {
          toast.error(`Can't find address: ${filterName.label}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setMarkerPin(null);
        }
      });
  } else {
    setMarkerPin(null);
  }

  // ------- Marker Pin by coordinates
  if (filterGeometric?.filter?.[filterGeometric.filterName].appliedValue) {
    const geocoder = new maps.Geocoder();
    const location: { lat: number; lng: number } = {
      lat: filterGeometric?.filter?.[filterGeometric.filterName]?.value?.coordinates[1] as number,
      lng: filterGeometric?.filter?.[filterGeometric.filterName]?.value?.coordinates[0] as number,
    };
    if (location)
      geocoder
        .geocode({ location })
        .then((response) => {
          if (response.results[0] && map) {
            const center = {
              lat: response.results[0].geometry.location.lat(),
              lng: response.results[0].geometry.location.lng(),
            };
            map.setCenter(center);
            map.setZoom(17);

            setMarkerPin(center);
          } else {
            toast.error(
              `Can't find Coordinates: ${
                filterGeometric?.filter?.[filterGeometric.filterName].value?.coordinates[1]
              }, ${filterGeometric?.filter?.[filterGeometric.filterName].value?.coordinates[0]}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              },
            );
            setMarkerPin(null);
          }
        })
        .catch((e) => {
          toast.error(
            `Can't find Coordinates: ${
              filterGeometric?.filter?.[filterGeometric.filterName].value?.coordinates[1]
            }, ${filterGeometric?.filter?.[filterGeometric.filterName].value?.coordinates[0]}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
          );
          setMarkerPin(null);
        });
  } else {
    setMarkerPin(null);
  }
};
