import React from "react";
import { ButtonPlainPrimary } from "../styledComponents";
import { selectMap } from "@/redux/slices";
import { useAppSelector } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { lighten } from "polished";
import { appPaths } from "@/configs";
import { useLocation } from "react-router-dom";
import { X } from "react-feather";

interface DrawingControllerProps {
  id: string;
  windowWidth: number;
}
function DrawingController({ id, windowWidth }: DrawingControllerProps) {
  const { primary, buttons, common } = useTheme();
  const { drawingMode, userDrawnPolygon } = useAppSelector(selectMap);
  const { t } = useTranslation("translation");
  const { pathname } = useLocation();
  const translation = t("navbar", { returnObjects: true });
  const isDisabled = userDrawnPolygon || pathname === appPaths.tableView.path;
  const colorCustomIcon = isDisabled ? lighten(0.5, buttons.borderPrimary.color) : primary.color;
  const customIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
      <path
        d="M12.8945 3.40039H5.39996L1.65173 9.89076L5.39996 16.3821H12.8945L16.6427 9.89076L12.8945 3.40039Z"
        stroke={colorCustomIcon}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4463 4.05153C5.80624 4.05153 6.09804 3.75973 6.09804 3.39979C6.09804 3.03984 5.80624 2.74805 5.4463 2.74805C5.08635 2.74805 4.79456 3.03984 4.79456 3.39979C4.79456 3.75973 5.08635 4.05153 5.4463 4.05153Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
      <path
        d="M12.9042 4.05153C13.2641 4.05153 13.5559 3.75973 13.5559 3.39979C13.5559 3.03984 13.2641 2.74805 12.9042 2.74805C12.5442 2.74805 12.2524 3.03984 12.2524 3.39979C12.2524 3.75973 12.5442 4.05153 12.9042 4.05153Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
      <path
        d="M16.4873 10.5086C16.8473 10.5086 17.1391 10.2168 17.1391 9.85682C17.1391 9.49687 16.8473 9.20508 16.4873 9.20508C16.1274 9.20508 15.8356 9.49687 15.8356 9.85682C15.8356 10.2168 16.1274 10.5086 16.4873 10.5086Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
      <path
        d="M5.4463 17.0339C5.80624 17.0339 6.09804 16.7422 6.09804 16.3822C6.09804 16.0223 5.80624 15.7305 5.4463 15.7305C5.08635 15.7305 4.79456 16.0223 4.79456 16.3822C4.79456 16.7422 5.08635 17.0339 5.4463 17.0339Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
      <path
        d="M12.9042 17.0339C13.2641 17.0339 13.5559 16.7422 13.5559 16.3822C13.5559 16.0223 13.2641 15.7305 12.9042 15.7305C12.5442 15.7305 12.2524 16.0223 12.2524 16.3822C12.2524 16.7422 12.5442 17.0339 12.9042 17.0339Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
      <path
        d="M20.28 2.74938C20.5494 2.47999 20.5494 2.03971 20.28 1.78287L18.6897 0.192625C18.4329 -0.0642085 17.9926 -0.0642085 17.7358 0.192625L16.4883 1.4401L19.0325 3.98431M8.24353 9.68486V12.2291H10.7877L18.3103 4.70653L15.7661 2.16233L8.24353 9.68486Z"
        fill={colorCustomIcon}
      />
      <path
        d="M1.65174 10.5086C2.01169 10.5086 2.30348 10.2168 2.30348 9.85682C2.30348 9.49687 2.01169 9.20508 1.65174 9.20508C1.29179 9.20508 1 9.49687 1 9.85682C1 10.2168 1.29179 10.5086 1.65174 10.5086Z"
        fill={colorCustomIcon}
        stroke={colorCustomIcon}
        strokeMiterlimit="10"
      />
    </svg>
  );

  return (
    <button
      disabled={isDisabled}
      className={`d-flex align-items-center justify-content-center cursor-pointer`}
      style={{
        padding: "6px 6px 6px 15px",
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        borderLeft: `1px solid ${common.lightGray}`,
      }}
      id={id}>
      {drawingMode !== null ? <X size={18} style={{ color: colorCustomIcon }} /> : customIcon}
    </button>
  );
}

export default DrawingController;
