import React, { useMemo, useState } from "react";
import {
  CategoricFiltersKey,
  CountryCodeKey,
  LanguageCodeKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
} from "@/keys";
import { Filters, OnlyKeyEntityFilter, selectAppConfig, selectFilters } from "@/redux/slices";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useLocaleCountryCode } from "@/hooks";
import { useAppSelector } from "@/app/hooks";
import { CategoricValueFilters } from "@/models";
import {
  CustomMultiValueLabel,
  CustomOption,
  CustomPlaceholder,
} from "@/components/reactSelect/CustomComponents";
import { sizeViewport } from "@/components/styledComponents";
import { AdvancedFilters } from "../../../Filters";
import classNames from "classnames";
import { useTheme } from "styled-components";
import SelectFilter from "../../../components/SelectFilter";

type TansactionsSectionProps = {
  isAdvancedFilters: boolean;
  advancedTransactionFilters: AdvancedFilters["transaction"]["filters"];
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  availableTransactionsFilters: {
    show: boolean;
    lastTransactionDateRange: boolean;
    transactionSector: boolean;
    transactionSubsector: boolean;
    transactionMicrosector: boolean;
  };
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
};

const TansactionsSection = ({
  isAdvancedFilters,
  advancedTransactionFilters,
  handleMinMaxChange,
  availableTransactionsFilters,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: TansactionsSectionProps) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const { common } = useTheme();
  const { languageCode, countryCode } = useLocaleCountryCode();
  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const reduxFilters = filtersByPortal[portal].filters;

  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;

  const languageOptions = useMemo(
    () => (value: string) => dependentOptionsMap(value),
    [
      languageCode,
      reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value,
      reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value,
    ],
  );

  // -------------- dependent options Select
  const dependentOptionsMap = (value: string) => {
    switch (value) {
      case CategoricFiltersKey.transactionSectors:
        return filtersByPortal[portal].categoricFilters.transactionSectors.map((option) => {
          const { value, label, esLabel } = option;
          return { value, label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label };
        });
      case CategoricFiltersKey.transactionSubsectors:
        if (reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value) {
          // si sector tiene valor
          const subS = filtersByPortal[portal].categoricFilters[
            CategoricFiltersKey.transactionSubsectors
          ].filter(
            (subsector) =>
              (!Array.isArray(
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value,
              ) &&
                subsector[NewsFiltersFrontKey.sectorId] ===
                  reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value
                    ?.value) ||
              (Array.isArray(
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value,
              ) &&
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value.length ===
                  1 &&
                subsector[NewsFiltersFrontKey.sectorId] ===
                  reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value[0]
                    ?.value),
          );
          if (subS.length) {
            return subS.map((option) => {
              const { value, label, esLabel, sectorId } = option;
              return {
                value,
                label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
                sectorId,
              };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.transactionSubsectors.map((option) => {
            const { value, label, esLabel, sectorId } = option;
            return {
              value,
              label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
              sectorId,
            };
          });
        }
      case CategoricFiltersKey.transactionMicrosectors:
        if (reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value) {
          // si subsector tiene valor
          const micro = filtersByPortal[portal].categoricFilters.transactionMicrosectors.filter(
            (microsector) =>
              (!Array.isArray(
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value,
              ) &&
                microsector[NewsFiltersFrontKey.subsectorId] ===
                  reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value
                    ?.value) ||
              (Array.isArray(
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value,
              ) &&
                reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value
                  .length === 1 &&
                microsector[NewsFiltersFrontKey.subsectorId] ===
                  reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value[0]
                    ?.value),
          );
          if (micro.length) {
            return micro.map((option) => {
              const { value, label, esLabel, subsectorId } = option;
              return {
                value,
                label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
                subsectorId,
              };
            });
          } else {
            return [];
          }
        } else {
          return filtersByPortal[portal].categoricFilters.transactionMicrosectors.map((option) => {
            const { value, label, esLabel, subsectorId } = option;
            return {
              value,
              label: languageCode === LanguageCodeKey.es ? esLabel ?? label : label,
              subsectorId,
            };
          });
        }
      default:
        return [];
    }
  };

  const customSort = (
    a: CategoricValueFilters,
    b: CategoricValueFilters,
    selected: Array<string>,
  ) => {
    if (selected.includes(a.value)) {
      return -1;
    }
    if (selected.includes(b.value)) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="row px-1">
      <div className="col-12">
        <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
          <h5 className="black fw-bold text-uppercase mb-0 mr-2">
            {language.menuFilters.transaction}
          </h5>
          <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
        </div>
      </div>

      {advancedTransactionFilters[NewsFiltersFrontKey.sectorId] &&
        availableTransactionsFilters.transactionSector &&
        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.sectorId}
            entityKey={NewsFiltersEntityFrontKey.transaction}
            title={language.filters.transactionSector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.transactionSectors).length
                ? language.filters.transactionSector.placeholder
                : language.filters.transactionSector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters[CategoricFiltersKey.transactionSectors]
                ? languageOptions(CategoricFiltersKey.transactionSectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(
                        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value,
                      )
                        ? (reduxFilters.transaction.categoric?.[
                            NewsFiltersFrontKey.sectorId
                          ].value?.map((option) => option.value) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isClearable={true}
            value={reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value}
            isMulti
            isDisabled={
              !filtersByPortal[portal].categoricFilters[CategoricFiltersKey.transactionSectors]
                .length
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.transactionSector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {advancedTransactionFilters[NewsFiltersFrontKey.subsectorId] &&
        availableTransactionsFilters.transactionSubsector &&
        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.subsectorId}
            entityKey={NewsFiltersEntityFrontKey.transaction}
            title={language.filters.transactionSubsector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.transactionSubsectors).length
                ? language.filters.transactionSubsector.placeholder
                : language.filters.transactionSubsector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters[CategoricFiltersKey.transactionSubsectors]
                ? languageOptions(CategoricFiltersKey.transactionSubsectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(
                        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value,
                      )
                        ? (reduxFilters.transaction.categoric?.[
                            NewsFiltersFrontKey.subsectorId
                          ].value?.map((option) => option.value) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isClearable={true}
            value={reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value}
            isMulti
            isDisabled={
              !reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.sectorId].value ||
              !dependentOptionsMap(CategoricFiltersKey.transactionSubsectors).length
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.transactionSubsector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )}

      {/* {advancedTransactionFilters[NewsFiltersFrontKey.microsectorId] &&
        availableTransactionsFilters.transactionMicrosector &&
        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.microsectorId] && (
          <SelectFilter
            isAdvancedFilters={isAdvancedFilters}
            filterKey={NewsFiltersFrontKey.microsectorId}
            entityKey={NewsFiltersEntityFrontKey.transaction}
            title={language.filters.transactionMicrosector.title}
            placeholder={
              dependentOptionsMap(CategoricFiltersKey.transactionMicrosectors).length
                ? language.filters.transactionMicrosector.placeholder
                : language.filters.transactionMicrosector.noOptions
            }
            options={
              filtersByPortal[portal].categoricFilters[CategoricFiltersKey.transactionSubsectors]
                ? languageOptions(CategoricFiltersKey.transactionSubsectors).sort((a, b) =>
                    customSort(
                      a,
                      b,
                      Array.isArray(
                        reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value,
                      )
                        ? (reduxFilters.transaction.categoric?.[
                            NewsFiltersFrontKey.subsectorId
                          ].value?.map((option) => option.value) as Array<string>)
                        : [],
                    ),
                  )
                : []
            }
            isClearable={true}
            value={reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.microsectorId].value}
            isMulti
            isDisabled={
              !reduxFilters.transaction.categoric?.[NewsFiltersFrontKey.subsectorId].value ||
              !dependentOptionsMap(CategoricFiltersKey.transactionMicrosectors).length
            }
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Placeholder: ({ children, ...props }) => (
                <CustomPlaceholder
                  {...props}>{`${language.filters.transactionMicrosector.placeholder}`}</CustomPlaceholder>
              ),
              Option: ({ children, ...props }) => (
                <CustomOption {...props}>{children}</CustomOption>
              ),
              MultiValueLabel: ({ children, ...props }) => (
                <CustomMultiValueLabel {...props}>{children}</CustomMultiValueLabel>
              ),
              MultiValueRemove: () => null,
            }}
            setMenuState={setMenuState}
            menuState={menuState}
            setActiveSelect={setActiveSelect}
            activeSelect={activeSelect}
            containerFilterRef={containerFilterRef}
            setLocalFilters={setLocalFilters}
          />
        )} */}

      {advancedTransactionFilters[NewsFiltersFrontKey.transactionDate] &&
        availableTransactionsFilters.lastTransactionDateRange &&
        reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate] && (
          <div
            className={classNames("col-12 mb-1", {
              "col-xl-3": isAdvancedFilters,
              "col-md-4": isAdvancedFilters,
              "col-md-6": !isAdvancedFilters,
              "col-sm-12": !isAdvancedFilters,
            })}>
            <h6 className="mb-05 fw-bold black ml-1">
              {language.filters.lastTransactionDateRange.title}
            </h6>
            <div className="row">
              <div className="col-6" style={{ marginBottom: isMobile ? ".5rem" : "" }}>
                <DatePicker
                  dateFormat={languageCode === LanguageCodeKey.en ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                  portalId="root-portal-filters"
                  placeholderText={language.filters.lastTransactionDateRange.startPlaceholder}
                  selected={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.min
                  }
                  onChange={(date: Date) =>
                    handleMinMaxChange({
                      name: NewsFiltersFrontKey.transactionDate,
                      value: { min: date },
                      entity: NewsFiltersEntityFrontKey.transaction,
                      type: NewsFiltersTypeFrontKey.date,
                    })
                  }
                  selectsStart
                  maxDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value
                      .max ?? new Date()
                  }
                  startDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.min
                  }
                  endDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.max
                  }
                  className="DatePicker"
                  popperClassName="calendar-popout"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  onInputClick={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    } else {
                      setMenuState(true);
                      setActiveSelect(
                        `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.min`,
                      );
                    }
                  }}
                  onSelect={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  onBlur={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.min`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  open={
                    activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.min` &&
                    menuState
                  }
                />
              </div>

              <div className="col-6">
                <DatePicker
                  dateFormat={languageCode === LanguageCodeKey.en ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                  portalId="root-portal-filters"
                  placeholderText={language.filters.lastTransactionDateRange.endPlaceholder}
                  selected={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.max
                  }
                  onChange={(date: Date) =>
                    handleMinMaxChange({
                      name: NewsFiltersFrontKey.transactionDate,
                      value: { max: date },
                      entity: NewsFiltersEntityFrontKey.transaction,
                      type: NewsFiltersTypeFrontKey.date,
                    })
                  }
                  selectsEnd
                  minDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.min
                  }
                  maxDate={new Date()}
                  startDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.min
                  }
                  endDate={
                    reduxFilters.transaction.date?.[NewsFiltersFrontKey.transactionDate].value.max
                  }
                  className="DatePicker"
                  popperClassName="calendar-popout"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  onInputClick={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    } else {
                      setMenuState(true);
                      setActiveSelect(
                        `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.max`,
                      );
                    }
                  }}
                  onSelect={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  onBlur={() => {
                    if (
                      activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.max`
                    ) {
                      setMenuState(false);
                      setActiveSelect(null);
                    }
                  }}
                  open={
                    activeSelect ===
                      `${NewsFiltersEntityFrontKey.transaction}.${NewsFiltersFrontKey.transactionDate}.max` &&
                    menuState
                  }
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default TansactionsSection;
