import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { SubscriptionCard, SubscriptionPaymentHistory, SubscriptionPlan } from "@/models";

interface SubscriptionState {
  card: SubscriptionCard | null;
  plans: SubscriptionPlan[];
  userPaymentHistory: SubscriptionPaymentHistory[];
  paymentSuccess: boolean;
  subsCriptionIsLoading: boolean;
  billingIsLoading: boolean;
}

const initialState: SubscriptionState = {
  card: null,
  plans: [],
  userPaymentHistory: [],
  paymentSuccess: false,
  subsCriptionIsLoading: false,
  billingIsLoading: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionPlans: (state, action: PayloadAction<{ plans: SubscriptionPlan[] }>) => {
      state.plans = action.payload.plans;
      state.subsCriptionIsLoading = false;
    },
    setSubscriptionCard: (state, action: PayloadAction<{ card: SubscriptionCard }>) => {
      state.card = action.payload.card;
      state.billingIsLoading = false;
    },
    setUserPaymentHistory: (
      state,
      action: PayloadAction<{ history: SubscriptionPaymentHistory[] }>,
    ) => {
      state.userPaymentHistory = action.payload.history;
      state.billingIsLoading = false;
    },
    setSubscriptionIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.subsCriptionIsLoading = action.payload.isLoading;
    },
    setBillingIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.billingIsLoading = action.payload.isLoading;
    },
    setSuccess: (state, action: PayloadAction<{ success: boolean | undefined }>) => {
      state.paymentSuccess =
        action.payload.success === undefined ? !action.payload.success : action.payload.success;
    },
  },
});

// These actions will dispatch in the app
export const {
  setSubscriptionPlans,
  setSubscriptionCard,
  setUserPaymentHistory,
  setSubscriptionIsLoading,
  setBillingIsLoading,
  setSuccess,
} = subscriptionSlice.actions;

export const selectSubscription = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;
