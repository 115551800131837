import { responseAmenitiesAdapter } from "@/adapters/responses/amenities/amenities.adapter";
import { LanguageCodeKey, useEndpoint } from "@/keys";
import { Amenities, ListingAmenity, OptionType } from "@/models";
import { api } from "../api";
import { AmenityBackend, AmenityTypeBackend } from "@/adapters";

const amenitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAmenities: builder.query<Amenities, { language?: LanguageCodeKey }>({
      query: () => {
        return {
          url: useEndpoint().amenities(),
          method: "get",
        };
      },
      transformResponse: (
        response: { data: Array<AmenityBackend> },
        _meta,
        { language },
      ): Amenities => responseAmenitiesAdapter(response.data, language),
    }),
    getSynonymAmenities: builder.query<
      Amenities,
      {
        search: string;
        language: LanguageCodeKey;
        amenityType?: AmenityTypeBackend;
      }
    >({
      query({ search, language, amenityType }) {
        return {
          url: useEndpoint().synonymAmenities(),
          params: {
            name: search,
            language,
            amenityType,
          },
        };
      },
      transformResponse: (
        response: { data: Array<AmenityBackend> },
        _meta,
        { language },
      ): Amenities => responseAmenitiesAdapter(response.data, language),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAmenitiesQuery, useLazyGetSynonymAmenitiesQuery } = amenitiesApi;
