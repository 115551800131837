import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { NavItem } from "reactstrap";
import * as Icon from "react-feather";
import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@/components/@vuexy/autoComplete/AutoCompleteComponent";
import notImg from "@/assets/img/slider/notImg.jpg";
import { NumberFormat } from "@/utilities";
import { AvailableFiltersByPortal, appPaths, getOrganization } from "@/configs";
import {
  CountryCodeKey,
  DatasetListingsBackendKey,
  ListingPropertyTypeBackendKey,
  ListingStatusBackendKey,
  NewsCategoriesKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  ParcelBackendExpandKey,
  ParcelBackendKey,
  ParcelEntityCountBackendKey,
  PortalKey,
  PropertyEntityCountBackendKey,
  PropertyFeatureTabKey,
  SectorBackendKey,
} from "@/keys";

import { userIsLoggedIn, loadPlacePredictions } from "@/redux/actions";
import {
  selectAppConfig,
  selectFilters,
  selectSearch,
  setAllValuesFilter,
  setAppliedFilters,
  setDeleteButtonWatcher,
  setIsElasticSearh,
  setValueFilter,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useGetSearchQuery, useLazyGetGptSearchQuery } from "@/services/filters/search.service";
import {
  getNeighbordhoods,
  getSubNeighbordhoods,
  useGetParcelsByIdsQuery,
  useGetParcelsCountsQuery,
  useGetPropertiesCountsQuery,
} from "@/services";
import { regexCoordinates } from "@/validations";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import ListingSuggestion from "./suggestions/ListingSuggestion";
import ParcelSuggestion from "./suggestions/ParcelSuggestion";
import UnitSuggestion from "./suggestions/UnitSuggestion";
import TenantSuggestion from "./suggestions/TenantSuggestion";
import PermitSuggestion from "./suggestions/PermitSuggestion";
import TransactionSuggestion from "./suggestions/TransactionSuggestion";
import { useCategoryTranslations, useLocaleCountryCode } from "@/hooks";
import { Pill, sizeViewport } from "@/components/styledComponents";
import useRouteQueryString from "@/hooks/queryString/useRouteQueryString.hook";
import NewCard from "@/views/pages/news/NewCard";
import { useApplyFilters } from "@/hooks/filters/useApplyFilters";
import classNames from "classnames";
import Typewriter from "../corporateSite/homePopUp/Typewriter";
import SuggestionsBox from "@/components/@vuexy/autoComplete/SuggestionsBox";
import { useLocation } from "react-router-dom";
import { history } from "@/history";
import { setViewActive, updateActiveTabGrid } from "../../../redux/slices/gridView/grid-view.slice";
import { useAvailableFilters } from "@/hooks/filters/useAvailableFilters";
import { EntityCount, NewGptJSONPick, Parcel, TransactionProperty } from "@/models";
import { listingStatusColorsColorsMapper } from "@/redux/mappers/listingStatusColorsMapper";

type IconKey = keyof typeof Icon;

export type SubMarketSuggestion = {
  id: string;
  title: string;
  icon: IconKey;
  filter: NewsFiltersFrontKey;
  entity: NewsFiltersEntityFrontKey.location;
  type: NewsFiltersTypeFrontKey;
};

export type ListingSuggestion = {
  id: string;
  title: string;
  price: string | JSX.Element;
  img: string;
  address: string;
  link: string;
  entity: NewsFiltersEntityFrontKey.listing;
  isForRent: boolean;
  active: boolean;
  status?: ListingStatusBackendKey | null;
  propertyType: string;
};

export type ParcelSuggestion = {
  id: string;
  title: string | null;
  govtId: string;
  img: string;
  address: string | null;
  sector?: { id: string; name: string } | null;
  link: string;
  entity: NewsFiltersEntityFrontKey.parcel;
};

export type PropertySuggestion = {
  id: string;
  address: string | null;
  govtId: string;
  img: string;
  parcelId?: string | null;
  parcel?: Parcel | null;
  link: string | null;
  entity: NewsFiltersEntityFrontKey.property;
};

export type TransactionSuggestion = {
  id: string;
  address: string | null;
  img: string;
  sellerName: string;
  buyerName: string;
  transactionType: string | null;
  transactionPrice: string | JSX.Element;
  pricePerBuiltSqm?: number | null;
  parcelId?: string | null;
  link: string | null;
  entity: NewsFiltersEntityFrontKey.transaction;
  unitId?: string;
  transactionProperties?: TransactionProperty[] | null;
};

export type TenantSuggestion = {
  id: string;
  title: string | null;
  address?: string | null;
  img: string;
  parcel?: Parcel | null;
  sector?: { id: string; name: string } | null;
  subsector?: { id: string; name: string } | null;
  microsector?: { id: string; name: string } | null;
  link: string | null;
  entity: NewsFiltersEntityFrontKey.tenant;
};

export type PermitSuggestion = {
  id: string;
  title: string | null;
  address: string | null;
  img: string;
  parcel?: Parcel | null;
  status: string | null;
  link: string | null;
  entity: NewsFiltersEntityFrontKey.permit;
};

export type DocumentSuggestion = {
  id: string;
  address: string | null;
  img: string;
  transactionType: string | null;
  parcelId?: string | null;
  link: string | null;
  entity: NewsFiltersEntityFrontKey.transaction;
};

export type NewsSuggestion = {
  id: string;
  title: string;
  esTitle: string;
  description: string;
  esDescription: string;
  category: Array<keyof typeof NewsCategoriesKey>;
  date: Date;
  link: string;
  gptJSON?: NewGptJSONPick;
  city?: {
    name: string;
  };
  entity: "news";
};

export type GroupedSuggestion = {
  id: string;
  title?: string | null;
  icon?: IconKey;
  img?: string;
  govtId?: string;
  address?: string | null;
  price?: string | JSX.Element;
  sector?: { id: string; name: string } | null;
  subsector?: { id: string; name: string } | null;
  microsector?: { id: string; name: string } | null;
  parcel?: Parcel | null;
  parcelId?: string | null;
  sellerName?: string | null;
  buyerName?: string | null;
  transactionType?: string | null;
  transactionPrice?: string | JSX.Element;
  pricePerBuiltSqm?: number | null;
  transactionProperties?: TransactionProperty[] | null;
  link?: string | null;
  filter?: NewsFiltersFrontKey;
  isForRent?: boolean;
  active?: boolean;
  propertyType?: string;
  status?: ListingStatusBackendKey | null | string;
  unitId?: string;
  entity: NewsFiltersEntityFrontKey | "news";
  type?: NewsFiltersTypeFrontKey;
  esTitle?: string;
  description?: string;
  esDescription?: string;
  category?: Array<keyof typeof NewsCategoriesKey>;
  date?: Date;
  gptJSON?: NewGptJSONPick;
  city?: {
    name: string;
  };
};

export type Suggestion = {
  id: string;
  groupTitle: string;
  searchLimit?: number;
  data: GroupedSuggestion[];
};

type SearchNavbarProps = {
  setNavbarSearch: (bool: boolean) => void;
  navbarSearch: boolean;
  setOpenMobileSearchbar: React.Dispatch<React.SetStateAction<boolean>>;
  fullWidth?: boolean;
};
// setNavbarSearch es para definirle que esta focuseado, navbarSearch es si esta focuseado o no (se puede crear un state en el popup inicial para ambos), clickBlocked a revisar la version publica, setOpenMobileSearchbar es como navbarSearch pero para comportamiento del mobile (en el popup inicial no es relevante)
function SearchNavbar({
  setNavbarSearch,
  navbarSearch,
  setOpenMobileSearchbar,
  fullWidth,
}: SearchNavbarProps) {
  /*   const {
      setNavbarSearch,
      navbarSearch,
      clickBlocked,
      applyFilters,
      removeFilter,
      setOpenMobileSearchbar,
    } = props; */

  const addressPredictions = useAppSelector((state) => state.google.addressPredictions);
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const { countryCode } = useLocaleCountryCode();
  const { userInputSearch } = useAppSelector(selectSearch);
  const { availableFilters } = useAvailableFilters();
  const { languagelistingStatus } = useCategoryTranslations();

  const { primary, listingColors, common, defaultColors } = useTheme();
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { appPropertyProfileQueryString, appListingProfileQueryString } = useRouteQueryString();
  const [parcelsIds, setParcelsIds] = useState<string[]>([]);
  const [expandedParcelsIds, setExpandedParcelsIds] = useState<string[]>([]);
  const [handleSearch, setHandleSearch] = useState({
    loading: false,
    text: "",
  });

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);

  const [userInput, setUserInput] = useState(`${userInputSearch.value}`);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wasStringModifiedRef = useRef(false);

  const container = useRef(null);

  useEffect(() => {
    if (userInputSearch.value && userInputSearch.value !== userInput) {
      setUserInput(userInputSearch.value);
    }
  }, [userInputSearch.value]);

  /**
   * @type {Array<{
   *   id: string;
   *   title: string;
   *   groupTitle: string;
   *   link: string;
   *   filter: NewsFiltersFrontKey;
   *   entity: NewsFiltersEntityFrontKey;
   *   type: NewsFiltersTypeFrontKey;
   * }>}
   */
  const [filteredData, setFilteredData] = useState([]);

  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { applySearchFilters, removeFilter } = useApplyFilters();
  const dispatch = useAppDispatch();

  const isMobile = windowWidth < sizeViewport.laptop;

  const {
    data: searchResults,
    isLoading,
    isFetching,
  } = useGetSearchQuery(
    {
      userInputText: handleSearch.text,
      countryCode,
      portal,
      userFree: dispatch(userIsLoggedIn()) ? false : true,
    },
    {
      skip:
        !handleSearch.loading || handleSearch.text.match(regexCoordinates)
          ? true
          : false || !handleSearch.text.length,
    },
  );

  // ------------------------ Start GPT SEARCH ----------------
  const [gptNoResultsToShow, setGptNoResultsToShow] = useState(false); // cuando el usuario hace un click en gpt por segunda vez pero no hay resultados
  const { pathname } = useLocation();

  const [
    triggerGptSearch,
    {
      currentData: gptSearchResults,
      isLoading: isLoadingGptSearchResults,
      isFetching: isFetchingGptSearchResults,
      // isUninitialized: isUninitializedGptSearchResults,
      isSuccess: isSuccessGptSearchResults,
      isError: isErrorGptSearchResults,
    },
  ] = useLazyGetGptSearchQuery();

  const onClickGpt = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | KeyboardEvent<HTMLInputElement>,
  ) => {
    //Onclick del usuario a chatGPT
    event.preventDefault();
    event.stopPropagation();
    dispatch(setIsElasticSearh(false));
    triggerGptSearch({
      search: userInput,
      countryCode,
      categoricFilters: filtersByPortal[portal].categoricFilters,
      advancedFilters: dispatch(userIsLoggedIn()) ? true : false,
    });
    wasStringModifiedRef.current = false;
  };

  useEffect(() => {
    if (isSuccessGptSearchResults && !isFetchingGptSearchResults) {
      if (gptSearchResults?.filtersAreAvailable && gptSearchResults.filtersRedux) {
        dispatch(setAllValuesFilter({ portal, filters: gptSearchResults.filtersRedux }));
        dispatch(setAppliedFilters({ portal }));

        dispatch(setDeleteButtonWatcher({ deleteButtonWatcher: true }));

        setNavbarSearch(false);

        // ---- Change gridView or listView tabs ----
        if (pathname === appPaths.indexHome.path) {
          if (gptSearchResults.tabKey) {
            dispatch(updateActiveTabGrid({ tab: gptSearchResults.tabKey }));
            if (pathname !== appPaths.tableView.path) {
              dispatch(setViewActive("grid"));
              history.push(appPaths.home.path);
            }
          } else {
            history.push(appPaths.home.path);
          }
        } else if (gptSearchResults.tabKey) {
          dispatch(updateActiveTabGrid({ tab: gptSearchResults.tabKey }));
          if (pathname !== appPaths.tableView.path) {
            dispatch(setViewActive("grid"));
            history.push(appPaths.home.path);
          }
        }
      } else {
        setGptNoResultsToShow(true);
      }
    }
    if (isErrorGptSearchResults) {
      setGptNoResultsToShow(true);
    }
  }, [isSuccessGptSearchResults, isFetchingGptSearchResults, isErrorGptSearchResults]);

  useEffect(() => {
    if (gptNoResultsToShow) {
      setGptNoResultsToShow(false);
    }
  }, [handleSearch.text]);

  // ------------------------ End GPT SEARCH ----------------

  const { data: expandedParcels } = useGetParcelsByIdsQuery(
    {
      countryCode,
      parcelIds: expandedParcelsIds,
      include: [
        `${ParcelBackendKey.id}`,
        `${ParcelBackendKey.photos}`,
        `${ParcelBackendKey.name}`,
        `${ParcelBackendKey.address}`,
        `${ParcelBackendExpandKey.sector}.${SectorBackendKey.name}`,
      ],
    },
    {
      skip: !expandedParcelsIds?.length,
    },
  );

  // Parcel Results complements
  const { data: parcelListingsCounts, isFetching: isFetchingParcelListingsCounts } =
    useGetParcelsCountsQuery(
      {
        countryCode,
        entity: ParcelEntityCountBackendKey.LISTINGS,
        parcelIds: parcelsIds,
        filters: [
          {
            listing: {
              search: {
                dataset: { notIn: [DatasetListingsBackendKey.duplicate] },
              },
            },
          },
        ],
      },
      {
        skip: !parcelsIds?.length || !availableFilters.listing,
      },
    );
  const { data: parcelPermitsCounts, isFetching: isFetchingParcelPermitsCounts } =
    useGetParcelsCountsQuery(
      {
        countryCode,
        entity: ParcelEntityCountBackendKey.PERMITS,
        parcelIds: parcelsIds,
      },
      {
        skip: !parcelsIds?.length || !availableFilters.permit,
      },
    );
  const { data: parcelTransactionsCounts, isFetching: isFetchingParcelTransactionsCounts } =
    useGetParcelsCountsQuery(
      {
        countryCode,
        entity: ParcelEntityCountBackendKey.TRANSACTIONS,
        parcelIds: parcelsIds,
      },
      {
        skip: !parcelsIds?.length || !availableFilters.transaction,
      },
    );

  // Property Results complements
  const { data: propertyTransactionsCounts, isFetching: isFetchingPropertyTransactionsCounts } =
    useGetPropertiesCountsQuery(
      {
        countryCode,
        entity: PropertyEntityCountBackendKey.TRANSACTIONS,
        propertyIds: searchResults?.properties?.map((p) => p.id) as string[],
      },
      {
        skip: !searchResults?.properties?.length || !availableFilters.transaction,
      },
    );

  const getParcel = ({ parcelId }: { parcelId?: string }) => {
    if (parcelId && expandedParcels?.length) {
      return expandedParcels.find((parcel) => parcel.id === parcelId);
    } else return undefined;
  };

  const getCount = ({
    id,
    entityCounts,
  }: {
    id?: string | null;
    entityCounts?: EntityCount[];
  }): number | undefined => {
    if (entityCounts?.length && id) {
      const foundParcel = entityCounts.find((obj) => obj[id] !== undefined);
      return foundParcel ? foundParcel[id] : undefined;
    } else {
      return undefined;
    }
  };

  const onChangeFilter = ({
    value,
    filter,
    entity,
    type,
  }: {
    value: any;
    filter: NewsFiltersFrontKey;
    entity: NewsFiltersEntityFrontKey;
    type: NewsFiltersTypeFrontKey;
  }) => {
    const filterByPortal =
      portal === PortalKey.professional
        ? filtersByPortal[PortalKey.professional].filters
        : portal === PortalKey.commercial
        ? filtersByPortal[PortalKey.commercial].filters
        : portal === PortalKey.residential
        ? filtersByPortal[PortalKey.residential].filters
        : filtersByPortal[PortalKey.investpr].filters;

    const appliedValue = filterByPortal[entity][type]?.[filter]?.appliedValue;

    let values = appliedValue
      ? {
          ...filterByPortal[entity][type],
          [filter]: { value, appliedValue },
        }
      : {
          ...filterByPortal[entity][type],
          [filter]: { value },
        };

    if (filter === NewsFiltersFrontKey.cityId) {
      values = {
        ...values,
        [NewsFiltersFrontKey.neighborhoodId]: { value: null },
        [NewsFiltersFrontKey.zipCodeId]: { value: null },
      };
      dispatch(getNeighbordhoods({ cityId: value.value, countryCode }));
    }

    if (filter === NewsFiltersFrontKey.neighborhoodId) {
      values = {
        ...values,
        [NewsFiltersFrontKey.subNeighborhoodId]: { value: null },
        [NewsFiltersFrontKey.zipCodeId]: { value: null },
      };
      dispatch(getSubNeighbordhoods({ neighborhoodIds: [value.value], countryCode }));
    }

    const filterTypeValue = {
      [type]: values,
    };

    // Si el filtro es name de parcel, tamb hay que agregarlo al govtId
    if (
      filter === NewsFiltersFrontKey.name &&
      entity === NewsFiltersEntityFrontKey.parcel &&
      type === NewsFiltersTypeFrontKey.search
    ) {
      const valueGovtId = {
        ...filterByPortal[entity][type],
        [NewsFiltersFrontKey.govtId]: { value },
        [filter]: { value },
      };

      const newFilterTypeValue = {
        [type]: valueGovtId,
      };

      dispatch(
        setValueFilter({
          portal,
          entity,
          filterTypeValue: newFilterTypeValue,
        }),
      );
    } else {
      dispatch(
        setValueFilter({
          portal,
          entity,
          filterTypeValue: filterTypeValue,
        }),
      );
    }

    if (filter === NewsFiltersFrontKey.address) {
      removeFilter({
        filterName: NewsFiltersFrontKey.coordinates,
        entityFilterKey: NewsFiltersEntityFrontKey.location,
        typeFilterKey: NewsFiltersTypeFrontKey.geometric,
      });
      dispatch(setAppliedFilters({ portal }));
    } else if (filter === NewsFiltersFrontKey.coordinates) {
      removeFilter({
        filterName: NewsFiltersFrontKey.address,
        entityFilterKey: NewsFiltersEntityFrontKey.location,
        typeFilterKey: NewsFiltersTypeFrontKey.categoric,
      });
      dispatch(setAppliedFilters({ portal }));
    } else {
      applySearchFilters();
    }
  };

  const availableOrganizationFilters = (getOrganization().filters as AvailableFiltersByPortal)[
    portal
  ];

  const getLocations = (userInput: string) => {
    availableOrganizationFilters.search.groupedSuggestions.address &&
      dispatch(loadPlacePredictions({ userInput, countryCode }));
    setHandleSearch({ loading: true, text: userInput });
  };

  const handleCloseSearch = () => {
    setNavbarSearch(false);
    if (setOpenMobileSearchbar) setOpenMobileSearchbar(false);
  };

  // ------ Start Grouped Suggestions
  const subMarkets: SubMarketSuggestion[] = [];
  const listings: ListingSuggestion[] = [];
  const parcels: ParcelSuggestion[] = [];
  const properties: PropertySuggestion[] = [];
  const transactions: TransactionSuggestion[] = [];
  const tenants: TenantSuggestion[] = [];
  const permits: PermitSuggestion[] = [];
  const documents: DocumentSuggestion[] = [];
  const news: NewsSuggestion[] = [];

  if (searchResults) {
    if (searchResults.cities !== undefined) {
      searchResults.cities.map((city) => {
        const { value, label } = city;
        subMarkets.push({
          id: value,
          title: label,
          icon: "MapPin",
          filter: NewsFiltersFrontKey.cityId,
          entity: NewsFiltersEntityFrontKey.location,
          type: NewsFiltersTypeFrontKey.categoric,
        });
      });
    }
    if (searchResults.parcels !== undefined) {
      searchResults.parcels.map((parcel) => {
        const { id, name, photos, address, govtId, sector } = parcel;
        parcels.push({
          id,
          title: name,
          govtId: govtId ?? "---",
          img: photos?.length ? photos[0] : notImg,
          address,
          sector,
          link: appPropertyProfileQueryString({ id }),
          entity: NewsFiltersEntityFrontKey.parcel,
        });
      });
    }
    if (searchResults.listings !== undefined) {
      searchResults.listings.map((listing) => {
        const { id, title, price, photos, address, isForRent, active, propertyType, status } =
          listing;

        const isResidential =
          propertyType === ListingPropertyTypeBackendKey.HOUSE ||
          propertyType === ListingPropertyTypeBackendKey.APARTMENT;

        listings.push({
          id,
          title,
          price: price
            ? NumberFormat({
                value:
                  !isResidential && isForRent && countryCode === CountryCodeKey.PR
                    ? Number(price) * 12
                    : price,
                prefix: "$ ",
                suffix: "",
              })
            : language.results.listings.noPrice,
          img: photos?.length ? photos[0] : notImg,
          address,
          link: appListingProfileQueryString(id),
          entity: NewsFiltersEntityFrontKey.listing,
          isForRent,
          active,
          status,
          propertyType,
        });
      });
    }
    if (searchResults.properties) {
      searchResults.properties.map((property) => {
        const { id, address, govtId, parcelId, parcel } = property;

        properties.push({
          id,
          address,
          govtId: govtId ?? "---",
          img: parcel?.photos?.length ? parcel?.photos[0] : notImg,
          parcelId,
          parcel,
          link: parcelId
            ? appPropertyProfileQueryString({
                id: parcelId,
                entity: PropertyFeatureTabKey.units,
                elementId: id,
              })
            : null,
          entity: NewsFiltersEntityFrontKey.property,
        });
      });
    }
    if (searchResults.transactions) {
      searchResults.transactions.map((transaction) => {
        const {
          id,
          address,
          sellerName,
          buyerName,
          transactionType,
          transactionPrice,
          pricePerBuiltSqm,
          transactionProperties,
        } = transaction;

        const parcelId = transactionProperties?.[0]?.property?.parcelId;

        transactions.push({
          id,
          address,
          img: notImg,
          sellerName: sellerName ?? "---",
          buyerName: buyerName ?? "---",
          transactionType,
          transactionPrice:
            transactionPrice && Number(transactionPrice) >= 0
              ? NumberFormat({
                  value: transactionPrice,
                  prefix: "$ ",
                  suffix: "",
                })
              : "---",
          pricePerBuiltSqm,
          parcelId,
          link: parcelId
            ? appPropertyProfileQueryString({
                id: parcelId,
                elementId: id,
                entity: PropertyFeatureTabKey.transactions,
              })
            : null,
          entity: NewsFiltersEntityFrontKey.transaction,
          unitId: transactionProperties?.[0]?.property?.id,
          transactionProperties: transactionProperties ?? [],
        });
      });
    }
    if (searchResults.tenants) {
      searchResults.tenants.map((tenant) => {
        const { id, name, address, parcel, sector, subsector, microsector } = tenant;

        tenants.push({
          id,
          title: name,
          address,
          img: parcel?.photos?.length ? parcel?.photos[0] : notImg,
          parcel,
          sector,
          subsector,
          microsector,
          link: parcel?.id
            ? appPropertyProfileQueryString({
                id: parcel.id,
                entity: PropertyFeatureTabKey.tenants,
                elementId: id,
              })
            : null,
          entity: NewsFiltersEntityFrontKey.tenant,
        });
      });
    }
    if (searchResults.permits) {
      searchResults.permits.map((permit) => {
        const { id, name, address, parcel, status } = permit;

        permits.push({
          id: id,
          title: name,
          address: address,
          img: parcel?.photos?.length ? parcel?.photos[0] : notImg,
          parcel,
          status,
          link: parcel?.id
            ? appPropertyProfileQueryString({
                id: parcel.id,
                entity: PropertyFeatureTabKey.permits,
                elementId: id,
              })
            : null,
          entity: NewsFiltersEntityFrontKey.permit,
        });
      });
    }
    if (searchResults.documents) {
      searchResults.documents.map((document) => {
        const { id, address, transactionType, transactionProperties } = document;

        const parcelId = transactionProperties?.[0]?.property?.parcelId;

        documents.push({
          id: id,
          address: address,
          img: notImg,
          transactionType, // This property makes it use the translation of type and not of transactionType.
          parcelId,
          link: parcelId
            ? appPropertyProfileQueryString({
                id: parcelId,
                entity: PropertyFeatureTabKey.attachments,
                elementId: id,
              })
            : null,
          entity: NewsFiltersEntityFrontKey.transaction,
        });
      });
    }
    if (searchResults.news) {
      searchResults.news.map((nNew) => {
        const {
          id,
          title,
          esTitle,
          description,
          esDescription,
          category,
          date,
          link,
          gptJSON,
          city,
        } = nNew;
        news.push({
          id,
          title,
          esTitle,
          description,
          esDescription,
          category,
          date,
          link,
          gptJSON,
          city,
          entity: "news",
        });
      });
    }
  }
  // ------ End Grouped Locations Suggestions

  useEffect(() => {
    const filtersSearch = availableOrganizationFilters.search.groupedSuggestions;
    const results: Suggestion[] = [];
    for (const filter in filtersSearch) {
      searchResult.map((search) => {
        if (search.id === filter && getOrganization().filters) {
          return results.push(search);
        }
        setSuggestions([]);
      });
    }
    setSuggestions(results);
  }, [searchResults, availableOrganizationFilters.search.groupedSuggestions]);

  const searchResult: Suggestion[] = [
    {
      id: "address",
      groupTitle: language.results.address.title,
      data: addressPredictions,
    },
    {
      id: "submarkets",
      groupTitle: language.results.subMarkets.title,
      searchLimit: 3,
      data: subMarkets,
    },
    {
      id: "listings",
      groupTitle: language.results.listings.title,
      data: listings,
    },
    {
      id: "parcels",
      groupTitle: language.results.parcels.title,
      data: parcels,
    },
    {
      id: "properties",
      groupTitle: language.results.properties.title,
      data: properties,
    },
    {
      id: "transactions",
      groupTitle: language.results.transactions.title,
      data: transactions,
    },
    {
      id: "tenants",
      groupTitle: language.results.tenants.title,
      data: tenants,
    },
    {
      id: "permits",
      groupTitle: language.results.permits.title,
      data: permits,
    },
    {
      id: "documents",
      groupTitle: language.results.documents.title,
      data: documents,
    },
    {
      id: "news",
      groupTitle: language.results.news.title,
      data: news,
    },
  ];

  useEffect(() => {
    const ids: string[] = [];
    const transactionParcelsIds: string[] = [];
    if (searchResults?.parcels?.length) {
      // ids.push(searchResults.parcels.map((parcel) => parcel.id));
      Array.prototype.push.apply(
        ids,
        searchResults.parcels.map((parcel) => parcel.id),
      );
    }
    if (searchResults?.properties?.length) {
      Array.prototype.push.apply(
        ids,
        searchResults.properties.map((property) => property.parcelId),
      );
    }
    if (searchResults?.transactions?.length) {
      for (let index = 0; index < searchResults.transactions.length; index++) {
        const transaction = searchResults.transactions[index];
        if (
          transaction?.transactionProperties?.length &&
          transaction?.transactionProperties[0]?.property?.parcelId
        ) {
          transactionParcelsIds.push(transaction?.transactionProperties[0]?.property?.parcelId);
        }
      }
    }
    if (searchResults?.documents?.length) {
      for (let index = 0; index < searchResults.documents.length; index++) {
        const document = searchResults.documents[index];
        if (
          document?.transactionProperties?.length &&
          document?.transactionProperties[0]?.property?.parcelId
        ) {
          transactionParcelsIds.push(document?.transactionProperties[0]?.property?.parcelId);
        }
      }
    }
    if (ids.length) {
      setParcelsIds(ids);
    }
    if (transactionParcelsIds.length) {
      setExpandedParcelsIds(transactionParcelsIds);
    }
  }, [searchResults]);

  return (
    <React.Fragment>
      <NavItem
        className={classNames("nav-search w-100", {
          "px-3": fullWidth && !isMobile,
          "px-1": !fullWidth,
        })}
        style={{
          overflow: "visible",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setNavbarSearch(!navbarSearch);
        }}>
        {!navbarSearch ? (
          <div
            className={classNames("d-flex align-items-center cursor-pointer w-100", {
              "placeholder-container": !fullWidth,
            })}>
            {!fullWidth ? (
              <>
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-05"
                  style={{ fontSize: "16px", color: primary.color }}
                  data-tour="search"
                />
                <p
                  className="mb-0 text-overflow-ellipsis w-100"
                  style={{ maxWidth: isMobile ? "calc(65vw - 2rem)" : "" }}>
                  {userInputSearch.value
                    ? userInputSearch.value
                    : userInput
                    ? userInput
                    : `${language.placeholder}...`}
                </p>
              </>
            ) : (
              <div className="w-100">
                <div
                  className={classNames("d-flex text-overflow-ellipsis", {
                    "align-items-start": !isMobile,
                    "flex-column": isMobile,
                  })}
                  style={{ width: "100%" }}>
                  <div
                    className={classNames("d-flex align-items-center", {
                      "flex-column": isMobile,
                    })}>
                    <div
                      className="d-flex"
                      style={{
                        marginBottom: isMobile && fullWidth ? ".3rem" : "",
                        marginTop: isMobile && fullWidth ? "1rem" : "",
                      }}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="mr-05"
                        style={{ fontSize: "16px", color: common.black }}
                        data-tour="search"
                      />
                      <h4
                        className="fw-bold mr-1 mb-0"
                        style={{
                          color: common.black,
                          fontSize: isMobile ? "1rem" : "1.32rem",
                          alignSelf: "flex-end",
                        }}>
                        {language.homePopup.placeholderTitle}
                      </h4>
                    </div>
                    <div className="d-flex w-100">
                      <small
                        style={{
                          whiteSpace: "pre-line",
                          alignSelf: "flex-end",
                          textAlign: "left",
                        }}>
                        {`${language.homePopup.example}: `}
                        <Typewriter
                          texts={language.homePopup.examples}
                          writeDelay={80}
                          backspaceDelay={20}
                          pauseDelay={1000}
                        />
                      </small>
                    </div>
                  </div>
                </div>

                <hr
                  className="mt-0"
                  style={{ marginBottom: "4px", borderTop: `1px solid ${primary.color}` }}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div
              className={classnames("search-input align-items-center w-100 position-relative ", {
                open: navbarSearch,
                "d-none": !navbarSearch,
                "d-flex": navbarSearch,
                "placeholder-container": !fullWidth && !isMobile,
              })}
              style={{ borderBottom: fullWidth ? `1px solid ${primary.color}` : "" }}
              ref={container}>
              <FontAwesomeIcon
                icon={faSearch}
                className="mr-05"
                style={{ fontSize: "16px", color: primary.color }}
                data-tour="search"
                onClick={() => handleCloseSearch()}
              />
              <Autocomplete
                handleCloseSearch={handleCloseSearch}
                container={container}
                filteredData={filteredData}
                userInput={userInput}
                setUserInput={setUserInput}
                showSuggestions={showSuggestions}
                setShowSuggestions={setShowSuggestions}
                wasStringModifiedRef={wasStringModifiedRef}
                fullWidth={fullWidth}
                isLoading={isLoading || isFetching}
                className={`form-control`}
                portal={portal}
                suggestions={suggestions}
                filterKey="title"
                filterHeaderKey="groupTitle"
                placeholder={`${language.placeholder}...`}
                autoFocus={true}
                changeUserInput={onChangeFilter}
                applyFilters={applySearchFilters}
                getLocations={getLocations}
                clearInput={navbarSearch}
                setHandleSearch={setHandleSearch}
                externalClick={(e) => setNavbarSearch(false)}
                setNavbarSearch={setNavbarSearch}
                onClickGpt={onClickGpt}
                activeSuggestion={activeSuggestion}
                setActiveSuggestion={setActiveSuggestion}
              />

              <SuggestionsBox
                setFilteredData={setFilteredData}
                filteredData={filteredData}
                fullWidth={fullWidth}
                changeUserInput={onChangeFilter}
                setUserInput={setUserInput}
                userInput={userInput}
                gptSearchResults={gptSearchResults?.filtersRedux}
                filterKey="title"
                filterHeaderKey="groupTitle"
                suggestions={suggestions}
                showSuggestions={showSuggestions}
                setShowSuggestions={setShowSuggestions}
                applyFilters={applySearchFilters}
                onClickGpt={onClickGpt}
                gptNoResultsToShow={gptNoResultsToShow}
                isLoadingGptSearchFilters={isLoadingGptSearchResults || isFetchingGptSearchResults}
                customRender={(
                  item,
                  i,
                  filteredData,
                  activeSuggestion,
                  onSuggestionItemClick,
                  onSuggestionItemHover,
                ) => {
                  const IconTag = item.icon ? Icon[item.icon] : null;
                  return (
                    <li
                      className={classnames("suggestion-item", {
                        active: filteredData.indexOf(item) === activeSuggestion,
                      })}
                      key={i}
                      onClick={(e) => {
                        onSuggestionItemClick({
                          id: item.id,
                          e,
                          title: item.title,
                          url: item.link,
                          filter: item.filter,
                          entity: item.entity,
                          type: item.type,
                          unitId: item.unitId,
                        });
                      }}
                      onMouseEnter={(e) => onSuggestionItemHover(filteredData.indexOf(item))}>
                      {item.entity === NewsFiltersEntityFrontKey.listing ? (
                        <ListingSuggestion item={item} />
                      ) : item.entity === NewsFiltersEntityFrontKey.parcel ? (
                        <ParcelSuggestion
                          item={item}
                          isFetchingListingCounts={isFetchingParcelListingsCounts}
                          listingCount={getCount({
                            id: item.id,
                            entityCounts: parcelListingsCounts,
                          })}
                          isFetchingPermitCounts={isFetchingParcelPermitsCounts}
                          permitCount={getCount({
                            id: item.id,
                            entityCounts: parcelPermitsCounts,
                          })}
                          isFetchingTransactionCounts={isFetchingParcelTransactionsCounts}
                          transactionCount={getCount({
                            id: item.id,
                            entityCounts: parcelTransactionsCounts,
                          })}
                        />
                      ) : item.entity === NewsFiltersEntityFrontKey.property ? (
                        <UnitSuggestion
                          item={item}
                          isFetchingTransactionCounts={isFetchingPropertyTransactionsCounts}
                          transactionCount={getCount({
                            id: item.id,
                            entityCounts: propertyTransactionsCounts,
                          })}
                        />
                      ) : item.entity === NewsFiltersEntityFrontKey.tenant ? (
                        <TenantSuggestion item={item} />
                      ) : item.entity === NewsFiltersEntityFrontKey.permit ? (
                        <PermitSuggestion item={item} />
                      ) : item.entity === NewsFiltersEntityFrontKey.transaction ? (
                        <TransactionSuggestion
                          item={item}
                          parcel={
                            item?.parcelId
                              ? getParcel({
                                  parcelId: item.parcelId,
                                })
                              : undefined
                          }
                        />
                      ) : item.entity === "news" ? (
                        <NewCard item={item} />
                      ) : (
                        <div
                          className={classnames({
                            "d-flex justify-content-between align-items-center": item.img,
                          })}>
                          <div className="item-container d-flex">
                            {IconTag ? (
                              <IconTag size={17} />
                            ) : item.img ? (
                              <img
                                className="img-list mt-25"
                                src={item.img}
                                height="60"
                                width="60"
                                alt={item.title ?? undefined}
                              />
                            ) : null}
                            <div
                              className="item-info ml-1 d-flex align-items-center"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}>
                              <div className="w-100">
                                {item.entity ? (
                                  <h6
                                    className="align-middle mb-0 fw-bold black"
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: "400px",
                                    }}>
                                    {item.title}
                                  </h6>
                                ) : (
                                  <p className="align-middle mb-0 fw-bold black">{item.title}</p>
                                )}
                                {item.address ? (
                                  <div
                                    className="mt-05"
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: "386px",
                                    }}>
                                    <small className="fw-bold">{`${language.results.parcels.address}: `}</small>
                                    <small>{item.address}</small>
                                  </div>
                                ) : null}
                                {item.price ? (
                                  <div className="mt-05">
                                    <small className="fw-bold">{`${language.results.parcels.price}: `}</small>
                                    <small>{item.price}</small>
                                  </div>
                                ) : null}
                                {item.govtId ? (
                                  <div className="mt-05">
                                    <small className="fw-bold">{`${language.results.parcels.govtId}: `}</small>
                                    <small>{item.govtId}</small>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {item.status ? (
                            <Pill
                              color={defaultColors.background}
                              backgroundColor={listingStatusColorsColorsMapper({
                                statusName: item.status,
                                statusColor: listingColors.status,
                              })}
                              borderColor={listingStatusColorsColorsMapper({
                                statusName: item.status,
                                statusColor: listingColors.status,
                              })}>
                              {languagelistingStatus(item.status)}
                            </Pill>
                          ) : null}
                        </div>
                      )}
                    </li>
                  );
                }}
                isLoading={isLoading || isFetching}
                wasStringModifiedRef={wasStringModifiedRef}
                activeSuggestion={activeSuggestion}
                setActiveSuggestion={setActiveSuggestion}
              />
            </div>
          </>
        )}
      </NavItem>
    </React.Fragment>
  );
}
export default SearchNavbar;
