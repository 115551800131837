import { AvailableMemberList } from "@/models";

export const responseAvailableMemberListAdapter = (
  availables: AvailableMemberList[],
): AvailableMemberList[] => {
  const data: AvailableMemberList[] = [];
  if (availables.length) {
    availables.map((available) =>
      data.push({
        id: available.id,
        name: available.name,
        description: available.description,
        available: available.available,
      }),
    );
  }
  return data;
};
