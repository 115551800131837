import { TimeOnMarketDataBackend } from "@/adapters";
import { TimeOnMarketDataBackendKey, TimeOnMarketDataKey } from "@/keys";
import { TimeOnMarketData } from "@/models";

export const responseReportTimeOnDataMarketAdapter = (
  data: TimeOnMarketDataBackend,
): TimeOnMarketData => ({
  [TimeOnMarketDataKey.oneMonth]: data[TimeOnMarketDataBackendKey.oneMonth] ?? null,
  [TimeOnMarketDataKey.sixMonths]: data[TimeOnMarketDataBackendKey.sixMonth] ?? null,
  [TimeOnMarketDataKey.twelveMonths]: data[TimeOnMarketDataBackendKey.twelveMonth] ?? null,
  [TimeOnMarketDataKey.certainty]: data[TimeOnMarketDataBackendKey.certainty] ?? null,
});
