import {
  DatasetListingsBackendKey,
  DatasetTransactionsBackendKey,
  KeyofSortDirectionBackendKey,
  KeyofSortDirectionKey,
  ListingBackendInclude,
  NewsFiltersEntityBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeBackendKey,
  NewsFiltersTypeFrontKey,
  ParcelBackendInclude,
  PermitBackendInclude,
  PropertyBackendInclude,
  SortDirectionBackendKey,
  SortDirectionKey,
  TabKey,
  TaxBackendInclude,
  TenantBackendInclude,
  TransactionBackendInclude,
} from "@/keys";
import { GeometricFilter, Pagination, SearchIds } from "@/models";
import { Filters, OnlyKeyFilterFrontKey } from "@/redux/slices";
import { convertPricePerUnit, convertUnits, DeleteCommas, UnitMeasurement } from "@/utilities";
import { PaginationBackend, requestFiltersOrAdapter } from "@/adapters";

const parseNumberFormat = ({
  number,
  filter,
  builtAreaUnit,
  landAreaUnit,
}: {
  number: number;
  filter: OnlyKeyFilterFrontKey;
  builtAreaUnit: UnitMeasurement;
  landAreaUnit: UnitMeasurement;
}) => {
  if (filter === NewsFiltersFrontKey.builtArea || filter === NewsFiltersFrontKey.totalArea) {
    return convertUnits({
      value: number,
      from: builtAreaUnit,
      to: UnitMeasurement.sqm,
    }) as number;
  } else if (filter === NewsFiltersFrontKey.landArea || filter === NewsFiltersFrontKey.lotArea) {
    return convertUnits({
      value: number,
      from: landAreaUnit,
      to: UnitMeasurement.sqm,
    }) as number;
  } else {
    return number;
  }
};

export type NumericFilterBackend = {
  min?: number;
  max?: number;
};

type BooleanFilterBackend = boolean | boolean[];

type CategoricFilterBackend = string | string[] | { matchAll: string[]; matchKey: string };

type DateFilterBackend = {
  min?: Date;
  max?: Date;
};

type SearchFilterBackend<KeyType extends string> =
  | KeyType
  | KeyType[]
  | { like: KeyType }
  | { ilike: KeyType }
  | { isNotNull: true }
  | { isNull: true }
  | { notIn: KeyType[] };

type GeometryFilterBackend = GeometricFilter | { intersects: GeometricFilter[] };
export type InputsTableFiltersBackend = {
  numeric?: Record<string, NumericFilterBackend>;
  boolean?: Record<string, BooleanFilterBackend>;
  categoric?: Record<string, CategoricFilterBackend>;
  date?: Record<string, DateFilterBackend>;
  search?: Record<string, SearchFilterBackend<string>>;
  geometry?: Record<string, GeometryFilterBackend>;
};

export type FiltersBackend = {
  parcel?: InputsTableFiltersBackend;
  listing?: InputsTableFiltersBackend;
  transaction?: InputsTableFiltersBackend;
  property?: InputsTableFiltersBackend;
  listingAmenity?: InputsTableFiltersBackend;
  tax?: InputsTableFiltersBackend;
  tenant?: InputsTableFiltersBackend;
  permit?: InputsTableFiltersBackend;
};

export type BackendInclude =
  | ParcelBackendInclude
  | ListingBackendInclude
  | PermitBackendInclude
  | PropertyBackendInclude
  | TaxBackendInclude
  | TenantBackendInclude
  | TransactionBackendInclude;

export type RequestFiltersBackendBase<T extends BackendInclude> = {
  filters?: FiltersBackend[];
  pagination?: PaginationBackend;
  include?: T[];
};

export type StrictRequestFiltersBackend = {
  filters?: FiltersBackend[];
};

export type FlexibleRequestFiltersBackend<T extends BackendInclude> = RequestFiltersBackendBase<T>;

export type RequestFiltersBackend<
  T extends BackendInclude,
  Strict extends { isCount: boolean },
> = Strict extends { isCount: true }
  ? StrictRequestFiltersBackend
  : FlexibleRequestFiltersBackend<T>;

//-------------------- Export File
export type BodyExportFileBackend = RequestFiltersBackend<BackendInclude, { isCount: false }> & {
  columns: Record<string, unknown>;
  format: string;
};

export type RequestFiltersAdapterPropsBase<T extends BackendInclude> = {
  filters: Filters;
  builtAreaUnit: UnitMeasurement;
  landAreaUnit: UnitMeasurement;
  pagination?: Pick<Pagination, "currentPage" | "perPage">;
  sortBy?: string | string[];
  sortOrder?: KeyofSortDirectionBackendKey | KeyofSortDirectionKey;
  defaultFilters: FiltersBackend;
  includeViewport?: boolean;
  isListing?: boolean;
  include?: T[];
  activeListing?: boolean;
  listingsWithParcel?: boolean;
  transactionsWithScriptures?: boolean;
  datasetFiltersListings?: SearchFilterBackend<keyof typeof DatasetListingsBackendKey> | null;
  datasetFiltersTransactions?: SearchFilterBackend<
    keyof typeof DatasetTransactionsBackendKey
  > | null;
  listingsIsOutlier?: boolean;
  searchIds?: SearchIds;
  matchListingFilters?: boolean;
  matchParcelFilters?: boolean;
};

// Con isCount
export type CountRequestFiltersAdapterProps<
  T extends BackendInclude,
  IsCount,
> = RequestFiltersAdapterPropsBase<T> & {
  isCount?: IsCount;
};

// Sin isCount
export type FlexibleRequestFiltersAdapterProps<T extends BackendInclude> =
  RequestFiltersAdapterPropsBase<T> & {
    isCount?: boolean;
  };

export type RequestFiltersAdapterProps<
  T extends BackendInclude,
  IsCount extends { isCount: boolean },
> = IsCount extends { isCount: true }
  ? CountRequestFiltersAdapterProps<T, IsCount["isCount"]>
  : FlexibleRequestFiltersAdapterProps<T>;

// ! ESTE ARMA EL BODY
export const requestFiltersAdapter = ({
  filters,
  isCount = false,
  defaultFilters,
  builtAreaUnit,
  landAreaUnit,
  pagination,
  sortBy,
  sortOrder,
  includeViewport,
  isListing,
  include,
  activeListing,
  listingsWithParcel,
  transactionsWithScriptures,
  datasetFiltersListings,
  datasetFiltersTransactions,
  listingsIsOutlier,
  searchIds,
  matchListingFilters,
  matchParcelFilters,
}: RequestFiltersAdapterProps<
  BackendInclude,
  { isCount: boolean }
>): FlexibleRequestFiltersBackend<BackendInclude> => {
  let entityKey: keyof typeof NewsFiltersEntityFrontKey;
  let typeFilterBackendKey: keyof typeof NewsFiltersTypeBackendKey;
  let typeFilterKey: keyof typeof NewsFiltersTypeFrontKey;
  let entityBackendKey: keyof typeof NewsFiltersEntityBackendKey;

  let newFilters: FiltersBackend = {};

  for (entityKey in filters) {
    if (entityKey !== NewsFiltersEntityFrontKey.location) {
      for (typeFilterKey in filters[entityKey]) {
        if (typeFilterKey === NewsFiltersTypeFrontKey.categoric) {
          for (const index in filters[entityKey][typeFilterKey]) {
            const appliedValue = filters[entityKey][typeFilterKey]?.[index].appliedValue;
            // Estos son los filtros categoric que deben funcionar como una compuerta AND
            const categoricFiltersAND = [
              {
                key: NewsFiltersFrontKey.genericListingId,
                matchKey: NewsFiltersFrontKey.amenityId,
              },
            ];

            if (
              appliedValue &&
              index !== NewsFiltersFrontKey.amenity && // estos filtros se usan para almacenar en el front las amenities separadas pero siempre se mergean todas en amenityId
              index !== NewsFiltersFrontKey.amenityRoom &&
              index !== NewsFiltersFrontKey.amenityView
            ) {
              let finalValue = {};
              const ANDFilter = categoricFiltersAND.find((f) => f.matchKey === index);
              const appliedValueParsed = Array.isArray(appliedValue)
                ? appliedValue.map((value) => value.value)
                : appliedValue.value;
              if (ANDFilter) {
                // Los filtros AND se envian de forma distinta
                finalValue = {
                  [ANDFilter.key]: {
                    matchKey: ANDFilter.matchKey,
                    matchAll: appliedValueParsed,
                  },
                };
              } else {
                finalValue = {
                  [index]: appliedValueParsed,
                };
              }
              newFilters = {
                ...newFilters,
                [entityKey]: {
                  ...newFilters[entityKey],
                  [typeFilterKey]: {
                    ...newFilters?.[entityKey]?.[typeFilterKey],
                    ...finalValue,
                  },
                },
              };
            }
          }
        }
        if (typeFilterKey === NewsFiltersTypeFrontKey.numeric) {
          for (const index in filters[entityKey][typeFilterKey]) {
            if (filters[entityKey][typeFilterKey]?.[index].appliedValue) {
              const minMax: { min?: number; max?: number } = {};
              if (filters[entityKey][typeFilterKey]?.[index].appliedValue?.min) {
                let newValue = filters[entityKey][typeFilterKey]?.[index].appliedValue?.min;
                if (
                  typeof filters[entityKey][typeFilterKey]?.[index].appliedValue?.min === "string"
                ) {
                  newValue = DeleteCommas(
                    filters[entityKey][typeFilterKey]?.[index].appliedValue?.min as string,
                  );
                }

                minMax.min = parseNumberFormat({
                  number: parseFloat(newValue as string),
                  filter: index as OnlyKeyFilterFrontKey,
                  builtAreaUnit,
                  landAreaUnit,
                });
                if (index === NewsFiltersFrontKey.pricePerBuiltSqm) {
                  minMax.min = convertPricePerUnit({
                    value: minMax.min,
                    from: builtAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.pricePerLotSqm) {
                  minMax.min = convertPricePerUnit({
                    value: minMax.min,
                    from: landAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.transactionPricePerBuiltSqm) {
                  minMax.min = convertPricePerUnit({
                    value: minMax.min,
                    from: builtAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.transactionPricePerLotSqm) {
                  minMax.min = convertPricePerUnit({
                    value: minMax.min,
                    from: landAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                }
              }
              if (filters[entityKey][typeFilterKey]?.[index].appliedValue?.max) {
                let newValue = filters[entityKey][typeFilterKey]?.[index].appliedValue?.max;
                if (
                  typeof filters[entityKey][typeFilterKey]?.[index].appliedValue?.max === "string"
                ) {
                  newValue = DeleteCommas(
                    filters[entityKey][typeFilterKey]?.[index].appliedValue?.max as string,
                  );
                }

                minMax.max = parseNumberFormat({
                  number: parseFloat(newValue as string),
                  filter: index as OnlyKeyFilterFrontKey,
                  builtAreaUnit,
                  landAreaUnit,
                });
                if (index === NewsFiltersFrontKey.pricePerBuiltSqm) {
                  minMax.max = convertPricePerUnit({
                    value: minMax.max,
                    from: builtAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.pricePerLotSqm) {
                  minMax.max = convertPricePerUnit({
                    value: minMax.max,
                    from: landAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.transactionPricePerBuiltSqm) {
                  minMax.max = convertPricePerUnit({
                    value: minMax.max,
                    from: builtAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                } else if (index === NewsFiltersFrontKey.transactionPricePerLotSqm) {
                  minMax.max = convertPricePerUnit({
                    value: minMax.max,
                    from: landAreaUnit,
                    to: UnitMeasurement.sqm,
                  }) as number;
                }
              }
              newFilters = {
                ...newFilters,
                [entityKey]: {
                  ...newFilters[entityKey],
                  [typeFilterKey]: {
                    ...newFilters?.[entityKey]?.[typeFilterKey],
                    [index]: minMax,
                  },
                },
              };
            }
          }
        }
        if (typeFilterKey === NewsFiltersTypeFrontKey.boolean) {
          const value: Record<string, BooleanFilterBackend> = {
            ...newFilters?.[entityKey]?.[typeFilterKey],
          };
          for (const index in filters[entityKey][typeFilterKey]) {
            if (filters[entityKey][typeFilterKey]?.[index].appliedValue !== undefined) {
              if (
                [
                  NewsFiltersFrontKey.isFloodZone,
                  NewsFiltersFrontKey.isForRent,
                  NewsFiltersFrontKey.isForSale,
                  NewsFiltersFrontKey.active,
                  NewsFiltersFrontKey.inactive,
                ].includes(index as NewsFiltersFrontKey)
              ) {
                value[index] = filters[entityKey][typeFilterKey]?.[index].appliedValue as boolean;
              }

              // isForRent
              if (
                value[NewsFiltersFrontKey.isForRent] !== undefined &&
                value[NewsFiltersFrontKey.isForSale] !== undefined
              ) {
                if (value[NewsFiltersFrontKey.isForRent] && value[NewsFiltersFrontKey.isForSale]) {
                  value[NewsFiltersFrontKey.isForRent] = [true, false];
                }

                delete value[NewsFiltersFrontKey.isForSale];
              } else if (value[NewsFiltersFrontKey.isForSale] !== undefined) {
                if (value[NewsFiltersFrontKey.isForSale]) {
                  value[NewsFiltersFrontKey.isForRent] = [false];
                }
                delete value[NewsFiltersFrontKey.isForSale];
              }

              // active
              if (
                value[NewsFiltersFrontKey.active] !== undefined &&
                value[NewsFiltersFrontKey.inactive] !== undefined
              ) {
                if (value[NewsFiltersFrontKey.active] && value[NewsFiltersFrontKey.inactive]) {
                  value[NewsFiltersFrontKey.active] = [true, false];
                }

                delete value[NewsFiltersFrontKey.inactive];
              } else if (value[NewsFiltersFrontKey.inactive] !== undefined) {
                if (value[NewsFiltersFrontKey.inactive]) {
                  value[NewsFiltersFrontKey.active] = [false];
                }
                delete value[NewsFiltersFrontKey.inactive];
              }

              // floodZone
              if (value[NewsFiltersFrontKey.isFloodZone] !== undefined) {
                if (value[NewsFiltersFrontKey.isFloodZone]) {
                  value[NewsFiltersFrontKey.isFloodZone] = false;
                }
              }

              newFilters = {
                ...newFilters,
                [entityKey]: {
                  ...newFilters[entityKey],
                  [typeFilterKey]: value,
                },
              };
            }
          }
        }
        if (typeFilterKey === NewsFiltersTypeFrontKey.date) {
          for (const index in filters[entityKey][typeFilterKey]) {
            if (filters[entityKey][typeFilterKey]?.[index].appliedValue) {
              const minMax: { min?: Date; max?: Date } = {};
              if (filters[entityKey][typeFilterKey]?.[index].appliedValue?.min !== null) {
                minMax.min = filters[entityKey][typeFilterKey]?.[index].appliedValue?.min as Date;
              }
              if (filters[entityKey][typeFilterKey]?.[index].appliedValue?.max !== null) {
                minMax.max = filters[entityKey][typeFilterKey]?.[index].appliedValue?.max as Date;
              }
              newFilters = {
                ...newFilters,
                [entityKey]: {
                  ...newFilters[entityKey],
                  [typeFilterKey]: {
                    ...newFilters?.[entityKey]?.[typeFilterKey],
                    [index]: minMax,
                  },
                },
              };
            }
          }
        }
        if (typeFilterKey === NewsFiltersTypeFrontKey.search) {
          for (const index in filters[entityKey][typeFilterKey]) {
            if (filters[entityKey][typeFilterKey]?.[index].appliedValue) {
              newFilters = {
                ...newFilters,
                [entityKey]: {
                  ...newFilters[entityKey],
                  [typeFilterKey]: {
                    ...newFilters?.[entityKey]?.[typeFilterKey],
                    [index]: { ilike: filters[entityKey][typeFilterKey]?.[index].appliedValue },
                  },
                },
              };
            }
          }
        }
        // ---------- Falta geometric
      }
    } else {
      // ---------- CASE LOCATION
      for (typeFilterKey in filters[entityKey]) {
        if (typeFilterKey === NewsFiltersTypeFrontKey.categoric) {
          for (const index in filters[entityKey][typeFilterKey]) {
            if (
              filters[entityKey][typeFilterKey]?.[index].appliedValue &&
              index !== NewsFiltersFrontKey.address
            ) {
              const appliedValue = filters[entityKey][typeFilterKey]?.[index].appliedValue;
              const value = Array.isArray(appliedValue)
                ? appliedValue?.map((value) => value.value)
                : appliedValue?.value;
              if (value)
                if (isListing) {
                  newFilters = {
                    ...newFilters,
                    [NewsFiltersEntityFrontKey.listing]: {
                      ...newFilters[NewsFiltersEntityFrontKey.listing],
                      [typeFilterKey]: {
                        ...newFilters?.[NewsFiltersEntityFrontKey.listing]?.[typeFilterKey],
                        [index]: value,
                      },
                    },
                  };
                } else {
                  newFilters = {
                    ...newFilters,
                    [NewsFiltersEntityFrontKey.parcel]: {
                      ...newFilters[NewsFiltersEntityFrontKey.parcel],
                      [typeFilterKey]: {
                        ...newFilters?.[NewsFiltersEntityFrontKey.parcel]?.[typeFilterKey],
                        [index]: value,
                      },
                    },
                  };
                }
            }
          }
        }

        if (typeFilterKey === NewsFiltersTypeFrontKey.geometric) {
          for (const index in filters[entityKey][typeFilterKey]) {
            if (
              filters[entityKey][typeFilterKey]?.[index].appliedValue &&
              index === NewsFiltersFrontKey.drawnPolygon
            ) {
              if (isListing) {
                let intersectsBuffer: GeometricFilter[] = [];
                if (
                  (
                    newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location as {
                      intersects: GeometricFilter[];
                    }
                  )?.intersects.length
                ) {
                  intersectsBuffer = (
                    newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location as {
                      intersects: GeometricFilter[];
                    }
                  )?.intersects;
                }

                newFilters = {
                  ...newFilters,
                  [NewsFiltersEntityFrontKey.listing]: {
                    ...newFilters[NewsFiltersEntityFrontKey.listing],
                    geometry: {
                      ...newFilters[NewsFiltersEntityFrontKey.listing]?.geometry,
                      location: {
                        ...newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location,
                        intersects: [
                          ...intersectsBuffer,
                          filters[entityKey][typeFilterKey]?.[index]
                            .appliedValue as GeometricFilter,
                        ],
                      } as GeometryFilterBackend,
                    },
                  },
                };
              } else {
                let intersectsBuffer: GeometricFilter[] = [];
                if (
                  (
                    newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry as {
                      intersects: GeometricFilter[];
                    }
                  )?.intersects.length
                ) {
                  intersectsBuffer = (
                    newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry as {
                      intersects: GeometricFilter[];
                    }
                  )?.intersects;
                }
                newFilters = {
                  ...newFilters,
                  [NewsFiltersEntityFrontKey.parcel]: {
                    ...newFilters[NewsFiltersEntityFrontKey.parcel],
                    geometry: {
                      ...newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry,
                      geometry: {
                        ...newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry,
                        intersects: [
                          ...intersectsBuffer,
                          filters[entityKey][typeFilterKey]?.[index]
                            .appliedValue as GeometricFilter,
                        ],
                      } as GeometryFilterBackend,
                    },
                  },
                };
              }
            }
          }
        }

        if (
          includeViewport &&
          typeFilterKey === NewsFiltersTypeFrontKey.geometric &&
          filters[entityKey][typeFilterKey]?.[NewsFiltersFrontKey.viewport]?.value
        ) {
          if (isListing) {
            let intersectsBuffer: GeometricFilter[] = [];
            if (
              (
                newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location as {
                  intersects: GeometricFilter[];
                }
              )?.intersects.length
            ) {
              intersectsBuffer = (
                newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location as {
                  intersects: GeometricFilter[];
                }
              )?.intersects;
            }
            newFilters = {
              ...newFilters,
              [NewsFiltersEntityFrontKey.listing]: {
                ...newFilters[NewsFiltersEntityFrontKey.listing],
                geometry: {
                  ...newFilters[NewsFiltersEntityFrontKey.listing]?.geometry,
                  location: {
                    ...newFilters[NewsFiltersEntityFrontKey.listing]?.geometry?.location,
                    intersects: [
                      ...intersectsBuffer,
                      filters[entityKey][typeFilterKey]?.[NewsFiltersFrontKey.viewport]
                        .value as GeometricFilter,
                    ],
                  } as GeometryFilterBackend,
                },
              },
            };
          } else {
            let intersectsBuffer: GeometricFilter[] = [];
            if (
              (
                newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry as {
                  intersects: GeometricFilter[];
                }
              )?.intersects.length
            ) {
              intersectsBuffer = (
                newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry as {
                  intersects: GeometricFilter[];
                }
              )?.intersects;
            }

            newFilters = {
              ...newFilters,
              [NewsFiltersEntityFrontKey.parcel]: {
                ...newFilters[NewsFiltersEntityFrontKey.parcel],
                geometry: {
                  ...newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry,
                  geometry: {
                    ...newFilters[NewsFiltersEntityFrontKey.parcel]?.geometry?.geometry,
                    intersects: [
                      ...intersectsBuffer,
                      filters[entityKey][typeFilterKey]?.[NewsFiltersFrontKey.viewport]
                        .value as GeometricFilter,
                    ],
                  } as GeometryFilterBackend,
                },
              },
            };
          }
        }
      }
    }
  }

  // ---- Add Default Filters
  if (Object.keys(defaultFilters).length) {
    for (entityBackendKey in defaultFilters) {
      for (typeFilterBackendKey in defaultFilters[entityBackendKey]) {
        for (const index in defaultFilters[entityBackendKey]?.[typeFilterBackendKey]) {
          if (defaultFilters[entityBackendKey]?.[typeFilterBackendKey]?.[index]) {
            newFilters = {
              ...newFilters,
              [entityBackendKey]: {
                ...newFilters[entityBackendKey],
                [typeFilterBackendKey]: {
                  ...newFilters?.[entityBackendKey]?.[typeFilterBackendKey],
                  [index]: newFilters?.[entityBackendKey]?.[typeFilterBackendKey]?.[index]
                    ? newFilters?.[entityBackendKey]?.[typeFilterBackendKey]?.[index]
                    : defaultFilters[entityBackendKey]?.[typeFilterBackendKey]?.[index],
                },
              },
            };
          }
        }
      }
    }
  }

  // Add active or past listings (esto se usa en la table view. Si el filtro se pudiera aplicar desde el navbar hay que quitarlo)
  if (activeListing !== undefined) {
    // hay que dejar undefined porq el valor puede ser true o false
    newFilters = {
      ...newFilters,
      listing: {
        ...newFilters.listing,
        boolean: {
          ...newFilters.listing?.boolean,
          active: activeListing,
        },
      },
    };
  }

  // Muestra solo los listings que estan asociados a parcelas. (Se usa para los clusters y markers de listings)
  if (listingsWithParcel) {
    newFilters = {
      ...newFilters,
      listing: {
        ...newFilters.listing,
        search: {
          ...newFilters.listing?.search,
          parcelId: { isNotNull: listingsWithParcel },
        },
      },
    };
  }

  if (datasetFiltersListings) {
    newFilters = {
      ...newFilters,
      listing: {
        ...newFilters.listing,
        search: {
          ...newFilters.listing?.search,
          dataset: datasetFiltersListings,
        },
      },
    };
  }

  if (listingsIsOutlier !== undefined) {
    newFilters = {
      ...newFilters,
      listing: {
        ...newFilters.listing,
        boolean: {
          ...newFilters.listing?.boolean,
          [NewsFiltersFrontKey.isOutlier]: listingsIsOutlier,
        },
      },
    };
  }

  if (transactionsWithScriptures) {
    newFilters = {
      ...newFilters,
      transaction: {
        ...newFilters.transaction,
        search: {
          ...newFilters.transaction?.search,
          scriptures: { isNotNull: transactionsWithScriptures },
        },
      },
    };
  }

  if (datasetFiltersTransactions) {
    newFilters = {
      ...newFilters,
      transaction: {
        ...newFilters.transaction,
        search: {
          ...newFilters.transaction?.search,
          dataset: datasetFiltersTransactions,
        },
      },
    };
  }

  if (searchIds) {
    for (const searchEntityKey in searchIds) {
      const entityKey = (
        searchEntityKey === TabKey.documents
          ? NewsFiltersEntityFrontKey.transaction
          : searchEntityKey
      ) as keyof typeof NewsFiltersEntityFrontKey;
      const ids = searchIds[searchEntityKey as keyof typeof searchIds];

      if (entityKey !== NewsFiltersEntityFrontKey.location) {
        newFilters = {
          ...newFilters,
          [entityKey]: {
            ...newFilters[entityKey],
            search: {
              ...newFilters[entityKey]?.search,
              [entityKey === NewsFiltersEntityFrontKey.listing
                ? NewsFiltersFrontKey.genericListingId
                : "id"]: ids,
            },
          },
        };
      }
    }
  }

  // esto se aplica cuando estamos en un EP para listing y debemos quitar los filtros matcheados
  if (matchListingFilters) {
    if (newFilters?.parcel?.numeric?.[NewsFiltersFrontKey.landArea]) {
      delete newFilters.parcel.numeric[NewsFiltersFrontKey.landArea];
      if (Object.keys(newFilters.parcel.numeric).length === 0) {
        delete newFilters.parcel.numeric;
      }
      if (Object.keys(newFilters.parcel).length === 0) {
        delete newFilters.parcel;
      }
    }
    if (newFilters?.property?.numeric?.[NewsFiltersFrontKey.totalArea]) {
      delete newFilters.property.numeric[NewsFiltersFrontKey.totalArea];
      if (Object.keys(newFilters.property.numeric).length === 0) {
        delete newFilters.property.numeric;
      }
      if (Object.keys(newFilters.property).length === 0) {
        delete newFilters.property;
      }
    }
    if (newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.sectorId]) {
      delete newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.sectorId];
      if (Object.keys(newFilters?.parcel?.categoric).length === 0) {
        delete newFilters.parcel.categoric;
      }
      if (Object.keys(newFilters.parcel).length === 0) {
        delete newFilters.parcel;
      }
    }
    if (newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.subsectorId]) {
      delete newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.subsectorId];
      if (Object.keys(newFilters?.parcel?.categoric).length === 0) {
        delete newFilters.parcel.categoric;
      }
      if (Object.keys(newFilters.parcel).length === 0) {
        delete newFilters.parcel;
      }
    }
    if (newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.microsectorId]) {
      delete newFilters?.parcel?.categoric?.[NewsFiltersFrontKey.microsectorId];
      if (Object.keys(newFilters?.parcel?.categoric).length === 0) {
        delete newFilters.parcel.categoric;
      }
      if (Object.keys(newFilters.parcel).length === 0) {
        delete newFilters.parcel;
      }
    }
    if (newFilters?.transaction?.numeric?.[NewsFiltersFrontKey.transactionPrice]) {
      delete newFilters.transaction.numeric[NewsFiltersFrontKey.transactionPrice];
      if (Object.keys(newFilters.transaction.numeric).length === 0) {
        delete newFilters.transaction.numeric;
      }
      if (Object.keys(newFilters.transaction).length === 0) {
        delete newFilters.transaction;
      }
    }
    if (newFilters?.transaction?.numeric?.[NewsFiltersFrontKey.pricePerBuiltSqm]) {
      delete newFilters.transaction.numeric[NewsFiltersFrontKey.pricePerBuiltSqm];
      if (Object.keys(newFilters.transaction.numeric).length === 0) {
        delete newFilters.transaction.numeric;
      }
      if (Object.keys(newFilters.transaction).length === 0) {
        delete newFilters.transaction;
      }
    }
    if (newFilters?.transaction?.numeric?.[NewsFiltersFrontKey.pricePerLotSqm]) {
      delete newFilters.transaction.numeric[NewsFiltersFrontKey.pricePerLotSqm];
      if (Object.keys(newFilters.transaction.numeric).length === 0) {
        delete newFilters.transaction.numeric;
      }
      if (Object.keys(newFilters.transaction).length === 0) {
        delete newFilters.transaction;
      }
    }
  }

  // esto se aplica cuando estamos en un EP para parcel y debemos quitar los filtros matcheados
  if (matchParcelFilters) {
    if (newFilters?.listing?.numeric?.[NewsFiltersFrontKey.lotArea]) {
      delete newFilters.listing.numeric[NewsFiltersFrontKey.lotArea];
      if (Object.keys(newFilters.listing.numeric).length === 0) {
        delete newFilters.listing.numeric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
    if (newFilters?.listing?.numeric?.[NewsFiltersFrontKey.builtArea]) {
      delete newFilters.listing.numeric[NewsFiltersFrontKey.builtArea];
      if (Object.keys(newFilters.listing.numeric).length === 0) {
        delete newFilters.listing.numeric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
    if (newFilters?.listing?.categoric?.[NewsFiltersFrontKey.propertyType]) {
      delete newFilters?.listing?.categoric?.[NewsFiltersFrontKey.propertyType];
      if (Object.keys(newFilters?.listing?.categoric).length === 0) {
        delete newFilters.listing.categoric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
    // TODO -> SI LA APP ES PUBLICA FILTRAR POR LISTING price/pricePerBuiltSqm/pricePerLotSqm y eliminar aca los TRANSACTION transactionPrice/pricePerBuiltSqm/pricePerLotSqm
    if (newFilters?.listing?.numeric?.[NewsFiltersFrontKey.price]) {
      delete newFilters.listing.numeric[NewsFiltersFrontKey.price];
      if (Object.keys(newFilters.listing.numeric).length === 0) {
        delete newFilters.listing.numeric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
    if (newFilters?.listing?.numeric?.[NewsFiltersFrontKey.pricePerBuiltSqm]) {
      delete newFilters.listing.numeric[NewsFiltersFrontKey.pricePerBuiltSqm];
      if (Object.keys(newFilters.listing.numeric).length === 0) {
        delete newFilters.listing.numeric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
    if (newFilters?.listing?.numeric?.[NewsFiltersFrontKey.pricePerLotSqm]) {
      delete newFilters.listing.numeric[NewsFiltersFrontKey.pricePerLotSqm];
      if (Object.keys(newFilters.listing.numeric).length === 0) {
        delete newFilters.listing.numeric;
      }
      if (Object.keys(newFilters.listing).length === 0) {
        delete newFilters.listing;
      }
    }
  }

  const response: RequestFiltersBackend<BackendInclude, { isCount: typeof isCount }> = {
    filters: [newFilters],
  };

  if (pagination && !isCount) {
    (response as RequestFiltersBackend<BackendInclude, { isCount: typeof isCount }>).pagination = {
      page: pagination.currentPage - 1,
      itemsPerPage: pagination.perPage || 10,
      sortBy: sortBy ?? undefined,
      sortOrder:
        sortOrder === SortDirectionKey.asc
          ? SortDirectionBackendKey.ASC
          : sortOrder === SortDirectionKey.desc
          ? SortDirectionBackendKey.DESC
          : sortOrder ?? undefined,
    };
  }

  if (include && !isCount) {
    (response as RequestFiltersBackend<BackendInclude, { isCount: typeof isCount }>).include =
      include;
  }

  return requestFiltersOrAdapter({ requestFilters: response });
};
