// Función para combinar objetos profundamente (multiple counts)
export const deepMerge = (target: any, source: any): any => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

// Función para hacer una copia profunda de un objeto
export const deepClone = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj));
};
