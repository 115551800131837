import { ListingBackendKeys } from "./listing-backend.key";
import { ParcelBackendKeys } from "./parcel-backend.key";
import { TaxBackendKeys } from "./tax-backend.key";
import { TransactionBackendKeys } from "./transaction-backend.key";

export enum PropertyBackendKey {
  totalArea = "totalArea",
  parcelId = "parcelId",
  address = "address",
  addressJSON = "addressJSON",
  cityId = "cityId",
  govtId = "govtId",
  id = "id",
  toilets = "toilets",
  parkingSpaces = "parkingSpaces",
  rooms = "rooms",
  unitType = "unitType",
  geometry = "geometry",
}

export type PropertyBackendKeys = keyof typeof PropertyBackendKey;

export enum PropertyBackendSortKey {
  totalArea = "totalArea",
  // parcelId = "parcelId",
  // address = "address",
  // addressJSON = "addressJSON",
  // cityId = "cityId",
  // govtId = "govtId",
  // id = "id",
  // toilets = "toilets",
  // parkingSpaces = "parkingSpaces",
  // rooms = "rooms",
  unitType = "unitType",
  // geometry = "geometry",
}

export enum PropertyBackendExpandKey {
  parcel = "parcel",
  city = "city",
  transactions = "transactions",
  listings = "listings",
  taxes = "taxes",
}

export type PropertyBackendExpandKeys = keyof typeof PropertyBackendExpandKey;

export type PropertyBackendInclude =
  | (PropertyBackendKeys | "*")
  | (`${PropertyBackendExpandKeys}.${ParcelBackendKeys}` | `${PropertyBackendExpandKeys}.*`)
  | (`${PropertyBackendExpandKeys}.${TransactionBackendKeys}` | `${PropertyBackendExpandKeys}.*`)
  | (`${PropertyBackendExpandKeys}.${ListingBackendKeys}` | `${PropertyBackendExpandKeys}.*`)
  | (`${PropertyBackendExpandKeys}.${TaxBackendKeys}` | `${PropertyBackendExpandKeys}.*`);
