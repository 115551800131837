import { createGlobalStyle } from "styled-components";

export const baseStyles = {
  borderRadius: "0.3rem",
  body: { margin: "1.3rem" },
  checkbox: {
    sizes: {
      sm: "1rem",
      md: "1.5rem",
      lg: "2rem",
    },
    iconSizes: {
      sm: "0.8rem",
      md: "1.3rem",
      lg: "1.8rem",
    },
  },
};

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.defaultColors.background};
    color: ${({ theme }) => theme.defaultColors.color};
    font-family: "AvenirNext", sans-serif;
  }`;
