import styled from "styled-components";
import { baseStyles } from "@/components/styledComponents";
import { darken, lighten } from "polished";

export const ButtonPrimary = styled.button<{
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  borderRadius?: string;
  paddingY?: string;
  paddingX?: string;
}>(
  ({
    theme,
    width,
    height,
    minWidth,
    maxWidth,
    disabled,
    disableBorderRadius = false,
    borderRadius,
    paddingY,
    paddingX,
  }) => `
  background: ${theme.buttons.primary.color} !important;
  font-size: 14px;
  color: ${theme.buttons.primary.contrastColor};
  padding: ${paddingY ?? "4px"} ${paddingX ?? "10px"};
  ${
    !disableBorderRadius
      ? `border-radius: ${borderRadius ?? baseStyles.borderRadius} !important;`
      : ""
  }
  border: ${theme.buttons.primary.color} 1px solid;
  ${width ? `width: ${width};` : ""}
  height: ${height};
  min-width: ${minWidth ?? "auto"};
  max-width: ${maxWidth};
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background: ${lighten(0.1, theme.buttons.primary.color)} !important;
    border-color: ${lighten(0.1, theme.buttons.primary.color)} !important;
  }
  ${
    disabled &&
    `cursor: not-allowed !important;
    background: ${lighten(0.5, theme.buttons.primary.color)} !important;
    border-color: none;
    &:hover {
      background: ${lighten(0.5, theme.buttons.primary.color)} !important;
    }`
  }`,
);

export const ButtonPrimaryDiv = styled.div<{
  width?: string;
  minWidth?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  borderRadius?: string;
}>(
  ({ theme, width, minWidth, disabled, disableBorderRadius = false, borderRadius }) => `
  background: ${theme.buttons.primary.color};
  color: ${theme.buttons.primary.contrastColor};
  padding: 4px 10px;
  text-align: center;
  ${
    !disableBorderRadius
      ? `border-radius: ${borderRadius ?? baseStyles.borderRadius} !important;`
      : ""
  }
  border: ${theme.buttons.primary.color} 1px solid;
  ${width ? `width: ${width};` : ""}
  min-width: ${minWidth ?? "6rem"};
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background: ${lighten(0.1, theme.buttons.primary.color)};
    border-color: ${lighten(0.1, theme.buttons.primary.color)};
  }
  ${
    disabled &&
    `cursor: not-allowed !important;
    background: ${lighten(0.5, theme.buttons.primary.color)};
    &:hover {
      background: ${lighten(0.5, theme.buttons.primary.color)};
    }`
  }`,
);

export const ButtonBorderPrimary = styled.button<{
  width?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  paddingY?: string;
  paddingX?: string;
  lowerCase?: boolean;
}>(
  ({
    theme,
    width,
    disabled,
    disableBorderRadius = false,
    paddingY,
    paddingX,
    lowerCase = false,
  }) => `
  background: ${theme.defaultColors.background};
  font-size: 14px;
  border: ${theme.buttons.borderPrimary.color} 1px solid;
  color: ${theme.buttons.borderPrimary.color};
  padding: ${paddingY ?? "4px"} ${paddingX ?? "10px"};
  text-transform: ${lowerCase ? "none" : "uppercase"};
  text-wrap: pretty;
  ${!disableBorderRadius ? `border-radius: ${baseStyles.borderRadius} !important;` : ""}
  width: ${width};
  font-weight: 600;
  &:hover {
    background: ${darken(0.1, theme.defaultColors.background)}
  }
  ${
    disabled &&
    `border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
     color: ${lighten(0.5, theme.buttons.borderPrimary.color)};
    &:hover {
      border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
      background: ${theme.defaultColors.background};
    }`
  }`,
);

export const ButtonBorderSecondary = styled.button<{
  width?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
}>(
  ({ theme, width, disabled, disableBorderRadius = false }) => `
  background: ${theme.defaultColors.background};
  border: ${theme.buttons.borderSecondary.color} 1px solid;
  color: ${theme.buttons.borderSecondary.color};
  padding: 4px 10px;
  text-transform: uppercase;
  ${!disableBorderRadius ? `border-radius: ${baseStyles.borderRadius} !important;` : ""}
  width: ${width};
  font-weight: 600;
  &:hover {
    background: ${lighten(0.5, theme.buttons.borderSecondary.color)}
  }
  ${
    disabled &&
    `border: ${lighten(0.5, theme.buttons.borderSecondary.color)} 1px solid;
     color: ${lighten(0.5, theme.buttons.borderSecondary.color)};
    &:hover {
      border: ${lighten(0.5, theme.buttons.borderSecondary.color)} 1px solid;
      background: ${theme.defaultColors.background};
    }`
  }`,
);

export const ButtonItem = styled.button(
  ({ theme }) => `
  padding: 0.25rem 1.5rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    display: block;
    width: 100%;
    font-weight: 400;
    color: ${theme.defaultColors.color};
    white-space: nowrap;
    &:hover,
    &.active {
      background: ${darken(0.1, theme.defaultColors.background)}
    }
  `,
);

export const ButtonBorderBottom = styled.button<{
  active: boolean;
}>(
  ({ theme, active }) => `
  padding: 0.25rem 0.8rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    display: block;
    width: auto;
    font-weight: 400;
    color: ${theme.defaultColors.color};
    white-space: nowrap;
    position: relative;
    ${
      active &&
      `
      font-weight: bold;
        &:after {
          content: attr(data-before);
          height: 3px;
          border-radius: 0.5rem;
          width: 80%;
          left: 0;
          right: 0;
          margin: auto;
          position: absolute;
          bottom: -1px;
          background: ${theme.secondary.color};
          transform: translateY(0px);
          transition: all 0.2s linear;
        }`
    };
    &:hover,
    &.active {
      font-weight: bold;
      position: relative;
      &:after {
        content: attr(data-before);
        height: 3px;
        border-radius: 0.5rem;
        width: 80%;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        bottom: -1px;
        background: ${theme.secondary.color};
        transform: translateY(0px);
        transition: all 0.2s linear;
      }
    }
  `,
);

export const ButtonLink = styled.span<{
  notClickeable?: boolean;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  uppercase?: boolean;
  underline?: boolean;
}>(
  ({ theme, notClickeable, fontSize, color, bold, uppercase, underline }) => `
    color: ${color ?? theme.defaultColors.color};
    line-height: 14px;
    font-size: ${fontSize ? fontSize : "12"}px;
    font-weight: ${bold ? 600 : "inherit"};
    cursor: ${notClickeable ? "" : "pointer"};
    text-transform: ${uppercase ? "uppercase" : "none"};
    text-decoration: ${underline ? "underline" : ""};
    ${
      !notClickeable &&
      `
    &:hover,
    &.active {
      color: ${lighten(0.5, theme.defaultColors.color)}
    }
    `
    } 
   
  `,
);

export const ButtonLinkExternal = styled.a<{ fontSize?: string; color?: string }>(
  ({ theme, fontSize, color }) => `
    color: ${color ?? theme.defaultColors.color};
    line-height: 14px;
    font-size: ${fontSize ? fontSize : "12px"};
    cursor: pointer;
    &:hover,
    &.active {
      color: ${lighten(0.5, theme.defaultColors.color)}
    }
  `,
);

export const ButtonXCirclePrimary = styled.div<{
  size?: string;
  right?: string;
  position?: string;
  margin?: string;
  top?: string;
}>(
  ({ theme, size, right, position, margin, top }) => `
    color: ${lighten(0.2, theme.primary.color)};
    font-size: ${size ? size : "16px"};
    position: ${position ?? "absolute"};
    margin: ${margin ?? "5px"};
    right: ${right ? right : "0"};
    top: ${top ? top : "auto"};
    cursor: pointer;
    &:hover,
    &.active {
      color: ${theme.primary.color}
    }
  `,
);

export const ButtonXCirclePrimaryContrast = styled.div<{ size?: string }>(
  ({ theme, size }) => `
    color: ${theme.primary.contrastColor};
    font-size: ${size ?? "16px"};
    position: absolute;
    margin: 5px;
    right: 0;
    cursor: pointer;
    &:hover,
    &.active {
      color: ${darken(0.2, theme.primary.contrastColor)}
    }
  `,
);

export const ButtonXCircleLightGrayContrast = styled.div<{ size?: string }>(
  ({ theme, size }) => `
    color: ${theme.common.lightGray};
    font-size: ${size ?? "16px"};
    position: absolute;
    margin: 5px;
    right: 0;
    cursor: pointer;
    &:hover,
    &.active {
      color: ${lighten(0.2, theme.primary.color)}
    }
  `,
);

export const ButtonMap = styled.div<{
  backgroundColor: string;
  color: string;
  disabled?: boolean;
  width?: string;
}>(
  ({ backgroundColor, color, disabled, width }) => `
  border-bottom-left-radius: ${baseStyles.borderRadius};
  border-bottom-right-radius: ${baseStyles.borderRadius};
  background-color: ${backgroundColor};
  color: ${color};
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  width: ${width ?? "100%"};
  position: absolute;
  z-index: 1;
  bottom: 0;
  cursor: pointer;
  &:hover {
    background: ${lighten(0.1, backgroundColor)};
  }${
    disabled &&
    `background: ${lighten(0.5, backgroundColor)};
    &:hover {
      background: ${lighten(0.5, backgroundColor)};
    }`
  }`,
);

export const ButtonPlainPrimary = styled.button<{
  width?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  paddingY?: string;
  paddingX?: string;
  border?: string;
}>(
  ({ theme, width, disabled, disableBorderRadius = false, paddingY, paddingX, border }) => `
  background: ${theme.defaultColors.background};
  fill: ${theme.buttons.primary.color};
  font-size: 14px;
  border: none;
  color: ${theme.buttons.borderPrimary.color};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  padding: ${paddingY ?? "12px"} ${paddingX ?? "10px"};
  text-transform: uppercase;
  ${!disableBorderRadius ? `border-radius: ${baseStyles.borderRadius} !important;` : ""}
  width: ${width};
  font-weight: 600;
  &:hover {
    background: ${darken(0.1, theme.defaultColors.background)}
  }
  ${
    disabled &&
    `border: ${border ?? `${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid`};
     color: ${lighten(0.5, theme.buttons.borderPrimary.color)};
    &:hover {
      border: ${border ?? `${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid`};
      background: ${theme.defaultColors.background};
    }`
  }`,
);

export const ButtonGray = styled.button<{
  width?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  paddingY?: string;
  paddingX?: string;
}>(
  ({ theme, width, disabled, disableBorderRadius = false, paddingY, paddingX }) => `
  background: ${darken(0.1, theme.defaultColors.background)};
  fill: ${theme.buttons.primary.color};
  font-size: 14px;
  border: none;
  color: ${theme.buttons.borderPrimary.color};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  padding: ${paddingY ?? "12px"} ${paddingX ?? "10px"};
  text-transform: uppercase;
  ${!disableBorderRadius ? `border-radius: ${baseStyles.borderRadius} !important;` : ""}
  width: ${width};
  font-weight: 600;
  &:hover {
    background: ${darken(0.2, theme.defaultColors.background)}
  }
  ${
    disabled &&
    `border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
     color: ${lighten(0.5, theme.buttons.borderPrimary.color)};
    &:hover {
      border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
      background: ${theme.defaultColors.background};
    }`
  }`,
);

export const IconButton = styled.button<{
  width?: string;
  disabled?: boolean;
  disableBorderRadius?: boolean;
  paddingY?: string;
  paddingX?: string;
}>(
  ({ theme, width, disabled, disableBorderRadius = false, paddingY, paddingX }) => `
  background: ${theme.defaultColors.background};
  fill: ${theme.buttons.primary.color};
  font-size: 14px;
  border: none;
  color: ${theme.buttons.borderPrimary.color};
  padding: ${paddingY ?? "12px"} ${paddingX ?? "10px"};
  text-transform: uppercase;
  ${!disableBorderRadius ? `border-radius: ${baseStyles.borderRadius} !important;` : ""}
  width: ${width};
  font-weight: 600;
  &:hover {
    background: ${darken(0.04, theme.defaultColors.background)}
  }
  ${
    disabled &&
    `border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
     color: ${lighten(0.5, theme.buttons.borderPrimary.color)};
    &:hover {
      border: ${lighten(0.5, theme.buttons.borderPrimary.color)} 1px solid;
      background: ${theme.defaultColors.background};
    }`
  }`,
);
