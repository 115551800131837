import axios, { AxiosResponse } from "axios";
import { URL_BACKEND } from "@/config";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ListingAmenityBackendKeys,
  ListingBackendKeys,
  NewsFiltersEntityBackendKey,
  NewsFiltersEntityFrontKey,
  ParcelBackendKeys,
  PermitBackendKeys,
  PropertyBackendKeys,
  TenantBackendKeys,
  TransactionBackendKeys,
  useEndpoint,
} from "@/keys";
import { AppThunk } from "@/app/store";

import {
  CategoricFiltersBackend,
  FiltersTypeBackend,
  responseCategoricsFiltersAdapter,
  responseFiltersAdapter,
} from "@/adapters";
import { CategoricFilters, FiltersType } from "@/models";
import { setCategoricFiltersAllPortals, setEntityFilters } from "@/redux/slices";

export const getListingsFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().listingsFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const listingsFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({ filters: listingsFilters, entity: NewsFiltersEntityFrontKey.listing }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getParcelsFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().parcelsFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const parcelsFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({ filters: parcelsFilters, entity: NewsFiltersEntityFrontKey.parcel }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTransactionsFilters = ({
  countryCode,
}: {
  countryCode: CountryCodeKey;
}): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().transactionsFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const transactionsFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: transactionsFilters,
          entity: NewsFiltersEntityFrontKey.transaction,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPropertyFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().propertiesFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const propertiesFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: propertiesFilters,
          entity: NewsFiltersEntityFrontKey.property,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTenantFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().tenantsFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const tenantsFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: tenantsFilters,
          entity: NewsFiltersEntityFrontKey.tenant,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTaxFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().taxesFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const taxesFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: taxesFilters,
          entity: NewsFiltersEntityFrontKey.tax,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPermitFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().permitsFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const permitsFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: permitsFilters,
          entity: NewsFiltersEntityFrontKey.permit,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getListingAmenityFilters = ({
  countryCode,
}: {
  countryCode: CountryCodeKey;
}): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: FiltersTypeBackend }, { data: FiltersType }> =
        await axios.get(
          `${URL_BACKEND}${useEndpoint().amenitiesFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
        );
      const amenitiesFilters = responseFiltersAdapter(response.data.data);
      dispatch(
        setEntityFilters({
          filters: amenitiesFilters,
          entity: NewsFiltersEntityFrontKey.listingAmenity,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export type IncludeCategoricFilters = {
  [NewsFiltersEntityBackendKey.parcel]?: ParcelBackendKeys[];
  [NewsFiltersEntityBackendKey.listing]?: ListingBackendKeys[];
  [NewsFiltersEntityBackendKey.listingAmenity]?: ListingAmenityBackendKeys[];
  [NewsFiltersEntityBackendKey.property]?: PropertyBackendKeys[];
  [NewsFiltersEntityBackendKey.transaction]?: TransactionBackendKeys[];
  [NewsFiltersEntityBackendKey.tenant]?: TenantBackendKeys[];
  [NewsFiltersEntityBackendKey.permit]?: PermitBackendKeys[];
};

export const getCategoricFilters = ({ countryCode }: { countryCode: CountryCodeKey }): AppThunk => {
  return async (dispatch) => {
    const include: IncludeCategoricFilters = {
      listing: ["propertyType", "apartmentType", "status"],
      parcel: ["sectorId", "subsectorId", "microsectorId"],
      listingAmenity: ["amenityId"],
      permit: ["sectorId", "subsectorId", "category", "status"],
      property: ["unitType"],
      transaction: ["sectorId", "subsectorId", "microsectorId"],
    };
    try {
      const response: AxiosResponse<{ data: CategoricFiltersBackend }, { data: CategoricFilters }> =
        await axios.post(
          `${URL_BACKEND}${useEndpoint().categoricFilters({
            countryCode: CountryCodeBackendKey[countryCode],
          })}`,
          {
            include,
          },
        );

      const categoricFilters = responseCategoricsFiltersAdapter(response.data.data);

      dispatch(
        setCategoricFiltersAllPortals({
          categorics: categoricFilters,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
};
