import type { SubscriptionCardBackend } from "@/adapters";
import { SubscriptionCard } from "@/models";

export const responseSubscriptionCardAdapter = (
  card: SubscriptionCardBackend,
): SubscriptionCard => ({
  brand: card.brand,
  email: card.email,
  expMonth: card.expMonth,
  expYear: card.expYear,
  last4: card.last4,
});
