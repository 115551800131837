import { SimilarListingBackend } from "@/adapters";

export const responseSimilarListingsAdapter = (
  listings: SimilarListingBackend[] | null,
): string[] => {
  const newListings: string[] = [];

  if (listings && listings.length) {
    listings.map((listing) => {
      newListings.push(listing.genericListingId);
    });
  }

  return newListings;
};
