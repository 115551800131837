import { store } from "@/app/store";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { AreaChartOptions } from "@/models";
import { AnalyticsBackend } from "../models/analytics/analytics.backend.model";

export const responseAnalyticsAreaChartAdapter = (data: AnalyticsBackend[]): AreaChartOptions => {
  const dataChart: AreaChartOptions = {
    series: [
      {
        data: [],
      },
    ],
    colors: [],
  };

  const { themeName, organizationTheme } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);

  if (data.length) {
    data.map((x) => {
      if (x.value)
        dataChart.series[0].data.push({
          x: x.key,
          y: Math.round(Number(x.value)).toString(),
        });
    });
    dataChart.colors.push(activeTheme?.secondary.color as ThemeType["secondary"]["color"]);
  }

  return dataChart;
};
