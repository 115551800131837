import { AppDispatch, AppThunk } from "@/app/store";
import { URL_BACKEND } from "@/config";
import { useEndpoint } from "@/keys";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { api } from "@/services";
import {
  CouponBackend,
  CouponStripeBackend,
  responseCouponAdapter,
  responseSubscriptionCardAdapter,
  responseSubscriptionPaymentHistoryAdapter,
  responseSubscriptionPlansAdapter,
  SubscriptionCardBackend,
  SubscriptionPaymentHistoryBackend,
  SubscriptionPlanBackend,
} from "@/adapters";
import { getUserIsVerified, loadUser } from "@/redux/actions";
import { appPaths, getOrganization } from "@/configs";
import { Coupon, CouponStripe, SubscriptionCard, SubscriptionPaymentHistory } from "@/models";
import { PaymentMethod } from "@stripe/stripe-js";
import { history } from "@/history";
import {
  setBillingIsLoading,
  setSubscriptionCard,
  setSubscriptionIsLoading,
  setSubscriptionPlans,
  setSuccess,
  setUserPaymentHistory,
} from "@/redux/slices";

export const createSubscriptionStripe = ({
  email,
  paymentMethod,
  planId,
  coupon,
  priceId,
  productId,
}: {
  email: string;
  paymentMethod: PaymentMethod;
  planId: string;
  coupon: string;
  priceId: string;
  productId: string;
}): AppThunk => {
  return async (dispatch) => {
    try {
      const response: AxiosResponse<{ data: { status: string } }> = await axios.post(
        `${URL_BACKEND}${useEndpoint().subscriptionStripe()}`,
        {
          paymentMethodId: paymentMethod.id,
          email,
          planId,
          coupon: coupon ?? null,
          priceId,
          productId,
        },
      );
      if (response.data.data) {
        if (response.data.data.status === "requires_action") {
          toast.error("Payment has failed, please try another credit card", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          dispatch(setSubscriptionIsLoading({ isLoading: false }));
        } else {
          dispatch(setPaymentSuccess(true));
          dispatch(getUserIsVerified());

          toast.success(`Successful payment. Welcome to ${getOrganization().organization.name}!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(setSubscriptionIsLoading({ isLoading: false }));
          history.push(appPaths.success.path);
        }
      }
    } catch (error) {
      dispatch(setSubscriptionIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response);
          // if (error?.response?.status === 422) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error("There was an error in the payment, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const updateSubscriptionStripe = (paymentMethod: PaymentMethod): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${URL_BACKEND}${useEndpoint().subscription()}`, {
        paymentMethod: paymentMethod,
      });

      if (response.data.data) {
        if (response.data.data.status === "requires_action") {
          toast.error("Payment has failed, please try another credit card", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(setBillingIsLoading({ isLoading: false }));
        } else {
          toast.success("Payment updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getSubscriptionCard());
        }
      }
    } catch (error) {
      dispatch(setBillingIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // if (error?.response?.status === 422) {
          if (error.response.data) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              return toast.error(error.response.data.errors[i], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else {
            toast.error("There was an error in the payment, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const getSubscriptionPlans = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubscriptionIsLoading({ isLoading: true }));

      const response: AxiosResponse<{ data: SubscriptionPlanBackend[] }> = await axios.get(
        `${URL_BACKEND}${useEndpoint().subscriptionPlans()}`,
      );

      dispatch(
        setSubscriptionPlans({ plans: responseSubscriptionPlansAdapter(response.data.data) }),
      );
    } catch (error) {
      dispatch(setSubscriptionIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error?.response?.status === 422) {
            if (error.response.data) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                return toast.error(error.response.data.errors[i], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }
          } else {
            toast.error("There was an error in the payment, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const getSubscriptionCard = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setBillingIsLoading({ isLoading: true }));

      const response: AxiosResponse<{ data: SubscriptionCardBackend }, { data: SubscriptionCard }> =
        await axios.get(`${URL_BACKEND}${useEndpoint().subscriptionCard()}`);

      const card = responseSubscriptionCardAdapter(response.data.data);

      dispatch(setSubscriptionCard({ card }));
    } catch (error) {
      dispatch(setBillingIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error?.response?.status === 422) {
            if (error.response.data) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                return toast.error(error.response.data.errors[i], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }
          } else {
            // toast.error(
            //   "There was an error in the payment, please try again later",
            //   {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   }
            // );
            console.log(error.response);
          }
        }
      }
    }
  };
};

export const setPaymentSuccess = (value?: boolean) => {
  return async (dispatch: any) => {
    dispatch(setSuccess({ success: value }));
  };
};

export const cancelSubscriptionPlan = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setBillingIsLoading({ isLoading: true }));

      await axios.delete(`${URL_BACKEND}${useEndpoint().subscription()}`);

      dispatch(setBillingIsLoading({ isLoading: false }));

      toast.success("Your subscription was successfully canceled", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch(loadUser());
    } catch (error) {
      dispatch(setBillingIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error?.response?.status === 422) {
            if (error.response.data) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                return toast.error(error.response.data.errors[i], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }
          } else {
            toast.error("There was an error, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const activeSubscriptionPlan = (email: string, planId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setBillingIsLoading({ isLoading: true }));

      await axios.post(`${URL_BACKEND}${useEndpoint().subscriptionActive()}`, {
        email: email,
        planId: planId,
      });
      toast.success("Your subscription was activated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch(setBillingIsLoading({ isLoading: false }));
      dispatch(getUserIsVerified());
    } catch (error) {
      dispatch(setBillingIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error?.response?.status === 422) {
            if (error.response.data) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                return toast.error(error.response.data.errors[i], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }
          } else {
            toast.error("There was an error, please try again later", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    }
  };
};

export const getUserPaymentHistory = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setBillingIsLoading({ isLoading: true }));

      const response: AxiosResponse<
        { data: SubscriptionPaymentHistoryBackend[] },
        { data: SubscriptionPaymentHistory[] }
      > = await axios.get(`${URL_BACKEND}${useEndpoint().paymentHistory()}`);

      const paymentHistory = responseSubscriptionPaymentHistoryAdapter(response.data.data);

      dispatch(setUserPaymentHistory({ history: paymentHistory }));
    } catch (error) {
      dispatch(setBillingIsLoading({ isLoading: false }));
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast.error(
            "An error occurred while trying to fetch the payment history. Please try again in a few seconds",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
          );
        }
      }
    }
  };
};

interface CouponBody {
  couponName: string;
  productId: string;
}

const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCoupon: builder.query<CouponStripe | Coupon, { bodyProps: CouponBody }>({
      query: ({ bodyProps }) => {
        return {
          url: useEndpoint().subscriptionCoupon(),
          method: "post",
          body: bodyProps,
        };
      },
      transformResponse: (response: {
        data: CouponStripeBackend | CouponBackend;
      }): CouponStripe | Coupon => responseCouponAdapter(response.data),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetCouponQuery } = subscriptionApi;
