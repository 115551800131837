import React, { useEffect } from "react";
import Select from "react-select";
import { StyleReactSelect, customTheme } from "@/components/reactSelect/StyleReactSelect";
import { useTranslation } from "react-i18next";
import { LanguageCodeKey, LanguageCountryCodeKey } from "@/keys";
import { useLocaleCountryCode } from "@/hooks";

function UserLanguageInvestpr({ handleLanguage, userLanguage, setValueLanguage }) {
  const { t } = useTranslation("translation");
  const translations = t("language", { returnObjects: true });

  const { languageCode } = useLocaleCountryCode();

  const languages = [
    {
      languageCode: LanguageCodeKey.en,
      countryCode: LanguageCountryCodeKey.pr,
      name: translations.english,
    },
    {
      languageCode: LanguageCodeKey.es,
      countryCode: LanguageCountryCodeKey.pr,
      name: translations.spanish,
    },
  ];

  const findLanguage = () =>
    languages.find((lang) => {
      return lang.languageCode === languageCode;
    });

  useEffect(() => {
    const language = findLanguage();
    setValueLanguage(language.name);
  }, [userLanguage]);

  const valueLanguage = () => {
    if (userLanguage) {
      const language = findLanguage();
      return {
        value: language.languageCode,
        label: language.name,
        languageCode: language.languageCode,
        countryCode: language.countryCode,
      };
    }
  };

  const optionsMapLanguage = (value) => {
    return value.map((option) => {
      const { name, languageCode, countryCode } = option;
      return { value: languageCode, label: name, languageCode, countryCode };
    });
  };

  // options Select
  const optionsMap = (value) => {
    return value.map((option) => {
      const { languageCode, name } = option;
      return { value: languageCode, label: name };
    });
  };

  // -------------- Selects changes
  const onSelectChange = (value, name) => {
    const findLanguage = languages.find((lang) => lang.languageCode === value.value);
    if (name.name === "language") {
      if (findLanguage) {
        handleLanguage(findLanguage.languageCode, findLanguage.countryCode);
      }
    }
  };

  return (
    <div className="p-1">
      <div style={{ width: "200px" }}>
        <div className="fw-bold black mr-1 mb-05">{translations.languageLabel}</div>

        <Select
          name="language"
          placeholder="Language"
          options={optionsMapLanguage(languages)}
          onChange={onSelectChange}
          value={valueLanguage()}
          styles={StyleReactSelect}
          theme={customTheme}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          menuPosition="fixed"
          closeMenuOnSelect={true}
          classNamePrefix="select"
          isClearable={false}
          className="w-100"
          style={{ width: "155px" }}
          isSearchable={false}
        />
      </div>
    </div>
  );
}

export default UserLanguageInvestpr;
