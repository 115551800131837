import styled from "styled-components";

export const BackgroundImage = styled.div<{
  backgroundImage: string;
  borderTopRightRadius?: string;
  borderTopLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomLeftRadius?: string;
  height?: string;
  width?: string;
}>(
  ({
    backgroundImage,
    borderTopRightRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    height,
    width,
  }) => `
  width: ${width ?? "100%"};
  height: ${height ?? "100%"};
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${backgroundImage});
  border-top-right-radius: ${borderTopRightRadius ?? 0};
  border-top-left-radius: ${borderTopLeftRadius ?? 0};
  border-bottom-right-radius: ${borderBottomRightRadius ?? 0};
  border-bottom-left-radius: ${borderBottomLeftRadius ?? 0};
`,
);

export const Image = styled.img<{
  borderTopRightRadius?: string;
  borderTopLeftRadius?: string;
  borderBottomRightRadius?: string;
  borderBottomLeftRadius?: string;
  height?: string;
  width?: string;
  cursorPointer?: boolean;
}>(
  ({
    borderTopRightRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    height,
    width,
    cursorPointer,
  }) => `
  width: ${width ?? "100%"};
  height: ${height ?? "100%"};
  border-top-right-radius: ${borderTopRightRadius ?? 0};
  border-top-left-radius: ${borderTopLeftRadius ?? 0};
  border-bottom-right-radius: ${borderBottomRightRadius ?? 0};
  border-bottom-left-radius: ${borderBottomLeftRadius ?? 0};
  ${
    cursorPointer &&
    `cursor: pointer;
  &:hover {
    filter:brightness(0.8);
  }`
  }`,
);
