import styled from "styled-components";
import { baseStyles } from "@/components/styledComponents";

export const Card = styled.div<{ clickable?: boolean }>(({ clickable, ...props }) => {
  return {
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    background: props.theme.defaultColors.background,
    borderRadius: `${baseStyles.borderRadius} !important`,
    "&:hover": {
      background: clickable ? "#efefef" : props.theme.defaultColors.background,
      cursor: clickable ? "pointer" : "inherit",
      boxShadow: clickable
        ? "0px 4px 25px 0px rgba(0, 0, 0, 0.3)"
        : "0px 4px 25px 0px rgba(0, 0, 0, 0.1)",
    },
  };
});
