import type { ListingAmenityBackend } from "@/adapters";
import { ListingAmenity } from "@/models";

export const responseListingAmenitiesAdapter = (
  amenities: ListingAmenityBackend[],
): ListingAmenity[] => {
  const data: ListingAmenity[] = [];

  if (amenities.length) {
    amenities.map((amenity) =>
      data.push({
        name: amenity.amenity.name,
        id: amenity.amenity.id,
        esName: amenity.amenity.esName,
        amenityType: amenity.amenity.amenityType,
      }),
    );
  }
  return data;
};
