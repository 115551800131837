import { useAppSelector } from "@/app/hooks";
import { selectGridView } from "@/redux/slices";
import { OverlayView } from "@react-google-maps/api";
import React from "react";
import styled from "styled-components";

const ClusterStyle = styled.div<{
  widthHeight: (countRecords: number) => string;
  margins: (countRecords: number) => string;
  countRecords: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.widthHeight(props.countRecords)};
  padding: 4px 10px;
  margin-left: ${(props) => props.margins(props.countRecords)};
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.common.white};
  border: ${(props) => `1px solid ${props.theme.common.white}`};
  border-radius: 1rem;
  background-color: ${(props) => props.theme.primary.color};
  cursor: pointer;
  font-weight: bold;
`;

const widthHeight = (countRecords: number) => {
  switch (countRecords.toString().length) {
    case 1 || 2:
      return "36px";
    case 3:
      return "46px";
    case 4:
      return "56px";
    case 5:
      return "66px";
    case 6:
      return "76px";
    case 7:
      return "86px";
    default:
      return "36px";
  }
};

const margins = (countRecords: number) => {
  switch (widthHeight(countRecords)) {
    case "36px":
      return "-18px";
    case "46px":
      return "-23px";
    case "56px":
      return "-28px";
    case "66px":
      return "-33px";
    case "76px":
      return "-38px";
    case "86px":
      return "-43px";
    default:
      return "-18px";
  }
};

type ClusterProps = {
  countRecords: number;
  countRecordsAbbreviated?: number | string;
  lat: number;
  lng: number;
  isDragMapRef: React.MutableRefObject<boolean>;
  map: google.maps.Map | null;
};

const Cluster = ({
  countRecords,
  countRecordsAbbreviated,
  lat,
  lng,
  isDragMapRef,
  map,
}: ClusterProps) => {
  const { viewActive } = useAppSelector(selectGridView);

  const onClusterClick = () => {
    if (isDragMapRef.current) return;
    if (map) {
      const center = { lat, lng };
      map.setCenter(center);
      const zoom = map.getZoom();
      if (zoom) {
        map.setZoom(zoom + (countRecords >= 100 ? 3 : 2));
      }
      if (viewActive === "grid") {
        const bounds = map.getBounds();
        if (bounds) {
          const centerRight = {
            lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
            lng:
              (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2 +
              (bounds.getNorthEast().lng() - bounds.getSouthWest().lng()) / 5,
          };
          map.setCenter(centerRight);
        }
      }
    }
  };

  return (
    <OverlayView
      position={{ lat, lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      onUnmount={(overlayView) => overlayView.setMap(null)}>
      <ClusterStyle
        countRecords={countRecords}
        widthHeight={widthHeight}
        margins={margins}
        onClick={() => onClusterClick()}>
        {`+${countRecordsAbbreviated ?? countRecords}`}
      </ClusterStyle>
    </OverlayView>
  );
};

export default Cluster;
