import type { ComparableTransaction } from "@/models";
import { TransactionBackend, responseTransactionAdapter } from "@/adapters";

export const responseComparableTransactionsAdapter = (
  transactions: ComparableTransactionBackend[],
) => {
  const newTransactions: ComparableTransaction[] = [];
  if (transactions.length) {
    transactions.map((transaction) => {
      newTransactions.push({
        ...responseTransactionAdapter(transaction),
        distanceInKm: transaction.distanceInKm,
      });
    });
  }

  return newTransactions;
};

export type ComparableTransactionBackend = TransactionBackend & {
  distanceInKm: number;
};
