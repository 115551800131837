import React, { useEffect, useRef, useState, memo, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AvailableFiltersByPortal, getOrganization } from "@/configs/organizationsConfig";
import {
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  PortalKey,
} from "@/keys";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  OnlyKeyEntityFilter,
  selectAppConfig,
  selectFilters,
  setValueFilter,
} from "@/redux/slices";
import FiltersLocation from "./sectionsFilters/location/FiltersLocation";
import FiltersListings from "./sectionsFilters/listings/FiltersListings";
import { sizeViewport } from "@/components/styledComponents";
import PermitSection from "./sectionsFilters/properties/sections/PermitSection";
import { thousandSeparatorWhithDecimal } from "@/utilities";
import PropertySection from "./sectionsFilters/properties/sections/PropertySection";
import TansactionsSection from "./sectionsFilters/properties/sections/TansactionsSection";
import UnitSection from "./sectionsFilters/properties/sections/UnitSection";
import TaxSection from "./sectionsFilters/properties/sections/TaxSection";
import TenantSection from "./sectionsFilters/properties/sections/TenantSection";
import classNames from "classnames";
import MobileFiltersContainer from "./MobileFiltersContainer";
import DesktopFiltersContainer from "./DesktopFiltersContainer";
import { FiltersType } from "@/models";
import { debounce } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { userIsLoggedIn } from "@/redux/actions";
import advancedFiltersImg from "@/assets/img/filters/AdvancedFilters.png";
import { FakeTableContainer } from "@/views/pages/tableView/tables/fakeData";

type FiltersProps = {
  applyFilters: () => void;
  removeFilters: () => void;
  portal: PortalKey;
  clickBlocked: () => void;
  openFilters: boolean;
  setOpenFilters: (value: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
};

export type AdvancedFilters = {
  location: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.cityId]: boolean;
      [NewsFiltersFrontKey.neighborhoodId]: boolean;
      [NewsFiltersFrontKey.subNeighborhoodId]: boolean;
      [NewsFiltersFrontKey.zipCodeId]: boolean;
      [NewsFiltersFrontKey.buildingId]: boolean;
    };
  };
  parcel: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
      [NewsFiltersFrontKey.landArea]: boolean;
    };
  };
  listing: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.isForRent]: boolean;
      [NewsFiltersFrontKey.active]: boolean;
      [NewsFiltersFrontKey.propertyType]: boolean;
      [NewsFiltersFrontKey.price]: boolean;
      [NewsFiltersFrontKey.pricePerBuiltSqm]: boolean;
      [NewsFiltersFrontKey.pricePerLotSqm]: boolean;
      [NewsFiltersFrontKey.builtArea]: boolean;
      [NewsFiltersFrontKey.rooms]: boolean;
      [NewsFiltersFrontKey.toilets]: boolean;
      [NewsFiltersFrontKey.parkingSpaces]: boolean;
      [NewsFiltersFrontKey.daysOnMarket]: boolean;
      [NewsFiltersFrontKey.createdAt]: boolean;
      [NewsFiltersFrontKey.luxuryScore]: boolean;
      [NewsFiltersFrontKey.yearBuilt]: boolean;
      [NewsFiltersFrontKey.amenityId]: boolean;
      [NewsFiltersFrontKey.amenity]: boolean;
      [NewsFiltersFrontKey.amenityRoom]: boolean;
      [NewsFiltersFrontKey.amenityView]: boolean;
      [NewsFiltersFrontKey.isFloodZone]: boolean;
    };
  };
  transaction: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
      [NewsFiltersFrontKey.transactionDate]: boolean;
    };
  };
  property: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.buildingDate]: boolean;
      [NewsFiltersFrontKey.unitType]: boolean;
      [NewsFiltersFrontKey.rooms]: boolean;
      [NewsFiltersFrontKey.toilets]: boolean;
      [NewsFiltersFrontKey.parkingSpaces]: boolean;
    };
  };
  permit: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.category]: boolean;
      [NewsFiltersFrontKey.status]: boolean;
      [NewsFiltersFrontKey.date]: boolean;
    };
  };
  tenant: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.sectorId]: boolean;
      [NewsFiltersFrontKey.subsectorId]: boolean;
      [NewsFiltersFrontKey.microsectorId]: boolean;
    };
  };
  tax: {
    show: boolean;
    filters: {
      [NewsFiltersFrontKey.taxable]: boolean;
      [NewsFiltersFrontKey.totalValuation]: boolean;
      [NewsFiltersFrontKey.exemption]: boolean;
      [NewsFiltersFrontKey.land]: boolean;
      [NewsFiltersFrontKey.structure]: boolean;
      [NewsFiltersFrontKey.machinery]: boolean;
      [NewsFiltersFrontKey.exoneration]: boolean;
    };
  };
};

export type handleOnBlurProps = {
  entity: OnlyKeyEntityFilter;
  filterTypeValue:
    | Pick<FiltersType, "boolean">
    | Pick<FiltersType, "numeric">
    | Pick<FiltersType, "geometric">
    | Pick<FiltersType, "categoric">
    | Pick<FiltersType, "date">
    | Pick<FiltersType, "search">;
};

const Filters = ({
  applyFilters,
  removeFilters,
  portal,
  clickBlocked,
  openFilters,
  setOpenFilters,
}: FiltersProps) => {
  const defaultSimplifiedFilters: AdvancedFilters = {
    location: {
      show: true,
      filters: {
        cityId: true,
        neighborhoodId: true,
        subNeighborhoodId: false,
        zipCodeId: true,
        buildingId: false,
      },
    },
    parcel: {
      show: false,
      filters: {
        sectorId: true,
        subsectorId: false,
        microsectorId: false,
        landArea: true,
      },
    },
    listing: {
      show: true,
      filters: {
        isForRent: true,
        active: false,
        propertyType: true,
        price: true,
        pricePerBuiltSqm: false,
        pricePerLotSqm: false,
        builtArea: true,
        rooms: true,
        toilets: true,
        parkingSpaces: false,
        daysOnMarket: false,
        createdAt: false,
        luxuryScore: true,
        yearBuilt: false,
        amenityId: true,
        amenity: false,
        amenityRoom: false,
        amenityView: false,
        isFloodZone: false,
      },
    },
    transaction: {
      show: false,
      filters: {
        sectorId: false,
        subsectorId: false,
        microsectorId: false,
        transactionDate: false,
      },
    },
    property: {
      show: false,
      filters: {
        buildingDate: false,
        unitType: false,
        rooms: false,
        toilets: false,
        parkingSpaces: false,
      },
    },
    permit: {
      show: false,
      filters: {
        sectorId: false,
        subsectorId: false,
        category: false,
        status: false,
        date: false,
      },
    },
    tenant: {
      show: false,
      filters: {
        sectorId: false,
        subsectorId: false,
        microsectorId: false,
      },
    },
    tax: {
      show: false,
      filters: {
        taxable: false,
        totalValuation: false,
        exemption: false,
        land: false,
        structure: false,
        machinery: false,
        exoneration: false,
      },
    },
  };
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>(defaultSimplifiedFilters);
  const [isAdvancedFilters, setIsAdvancedFilters] = useState<boolean>(false);
  const { windowWidth } = useAppSelector(selectAppConfig);
  const isMobile = windowWidth < sizeViewport.laptop;
  const handleAdvancedFilters = (boolean: boolean) => {
    if (boolean) {
      const updatedFilters = {
        ...advancedFilters,
        location: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.location.filters).map((key) => [key, true]),
          ),
        },
        parcel: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.parcel.filters).map((key) => [key, true]),
          ),
        },
        listing: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.listing.filters).map((key) => [key, true]),
          ),
        },
        transaction: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.transaction.filters).map((key) => [key, true]),
          ),
        },
        property: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.property.filters).map((key) => [key, true]),
          ),
        },
        permit: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.permit.filters).map((key) => [key, true]),
          ),
        },
        tenant: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.tenant.filters).map((key) => [key, true]),
          ),
        },
        tax: {
          show: true,
          filters: Object.fromEntries(
            Object.keys(advancedFilters.tax.filters).map((key) => [key, true]),
          ),
        },
      };
      setAdvancedFilters(updatedFilters as AdvancedFilters);
    } else {
      setAdvancedFilters(defaultSimplifiedFilters);
    }
    setIsAdvancedFilters(boolean);
  };

  const availableFilters = (getOrganization().filters as AvailableFiltersByPortal)[portal];

  const dispatch = useAppDispatch();
  const { filtersByPortal, areFiltersAvailable, dropdownToggle } = useAppSelector(selectFilters);
  const filters = filtersByPortal[portal].filters;
  const [localFilters, setLocalFilters] = useState(filters); // Estado local para almacenar los filtros no aplicados

  useEffect(() => {
    if (areFiltersAvailable && dropdownToggle && !isMobile) {
      // console.log("actualizar estado LOCAL con lo q esta en redux: ", filters);
      setLocalFilters(filters);
    }
  }, [areFiltersAvailable, dropdownToggle]);

  useEffect(() => {
    if (areFiltersAvailable && openFilters && isMobile) {
      // console.log("actualizar estado LOCAL con lo q esta en redux: ", filters);
      setLocalFilters(filters);
    }
  }, [areFiltersAvailable, openFilters]);

  useEffect(() => {
    if (isMobile) {
      setLocalFilters(filters);
    }
  }, [filters]);

  // Input min & max
  const handleMinMaxChange = ({
    name,
    value,
    entity,
    type,
  }: {
    name: string;
    value: {
      min?: Date | string | number;
      max?: Date | string | number;
    };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => {
    const appliedValue = filters[entity][type]?.[name].appliedValue;

    let valueMin = filters[entity][type]?.[name].value.min;
    let valueMax = filters[entity][type]?.[name].value.max;

    let formatValueMin = value.min;
    let formatValueMax = value.max;
    let emptyValue = null;

    if (
      type === NewsFiltersTypeFrontKey.numeric &&
      typeof value.min === "string" &&
      value.min.length
    ) {
      formatValueMin = thousandSeparatorWhithDecimal(value.min);
    }
    if (
      type === NewsFiltersTypeFrontKey.numeric &&
      typeof value.max === "string" &&
      value.max.length
    ) {
      formatValueMax = thousandSeparatorWhithDecimal(value.max);
    }

    if (type === NewsFiltersTypeFrontKey.numeric) {
      emptyValue = "";
    }

    if (value.min !== undefined) {
      valueMin = formatValueMin;
    }
    if (value.max !== undefined) {
      valueMax = formatValueMax;
    }

    const filterTypeValue = appliedValue
      ? {
          [type]: {
            ...filters[entity][type],
            [name]: {
              value: {
                min: valueMin ?? emptyValue,
                max: valueMax ?? emptyValue,
              },
              appliedValue,
            },
          },
        }
      : {
          [type]: {
            ...filters[entity][type],
            [name]: {
              value: {
                min: valueMin ?? emptyValue,
                max: valueMax ?? emptyValue,
              },
            },
          },
        };

    const newFilters = { ...localFilters };

    newFilters[entity] = {
      ...newFilters[entity],
      ...filterTypeValue,
    };
    if (
      (name === NewsFiltersFrontKey.landArea && entity === NewsFiltersEntityFrontKey.parcel) ||
      (name === NewsFiltersFrontKey.builtArea && entity === NewsFiltersEntityFrontKey.listing) ||
      (name === NewsFiltersFrontKey.price && entity === NewsFiltersEntityFrontKey.listing) ||
      (name === NewsFiltersFrontKey.pricePerBuiltSqm &&
        entity === NewsFiltersEntityFrontKey.listing) ||
      (name === NewsFiltersFrontKey.pricePerLotSqm && entity === NewsFiltersEntityFrontKey.listing)
    ) {
      if (name === NewsFiltersFrontKey.landArea) {
        // Modify the state of another entity
        const listingEntity = NewsFiltersEntityFrontKey.listing;
        const listingName = NewsFiltersFrontKey.lotArea;
        const newAppliedValue = filters[listingEntity][type]?.[listingName].appliedValue;
        const newFilterTypeValue = newAppliedValue
          ? {
              [type]: {
                ...filters[listingEntity][type],
                [listingName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                  newAppliedValue,
                },
              },
            }
          : {
              [type]: {
                ...filters[listingEntity][type],
                [listingName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                },
              },
            };

        const newFilters2 = { ...newFilters };

        newFilters2[listingEntity] = {
          ...newFilters2[listingEntity],
          ...newFilterTypeValue,
        };

        setLocalFilters(newFilters2);
      }

      if (name === NewsFiltersFrontKey.builtArea) {
        // Modify the state of another entity
        const propertyEntity = NewsFiltersEntityFrontKey.property;
        const propertyName = NewsFiltersFrontKey.totalArea;
        const newAppliedValue = filters[propertyEntity][type]?.[propertyName].appliedValue;
        const newFilterTypeValue = newAppliedValue
          ? {
              [type]: {
                ...filters[propertyEntity][type],
                [propertyName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                  newAppliedValue,
                },
              },
            }
          : {
              [type]: {
                ...filters[propertyEntity][type],
                [propertyName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                },
              },
            };
        const newFilters2 = { ...newFilters };

        newFilters2[propertyEntity] = {
          ...newFilters2[propertyEntity],
          ...newFilterTypeValue,
        };

        setLocalFilters(newFilters2);
      }

      if (name === NewsFiltersFrontKey.price) {
        // Modify the state of another entity
        const transactionEntity = NewsFiltersEntityFrontKey.transaction;
        const transactionName = NewsFiltersFrontKey.transactionPrice;
        const newAppliedValue = filters[transactionEntity][type]?.[transactionName].appliedValue;
        const newFilterTypeValue = newAppliedValue
          ? {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                  newAppliedValue,
                },
              },
            }
          : {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                },
              },
            };

        const newFilters2 = { ...newFilters };

        newFilters2[transactionEntity] = {
          ...newFilters2[transactionEntity],
          ...newFilterTypeValue,
        };

        setLocalFilters(newFilters2);
      }

      if (name === NewsFiltersFrontKey.pricePerBuiltSqm) {
        // Modify the state of another entity
        const transactionEntity = NewsFiltersEntityFrontKey.transaction;
        const transactionName = NewsFiltersFrontKey.pricePerBuiltSqm;
        const newAppliedValue = filters[transactionEntity][type]?.[transactionName].appliedValue;
        const newFilterTypeValue = newAppliedValue
          ? {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                  newAppliedValue,
                },
              },
            }
          : {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                },
              },
            };

        const newFilters2 = { ...newFilters };

        newFilters2[transactionEntity] = {
          ...newFilters2[transactionEntity],
          ...newFilterTypeValue,
        };

        setLocalFilters(newFilters2);
      }

      if (name === NewsFiltersFrontKey.pricePerLotSqm) {
        // Modify the state of another entity
        const transactionEntity = NewsFiltersEntityFrontKey.transaction;
        const transactionName = NewsFiltersFrontKey.pricePerLotSqm;
        const newAppliedValue = filters[transactionEntity][type]?.[transactionName].appliedValue;
        const newFilterTypeValue = newAppliedValue
          ? {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                  newAppliedValue,
                },
              },
            }
          : {
              [type]: {
                ...filters[transactionEntity][type],
                [transactionName]: {
                  value: {
                    min: valueMin ?? emptyValue,
                    max: valueMax ?? emptyValue,
                  },
                },
              },
            };

        const newFilters2 = { ...newFilters };

        newFilters2[transactionEntity] = {
          ...newFilters2[transactionEntity],
          ...newFilterTypeValue,
        };

        setLocalFilters(newFilters2);
      }
    } else {
      if (
        name === NewsFiltersFrontKey.rooms ||
        name === NewsFiltersFrontKey.toilets ||
        name === NewsFiltersFrontKey.parkingSpaces ||
        name === NewsFiltersFrontKey.yearBuilt ||
        name === NewsFiltersFrontKey.createdAt ||
        name === NewsFiltersFrontKey.transactionDate ||
        (name === NewsFiltersFrontKey.date && entity === NewsFiltersEntityFrontKey.permit)
      ) {
        //estos casos pasan directo a redux porque usan DatePicker
        dispatch(
          setValueFilter({
            portal,
            entity,
            filterTypeValue: filterTypeValue,
          }),
        );
      }
      setLocalFilters(newFilters);
    }
  };

  const onCheckboxChange = ({
    name,
    value,
    entity,
  }: {
    name: string;
    value: boolean;
    entity: OnlyKeyEntityFilter;
  }) => {
    const appliedValue = filters[entity][NewsFiltersTypeFrontKey.boolean]?.[name].appliedValue;

    const filterTypeValue = appliedValue
      ? {
          [NewsFiltersTypeFrontKey.boolean]: {
            ...filters[entity][NewsFiltersTypeFrontKey.boolean],
            [name]: {
              value: value,
              appliedValue,
            },
          },
        }
      : {
          [NewsFiltersTypeFrontKey.boolean]: {
            ...filters[entity][NewsFiltersTypeFrontKey.boolean],
            [name]: {
              value: value,
            },
          },
        };

    dispatch(
      setValueFilter({
        portal,
        entity,
        filterTypeValue: filterTypeValue,
      }),
    );
  };

  const handleOnBlur = (array: handleOnBlurProps[]) => {
    array.map(({ entity, filterTypeValue }) =>
      dispatch(
        setValueFilter({
          portal,
          entity,
          filterTypeValue,
        }),
      ),
    );
  };

  const scrollContainerRef = useRef<HTMLElement>();
  const containerFilterRef = useRef<HTMLDivElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const [activeSelect, setActiveSelect] = useState<string | null>(null);

  const handleScroll = useCallback(() => {
    if (activeSelect) {
      if (scrollContainerRef.current) {
        setActiveSelect(null);
      }
    }
  }, [activeSelect, menuState]);

  const sections = [
    {
      available:
        availableFilters.location.show &&
        Object.keys(filtersByPortal[portal].filters.location).length &&
        advancedFilters.location.show,
      content: (
        <FiltersLocation
          key="FiltersLocation"
          isAdvancedFilters={isAdvancedFilters}
          advancedFilters={advancedFilters.location.filters}
          availableLocationFilters={availableFilters.location.filters.location}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        (availableFilters.parcel.filters.property.show &&
          Object.keys(filtersByPortal[portal].filters.parcel).length &&
          advancedFilters.parcel.show) ||
        (availableFilters.parcel.filters.unit.show &&
          Object.keys(filtersByPortal[portal].filters.property).length &&
          advancedFilters.property.show), // Se unifico la seccion de unit y property
      content: (
        <PropertySection
          key="PropertySection"
          isAdvancedFilters={isAdvancedFilters}
          advancedParcelFilters={advancedFilters.parcel.filters}
          advancedUnitFilters={advancedFilters.property.filters}
          advancedListingFilters={advancedFilters.listing.filters}
          availableParcelFilters={availableFilters.parcel.filters.property}
          availableUnitFilters={availableFilters.parcel.filters.unit}
          availableListingFilters={availableFilters.listing.filters.listings}
          localFilters={localFilters}
          handleMinMaxChange={handleMinMaxChange}
          onCheckboxChange={onCheckboxChange}
          handleOnBlur={handleOnBlur}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        availableFilters.listing.show &&
        Object.keys(filters.listing).length &&
        advancedFilters.listing.show,
      content: (
        <FiltersListings
          key="FiltersListings"
          isAdvancedFilters={isAdvancedFilters}
          advancedListingFilters={advancedFilters.listing.filters}
          advancedParcelFilters={advancedFilters.parcel.filters}
          availableListingFilters={availableFilters.listing.filters.listings}
          availableParcelFilters={availableFilters.parcel.filters.property}
          localFilters={localFilters}
          handleMinMaxChange={handleMinMaxChange}
          onCheckboxChange={onCheckboxChange}
          handleOnBlur={handleOnBlur}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        availableFilters.parcel.filters.transaction.show &&
        Object.keys(filtersByPortal[portal].filters.transaction).length &&
        advancedFilters.transaction.show,
      content: (
        <TansactionsSection
          key="TansactionsSection"
          isAdvancedFilters={isAdvancedFilters}
          advancedTransactionFilters={advancedFilters.transaction.filters}
          availableTransactionsFilters={availableFilters.parcel.filters.transaction}
          handleMinMaxChange={handleMinMaxChange}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        availableFilters.parcel.filters.permit.show &&
        Object.keys(filtersByPortal[portal].filters.permit).length &&
        advancedFilters.permit.show,
      content: (
        <PermitSection
          key="PermitSection"
          isAdvancedFilters={isAdvancedFilters}
          advancedPermitFilters={advancedFilters.permit.filters}
          availablePermitsFilters={availableFilters.parcel.filters.permit}
          handleMinMaxChange={handleMinMaxChange}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        availableFilters.parcel.filters.tenant.show &&
        Object.keys(filtersByPortal[portal].filters.tenant).length &&
        advancedFilters.tenant.show,
      content: (
        <TenantSection
          key="TenantSection"
          isAdvancedFilters={isAdvancedFilters}
          advancedTenantFilters={advancedFilters.tenant.filters}
          availableTenantFilters={availableFilters.parcel.filters.tenant}
          setMenuState={setMenuState}
          menuState={menuState}
          setActiveSelect={setActiveSelect}
          activeSelect={activeSelect}
          containerFilterRef={containerFilterRef}
          setLocalFilters={setLocalFilters}
        />
      ),
    },
    {
      available:
        availableFilters.parcel.filters.tax.show &&
        Object.keys(filtersByPortal[portal].filters.tax).length &&
        advancedFilters.tax.show,
      content: (
        <TaxSection
          key="TaxSection"
          isAdvancedFilters={isAdvancedFilters}
          advancedTaxesFilters={advancedFilters.tax.filters}
          availableTaxesFilters={availableFilters.parcel.filters.tax}
          localFilters={localFilters}
          handleMinMaxChange={handleMinMaxChange}
          handleOnBlur={handleOnBlur}
        />
      ),
    },
  ];

  const children = (
    <div ref={containerFilterRef}>
      <PerfectScrollbar
        containerRef={(el) => (scrollContainerRef.current = el)}
        onScrollY={handleScroll}
        options={{ suppressScrollX: true }}
        style={{
          width: "100%",
          height: "calc(100svh - 157px)",
        }}>
        <div className="row d-flex justify-content-center">
          <div
            className={classNames("col-12", {
              "col-md-10": isAdvancedFilters,
            })}>
            {isAdvancedFilters && !dispatch(userIsLoggedIn()) ? (
              <FakeTableContainer hideData topCTAModal="25svh">
                <div
                  className="cover-section w-100 blur-content"
                  style={{
                    backgroundImage: `url(${advancedFiltersImg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    height: "calc(100svh - 157px)",
                  }}
                />
              </FakeTableContainer>
            ) : (
              sections
                .filter((section) => section.available)
                .map((section, key) => <div key={key}>{section.content}</div>)
            )}
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );

  return (
    <div className="d-flex">
      {isMobile ? (
        <MobileFiltersContainer
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          applyFilters={applyFilters}
          removeFilters={removeFilters}
          portal={portal}
          clickBlocked={clickBlocked}>
          {children}
        </MobileFiltersContainer>
      ) : (
        <DesktopFiltersContainer
          applyFilters={applyFilters}
          removeFilters={removeFilters}
          portal={portal}
          clickBlocked={clickBlocked}
          isAdvancedFilters={isAdvancedFilters}
          handleAdvancedFilters={handleAdvancedFilters}>
          {children}
        </DesktopFiltersContainer>
      )}
    </div>
  );
};
export default memo(Filters);
