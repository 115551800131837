import type { TransactionProperty } from "@/models";
import { TransactionPropertyBackend, responsePropertyAdapter } from "@/adapters";

export const responseTransactionPropertyAdapter = (
  transactionProperty: TransactionPropertyBackend,
): TransactionProperty => ({
  id: transactionProperty.id,
  govtId: transactionProperty.govtId,
  fincaNumber: transactionProperty.fincaNumber,
  solar: transactionProperty.solar,
  createdAt: transactionProperty.createdAt,
  deletedAt: transactionProperty.deletedAt,
  updatedAt: transactionProperty.updatedAt,
  property: transactionProperty.property
    ? responsePropertyAdapter(transactionProperty.property)
    : null,
});
