export enum AddressJSONKey {
  id = "id",
  dataType = "dataType",
  buildingName = "buildingName",
  joinedAddress = "joinedAddress",
  original = "original",
  sourceId = "sourceId",
}

export enum AddressJSONExpandKey {
  streetName = "streetName",
  streetNumber = "streetNumber",
  unitNumber = "unitNumber",
  unitType = "unitType",
  zipCode = "zipCode",
  source = "source",
}

export enum AddressJSONStreetNameKey {
  method = "method",
  status = "status",
  street_polygone_distance = "street_polygone_distance",
  value = "value",
}

export enum AddressJSONStreetNumberKey {
  status = "status",
  value = "value",
}

export enum AddressJSONUnitNumberKey {
  status = "status",
  value = "value",
}

export enum AddressJSONUnitTypeKey {
  status = "status",
  value = "value",
}

export enum AddressJSONZipCodeKey {
  status = "status",
  value = "value",
}
