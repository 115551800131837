export enum ZipcodeBackendKey {
  id = "id",
  zipCode = "zipCode",
  cityName = "cityName",
  cityCode = "cityCode",
  stateName = "stateName",
  stateCode = "stateCode",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  centroid = "centroid",
  geometry = "geometry",
  cityId = "cityId",
  neighborhoodId = "neighborhoodId",
}

export type ZipcodeBackendKeys = keyof typeof ZipcodeBackendKey;
