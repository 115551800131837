export enum GeometryTypesKey {
  geometryCollection = "GeometryCollection",
  featureCollection = "FeatureCollection",
  feature = "Feature",
  polygon = "Polygon",
  multiPolygon = "MultiPolygon",
  point = "Point",
  lineString = "LineString",
  multiPoint = "MultiPoint",
  multiLineString = "MultiLineString",
}
