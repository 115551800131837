import { useAppSelector } from "@/app/hooks";
import { selectAppConfig } from "@/redux/slices";
import { UnitMeasurement, convertPricePerUnit, convertUnits } from "@/utilities";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

/**
 * Custom hook para convertir unidades de área.
 * @returns {Object} Objeto con funciones para convertir áreas.
 */
export const useConvertUnits = () => {
  const { builtAreaUnit, landAreaUnit } = useAppSelector(selectAppConfig);
  const { t } = useTranslation("translation");
  const languageMeasurementUnits = t("measurementUnits", { returnObjects: true });

  // Built Area

  /**
   * Convierte un valor de área construida a la unidad actual.
   * @param {Object} args - Argumentos de conversión.
   * @param {number} args.value - Valor a convertir.
   * @param {UnitMeasurement} [args.from] - Unidad de medida de origen (opcional).
   * @returns {number} Valor convertido.
   */
  const convertBuiltArea = useCallback(
    ({ value, from }: { value: number; from?: UnitMeasurement }) => {
      return convertUnits({ value, from, to: builtAreaUnit });
    },
    [builtAreaUnit],
  );

  /**
   * Convierte un valor de precio por área construida a la unidad actual.
   * @param {Object} args - Argumentos de conversión.
   * @param {number} args.value - Valor a convertir.
   * @param {UnitMeasurement} [args.from] - Unidad de medida de origen (opcional).
   * @returns {number} Valor convertido.
   */
  const convertPricePerBuiltArea = useCallback(
    ({ value, from }: { value: number; from?: UnitMeasurement }) => {
      return convertPricePerUnit({ value, from, to: builtAreaUnit });
    },
    [builtAreaUnit],
  );

  /**
   * Traduccion de unidades de área construida.
   */
  const languageBuiltAreaUnits = languageMeasurementUnits[builtAreaUnit];

  /**
   * Traduccion de unidades de precio por área construida.
   */
  const languagePerBuiltAreaUnits = languageMeasurementUnits.per[builtAreaUnit];

  // Land Area

  /**
   * Convierte un valor de área de terreno a la unidad actual.
   * @param {Object} args - Argumentos de conversión.
   * @param {number} args.value - Valor a convertir.
   * @param {UnitMeasurement} [args.from] - Unidad de medida de origen (opcional).
   * @returns {number} Valor convertido.
   */
  const convertLandArea = useCallback(
    ({
      value,
      from,
      preciseValue = false,
    }: {
      value: number;
      from?: UnitMeasurement;
      preciseValue?: boolean;
    }) => {
      return convertUnits({ value, from, to: landAreaUnit, preciseValue });
    },
    [landAreaUnit],
  );

  /**
   * Convierte un valor de precio por área de terreno a la unidad actual.
   * @param {Object} args - Argumentos de conversión.
   * @param {number} args.value - Valor a convertir.
   * @param {UnitMeasurement} [args.from] - Unidad de medida de origen (opcional).
   * @returns {number} Valor convertido.
   */
  const convertPricePerLandArea = useCallback(
    ({ value, from }: { value: number; from?: UnitMeasurement }) => {
      return convertPricePerUnit({ value, from, to: landAreaUnit });
    },
    [landAreaUnit],
  );

  /**
   * Traduccion de unidades de área de terreno.
   */
  const languageLandAreaUnits = languageMeasurementUnits[landAreaUnit];

  /**
   * Traduccion de unidades de precio por área de terreno
   */
  const languagePerLandAreaUnits = languageMeasurementUnits.per[landAreaUnit];

  return {
    convertBuiltArea,
    convertPricePerBuiltArea,
    languageBuiltAreaUnits,
    languagePerBuiltAreaUnits,
    convertLandArea,
    convertPricePerLandArea,
    languageLandAreaUnits,
    languagePerLandAreaUnits,
  };
};
