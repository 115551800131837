import type { NewsBackend } from "@/adapters";
import { News } from "@/models";
import { responseNewsProfileAdapter } from "./news-profile.adapter";

export const responseNewsAdapter = (news: NewsBackend[]): News[] => {
  const data: News[] = [];
  if (news.length) {
    news.map((news) => data.push(responseNewsProfileAdapter(news)));
  }
  return data;
};
