import { Lists } from "@/models";
import { ListBackend } from "@/adapters";

export const responseListsAdapter = (propertyList: ListBackend): Lists => {
  const lists: Lists["lists"] = [];

  const data: Lists = {
    count: propertyList.count,
    lists: lists,
  };
  if (propertyList.lists.length) {
    propertyList.lists.map((list) =>
      lists.push({
        id: list.id,
        name: list.name,
        description: list.description,
        total: list.total,
      }),
    );
  }
  return data;
};
