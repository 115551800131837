export const sqmToAcres = (value: number) => {
  return value / 4046.86;
};

export const acresToSqm = (value: number) => {
  return value * 4046.86;
};

export const sqftToSqm = (value: number) => {
  return value / 10.764;
};

export const sqmToSqft = (value: number) => {
  return value * 10.764;
};

export const mToFt = (value: number) => {
  return value * 3.28084;
};

export const ftToM = (value: number) => {
  return value / 3.28084;
};

export const kmToMiles = (value: number) => {
  return value * 0.621371;
};

export const pricePerSqmToSqft = (value: number) => {
  return value / 10.764;
};

export const pricePerSqmToAcres = (value: number) => {
  return value * 4046.86;
};

export const pricePerSqftToSqm = (value: number) => {
  return value * 10.764;
};

export const pricePerAcreToSqm = (value: number) => {
  return value / 4046.86;
};

export const deleteCurrency = (value: string) => {
  if (value.includes("$")) {
    return value.slice(1);
  } else {
    return value;
  }
};

export enum UnitMeasurement {
  sqm = "sqm",
  sqft = "sqft",
  acre = "acre",
}

export const convertUnits = ({
  value,
  from = UnitMeasurement.sqm,
  to,
  preciseValue,
}: {
  value: number;
  from?: UnitMeasurement;
  to: UnitMeasurement;
  preciseValue?: boolean;
}) => {
  if (from === to) {
    return Number(Number(value).toFixed(2));
  }
  if (from === UnitMeasurement.sqm && to === UnitMeasurement.sqft) {
    return Number(sqmToSqft(value).toFixed(0));
  }
  if (from === UnitMeasurement.sqft && to === UnitMeasurement.sqm) {
    return sqftToSqm(value);
  }
  if (from === UnitMeasurement.sqm && to === UnitMeasurement.acre) {
    if (preciseValue) {
      return sqmToAcres(value);
    }
    return Number(sqmToAcres(value).toFixed(3));
  }
  if (from === UnitMeasurement.acre && to === UnitMeasurement.sqm) {
    return acresToSqm(value);
  }
};

// Similar to before function, but applied to the price per unit measurement.
export const convertPricePerUnit = ({
  value,
  from = UnitMeasurement.sqm,
  to,
}: {
  value: number;
  from?: UnitMeasurement;
  to: UnitMeasurement;
}) => {
  if (from === to) {
    return value;
  }
  if (from === UnitMeasurement.sqm && to === UnitMeasurement.sqft) {
    return pricePerSqmToSqft(value).toFixed(2);
  }
  if (from === UnitMeasurement.sqft && to === UnitMeasurement.sqm) {
    return pricePerSqftToSqm(value);
  }
  if (from === UnitMeasurement.sqm && to === UnitMeasurement.acre) {
    return pricePerSqmToAcres(value).toFixed(2);
  }
  if (from === UnitMeasurement.acre && to === UnitMeasurement.sqm) {
    return pricePerAcreToSqm(value);
  }
};
