import { SubscriptionPlanPrice } from "@/models";
import { SubscriptionPlanPriceBackend } from "../models/subscription.backend.model";

export const responseSubscriptionPlanPriceAdapter = (
  price: SubscriptionPlanPriceBackend,
): SubscriptionPlanPrice | undefined => {
  if (price.active) {
    return {
      id: price.id,
      stripePriceId: price.stripePriceId,
      stripeProductId: price.stripeProductId,
      price: Number(price.price),
      frequency: price.frequency,
      active: price.active,
    };
  } else {
    return;
  }
};
