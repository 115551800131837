import { createBrowserHistory, History } from "history";

const history: History = createBrowserHistory();

// historyStack guarda las rutas en el orden que fueron visitados, siendo el index 0 la primer ruta, y el index historyStack.length-1 la ruta anterior a la actual (por ejemplo si tocas atras)
const historyStack: string[] = [];

history.listen((location, action) => {
  if (action === "PUSH") {
    historyStack.push(location.pathname);
  } else if (action === "POP") {
    historyStack.pop();
  }
});

export { history, historyStack };
