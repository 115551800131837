const initialState = {
    isLoadingReverseGeocoding: false,
    addressPredictions: [],
    isLoadingAddressPredictions: false
};
  
const googleReducer = (state = initialState, action) => {
switch (action.type) {
    case "START_REVERSE_GEOCODING_IS_LOADING":
    return {
        ...state,
        isLoadingReverseGeocoding: true,
    };
    case "END_REVERSE_GEOCODING_IS_LOADING":
    return {
        ...state,
        isLoadingReverseGeocoding: false,
    };
    case "GET_PLACE_PREDICTIONS":
    return {
        ...state,
        addressPredictions: action.payload,
    };
    case "START_PLACE_PREDICTIONS_IS_LOADING":
    return {
        ...state,
        isLoadingAddressPredictions: true,
    };
    case "END_PLACE_PREDICTIONS_IS_LOADING":
    return {
        ...state,
        isLoadingAddressPredictions: false,
    };
    default:
    return state;
}
};

export default googleReducer;
  