import {
  CountryCodeBackendKey,
  CountryCodeKey,
  ListingBackendInclude,
  ParcelBackendInclude,
  useEndpoint,
} from "@/keys";
import {
  ListingBackend,
  ParcelBackend,
  RequestFiltersAdapterProps,
  requestFiltersAdapter,
  responseListingsAdapter,
  responseParcelsAdapter,
  responseParcelsClustersAdapter,
} from "@/adapters";
import { api } from "../api";
import { Listing, ListingCluster, ParcelCluster } from "@/models";

const clusterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getParcelsClusters: builder.query<
      ParcelCluster[],
      {
        bodyProps: RequestFiltersAdapterProps<ParcelBackendInclude, { isCount: false }>;
        countryCode: CountryCodeKey;
        controller: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().parcels({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller.signal,
        };
      },
      transformResponse: (response: { data: ParcelBackend[] }): ParcelCluster[] =>
        responseParcelsClustersAdapter(responseParcelsAdapter(response.data)),
      keepUnusedDataFor: 0,
    }),
    getListingsClusters: builder.query<
      Listing[],
      {
        bodyProps: RequestFiltersAdapterProps<ListingBackendInclude, { isCount: false }>;
        countryCode: CountryCodeKey;
        controller: AbortController;
      }
    >({
      query: ({ countryCode, bodyProps, controller }) => {
        const body = requestFiltersAdapter({ ...bodyProps });
        return {
          url: useEndpoint().listings({ countryCode: CountryCodeBackendKey[countryCode] }),
          method: "post",
          body,
          signal: controller.signal,
        };
      },
      transformResponse: (response: { data: ListingBackend[] }): Listing[] =>
        responseListingsAdapter(response.data),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetParcelsClustersQuery, useLazyGetListingsClustersQuery } = clusterApi;
