import React from "react";
import { history } from "@/history";
import NewsButton from "./NewsButton";
import LatestNews from "./LatestNews";
import NewsStatistics from "./NewsStatistics";
import { useLocaleCountryCode } from "@/hooks";
import { CountryCodeKey } from "@/keys";
interface NewsFooter {
  clickBlocked: () => void;
  windowWidth: number;
}

function NewsFooter({ clickBlocked, windowWidth }: NewsFooter) {
  const { countryCode } = useLocaleCountryCode();
  return (
    <div
      className="card mb-0 p-05 w-100 d-flex align-items-center"
      style={{ height: "3.52rem", flexDirection: "inherit" }}>
      <NewsButton history={history} clickBlocked={clickBlocked} windowWidth={windowWidth} />

      <NewsStatistics />
      <div style={{ overflow: "hidden" }}>
        <div style={{ maxWidth: "100%" }}>
          <LatestNews />
        </div>
      </div>
    </div>
  );
}
export default NewsFooter;
