import { MicrosectorBackendKeys } from "./microsector-backend.key";
import { ParcelBackendKeys } from "./parcel-backend.key";
import { SectorBackendKeys } from "./sector-backend.key";
import { SourceBackendKeys } from "./source-backend.key";
import { SubsectorBackendKeys } from "./subsector-backend.key";

export enum TenantBackendKey {
  id = "id",
  pictures = "pictures",
  name = "name",
  url = "url",
  phones = "phones",
  address = "address",
  addressJSON = "addressJSON",
  createdAt = "createdAt",
  descriptionText = "descriptionText",
  emails = "emails",
  extractedAt = "extractedAt",
  geometry = "geometry",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
  microsectorId = "microsectorId",
  tags = "tags",
  updatedAt = "updatedAt",
  websites = "websites",
}

export type TenantBackendKeys = keyof typeof TenantBackendKey;

export enum TenantBackendSortKey {
  // id = "id",
  // pictures = "pictures",
  name = "name",
  // url = "url",
  // phones = "phones",
  // address = "address",
  // addressJSON = "addressJSON",
  // createdAt = "createdAt",
  // descriptionText = "descriptionText",
  // emails = "emails",
  // extractedAt = "extractedAt",
  // geometry = "geometry",
  // sectorId = "sectorId",
  // subsectorId = "subsectorId", // Inconsistent SortKey
  // microsectorId = "microsectorId", // Inconsistent SortKey
  // tags = "tags",
  // updatedAt = "updatedAt",
  // websites = "websites",
}

export enum TenantBackendExpandKey {
  parcel = "parcel",
  source = "source",
  sector = "sector",
  subsector = "subsector",
  microsector = "microsector",
}

export type TenantBackendExpandKeys = keyof typeof TenantBackendExpandKey;

export type TenantBackendInclude =
  | (TenantBackendKeys | "*")
  | `${TenantBackendExpandKey}.${ParcelBackendKeys}`
  | `${TenantBackendExpandKey}.${SourceBackendKeys}`
  | `${TenantBackendExpandKey}.${SectorBackendKeys}`
  | `${TenantBackendExpandKey}.${SubsectorBackendKeys}`
  | `${TenantBackendExpandKey}.${MicrosectorBackendKeys}`;
