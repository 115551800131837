import { Permit } from "@/models";
import { PermitBackend, responsePermitAdapter } from "@/adapters";

export const responsePermitsAdapter = (permits: PermitBackend[]): Permit[] => {
  const newPermits: Permit[] = [];

  if (permits.length) {
    permits.map((permit) => newPermits.push(responsePermitAdapter(permit)));
  }
  return newPermits;
};
