import { ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { ListingStatusBackendKey } from "@/keys";

export function listingStatusColorsColorsMapper({
  statusName,
  statusColor,
}: {
  statusName: string | null;
  statusColor: ThemeType["listingColors"]["status"];
}) {
  switch (statusName) {
    case ListingStatusBackendKey.SOLD:
    case ListingStatusBackendKey.RENTED:
      return statusColor.sold;
    case ListingStatusBackendKey.FOR_RENT:
      return statusColor.forLease;
    case ListingStatusBackendKey.FOR_SALE:
      return statusColor.forSale;
    default:
      return statusColor.default;
  }
}
