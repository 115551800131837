import { NewsFiltersEntityFrontKey, NewsFiltersFrontKey, NewsFiltersTypeFrontKey } from "@/keys";

export const getReverseGeocoding = (lat, lng) => {
  return async (dispatch, getState) => {
    dispatch({
      type: `START_REVERSE_GEOCODING_IS_LOADING`,
    });

    const resp = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&result_type=street_address&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          dispatch({
            type: `END_REVERSE_GEOCODING_IS_LOADING`,
          });
          let submarket = data.results[0].address_components.find((address) =>
            address.types.find((type) => type === "administrative_area_level_1"),
          );
          let micromarket = data.results[0].address_components.find((address) =>
            address.types.find((type) => type === "locality"),
          );
          let resp = {
            address: data.results[0].formatted_address,
            submarket: submarket?.long_name ? submarket.long_name : "",
            micromarket: micromarket?.long_name ? micromarket.long_name : "",
          };
          return resp;
        } else {
          return fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
          )
            .then((response) => response.json())
            .then((data) => {
              dispatch({
                type: `END_REVERSE_GEOCODING_IS_LOADING`,
              });
              if (data.status === "OK") {
                let response = [];
                data.results.map((result, index) => {
                  let submarket = result.address_components.find((address) =>
                    address.types.find((type) => type === "administrative_area_level_1"),
                  );
                  let micromarket = result.address_components.find((address) =>
                    address.types.find((type) => type === "locality"),
                  );

                  return response.push({
                    value: index,
                    label: result.formatted_address,
                    submarket: submarket?.long_name ? submarket.long_name : "",
                    micromarket: micromarket?.long_name ? micromarket.long_name : "",
                  });
                });
                return response;
              } else {
                return null;
              }
            });
        }
      })
      .catch((error) => {
        dispatch({
          type: `END_REVERSE_GEOCODING_IS_LOADING`,
        });
        console.log(error);
        return null;
      });

    return resp;
  };
};

export const loadPlacePredictions = ({ userInput, countryCode }) => {
  return async (dispatch) => {
    dispatch({
      type: `START_PLACE_PREDICTIONS_IS_LOADING`,
    });

    if (window?.google?.maps) {
      const options = {
        // types: ["street_address", "geocode"],
        componentRestrictions: { country: countryCode },
      };

      const { AutocompleteService } =
        window.google.maps.places ?? (await window.google.maps.importLibrary("places"));
      let service = new AutocompleteService(null, options);
      let request = {
        input: userInput,
        // types: ["geocode"],
        componentRestrictions: { country: countryCode },
      };

      return service.getPlacePredictions(request, callback).then((response) => {
        if (response) {
          let addressPredictions = [];
          response.predictions?.slice(0, 3)?.map((prediction) => {
            return addressPredictions.push({
              id: prediction.place_id,
              title: prediction.description,
              icon: "MapPin",
              filter: NewsFiltersFrontKey.address,
              entity: NewsFiltersEntityFrontKey.location,
              type: NewsFiltersTypeFrontKey.categoric,
            });
          });
          dispatch({
            type: `GET_PLACE_PREDICTIONS`,
            payload: addressPredictions,
          });
        } else {
          dispatch({
            type: `GET_PLACE_PREDICTIONS`,
            payload: [],
          });
        }
      });
    } else {
      dispatch({
        type: `END_PLACE_PREDICTIONS_IS_LOADING`,
      });
    }
  };
};

const callback = (predictions, status) => {
  if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
    return false;
  } else {
    return predictions;
  }
};
