import { AmenityTypeBackendKey } from "@/adapters";
import { store } from "@/app/store";
import {
  CategoricFiltersKey,
  CountryCodeKey,
  LanguageCodeKey,
  NewsFiltersEntityBackendKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeBackendKey,
  NewsFiltersTypeFrontKey,
  TabKey,
} from "@/keys";
import type { CategoricFilters, FiltersType } from "@/models";
import { Filters, resetFiltersByPortal, setUserInputSearch } from "@/redux/slices";
import {
  GptSearchBackend,
  GptSearchBackendEntityKey,
  GptSearchBackendLocationFilterKey,
  getNeighbordhoods,
  getSubNeighbordhoods,
} from "@/services";
import { convertUnits } from "@/utilities";
import { unifiedGptFiltersAdapter } from "./unified-gpt-filters.adapter";

async function fetchSubNeighborhoods({
  neighborhoodIds,
  countryCode,
}: {
  neighborhoodIds: string[];
  countryCode: CountryCodeKey;
}) {
  try {
    await store.dispatch(
      getSubNeighbordhoods({
        neighborhoodIds,
        countryCode,
      }),
    );
  } catch (error) {
    return null;
  }
}

async function fetchNeighborhoods({
  cityId,
  countryCode,
}: {
  cityId: string;
  countryCode: CountryCodeKey;
}) {
  try {
    await store.dispatch(
      getNeighbordhoods({
        cityId,
        countryCode,
      }),
    );
  } catch (error) {
    return null;
  }
}

async function fetchNeighborhoodAndFind({
  cityId,
  neighborhoodId,
  countryCode,
}: {
  cityId: string;
  neighborhoodId: string;
  countryCode: CountryCodeKey;
}) {
  try {
    await store.dispatch(
      getNeighbordhoods({
        cityId,
        countryCode,
      }),
    );

    const findNeighborhood = store
      .getState()
      .locations.neighborhoods.find((nei) => nei.value === neighborhoodId);

    return findNeighborhood ?? null;
  } catch (error) {
    return null;
  }
}

async function fetchSubNeighborhoodAndFind({
  subNeighborhoodId,
  neighborhoodIds,
  countryCode,
}: {
  subNeighborhoodId: string;
  neighborhoodIds: string[];
  countryCode: CountryCodeKey;
}) {
  try {
    await store.dispatch(
      getSubNeighbordhoods({
        neighborhoodIds: neighborhoodIds,
        countryCode,
      }),
    );

    const findSubNeighborhood = store
      .getState()
      .locations.subNeighborhoods.find((nei) => nei.value === subNeighborhoodId);

    return findSubNeighborhood ?? null;
  } catch (error) {
    return null;
  }
}

export const responseGptSearchAdapter = async (
  gptData: GptSearchBackend,
  arg: {
    search: string;
    countryCode: CountryCodeKey;
    categoricFilters: CategoricFilters;
    advancedFilters: boolean;
  },
): Promise<{
  filtersRedux: Filters | null;
  tabKey: TabKey | null;
  filtersAreAvailable: boolean;
}> => {
  const { portal, landAreaUnit, builtAreaUnit } = store.getState().app;

  let key: keyof typeof GptSearchBackendEntityKey;

  let filtersAreAvailable = false;

  let tabKeyResponse: TabKey | null = null;

  for (key in gptData) {
    if (key !== GptSearchBackendEntityKey.entity) {
      if (Object.keys(gptData[key]).length && !filtersAreAvailable) {
        filtersAreAvailable = true;
      }
    } else {
      if (gptData[key] === NewsFiltersEntityBackendKey.listing) {
        tabKeyResponse = TabKey.listings;
      } else if (gptData[key] === NewsFiltersEntityBackendKey.parcel) {
        tabKeyResponse = TabKey.properties;
      } else if (gptData[key] === NewsFiltersEntityBackendKey.transaction) {
        tabKeyResponse = TabKey.transactions;
      }
    }
  }

  if (filtersAreAvailable) {
    store.dispatch(resetFiltersByPortal({ portal }));
    const { filters, categoricFilters } = store.getState().newFilters.filtersByPortal[portal]; // aca se llama de nuevo a filters para acceder al valor limpio
    const getCategoricFilterLabel = (
      value: string,
      filterKey: string,
      languageCode: LanguageCodeKey,
    ): string => {
      if (filterKey === NewsFiltersFrontKey.amenityId) {
        const element = arg.advancedFilters
          ? [
              ...categoricFilters.amenities,
              ...categoricFilters.amenitiesRooms,
              ...categoricFilters.amenitiesViews,
            ]
          : categoricFilters.amenitiesTop10;
        const foundItem = element.find((item) => item.value === value);
        if (foundItem) {
          return languageCode === LanguageCodeKey.en
            ? foundItem.label
            : foundItem?.esLabel ?? foundItem.label;
        }
      } else {
        for (const key in categoricFilters) {
          const element = categoricFilters[key as CategoricFiltersKey];
          const foundItem = element.find((item) => item.value === value);
          if (foundItem) {
            if (filterKey === NewsFiltersFrontKey.sectorId) {
              return foundItem.name ? foundItem.name : "";
            }
            if (filterKey === NewsFiltersFrontKey.propertyType) {
              return foundItem.name ? foundItem.name : "";
            }

            return languageCode === LanguageCodeKey.en
              ? foundItem.label
              : foundItem?.esLabel ?? foundItem.label;
          }
        }
      }

      return "";
    };

    const getCategoricAmenityType = (value: string): string | null => {
      if (categoricFilters) {
        const concatedAmenitiesArray = arg.advancedFilters
          ? [
              ...categoricFilters[CategoricFiltersKey.amenities],
              ...categoricFilters[CategoricFiltersKey.amenitiesRooms],
              ...categoricFilters[CategoricFiltersKey.amenitiesViews],
            ]
          : categoricFilters.amenitiesTop10;
        const foundItem = concatedAmenitiesArray.find((item) => item.value === value);
        if (foundItem?.amenityType) {
          return foundItem.amenityType;
        }
      }
      return null;
    };

    let response = filters;

    let entityKey: keyof typeof GptSearchBackendEntityKey;
    let typeFilterKey: keyof typeof NewsFiltersTypeBackendKey;

    for (entityKey in gptData) {
      if (
        entityKey !== GptSearchBackendEntityKey.location &&
        entityKey !== GptSearchBackendEntityKey.entity
      ) {
        for (typeFilterKey in gptData[entityKey]) {
          if (typeFilterKey === NewsFiltersTypeBackendKey.categoric) {
            for (const filterKey in gptData[entityKey][typeFilterKey]) {
              const value = gptData[entityKey][typeFilterKey]?.[filterKey];

              if (value !== undefined) {
                const valueFilter: FiltersType["categoric"] = {
                  [filterKey]: {
                    value: Array.isArray(value)
                      ? value.map((strValue) => ({
                          value: strValue,
                          label: getCategoricFilterLabel(strValue, filterKey, LanguageCodeKey.en),
                          esLabel: getCategoricFilterLabel(strValue, filterKey, LanguageCodeKey.es),
                          name: getCategoricFilterLabel(strValue, filterKey, LanguageCodeKey.en),
                        }))
                      : typeof value === "string"
                      ? [
                          {
                            value: String(value),
                            label: getCategoricFilterLabel(value, filterKey, LanguageCodeKey.en),
                            esLabel: getCategoricFilterLabel(value, filterKey, LanguageCodeKey.es),
                            name: getCategoricFilterLabel(value, filterKey, LanguageCodeKey.en),
                          },
                        ]
                      : value.matchAll.map((strValue) => {
                          if (filterKey === NewsFiltersFrontKey.amenityId) {
                            return {
                              value: strValue,
                              label: getCategoricFilterLabel(
                                strValue,
                                filterKey,
                                LanguageCodeKey.en,
                              ),
                              esLabel: getCategoricFilterLabel(
                                strValue,
                                filterKey,
                                LanguageCodeKey.es,
                              ),
                              amenityType: getCategoricAmenityType(strValue),
                            };
                          } else {
                            return {
                              value: strValue,
                              label: getCategoricFilterLabel(
                                strValue,
                                filterKey,
                                LanguageCodeKey.en,
                              ),
                              esLabel: getCategoricFilterLabel(
                                strValue,
                                filterKey,
                                LanguageCodeKey.es,
                              ),
                            };
                          }
                        }),
                  },
                };

                if (filterKey === NewsFiltersFrontKey.amenityId) {
                  if (!Array.isArray(value) && typeof value !== "string") {
                    const allAmenities = value.matchAll.map((strValue) => ({
                      value: strValue,
                      label: getCategoricFilterLabel(strValue, filterKey, LanguageCodeKey.en),
                      esLabel: getCategoricFilterLabel(strValue, filterKey, LanguageCodeKey.es),
                      amenityType: getCategoricAmenityType(strValue),
                    }));

                    const amenities = allAmenities.filter(
                      (amenity) => amenity.amenityType === null && amenity.label.length,
                    );
                    const amenitiesRooms = allAmenities.filter(
                      (amenity) =>
                        amenity.amenityType === AmenityTypeBackendKey.room && amenity.label.length,
                    );
                    const amenitiesViews = allAmenities.filter(
                      (amenity) =>
                        amenity.amenityType === AmenityTypeBackendKey.view && amenity.label.length,
                    );

                    valueFilter[NewsFiltersFrontKey.amenity] = {
                      value: amenities.length ? amenities : null,
                    };
                    valueFilter[NewsFiltersFrontKey.amenityRoom] = {
                      value: amenitiesRooms.length ? amenitiesRooms : null,
                    };
                    valueFilter[NewsFiltersFrontKey.amenityView] = {
                      value: amenitiesViews.length ? amenitiesViews : null,
                    };
                  }
                }

                response = {
                  ...response,
                  [entityKey]: {
                    ...response[entityKey],
                    [typeFilterKey]: {
                      ...response?.[entityKey]?.[typeFilterKey],
                      ...valueFilter,
                    },
                  },
                };
              }
            }
          } else if (typeFilterKey === NewsFiltersTypeBackendKey.boolean) {
            for (const filterKey in gptData[entityKey][typeFilterKey]) {
              const value = gptData[entityKey][typeFilterKey]?.[filterKey];
              if (value !== undefined) {
                let valueFilter: FiltersType["boolean"] = undefined;
                if (filterKey === NewsFiltersFrontKey.active && !Array.isArray(value) && !value) {
                  valueFilter = {
                    [NewsFiltersFrontKey.inactive]: { value: true },
                  };
                } else if (
                  filterKey === NewsFiltersFrontKey.isForRent &&
                  !Array.isArray(value) &&
                  !value
                ) {
                  valueFilter = {
                    [NewsFiltersFrontKey.isForSale]: { value: true },
                  };
                } else {
                  valueFilter = {
                    [filterKey]: { value: value as boolean },
                  };
                }

                response = {
                  ...response,
                  [entityKey]: {
                    ...response[entityKey],
                    [typeFilterKey]: {
                      ...response?.[entityKey]?.[typeFilterKey],
                      ...valueFilter,
                    },
                  },
                };
              }
            }
          } else if (typeFilterKey === NewsFiltersTypeBackendKey.date) {
            for (const filterKey in gptData[entityKey][typeFilterKey]) {
              const value = gptData[entityKey][typeFilterKey]?.[filterKey];
              if (value !== undefined) {
                const valueFilter: FiltersType["date"] = {
                  [filterKey]: {
                    value: {
                      min: value.min ? new Date(value.min) : null,
                      max: value.max ? new Date(value.max) : null,
                    },
                  },
                };

                response = {
                  ...response,
                  [entityKey]: {
                    ...response[entityKey],
                    [typeFilterKey]: {
                      ...response?.[entityKey]?.[typeFilterKey],
                      ...valueFilter,
                    },
                  },
                };
              }
            }
          } else if (typeFilterKey === NewsFiltersTypeBackendKey.numeric) {
            for (const filterKey in gptData[entityKey][typeFilterKey]) {
              const value = gptData[entityKey][typeFilterKey]?.[filterKey];
              if (value !== undefined) {
                const valueFilter: FiltersType["numeric"] = {
                  [filterKey]: {
                    value: {
                      min: value.min
                        ? filterKey === NewsFiltersFrontKey.landArea ||
                          filterKey === NewsFiltersFrontKey.lotArea
                          ? (convertUnits({ value: value.min, to: landAreaUnit }) as number)
                          : filterKey === NewsFiltersFrontKey.totalArea ||
                            filterKey === NewsFiltersFrontKey.builtArea
                          ? (convertUnits({ value: value.min, to: builtAreaUnit }) as number)
                          : value.min
                        : "",
                      max: value.max
                        ? filterKey === NewsFiltersFrontKey.landArea ||
                          filterKey === NewsFiltersFrontKey.lotArea
                          ? (convertUnits({ value: value.max, to: landAreaUnit }) as number)
                          : filterKey === NewsFiltersFrontKey.totalArea ||
                            filterKey === NewsFiltersFrontKey.builtArea
                          ? (convertUnits({ value: value.max, to: builtAreaUnit }) as number)
                          : value.max
                        : "",
                    },
                  },
                };

                response = {
                  ...response,
                  [entityKey]: {
                    ...response[entityKey],
                    [typeFilterKey]: {
                      ...response?.[entityKey]?.[typeFilterKey],
                      ...valueFilter,
                    },
                  },
                };
              }
            }
          }
          // NO ESTAMOS CONTEMPLANDO GEOMETRY NI SEARCH (si se quieren agregar esos casos a chatgpt hay q agregarlos aca tamb)
        }
      } else if (entityKey === GptSearchBackendEntityKey.location) {
        const city =
          gptData[GptSearchBackendEntityKey.location][GptSearchBackendLocationFilterKey.city];
        const neighborhood = gptData[entityKey][GptSearchBackendLocationFilterKey.neighborhood];
        const subNeighborhood =
          gptData[entityKey][GptSearchBackendLocationFilterKey.subNeighborhood];
        const zipCode = gptData[entityKey][GptSearchBackendLocationFilterKey.zipCode];
        const urbanization = gptData[entityKey][GptSearchBackendLocationFilterKey.urbanization];

        if (subNeighborhood !== undefined && neighborhood !== undefined && city !== undefined) {
          const findNeighborhood = await fetchNeighborhoodAndFind({
            cityId: city.id,
            neighborhoodId: neighborhood.id,
            countryCode: arg.countryCode,
          });

          let findSubNeighborhood = null;
          if (arg.countryCode !== CountryCodeKey.PR) {
            const neighborhoodIds = store
              .getState()
              .locations.neighborhoods.map((neighborhood) => neighborhood.value);

            findSubNeighborhood = await fetchSubNeighborhoodAndFind({
              neighborhoodIds,
              subNeighborhoodId: subNeighborhood.id,
              countryCode: arg.countryCode,
            });
          } else {
            findSubNeighborhood = store
              .getState()
              .locations.neighborhoods.find(
                (nei) => nei.value === subNeighborhood.id && nei.isNeighborhood === false,
              );
          }

          if (findSubNeighborhood) {
            const valueFilter: FiltersType["categoric"] = {
              [NewsFiltersFrontKey.cityId]: {
                value: {
                  value: city.id,
                  label: city.name,
                },
              },
              [NewsFiltersFrontKey.neighborhoodId]: {
                value: findNeighborhood,
              },
              [NewsFiltersFrontKey.subNeighborhoodId]: {
                value: findSubNeighborhood,
              },
            };
            response = {
              ...response,
              [entityKey]: {
                ...response[entityKey],
                [NewsFiltersTypeFrontKey.categoric]: {
                  ...response?.[entityKey]?.[NewsFiltersTypeFrontKey.categoric],
                  ...valueFilter,
                },
              },
            };
          }
        } else if (neighborhood !== undefined && city !== undefined) {
          const findNeighborhood = await fetchNeighborhoodAndFind({
            cityId: city.id,
            neighborhoodId: neighborhood.id,
            countryCode: arg.countryCode,
          });

          if (findNeighborhood) {
            if (arg.countryCode !== CountryCodeKey.PR) {
              const neighborhoodIds = store
                .getState()
                .locations.neighborhoods.map((neighborhood) => neighborhood.value);

              await fetchSubNeighborhoods({
                neighborhoodIds,
                countryCode: arg.countryCode,
              });
            }

            const valueFilter: FiltersType["categoric"] = {
              [NewsFiltersFrontKey.cityId]: {
                value: {
                  value: city.id,
                  label: city.name,
                },
              },
              [NewsFiltersFrontKey.neighborhoodId]: {
                value: findNeighborhood,
              },
            };

            response = {
              ...response,
              [entityKey]: {
                ...response[entityKey],
                [NewsFiltersTypeFrontKey.categoric]: {
                  ...response?.[entityKey]?.[NewsFiltersTypeFrontKey.categoric],
                  ...valueFilter,
                },
              },
            };
          }
        } else if (city !== undefined) {
          await fetchNeighborhoods({
            cityId: city.id,
            countryCode: arg.countryCode,
          });

          const valueFilter: FiltersType["categoric"] = {
            [NewsFiltersFrontKey.cityId]: {
              value: {
                value: city.id,
                label: city.name,
              },
            },
          };

          response = {
            ...response,
            [entityKey]: {
              ...response[entityKey],
              [NewsFiltersTypeFrontKey.categoric]: {
                ...response?.[entityKey]?.[NewsFiltersTypeFrontKey.categoric],
                ...valueFilter,
              },
            },
          };
        } else if (zipCode !== undefined) {
          const valueFilter: FiltersType["categoric"] = {
            [NewsFiltersFrontKey.zipCodeId]: {
              value: {
                value: zipCode.id,
                label: zipCode.zipCode,
              },
            },
          };

          response = {
            ...response,
            [entityKey]: {
              ...response[entityKey],
              [NewsFiltersTypeFrontKey.categoric]: {
                ...response?.[entityKey]?.[NewsFiltersTypeFrontKey.categoric],
                ...valueFilter,
              },
            },
          };
        }
        if (urbanization !== undefined) {
          const valueFilter: FiltersType["categoric"] = {
            [NewsFiltersFrontKey.buildingId]: {
              value: {
                value: urbanization.id,
                label: urbanization.name,
              },
            },
          };

          response = {
            ...response,
            [NewsFiltersEntityBackendKey.parcel]: {
              ...response[NewsFiltersEntityBackendKey.parcel],
              [NewsFiltersTypeFrontKey.categoric]: {
                ...response?.[NewsFiltersEntityBackendKey.parcel]?.[
                  NewsFiltersTypeFrontKey.categoric
                ],
                ...valueFilter,
              },
            },
          };
        }
      }
    }

    const filtersResponse = unifiedGptFiltersAdapter(response);

    store.dispatch(setUserInputSearch({ value: arg.search, showPill: false }));

    return { filtersRedux: filtersResponse, tabKey: tabKeyResponse, filtersAreAvailable };
  } else {
    return { filtersRedux: null, tabKey: tabKeyResponse, filtersAreAvailable };
  }
};
