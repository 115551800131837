import React, { useState } from "react";
import { CountryCodeKey, NewsFiltersEntityFrontKey, NewsFiltersFrontKey } from "@/keys";
import { useAppSelector } from "@/app/hooks";
import { Filters, selectAppConfig, selectFilters, selectLocations } from "@/redux/slices";
import { useLazyGetBuildingsQuery, useLazyGetZipCodesQuery } from "@/services";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useLocaleCountryCode } from "@/hooks";
import { AdvancedFilters } from "../../Filters";
import SelectFilter from "../../components/SelectFilter";

interface FiltersLocationProps {
  isAdvancedFilters: boolean;
  availableLocationFilters: {
    show: boolean;
    city: boolean;
    neighborhood: boolean;
    subNeighborhood: boolean;
    zipCode: boolean;
    urbanization: boolean;
  };
  advancedFilters: AdvancedFilters["location"]["filters"];
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
}

function FiltersLocation({
  isAdvancedFilters,
  availableLocationFilters,
  advancedFilters,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: FiltersLocationProps) {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const { cities, neighborhoods, subNeighborhoods } = useAppSelector(selectLocations);
  const { areFiltersAvailable, filtersByPortal } = useAppSelector(selectFilters);
  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>("");
  const [urbanizationInputValue, setUrbanizationInputValue] = useState<string>("");
  const { countryCode } = useLocaleCountryCode();
  const { portal } = useAppSelector(selectAppConfig);

  const reduxFilters = filtersByPortal[portal].filters;

  const [
    trigger,
    {
      isError: isErrorZipCodes,
      isLoading: isLoadingZipCodes,
      isFetching: isFetchingZipCodes,
      data: zipCodesData,
    },
  ] = useLazyGetZipCodesQuery();

  const [
    triggerBuilding,
    {
      isError: isErrorBuildings,
      isLoading: isLoadingBuildings,
      isFetching: isFetchingBuildings,
      data: buildingsData,
    },
  ] = useLazyGetBuildingsQuery();

  const handleChangeZipCodeFilter = debounce((zipCode: string) => {
    if (zipCode) {
      setZipCodeInputValue(zipCode);
    }
    if (zipCode.length > 2) {
      trigger({ zipCode, countryCode }, true);
    }
  }, 400);

  const handleChangeBuildingFilter = debounce((name: string) => {
    if (name) {
      setUrbanizationInputValue(name);
    }
    if (name.length > 2) {
      triggerBuilding({ name, type: "Urb", countryCode }, true);
    }
  }, 400);

  return (
    <div className="row px-1 pt-1">
      <div className="col-12">
        {availableLocationFilters.show && (
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-1" style={{ whiteSpace: "nowrap" }}>
                <h5 className="black fw-bold text-uppercase mb-0 mr-2">
                  {language.menuFilters.location}
                </h5>
                {/* <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} /> */}
              </div>
            </div>
            {advancedFilters[NewsFiltersFrontKey.cityId] && availableLocationFilters.city && (
              <SelectFilter
                isAdvancedFilters={isAdvancedFilters}
                filterKey={NewsFiltersFrontKey.cityId}
                entityKey={NewsFiltersEntityFrontKey.location}
                title={language.filters.city.title}
                placeholder={language.filters.city.placeholder}
                options={cities}
                isClearable={true}
                isDisabled={
                  !areFiltersAvailable || !!reduxFilters.location.categoric?.zipCodeId.value
                }
                value={reduxFilters.location.categoric?.cityId.value}
                setMenuState={setMenuState}
                menuState={menuState}
                setActiveSelect={setActiveSelect}
                activeSelect={activeSelect}
                containerFilterRef={containerFilterRef}
                setLocalFilters={setLocalFilters}
              />
            )}

            {countryCode === CountryCodeKey.PR ? (
              advancedFilters[NewsFiltersFrontKey.neighborhoodId] &&
              availableLocationFilters.neighborhood && (
                <SelectFilter
                  isAdvancedFilters={isAdvancedFilters}
                  filterKey={NewsFiltersFrontKey.neighborhoodId}
                  entityKey={NewsFiltersEntityFrontKey.location}
                  title={language.filters.neighborhood.title}
                  placeholder={language.filters.neighborhood.placeholder}
                  options={neighborhoods}
                  isClearable={true}
                  isDisabled={
                    !reduxFilters.location.categoric?.cityId.value || !neighborhoods.length
                  }
                  value={
                    reduxFilters.location.categoric?.subNeighborhoodId.value ??
                    reduxFilters.location.categoric?.neighborhoodId.value
                  }
                  setMenuState={setMenuState}
                  menuState={menuState}
                  setActiveSelect={setActiveSelect}
                  activeSelect={activeSelect}
                  containerFilterRef={containerFilterRef}
                  setLocalFilters={setLocalFilters}
                />
              )
            ) : (
              <>
                {advancedFilters[NewsFiltersFrontKey.neighborhoodId] &&
                  availableLocationFilters.neighborhood && (
                    <SelectFilter
                      isAdvancedFilters={isAdvancedFilters}
                      filterKey={NewsFiltersFrontKey.neighborhoodId}
                      entityKey={NewsFiltersEntityFrontKey.location}
                      title={language.filters.neighborhood.title}
                      placeholder={language.filters.neighborhood.placeholder}
                      options={neighborhoods}
                      isClearable={true}
                      isDisabled={
                        !reduxFilters.location.categoric?.cityId.value || !neighborhoods.length
                      }
                      value={reduxFilters.location.categoric?.neighborhoodId.value}
                      setMenuState={setMenuState}
                      menuState={menuState}
                      setActiveSelect={setActiveSelect}
                      activeSelect={activeSelect}
                      containerFilterRef={containerFilterRef}
                      setLocalFilters={setLocalFilters}
                    />
                  )}
                {advancedFilters[NewsFiltersFrontKey.subNeighborhoodId] &&
                  availableLocationFilters.subNeighborhood && (
                    <SelectFilter
                      isAdvancedFilters={isAdvancedFilters}
                      filterKey={NewsFiltersFrontKey.subNeighborhoodId}
                      entityKey={NewsFiltersEntityFrontKey.location}
                      title={language.filters.subNeighborhood.title}
                      placeholder={language.filters.subNeighborhood.placeholder}
                      options={subNeighborhoods}
                      isClearable={true}
                      isDisabled={
                        !reduxFilters.location.categoric?.neighborhoodId.value ||
                        !subNeighborhoods.length
                      }
                      value={reduxFilters.location.categoric?.subNeighborhoodId.value}
                      setMenuState={setMenuState}
                      menuState={menuState}
                      setActiveSelect={setActiveSelect}
                      activeSelect={activeSelect}
                      containerFilterRef={containerFilterRef}
                      setLocalFilters={setLocalFilters}
                    />
                  )}
              </>
            )}

            {advancedFilters[NewsFiltersFrontKey.zipCodeId] && availableLocationFilters.zipCode && (
              <SelectFilter
                isAdvancedFilters={isAdvancedFilters}
                filterKey={NewsFiltersFrontKey.zipCodeId}
                entityKey={NewsFiltersEntityFrontKey.location}
                title={language.filters.zipCode.title}
                placeholder={language.filters.zipCode.placeholder}
                options={
                  zipCodeInputValue.length > 2 && !isErrorZipCodes
                    ? zipCodesData?.map((zip) => ({
                        value: zip.id as string,
                        label: zip.zipCode as string,
                      }))
                    : []
                }
                isClearable={true}
                isDisabled={
                  (!!reduxFilters.location.categoric?.cityId.value &&
                    (Array.isArray(reduxFilters.location.categoric?.cityId.value) &&
                    reduxFilters.location.categoric?.cityId.value.length
                      ? true
                      : false)) ||
                  reduxFilters.location.categoric?.cityId.value
                    ? true
                    : false
                }
                value={reduxFilters.location.categoric?.zipCodeId.value}
                onInputChange={handleChangeZipCodeFilter}
                noOptionsMessage={({ inputValue }) =>
                  inputValue.length < 3 ? "Must be at least 3 characters" : undefined
                }
                isLoading={isLoadingZipCodes || isFetchingZipCodes}
                loadingMessage={() => "Loading zip codes..."}
                setMenuState={setMenuState}
                menuState={menuState}
                setActiveSelect={setActiveSelect}
                activeSelect={activeSelect}
                containerFilterRef={containerFilterRef}
                setLocalFilters={setLocalFilters}
              />
            )}
            {advancedFilters[NewsFiltersFrontKey.buildingId] &&
              availableLocationFilters.urbanization &&
              reduxFilters?.parcel?.categoric?.buildingId && (
                <SelectFilter
                  isAdvancedFilters={isAdvancedFilters}
                  filterKey={NewsFiltersFrontKey.buildingId}
                  entityKey={NewsFiltersEntityFrontKey.parcel}
                  title={language.filters.urbanization.title}
                  placeholder={language.filters.urbanization.placeholder}
                  options={
                    urbanizationInputValue.length > 2 && !isErrorBuildings
                      ? buildingsData?.map((building) => ({
                          value: building.id as string,
                          label: building.name as string,
                        }))
                      : []
                  }
                  isClearable={true}
                  value={reduxFilters.parcel.categoric?.buildingId.value}
                  onInputChange={handleChangeBuildingFilter}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue.length < 3 ? "Must be at least 3 characters" : undefined
                  }
                  isLoading={isLoadingBuildings || isFetchingBuildings}
                  loadingMessage={() => "Loading urbanizations..."}
                  setMenuState={setMenuState}
                  menuState={menuState}
                  setActiveSelect={setActiveSelect}
                  activeSelect={activeSelect}
                  containerFilterRef={containerFilterRef}
                  setLocalFilters={setLocalFilters}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
}
export default FiltersLocation;
