import type { PropertyTransaction } from "@/models";
import { PropertyTransactionBackend, responseTransactionAdapter } from "@/adapters";

export const responsePropertyTransactionsAdapter = (
  propertyTransactions: PropertyTransactionBackend[],
): PropertyTransaction[] => {
  const newTransactions: PropertyTransaction[] = [];

  if (propertyTransactions.length) {
    propertyTransactions.map((propertyTransaction) => {
      newTransactions.push({
        id: propertyTransaction.id,
        govtId: propertyTransaction.govtId,
        fincaNumber: propertyTransaction.fincaNumber,
        solar: propertyTransaction.solar,
        createdAt: propertyTransaction.createdAt,
        deletedAt: propertyTransaction.deletedAt,
        updatedAt: propertyTransaction.updatedAt,
        transaction: responseTransactionAdapter(propertyTransaction.transaction),
      });
    });
  }

  return newTransactions;
};
