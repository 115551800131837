export const capitalizeFirstLetter = ({ string }: { string: string }) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeFirstLetterOfEachWord = ({ string }: { string: string }) => {
  return string
    .split(" ")
    .map((word) => capitalizeFirstLetter({ string: word }))
    .join(" ");
};

export const httpToHttps = (url: string) => {
  return url.replace("http://", "https://");
};
