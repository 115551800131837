import { OrganizationKey } from "@/keys";
import { ComponentType, LazyExoticComponent, lazy } from "react";
import { getOrganization } from "./organizationsConfig";

const isRedAtlas = getOrganization().organization.key === OrganizationKey.redatlas;

const Home = lazy(() => import("@/views/pages/Home"));
const CorporateHome = lazy(() => import("@/views/pages/corporate/Home"));
const LearnMore = lazy(() => import("@/views/pages/corporate/learnMore/LearnMore"));
const AtlasApi = lazy(() => import("@/views/pages/corporate/atlasApi/AtlasApi.corporate"));
const About = lazy(() => import("@/views/pages/corporate/About"));
// const Careers = lazy(() => import("@/views/pages/corporate/Careers"));
// const Contact = lazy(() => import("@/views/pages/corporate/Contact"));
const Investors = lazy(() => import("@/views/pages/corporate/Investors"));
const NoticesAndPolicies = lazy(() => import("@/views/pages/corporate/NoticesAndPolicies"));

// S P E C I F I C   P O L I C I E S
const AccommodationsPolicy = lazy(
  () => import("@/views/pages/corporate/noticesPolicies/Acommodations"),
);
const CurrencyDisclaimer = lazy(
  () => import("@/views/pages/corporate/noticesPolicies/CurrencyDisclaimer"),
);
const IgaStatement = lazy(() => import("@/views/pages/corporate/noticesPolicies/IGAStatement"));
const PrivacyPolicy = lazy(() => import("@/views/pages/corporate/noticesPolicies/PrivacyPolicy"));
const RecruitingPolicy = lazy(
  () => import("@/views/pages/corporate/noticesPolicies/RecruitingPolicy"),
);
const LawEnforcement = lazy(() => import("@/views/pages/corporate/noticesPolicies/LawEnforcement"));
const SponsorContent = lazy(() => import("@/views/pages/corporate/noticesPolicies/SponsorContent"));
const SocialMedia = lazy(() => import("@/views/pages/corporate/noticesPolicies/SocialMedia"));
const TaxPolicy = lazy(() => import("@/views/pages/corporate/noticesPolicies/TaxPolicy"));
const TermsOfService = lazy(() => import("@/views/pages/corporate/noticesPolicies/TermsOfService"));

const Login = lazy(() => import("@/views/pages/corporate/auth/login/Login"));
const Register = lazy(() => import("@/views/pages/corporate/auth/register/Register"));

const TableView = lazy(() => import("@/views/pages/tableView/TableView"));
const UserSettings = lazy(() => import("@/views/pages/userSettings/UserSettings"));
const Valuation = lazy(() => import("@/views/pages/valuation/Valuation"));
const News = lazy(() => import("@/views/pages/news/News"));
const EmailVerification = lazy(() => import("@/views/pages/authentication/EmailVerification"));
const Payment = lazy(() => import("@/views/pages/payment/Payment"));
const PaymentSuccess = lazy(() => import("@/views/pages/payment/PaymentSuccess"));
const ForgotPassword = lazy(() => import("@/views/pages/corporate/auth/password/ForgotPassword"));
const RestorePassword = lazy(() => import("@/views/pages/corporate/auth/password/RestorePassword"));
const List = lazy(() => import("@/views/pages/lists/properties/List"));
const PropertyProfile = lazy(() => import("@/views/pages/property/PropertyProfile"));
const ListingProfile = lazy(() => import("@/views/pages/listing/redatlas/ListingProfile"));
const ListingReport = lazy(() => import("@/views/pages/reports/listing/ListingReport"));
const UnitReport = lazy(() => import("@/views/pages/reports/unit/UnitReport"));
const ListReport = lazy(() => import("@/views/pages/reports/list/ListReport"));
const Report = lazy(() => import("@/views/pages/reports/Report"));
const ListingProfileInvestpr = lazy(
  () => import("@/views/pages/listing/investpr/ListingProfile.investpr"),
);
const NewsProfile = lazy(() => import("@/views/pages/news/profile/NewsProfile"));

const id = ":id";
const unitId = ":unitId";
const parcelId = ":parcelId";
const reportId = ":reportId";
const listingId = ":listingId";
const listId = ":listId";

export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // If router is private, this is going to be true
  privateRoute?: boolean;
  // Sub routes
  // subRoutes?: Record<string, IRoute>;
  // Redirect path
  // redirect?: string;
}

const relativeAppPaths = () => {
  const list = (id: string) => {
    return `/list/${id}`;
  };
  const propertyProfile = (id: number | string) => {
    return `/properties/${id}`;
  };
  const listingProfile = (id: number | string) => {
    return `/listings/${id}`;
  };
  const newsProfile = (id: string) => {
    return `/news/${id}`;
  };
  const listingReport = (id: number | string) => {
    return `/report/listings/${id}`;
  };
  return { list, propertyProfile, listingProfile, newsProfile, listingReport };
};

const relativeCorporatePaths = () => {
  const newsProfile = (id: string) => {
    return `/public/news/${id}`;
  };
  return { newsProfile };
};

const relativeReportPaths = () => {
  const listingReport = ({ reportId, listingId }: { reportId: string; listingId: string }) => {
    return `/generate/reports/${reportId}/listings/${listingId}`;
  };
  const unitReport = ({
    reportId,
    parcelId,
    unitId,
  }: {
    reportId: string;
    parcelId: string;
    unitId: string;
  }) => {
    return `/generate/reports/${reportId}/properties/${parcelId}/units/${unitId}`;
  };
  const listReport = ({ reportId, listId }: { reportId: string; listId: string }) => {
    return `/generate/reports/${reportId}/list/${listId}`;
  };
  return { listingReport, unitReport, listReport };
};

const appPaths: Record<string, IRoute> = {
  home: { path: "/app", component: Home, privateRoute: false, exact: true },
  tableView: {
    path: "/tableView",
    component: Home,
    privateRoute: false,
    exact: false,
  },
  profile: { path: "/profile", component: UserSettings, privateRoute: true, exact: false },
  billing: { path: "/billing", component: UserSettings, privateRoute: true, exact: false },
  business: { path: "/business", component: UserSettings, privateRoute: true, exact: false },
  valuation: { path: "/valuation", component: Valuation, privateRoute: true, exact: false },
  news: { path: "/news", component: News, privateRoute: false, exact: false },
  emailVerification: {
    path: "/emailVerification",
    component: EmailVerification,
    privateRoute: false,
    exact: false,
  },
  propertiesList: {
    path: relativeAppPaths().list(id),
    component: List,
    privateRoute: true,
    exact: false,
  },
  propertyProfile: {
    path: relativeAppPaths().propertyProfile(id),
    component: PropertyProfile,
    privateRoute: false,
    exact: false,
  },
  listingProfile: {
    path: relativeAppPaths().listingProfile(id),
    component:
      getOrganization().organization.key !== OrganizationKey.redatlas
        ? ListingProfileInvestpr
        : ListingProfile,
    privateRoute: false,
    exact: false,
  },
  // listingReport: {
  //   path: relativeAppPaths().listingReport(id),
  //   component: Report,
  //   privateRoute: true,
  //   exact: false,
  // },
  newsProfile: {
    path: relativeAppPaths().newsProfile(id),
    component: NewsProfile,
    privateRoute: false,
    exact: false,
  },

  // Corporate Paths

  indexHome: { path: "/", component: CorporateHome, privateRoute: false, exact: true },
  learnMore: {
    path: "/learn-more",
    component: LearnMore,
    privateRoute: false,
    exact: true,
  },
  atlasApi: {
    path: "/atlas-api",
    component: AtlasApi,
    privateRoute: false,
    exact: true,
  },
  about: { path: "/about", component: About, privateRoute: false, exact: true },
  // careers: { path: "/careers", component: Careers, privateRoute: false, exact: true },
  // contact: { path: "/contact", component: Contact, privateRoute: false, exact: true },
  investors: { path: "/investors", component: Investors, privateRoute: false, exact: true },
  noticesAndPolicies: {
    path: "/noticesAndPolicies",
    component: NoticesAndPolicies,
    privateRoute: false,
    exact: true,
  },
  accommodationsPolicy: {
    path: "/Acommodations-policy",
    component: AccommodationsPolicy,
    privateRoute: false,
    exact: true,
  },
  currencyDisclaimer: {
    path: "/currency-data-disclaimer",
    component: CurrencyDisclaimer,
    privateRoute: false,
    exact: true,
  },
  igaStatement: {
    path: "/iga-statement",
    component: IgaStatement,
    privateRoute: false,
    exact: true,
  },
  privacyPolicy: {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    privateRoute: false,
    exact: true,
  },
  recruitingPolicy: {
    path: "/recruiting-policy",
    component: RecruitingPolicy,
    privateRoute: false,
    exact: true,
  },
  lawEnforcement: {
    path: "/law-enforcement",
    component: LawEnforcement,
    privateRoute: false,
    exact: true,
  },
  sponsorContent: {
    path: "/sponsor-content",
    component: SponsorContent,
    privateRoute: false,
    exact: true,
  },
  socialMedia: {
    path: "/social-media",
    component: SocialMedia,
    privateRoute: false,
    exact: true,
  },
  taxPolicy: {
    path: "/tax-policy",
    component: TaxPolicy,
    privateRoute: false,
    exact: true,
  },
  termsOfService: {
    path: "/terms-of-service",
    component: TermsOfService,
    privateRoute: false,
    exact: true,
  },
  // privacyPolicy: {
  //   path: "/privacyPolicy",
  //   component: PrivacyPolicy,
  //   privateRoute: false,
  //   exact: true,
  // },
  signIn: { path: "/signIn", component: Login, privateRoute: false, exact: true },
  signUp: { path: "/signUp", component: Register, privateRoute: false, exact: true },
  forgotPassword: {
    path: "/forgotPassword",
    component: ForgotPassword,
    privateRoute: false,
    exact: false,
  },
  restorePassword: {
    path: "/restorePassword",
    component: RestorePassword,
    privateRoute: false,
    exact: false,
  },
  setPassword: {
    path: "/setPassword",
    component: RestorePassword,
    privateRoute: false,
    exact: false,
  },
  payment: { path: "/payment", component: Payment, privateRoute: false, exact: false },
  success: { path: "/success", component: PaymentSuccess, privateRoute: false, exact: false },

  newsProfilePublic: {
    path: relativeCorporatePaths().newsProfile(id),
    component: NewsProfile,
    privateRoute: false,
    exact: false,
  },
};

const reportPaths: Record<string, IRoute> = {
  listingReport: {
    path: relativeReportPaths().listingReport({ reportId, listingId }),
    component: ListingReport,
    privateRoute: true,
    exact: false,
  },
  unitReport: {
    path: relativeReportPaths().unitReport({ reportId, parcelId, unitId }),
    component: UnitReport,
    privateRoute: true,
    exact: false,
  },
  listReport: {
    path: relativeReportPaths().listReport({ reportId, listId }),
    component: ListReport,
    privateRoute: true,
    exact: false,
  },
};

export type AppPaths = typeof appPaths;
export type ReportPaths = typeof reportPaths;

export { appPaths, reportPaths, relativeAppPaths, relativeCorporatePaths, relativeReportPaths };
