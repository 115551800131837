import { AmenityBackendKeys } from "./amenity-backend.key";
import { CityBackendKeys } from "./city-backend.key";
import { NeighborhoodBackendKeys } from "./neighborhood-backend.key";
import { ParcelBackendKeys } from "./parcel-backend.key";
import { PropertyBackendKeys } from "./property-backend.key";
import { SourceBackendKeys } from "./source-backend.key";
import { TransactionBackendKeys } from "./transaction-backend.key";

export enum ListingBackendKey {
  genericListingId = "genericListingId",
  uniqueListingId = "uniqueListingId",
  location = "location",
  centroidLocation = "centroidLocation",
  title = "title",
  url = "url",
  price = "price",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  priceString = "priceString",
  isForRent = "isForRent",
  address = "address",
  addressJSON = "addressJSON",
  builtArea = "builtArea",
  lotArea = "lotArea",
  toilets = "toilets",
  rooms = "rooms",
  parkingSpaces = "parkingSpaces",
  buildingDate = "buildingDate",
  createdAt = "createdAt",
  deactivatedAt = "deactivatedAt",
  descriptionText = "descriptionText",
  active = "active",
  propertyType = "propertyType",
  apartmentType = "apartmentType",
  photos = "photos",
  source = "source",
  brokerName = "brokerName",
  phones = "phones",
  brokersInfo = "brokersInfo",
  dataset = "dataset",
  daysOnMarket = "daysOnMarket",
  propertyId = "propertyId",
  administrationFee = "administrationFee",
  luxuryScore = "luxuryScore",
  yearBuilt = "yearBuilt",
  jsonGPT = "jsonGPT",
  inBuilding = "inBuilding",
  isDuplex = "isDuplex",
  isExterior = "isExterior",
  isNew = "isNew",
  isResidentialComplex = "isResidentialComplex",
  hasPublicServices = "hasPublicServices",
  administrationIncluded = "administrationIncluded",
  hasKidsPlayZone = "hasKidsPlayZone",
  hasReception = "hasReception",
  hasSoccerField = "hasSoccerField",
  officeArea = "officeArea",
  hasDeposit = "hasDeposit",
  hasGarage = "hasGarage",
  hasStorageSpace = "hasStorageSpace",
  attachments = "attachments",
  featuresTracking = "featuresTracking",
  sourceSpecificInfo = "sourceSpecificInfo",
  isOutlier = "isOutlier",
  status = "status",
  score = "score",
}

export type ListingBackendKeys = keyof typeof ListingBackendKey;

/**
 * Llaves que acepta el backend en la opción de `sortBy`.
 * Las llaves comentadas funcionan, para habilitarlas bastaría con descomentarlas.
 */
export enum ListingBackendSortKey {
  price = "price",
  builtArea = "builtArea",
  createdAt = "createdAt",
  lotArea = "lotArea",
  score = "score",
  // isForRent = "isForRent",
  // active = "active",
  // propertyType = "propertyType",
  // cityId = "cityId",
  // neighborhoodId = "neighborhoodId",
  // extractedAt = "extractedAt",
  // deactivatedAt = "deactivatedAt",
  // lotArea = "lotArea",
  // parkingSpaces = "parkingSpaces",
  // rooms = "rooms",
  // toilets = "toilets",
  // genericListingId = "genericListingId",
  // title = "title",
  // sourceId = "sourceId",
  // parcelId = "parcelId",
}

export enum ListingBackendExpandKey {
  parcel = "parcel",
  source = "source",
  city = "city",
  neighborhood = "neighborhood",
  subNeighborhood = "subNeighborhood",
  amenities = "amenities",
  property = "property",
  transactions = "transactions",
}

export type ListingBackendExpandKeys = keyof typeof ListingBackendExpandKey;

export type ListingBackendInclude =
  | (ListingBackendKeys | "*")
  | `${ListingBackendExpandKeys}.${ParcelBackendKeys}`
  | `${ListingBackendExpandKeys}.${PropertyBackendKeys}`
  | `${ListingBackendExpandKeys}.${SourceBackendKeys}`
  | `${ListingBackendExpandKeys}.${CityBackendKeys}`
  | `${ListingBackendExpandKeys}.${AmenityBackendKeys}`
  | `${ListingBackendExpandKeys}.${NeighborhoodBackendKeys}`
  | `${ListingBackendExpandKeys}.${TransactionBackendKeys}`;
