import { GeometryTypesKey } from "@/keys";
import { GeometricFilter } from "@/models";

type ExtendViewportBounds = {
  bounds: {
    ne: { lat: number; lng: number };
    nw: { lat: number; lng: number };
    se: { lat: number; lng: number };
    sw: { lat: number; lng: number };
  };
  type?: "expanded" | "divided" | "exact";
};

type NewBounds = {
  ne: { lat: number; lng: number };
  nw: { lat: number; lng: number };
  se: { lat: number; lng: number };
  sw: { lat: number; lng: number };
};

export const transformBounds = ({ bounds }: { bounds?: google.maps.LatLngBounds }) => {
  const newBounds: NewBounds = {
    ne: {
      lat: bounds?.getNorthEast().lat() as number,
      lng: bounds?.getNorthEast().lng() as number,
    },
    nw: {
      lat: bounds?.getNorthEast().lat() as number,
      lng: bounds?.getSouthWest().lng() as number,
    },
    se: {
      lat: bounds?.getSouthWest().lat() as number,
      lng: bounds?.getNorthEast().lng() as number,
    },
    sw: {
      lat: bounds?.getSouthWest().lat() as number,
      lng: bounds?.getSouthWest().lng() as number,
    },
  };
  return newBounds;
};

export const recalculateViewport = ({ bounds, type = "expanded" }: ExtendViewportBounds) => {
  const deltaLat = 0.0025;
  const deltaLng = 0.005;

  const polygonsMap: { [key: string]: GeometricFilter } = {
    expanded: {
      type: GeometryTypesKey.polygon,
      coordinates: [
        [
          [bounds.sw.lng - deltaLng, bounds.ne.lat + deltaLat],
          [bounds.sw.lng - deltaLng, bounds.sw.lat - deltaLat],
          [bounds.ne.lng + deltaLng, bounds.sw.lat - deltaLat],
          [bounds.ne.lng + deltaLng, bounds.ne.lat + deltaLat],
          [bounds.sw.lng - deltaLng, bounds.ne.lat + deltaLat],
        ],
      ],
    },
    divided: {
      type: GeometryTypesKey.polygon,
      coordinates: [
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.sw.lng, bounds.sw.lat],
          [bounds.ne.lng - (bounds.ne.lng - bounds.sw.lng) / 2, bounds.sw.lat],
          [bounds.ne.lng - (bounds.ne.lng - bounds.sw.lng) / 2, bounds.ne.lat],
          [bounds.sw.lng, bounds.ne.lat],
        ],
      ],
    },
    exact: {
      type: GeometryTypesKey.polygon,
      coordinates: [
        [
          [bounds.sw.lng, bounds.ne.lat],
          [bounds.sw.lng, bounds.sw.lat],
          [bounds.ne.lng, bounds.sw.lat],
          [bounds.ne.lng, bounds.ne.lat],
          [bounds.sw.lng, bounds.ne.lat],
        ],
      ],
    },
  };

  return polygonsMap[type];
};
