import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "fffff7cb-8a33-6abd-91d6-18fe1dd0d4bd",
    address: "BO GALATEO BAJO CARR 474 KM.HM 3.9 INTERIOR ISABELA PR",
    addressJSON: {
      id: "fffff7cb-8a33-6abd-91d6-18fe1dd0d4bd",
      zipCode: null,
      dataType: "unit",
      original: "BO GALATEO BAJO CARR 474 KM.HM 3.9 INTERIOR ISABELA PR",
      sourceId: "faf69cc8-34a2-002a-7d4e-05d2cc329082",
      unitType: {
        value: "Unit",
        status: "plausible",
      },
      streetName: {
        value: "Carretera 474",
        method: "low_partial_match",
        status: "unlikely",
        street_polygone_distance: null,
      },
      unitNumber: {
        value: "39",
        status: "plausible",
      },
      buildingName: null,
      streetNumber: {
        value: "474",
        status: "plausible",
      },
      joinedAddress: "474 Carretera 474, Unit 39, Galateo Bajo, Isabela, Puerto Rico",
    },
    builtArea: "1088.65",
    transactions: [],
    govtId: "025-030-031-86-000",
    city: null,
    neighborhood: null,
    parcel: {
      address: "474 Carretera 474",
      addresses: [],
      id: "4492ee2b-da13-db18-22b0-30e46ba9293e",
      name: null,
      photos: [
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/170067059994195376bff1dc732795b94.png",
      ],
      sectorId: "1ba8045f-891f-feda-4373-1fed6b4b5c89",
      subsectorId: "d9ec99dc-ee87-5a26-6cb7-00a1d9d8d75a",
      city: null,
      neighborhood: null,
      subNeighborhood: null,
      listings: [],
      permits: [],
      tenants: [],
      properties: [],
      zipCode: null,
    },
    taxes: null,
    listings: null,
    unitType: "Unit",
    unitNumber: "39",
    lastTransactionPrice: 0,
    lastTransactionTotalAreaPricePerSqm: 0,
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    parcel: {
      ...value.parcel,
      photos: [getRandomImage()],
    },
  }));
