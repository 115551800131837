import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  City,
  CityAvailableValuation,
  CountryOptionType,
  Neighborhood,
  SubNeighborhood,
} from "@/models";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface LocationState {
  cities: City[];
  neighborhoods: Neighborhood[];
  subNeighborhoods: SubNeighborhood[];
  countries: CountryOptionType[];
  citiesAvailableValuations: CityAvailableValuation[];
}

// Define the initial state using that type
const initialState: LocationState = {
  cities: [],
  neighborhoods: [],
  subNeighborhoods: [],
  countries: [],
  citiesAvailableValuations: [],
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<{ cities: City[] }>) => {
      state.cities = action.payload.cities;
    },
    setNeighborhoods: (state, action: PayloadAction<{ neighborhoods: Neighborhood[] }>) => {
      state.neighborhoods = action.payload.neighborhoods;
    },
    updateNeighborhoods: (state, action: PayloadAction<{ neighborhoods: Neighborhood[] }>) => {
      state.neighborhoods = action.payload.neighborhoods;
    },
    setSubNeighborhoods: (
      state,
      action: PayloadAction<{ subNeighborhoods: SubNeighborhood[] }>,
    ) => {
      state.subNeighborhoods = action.payload.subNeighborhoods;
    },
    setCountries: (state, action: PayloadAction<{ countries: CountryOptionType[] }>) => {
      state.countries = action.payload.countries;
    },
    setCitiesAvailableValuations: (
      state,
      action: PayloadAction<{ citiesAvailableValuations: CityAvailableValuation[] }>,
    ) => {
      state.citiesAvailableValuations = action.payload.citiesAvailableValuations;
    },
  },
});

// These actions will dispatch in the app
export const {
  setCities,
  setNeighborhoods,
  updateNeighborhoods,
  setSubNeighborhoods,
  setCountries,
  setCitiesAvailableValuations,
} = locationsSlice.actions;

export const selectLocations = (state: RootState) => state.locations;

export default locationsSlice.reducer;
