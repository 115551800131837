export enum TimeOnMarketKey {
  lotValue = "Lot Value",
  pricePerAcre = "Price per Acre",
  builtValue = "Built Value",
  pricePerSqft = "Price per Sqft",
  annualRent = "Annual Rent",
  rentPerSqft = "Rent per Sqft",
  rentToValue = "Rent to Value",
  totalValue = "Total Value",
}

export enum TimeOnMarketDataKey {
  oneMonth = "1 Month",
  sixMonths = "6 Months",
  twelveMonths = "12 Months",
  certainty = "Certainty",
}

export enum CurrentValuationKey {
  totalValue = "Total Value",
  totalValuePer = "Total Value Per",
  builtValue = "Built Value",
  builtValuePer = "Built Value Per",
  lotValue = "Lot Value",
  lotValuePer = "Lot Value Per",
  rentPerYear = "Rent per Year",
  rentPerYearPer = "Rent per Year per",
}
