/**
 * Column key sorted alphabetically
 */
export enum ColumnKey {
  active = "active",
  address = "address",
  asking_price = "asking_price",
  base_rent = "base_rent",
  bathrooms = "bathrooms",
  bedrooms = "bedrooms",
  category = "category",
  createdAt = "createdAt",
  data_source = "data_source",
  date = "date",
  deed = "deed",
  exemption = "exemption",
  exoneration = "exoneration",
  full_address = "full_address",
  garages = "garages",
  international_phone_number = "international_phone_number",
  land = "land",
  land_area_sqm = "land_area_sqm",
  lease_link = "lease_link",
  listing_link = "listing_link",
  listing_picture_url = "listing_picture_url",
  listing_sector = "listing_sector",
  listing_type_id = "listing_type_id",
  machinery = "machinery",
  micro_market = "micro_market",
  name = "name",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  lotArea = "lotArea",
  amenitiesCount = "amenitiesCount",
  priceString = "priceString",
  property_picture_url = "property_picture_url",
  rentable_area_sqm = "rentable_area_sqm",
  status = "status",
  structure = "structure",
  sub_market = "sub_market",
  micro_sector = "micro_sector",
  sub_sector = "sub_sector",
  sectors = "sectors",
  taxable = "taxable",
  tenantName = "tenantName",
  title = "title",
  total_valuation = "total_valuation",
  transaction_price = "transaction_price",
  transaction_price_psqft = "transaction_price_psqft",
  transactionType = "transactionType",
  buyer = "buyer",
  seller = "seller",
  url = "url",
  unitNumber = "unitNumber",
  unitType = "unitType",
  cadaster = "cadaster",
  listingsCount = "listingsCount",
  permitsCount = "permitsCount",
  transactionsCount = "transactionsCount",
  viewUnit = "viewUnit",
  link = "link",
  agency = "agency",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
}
