import { getRandomImage } from "./fakeImageGenerator";

export default Array(10)
  .fill({
    id: "ffec356e-3f9c-1a38-e665-ef31d6534962",
    name: "Westside Fitness Club Bayamón",
    url: "https://maps.google.com/?cid=17800793311458302303",
    pictures: null,
    sector: {
      name: "Recreation",
    },
    subsector: {
      name: "Fitness, Courts, and Spa Facilities",
    },
    microsector: {
      name: "Health & Fitness Center / Sports Club / Gym",
    },
    source: {
      name: "Google Maps",
      logoUrl:
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/45efe879136269de0dde07439e16615a1519856215226.jpg",
    },
    city: null,
    parcel: {
      address: "Urbanizacion Santa Rosa , 226 Avenida Boundary",
      addresses: [],
      id: "0108a32b-350c-b686-dd07-c7a31367a751",
      name: "Maribel Plaza",
      photos: [
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16733841396904ec1092c014ad6ed3b20.jpeg",
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16733841400953602b8239c49dd3f9d1f.png",
      ],
      sectorId: "7c4d495b-94c6-b5ae-b3c1-55a10d5c1d84",
      subsectorId: "99bbfd49-0841-ffac-4cc9-bf53a1254df4",
      city: null,
      neighborhood: null,
      subNeighborhood: null,
      listings: [],
      permits: [],
      tenants: [],
      properties: [],
      zipCode: null,
    },
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
    parcel: {
      ...value.parcel,
      photos: [getRandomImage()],
    },
  }));
