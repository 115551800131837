import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppSelector } from "@/app/hooks";
import { selectAppConfig } from "@/redux/slices";
import { useGetListingPricePerSqftStatisticQuery } from "@/services";
import { thousandSeparatorWhithDecimal } from "@/utilities";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import ModalNewsStatistics from "./ModalNewsStatistics";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useLocaleCountryCode } from "@/hooks";
import { countries } from "@/configs";
import { AnalyticsPeriodsStatusKey } from "@/keys";

interface TypesProps {
  id: string;
  name: string;
  description: {
    title: string;
    period: string;
  };
  currentPrice: string;
  difference: string;
  percent: string;
  status: keyof typeof AnalyticsPeriodsStatusKey | null;
  omit?: boolean;
}

function NewsStatistics() {
  const { t } = useTranslation(["translation"]);
  const language = t("translation:footer", { returnObjects: true });
  const { windowWidth } = useAppSelector(selectAppConfig);
  const { alertColors, primary, defaultColors } = useTheme();
  const [listingsTypes, setListingsTypes] = useState<TypesProps[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [serieId, setSerieId] = useState<string | null>(null);
  const [serieTitle, setSerieTitle] = useState<string>("");
  const { languageCode, countryCode } = useLocaleCountryCode();

  const types: TypesProps[] = [
    {
      id: "ALL",
      name: language.statistics.px[countryCode].name,
      description: {
        title: language.statistics.px[countryCode].title,
        period: t("translation:footer.statistics.period", {
          count: 7,
        }),
      },
      currentPrice: "",
      difference: "",
      percent: "",
      status: null,
    },
    {
      id: "BUSINESS",
      name: language.statistics.rx[countryCode].name,
      description: {
        title: language.statistics.rx[countryCode].title,
        period: t("translation:footer.statistics.period", {
          count: 7,
        }),
      },
      currentPrice: "",
      difference: "",
      percent: "",
      status: null,
      omit: true,
    },
    {
      id: "OFFICE",
      name: language.statistics.ox[countryCode].name,
      description: {
        title: language.statistics.ox[countryCode].title,
        period: t("translation:footer.statistics.period", {
          count: 7,
        }),
      },
      currentPrice: "",
      difference: "",
      percent: "",
      status: null,
      omit: true,
    },
  ];

  const handleChangeModal = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }
    setOpenModal(!openModal);
  };

  const handleStatistic = ({ id, title }: { id: string; title: string }) => {
    setSerieId(id);
    setSerieTitle(title);
    handleChangeModal();
  };

  const {
    data: listingsPricesPerSqft,
    error: errorListingsPricesPerSqft,
    isLoading: isLoadingListingsPricesPerSqft,
    isFetching: isFetchingListingsPricesPerSqft,
    refetch,
  } = useGetListingPricePerSqftStatisticQuery(
    {
      countryCode,
      name: "ListingsIDX",
    },
    { skip: !countries[countryCode].hasAccessToPlatform },
  );

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 24 * 60 * 60 * 1000); // 24 horas en milisegundos

    return () => {
      clearInterval(timer);
      setListingsTypes([]);
    };
  }, [refetch, countryCode]);

  useEffect(() => {
    if (listingsPricesPerSqft?.series) {
      types.map((listingType) => {
        const findType = listingsPricesPerSqft?.series.find((item) => item.id === listingType.id);
        if (findType?.filters) {
          listingType.currentPrice = thousandSeparatorWhithDecimal(
            Number(findType?.filters?.currentPrice).toFixed(2),
          );
          listingType.difference = thousandSeparatorWhithDecimal(
            Number(
              (findType?.filters?.currentPrice - findType?.filters?.["7days"]).toFixed(2),
            ).toFixed(2),
          );
          listingType.percent = `${thousandSeparatorWhithDecimal(
            Number(
              (
                ((findType?.filters?.currentPrice - findType?.filters?.["7days"]) /
                  findType?.filters?.["7days"]) *
                100
              ).toFixed(2),
            ).toFixed(2),
          )}%`;
          listingType.status =
            Number((findType?.filters?.currentPrice - findType?.filters?.["7days"]).toFixed(2)) ===
            0
              ? AnalyticsPeriodsStatusKey.neutral
              : Number(
                  (findType?.filters?.currentPrice - findType?.filters?.["7days"]).toFixed(2),
                ) > 0
              ? AnalyticsPeriodsStatusKey.positive
              : AnalyticsPeriodsStatusKey.negative;
        }
      });
      setListingsTypes(types);
    }
  }, [listingsPricesPerSqft?.series, languageCode]);

  return (
    <>
      <div className="mr-1 d-flex align-items-center">
        {isLoadingListingsPricesPerSqft || isFetchingListingsPricesPerSqft ? (
          <Spinner style={{ color: primary.color }} size="sm" className="mx-1" />
        ) : null}
        {listingsPricesPerSqft?.series && listingsTypes.length
          ? listingsTypes.map((item) => {
              if (!item.omit)
                return (
                  <div
                    className="btn-footer-navbar statistics-footer-navbar"
                    key={item.id}
                    onClick={() =>
                      handleStatistic({
                        id: item.id,
                        title: `${item.name} - ${item.description.title}`,
                      })
                    }>
                    <div
                      style={{
                        lineHeight: "18px",
                      }}
                      id={item.id}
                      className={classNames(
                        `cursor-pointer d-flex align-items-center justify-content-center`,
                        {
                          ["flex-column"]: windowWidth <= 1450,
                        },
                      )}>
                      <div className="mr-05 fw-bold">{item.name}</div>
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            color: defaultColors.color,
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                          }}
                          className="fw-bold mr-05">
                          {`$${item.currentPrice}`}
                        </div>
                        <div
                          style={{
                            color:
                              item.status === AnalyticsPeriodsStatusKey.positive
                                ? alertColors.success
                                : item.status === AnalyticsPeriodsStatusKey.negative
                                ? alertColors.danger
                                : primary.color,
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                          }}
                          className="fw-bold">
                          {`(${item.percent})`}
                        </div>
                      </div>
                    </div>
                    <UncontrolledTooltip placement="top" target={item.id}>
                      {`${item.description.title}. (${item.description.period})`}
                    </UncontrolledTooltip>
                  </div>
                );
            })
          : null}
      </div>

      {openModal ? (
        <ModalNewsStatistics
          openModal={openModal}
          toggleModal={handleChangeModal}
          serieId={serieId}
          serieTitle={serieTitle}
        />
      ) : null}
    </>
  );
}

export default NewsStatistics;
