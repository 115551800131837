import { useAppSelector } from "@/app/hooks";

import { NewsFiltersEntityFrontKey } from "@/keys";
import { selectAppConfig, selectFilters } from "@/redux/slices";
import { useMemo } from "react";

export const useAvailableFilters = () => {
  const {
    app: { portal },
    filters: { filtersByPortal },
  } = useAppSelector((state) => ({
    app: selectAppConfig(state),
    filters: selectFilters(state),
  }));

  type OnlyKeyEntityFilter = keyof typeof NewsFiltersEntityFrontKey;

  const availableFilters = useMemo(() => {
    return Object.keys(NewsFiltersEntityFrontKey).reduce((acc, key) => {
      acc[key as OnlyKeyEntityFilter] = !!Object.keys(
        filtersByPortal[portal].filters[key as OnlyKeyEntityFilter],
      ).length;
      return acc;
    }, {} as { [key in OnlyKeyEntityFilter]: boolean });
  }, [filtersByPortal, portal]);

  return { availableFilters };
};
