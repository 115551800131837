import moment from "moment";

type GetDaysProps = {
  type: "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "quarters" | "years";
  value: number;
};

export const getDays = ({ type, value }: GetDaysProps) => {
  const startDate = moment().subtract(value, type);
  const days = moment().diff(startDate, "days");

  return days;
};
