import {
  DataStatisticsListingPricePerSqftBackend,
  StatisticsListingPricePerSqftBackend,
} from "@/adapters";
import { store } from "@/app/store";
import { themes, ThemeType } from "@/components/styledComponents/themes/redAtlas/theme";
import { StatisticsFiltersBackendKey } from "@/keys";
import { AreaChartOptions } from "@/models";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";

export const responseListingsPricePerSqftStatisticsAdapter = (
  data: StatisticsListingPricePerSqftBackend,
): AreaChartOptions => {
  const { themeName, organizationTheme } = store.getState().app;
  const activeTheme = themes[organizationTheme].find((theme) => theme.themeName === themeName);

  const newData: AreaChartOptions = {
    series: [],
    colors: [activeTheme?.primary.color as ThemeType["primary"]["color"]],
  };

  if (Object.keys(data).length) {
    Object.keys(data).map((key) => {
      const dataSerie: DataStatisticsListingPricePerSqftBackend[] = [];

      if (data[key as keyof typeof data] !== null) {
        data[key as keyof typeof data].data.map((d) => {
          dataSerie.push({
            x: d.x,
            y: Number(d.y).toFixed(2),
          });
        });

        newData.series.push({
          id: key.toUpperCase(),
          data: dataSerie,
          updatedAt: data[key as keyof typeof data].updatedAt,
          filters: {
            currentPrice: data[key as keyof typeof data].periods["currentPrice"],
            [StatisticsFiltersBackendKey["7days"]]: data[key as keyof typeof data].periods["7days"],
            [StatisticsFiltersBackendKey["1month"]]:
              data[key as keyof typeof data].periods["1month"],
            [StatisticsFiltersBackendKey["3months"]]:
              data[key as keyof typeof data].periods["3months"],
            [StatisticsFiltersBackendKey["6months"]]:
              data[key as keyof typeof data].periods["6months"],
            [StatisticsFiltersBackendKey["1year"]]: data[key as keyof typeof data].periods["1year"],
          },
          color:
            key === "all"
              ? (activeTheme?.secondary.color as ThemeType["secondary"]["color"])
              : listingPropertyTypeColorsMapper({
                  propertyTypeName: key.toUpperCase(),
                  propertyTypeColor: activeTheme?.listingColors
                    .propertyType as ThemeType["listingColors"]["propertyType"],
                }),
        });
        newData.colors.push(
          key === "all"
            ? (activeTheme?.secondary.color as ThemeType["secondary"]["color"])
            : listingPropertyTypeColorsMapper({
                propertyTypeName: key.toUpperCase(),
                propertyTypeColor: activeTheme?.listingColors
                  .propertyType as ThemeType["listingColors"]["propertyType"],
              }),
        );
      }
    });
  }

  return newData;
};
