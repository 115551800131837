import React from "react";
import { useTheme } from "styled-components";
import { lighten } from "polished";
import { baseStyles } from "@/components/styledComponents";
import { AlertTriangle } from "react-feather";

type AnalyticsEmptyProps = {
  title: string;
  description?: string;
  height?: string;
};

function AnalyticsEmpty({ title, description, height }: AnalyticsEmptyProps) {
  const { primary } = useTheme();

  return (
    <div
      style={{
        height: height ?? "305.9px",
        width: "100%",
        backgroundColor: lighten(0.6, primary.color),
        borderRadius: baseStyles.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <div className="d-flex flex-column align-items-center">
        <AlertTriangle size={22} style={{ color: primary.color }} className="mb-1" />
        <div className="fw-bold" style={{ color: primary.color }}>
          {title}
        </div>
        {description && <p className="mb-0">{description}</p>}
      </div>
    </div>
  );
}

export default AnalyticsEmpty;
