import React from "react";
import { History } from "history";
import ImpellerNavbar from "./ImpellerNavbar";
import RealStateNavbar from "./RealStateNavbar";
import UserNavbar from "./userNavbar/UserNavbar";
import PropertyListNavbar from "./PropertyListNavbar";
import ContactNavbar from "./ContactNavbar";
import { AnimatedNavbarCard, sizeViewport } from "@/components/styledComponents";
import { useLocaleCountryCode } from "@/hooks";
import { countries } from "@/configs";
import classNames from "classnames";
import CountryDropdown from "../../corporateSite/navbar/languageDropdowns/CountryDropdown";

type MenuNavbarProps = {
  history: History;
  match: { path: string; id: string };
  windowWidth: number;
  clickBlocked: () => void;
  portal: string;
};

function MenuNavbar({ history, match, windowWidth, clickBlocked, portal }: MenuNavbarProps) {
  const isMobile = windowWidth < sizeViewport.laptop;
  const { countryCode } = useLocaleCountryCode();
  return (
    <div>
      <ul className="nav d-flex flex-nowrap">
        {/* ------- Property List Navbar ------- */}
        {!isMobile && countries[countryCode].lists && (
          <PropertyListNavbar
            windowWidth={windowWidth}
            clickBlocked={clickBlocked}
            portal={portal}
            history={history}
          />
        )}

        {/* ------- Impeller Navbar (investpr) ------- */}
        <ImpellerNavbar windowWidth={windowWidth} />

        {/* ------- Real State Navbar (investpr) ------- */}
        <RealStateNavbar windowWidth={windowWidth} />

        {/* ------- Contact Navbar (investpr) ------- */}
        <ContactNavbar windowWidth={windowWidth} />

        {/* ------- User Navbar ------- */}
        <UserNavbar
          windowWidth={windowWidth}
          match={match}
          history={history}
          clickBlocked={clickBlocked}
        />
      </ul>
    </div>
  );
}

export default MenuNavbar;
