import React, { useState } from "react";
import { DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from "reactstrap";
import { MessageSquare, FileText } from "react-feather";
import ReactCountryFlag from "react-country-flag";
import notImg from "@/assets/img/slider/notImg.jpg";
import { getOrganization } from "@/configs/organizationsConfig";
import UserLanguageInvestpr from "./UserLanguage.investpr";
import { useTranslation } from "react-i18next";
import { useLocaleCountryCode } from "@/hooks";
import { appPaths } from "@/configs";

function MenuUserInvestpr() {
  const [openMenuUser, setOpenMenuUser] = useState(false);
  const [openDropdownLanguage, setOpenDropdownLanguage] = useState(false);
  const [valueLanguage, setValueLanguage] = useState("");
  const { t, i18n } = useTranslation("translation");
  const language = t("navbar.menuNavbar.menuUser", { returnObjects: true });

  const { countryCode } = useLocaleCountryCode();

  const toggleMenuUser = () => {
    setOpenMenuUser(!openMenuUser);
  };

  const toggleMenuUserTrue = () => {
    setOpenMenuUser(true);
  };

  const onMouseEnter = () => {
    setOpenDropdownLanguage(true);
  };

  const onMouseLeave = () => {
    setOpenDropdownLanguage(false);
  };

  const handleLanguage = (languageCode, countryCode) => {
    setOpenMenuUser(true);
    i18n.changeLanguage(`${languageCode}-${countryCode}`);
  };

  return (
    <Dropdown
      isOpen={openMenuUser}
      toggle={openDropdownLanguage ? toggleMenuUserTrue : toggleMenuUser}>
      <DropdownToggle
        tag="a"
        className="nav-link position-relative d-flex align-items-center p-0 ml-1">
        <span data-tour="user">
          <img
            src={
              getOrganization().menu.menuUser.userPhoto
                ? getOrganization().menu.menuUser.userPhoto
                : notImg
            }
            onError={(e) => {
              e.target.src = notImg;
            }}
            height="40"
            width="40"
            alt="avatar"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu right className="user-dropdown" style={{ width: "293px" }}>
        <div className="d-flex align-items-center px-1">
          <a
            className="primary"
            href={getOrganization().menu.menuUser.userPhotoUrl}
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={
                getOrganization().menu.menuUser.userPhoto
                  ? getOrganization().menu.menuUser.userPhoto
                  : notImg
              }
              onError={(e) => {
                e.target.src = notImg;
              }}
              className="mr-1"
              height="50"
              width="50"
              alt="avatar"
            />
          </a>

          <div>
            <div className="user-name text-bold-600 mb-05" style={{ overflowWrap: "anywhere" }}>
              {getOrganization().menu.menuUser.mailto ? (
                <a
                  className="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${getOrganization().menu.menuUser.email}`}>
                  {getOrganization().menu.menuUser.name}
                </a>
              ) : (
                getOrganization().menu.menuUser.name
              )}
            </div>
            <div className="user-status" style={{ overflowWrap: "anywhere" }}>
              {getOrganization().menu.menuUser.mailto ? (
                <a
                  className="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${getOrganization().menu.menuUser.email}`}>
                  {getOrganization().menu.menuUser.email}
                </a>
              ) : (
                getOrganization().menu.menuUser.email
              )}
            </div>
          </div>
        </div>
        <div className="dropdown-divider"></div>

        {getOrganization().menu.menuUser.language ? (
          <DropdownItem
            tag="div"
            onMouseOver={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="dropdown-language">
            <Dropdown isOpen={openDropdownLanguage} toggle={onMouseEnter}>
              <DropdownToggle tag="div" className="nav-item has-sub d-flex justify-content-between">
                <div>
                  <ReactCountryFlag
                    id="a"
                    className="country-flag mr-50"
                    countryCode={countryCode}
                    svg
                  />
                  <span className="align-middle">{valueLanguage}</span>
                </div>
              </DropdownToggle>
              <DropdownMenu right className="menuDropdown-language" onMouseOver={onMouseEnter}>
                <UserLanguageInvestpr
                  handleLanguage={handleLanguage}
                  userLanguage={valueLanguage}
                  setValueLanguage={setValueLanguage}
                />
              </DropdownMenu>
            </Dropdown>
          </DropdownItem>
        ) : null}

        {getOrganization().menu.menuUser.feedback ? (
          <DropdownItem
            tag="div"
            onClick={() =>
              window.open(
                "https://forms.office.com/Pages/ResponsePage.aspx?id=eLQ7DQIGXUWQjnX_GqaAy3UQuRG_2_BNkFO-uxK9GTlUOFhXRDFQTU9KN0NENTVINVdXNE85NEhYQi4u",
                "_blank",
              )
            }>
            <MessageSquare size={14} className="mr-50" />
            <span className="align-middle">{language.feedback}</span>
          </DropdownItem>
        ) : null}

        {getOrganization().menu.menuUser.noticesAndPolicies ? (
          <DropdownItem tag="div" onClick={() => history.push(appPaths.noticesAndPolicies.path)}>
            <FileText size={14} className="mr-50" />
            <span className="align-middle">{language.noticesAndPolicies}</span>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
}

export default MenuUserInvestpr;
