export const getDataSourceFromUrl = (url: string) => {
  /* eslint-disable no-useless-escape */
  const match = url.match(/^https?:\/\/(?:www\.)?([^\/]+)/);
  if (match) {
    if (match[0] === "https://propertyconceptscommercial.com") {
      return {
        name: "Property Concepts Commercial",
        logoUrl:
          "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/property_concepts_logo.jpg",
      };
    } else if (match[0] === "https://christiansencommercial.com") {
      return {
        name: "Christiansen Commercial",
        logoUrl:
          "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/christiansen_logo.jpeg",
      };
    } else if (match[0] === "https://property.jll.com") {
      return {
        name: "JLL Properties",
        logoUrl:
          "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/8e3a48e354dfcca00509fa877cf64c222560px-JLL_logo.svg.png",
      };
    }
  }
};
