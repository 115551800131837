import { SubNeighborhood } from "@/models";
import { SubNeighborhoodBackend, responseSubNeighborhoodAdapter } from "@/adapters";

export const responseSubNeighborhoodsAdapter = (
  subNeighborhoods: SubNeighborhoodBackend[],
): SubNeighborhood[] => {
  const data: SubNeighborhood[] = [];
  if (subNeighborhoods.length) {
    subNeighborhoods.map((subNeighborhood) => {
      data.push(responseSubNeighborhoodAdapter(subNeighborhood));
    });
  }

  return data;
};
