import React from "react";
import "@/assets/scss/components/app-loader.scss";
import Spinner from "./Spinner";

class SpinnerFullWidth extends React.Component {
  render() {
    return (
      <div className="fallback-spinner-custom vh-100">
        <Spinner />
      </div>
    );
  }
}

export default SpinnerFullWidth;
