export enum PropertyFeatureTabKey {
  property = "property",
  listings = "listings",
  units = "units",
  transactions = "transactions",
  permits = "permits",
  tenants = "tenants",
  taxes = "taxes",
  map = "map",
  nearby = "nearby",
  attachments = "attachments",
}
