import React from "react";
import ReactPlayer from "react-player";
import { useLocaleCountryCode } from "@/hooks";
import { LanguageCodeKey } from "@/keys";

const Video = () => {
  const { languageCode } = useLocaleCountryCode();
  const videoUrl =
    languageCode === LanguageCodeKey.en
      ? "https://youtu.be/bgk2yyTz0rQ"
      : "https://youtu.be/kbrOWxJ0K_k";

  return (
    <div className="cardHeaderVideo">
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        url={videoUrl}
        controls={true}
        loop={true}
        playing={true}
        muted={true}
      />
    </div>
  );
};

export default Video;
