import { getOrganization } from "@/configs";
import { useLocaleCountryCode } from "@/hooks";
import React from "react";
import { Helmet } from "react-helmet-async";

const SEOComponent = ({
  title,
  description,
  route,
  img = getOrganization().organization.logo,
  showREDAtlas = true,
}: {
  title: string;
  description: string;
  route?: string;
  img?: string;
  showREDAtlas?: boolean;
}) => {
  const { languageCode } = useLocaleCountryCode();
  return (
    <Helmet>
      <html lang={languageCode} />
      <title>{`${title} ${showREDAtlas ? `| RED Atlas` : ""}`}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:type" content="website" /> */}
      {/* <meta property="og:url" content={`http://mysite.com/${route}`} /> */}
      <meta property="og:image" content={img} />
      <meta property="og:locale" content={languageCode} />
      <meta property="og:site_name" content="RED Atlas" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@redatlas" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:site" content="@redatlas" />

      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
  );
};

export default SEOComponent;
