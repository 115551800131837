import { ValuationFeatureBackend } from "@/adapters";
import { ValuationFeature } from "@/models";

export const responseValuationFeaturesAdapter = (
  data: ValuationFeatureBackend,
): ValuationFeature => {
  const newData: ValuationFeature = {
    boolean: [],
    numeric: [],
    amenitiesIds: [],
  };

  if (data?.boolean?.length) {
    newData.boolean = data?.boolean;
  }
  if (data?.numeric?.length) {
    newData.numeric = data?.numeric?.map((item) => item.name);
  }
  if (data?.amenitiesIds?.length) {
    newData.amenitiesIds = data?.amenitiesIds;
  }

  return newData;
};
