import React from "react";
import Skeleton from "react-loading-skeleton";

function SkeletonTableView() {
  return (
    <div style={{height: "calc(100vh - 265px)", width: "100%"}}>
      <div style={{height: "90%"}}>
            <Skeleton
            height={"10%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
                marginBottom: "0.5%"
            }}
            />
            <Skeleton
            height={"15%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
                marginBottom: "1%"
            }}
            />
            <Skeleton
            height={"15%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
                marginBottom: "1%"
            }}
            />
            <Skeleton
            height={"15%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
                marginBottom: "1%"
            }}
            />
            <Skeleton
            height={"15%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
                marginBottom: "1%"
            }}
            />
            <Skeleton
            height={"15%"}
            width={"100%"}
            style={{
                borderTopLeftRadius: "0.5rem",
                borderTopRightRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem"
            }}
            />
            
      </div>
      <div style={{height: "10%"}} className="d-flex justify-content-end align-items-end">
        <div className="d-flex align-items-center">
        <Skeleton
            height={25}
            width={150}
            style={{
                borderTopLeftRadius: "2rem",
                borderTopRightRadius: "2rem",
                borderBottomLeftRadius: "2rem",
                borderBottomRightRadius: "2rem"
            }}
            className="mr-1"
            />
        <Skeleton
            height={35}
            width={35}
            style={{
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%"
            }}
            className="mr-2"
            />
        <Skeleton
            height={25}
            width={100}
            style={{
                borderTopLeftRadius: "2rem",
                borderTopRightRadius: "2rem",
                borderBottomLeftRadius: "2rem",
                borderBottomRightRadius: "2rem"
            }}
            className="mr-2"
            />
        
            <Skeleton
            height={35}
            width={35}
            style={{
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%"
            }}
            className="mr-1"
            />
            <Skeleton
            height={35}
            width={35}
            style={{
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%"
            }}
            className="mr-1"
            />
            <Skeleton
            height={35}
            width={35}
            style={{
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%"
            }}
            className="mr-1"
            />
            <Skeleton
            height={35}
            width={35}
            style={{
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%"
            }}
            />
        </div>
     
            </div>
    </div>
  );
}
export default SkeletonTableView;
