import { Pill } from "@/components/styledComponents";
import { useCategoryTranslations } from "@/hooks";
import { NewsFiltersEntityFrontKey } from "@/keys";
import { Parcel } from "@/models";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useTheme } from "styled-components";
import { GroupedSuggestion } from "../SearchNavbar";

type TenantSuggestionProps = {
  item: GroupedSuggestion;
};

const TenantSuggestion: FC<TenantSuggestionProps> = ({
  item: { id, title, img, address, sector, subsector, microsector },
}) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { defaultColors, parcelColors } = useTheme();
  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": img,
      })}>
      <div className="item-container d-flex w-100">
        {img ? (
          <img
            className="img-list mt-25"
            src={img}
            height="60"
            width="60"
            alt={title ?? undefined}
          />
        ) : null}
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          <div className="w-100">
            <h6
              id={`nameResultAddress-${id}`}
              className="align-middle mb-0 fw-bold black"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "400px",
              }}>
              {address}
            </h6>
            <UncontrolledTooltip placement="bottom" target={`nameResultAddress-${id}`}>
              {address}
            </UncontrolledTooltip>

            {title ? (
              <div
                className="mt-05"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "386px",
                }}>
                <small className="fw-bold">{`${language.results.tenants.name}: `}</small>
                <small>{title}</small>
              </div>
            ) : null}
            <div className="d-flex align-items-center flex-wrap mt-05">
              <Pill
                className="mr-1 cursor-pointer"
                style={{
                  marginRight: "0.5rem",
                }}
                color={defaultColors.background}
                backgroundColor={sectorColorsMapper({
                  sectorName: sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}
                borderColor={sectorColorsMapper({
                  sectorName: sector?.name ?? null,
                  sectorColor: parcelColors.sectors,
                  fillColor: true,
                })}>
                <p className="mb-0">{sector?.name ? languageSector(sector.name) : "---"}</p>
              </Pill>

              {subsector ? (
                <span className="text-truncate mb-0 mr-05">{subsector?.name ?? "---"}</span>
              ) : null}

              {microsector ? (
                <span className="text-truncate mb-0">
                  {"| "}
                  {microsector?.name ?? "---"}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantSuggestion;
