import React from "react";
import "@/assets/scss/components/app-loader.scss";
import { getOrganization } from "@/configs";
import classNames from "classnames";
import { useAppSelector } from "@/app/hooks";
import { selectGridView } from "@/redux/slices";

function SpinnerClustersMap() {
  const { viewActive } = useAppSelector(selectGridView);
  return (
    <div
      className={classNames("fallback-spinner", {
        ["fallback-spinner-left-screen"]: viewActive === "grid",
      })}>
      <div className="loading bg-white">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
        <img
          src={getOrganization().organization.logo}
          alt={getOrganization().organization.name}
          width={25}
          style={{ position: "absolute", top: "9px", left: "12px" }}
        />
      </div>
    </div>
  );
}

export default SpinnerClustersMap;
