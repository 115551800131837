import { store } from "@/app/store";
import {
  ListingPropertyTypeBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  SectorKey,
} from "@/keys";
import { OptionType } from "@/models";
import { Filters } from "@/redux/slices";

//   Filtro visible   |  filtro NO visible
// ------------------------------------------
// parcel.landArea         |   listing.lotArea
// listing.builtArea         |   property.totalArea
// listing.price                   |  transaction.transactionPrice
// listing.pricePerBuiltSqm   |   transaction.pricePerBuiltSqm
// listing.pricePerLotSqm      |    transaction.pricePerLotSqm
// parcel.sectorId           |   listing.propertyType

export const unifiedGptFiltersAdapter = (data: Filters): Filters => {
  const { portal } = store.getState().app;
  const { categoricFilters } = store.getState().newFilters.filtersByPortal[portal];

  // ------------------- parcel.landArea -> listing.lotArea -------------------
  if (
    data[NewsFiltersEntityFrontKey.parcel][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.landArea
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.listing]: {
        ...data[NewsFiltersEntityFrontKey.listing],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.lotArea]:
            data[NewsFiltersEntityFrontKey.parcel][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.landArea
            ],
        },
      },
    };
  } else if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.lotArea
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.parcel]: {
        ...data[NewsFiltersEntityFrontKey.parcel],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.parcel]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.landArea]:
            data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.lotArea
            ],
        },
      },
    };
  }

  // ------------------- listing.builtArea -> property.totalArea -------------------
  if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.builtArea
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.property]: {
        ...data[NewsFiltersEntityFrontKey.property],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.property]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.totalArea]:
            data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.builtArea
            ],
        },
      },
    };
  } else if (
    data[NewsFiltersEntityFrontKey.property][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.totalArea
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.listing]: {
        ...data[NewsFiltersEntityFrontKey.listing],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.builtArea]:
            data[NewsFiltersEntityFrontKey.property][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.totalArea
            ],
        },
      },
    };
  }

  // ------------------- listing.price -> transaction.transactionPrice -------------------
  if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.price
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.transaction]: {
        ...data[NewsFiltersEntityFrontKey.transaction],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.transaction]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.transactionPrice]:
            data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.price
            ],
        },
      },
    };
  } else if (
    data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.transactionPrice
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.listing]: {
        ...data[NewsFiltersEntityFrontKey.listing],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.price]:
            data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.transactionPrice
            ],
        },
      },
    };
  }

  // ------------------- listing.pricePerBuiltSqm -> transaction.pricePerBuiltSqm -------------------
  if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.pricePerBuiltSqm
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.transaction]: {
        ...data[NewsFiltersEntityFrontKey.transaction],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.transaction]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.pricePerBuiltSqm]:
            data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.pricePerBuiltSqm
            ],
        },
      },
    };
  } else if (
    data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.pricePerBuiltSqm
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.listing]: {
        ...data[NewsFiltersEntityFrontKey.listing],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.pricePerBuiltSqm]:
            data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.pricePerBuiltSqm
            ],
        },
      },
    };
  }

  // ------------------- listing.pricePerLotSqm -> transaction.pricePerLotSqm -------------------
  if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.pricePerLotSqm
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.transaction]: {
        ...data[NewsFiltersEntityFrontKey.transaction],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.transaction]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.pricePerLotSqm]:
            data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.pricePerLotSqm
            ],
        },
      },
    };
  } else if (
    data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
      NewsFiltersFrontKey.pricePerLotSqm
    ].value
  ) {
    data = {
      ...data,
      [NewsFiltersEntityFrontKey.listing]: {
        ...data[NewsFiltersEntityFrontKey.listing],
        [NewsFiltersTypeFrontKey.numeric]: {
          ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.numeric],
          [NewsFiltersFrontKey.pricePerLotSqm]:
            data[NewsFiltersEntityFrontKey.transaction][NewsFiltersTypeFrontKey.numeric]?.[
              NewsFiltersFrontKey.pricePerLotSqm
            ],
        },
      },
    };
  }

  // ------------------- listing.propertyType -> parcel.sectorId -------------------
  if (
    data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.categoric]?.[
      NewsFiltersFrontKey.propertyType
    ].value
  ) {
    const searchSectors = (
      data[NewsFiltersEntityFrontKey.listing][NewsFiltersTypeFrontKey.categoric]?.[
        NewsFiltersFrontKey.propertyType
      ].value as OptionType[]
    ).map((propertyType) => {
      if (propertyType?.value === ListingPropertyTypeBackendKey.HOUSE) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.singleFamily,
        );
      }
      if (propertyType?.value === ListingPropertyTypeBackendKey.APARTMENT) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.multiFamily,
        );
      }
      if (propertyType?.value === ListingPropertyTypeBackendKey.WAREHOUSE) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.industrial,
        );
      }
      if (propertyType?.value === ListingPropertyTypeBackendKey.LOT) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.land,
        );
      }
      if (propertyType?.value === ListingPropertyTypeBackendKey.OFFICE) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.office,
        );
      }
      if (propertyType?.value === ListingPropertyTypeBackendKey.BUSINESS) {
        return categoricFilters.sectors.find(
          (sector) => sector?.name?.toLowerCase() === SectorKey.retail,
        );
      }
    });

    if (searchSectors !== undefined) {
      const values: OptionType[] = searchSectors.map((sector) => ({
        value: sector?.value ?? "",
        label: sector?.label ?? "",
        name: sector?.name ?? "",
      }));

      data = {
        ...data,
        [NewsFiltersEntityFrontKey.parcel]: {
          ...data[NewsFiltersEntityFrontKey.parcel],
          [NewsFiltersTypeFrontKey.categoric]: {
            ...data?.[NewsFiltersEntityFrontKey.parcel]?.[NewsFiltersTypeFrontKey.categoric],
            [NewsFiltersFrontKey.sectorId]: { value: values },
          },
        },
      };
    } else if (
      data[NewsFiltersEntityFrontKey.parcel][NewsFiltersTypeFrontKey.categoric]?.[
        NewsFiltersFrontKey.sectorId
      ].value
    ) {
      const searchPropertyType = (
        data[NewsFiltersEntityFrontKey.parcel][NewsFiltersTypeFrontKey.categoric]?.[
          NewsFiltersFrontKey.sectorId
        ].value as OptionType[]
      ).map((sector) => {
        if (sector.label === SectorKey.singleFamily) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.HOUSE,
          );
        } else if (sector.label === SectorKey.multiFamily) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.APARTMENT,
          );
        } else if (sector.label === SectorKey.industrial) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.WAREHOUSE,
          );
        } else if (sector.label === SectorKey.land) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.LOT,
          );
        } else if (sector.label === SectorKey.office) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.OFFICE,
          );
        } else if (sector.label === SectorKey.retail) {
          return categoricFilters.propertyType.find(
            (propertyType) => propertyType.label === ListingPropertyTypeBackendKey.BUSINESS,
          );
        } else {
          return sector;
        }
      });

      if (searchPropertyType !== undefined) {
        const values: OptionType[] = searchPropertyType.map((propertyType) => ({
          value: propertyType?.value ?? "",
          label: propertyType?.label ?? "",
          name: propertyType?.name ?? "",
        }));

        data = {
          ...data,
          [NewsFiltersEntityFrontKey.listing]: {
            ...data[NewsFiltersEntityFrontKey.listing],
            [NewsFiltersTypeFrontKey.categoric]: {
              ...data?.[NewsFiltersEntityFrontKey.listing]?.[NewsFiltersTypeFrontKey.categoric],
              [NewsFiltersFrontKey.propertyType]: { value: values },
            },
          },
        };
      }
    }
  }
  return data;
};
