import type { AreaChartOptions, DataChartNews } from "@/models";
import { CountryCodeBackendKey, CountryCodeKey, useEndpoint } from "@/keys";
import {
  DataChartNewsBackend,
  StatisticsListingPricePerSqftBackend,
  responseDataChartNewsAdapter,
  responseListingsPricePerSqftStatisticsAdapter,
} from "@/adapters";
import { api } from "@/services";

export const statisticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // ------------- Listing Price Per SQFT --------------------
    getListingPricePerSqftStatistic: builder.query<
      AreaChartOptions,
      {
        countryCode: CountryCodeKey;
        name: string;
      }
    >({
      query: ({ countryCode, name }) => ({
        url: useEndpoint().statistics({ countryCode: CountryCodeBackendKey[countryCode] }),
        params: { name },
      }),
      transformResponse: (response: {
        data: StatisticsListingPricePerSqftBackend;
      }): AreaChartOptions => responseListingsPricePerSqftStatisticsAdapter(response.data),
    }),
  }),
  overrideExisting: false,
});

export const { useGetListingPricePerSqftStatisticQuery } = statisticsApi;
