import { NeighborhoodBackend } from "@/adapters";
import { useLocaleCountryCode } from "@/hooks";
import { CountryCodeKey } from "@/keys";
import { Neighborhood } from "@/models";
import { convertGeoJson } from "@/utilities";

export const responseNeighborhoodAdapter = (neighborhood: NeighborhoodBackend): Neighborhood => {
  const { countryCode } = useLocaleCountryCode();
  const data: Neighborhood = {
    value: neighborhood.id,
    label: neighborhood.name,
    geometry: neighborhood.geometry
      ? convertGeoJson({
          geojson: neighborhood.geometry,
        })
      : null,
  };
  if (countryCode === CountryCodeKey.PR) {
    data.isNeighborhood = true;
  }
  return data;
};
