const initialState = {
  isLoading: false,
};

export const register = (state = initialState, action) => {
  switch (action.type) {
    case "START_REGISTER_IS_LOADING": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "END_REGISTER_IS_LOADING": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
