import favicon_redatlas from "@/assets/img/ico/favicon.ico";
import logotype_redatlas from "@/assets/img/logo/logotype.png";
import logotype_white_redatlas from "@/assets/img/logo/logotype-white.png";
import logo_redatlas from "@/assets/img/logo/logo.png";
import logo_white_redatlas from "@/assets/img/logo/logo-white.png";
import loginBg_redatlas from "@/assets/img/auth/login-img.png";
import registerBg_redatlas from "@/assets/img/auth/create-account.png";
import { sizeViewport } from "@/components/styledComponents";

import favicon_investpr from "@/assets/img/organizations/investpr/ico/favicon.svg";
import logotype_investpr from "@/assets/img/organizations/investpr/logo/logotype.png";
import logo_investpr from "@/assets/img/organizations/investpr/logo/logo.png";
import {
  CategoricFiltersKey,
  ColumnKey,
  ListingPropertyTypeBackendKey,
  NewsFiltersBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersTypeFrontKey,
  OrganizationKey,
  PortalKey,
  PropertyFeatureTabKey,
  TabKey,
} from "@/keys";
import { FiltersBackend } from "@/adapters";
import { CategoricFilters } from "@/models";

const organization = process.env.REACT_APP_ORGANIZATION;

const filter_listingSectors = (listingSectors: any, ids: any) => {
  return listingSectors.filter((listing: any) => ids.includes(listing.id));
};

const organizations = {
  [OrganizationKey.redatlas]: {
    organization: {
      key: OrganizationKey.redatlas,
      name: "RED Atlas",
      documentTitle: "RED Atlas",
      favicon: favicon_redatlas,
      logotype: logotype_redatlas,
      logotypeDark: logotype_white_redatlas,
      logo: logo_redatlas,
      logoDark: logo_white_redatlas,
      website: "https://atlas.red/home.html",
    },
    auth: {
      login: true,
      register: true,
      loginBg: loginBg_redatlas,
      registerBg: registerBg_redatlas,
    },
    filters: {
      [PortalKey.professional]: {
        show: true,
        search: {
          show: true,
          groupedSuggestions: {
            chatGpt: true,
            address: true,
            markets: true,
            submarkets: true,
            listings: true,
            parcels: true,
            properties: true,
            transactions: true,
            tenants: true,
            permits: true,
            documents: true,
            news: true,
          },
        },
        location: {
          show: true,
          filters: {
            location: {
              show: true,
              city: true,
              neighborhood: true,
              subNeighborhood: true,
              zipCode: true,
              urbanization: true,
            },
          },
        },
        listing: {
          show: true,
          filters: {
            listings: {
              show: true,
              isForRent: true,
              active: true,
              status: true,
              floodZone: true,
              listingPrice: true,
              pricePerBuiltSqm: true,
              pricePerLotSqm: true,
              listingRentableArea: true,
              lotArea: true,
              listingSector: true,
              listingPublicationDate: true,
              luxuryScore: true,
              bedrooms: true,
              bathrooms: true,
              garages: true,
              amenities: true,
              amenitiesRooms: true,
              amenitiesViews: true,
              daysOnMarket: true,
              buildingDate: true,
              yearBuilt: true,
            },
          },
        },
        property: {
          show: true,
          filters: {
            land: {
              show: true,
              landArea: true,
              landSector: true,
            },
            building: {
              show: true,
              sector: true,
              subsector: true,
              microsector: true,
              totalArea: true,
              buildingDate: true,
            },
            transaction: {
              show: true,
              lastTransactionDateRange: true,
              transactionPrice: true,
            },
          },
        },
        parcel: {
          show: true,
          filters: {
            property: {
              show: true,
              landArea: true,
              sector: true,
              subsector: true,
              microsector: true,
            },
            transaction: {
              show: true,
              lastTransactionDateRange: true,
              transactionPrice: true,
              pricePerBuiltSqm: true,
              pricePerLotSqm: true,
              transactionSector: true,
              transactionSubsector: true,
              transactionMicrosector: true,
            },
            unit: {
              show: true,
              unitType: true,
              totalArea: true,
              rooms: false,
              toilets: false,
              parkingSpaces: false,
              buildingDate: true,
            },
            permit: {
              show: true,
              sectorId: true,
              subsectorId: true,
              category: true,
              status: true,
              date: true,
            },
            tenant: {
              show: true,
              sector: true,
              subsector: true,
              microsector: true,
            },
            tax: {
              show: true,
              totalValuation: true,
              exemption: true,
              land: true,
              structure: true,
              machinery: true,
              exoneration: true,
              taxable: true,
            },
          },
        },
      },
      [PortalKey.residential]: {
        show: true,
        search: {
          show: true,
          groupedSuggestions: {
            chatGpt: true,
            address: true,
            markets: true,
            submarkets: true,
            listings: true,
            parcels: false,
            properties: false,
            transactions: false,
            tenants: false,
            permits: false,
            documents: false,
          },
        },
        location: {
          show: true,
          filters: {
            location: {
              show: true,
              city: true,
              neighborhood: true,
              subNeighborhood: true,
              zipCode: true,
              urbanization: true,
            },
          },
        },
        listing: {
          show: true,
          filters: {
            listings: {
              show: true,
              isForRent: true,
              active: true,
              status: true,
              floodZone: true,
              listingPrice: true,
              pricePerBuiltSqm: true,
              pricePerLotSqm: true,
              listingRentableArea: true,
              lotArea: true,
              listingSector: true,
              listingPublicationDate: true,
              luxuryScore: true,
              bedrooms: true,
              bathrooms: true,
              garages: true,
              amenities: true,
              amenitiesRooms: true,
              amenitiesViews: true,
              daysOnMarket: true,
              buildingDate: true,
              yearBuilt: true,
            },
          },
        },
        property: {
          show: true,
          filters: {
            land: {
              show: false,
              landArea: false,
              landSector: false,
            },
            building: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
              totalArea: false,
              buildingDate: true,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
            },
          },
        },
        parcel: {
          show: false,
          filters: {
            property: {
              show: false,
              landArea: false,
              sector: true,
              subsector: false,
              microsector: false,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
              pricePerBuiltSqm: false,
              pricePerLotSqm: false,
              transactionSector: false,
              transactionSubsector: false,
              transactionMicrosector: false,
            },
            unit: {
              show: false,
              unitType: false,
              totalArea: false,
              rooms: false,
              toilets: false,
              parkingSpaces: false,
              buildingDate: false,
            },
            permit: {
              show: false,
              sectorId: false,
              subsectorId: false,
              category: false,
              status: false,
              date: false,
            },
            tenant: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
            },
            tax: {
              show: false,
              totalValuation: false,
              exemption: false,
              land: false,
              structure: false,
              machinery: false,
              exoneration: false,
              taxable: false,
            },
          },
        },
      },
      [PortalKey.commercial]: {
        show: true,
        search: {
          show: true,
          groupedSuggestions: {
            chatGpt: true,
            address: true,
            markets: true,
            submarkets: true,
            listings: true,
            parcels: false,
            properties: false,
            transactions: false,
            tenants: false,
            permits: false,
            documents: false,
          },
        },
        location: {
          show: true,
          filters: {
            location: {
              show: true,
              city: true,
              neighborhood: true,
              subNeighborhood: true,
              zipCode: true,
              urbanization: true,
            },
          },
        },
        listing: {
          show: true,
          filters: {
            listings: {
              show: true,
              isForRent: true,
              active: true,
              status: true,
              floodZone: true,
              listingPrice: true,
              pricePerBuiltSqm: true,
              pricePerLotSqm: true,
              listingRentableArea: true,
              lotArea: true,
              listingSector: true,
              listingPublicationDate: true,
              luxuryScore: true,
              bedrooms: true,
              bathrooms: true,
              garages: true,
              amenities: true,
              amenitiesRooms: true,
              amenitiesViews: true,
              daysOnMarket: true,
              buildingDate: true,
              yearBuilt: true,
            },
          },
        },
        property: {
          show: true,
          filters: {
            land: {
              show: false,
              landArea: false,
              landSector: false,
            },
            building: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
              totalArea: false,
              buildingDate: true,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
            },
          },
        },
        parcel: {
          show: false,
          filters: {
            property: {
              show: false,
              landArea: false,
              sector: true,
              subsector: false,
              microsector: false,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
              pricePerBuiltSqm: false,
              pricePerLotSqm: false,
              transactionSector: false,
              transactionSubsector: false,
              transactionMicrosector: false,
            },
            unit: {
              show: false,
              unitType: false,
              totalArea: false,
              rooms: false,
              toilets: false,
              parkingSpaces: false,
              buildingDate: false,
            },
            permit: {
              show: false,
              sectorId: false,
              subsectorId: false,
              category: false,
              status: false,
              date: false,
            },
            tenant: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
            },
            tax: {
              show: true,
              totalValuation: true,
              exemption: true,
              land: true,
              structure: true,
              machinery: true,
              exoneration: true,
              taxable: true,
            },
          },
        },
      },
    },
    defaultFilters: {
      [PortalKey.professional]: {},
      [PortalKey.residential]: {
        [NewsFiltersEntityFrontKey.listing]: {
          [NewsFiltersTypeFrontKey.categoric]: {
            [NewsFiltersBackendKey.propertyType]: [
              ListingPropertyTypeBackendKey.APARTMENT,
              ListingPropertyTypeBackendKey.HOUSE,
            ],
          },
        },
      },
      [PortalKey.commercial]: {
        [NewsFiltersEntityFrontKey.listing]: {
          [NewsFiltersTypeFrontKey.categoric]: {
            [NewsFiltersBackendKey.propertyType]: [
              ListingPropertyTypeBackendKey.BUSINESS,
              ListingPropertyTypeBackendKey.LOT,
              ListingPropertyTypeBackendKey.OFFICE,
              ListingPropertyTypeBackendKey.WAREHOUSE,
            ],
          },
        },
      },
    },
    defaultFilterDropdownlists: {
      [PortalKey.professional]: {
        [CategoricFiltersKey.propertyType]: [],
        [CategoricFiltersKey.apartmentType]: [],
        [CategoricFiltersKey.status]: [],
        [CategoricFiltersKey.sectors]: [],
        [CategoricFiltersKey.subsectors]: [],
        [CategoricFiltersKey.microsectors]: [],
        [CategoricFiltersKey.amenities]: [],
        [CategoricFiltersKey.amenitiesRooms]: [],
        [CategoricFiltersKey.amenitiesViews]: [],
        [CategoricFiltersKey.amenitiesTop10]: [],
        [CategoricFiltersKey.permitSectors]: [],
        [CategoricFiltersKey.permitSubsectors]: [],
        [CategoricFiltersKey.permitCategories]: [],
        [CategoricFiltersKey.permitStatuses]: [],
        [CategoricFiltersKey.unitTypes]: [],
        [CategoricFiltersKey.transactionSectors]: [],
        [CategoricFiltersKey.transactionSubsectors]: [],
        [CategoricFiltersKey.transactionMicrosectors]: [],
      },
      [PortalKey.residential]: {
        [CategoricFiltersKey.propertyType]: [
          {
            value: ListingPropertyTypeBackendKey.APARTMENT,
            label: ListingPropertyTypeBackendKey.APARTMENT,
          },
          {
            value: ListingPropertyTypeBackendKey.HOUSE,
            label: ListingPropertyTypeBackendKey.HOUSE,
          },
        ],
        [CategoricFiltersKey.apartmentType]: [],
        [CategoricFiltersKey.sectors]: [],
        [CategoricFiltersKey.subsectors]: [],
        [CategoricFiltersKey.microsectors]: [],
        [CategoricFiltersKey.amenities]: [],
        [CategoricFiltersKey.amenitiesRooms]: [],
        [CategoricFiltersKey.amenitiesViews]: [],
        [CategoricFiltersKey.permitSectors]: [],
        [CategoricFiltersKey.permitSubsectors]: [],
        [CategoricFiltersKey.permitCategories]: [],
        [CategoricFiltersKey.permitStatuses]: [],
        [CategoricFiltersKey.unitTypes]: [],
        [CategoricFiltersKey.transactionSectors]: [],
        [CategoricFiltersKey.transactionSubsectors]: [],
        [CategoricFiltersKey.transactionMicrosectors]: [],
      },
      [PortalKey.commercial]: {
        [NewsFiltersBackendKey.propertyType]: [
          {
            value: ListingPropertyTypeBackendKey.BUSINESS,
            label: ListingPropertyTypeBackendKey.BUSINESS,
          },
          {
            value: ListingPropertyTypeBackendKey.LOT,
            label: ListingPropertyTypeBackendKey.LOT,
          },
          {
            value: ListingPropertyTypeBackendKey.OFFICE,
            label: ListingPropertyTypeBackendKey.OFFICE,
          },
          {
            value: ListingPropertyTypeBackendKey.WAREHOUSE,
            label: ListingPropertyTypeBackendKey.WAREHOUSE,
          },
        ],
        [CategoricFiltersKey.apartmentType]: [],
        [CategoricFiltersKey.sectors]: [],
        [CategoricFiltersKey.subsectors]: [],
        [CategoricFiltersKey.microsectors]: [],
        [CategoricFiltersKey.amenities]: [],
        [CategoricFiltersKey.amenitiesRooms]: [],
        [CategoricFiltersKey.amenitiesViews]: [],
        [CategoricFiltersKey.permitSectors]: [],
        [CategoricFiltersKey.permitSubsectors]: [],
        [CategoricFiltersKey.permitCategories]: [],
        [CategoricFiltersKey.permitStatuses]: [],
        [CategoricFiltersKey.unitTypes]: [],
        [CategoricFiltersKey.transactionSectors]: [],
        [CategoricFiltersKey.transactionSubsectors]: [],
        [CategoricFiltersKey.transactionMicrosectors]: [],
      },
    },
    menu: {
      menuUser: {
        show: true,
        userPhotoUrl: "",
        mailto: false,
        profile: true,
        security: true,
        country: true,
        language: false,
        feedback: true,
        reportErrors: true,
        noticesAndPolicies: true,
      },
      lists: {
        show: true,
      },
      news: {
        show: true,
      },
      impeller: {
        show: false,
        url: "",
      },
      realEstate: {
        show: false,
        url: "",
      },
      contact: {
        show: false,
        title: "",
        email: "",
      },
    },
    tablesView: {
      [PortalKey.professional]: [
        {
          name: TabKey.listings,
          columns: [
            ColumnKey.listing_picture_url,
            ColumnKey.title,
            ColumnKey.asking_price,
            ColumnKey.pricePerBuiltSqm,
            ColumnKey.rentable_area_sqm,
            ColumnKey.lotArea,
            ColumnKey.bedrooms,
            ColumnKey.bathrooms,
            ColumnKey.amenitiesCount,
            ColumnKey.active,
            ColumnKey.createdAt,
            ColumnKey.data_source,
            ColumnKey.listing_link,
          ],
        },
        {
          name: TabKey.properties,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.sub_market,
            ColumnKey.micro_market,
            ColumnKey.rentable_area_sqm,
            ColumnKey.land_area_sqm,
            ColumnKey.listingsCount,
            ColumnKey.transactionsCount,
            ColumnKey.permitsCount,
          ],
        },
        {
          name: TabKey.units,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.unitType,
            ColumnKey.unitNumber,
            ColumnKey.rentable_area_sqm,
            ColumnKey.cadaster,
          ],
        },
        {
          name: TabKey.transactions,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.sub_market,
            ColumnKey.transactionType,
            ColumnKey.transaction_price,
            ColumnKey.transaction_price_psqft,
            ColumnKey.pricePerLotSqm,
            ColumnKey.date,
            ColumnKey.buyer,
            ColumnKey.seller,
            ColumnKey.deed,
            ColumnKey.data_source,
          ],
        },
        {
          name: TabKey.tenants,
          columns: [
            ColumnKey.listing_picture_url,
            ColumnKey.name,
            ColumnKey.tenantName,
            ColumnKey.sectors,
            ColumnKey.sub_sector,
            ColumnKey.micro_sector,
            ColumnKey.data_source,
            ColumnKey.lease_link,
          ],
        },
        {
          name: TabKey.taxes,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.full_address,
            ColumnKey.taxable,
            ColumnKey.total_valuation,
            ColumnKey.exemption,
            ColumnKey.land,
            ColumnKey.structure,
            ColumnKey.machinery,
            ColumnKey.exoneration,
          ],
        },
        {
          name: TabKey.permits,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.address,
            ColumnKey.name,
            ColumnKey.sectorId,
            ColumnKey.subsectorId,
            ColumnKey.status,
            ColumnKey.date,
            ColumnKey.url,
          ],
        },
        {
          name: TabKey.documents,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.unitNumber,
            ColumnKey.transactionType,
            ColumnKey.date,
            ColumnKey.data_source,
            ColumnKey.link,
          ],
        },
      ],
      [PortalKey.residential]: [
        {
          name: TabKey.listings,
          columns: [
            ColumnKey.listing_picture_url,
            ColumnKey.title,
            ColumnKey.asking_price,
            ColumnKey.pricePerBuiltSqm,
            ColumnKey.rentable_area_sqm,
            ColumnKey.lotArea,
            ColumnKey.bedrooms,
            ColumnKey.bathrooms,
            ColumnKey.amenitiesCount,
            ColumnKey.active,
            ColumnKey.createdAt,
            ColumnKey.data_source,
            ColumnKey.listing_link,
          ],
        },
        {
          name: TabKey.properties,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.sub_market,
            ColumnKey.micro_market,
            ColumnKey.rentable_area_sqm,
            ColumnKey.land_area_sqm,
            ColumnKey.listingsCount,
            ColumnKey.transactionsCount,
            ColumnKey.permitsCount,
          ],
        },
        {
          name: TabKey.units,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.unitType,
            ColumnKey.unitNumber,
            ColumnKey.rentable_area_sqm,
            ColumnKey.cadaster,
          ],
        },
        {
          name: TabKey.transactions,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.sub_market,
            ColumnKey.transactionType,
            ColumnKey.transaction_price,
            ColumnKey.transaction_price_psqft,
            ColumnKey.pricePerLotSqm,
            ColumnKey.date,
            ColumnKey.buyer,
            ColumnKey.seller,
            ColumnKey.deed,
            ColumnKey.data_source,
          ],
        },
        {
          name: TabKey.tenants,
        },
        {
          name: TabKey.taxes,
        },
        {
          name: TabKey.permits,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.address,
            ColumnKey.name,
            ColumnKey.sectorId,
            ColumnKey.subsectorId,
            ColumnKey.status,
            ColumnKey.date,
            ColumnKey.url,
          ],
        },
        {
          name: TabKey.documents,
          columns: [
            ColumnKey.property_picture_url,
            ColumnKey.name,
            ColumnKey.unitNumber,
            ColumnKey.transactionType,
            ColumnKey.date,
            ColumnKey.data_source,
            ColumnKey.link,
          ],
        },
      ],
      [PortalKey.commercial]: [
        {
          name: TabKey.activeListings,
          columns: [
            ColumnKey.listing_picture_url,
            ColumnKey.title,
            ColumnKey.asking_price,
            ColumnKey.listing_sector,
            ColumnKey.listing_type_id,
            ColumnKey.rentable_area_sqm,
            ColumnKey.bedrooms,
            ColumnKey.bathrooms,
            ColumnKey.garages,
            ColumnKey.data_source,
            ColumnKey.createdAt,
          ],
        },
        {
          name: TabKey.inactiveListings,
        },
        {
          name: TabKey.properties,
        },
        {
          name: TabKey.transactions,
        },
        {
          name: TabKey.tenants,
        },
        {
          name: TabKey.taxes,
        },
        {
          name: TabKey.permits,
        },
      ],
    },
    gridView: {
      sections: [
        TabKey.listings,
        TabKey.properties,
        TabKey.units,
        TabKey.transactions,
        TabKey.tenants,
        TabKey.taxes,
        TabKey.permits,
        TabKey.documents,
      ],
    },
    footer: {
      logo: {
        show: true,
        logotype: logotype_redatlas,
        url: "https://atlas.red/home.html",
      },
    },
    lists: true,
    property: {
      edit: true,
      extraTabs: [
        {
          name: "map",
        },
        // {
        //   name: "nearby",
        // },
        // {
        //   name: "attachments",
        // },
      ],
      featureTabs: [
        {
          name: PropertyFeatureTabKey.property,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.listings,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.units,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.transactions,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.permits,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.tenants,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.taxes,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.attachments,
          internalTabs: [],
        },
      ],
    },
  },
  [OrganizationKey.investpr]: {
    organization: {
      key: OrganizationKey.investpr,
      name: "Invest Puerto Rico",
      documentTitle: "Invest Puerto Rico",
      favicon: favicon_investpr,
      logotype: logotype_investpr,
      logotypeDark: logotype_investpr,
      logo: logo_investpr,
      logoDark: logo_investpr,
      website: "https://investpr.org/",
    },
    auth: {
      login: false,
      register: false,
      loginBg: null,
      registerBg: null,
    },
    filters: {
      [PortalKey.investpr]: {
        show: false,
        search: {
          show: true,
          groupedSuggestions: {
            chatGpt: false,
            address: true,
            markets: true,
            submarkets: true,
            listings: true,
            parcels: false,
            properties: false,
            transactions: false,
            tenants: false,
            permits: false,
            documents: false,
          },
        },
        location: {
          show: true,
          filters: {
            location: {
              show: true,
              city: true,
              neighborhood: true,
              subNeighborhood: true,
              zipCode: true,
              urbanization: true,
            },
          },
        },
        listing: {
          show: true,
          filters: {
            listings: {
              show: true,
              isForRent: true,
              active: true,
              status: true,
              floodZone: false,
              listingPrice: true,
              pricePerBuiltSqm: true,
              pricePerLotSqm: true,
              listingRentableArea: true,
              lotArea: true,
              listingSector: true,
              listingPublicationDate: true,
              luxuryScore: false,
              bedrooms: false,
              bathrooms: false,
              garages: false,
              amenities: false,
              amenitiesRooms: false,
              amenitiesViews: false,
              daysOnMarket: false,
              buildingDate: false,
              yearBuilt: false,
            },
          },
        },
        property: {
          show: false,
          filters: {
            land: {
              show: false,
              landArea: false,
              landSector: false,
            },
            building: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
              totalArea: false,
              buildingDate: true,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
            },
          },
        },
        parcel: {
          show: false,
          filters: {
            property: {
              show: false,
              landArea: false,
              sector: true,
              subsector: false,
              microsector: false,
            },
            transaction: {
              show: false,
              lastTransactionDateRange: false,
              transactionPrice: false,
              pricePerBuiltSqm: false,
              pricePerLotSqm: false,
              transactionSector: false,
              transactionSubsector: false,
              transactionMicrosector: false,
            },
            unit: {
              show: false,
              unitType: false,
              totalArea: false,
              rooms: false,
              toilets: false,
              parkingSpaces: false,
              buildingDate: false,
            },
            permit: {
              show: false,
              sectorId: false,
              subsectorId: false,
              category: false,
              status: false,
              date: false,
            },
            tenant: {
              show: false,
              sector: false,
              subsector: false,
              microsector: false,
            },
            tax: {
              show: false,
              totalValuation: false,
              exemption: false,
              land: false,
              structure: false,
              machinery: false,
              exoneration: false,
              taxable: false,
            },
          },
        },
      },
    },
    defaultFilters: {
      [PortalKey.investpr]: {
        [NewsFiltersEntityFrontKey.listing]: {
          [NewsFiltersTypeFrontKey.search]: {
            [NewsFiltersBackendKey.sourceId]: "d906ceae-eded-81bc-3ba4-858ebc751d47",
          },
        },
      },
    },
    defaultFilterDropdownlists: {
      [PortalKey.investpr]: {
        [CategoricFiltersKey.propertyType]: [],
        [CategoricFiltersKey.apartmentType]: [],
        [CategoricFiltersKey.status]: [],
        [CategoricFiltersKey.sectors]: [],
        [CategoricFiltersKey.subsectors]: [],
        [CategoricFiltersKey.microsectors]: [],
        [CategoricFiltersKey.amenities]: [],
        [CategoricFiltersKey.amenitiesRooms]: [],
        [CategoricFiltersKey.amenitiesViews]: [],
        [CategoricFiltersKey.amenitiesTop10]: [],
        [CategoricFiltersKey.permitSectors]: [],
        [CategoricFiltersKey.permitSubsectors]: [],
        [CategoricFiltersKey.permitCategories]: [],
        [CategoricFiltersKey.permitStatuses]: [],
        [CategoricFiltersKey.unitTypes]: [],
        [CategoricFiltersKey.transactionSectors]: [],
        [CategoricFiltersKey.transactionSubsectors]: [],
        [CategoricFiltersKey.transactionMicrosectors]: [],
        // listingSectors: {
        //   filter_listingSectors,
        //   ids: [3, 5, 6, 7, 8],
        // },
      },
    },
    menu: {
      menuUser: {
        show: true,
        userPhoto:
          "https://dev-red-atlas.s3.us-east-2.amazonaws.com/avatar/0rj9movInvest%20Puerto%20Rico-Digital_Color.png",
        userPhotoUrl: "https://www.investpr.org/contact-us/",
        mailto: true,
        email: "info@investpr.org",
        name: "InvestPR",
        profile: false,
        security: false,
        country: false,
        language: true,
        feedback: true,
        reportErrors: false,
        noticesAndPolicies: true,
      },
      lists: {
        show: false,
      },
      news: {
        show: false,
      },
      impeller: {
        show: true,
        url: "https://impeller.investpr.org/",
      },
      realEstate: {
        show: true,
        url: "https://www.investpr.org/how-we-help/real-estate/",
      },
      contact: {
        show: true,
        title: "Janson Colberg",
        email: "jcolberg@investpr.org",
      },
    },
    tablesView: {
      [PortalKey.investpr]: [
        {
          name: TabKey.featuredListings,
          columns: [
            ColumnKey.listing_picture_url,
            ColumnKey.title,
            ColumnKey.priceString,
            ColumnKey.sub_market,
            ColumnKey.listing_sector,
            ColumnKey.listing_type_id,
            ColumnKey.sub_sector,
            ColumnKey.rentable_area_sqm,
            ColumnKey.land_area_sqm,
            ColumnKey.data_source,
            ColumnKey.listing_link,
          ],
        },
      ],
    },
    gridView: {
      sections: [TabKey.featuredListings],
    },
    footer: {
      logo: {
        show: true,
        logotype: logotype_redatlas,
        url: "https://atlas.red/home.html",
      },
      professional: {
        show: false,
      },
      residential: {
        show: false,
      },
      commercial: {
        show: false,
      },
    },
    lists: false,
    property: {
      edit: false,
      extraTabs: [
        {
          name: "attachments",
        },
      ],
      featureTabs: [
        {
          name: PropertyFeatureTabKey.property,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.units,
          internalTabs: [],
        },
        {
          name: PropertyFeatureTabKey.permits,
          internalTabs: [],
        },
      ],
    },
  },
};

export const getOrganization = () => {
  if (organization) {
    return organizations[organization as keyof typeof organizations];
  } else {
    return organizations[OrganizationKey.redatlas];
  }
};

const defaultFiltersPortal: { [key: string]: FiltersBackend } = {};
export const defaultFilters = getOrganization().defaultFilters as typeof defaultFiltersPortal;

const defaultFilterDropdownlistsPortal: { [key: string]: CategoricFilters } = {};
export const defaultFilterDropdownlists = getOrganization()
  .defaultFilterDropdownlists as typeof defaultFilterDropdownlistsPortal;

const availableFiltersREDAtlas = organizations[OrganizationKey.redatlas].filters;
const availableFiltersInvestPR = organizations[OrganizationKey.investpr].filters;
export type AvailableFiltersByPortal = typeof availableFiltersREDAtlas &
  typeof availableFiltersInvestPR;
