import React from "react";
import { News as NewsModel } from "@/models";
import { useLocaleCountryCode } from "@/hooks";
import { useTheme } from "styled-components";
import { LanguageCodeKey } from "@/keys";
import { newsCategoriesColorsMapper } from "@/redux/mappers/newsCategoriesColorsMapper";
import { Pill } from "@/components/styledComponents";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { setNews } from "@/redux/slices/news/news.slice";
import { useAppDispatch } from "@/app/hooks";
import { GroupedSuggestion } from "@/layouts/components/navbar/SearchNavbar";

const NewCard = ({
  item: { id, title, esTitle, description, esDescription, category, date, link, gptJSON, city },
}: {
  item: NewsModel | GroupedSuggestion;
}) => {
  const { languageCode } = useLocaleCountryCode();
  const { defaultColors, newsColors } = useTheme();
  const { t } = useTranslation("news");
  const language = t("news", { returnObjects: true });
  const dispatch = useAppDispatch();

  return (
    <div
      className="items-same-height w-100"
      style={{
        position: "relative",
      }}
      key={id}>
      <div
        className="card mb-0 pr-1 cursor-pointer"
        onClick={
          () => (link ? window.open(link) : dispatch(setNews({ newsView: true, newsId: id })))
          // gptJSON ? dispatch(setNews({ newsView: true, newsId: id })) : window.open(link)
        }>
        <div className="row">
          <div className="col-12">
            <div className="row">
              {gptJSON?.ogImage && (
                <div className="col-3 pr-0">
                  <div
                    className="bgNewsReport"
                    style={{
                      backgroundImage: `url(${gptJSON?.ogImage})`,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              )}
              <div className={`col-${gptJSON?.ogImage ? "9" : "12"}  py-1`}>
                <div className={gptJSON?.ogImage ? "" : "ml-1"}>
                  <h5
                    className="mb-05 title-list-menu fw-bold title-news"
                    style={{ color: defaultColors.color, whiteSpace: "normal" }}>
                    {languageCode === LanguageCodeKey.en ? title : esTitle}
                  </h5>
                  <div className="description-news mb-0" style={{ whiteSpace: "normal" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          languageCode === LanguageCodeKey.en
                            ? (description as string)
                            : (esDescription as string),
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <div className="d-flex align-items-center">
                      {category
                        ? category?.map((c) => (
                            <Pill
                              key={c}
                              className="mr-05 line-clamp-1-ellipsis"
                              color={defaultColors.background}
                              backgroundColor={newsCategoriesColorsMapper({
                                categoryName: c,
                                newsColor: newsColors.categories,
                                fillColor: true,
                              })}
                              borderColor={newsCategoriesColorsMapper({
                                categoryName: c,
                                newsColor: newsColors.categories,
                                fillColor: true,
                              })}>
                              <p className="mb-0">{language.newsCategories[c]}</p>
                            </Pill>
                          ))
                        : null}
                      {city ? (
                        <b className="mr-1 mb-0 line-clamp-1-ellipsis"> | {city.name}</b>
                      ) : null}
                    </div>
                    <small className="gray" style={{ whiteSpace: "normal" }}>
                      {date ? moment(date).format("MMM D, YYYY, h:mm A") : null}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCard;
