export enum FiltersBackendKey {
  market_id = "market_id",
  submarket_id = "submarket_id",
  micromarket_id = "micromarket_id",
  isForRent = "isForRent",
  flood_zone = "flood_zone",
  min_listing_price = "min_listing_price",
  max_listing_price = "max_listing_price",
  min_listing_rentable_area = "min_listing_rentable_area",
  max_listing_rentable_area = "max_listing_rentable_area",
  listing_sector_id = "listing_sector_id",
  published_at = "published_at",
  min_bedroom = "min_bedroom",
  max_bedroom = "max_bedroom",
  min_bathroom = "min_bathroom",
  max_bathroom = "max_bathroom",
  min_garage = "min_garage",
  max_garage = "max_garage",
  min_land_area = "min_land_area",
  max_land_area = "max_land_area",
  land_sectors = "land_sectors",
  sector_id = "sector_id",
  not_sector_id = "not_sector_id",
  subsector_id = "subsector_id",
  microsector_id = "microsector_id",
  min_rentable_area = "min_rentable_area",
  max_rentable_area = "max_rentable_area",
  amenities_ids = "amenities_ids",
  building_date = "building_date",
  last_transaction_start_date = "last_transaction_start_date",
  last_transaction_end_date = "last_transaction_end_date",
  min_transaction_price = "min_transaction_price",
  max_transaction_price = "max_transaction_price",
  map_search = "map_search",
  map_listing_search = "map_listing_search",
  westLng = "westLng",
  southLat = "southLat",
  eastLng = "eastLng",
  northLat = "northLat",
  extendViewport = "viewport",
  page = "page",
  count = "count",
  sort_by = "sort_by",
  order = "order",
  isInvestpr = "isInvestpr",
  activeListing = "active",
}

export enum NewsFiltersBackendKey {
  countryCode = "countryCode",
  cityId = "cityId",
  price = "price",
  lotArea = "lotArea",
  builtArea = "builtArea",
  parkingSpaces = "parkingSpaces",
  rooms = "rooms",
  toilets = "toilets",
  isForRent = "isForRent",
  active = "active",
  genericListingId = "genericListingId",
  propertyType = "propertyType",
  extractedAt = "extractedAt",
  totalArea = "totalArea",
  landArea = "landArea",
  id = "id",
  parentPropertyId = "parentPropertyId",
  geometry = "geometry",
  transactionPrice = "transactionPrice",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  createdAt = "createdAt",
  sourceId = "sourceId",
  dataset = "dataset",
  scriptures = "scriptures",
  amenityId = "amenityId",
}

export enum NewsFiltersTypeFrontKey {
  numeric = "numeric",
  boolean = "boolean",
  categoric = "categoric",
  date = "date",
  search = "search",
  geometric = "geometric",
}

export enum NewsFiltersTypeBackendKey {
  numeric = "numeric",
  boolean = "boolean",
  categoric = "categoric",
  date = "date",
  search = "search",
  geometry = "geometry",
}

export enum NewsFiltersEntityBackendKey {
  listing = "listing",
  parcel = "parcel",
  transaction = "transaction",
  property = "property",
  listingAmenity = "listingAmenity",
  tax = "tax",
  tenant = "tenant",
  permit = "permit",
}

export enum NewsFiltersEntityFrontKey {
  location = "location",
  listing = "listing",
  parcel = "parcel",
  transaction = "transaction",
  property = "property",
  listingAmenity = "listingAmenity",
  tax = "tax",
  tenant = "tenant",
  permit = "permit",
}

export enum NewsFiltersFrontKey {
  cityId = "cityId",
  neighborhoodId = "neighborhoodId",
  subNeighborhoodId = "subNeighborhoodId",
  zipCodeId = "zipCodeId",
  buildingId = "buildingId",
  viewport = "viewport",
  coordinates = "coordinates",
  drawnPolygon = "drawnPolygon",
  govtId = "govtId",
  price = "price",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  isForRent = "isForRent",
  isForSale = "isForSale",
  min = "min",
  max = "max",
  builtArea = "builtArea",
  rooms = "rooms",
  toilets = "toilets",
  parkingSpaces = "parkingSpaces",
  createdAt = "createdAt",
  daysOnMarket = "daysOnMarket",
  propertyType = "propertyType",
  transactionPrice = "transactionPrice",
  transactionPricePerBuiltSqm = "transactionPricePerBuiltSqm",
  transactionPricePerLotSqm = "transactionPricePerLotSqm",
  transactionDate = "transactionDate",
  // transactionSector = "transactionSector",
  // transactionSubsector = "transactionSubsector",
  // transactionMicrosector = "transactionMicrosector",
  sectorId = "sectorId",
  subsectorId = "subsectorId",
  microsectorId = "microsectorId",
  isFloodZone = "isFloodZone",
  unitType = "unitType",
  totalArea = "totalArea",
  landArea = "landArea",
  lotArea = "lotArea",
  title = "title",
  name = "name",
  address = "address",
  amenityId = "amenityId",
  amenity = "amenity",
  amenityRoom = "amenityRoom",
  amenityView = "amenityView",
  genericListingId = "genericListingId",
  totalValuation = "totalValuation",
  exemption = "exemption",
  land = "land",
  structure = "structure",
  machinery = "machinery",
  exoneration = "exoneration",
  taxable = "taxable",
  date = "date",
  agency = "agency",
  category = "category",
  permitType = "permitType",
  status = "status",
  active = "active",
  inactive = "inactive",
  luxuryScore = "luxuryScore",
  yearBuilt = "yearBuilt",
  buildingDate = "buildingDate",
  isOutlier = "isOutlier",
}

export enum CategoricFiltersKey {
  propertyType = "propertyType",
  apartmentType = "apartmentType",
  status = "status",
  sectors = "sectors",
  subsectors = "subsectors",
  microsectors = "microsectors",
  amenities = "amenities",
  amenitiesRooms = "amenitiesRooms",
  amenitiesViews = "amenitiesViews",
  amenitiesTop10 = "amenitiesTop10",
  permitSectors = "permitSectors",
  permitSubsectors = "permitSubsectors",
  permitCategories = "permitCategories",
  permitStatuses = "permitStatuses",
  unitTypes = "unitTypes",
  transactionSectors = "transactionSectors",
  transactionSubsectors = "transactionSubsectors",
  transactionMicrosectors = "transactionMicrosectors",
}

export enum FiltersFrontKey {
  market = "market",
  market_search = "market_search",
  submarket = "submarket",
  submarket_search = "submarket_search",
  micromarket = "micromarket",

  // ---- LISTINGS
  isForRent = "isForRent",
  isForSale = "isForSale",
  floodZone = "floodZone",
  min_listing_price = "min_listing_price",
  max_listing_price = "max_listing_price",
  min_listing_rentable_area = "min_listing_rentable_area",
  max_listing_rentable_area = "max_listing_rentable_area",
  listing_sector_id = "listing_sector_id",
  publication_date = "publication_date",
  min_bedrooms = "min_bedrooms",
  max_bedrooms = "max_bedrooms",
  min_bathrooms = "min_bathrooms",
  max_bathrooms = "max_bathrooms",
  min_garages = "min_garages",
  max_garages = "max_garages",

  // ---- PROPERTIES
  // ---- land
  min_land_area = "min_land_area",
  max_land_area = "max_land_area",
  landSectors = "landSectors",
  // ---- building
  buidingSector = "buidingSector",
  buildingType = "buildingType",
  buildingSubtype = "buildingSubtype",
  min_rentable_area = "min_rentable_area",
  max_rentable_area = "max_rentable_area",
  building_date = "building_date",
  // ---- transactions
  lastTransactionStartDate = "lastTransactionStartDate",
  lastTransactionEndDate = "lastTransactionEndDate",
  minTransactionPrice = "minTransactionPrice",
  maxTransactionPrice = "maxTransactionPrice",

  // ---- SEARCH
  map_search = "map_search",
  map_listing_search = "map_listing_search",
  address_search = "address_search",
  locations_search = "locations_search",
  listings_search = "listings_search",
}
