import { countries } from "@/configs";
import { CountryCodeKey } from "@/keys";

export const handleCenterCountryMap = ({
  map,
  countryCode,
  isMobile = false,
}: {
  map: google.maps.Map;
  countryCode: CountryCodeKey;
  isMobile?: boolean;
}) => {
  map.setZoom(countries[countryCode].zoom);
  const center = {
    lat: countries[countryCode].lat,
    lng: isMobile ? countries[countryCode].lngMobile : countries[countryCode].lng,
  };
  setTimeout(() => map.setCenter(center), 100);
};
