export enum ListingKey {
  id = "id",
  uniqueListingId = "uniqueListingId",
  ageNumeric = "ageNumeric",
  genericListingId = "genericListingId",
  location = "location",
  title = "title",
  url = "url",
  price = "price",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  priceString = "priceString",
  isForRent = "isForRent",
  address = "address",
  builtArea = "builtArea",
  lotArea = "lotArea",
  toilets = "toilets",
  rooms = "rooms",
  parkingSpaces = "parkingSpaces",
  buildingDate = "buildingDate",
  createdAt = "createdAt",
  deactivatedAt = "deactivatedAt",
  descriptionText = "descriptionText",
  active = "active",
  propertyType = "propertyType",
  photos = "photos",
  brokerName = "brokerName",
  phones = "phones",
  floodZone = "floodZone",
}

export enum ListingExpandKey {
  dataSource = "dataSource",
  brokersInfo = "brokersInfo",
  parcel = "parcel",
  city = "city",
  neighborhood = "neighborhood",
  subNeighborhood = "subNeighborhood",
  amenities = "amenities",
}
