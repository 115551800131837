export enum TransactionKey {
  id = "id",
  address = "address",
  buyerName = "buyerName",
  createdAt = "createdAt",
  deedBook = "deedBook",
  deedNumber = "deedNumber",
  deedPage = "deedPage",
  sellerName = "sellerName",
  transactionDate = "transactionDate",
  transactionPrice = "transactionPrice",
  transactionType = "transactionType",
  solar = "solar",
  unitType = "unitType",
  unitNumber = "unitNumber",
  pricePerBuiltSqm = "pricePerBuiltSqm",
  pricePerLotSqm = "pricePerLotSqm",
  builtArea = "builtArea",
}

export enum TransactionExpandKey {
  transactionProperties = "transactionProperties",
  source = "source",
  scriptures = "scriptures",
}
