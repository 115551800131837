export const customSortDate = (a: Date, b: Date): number => {
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
