import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

// Define a type for the slice state
interface ListingState {
  listingView: boolean;
  listingId: string | null;
  duplicatedListingsView: boolean;
  uniqueListingId?: string;
  listingCenter: { lat: number; lng: number } | null;
}

// Define the initial state using that type
const initialState: ListingState = {
  listingView: false,
  listingId: null,
  duplicatedListingsView: false,
  uniqueListingId: undefined,
  listingCenter: null,
};

export const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setListing: (
      state,
      action: PayloadAction<{ listingView: boolean; listingId: string | null }>,
    ) => {
      state.listingView = action.payload.listingView;
      state.listingId = action.payload.listingId;
    },
    setDuplicatedListings: (
      state,
      {
        payload: { duplicatedListingsView, uniqueListingId },
      }: PayloadAction<{ duplicatedListingsView: boolean; uniqueListingId?: string }>,
    ) => {
      state.duplicatedListingsView = duplicatedListingsView;
      state.uniqueListingId = uniqueListingId;
    },
    setListingCenter: (
      state,
      action: PayloadAction<{ listingCenter: { lat: number; lng: number } | null }>,
    ) => {
      state.listingCenter = action.payload.listingCenter;
    },
  },
});

// These actions will dispatch in the app
export const { setListing, setDuplicatedListings, setListingCenter } = listingSlice.actions;

export const selectListing = (state: RootState) => state.listing;

export default listingSlice.reducer;
