import React from "react";
import { components } from "react-select";
import { InputGroup, InputGroupAddon, Input, Label } from "reactstrap";
import { Check, Info } from "react-feather";
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexyCircle";
import {
  LanguageCodeKey,
  ListingPropertyTypeBackendKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
  SectorKey,
} from "@/keys";
import { Filters, OnlyKeyEntityFilter, selectFilters, selectAppConfig } from "@/redux/slices";
import { useAppSelector } from "@/app/hooks";
import { CategoricValueFilters, OptionType } from "@/models";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  useCategoryTranslations,
  useConvertUnits,
  useDefaultFilterOptions,
  useFilterSelect,
  useLocaleCountryCode,
  useLuxuryScore,
} from "@/hooks";
import { Pill, sizeViewport } from "@/components/styledComponents";
import { listingPropertyTypeColorsMapper } from "@/redux/mappers/listingPropertyTypeColorsMapper";
import { useGetAmenitiesQuery } from "@/services";
import { CustomPlaceholder } from "@/components/reactSelect/CustomComponents";
import { AdvancedFilters, handleOnBlurProps } from "../../Filters";
import classNames from "classnames";
import { AmenityTypeBackendKey } from "@/adapters";
import SelectFilter from "../../components/SelectFilter";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { listingStatusColorsColorsMapper } from "@/redux/mappers/listingStatusColorsMapper";
import InputWithSelect from "../../components/InputWithSelect";
import RadioButton from "../../components/radioButton/RadioButton";
// import LuxuryScoreTooltip from "@/components/luxuryScore/LuxuryScoreTooltip";

interface FiltersListingsProps {
  isAdvancedFilters: boolean;
  advancedListingFilters: AdvancedFilters["listing"]["filters"];
  advancedParcelFilters: AdvancedFilters["parcel"]["filters"];
  availableListingFilters: {
    show: boolean;
    isForRent: boolean;
    status: boolean;
    active: boolean;
    floodZone: boolean;
    listingPrice: boolean;
    pricePerBuiltSqm: boolean;
    pricePerLotSqm: boolean;
    listingRentableArea: boolean;
    lotArea: boolean;
    listingSector: boolean;
    listingPublicationDate: boolean;
    luxuryScore: boolean;
    buildingDate: boolean;
    yearBuilt: boolean;
    bedrooms: boolean;
    bathrooms: boolean;
    garages: boolean;
    amenities: boolean;
    amenitiesRooms: boolean;
    amenitiesViews: boolean;
    daysOnMarket: boolean;
  };
  availableParcelFilters: {
    show: boolean;
    landArea: boolean;
    sector: boolean;
    subsector: boolean;
    microsector: boolean;
  };
  localFilters: Filters;
  handleMinMaxChange: (params: {
    name: string;
    value: { min?: Date | string | number; max?: Date | string | number };
    entity: OnlyKeyEntityFilter;
    type: Extract<
      NewsFiltersTypeFrontKey,
      NewsFiltersTypeFrontKey.numeric | NewsFiltersTypeFrontKey.date
    >;
  }) => void;
  onCheckboxChange: (params: { name: string; value: boolean; entity: OnlyKeyEntityFilter }) => void;
  handleOnBlur: (params: handleOnBlurProps[]) => void;
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  menuState: boolean;
  setActiveSelect: React.Dispatch<React.SetStateAction<string | null>>;
  activeSelect: string | null;
  containerFilterRef: React.MutableRefObject<HTMLDivElement | null>;
  setLocalFilters: (value: React.SetStateAction<Filters>) => void;
}

function FiltersListings({
  isAdvancedFilters,
  advancedListingFilters,
  advancedParcelFilters,
  availableListingFilters,
  availableParcelFilters,
  localFilters,
  handleMinMaxChange,
  onCheckboxChange,
  handleOnBlur,
  setMenuState,
  menuState,
  setActiveSelect,
  activeSelect,
  containerFilterRef,
  setLocalFilters,
}: FiltersListingsProps) {
  // console.log("localFilters.listing.numeric?.price: ", localFilters.listing.numeric?.price);

  const { t } = useTranslation("translation");
  const language = t("navbar.filters", { returnObjects: true });
  const { languageSector, languagelistingStatus } = useCategoryTranslations();
  const { languageCode } = useLocaleCountryCode();
  const { prices, builtArea, landArea, rooms, toilets } = useDefaultFilterOptions();

  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const { primary, defaultColors, listingColors, common, alertColors, parcelColors } = useTheme();
  const { handleChange } = useFilterSelect();
  const { languageBuiltAreaUnits, languageLandAreaUnits } = useConvertUnits();
  const isMobile = windowWidth < sizeViewport.laptop;
  const reduxFilters = filtersByPortal[portal].filters;

  const { data: amenities } = useGetAmenitiesQuery(
    { language: languageCode },
    { skip: !reduxFilters.listingAmenity.categoric?.amenityId },
  );

  const customSort = (
    a: CategoricValueFilters,
    b: CategoricValueFilters,
    selected: Array<string>,
  ) => {
    if (selected.includes(a.value)) {
      return -1;
    }
    if (selected.includes(b.value)) {
      return 1;
    }
    return 0;
  };

  const { LuxuryScoreOptionsFilters } = useLuxuryScore();

  const checkboxAmenityValue = ({ value }: { value: string }): boolean => {
    const val =
      reduxFilters.listingAmenity.categoric?.amenityId.value &&
      Array.isArray(reduxFilters.listingAmenity.categoric?.amenityId.value)
        ? reduxFilters.listingAmenity.categoric?.amenityId.value?.find((val) => val.value === value)
        : false;
    return val ? true : false;
  };

  return (
    <div className="row px-1">
      <div className="col-12">
        <div className="d-flex align-items-center my-1" style={{ whiteSpace: "nowrap" }}>
          <h5 className="black fw-bold text-uppercase mb-0 mr-2">
            {isAdvancedFilters ? language.menuFilters.listing : language.menuFilters.parcel}
          </h5>
          <span style={{ borderTop: `2px solid ${common.lightGray}`, width: "100%" }} />
        </div>
      </div>
      <div className="col-12">
        {/* LISTING */}
        {availableListingFilters.show && (
          <div className="row">
            {availableListingFilters.status && reduxFilters.listing.categoric?.status && (
              <div className="col-12 col-xl-3 col-lg-4 col-md-4 mb-1 d-flex align-items-start flex-wrap">
                {filtersByPortal[portal].categoricFilters.status.map((type) => (
                  <Pill
                    key={type.value}
                    color={defaultColors.background}
                    backgroundColor={listingStatusColorsColorsMapper({
                      statusName: type.value ?? null,
                      statusColor: listingColors.status,
                    })}
                    borderColor={listingStatusColorsColorsMapper({
                      statusName: type.value ?? null,
                      statusColor: listingColors.status,
                    })}
                    className="mr-1 mb-05">
                    <Checkbox
                      color="white"
                      icon={
                        <Check
                          className="vx-icon"
                          color={listingStatusColorsColorsMapper({
                            statusName: type.value ?? null,
                            statusColor: listingColors.status,
                          })}
                          size={18}
                        />
                      }
                      value={
                        reduxFilters.listing.categoric?.status.value &&
                        Array.isArray(reduxFilters.listing.categoric?.status.value)
                          ? reduxFilters.listing.categoric?.status.value?.find(
                              (val) => val.value === type.value,
                            )
                          : false
                      }
                      label={languagelistingStatus(type.label)}
                      textTruncateLabel
                      defaultChecked={
                        reduxFilters.listing.categoric?.status.value &&
                        Array.isArray(reduxFilters.listing.categoric?.status.value)
                          ? reduxFilters.listing.categoric?.status.value?.find(
                              (val) => val.value === type.value,
                            )
                          : false
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (
                          reduxFilters.listing.categoric?.status.value !== undefined &&
                          Array.isArray(reduxFilters.listing.categoric?.status.value)
                        ) {
                          const fil = reduxFilters.listing.categoric?.status.value;
                          let inputValue: OptionType[] = [];
                          if (e.target.checked) {
                            inputValue = [...fil, { value: type.value, label: type.label }];
                          } else {
                            inputValue = fil.filter((val) => val.value !== type.value);
                          }
                          handleChange(
                            inputValue,
                            {
                              action: "select-option",
                              option: undefined,
                              name: NewsFiltersFrontKey.status,
                            },
                            NewsFiltersEntityFrontKey.listing,
                            reduxFilters,
                          );
                        } else {
                          let inputValue: OptionType[] = [];
                          if (e.target.checked) {
                            inputValue.push({
                              value: type.value,
                              label: type.label,
                            });
                          } else {
                            inputValue = [];
                          }
                          handleChange(
                            [{ value: type.value, label: type.label }],
                            {
                              action: "select-option",
                              option: undefined,
                              name: NewsFiltersFrontKey.status,
                            },
                            NewsFiltersEntityFrontKey.listing,
                            reduxFilters,
                          );
                        }
                      }}
                      onBlur={() => setLocalFilters(reduxFilters)}
                    />
                  </Pill>
                ))}
              </div>
            )}

            {isMobile && (
              <div className="col-12">
                <hr className="mt-0" />
              </div>
            )}

            {advancedListingFilters[NewsFiltersFrontKey.active] &&
              availableListingFilters.active &&
              (reduxFilters.listing.boolean?.inactive || reduxFilters.listing.boolean?.active) && (
                <div
                  className={classNames("col-12 mb-1 d-flex align-items-center", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-xl-6": !isAdvancedFilters,
                  })}>
                  {reduxFilters.listing.boolean?.active && (
                    <div className="d-flex align-items-center justify-content-center mr-1">
                      <Pill
                        color={defaultColors.background}
                        backgroundColor={listingColors.status.active}
                        borderColor={listingColors.status.active}>
                        <Checkbox
                          color="white"
                          icon={
                            <Check
                              className="vx-icon"
                              color={listingColors.status.active}
                              size={18}
                            />
                          }
                          value={reduxFilters.listing.boolean.active.value}
                          label={language.filters.active.title}
                          defaultChecked={reduxFilters.listing.boolean.active.value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onCheckboxChange({
                              name: NewsFiltersFrontKey.active,
                              value: e.target.checked,
                              entity: NewsFiltersEntityFrontKey.listing,
                            });
                          }}
                          onBlur={() => setLocalFilters(reduxFilters)}
                        />
                      </Pill>
                    </div>
                  )}
                  {reduxFilters.listing.boolean?.inactive && (
                    <div className="d-flex align-items-center justify-content-center">
                      <Pill
                        color={defaultColors.background}
                        backgroundColor={listingColors.status.inactive}
                        borderColor={listingColors.status.inactive}>
                        <Checkbox
                          color="white"
                          icon={
                            <Check
                              className="vx-icon"
                              color={listingColors.status.inactive}
                              size={18}
                            />
                          }
                          value={reduxFilters.listing.boolean.inactive.value}
                          label={language.filters.inactive.title}
                          defaultChecked={reduxFilters.listing.boolean.inactive.value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onCheckboxChange({
                              name: NewsFiltersFrontKey.inactive,
                              value: e.target.checked,
                              entity: NewsFiltersEntityFrontKey.listing,
                            });
                          }}
                          onBlur={() => setLocalFilters(reduxFilters)}
                        />
                      </Pill>
                    </div>
                  )}
                </div>
              )}

            {!isAdvancedFilters &&
              availableParcelFilters.sector &&
              reduxFilters.parcel.categoric?.[NewsFiltersFrontKey.sectorId] &&
              reduxFilters.listing.categoric?.propertyType &&
              advancedParcelFilters[NewsFiltersFrontKey.sectorId] && (
                <div
                  className={classNames("col-12 mb-1 d-flex align-items-start flex-wrap", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-xl-9": !isAdvancedFilters,
                    "col-lg-8": !isAdvancedFilters,
                    "col-md-8": !isAdvancedFilters,
                    "col-sm-12": !isAdvancedFilters,
                  })}>
                  {filtersByPortal[portal].categoricFilters.sectors.map((type) => (
                    <Pill
                      key={type.value}
                      color={defaultColors.background}
                      backgroundColor={sectorColorsMapper({
                        sectorName: type.name ?? null,
                        sectorColor: parcelColors.sectors,
                        fillColor: true,
                      })}
                      borderColor={sectorColorsMapper({
                        sectorName: type.name ?? null,
                        sectorColor: parcelColors.sectors,
                        fillColor: true,
                      })}
                      className="mr-1 mb-05">
                      <Checkbox
                        color="white"
                        icon={
                          <Check
                            className="vx-icon"
                            color={sectorColorsMapper({
                              sectorName: type.name ?? null,
                              sectorColor: parcelColors.sectors,
                              fillColor: true,
                            })}
                            size={18}
                          />
                        }
                        value={
                          reduxFilters.parcel.categoric?.sectorId.value &&
                          Array.isArray(reduxFilters.parcel.categoric?.sectorId.value)
                            ? reduxFilters.parcel.categoric?.sectorId.value?.find(
                                (val) => val.value === type.value,
                              )
                            : false
                        }
                        label={languageSector(type.name ? type.name : type.label)}
                        defaultChecked={
                          reduxFilters.parcel.categoric?.sectorId.value &&
                          Array.isArray(reduxFilters.parcel.categoric?.sectorId.value)
                            ? reduxFilters.parcel.categoric?.sectorId.value?.find(
                                (val) => val.value === type.value,
                              )
                            : false
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (
                            reduxFilters.parcel.categoric?.sectorId.value !== undefined &&
                            Array.isArray(reduxFilters.parcel.categoric?.sectorId.value)
                          ) {
                            const fil = reduxFilters.parcel.categoric?.sectorId.value;
                            let inputValue: OptionType[] = [];
                            if (e.target.checked) {
                              inputValue = [
                                ...fil,
                                { value: type.value, label: type.label, name: type.name },
                              ];
                            } else {
                              inputValue = fil.filter((val) => val.value !== type.value);
                            }
                            handleChange(
                              inputValue,
                              {
                                action: "select-option",
                                option: undefined,
                                name: NewsFiltersFrontKey.sectorId,
                              },
                              NewsFiltersEntityFrontKey.parcel,
                              reduxFilters,
                            );

                            const listingInputValue: OptionType[] = [];

                            inputValue.map((type) => {
                              if (type?.name?.toLowerCase() === SectorKey.singleFamily) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.HOUSE,
                                  label: ListingPropertyTypeBackendKey.HOUSE,
                                  name: ListingPropertyTypeBackendKey.HOUSE,
                                });
                              } else if (type?.name?.toLowerCase() === SectorKey.multiFamily) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.APARTMENT,
                                  label: ListingPropertyTypeBackendKey.APARTMENT,
                                  name: ListingPropertyTypeBackendKey.APARTMENT,
                                });
                              } else if (type?.name?.toLowerCase() === SectorKey.industrial) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.WAREHOUSE,
                                  label: ListingPropertyTypeBackendKey.WAREHOUSE,
                                  name: ListingPropertyTypeBackendKey.WAREHOUSE,
                                });
                              } else if (type?.name?.toLowerCase() === SectorKey.land) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.LOT,
                                  label: ListingPropertyTypeBackendKey.LOT,
                                  name: ListingPropertyTypeBackendKey.LOT,
                                });
                              } else if (type?.name?.toLowerCase() === SectorKey.office) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.OFFICE,
                                  label: ListingPropertyTypeBackendKey.OFFICE,
                                  name: ListingPropertyTypeBackendKey.OFFICE,
                                });
                              } else if (type?.name?.toLowerCase() === SectorKey.retail) {
                                listingInputValue.push({
                                  value: ListingPropertyTypeBackendKey.BUSINESS,
                                  label: ListingPropertyTypeBackendKey.BUSINESS,
                                  name: ListingPropertyTypeBackendKey.BUSINESS,
                                });
                              } else {
                                listingInputValue.push(type);
                              }
                            });

                            handleChange(
                              listingInputValue,
                              {
                                action: "select-option",
                                option: undefined,
                                name: NewsFiltersFrontKey.propertyType,
                              },
                              NewsFiltersEntityFrontKey.listing,
                              reduxFilters,
                            );
                          } else {
                            let inputValue: OptionType[] = [];
                            if (e.target.checked) {
                              inputValue.push({
                                value: type.value,
                                label: type.label,
                                name: type.name,
                              });
                            } else {
                              inputValue = [];
                            }
                            handleChange(
                              [{ value: type.value, label: type.label, name: type.name }],
                              {
                                action: "select-option",
                                option: undefined,
                                name: NewsFiltersFrontKey.sectorId,
                              },
                              NewsFiltersEntityFrontKey.parcel,
                              reduxFilters,
                            );

                            let propertyType: OptionType = {
                              value: type.value,
                              label: type.label,
                              name: type.name,
                            };

                            if (type?.name?.toLowerCase() === SectorKey.singleFamily) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.HOUSE,
                                label: ListingPropertyTypeBackendKey.HOUSE,
                                name: ListingPropertyTypeBackendKey.HOUSE,
                              };
                            } else if (type?.name?.toLowerCase() === SectorKey.multiFamily) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.APARTMENT,
                                label: ListingPropertyTypeBackendKey.APARTMENT,
                                name: ListingPropertyTypeBackendKey.APARTMENT,
                              };
                            } else if (type?.name?.toLowerCase() === SectorKey.industrial) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.WAREHOUSE,
                                label: ListingPropertyTypeBackendKey.WAREHOUSE,
                                name: ListingPropertyTypeBackendKey.WAREHOUSE,
                              };
                            } else if (type?.name?.toLowerCase() === SectorKey.land) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.LOT,
                                label: ListingPropertyTypeBackendKey.LOT,
                                name: ListingPropertyTypeBackendKey.LOT,
                              };
                            } else if (type?.name?.toLowerCase() === SectorKey.office) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.OFFICE,
                                label: ListingPropertyTypeBackendKey.OFFICE,
                                name: ListingPropertyTypeBackendKey.OFFICE,
                              };
                            } else if (type.label.toLowerCase() === SectorKey.retail) {
                              propertyType = {
                                value: ListingPropertyTypeBackendKey.BUSINESS,
                                label: ListingPropertyTypeBackendKey.BUSINESS,
                                name: ListingPropertyTypeBackendKey.BUSINESS,
                              };
                            }

                            handleChange(
                              [propertyType],
                              {
                                action: "select-option",
                                option: undefined,
                                name: NewsFiltersFrontKey.propertyType,
                              },
                              NewsFiltersEntityFrontKey.listing,
                              reduxFilters,
                            );
                          }
                        }}
                        onBlur={() => setLocalFilters(reduxFilters)}
                      />
                    </Pill>
                  ))}
                </div>
              )}

            {!isAdvancedFilters &&
              advancedListingFilters[NewsFiltersFrontKey.price] &&
              availableListingFilters.listingPrice &&
              localFilters.listing.numeric?.price && (
                <div
                  className={classNames("col-12 mb-1", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-xl-6": !isAdvancedFilters,
                    "col-lg-6": !isAdvancedFilters,
                    "col-md-6": !isAdvancedFilters,
                    "col-sm-12": !isAdvancedFilters,
                  })}>
                  <InputWithSelect
                    options={prices}
                    label={language.filters.listingPrice.title}
                    filter={localFilters.listing.numeric?.price}
                    placeholder={{
                      min: language.filters.listingPrice.minPlaceholder,
                      max: language.filters.listingPrice.maxPlaceholder,
                    }}
                    handleMinMaxChange={handleMinMaxChange}
                    handleOnBlur={() =>
                      handleOnBlur([
                        {
                          entity: NewsFiltersEntityFrontKey.listing,
                          filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                        },
                        {
                          entity: NewsFiltersEntityFrontKey.transaction,
                          filterTypeValue: localFilters[NewsFiltersEntityFrontKey.transaction],
                        },
                      ])
                    }
                    reduxFilters={{
                      originalFilter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.price],
                      matchFilter:
                        reduxFilters.transaction.numeric?.[NewsFiltersFrontKey.transactionPrice],
                    }}
                    filterKeys={{
                      name: NewsFiltersFrontKey.price,
                      entity: NewsFiltersEntityFrontKey.listing,
                      type: NewsFiltersTypeFrontKey.numeric,
                    }}
                  />
                </div>
              )}

            {!isAdvancedFilters &&
              advancedListingFilters[NewsFiltersFrontKey.luxuryScore] &&
              availableListingFilters.luxuryScore &&
              reduxFilters.listing.categoric?.[NewsFiltersFrontKey.luxuryScore] && (
                <RadioButton
                  label={language.filters.luxuryScore.title}
                  exactMatch={{
                    defaultValue: true,
                    show: false,
                  }}
                  options={LuxuryScoreOptionsFilters}
                  showTooltip={true}
                  isAdvancedFilters={isAdvancedFilters}
                  filter={{
                    filter: reduxFilters.listing.categoric?.[NewsFiltersFrontKey.luxuryScore],
                    name: NewsFiltersFrontKey.luxuryScore,
                    entity: NewsFiltersEntityFrontKey.listing,
                    type: NewsFiltersTypeFrontKey.categoric,
                    reduxFilters: reduxFilters,
                    setLocalFilters: setLocalFilters,
                  }}
                  // tooltipLabel={
                  //   <>
                  //     <Info
                  //       className="mr-1 cursor-pointer"
                  //       size={15}
                  //       style={{ color: primary.color }}
                  //       id="infoLuxuryScores"
                  //     />
                  //     <LuxuryScoreTooltip target="infoLuxuryScores" />
                  //   </>
                  // }
                />
              )}

            {!isAdvancedFilters &&
              advancedListingFilters[NewsFiltersFrontKey.rooms] &&
              availableListingFilters.bedrooms &&
              reduxFilters.listing.numeric?.[NewsFiltersFrontKey.rooms] && (
                <RadioButton
                  label={language.filters.bedrooms.title}
                  exactMatch={{
                    defaultValue: false,
                    show: true,
                  }}
                  options={rooms}
                  isAdvancedFilters={isAdvancedFilters}
                  filter={{
                    filter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.rooms],
                    name: NewsFiltersFrontKey.rooms,
                    entity: NewsFiltersEntityFrontKey.listing,
                    type: NewsFiltersTypeFrontKey.numeric,
                    handleMinMaxChange: handleMinMaxChange,
                  }}
                />
              )}

            {!isAdvancedFilters &&
              advancedListingFilters[NewsFiltersFrontKey.toilets] &&
              availableListingFilters.bathrooms &&
              reduxFilters.listing.numeric?.[NewsFiltersFrontKey.toilets] && (
                <RadioButton
                  label={language.filters.bathrooms.title}
                  exactMatch={{
                    defaultValue: false,
                    show: true,
                  }}
                  options={toilets}
                  isAdvancedFilters={isAdvancedFilters}
                  filter={{
                    filter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.toilets],
                    name: NewsFiltersFrontKey.toilets,
                    entity: NewsFiltersEntityFrontKey.listing,
                    type: NewsFiltersTypeFrontKey.numeric,
                    handleMinMaxChange: handleMinMaxChange,
                  }}
                />
              )}

            {!isAdvancedFilters &&
              advancedListingFilters[NewsFiltersFrontKey.builtArea] &&
              availableListingFilters.listingRentableArea &&
              localFilters.listing.numeric?.builtArea && (
                <>
                  <div
                    className={classNames("col-12 mb-1", {
                      "col-xl-3": isAdvancedFilters,
                      "col-md-4": isAdvancedFilters,
                      "col-xl-6": !isAdvancedFilters,
                      "col-lg-6": !isAdvancedFilters,
                      "col-md-6": !isAdvancedFilters,
                      "col-sm-12": !isAdvancedFilters,
                    })}>
                    <InputWithSelect
                      options={builtArea.areas}
                      label={language.filters.listingRentableArea.title}
                      filter={localFilters.listing.numeric?.builtArea}
                      placeholder={{
                        min: language.filters.listingRentableArea.minPlaceholder,
                        max: language.filters.listingRentableArea.maxPlaceholder,
                      }}
                      handleMinMaxChange={handleMinMaxChange}
                      handleOnBlur={() =>
                        handleOnBlur([
                          {
                            entity: NewsFiltersEntityFrontKey.listing,
                            filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                          },
                          {
                            entity: NewsFiltersEntityFrontKey.property,
                            filterTypeValue: localFilters[NewsFiltersEntityFrontKey.property],
                          },
                        ])
                      }
                      reduxFilters={{
                        originalFilter:
                          reduxFilters.listing.numeric?.[NewsFiltersFrontKey.builtArea],
                        matchFilter: reduxFilters.property.numeric?.[NewsFiltersFrontKey.totalArea],
                      }}
                      filterKeys={{
                        name: NewsFiltersFrontKey.builtArea,
                        entity: NewsFiltersEntityFrontKey.listing,
                        type: NewsFiltersTypeFrontKey.numeric,
                      }}
                    />
                  </div>
                </>
              )}

            {!isAdvancedFilters &&
              availableParcelFilters[NewsFiltersFrontKey.landArea] &&
              localFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea] &&
              advancedParcelFilters[NewsFiltersFrontKey.landArea] && (
                <div
                  className={classNames("col-12 mb-1", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-md-6": !isAdvancedFilters,
                    "col-sm-12": !isAdvancedFilters,
                  })}>
                  <InputWithSelect
                    options={landArea.areas}
                    label={language.filters.landArea.title}
                    filter={localFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea]}
                    placeholder={{
                      min: language.filters.landArea.minPlaceholder,
                      max: language.filters.landArea.maxPlaceholder,
                    }}
                    handleMinMaxChange={handleMinMaxChange}
                    handleOnBlur={() =>
                      handleOnBlur([
                        {
                          entity: NewsFiltersEntityFrontKey.listing,
                          filterTypeValue: localFilters[NewsFiltersEntityFrontKey.listing],
                        },
                        {
                          entity: NewsFiltersEntityFrontKey.parcel,
                          filterTypeValue: localFilters[NewsFiltersEntityFrontKey.parcel],
                        },
                      ])
                    }
                    reduxFilters={{
                      originalFilter: reduxFilters.parcel.numeric?.[NewsFiltersFrontKey.landArea],
                      matchFilter: reduxFilters.listing.numeric?.[NewsFiltersFrontKey.lotArea],
                    }}
                    filterKeys={{
                      name: NewsFiltersFrontKey.landArea,
                      entity: NewsFiltersEntityFrontKey.parcel,
                      type: NewsFiltersTypeFrontKey.numeric,
                    }}
                  />
                </div>
              )}

            {advancedListingFilters[NewsFiltersFrontKey.daysOnMarket] &&
              availableListingFilters.daysOnMarket &&
              localFilters.listing.numeric?.daysOnMarket && (
                <div
                  className={classNames("col-12 mb-1", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-xl-4": !isAdvancedFilters,
                    "col-lg-6": !isAdvancedFilters,
                    "col-md-6": !isAdvancedFilters,
                    "col-sm-12": !isAdvancedFilters,
                  })}>
                  <h6 className="mb-05 fw-bold black ml-1">
                    {language.filters.daysOnMarket.title}
                  </h6>
                  <div className="d-flex">
                    <InputGroup className="inputGroupAreasFrom">
                      <Input
                        placeholder={language.filters.daysOnMarket.minPlaceholder}
                        type="text"
                        value={localFilters.listing.numeric?.daysOnMarket.value.min}
                        className="font-size-16px"
                        onChange={(value: React.FormEvent<HTMLInputElement>) =>
                          handleMinMaxChange({
                            name: NewsFiltersFrontKey.daysOnMarket,

                            value: { min: value.currentTarget.value },
                            entity: NewsFiltersEntityFrontKey.listing,
                            type: NewsFiltersTypeFrontKey.numeric,
                          })
                        }
                        onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                          if (
                            reduxFilters.listing.numeric?.daysOnMarket.value.min !==
                            value.currentTarget.value
                          ) {
                            handleOnBlur([
                              {
                                entity: NewsFiltersEntityFrontKey.listing,
                                filterTypeValue: localFilters.listing,
                              },
                            ]);
                          }
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="inputGroupPriceTo">
                      <Input
                        placeholder={language.filters.daysOnMarket.maxPlaceholder}
                        type="text"
                        className="font-size-16px"
                        value={localFilters.listing.numeric?.daysOnMarket.value.max}
                        onChange={(value: React.FormEvent<HTMLInputElement>) =>
                          handleMinMaxChange({
                            name: NewsFiltersFrontKey.daysOnMarket,

                            value: { max: value.currentTarget.value },
                            entity: NewsFiltersEntityFrontKey.listing,
                            type: NewsFiltersTypeFrontKey.numeric,
                          })
                        }
                        onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                          if (
                            reduxFilters.listing.numeric?.daysOnMarket.value.max !==
                            value.currentTarget.value
                          ) {
                            handleOnBlur([
                              {
                                entity: NewsFiltersEntityFrontKey.listing,
                                filterTypeValue: localFilters.listing,
                              },
                            ]);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              )}

            {advancedListingFilters[NewsFiltersFrontKey.createdAt] &&
              availableListingFilters.listingPublicationDate &&
              reduxFilters.listing.date?.createdAt && (
                <div
                  className={classNames("col-12 mb-1", {
                    "col-xl-3": isAdvancedFilters,
                    "col-md-4": isAdvancedFilters,
                    "col-xl-4": !isAdvancedFilters,
                    "col-lg-6": !isAdvancedFilters,
                    "col-md-6": !isAdvancedFilters,
                    "col-sm-12": !isAdvancedFilters,
                  })}>
                  <h6 className="mb-05 fw-bold black ml-1">
                    {language.filters.listingPublicationDate.title}
                  </h6>
                  <div className="row">
                    <div
                      className="col-md-6 col-12"
                      style={{ marginBottom: isMobile ? ".5rem" : "" }}>
                      <DatePicker
                        dateFormat={
                          languageCode === LanguageCodeKey.en ? "MM/dd/yyyy" : "dd/MM/yyyy"
                        }
                        portalId="root-portal-filters"
                        placeholderText={language.filters.listingPublicationDate.minPlaceholder}
                        selected={reduxFilters.listing.date?.createdAt.value.min}
                        onChange={(date: Date) =>
                          handleMinMaxChange({
                            name: NewsFiltersFrontKey.createdAt,
                            value: { min: date },
                            entity: NewsFiltersEntityFrontKey.listing,
                            type: NewsFiltersTypeFrontKey.date,
                          })
                        }
                        selectsStart
                        maxDate={reduxFilters.listing.date?.createdAt.value.max ?? new Date()}
                        startDate={reduxFilters.listing.date?.createdAt.value.min}
                        endDate={reduxFilters.listing.date?.createdAt.value.max}
                        className="DatePicker"
                        popperClassName="calendar-popout"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        showMonthDropdown
                        onInputClick={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.min`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          } else {
                            setMenuState(true);
                            setActiveSelect(
                              `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.min`,
                            );
                          }
                        }}
                        onSelect={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.min`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          }
                        }}
                        onBlur={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.min`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          }
                        }}
                        open={
                          activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.min` &&
                          menuState
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <DatePicker
                        dateFormat={
                          languageCode === LanguageCodeKey.en ? "MM/dd/yyyy" : "dd/MM/yyyy"
                        }
                        portalId="root-portal-filters"
                        placeholderText={language.filters.listingPublicationDate.maxPlaceholder}
                        selected={reduxFilters.listing.date?.createdAt.value.max}
                        onChange={(date: Date) =>
                          handleMinMaxChange({
                            name: NewsFiltersFrontKey.createdAt,
                            value: { max: date },
                            entity: NewsFiltersEntityFrontKey.listing,
                            type: NewsFiltersTypeFrontKey.date,
                          })
                        }
                        selectsEnd
                        minDate={reduxFilters.listing.date?.createdAt.value.min}
                        maxDate={new Date()}
                        startDate={reduxFilters.listing.date?.createdAt.value.min}
                        endDate={reduxFilters.listing.date?.createdAt.value.max}
                        className="DatePicker"
                        popperClassName="calendar-popout"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        showMonthDropdown
                        onInputClick={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.max`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          } else {
                            setMenuState(true);
                            setActiveSelect(
                              `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.max`,
                            );
                          }
                        }}
                        onSelect={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.max`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          }
                        }}
                        onBlur={() => {
                          if (
                            activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.max`
                          ) {
                            setMenuState(false);
                            setActiveSelect(null);
                          }
                        }}
                        open={
                          activeSelect ===
                            `${NewsFiltersEntityFrontKey.listing}.${NewsFiltersFrontKey.createdAt}.max` &&
                          menuState
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

            {advancedListingFilters[NewsFiltersFrontKey.amenityId] &&
              availableListingFilters.amenities &&
              availableListingFilters.amenitiesRooms &&
              availableListingFilters.amenitiesViews &&
              reduxFilters.listingAmenity.categoric?.amenityId &&
              !isAdvancedFilters && (
                <div
                  className={classNames(
                    "col-12 mb-1 d-flex align-items-start flex-wrap justify-content-center mt-2",
                    {
                      "col-xl-3": isAdvancedFilters,
                      "col-md-4": isAdvancedFilters,
                    },
                  )}>
                  {amenities?.top10.map((type) => (
                    <div className="d-flex align-items-center" key={type.value}>
                      <Pill
                        color={
                          checkboxAmenityValue({ value: type.value })
                            ? primary.contrastColor
                            : primary.color
                        }
                        backgroundColor={
                          checkboxAmenityValue({ value: type.value })
                            ? alertColors.success
                            : common.lightGray
                        }
                        borderColor={
                          checkboxAmenityValue({ value: type.value })
                            ? alertColors.success
                            : common.lightGray
                        }
                        isTextShadow={false}
                        className="mr-1 mb-05">
                        <Checkbox
                          name={NewsFiltersFrontKey.amenityId}
                          color={
                            checkboxAmenityValue({ value: type.value })
                              ? primary.contrastColor
                              : primary.color
                          }
                          icon={<Check className="vx-icon" color={alertColors.success} size={18} />}
                          value={checkboxAmenityValue({ value: type.value })}
                          label={
                            <Label
                              for={NewsFiltersFrontKey.amenityId}
                              className="cursor-pointer"
                              style={{
                                color: checkboxAmenityValue({ value: type.value })
                                  ? primary.contrastColor
                                  : primary.color,
                              }}>
                              {type.label}
                            </Label>
                          }
                          checked={checkboxAmenityValue({ value: type.value })}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const checkAmenityType = () =>
                              type.amenityType === AmenityTypeBackendKey.room
                                ? NewsFiltersFrontKey.amenityRoom
                                : type.amenityType === AmenityTypeBackendKey.view
                                ? NewsFiltersFrontKey.amenityView
                                : NewsFiltersFrontKey.amenity;

                            const amenityValue =
                              reduxFilters.listingAmenity.categoric?.[checkAmenityType()].value;
                            if (
                              reduxFilters.listingAmenity.categoric?.amenityId.value !==
                                undefined &&
                              Array.isArray(amenityValue)
                            ) {
                              let inputValue = [];
                              if (e.target.checked) {
                                inputValue = [
                                  ...amenityValue,
                                  { value: type.value, label: type.label },
                                ];
                              } else {
                                inputValue = amenityValue.filter((val) => val.value !== type.value);
                              }
                              handleChange(
                                inputValue,
                                {
                                  action: "select-option",
                                  option: undefined,
                                  name: checkAmenityType(),
                                },
                                NewsFiltersEntityFrontKey.listingAmenity,
                                reduxFilters,
                              );
                            } else {
                              let inputValue = [];
                              if (e.target.checked) {
                                inputValue.push({ value: type.value, label: type.label });
                              } else {
                                inputValue = [];
                              }
                              handleChange(
                                [{ value: type.value, label: type.label }],
                                {
                                  action: "select-option",
                                  option: undefined,
                                  name: checkAmenityType(),
                                },
                                NewsFiltersEntityFrontKey.listingAmenity,
                                reduxFilters,
                              );
                            }
                          }}
                          onBlur={() => setLocalFilters(reduxFilters)}
                        />
                      </Pill>
                    </div>
                  ))}
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
}
export default FiltersListings;
