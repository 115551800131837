export interface AmenityBackend {
  id: string;
  name: string;
  esName: string;
  amenityType: AmenityTypeBackend;
  count: number;
  listingTypes: {
    [key: string]: boolean;
  } | null;
}

export type AmenityTypeBackend = AmenityTypeBackendKey | null;

export enum AmenityTypeBackendKey {
  room = "Room",
  view = "View",
}
