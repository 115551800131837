import { CityBackendKeys } from "./city-backend.key";
import { ListingBackendKeys } from "./listing-backend.key";
import { MicrosectorBackendKeys } from "./microsector-backend.key";
import { NeighborhoodBackendKeys } from "./neighborhood-backend.key";
import { PermitBackendKeys } from "./permit-backend.key";
import { PropertyBackendKeys } from "./property-backend.key";
import { SectorBackendKeys } from "./sector-backend.key";
import { SourceBackendKeys } from "./source-backend.key";
import { SubsectorBackendKeys } from "./subsector-backend.key";
import { TenantBackendKeys } from "./tenant-backend.key";
import { TransactionBackendKey } from "./transaction-backend.key";
import { ZipcodeBackendKeys } from "./zipcode-backend.key";

export enum ParcelBackendKey {
  address = "address",
  addressJSON = "addressJSON",
  addressesJSON = "addressesJSON",
  applicableRegulation = "applicableRegulation",
  cityId = "cityId",
  classification = "classification",
  classificationValidationDate = "classificationValidationDate",
  createdAt = "createdAt",
  districtOverlay = "districtOverlay",
  floodZone = "floodZone",
  floodZoneAdvisory = "floodZoneAdvisory",
  floodingPanel = "floodingPanel",
  floodway = "floodway",
  geoHash = "geoHash",
  geometry = "geometry",
  govtId = "govtId",
  id = "id",
  isFloodZone = "isFloodZone",
  landArea = "landArea",
  microsectorId = "microsectorId",
  name = "name",
  neighborhoodId = "neighborhoodId",
  parcelType = "parcelType",
  photos = "photos",
  putClassification = "putClassification",
  putStatus = "putStatus",
  qualification = "qualification",
  sectorId = "sectorId",
  soil = "soil",
  subsectorId = "subsectorId",
  updatedAt = "updatedAt",
  usesPermitted = "usesPermitted",
  parcelSector = "parcelSector",
  historicArea = "historicArea",
  historicSite = "historicSite",
  geodataValidationDate = "geodataValidationDate",
  centroid = "centroid",
  score = "score",
  deactivatedAt = "deactivatedAt",
}
export type ParcelBackendKeys = keyof typeof ParcelBackendKey;
/**
 * Llaves que acepta el backend en la opción de `sortBy`.
 * Las llaves comentadas funcionan, para habilitarlas bastaría con descomentarlas.
 */
export enum ParcelBackendSortKey {
  score = "score",
  landArea = "landArea",
  // isFloodZone = "isFloodZone",
  // sectorId = "sectorId",
  // subsectorId = "subsectorId",
  // microsectorId = "microsectorId",
  // cityId = "cityId",
  // neighborhoodId = "neighborhoodId",
  // id = "id",
  // name = "name",
  // govtId = "govtId",
}

export enum ParcelBackendExpandKey {
  sector = "sector",
  subsector = "subsector",
  microsector = "microsector",
  city = "city",
  neighborhood = "neighborhood",
  subNeighborhood = "subNeighborhood",
  listings = "listings",
  permits = "permits",
  tenants = "tenants",
  properties = "properties",
  transactions = "transactions",
  zipCode = "zipCode",
  building = "building",
}

export type ParcelBackendExpandKeys = keyof typeof ParcelBackendExpandKey;

export type ParcelBackendInclude =
  | (ParcelBackendKeys | "*")
  | (`${ParcelBackendExpandKeys}.${ListingBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${PropertyBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${PermitBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${TenantBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${SectorBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${SubsectorBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${MicrosectorBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${SourceBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${CityBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${NeighborhoodBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${ZipcodeBackendKeys}` | `${ParcelBackendExpandKeys}.*`)
  | (`${ParcelBackendExpandKeys}.${TransactionBackendKey}` | `${ParcelBackendExpandKeys}.*`);
