import {
  MapDrawnPolygonButtonId,
  NewsFiltersFrontKey,
  TypeOfDrawing,
  GeometryTypesKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersTypeFrontKey,
} from "@/keys";
import {
  setDrawingMode,
  setValueFilter,
  setUserDrawnPolygon,
  OnlyKeyPortal,
  Filters,
} from "@/redux/slices";
import { useTheme } from "styled-components";
import { useAppDispatch } from "@/app/hooks";
import { convertToCoordinates } from "@/utilities";
import { GeometricFilter } from "@/models";
import { AppDispatch } from "@/app/store";

type PolygonOptionsCustom = google.maps.PolygonOptions & { suppressUndo: boolean }; // agrego suppressUndo porque no esta en los types

export const useCreateDrawingManager = () => {
  const { secondary } = useTheme();
  const dispatch = useAppDispatch();

  const polygonOptions: PolygonOptionsCustom = {
    fillColor: secondary.color,
    fillOpacity: 0.15,
    strokeColor: secondary.color,
    strokeWeight: 3,
    clickable: false,
    editable: true,
    zIndex: 1,
    suppressUndo: true, // esta option no esta en los types
  };

  const CreateDrawControls = ({
    maps,
    map,
    wasPolygonCanceledRef,
  }: {
    maps: typeof google.maps;
    map: google.maps.Map;
    wasPolygonCanceledRef: React.MutableRefObject<boolean>;
  }) => {
    const drawingManager = new maps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.LEFT_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON, maps.drawing.OverlayType.RECTANGLE],
      },
      polygonOptions: polygonOptions,
    });

    drawingManager.setMap(map);
    const drawPolygonButton = document.getElementById(MapDrawnPolygonButtonId.drawPolygonButton);
    if (drawPolygonButton) {
      drawPolygonButton.addEventListener("click", () => {
        if (drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON) {
          wasPolygonCanceledRef.current = true;
          drawingManager.setDrawingMode(null);
          dispatch(setDrawingMode({ drawingMode: null }));
          return;
        }
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        dispatch(setDrawingMode({ drawingMode: TypeOfDrawing.polygon }));
      });
    }
    return drawingManager;
  };

  return { CreateDrawControls };
};

export const CreateDeleteControls = (
  dispatch: AppDispatch,
  polygon: google.maps.Polygon,
  drawPolygonButtonRef: React.MutableRefObject<HTMLElement | null>,
) => {
  drawPolygonButtonRef.current = document.getElementById(NewsFiltersFrontKey.drawnPolygon);
  addDeleteListener({ button: drawPolygonButtonRef.current, polygon, dispatch });
};

const addDeleteListener = ({
  button,
  polygon,
  dispatch,
}: {
  button: HTMLElement | null;
  polygon: google.maps.Polygon;
  dispatch: AppDispatch;
}) => {
  if (button) {
    button.addEventListener("click", () => {
      polygon.setMap(null);
      dispatch(setUserDrawnPolygon({ userDrawnPolygon: false }));
    });
  }
};

const applyDrawnPolygonFilter = ({
  polygon,
  dispatch,
  portal,
  filters,
  applySearchFunction,
}: {
  polygon: google.maps.Polygon;
  dispatch: AppDispatch;
  portal: OnlyKeyPortal;
  filters: Filters;
  applySearchFunction: () => void;
}) => {
  const coordinates = polygon.getPath().getArray();
  const convertedCoordinates = convertToCoordinates(coordinates);

  const drawnPolygon: GeometricFilter = {
    type: GeometryTypesKey.polygon,
    coordinates: convertedCoordinates,
  };

  dispatch(
    setValueFilter({
      portal: portal,
      entity: NewsFiltersEntityFrontKey.location,
      filterTypeValue: {
        [NewsFiltersTypeFrontKey.geometric]: {
          ...filters[NewsFiltersEntityFrontKey.location][NewsFiltersTypeFrontKey.geometric],
          [NewsFiltersFrontKey.drawnPolygon]: {
            value: drawnPolygon,
            appliedValue: drawnPolygon,
          },
        },
      },
    }),
  );

  applySearchFunction();
};

export const onPolygonCompleted = ({
  polygon,
  dispatch,
  drawingManager,
  portal,
  filters,
  deletePolygonButtonRef,
  applySearchFunction,
  wasPolygonCanceledRef,
}: {
  polygon: google.maps.Polygon;
  dispatch: AppDispatch;
  drawingManager: google.maps.drawing.DrawingManager;
  portal: OnlyKeyPortal;
  filters: Filters;
  deletePolygonButtonRef: React.MutableRefObject<HTMLElement | null>;
  applySearchFunction: () => void;
  wasPolygonCanceledRef: React.MutableRefObject<boolean>;
}) => {
  if (wasPolygonCanceledRef.current) {
    polygon.setMap(null);
    wasPolygonCanceledRef.current = false;
    return;
  }
  dispatch(setUserDrawnPolygon({ userDrawnPolygon: true }));
  CreateDeleteControls(dispatch, polygon, deletePolygonButtonRef);
  addPolygonEditListener({
    polygon,
    dispatch,
    portal,
    filters,
    applySearchFunction,
  });

  drawingManager.setDrawingMode(null);
  dispatch(setDrawingMode({ drawingMode: null }));
  applyDrawnPolygonFilter({ polygon, dispatch, portal, filters, applySearchFunction });
};

const addPolygonEditListener = ({
  polygon,
  dispatch,
  portal,
  filters,
  applySearchFunction,
}: {
  polygon: google.maps.Polygon;
  dispatch: AppDispatch;
  portal: OnlyKeyPortal;
  filters: Filters;
  applySearchFunction: () => void;
}) => {
  // Add event listeners for polygon editing
  google.maps.event.addListener(polygon.getPath(), "set_at", () => {
    applyDrawnPolygonFilter({ polygon, dispatch, portal, filters, applySearchFunction });
  });
  google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
    applyDrawnPolygonFilter({ polygon, dispatch, portal, filters, applySearchFunction });
  });
  google.maps.event.addListener(polygon.getPath(), "remove_at", () => {
    applyDrawnPolygonFilter({ polygon, dispatch, portal, filters, applySearchFunction });
  });
};
