import { responseListingAdapter } from "@/adapters";
import { GeometryTypesKey } from "@/keys";

export default Array(10)
  .fill({
    id: "09563b08-f511-4aff-a6ad-064308b4597e",
    uniqueListingId: null,
    pricePerBuiltSqm: "2224.8240034537809501",
    pricePerLotSqm: null,
    genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
    location: {
      type: "Point",
      coordinates: [-65.93591, 18.36764],
    },
    centroidLocation: false,
    title: "MANSIONES DE CAROLINA, LISTA PARA MUDARSE",
    url: "https://www.clasificadosonline.com/UDRealEstateDetail.asp?ID=4799389",
    price: "210000.0",
    priceString: "$210,000",
    isForRent: false,
    address: "00987, Apartment in Carolina",
    addressJSON: null,
    builtArea: 94.38948864000001,
    lotArea: 0,
    toilets: 2,
    rooms: 3,
    parkingSpaces: 2,
    createdAt: "2024-04-03",
    deactivatedAt: "2024-04-03",
    daysOnMarket: "0",
    active: false,
    dataset: "train",
    isOutlier: false,
    type: "APARTMENT",
    propertyType: "Multi-family (apartment)",
    photos: [
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024200633hvc4wwei_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002ye3yebjq_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002ziqohqol_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002jiphsxao_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002mjaz3vg5_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002wk12px4l_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201003vjigsxup_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201037ipvjecai_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201150gzuz5jgn_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201150vbjixhk4_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201426h4mz3pyv_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201426mrjn10mi_Big.jpg",
    ],
    dataSource: {
      name: "CLASIFICADOS",
      logoUrl:
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/49eafaa90d9b31c81c3be709fef04b6eLogoClas.png",
      url: "https://www.clasificadosonline.com/",
    },
    brokersInfo: null,
    floodZone: false,
    city: {
      label: "Carolina",
    },
    amenities: [
      {
        id: "c655cc5b-db71-054e-6963-3b6db82af726",
      },
      {
        id: "27701063-8e27-74cb-25f2-9f5065a02bde",
      },
      {
        id: "082e0287-21b0-d969-889e-c64527112187",
      },
      {
        id: "87b41133-73c1-9c4b-4469-57988e6daeee",
      },
      {
        id: "699a8b63-d7f1-daad-4fae-25ade7c5a777",
      },
      {
        id: "4e95b0c0-32f4-d1c9-1e04-069d47f70f82",
      },
      {
        id: "764bc1c7-bc05-d96d-b786-20a35069ebb8",
      },
    ],
    property: null,
    attachments: null,
  })
  .map((value, i) => ({
    ...value,
    id: `${value.id}-${i}`,
  }));

export const generateRandomId = (): string => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
};

export const createFakeListing = ({ id }: { id?: string }) => {
  return responseListingAdapter({
    genericListingId: id ?? generateRandomId(),
    uniqueListingId: undefined,
    price: "210000.0",
    pricePerLotSqm: null,
    pricePerBuiltSqm: 2224.82400345378,
    builtArea: "94.38948864000001",
    daysOnMarket: 0,
    url: "https://www.clasificadosonline.com/UDRealEstateDetail.asp?ID=4799389",
    location: {
      type: GeometryTypesKey.point,
      coordinates: [-65.93591, 18.36764],
    },
    createdAt: new Date("2024-04-03"),
    deactivatedAt: new Date("2024-04-03"),
    isForRent: false,
    photos: [
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024200633hvc4wwei_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002ye3yebjq_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002ziqohqol_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002jiphsxao_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002mjaz3vg5_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201002wk12px4l_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201003vjigsxup_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201037ipvjecai_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201150gzuz5jgn_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201150vbjixhk4_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201426h4mz3pyv_Big.jpg",
      "https://imgcache.clasificadosonline.com//PP/FS/2024/4/2/04022024201426mrjn10mi_Big.jpg",
    ],
    active: false,
    dataset: "train",
    isOutlier: false,
    attachments: undefined,
    jsonGPT: {
      CAMFees: undefined,
      HOAFees: undefined,
      ceilingHeight: undefined,
      insuranceCost: undefined,
      monthlyUtilities: undefined,
      unitCeilingHeight: undefined,
    },
    yearBuilt: null,
    centroidLocation: false,
    propertyType: "APARTMENT",
    address: "00987, Apartment in Carolina",
    addressJSON: null,
    descriptionText:
      "HERMOSA PROPIEDAD CON EXCELENTE UBICACION EN CAROLINA, CUENTA CON 3 HABITACIONES, 2 BANOS, SALA, COCINA, COMEDOR, TODO REMODELADO.  LA PROPIEDAD SE UTILIZABA PARA AIRBNB, LO QUE PUEDE SER UNA OPORTUNIDAD SI ESTAS BUSCANDO ENTRAR EN ESTE NEGOCIO.  LA MARQUESINA PARA DOS AUTOS CUENTA CON UN COMODO LAUNDRY Y UN CUARTO ADICIONAL IDEAL PARA OFICINA. LOSAS NUEVAS EN TODA LA CASA Y SE AMPLIARON TODAS LAS PUERTAS PARA QUE PUEDA PASAR UNA SILLA DE RUEDA. SE QUEDA CON NEVERA, ESTUFA, LAVADORA, SECADORA, 5 MINI SPLITS, LAMPARAS.  EL MOBILIARIO SE PUEDE NEGOCIAR APARTE. TIENE CALENTADOR SOLAR Y TRANSFER SWITCH.  PARA CITAS COMUNICARSE CON BRENDA DELGADO LANCARA AL 787-951-0977 (LIC. 18962)",
    title: "MANSIONES DE CAROLINA, LISTA PARA MUDARSE",
    lotArea: null,
    brokersInfo: [
      {
        broker_name: "BRENDA DELGADO",
        broker_emails: [],
        broker_phones: ["tel:7879510977"],
        broker_company: "L�NCARA REALTY",
        broker_license: null,
      },
    ],
    toilets: 2,
    administrationFee: null,
    parkingSpaces: 2,
    rooms: 3,
    priceString: "$210,000",
    luxuryScore: 2,
    apartmentType: "Apartment",
    hasGarage: undefined,
    source: {
      id: "sourceId",
      name: "CLASIFICADOS",
      url: "https://www.clasificadosonline.com/",
      logoUrl:
        "https://prod-red-atlas.s3.us-east-2.amazonaws.com/data_sources/49eafaa90d9b31c81c3be709fef04b6eLogoClas.png",
    },
    city: {
      id: "450e67ba-d6c4-40a5-a86d-457c81f01529",
      name: "Carolina",
    },
    neighborhood: {
      id: "neighborhoodId",
      name: "Trujillo Bajo",
    },
    subNeighborhood: undefined,
    listingAmenities: [
      {
        id: "66cc4244-0e29-51a5-a83f-cfd57019266f",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "4e95b0c0-32f4-d1c9-1e04-069d47f70f82",
        amenity: {
          id: "4e95b0c0-32f4-d1c9-1e04-069d47f70f82",
          amenityType: "",
          name: "Appliances",
          esName: "Accesorios",
        },
      },
      {
        id: "5cc527cf-727d-5c62-b59e-a7997d5ae301",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "c655cc5b-db71-054e-6963-3b6db82af726",
        amenity: {
          id: "c655cc5b-db71-054e-6963-3b6db82af726",
          amenityType: "",
          name: "Recently Remodeled",
          esName: "Recientemente remodelado",
        },
      },
      {
        id: "cbd183ef-cf38-5e1b-949e-36c38db1857b",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "764bc1c7-bc05-d96d-b786-20a35069ebb8",
        amenity: {
          id: "764bc1c7-bc05-d96d-b786-20a35069ebb8",
          amenityType: "Room",
          name: "Laundry Facilities",
          esName: "Instalaciones de lavandería",
        },
      },
      {
        id: "84b5d98e-d607-56f0-ba20-b7f6d7e6a042",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "699a8b63-d7f1-daad-4fae-25ade7c5a777",
        amenity: {
          id: "699a8b63-d7f1-daad-4fae-25ade7c5a777",
          amenityType: "",
          name: "Accessible Curb Cuts",
          esName: "Cortes accesibles",
        },
      },
      {
        id: "50ce9b79-3933-5b36-965f-2e8c6a500474",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "87b41133-73c1-9c4b-4469-57988e6daeee",
        amenity: {
          id: "87b41133-73c1-9c4b-4469-57988e6daeee",
          amenityType: "Room",
          name: "Food Court",
          esName: "Zona de comidas",
        },
      },
      {
        id: "01e576a1-37f1-5c05-9644-1c958fb4d6ce",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "082e0287-21b0-d969-889e-c64527112187",
        amenity: {
          id: "082e0287-21b0-d969-889e-c64527112187",
          amenityType: "",
          name: "In-Unit Washer",
          esName: "Lavadora",
        },
      },
      {
        id: "75b5008a-97de-53c1-a4db-5d45b802009a",
        genericListingId: "09563b08-f511-4aff-a6ad-064308b4597e",
        amenityId: "27701063-8e27-74cb-25f2-9f5065a02bde",
        amenity: {
          id: "27701063-8e27-74cb-25f2-9f5065a02bde",
          amenityType: "Room",
          name: "Kitchen Facilities",
          esName: "Instalaciones de cocina",
        },
      },
    ],
    parcel: undefined,
    property: null,
    ageNumeric: 0,
    buildingDate: new Date(),
    brokerName: "lorem",
    phones: [],
  });
};
