import { useAppSelector } from "@/app/hooks";
import { Pill } from "@/components/styledComponents";
import { useCategoryTranslations } from "@/hooks";
import { NewsFiltersEntityFrontKey, SectorKey } from "@/keys";
import { Parcel } from "@/models";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { selectAppConfig, selectFilters } from "@/redux/slices";
import { findCategoricFilter } from "@/views/pages/tableView/tables/TableUnits";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useTheme } from "styled-components";
import { GroupedSuggestion } from "../SearchNavbar";

type PermitSuggestionProps = {
  item: GroupedSuggestion;
};

const PermitSuggestion: FC<PermitSuggestionProps> = ({
  item: { id, title, img, address, parcel, status },
}) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { defaultColors, parcelColors } = useTheme();
  const { portal } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": img,
      })}>
      <div className="item-container d-flex w-100">
        {img ? (
          <img
            className="img-list mt-25"
            src={img}
            height="60"
            width="60"
            alt={title ?? undefined}
          />
        ) : null}
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          <div className="w-100">
            <h6
              id={`nameResultAddress-${id}`}
              className="align-middle mb-0 fw-bold black"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "400px",
              }}>
              {address}
            </h6>
            <UncontrolledTooltip placement="bottom" target={`nameResultAddress-${id}`}>
              {address}
            </UncontrolledTooltip>

            {title ? (
              <div className="mt-05">
                <small className="fw-bold">{`${language.results.permits.name}: `}</small>
                <small>{title}</small>
              </div>
            ) : null}

            <div className="d-flex align-items-center flex-wrap mt-05">
              {parcel?.sectorId ? (
                <Pill
                  className="d-flex align-items-center text-truncate cursor-pointer mr-1"
                  color={defaultColors.background}
                  backgroundColor={sectorColorsMapper({
                    sectorName:
                      parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}
                  borderColor={sectorColorsMapper({
                    sectorName:
                      parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}>
                  <p className="mb-0 text-overflow-ellipsis" style={{ marginTop: "2px" }}>
                    {parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                      ? languageSector(
                          findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          }),
                        )
                      : "---"}
                  </p>
                </Pill>
              ) : null}

              {status ? (
                <div className="">
                  <small className="fw-bold">{`${language.results.permits.status}: `}</small>
                  <small>{status}</small>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermitSuggestion;
