import { combineReducers } from "redux";
import { login } from "./loginReducer";
import { register } from "./registerReducer";
import { password } from "./passwordReducer";

const authReducers = combineReducers({
  login,
  register,
  password,
});

export default authReducers;
