import { useAppSelector } from "@/app/hooks";
import { Pill, sizeViewport } from "@/components/styledComponents";
import { useCategoryTranslations } from "@/hooks";
import { sectorColorsMapper } from "@/redux/mappers/sectorColorsMapper";
import { selectAppConfig, selectFilters } from "@/redux/slices";
import { findCategoricFilter } from "@/views/pages/tableView/tables/TableUnits";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import { useTheme } from "styled-components";
import { GroupedSuggestion } from "../SearchNavbar";
import { NumberFormat } from "@/utilities";

type UnitSuggestionProps = {
  item: GroupedSuggestion;
  isFetchingTransactionCounts: boolean;
  transactionCount?: number;
};

const UnitSuggestion: FC<UnitSuggestionProps> = ({
  item: { govtId, address, img, parcel },
  isFetchingTransactionCounts,
  transactionCount,
}) => {
  const { t } = useTranslation("translation");
  const language = t("navbar.filters.search", { returnObjects: true });
  const languageInfowindow = t("map.infoWindow", { returnObjects: true });
  const { languageSector } = useCategoryTranslations();
  const { defaultColors, primary, listingColors, parcelColors, transactionColors } = useTheme();
  const { portal, windowWidth } = useAppSelector(selectAppConfig);
  const { filtersByPortal } = useAppSelector(selectFilters);
  const isMobile = windowWidth < sizeViewport.laptop;
  return (
    <div
      className={classNames({
        "d-flex justify-content-between align-items-center w-100": img,
      })}>
      <div className="item-container d-flex w-100">
        {img ? (
          <img
            className="img-list mt-25"
            src={img}
            height="60"
            width="60"
            alt={address ?? undefined}
          />
        ) : null}
        <div
          className="item-info ml-1 d-flex align-items-center"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          <div className="w-100">
            {address ? (
              <h6
                className="align-middle mb-0 fw-bold black"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}>
                {address}
              </h6>
            ) : null}
            {govtId ? (
              <div className="mt-05">
                <small className="fw-bold">{`${language.results.parcels.govtId}: `}</small>
                <small>{govtId}</small>
              </div>
            ) : null}
            <div
              className={classNames("d-flex flex-wrap", {
                "flex-column": isMobile,
                "align-items-left": isMobile,
                "align-items-center": !isMobile,
              })}>
              {parcel?.sectorId ? (
                <Pill
                  className="d-flex align-items-center text-truncate cursor-pointer mr-1 mt-05"
                  color={defaultColors.background}
                  backgroundColor={sectorColorsMapper({
                    sectorName:
                      parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}
                  borderColor={sectorColorsMapper({
                    sectorName:
                      parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                        ? findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          })
                        : null,
                    sectorColor: parcelColors.sectors,
                    fillColor: true,
                  })}>
                  <p className="mb-0 text-overflow-ellipsis" style={{ marginTop: "2px" }}>
                    {parcel?.sectorId && filtersByPortal[portal].categoricFilters.sectors.length
                      ? languageSector(
                          findCategoricFilter({
                            id: parcel?.sectorId,
                            arrayCategoric: filtersByPortal[portal].categoricFilters.sectors,
                          }),
                        )
                      : "---"}
                  </p>
                </Pill>
              ) : null}

              {transactionCount ? (
                <div className="d-flex align-items-center mt-05">
                  <div
                    className="sectorMarker"
                    style={{
                      backgroundColor: transactionColors.count,
                      marginRight: "4px",
                    }}
                  />
                  <span>
                    {NumberFormat({
                      value: transactionCount,
                      suffix: ` ${t("map.infoWindow.transactionCount.title", {
                        count: transactionCount,
                      })}`,
                    })}
                  </span>
                </div>
              ) : null}

              {isFetchingTransactionCounts ? (
                <div className="d-flex align-items-center mt-05">
                  <Spinner
                    size="sm"
                    className="mx-05"
                    style={{ width: "14px", height: "14px", color: primary.color }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitSuggestion;
