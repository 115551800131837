const images = [
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1679325960904902632400ef105ba682e.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1657981350137da880ee0d28093b12033.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1681157023305b3f73c4aa1b4fecbea99.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/166120028652544fa7545f83937533ff1.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167034399487687d347d07cceca33d891.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1678223643756c7373bb6ea092fa44e75.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1656522455896130e3170e6e7fc8f5aed.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16812224171103a04d02bd48c04a97097.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1678807360811704349bb30182022c19e.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16703549171495a8a3092c105b19b710f.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/168124378340838a0fb7e9af9cdd72a24.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1655922010767ef95f62b6574f99313a1.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16785080156448b04c1492796009702b6.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/165789113164542e62d44ad7be866f37c.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/166146031467725b71507a6e5ffd7de33.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1655925153622cef5bf80835a0ed720a5.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16703467549753cd814cf292af2c4a76e.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/165781744849185da4e2f785385f18423.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1670425216667303bac318394a3027928.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1670346973079ac48c73918cfc813d1e7.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1678802074903630189d3a7deddce6cb8.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16723338848047595f323d6fe020fb95f.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1679418978229625d686d23209494bfd9.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1655925090004b870fa492795dbd14a95.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167381574572786345efe3c16bfc02fa3.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167846113385373564fb3f62a02426bec.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1680122354810e09cf771425466b38eff.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1673655957846f430c610fd9301b9d261.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/165176920407530f94858102c352e3e65.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/167880212817465dc595b7e50a0211c65.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16703620233478aeda2348b3a4d09e61a.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1670349680375a4bf81b22e13251cadea.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1681265173083f49d5ae49eaa5f5e3731.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/165964319237572b81e001b5573d025f5.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16808157142815e6e950cdbea176ec09c.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16801152702631cd071824da45b6c0a19.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/1678802487356d4e34c72e7333f4f8249.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16788454699578da93651eac1a41e3759.png",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16796943680662397df2c117b7b033fb6.jpeg",
  "https://prod-red-atlas.s3.us-east-2.amazonaws.com/properties/16795150809656f5a6cb3622252431401.png",
];

export const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};
