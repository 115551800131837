import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  CountryCodeBackendKey,
  CountryCodeKey,
  NewsFiltersEntityFrontKey,
  NewsFiltersFrontKey,
  NewsFiltersTypeFrontKey,
} from "@/keys";
import { OptionType } from "@/models";
import {
  Filters,
  OnlyKeyEntityFilter,
  OnlyKeyFilterFrontKey,
  selectAppConfig,
  setNeighborhoods,
  setSubNeighborhoods,
  setValueFilter,
} from "@/redux/slices";
import { getNeighbordhoods, getSubNeighbordhoods } from "@/services";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import { useLocaleCountryCode } from "../country-code.hook";
import { letterSpacing } from "html2canvas/dist/types/css/property-descriptors/letter-spacing";

export const useFilterSelect = () => {
  const dispatch = useAppDispatch();
  const { portal } = useAppSelector(selectAppConfig);
  const { countryCode } = useLocaleCountryCode();

  const removeDependentValue = ({
    entity,
    type,
    filter,
    filters,
  }: {
    entity: OnlyKeyEntityFilter;
    type: Extract<NewsFiltersTypeFrontKey, NewsFiltersTypeFrontKey.categoric>;
    filter: OnlyKeyFilterFrontKey;
    filters: Filters;
  }) => {
    const appliedValue = filters[entity][type]?.[filter].appliedValue;

    return appliedValue ? { value: null, appliedValue } : { value: null };
  };

  const handleChange = (
    inputValue: SingleValue<OptionType> | MultiValue<OptionType>,
    { name }: ActionMeta<OptionType>,
    entity: OnlyKeyEntityFilter,
    filters: Filters,
  ) => {
    const value = inputValue as OptionType | OptionType[];
    const adjustedValue = Array.isArray(value) && !value.length ? null : value; // This is necessary to avoid [] values

    // ---------- inputValue MultiValue (reestablece las options de locations)
    if (typeof value === "object" && value instanceof Array) {
      // Restore categories values (neighborhoods & subNeighborhoods)
      if (name === NewsFiltersFrontKey.cityId) {
        if (!value.length || value.length > 1) {
          dispatch(setNeighborhoods({ neighborhoods: [] }));
          dispatch(setSubNeighborhoods({ subNeighborhoods: [] }));
        } else if (value !== undefined && value.length === 1) {
          dispatch(
            getNeighbordhoods({
              cityId: value[0].value,
              countryCode,
            }),
          );
        }
      }

      // Restore categories values (subNeighborhoods)
      if (name === NewsFiltersFrontKey.neighborhoodId) {
        if (!value.length || value.length > 1) {
          dispatch(setSubNeighborhoods({ subNeighborhoods: [] }));
        } else if (value !== undefined && value.length === 1 && countryCode !== CountryCodeKey.PR) {
          dispatch(getSubNeighbordhoods({ neighborhoodIds: [value[0].value], countryCode }));
        }
      }
    } else {
      // ---------- inputValue SingleValue

      // Restore categories values (neighborhoods & subNeighborhoods)
      if (name === NewsFiltersFrontKey.cityId) {
        if (!value) {
          dispatch(setNeighborhoods({ neighborhoods: [] }));
          dispatch(setSubNeighborhoods({ subNeighborhoods: [] }));
        } else if (inputValue !== undefined) {
          dispatch(
            getNeighbordhoods({
              cityId: value.value,
              countryCode,
            }),
          );
        }
      }

      // Restore categories values (subNeighborhoods)
      if (name === NewsFiltersFrontKey.neighborhoodId) {
        if (!value) {
          dispatch(setSubNeighborhoods({ subNeighborhoods: [] }));
        } else if (inputValue !== undefined && countryCode !== CountryCodeKey.PR) {
          dispatch(getSubNeighbordhoods({ neighborhoodIds: [value.value], countryCode }));
        }
      }
    }

    if (name) {
      const appliedValue = filters[entity][NewsFiltersTypeFrontKey.categoric]?.[name].appliedValue;

      let filterTypeValue = appliedValue
        ? {
            categoric: {
              ...filters[entity][NewsFiltersTypeFrontKey.categoric],
              [name]: { value: adjustedValue, appliedValue },
            },
          }
        : {
            categoric: {
              ...filters[entity][NewsFiltersTypeFrontKey.categoric],
              [name]: { value: adjustedValue },
            },
          };

      if (
        name === NewsFiltersFrontKey.amenity ||
        name === NewsFiltersFrontKey.amenityRoom ||
        name === NewsFiltersFrontKey.amenityView
      ) {
        let adjustedValueAmenityId = adjustedValue;

        const amenityIdValue =
          filters[entity][NewsFiltersTypeFrontKey.categoric]?.[NewsFiltersFrontKey.amenityId].value;
        const amenityValue =
          filters[entity][NewsFiltersTypeFrontKey.categoric]?.[NewsFiltersFrontKey.amenity].value;
        const amenityRoomValue =
          filters[entity][NewsFiltersTypeFrontKey.categoric]?.[NewsFiltersFrontKey.amenityRoom]
            .value;
        const amenityViewValue =
          filters[entity][NewsFiltersTypeFrontKey.categoric]?.[NewsFiltersFrontKey.amenityView]
            .value;

        if (Array.isArray(adjustedValue) && Array.isArray(adjustedValueAmenityId)) {
          if (name === NewsFiltersFrontKey.amenity) {
            if (Array.isArray(amenityRoomValue) && Array.isArray(amenityViewValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityRoomValue, amenityViewValue);
            } else if (Array.isArray(amenityRoomValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityRoomValue);
            } else if (Array.isArray(amenityViewValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityViewValue);
            }
          }
          if (name === NewsFiltersFrontKey.amenityRoom) {
            if (Array.isArray(amenityValue) && Array.isArray(amenityViewValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityValue, amenityViewValue);
            } else if (Array.isArray(amenityValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityValue);
            } else if (Array.isArray(amenityViewValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityViewValue);
            }
          }
          if (name === NewsFiltersFrontKey.amenityView) {
            if (Array.isArray(amenityRoomValue) && Array.isArray(amenityValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityRoomValue, amenityValue);
            } else if (Array.isArray(amenityRoomValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityRoomValue);
            } else if (Array.isArray(amenityValue)) {
              adjustedValueAmenityId = adjustedValue.concat(amenityValue);
            }
          }
        } else if (adjustedValue === null && Array.isArray(amenityIdValue)) {
          if (name === NewsFiltersFrontKey.amenity && Array.isArray(amenityValue)) {
            adjustedValueAmenityId = amenityIdValue.filter((item) => {
              return !amenityValue.some(({ value }) => value === item.value);
            });
          }
          if (name === NewsFiltersFrontKey.amenityRoom && Array.isArray(amenityRoomValue)) {
            adjustedValueAmenityId = amenityIdValue.filter((item) => {
              return !amenityRoomValue.some(({ value }) => value === item.value);
            });
          }
          if (name === NewsFiltersFrontKey.amenityView && Array.isArray(amenityViewValue)) {
            adjustedValueAmenityId = amenityIdValue.filter((item) => {
              return !amenityViewValue.some(({ value }) => value === item.value);
            });
          }
        }

        filterTypeValue = appliedValue
          ? {
              categoric: {
                ...filters[entity][NewsFiltersTypeFrontKey.categoric],
                [name]: { value: adjustedValue, appliedValue },
                [NewsFiltersFrontKey.amenityId]: {
                  value: adjustedValueAmenityId,
                  appliedValue:
                    filters[entity][NewsFiltersTypeFrontKey.categoric]?.[
                      NewsFiltersFrontKey.amenityId
                    ].appliedValue,
                },
              },
            }
          : {
              categoric: {
                ...filters[entity][NewsFiltersTypeFrontKey.categoric],
                [name]: { value: adjustedValue },
                [NewsFiltersFrontKey.amenityId]: { value: adjustedValueAmenityId },
              },
            };
      }

      // (reestablece los values)
      if (
        name === NewsFiltersFrontKey.cityId ||
        name === NewsFiltersFrontKey.zipCodeId ||
        (name === NewsFiltersFrontKey.subNeighborhoodId && countryCode === CountryCodeKey.PR)
      ) {
        filterTypeValue.categoric[NewsFiltersFrontKey.neighborhoodId] = removeDependentValue({
          entity,
          type: NewsFiltersTypeFrontKey.categoric,
          filter: NewsFiltersFrontKey.neighborhoodId,
          filters,
        });
      }

      if (name === NewsFiltersFrontKey.zipCodeId) {
        filterTypeValue.categoric[NewsFiltersFrontKey.cityId] = removeDependentValue({
          entity,
          type: NewsFiltersTypeFrontKey.categoric,
          filter: NewsFiltersFrontKey.cityId,
          filters,
        });
      }

      if (
        name === NewsFiltersFrontKey.cityId ||
        name === NewsFiltersFrontKey.neighborhoodId ||
        name === NewsFiltersFrontKey.zipCodeId
      ) {
        filterTypeValue.categoric[NewsFiltersFrontKey.subNeighborhoodId] = removeDependentValue({
          entity,
          type: NewsFiltersTypeFrontKey.categoric,
          filter: NewsFiltersFrontKey.subNeighborhoodId,
          filters,
        });
      }

      if (name === NewsFiltersFrontKey.sectorId) {
        filterTypeValue.categoric[NewsFiltersFrontKey.subsectorId] = removeDependentValue({
          entity,
          type: NewsFiltersTypeFrontKey.categoric,
          filter: NewsFiltersFrontKey.subsectorId,
          filters,
        });
      }
      if (
        (name === NewsFiltersFrontKey.sectorId || name === NewsFiltersFrontKey.subsectorId) &&
        entity !== NewsFiltersEntityFrontKey.permit
      ) {
        filterTypeValue.categoric[NewsFiltersFrontKey.microsectorId] = removeDependentValue({
          entity,
          type: NewsFiltersTypeFrontKey.categoric,
          filter: NewsFiltersFrontKey.microsectorId,
          filters,
        });
      }

      dispatch(
        setValueFilter({
          portal,
          entity,
          filterTypeValue: filterTypeValue,
        }),
      );
    }
  };

  return { handleChange };
};
